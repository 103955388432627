import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import NotificationIcon from 'components/Icons/NotificationIcon'

interface Props {
  unreadNotifications: number
  isSelected: boolean
  onClick?: () => void
}

const NotificationBellMobile: React.FC<Props> = props => {
  const classes = useStyles(theme)

  return (
    <div className={classes.notificationWrapper} onClick={props.onClick}>
      <NotificationIcon isSelected={props.isSelected} />
      {props.unreadNotifications > 0 && (
        <div className={classes.notificationCount}>
          {props.unreadNotifications}
        </div>
      )}
    </div>
  )
}

export default NotificationBellMobile
