import { formatWithEllipsis } from './stringUtils'

export enum ShareActionsMenuItemIds {
  TWITTER,
  REDDIT,
  FACEBOOK,
  GMAIL,
  EMAIL,
}

export function onShareActions(
  id: string,
  action: string | number,
  symbol: string,
  shareText: string,
  commentId?: string
) {
  let shareUrl = `${window.location.origin}/asset/${symbol}/topic/${id}`

  if (commentId) {
    shareUrl += `?commentid=${commentId}`
  }

  switch (action) {
    case ShareActionsMenuItemIds.TWITTER: {
      const formattedShareText = formatWithEllipsis(shareText, 50).trim()
      const twitterText = encodeURIComponent(formattedShareText + '\n').replace(
        /%0A/g,
        '%0A'
      )
      const encodedUrl = encodeURIComponent(shareUrl)

      window.open(
        `https://twitter.com/intent/post?text=${twitterText}&url=${encodedUrl}&hashtags=${symbol},fnchart,investing,trading,stocks,markets`,
        '_blank'
      )
      break
    }
    case ShareActionsMenuItemIds.REDDIT: {
      window.open(
        `http://www.reddit.com/submit?url=${encodeURIComponent(
          shareUrl
        )}&title=${encodeURIComponent(
          formatWithEllipsis(symbol + ' ' + shareText, 290)
        )}`,
        '_blank'
      )
      break
    }
    case ShareActionsMenuItemIds.FACEBOOK: {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}%2F&amp;src=sdkpreparse`,
        '_blank'
      )
      break
    }
    case ShareActionsMenuItemIds.GMAIL: {
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Check%20out%20this%20news%20about%20${encodeURIComponent(
          symbol
        )}&body='${encodeURIComponent(shareText)}%20${encodeURIComponent(
          shareUrl
        )}'&ui=2&tf=1&pli=1`,
        '_blank'
      )
      break
    }
    case ShareActionsMenuItemIds.EMAIL: {
      window.open(
        `mailto:?subject=Check out this news about ${encodeURIComponent(
          symbol
        )}&body=${encodeURIComponent(shareText)} ${encodeURIComponent(
          shareUrl
        )}`,
        '_blank'
      )
      break
    }
  }
}
