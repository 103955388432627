import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { regex, timezones } from '../../../constants'
import useStyles from './styles'
import theme from '../../../mui-theme'
import TextInput from '../../inputs/Text'
import AutocompleteInput from '../../inputs/Autocomplete'
import * as R from 'ramda'
import CancelButton from 'components/common/CancelButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { BrandButton } from 'components/common/BrandButton'

export interface AccountSettingsInput {
  id: string
  email: string
  old_password: string
  password: string
  timezone: string
}

interface AccountSettingsForm extends AccountSettingsInput {
  password_confirm: string
}

interface Props {
  id: string
  email: string
  timezone: string
  social?: boolean
  password_error?: string
  onAccountSaveClick: (input: AccountSettingsInput) => void
  onPasswordChange: (
    oldPassword: string,
    newPassword: string
  ) => Promise<boolean>
}

export default function AccountSettingsPanel(props: Props) {
  const classes = useStyles(theme)
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<AccountSettingsForm>()
  const [timezone, setTimezone] = React.useState(props.timezone)
  // const [emailError, setEmailError] = React.useState<string | null | undefined>(props.email_error);
  const [passwordError, setPasswordError] = React.useState<
    string | null | undefined
  >(props.password_error)

  const loadValues = () => {
    if (props) {
      // setValue('email', props.email);
      setValue('timezone', props.timezone)
      setValue('old_password', '')
      setValue('password', '')
      setValue('password_confirm', '')
    }
  }

  const onCancel = () => {
    // setEmailError(null);
    setPasswordError(null)
    loadValues()
  }

  const onSubmit = (data: AccountSettingsForm) => {
    data.id = props.id

    if (!!getValues().password) {
      props
        .onPasswordChange(getValues().old_password, getValues().password)
        .then(res => {
          if (res) {
            props.onAccountSaveClick(R.dissoc('password_confirm', data))
          }
        })

      return
    }

    props.onAccountSaveClick(R.dissoc('password_confirm', data))
  }

  useEffect(() => {
    loadValues()

    if (props.password_error) {
      // setEmailError(props.email_error);
      setPasswordError(props.password_error)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {/* <div className={classes.inputWrapper}>
          <TextInput
            name='email'
            label='Email'
            label_outside_input={true}
            onChange={() => { setEmailError(null); }}
            ref={register({
              required: 'The email field is a required field',
              pattern: {
                value: regex.email,
                message: 'You have entered an invalid email address. Please check your entry, and correct the error.'
              }
            })}
            error={emailError || errors.email?.message}
          />
        </div> */}
        <div className={classes.formFieldsWrapper}>
          <div className={classes.inputWrapper}>
            <TextInput
              name="password"
              label="Enter New Password"
              placeholderHtml={
                props.social && (
                  <div className={classes.externalProviderWrapper}>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className={classes.externalProviderCross}
                      size="lg"
                    />
                    <div className={classes.externalProviderText}>
                      You have logged in via an external provider
                    </div>
                  </div>
                )
              }
              label_outside_input={true}
              type="password"
              disabled={props.social}
              onChange={() => {
                setPasswordError(null)
              }}
              ref={register({
                pattern: {
                  value: regex.password,
                  message:
                    'The password must be at least 10 characters, have at least one of each of the following: upper-case letter (A-Z), lower-case letter (a-z), number (0-9), and special ASCII character.',
                },
              })}
              error={passwordError || errors.password?.message}
            />
          </div>
          {!props.social && (
            <div className={classes.inputWrapper}>
              <TextInput
                name="password_confirm"
                label="Confirm New Password"
                label_outside_input={true}
                type="password"
                disabled={props.social}
                ref={register({
                  validate: value => {
                    return (
                      value === watch('password') ||
                      'The passwords you entered do not match. Please re-enter both passwords, and try again.'
                    )
                  },
                })}
                error={errors.password_confirm?.message}
              />
            </div>
          )}
          {watch('password') && (
            <div className={classes.inputWrapper}>
              <TextInput
                name="old_password"
                label="Enter Old Password"
                label_outside_input={true}
                type="password"
                ref={register({
                  required:
                    'The old password must be confirmed when changing email or entering a new password',
                })}
                error={errors.old_password?.message}
              />
            </div>
          )}
          <div className={classes.inputWrapper}>
            <Controller
              as={AutocompleteInput}
              options={timezones}
              label_outside_select={true}
              label="Time Zone"
              name="timezone"
              defaultValue={timezone}
              value={timezone}
              searchText={timezone}
              control={control}
              onChange={setTimezone}
              onInputChange={setTimezone}
              rules={{ required: 'The timezone field is required' }}
              error={errors.timezone?.message}
            />
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <CancelButton
            className={classes.cancelButton}
            onClick={() => onCancel()}
          >
            Cancel
          </CancelButton>
          <BrandButton
            variant="contained"
            className={classes.saveButton}
            type="submit"
          >
            Save
          </BrandButton>
        </div>
      </form>
    </div>
  )
}
