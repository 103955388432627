import { Box } from '@mui/material'
import VoteUpDown, { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import { ensureHttpProtocol, extractHostname } from 'utils/urlUtils'
import theme from '../../../mui-theme'
import { NewsPostItem } from '../interfaces/newsTabItem'
import useStyles from './styles'
import { PostIcons } from 'components/PostIcons/PostIcons'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import TickerImage from 'components/common/TickerImage'

export interface NewsPostProps {
  onVote: (direction: VoteDirection) => void
  newsPostClick: (type: string, symbol: string, newsPostId: string) => void
  newsPost: NewsPostItem
  onPostClick: (id: string, symbol: string) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
}

export const getTickerUrl = (ticker?: string): string => {
  return '/asset/' + ticker!.toLowerCase()
}

export default forwardRef<HTMLInputElement, NewsPostProps>((props, ref) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const clickPost = () => {
    props.newsPostClick(
      props.newsPost.post.assetType.toLowerCase(),
      props.newsPost.post.symbol!.toLowerCase(),
      props.newsPost.post.id
    )
  }

  const handleUsernameClick = () => {
    navigate(`/user/${props.newsPost.post.username}`)
  }

  return (
    <div className={classes.wrapper}>
      <Box
        sx={{
          flex: 1,
          width: 'calc(100% - 93px)',
          alignSelf: 'center',
        }}
      >
        <div onClick={clickPost} className={classes.symbolDetails}>
          <TickerImage
            symbol={props.newsPost.symbolName ?? props.newsPost.post.symbol}
          />
          <div className={classes.symbolTitle}>
            {props.newsPost.post.symbolName}
          </div>
        </div>
        <div onClick={clickPost} className={classes.title}>
          {props.newsPost.post.title}
        </div>
        {props.newsPost.post.url && (
          <a
            className={classes.url}
            href={ensureHttpProtocol(props.newsPost.post.url)}
            rel="noreferrer"
            target="_blank"
          >
            ({extractHostname(props.newsPost.post.url)})
          </a>
        )}
        {!!props.newsPost.post.image && !!props.newsPost.post.url && (
          <div className={classes.imageWrapper}>
            <a
              className={classes.image}
              href={props.newsPost.post.url!}
              rel="noreferrer"
              target="_blank"
            >
              <img
                className={classes.image}
                src={props.newsPost.post.image}
                loading="lazy"
                alt="Post Image"
              />
            </a>
          </div>
        )}

        <div className={classes.usernameSymbolWrapper}>
          <div className={classes.username} onClick={handleUsernameClick}>
            {props.newsPost.post.username}
          </div>
          <a
            className={classes.symbol}
            href={getTickerUrl(props.newsPost.post.symbol!)}
            // rel="noreferrer"
            // target="_blank"
          >
            ${props.newsPost.post.symbol}
          </a>
          <div className={classes.createdAt}>
            {getTimeElapsedRounded(
              props.newsPost.date,
              props.newsPost.post.createdAt
            )}
          </div>
        </div>
        {/* {deviceType === DeviceType.Desktop && ( */}
        <div className={classes.postIconsWrapper}>
          <PostIcons
            post={props.newsPost.post}
            numberOfReplies={props.newsPost.post.numberOfReplies || 0}
            onCommentClick={(id: string) => {
              props.onPostClick(id, props.newsPost.post.symbol!)
            }}
            onTopicBookmarkClick={() => {}}
            onMoreActionsClick={(id: string, action: string | number) =>
              props.onMoreActionsClick(
                id,
                props.newsPost.post.symbol!,
                props.newsPost.post.assetType,
                action
              )
            }
            onShareActionsClick={(id: string, action: string | number) =>
              props.onShareActionsClick(
                id,
                props.newsPost.post.title,
                props.newsPost.post.symbol!,
                action
              )
            }
            onCameraActionsClick={(
              topicInfo: PostData,
              action: string | number
            ) => {
              props.onCameraActionsClick(topicInfo, action)
            }}
            isCreator={props.newsPost.isCreator}
          />
        </div>
        {/* )} */}
      </Box>

      <Box sx={{ alignSelf: 'center' }}>
        <VoteUpDown
          numVotes={props.newsPost.post.voteNum || 0}
          largeText={true}
          onVoteDownClick={() => props.onVote(VoteDirection.down)}
          onVoteUpClick={() => props.onVote(VoteDirection.up)}
          voteDirection={props.newsPost.post.userVoteDirection}
        />
      </Box>
    </div>
  )
})
