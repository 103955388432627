import useStyles from './styles'
import theme from '../../../mui-theme'
import { forwardRef, useEffect } from 'react'
import React from 'react'
import { Button, Tab, Tabs } from '@mui/material'
import classNames from 'classnames'
import WatchedStarUnselected from 'components/Images/WatchedStarUnselected'
import WatchedStarSelected from 'components/Images/WatchedStarSelected'
import { SymbolTabItem } from '../interfaces/symbolTabItem'
import { NewsTabItem } from '../interfaces/newsTabItem'
import NewsPost from '../NewsPost'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { convertVoteDirection } from 'utils/voteUtils'
import { formatWithEllipsis } from 'utils/stringUtils'
import NewsFeedItem from '../NewsFeedItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TabVisibility } from '..'
import { TabIdentifiers } from '../constants/tabConstants'
import { PostLoading } from 'components/SearchOverlay/PostLoading'
import PullToRefresh from 'react-simple-pull-to-refresh'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { IPricePredictionResultNotification } from 'types/notification'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import TickerImage from 'components/common/TickerImage'
import { useMarkNotificationsMutation } from 'generated/graphql'

interface SearchResultsMobilePopupProps {
  currentUserId: string | undefined
  trendingTab: SymbolTabItem
  resultsTab: SymbolTabItem
  watchListTab: SymbolTabItem
  mostWatchedTab: SymbolTabItem
  feedNewsTab: NewsTabItem
  trendingNewsTab: NewsTabItem
  latestNewsTab: NewsTabItem
  selectedTab: TabIdentifiers | null
  hasMoreFeedItems: boolean
  hasMoreNewestPosts: boolean
  tabVisibility: TabVisibility
  latestPricePredictionNotification: IPricePredictionResultNotification | null
  isPricePredictionRoundVotingOpen: boolean | undefined
  handleRefresh: () => Promise<any>
  loadMoreFeedItems: () => void
  loadMoreLatestNews: () => void
  resultClick: (type: string, symbol: string) => void
  watchedClick: (symbol: string) => void
  setSelectedTab: (newSelectedTab: TabIdentifiers) => void
  newsClick: (type: string, symbol: string, newsPostId: string) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  onPostClick: (id: string, symbol: string) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
  onVote: (
    direction: VoteDirection,
    id: string,
    userVotePositive?: boolean | null
  ) => void
  onCommentVote: (
    direction: VoteDirection,
    id: string,
    userVotePositive?: boolean | null
  ) => void
}

export default forwardRef<HTMLInputElement, SearchResultsMobilePopupProps>(
  (props, ref) => {
    const classes = useStyles(theme)
    const navigate = useNavigate()

    const [markNotifications] = useMarkNotificationsMutation()

    const handleTabChange = (
      _event: React.ChangeEvent<{}>,
      newSelectedTab: TabIdentifiers
    ) => {
      props.setSelectedTab(newSelectedTab)
    }

    // Function to determine if the selected tab is a SymbolTabItem
    const isSymbolTab = (tab: TabIdentifiers): boolean => {
      return (
        tab === TabIdentifiers.TRENDING ||
        tab === TabIdentifiers.RESULTS ||
        tab === TabIdentifiers.WATCHLIST ||
        tab === TabIdentifiers.MOST_WATCHED
      )
    }

    // Function to determine if the selected tab is a NewsTabItem
    const isTrendingNewsTab = (tab: TabIdentifiers): boolean => {
      return tab === TabIdentifiers.TRENDING_NEWS
    }

    // Function to determine if the selected tab is a NewsTabItem
    const isLatestNewsTab = (tab: TabIdentifiers): boolean => {
      return tab === TabIdentifiers.LATEST_NEWS
    }

    // Function to determine if the selected tab is the Feed News tab
    const isFeedNewsTab = (tab: TabIdentifiers): boolean => {
      return tab === TabIdentifiers.FEED
    }

    // Cast getSelectedTab to SymbolTabItem
    const getSymbolTab = () => getSelectedTab() as SymbolTabItem

    // Cast getSelectedTab to NewsTabItem
    const getNewsTab = () => getSelectedTab() as NewsTabItem

    const getSelectedTab = () => {
      switch (props.selectedTab) {
        case TabIdentifiers.FEED:
          return props.feedNewsTab
        case TabIdentifiers.TRENDING:
          return props.trendingTab
        case TabIdentifiers.RESULTS:
          return props.resultsTab
        case TabIdentifiers.WATCHLIST:
          return props.watchListTab
        case TabIdentifiers.MOST_WATCHED:
          return props.mostWatchedTab
        case TabIdentifiers.TRENDING_NEWS:
          return props.trendingNewsTab
        case TabIdentifiers.LATEST_NEWS:
          return props.latestNewsTab
        default:
          return props.trendingTab
      }
    }

    // Update selected tab when visibility changes
    useEffect(() => {
      if (!!props.selectedTab && !props.tabVisibility[props.selectedTab]) {
        props.setSelectedTab(TabIdentifiers.FEED)
      }
    }, [props.tabVisibility, props.selectedTab])

    useEffect(() => {
      if (props.selectedTab === null) {
        props.setSelectedTab(TabIdentifiers.FEED)
      }
    }, [props.selectedTab])

    return (
      <div className={classes.wrapper}>
        <Tabs
          value={props.selectedTab}
          className={classes.tabs}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="user settings tabs"
          sx={{
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          }}
          classes={{
            indicator: classes.selectedTabIndicator,
          }}
        >
          {props.tabVisibility[TabIdentifiers.FEED] && (
            <Tab
              key={'0'}
              value={TabIdentifiers.FEED}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.FEED
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.feedNewsTab.label}</div>}
            />
          )}
          {props.tabVisibility[TabIdentifiers.TRENDING] && (
            <Tab
              key={'1'}
              value={TabIdentifiers.TRENDING}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.TRENDING
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.trendingTab.label}</div>}
            />
          )}
          {props.tabVisibility[TabIdentifiers.RESULTS] && (
            <Tab
              key={'2'}
              value={TabIdentifiers.RESULTS}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.RESULTS
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.resultsTab.label}</div>}
            />
          )}
          {props.tabVisibility[TabIdentifiers.WATCHLIST] && (
            <Tab
              key={'3'}
              value={TabIdentifiers.WATCHLIST}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.WATCHLIST
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.watchListTab.label}</div>}
            />
          )}
          {props.tabVisibility[TabIdentifiers.MOST_WATCHED] && (
            <Tab
              key={'4'}
              value={TabIdentifiers.MOST_WATCHED}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.MOST_WATCHED
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.mostWatchedTab.label}</div>}
            />
          )}
          {props.tabVisibility[TabIdentifiers.TRENDING_NEWS] && (
            <Tab
              key={'5'}
              value={TabIdentifiers.TRENDING_NEWS}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.TRENDING_NEWS
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.trendingNewsTab.label}</div>}
            />
          )}
          {props.tabVisibility[TabIdentifiers.LATEST_NEWS] && (
            <Tab
              key={'6'}
              value={TabIdentifiers.LATEST_NEWS}
              className={classNames(
                classes.tab,
                props.selectedTab === TabIdentifiers.LATEST_NEWS
                  ? classes.selectedTab
                  : ''
              )}
              label={<div>{props.latestNewsTab.label}</div>}
            />
          )}
        </Tabs>
        <div className={classes.body} id="body">
          <>
            {!!props.selectedTab &&
              isSymbolTab(props.selectedTab) &&
              getSymbolTab().options.map((item, i) => (
                <div
                  className={classes.resultRow}
                  key={i}
                  onClick={() => props.resultClick(item.type, item.symbol)}
                >
                  <div className={classes.key}>
                    <TickerImage symbol={item.symbol} />
                    <span className={classes.keyText}>{item.symbol}</span>
                    <span
                      className={classes.keyIcon}
                      onClick={_event => {
                        props.watchedClick(item.symbol)
                        _event.stopPropagation()
                      }}
                    >
                      {!item.watched && <WatchedStarUnselected />}
                      {item.watched && <WatchedStarSelected />}
                    </span>
                  </div>
                  <div className={classes.value}>
                    {formatWithEllipsis(item.name, 30)}
                  </div>
                </div>
              ))}
            {props.selectedTab === TabIdentifiers.RESULTS &&
              (getSelectedTab() as SymbolTabItem).options.length === 0 && (
                <div className={classes.noResultsWrapper}>
                  <div className={classes.noResultsTitle}>No Results</div>
                  <div className={classes.noResultsBody}>
                    We couldn't find any results matching that stock symbol.
                    Please double-check the symbol and try again.
                  </div>
                </div>
              )}
            {!!props.selectedTab && isTrendingNewsTab(props.selectedTab) && (
              <PullToRefresh onRefresh={props.handleRefresh}>
                <>
                  {getNewsTab().newsPosts?.map((post, i) => (
                    <NewsPost
                      key={i}
                      newsPost={post}
                      newsPostClick={props.newsClick}
                      onVote={direction => {
                        props.onVote(
                          direction,
                          post.post.id,
                          post.post.userVoteDirection !== null
                            ? convertVoteDirection(post.post.userVoteDirection!)
                            : undefined
                        )
                      }}
                      onShareActionsClick={props.onShareActionsClick}
                      onCameraActionsClick={props.onCameraActionsClick}
                      onMoreActionsClick={props.onMoreActionsClick}
                      onPostClick={props.onPostClick}
                    />
                  ))}
                </>
              </PullToRefresh>
            )}
            {!!props.selectedTab && isLatestNewsTab(props.selectedTab) && (
              <PullToRefresh onRefresh={props.handleRefresh}>
                <InfiniteScroll
                  dataLength={getNewsTab().newsPosts?.length ?? 0}
                  style={{ height: '100%' }}
                  next={() => {
                    props.loadMoreLatestNews()
                  }}
                  hasMore={props.hasMoreNewestPosts}
                  loader={
                    <div>
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                    </div>
                  }
                  scrollableTarget="body"
                  height="100%"
                >
                  {getNewsTab().newsPosts?.map((post, i) => (
                    <NewsPost
                      key={i}
                      newsPost={post}
                      newsPostClick={props.newsClick}
                      onVote={direction => {
                        props.onVote(
                          direction,
                          post.post.id,
                          post.post.userVoteDirection !== null
                            ? convertVoteDirection(post.post.userVoteDirection!)
                            : undefined
                        )
                      }}
                      onShareActionsClick={props.onShareActionsClick}
                      onCameraActionsClick={props.onCameraActionsClick}
                      onMoreActionsClick={props.onMoreActionsClick}
                      onPostClick={props.onPostClick}
                    />
                  ))}
                </InfiniteScroll>
              </PullToRefresh>
            )}
            {!!props.selectedTab && isFeedNewsTab(props.selectedTab) && (
              <PullToRefresh onRefresh={props.handleRefresh}>
                <InfiniteScroll
                  dataLength={getNewsTab().feedItems?.length ?? 0}
                  style={{ height: '100%' }}
                  next={() => {
                    console.log('load more feed items')
                    props.loadMoreFeedItems()
                  }}
                  hasMore={props.hasMoreFeedItems}
                  loader={
                    <div>
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                      <PostLoading />
                    </div>
                  }
                  scrollableTarget="body"
                  height="100%"
                >
                  {props.isPricePredictionRoundVotingOpen && (
                    <div className={classes.predictionResult}>
                      <div className={classes.predictionResultHeader}>
                        <img
                          className={classes.fnchartIcon}
                          src={'./media/fnchartRoundWhiteBack.png'}
                          alt="fnchartRoundWhiteBack"
                        />
                        <div className={classes.fnchartText}>fnchart</div>
                        <Button
                          className={classes.seeResultButton}
                          sx={{
                            '.MuiTouchRipple-root': {
                              display: 'none',
                            },
                          }}
                          onClick={() => {
                            navigate(ROUTES.PRICE_PREDICTION)
                          }}
                        >
                          Play game
                        </Button>
                      </div>
                      <div className={classes.resultIsHereText}>
                        Daily Challenge 🔥: Read the latest posts and predict
                        the stock price at the close of the upcoming trading
                        session.
                      </div>
                      <img
                        className={classes.predictResultHereFeedImage}
                        src={'./media/PredictPriceTomorrowFeed.png'}
                        alt="PredictResultHereFeed"
                        onClick={() => {
                          navigate(ROUTES.PRICE_PREDICTION)
                        }}
                      />
                    </div>
                  )}
                  {props.latestPricePredictionNotification && (
                    <div className={classes.predictionResult}>
                      <div className={classes.predictionResultHeader}>
                        <img
                          className={classes.fnchartIcon}
                          src={'./media/fnchartRoundWhiteBack.png'}
                          alt="fnchartRoundWhiteBack"
                        />
                        <div className={classes.fnchartText}>fnchart</div>
                        <Button
                          className={classes.seeResultButton}
                          sx={{
                            '.MuiTouchRipple-root': {
                              display: 'none',
                            },
                          }}
                          onClick={() => {
                            markNotifications({
                              variables: {
                                ids: [
                                  props.latestPricePredictionNotification
                                    ?.notificationId!,
                                ],
                                read: true,
                              },
                            })

                            navigate(
                              ROUTES.PRICE_PREDICTION_RESULT.replace(
                                ':roundId',
                                props.latestPricePredictionNotification
                                  ?.roundId!
                              )
                            )
                          }}
                        >
                          See result
                        </Button>
                      </div>
                      <div className={classes.resultIsHereText}>
                        Daily challenge 🔥 Result is here
                      </div>
                      <img
                        className={classes.predictResultHereFeedImage}
                        src={'./media/PredictResultHereFeed.png'}
                        alt="PredictResultHereFeed"
                      />
                    </div>
                  )}
                  {getNewsTab().feedItems?.map((feedItem, i) => (
                    <NewsFeedItem
                      currentUserId={props.currentUserId}
                      key={i}
                      index={i}
                      feedItem={feedItem}
                      onVote={(direction: VoteDirection) => {
                        props.onVote(
                          direction,
                          feedItem?.id!,
                          feedItem!.userVoteDirection !== null
                            ? convertVoteDirection(feedItem!.userVoteDirection!)
                            : undefined
                        )
                      }}
                      onCommentVote={(direction: VoteDirection) => {
                        props.onCommentVote(
                          direction,
                          // cast comment data to feed item data
                          feedItem?.id!,
                          feedItem!.userVoteDirection !== null
                            ? convertVoteDirection(feedItem!.userVoteDirection!)
                            : undefined
                        )
                      }}
                      onShareActionsClick={props.onShareActionsClick}
                      onMoreActionsClick={props.onMoreActionsClick}
                      onCameraActionsClick={props.onCameraActionsClick}
                      onCameraActionsCommentClick={
                        props.onCameraActionsCommentClick
                      }
                      onMoreCommentActionsClick={
                        props.onMoreCommentActionsClick
                      }
                      onPostClick={props.onPostClick}
                      onCommentClick={props.onCommentClick}
                    />
                  ))}
                </InfiniteScroll>
              </PullToRefresh>
            )}
          </>
        </div>
      </div>
    )
  }
)
