import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit'
import { faFlag } from '@fortawesome/free-regular-svg-icons/faFlag'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons/faMinusSquare'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons/faPlusSquare'
import {
  faArrowUpRightFromSquare,
  faCamera,
  faCopy,
  faDownload,
  faEnvelope,
  faLink,
  faReply,
  faShare,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import { colors, fonts } from 'constants/index'
import React from 'react'
import MenuWithOptions, { PopupMenuItem } from '../common/MenuWithOptions'
import CustomTooltip from 'components/common/CustomTooltip'
import { ShareActionsMenuItemIds } from 'utils/shareUtils'
import { CommentData } from 'components/common/CommentWithParent/Comment.interfaces'
import { CameraActionsMenuItemIds } from 'utils/cameraUtils'

export enum MoreActionsMenuItemIds {
  DELETE,
  EDIT,
  REPORT,
}

export const moreActionsMenuItems: PopupMenuItem[] = [
  {
    text: 'Report',
    id: MoreActionsMenuItemIds.REPORT,
    icon: faFlag,
  },
]

export const moreActionsMenuItemsCreator: PopupMenuItem[] = [
  {
    text: 'Delete',
    id: MoreActionsMenuItemIds.DELETE,
    icon: faWrench,
  },
  {
    text: 'Edit',
    id: MoreActionsMenuItemIds.EDIT,
    icon: faEdit,
  },
  {
    text: 'Report',
    id: MoreActionsMenuItemIds.REPORT,
    icon: faFlag,
  },
]

export const shareActionMenuItems: PopupMenuItem[] = [
  {
    text: 'Twitter',
    id: ShareActionsMenuItemIds.TWITTER,
    icon: faTwitter,
  },
  {
    text: 'Reddit',
    id: ShareActionsMenuItemIds.REDDIT,
    icon: faReddit,
  },
  {
    text: 'Facebook',
    id: ShareActionsMenuItemIds.FACEBOOK,
    icon: faFacebook,
  },
  {
    text: 'Gmail',
    id: ShareActionsMenuItemIds.GMAIL,
    icon: faGoogle,
  },
  {
    text: 'Email',
    id: ShareActionsMenuItemIds.EMAIL,
    icon: faEnvelope,
  },
]

export const cameraActionMenuItems: PopupMenuItem[] = [
  {
    text: 'Download image',
    id: CameraActionsMenuItemIds.DOWNLOAD_IMAGE,
    icon: faDownload,
  },
  {
    text: 'Copy image',
    id: CameraActionsMenuItemIds.COPY_IMAGE,
    icon: faCopy,
  },
  {
    text: 'Copy link',
    id: CameraActionsMenuItemIds.COPY_LINK,
    icon: faLink,
  },
  {
    text: 'Open in new tab',
    id: CameraActionsMenuItemIds.OPEN_IN_NEW_TAB,
    icon: faArrowUpRightFromSquare,
  },
]

interface Props {
  comment: CommentData
  numberOfReplies: number
  onCommentClick?: (postId: string, symbol: string, id: string) => void
  onTopicReplyClick?: (id: string) => void
  onShareActionsClick: (id: string, action: string | number) => void
  onCameraActionsClick: (
    commentWithParent: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (id: string, action: string | number) => void
  onExpand?: () => void
  isExpanded?: boolean
  isCreator: boolean
}

export const CommentIcons: React.FC<Props> = props => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {props.numberOfReplies > 0 && props.onExpand && (
        <CustomTooltip
          title={props.isExpanded ? 'Collapse Comments' : 'Expand Comments'}
        >
          <Box
            sx={{
              display: 'flex',
              marginRight: 4,
              cursor: 'pointer',
              color: colors.textGrey,
              transitionDuration: '50ms',

              '&:hover': {
                color: colors.text_white,
              },
            }}
            onClick={() => {
              props.onExpand!()
            }}
          >
            <FontAwesomeIcon
              icon={props.isExpanded ? faMinusSquare : faPlusSquare}
              color={colors.textGrey}
              size="sm"
            />
            <Box
              sx={{
                fontFamily: fonts.dm_sans,
                fontSize: 12,
                marginLeft: 1,
              }}
            >
              {props.numberOfReplies}
            </Box>
          </Box>
        </CustomTooltip>
      )}
      {props.onTopicReplyClick && (
        <CustomTooltip title="Reply">
          <Box
            sx={{
              display: 'flex',
              marginRight: 4,
              cursor: 'pointer',
              color: colors.textGrey,
              transitionDuration: '50ms',

              '&:hover': {
                color: colors.text_white,
              },
            }}
            onClick={() => {
              props.onTopicReplyClick!(props.comment.id)
            }}
          >
            <FontAwesomeIcon icon={faReply} size="sm" />
          </Box>
        </CustomTooltip>
      )}
      {props.onCommentClick && (
        <CustomTooltip title="Comments">
          <Box
            sx={{
              display: 'flex',
              marginRight: 4,
              cursor: 'pointer',
              color: colors.textGrey,
              transitionDelay: '50ms',

              '&:hover': {
                color: colors.text_white,
              },
            }}
            onClick={() => {
              props.onCommentClick!(
                props.comment.parentPost?.id!,
                props.comment.parentPost?.symbol!,
                props.comment.id
              )
            }}
          >
            <FontAwesomeIcon icon={faCommentAlt} size="sm" />
            {props.numberOfReplies > 0 && (
              <Box
                sx={{
                  fontFamily: fonts.dm_sans,
                  fontSize: 12,
                  marginLeft: 1,
                }}
              >
                {props.numberOfReplies}
              </Box>
            )}
          </Box>
        </CustomTooltip>
      )}
      <Box
        sx={{
          marginRight: 4,
          cursor: 'pointer',
        }}
      >
        <MenuWithOptions
          menuIcon={faShare}
          iconToolTip={'Share'}
          actionsMenuItems={shareActionMenuItems}
          onActionClick={action =>
            props.onShareActionsClick(props.comment.parentPost?.id!, action)
          }
        ></MenuWithOptions>
      </Box>
      <Box
        sx={{
          marginRight: 4,
          cursor: 'pointer',
        }}
      >
        <MenuWithOptions
          menuIcon={faCamera}
          iconToolTip={'Save'}
          actionsMenuItems={cameraActionMenuItems}
          onActionClick={action =>
            props.onCameraActionsClick(props.comment, action)
          }
        ></MenuWithOptions>
      </Box>
      <Box
        sx={{
          marginRight: 4,
          cursor: 'pointer',
        }}
      >
        <MenuWithOptions
          menuIcon={faEllipsisH}
          iconToolTip={'More Actions'}
          actionsMenuItems={
            props.isCreator ? moreActionsMenuItemsCreator : moreActionsMenuItems
          }
          onActionClick={action => {
            props.onMoreActionsClick(props.comment.id, action)
          }}
        ></MenuWithOptions>
      </Box>
    </Box>
  )
}
