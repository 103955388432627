import {
  ChildPostData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { ExDate } from '../../constants'
import { PostDataFragment, PostFeedDataFragment } from 'generated/graphql'
import { convertToVoteDirection } from 'utils/voteUtils'

export const mapPost = (
  post: PostDataFragment | PostFeedDataFragment
): PostData => {
  const userVoteDirection = convertToVoteDirection(post.userVotePositive)
  let textContent = ''

  if ('text' in post && post.text !== null && post.text !== undefined) {
    textContent = post.text
  } else if (
    'postText' in post &&
    post.postText !== null &&
    post.postText !== undefined
  ) {
    textContent = post.postText
  }

  let childPosts: ChildPostData[] = []

  if ('storyPosts' in post && post.storyPosts !== undefined) {
    childPosts = post.storyPosts.map((childPost: any) => ({
      noteText: childPost.noteText,
      childPost: mapPost(childPost.childPost!),
    }))
  }

  return {
    id: post.id,
    title: post.title,
    postType: post.postType,
    text: textContent,
    url: post.url,
    date: new ExDate(post.date),
    userId: post.user?.id,
    username: post.user?.nickname,
    emailHash: post.user?.emailHash,
    symbol: post.postSymbol ?? '',
    symbolName: post.symbol?.name ?? '',
    assetType: post.postAssetType ?? '',
    numberOfReplies: post.numberOfReplies,
    voteNum: post.votes,
    userVoteDirection: userVoteDirection,
    createdAt: new Date(post.createdAt),
    childPosts: childPosts,
    followersCount: post.user?.followersCount,
    followingCount: post.user?.followingCount,
    image: post.image,
  }
}

export const mapPosts = (posts: PostDataFragment[]): PostData[] => {
  if (!posts) {
    return []
  }

  return posts.map((post: PostDataFragment) => mapPost(post))
}
