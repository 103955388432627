import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { NAVIGATION_SIDEBAR_ACTION_NAMES } from './navigationConstants'

export const navigationMenuItems = [
  {
    icon: faChartColumn,
    text: 'Chart',
    id: NAVIGATION_SIDEBAR_ACTION_NAMES.CHART,
  },
]
