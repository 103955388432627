import { format, isSameDay } from 'date-fns'
import { colors } from '../../constants'
import {
  CandleStickData,
  Dimensions,
  DragStart,
  MarkerBoundary,
  OHLC,
  ViewWindow,
} from './interfaces'

// Utility function to get maximum and minimum prices from chart data
export const getMaxMinPrices = (chartData: CandleStickData[]) => {
  const prices = chartData.map(c => [c.high, c.low]).flat()
  return {
    maxPrice: Math.max(...prices),
    minPrice: Math.min(...prices),
  }
}

// Normalize price data
export const normalizePrice = (
  price: number,
  minPrice: number,
  maxPrice: number
) => {
  return (price - minPrice) / (maxPrice - minPrice)
}

// scaleY utility function
export const scaleY = (
  price: number,
  visibleMaxPrice: number,
  visibleMinPrice: number,
  canvasHeight: number
) => {
  const visiblePriceRange = visibleMaxPrice - visibleMinPrice
  const paddedHeight = canvasHeight - 2
  return ((visibleMaxPrice - price) / visiblePriceRange) * paddedHeight
}

// Calculate the width of each candlestick
export const getCandlestickWidth = (
  currentViewWindow: any,
  dimensions: Dimensions | null,
  horizontalPadding: number,
  rightPadding: number
) => {
  if (!dimensions) return 0

  const paddedWidth = dimensions.width - horizontalPadding - rightPadding
  return paddedWidth / (currentViewWindow.end - currentViewWindow.start)
}

// Draw a candlestick
export const drawCandlestick = (
  ctx: CanvasRenderingContext2D,
  candle: CandleStickData,
  xPosition: number,
  candleWidth: number,
  isHovered: boolean,
  isSelected: boolean,
  canvasHeight: number,
  scaleYFunction: (price: number) => number,
  isHighlighted?: boolean
): void => {
  const { open, close, high, low } = candle

  // Calculate Y positions using the scaleY function
  const yOpen = scaleYFunction(open)
  const yClose = scaleYFunction(close)
  const yHigh = scaleYFunction(high)
  const yLow = scaleYFunction(low)

  // Draw the wick
  ctx.beginPath()
  ctx.moveTo(xPosition + candleWidth / 2, yLow)
  ctx.lineTo(xPosition + candleWidth / 2, yHigh)
  ctx.strokeStyle = open > close ? colors.error : colors.blue
  ctx.stroke()

  // Draw the body
  ctx.fillStyle = open > close ? colors.error : colors.blue
  ctx.fillRect(
    xPosition,
    Math.min(yOpen, yClose),
    candleWidth,
    Math.max(1, Math.abs(yOpen - yClose))
  )

  if (isHighlighted) {
    // Apply highlighting styling
    ctx.fillStyle = 'rgba(154, 164, 245, 0.15)'
    ctx.fillRect(xPosition, 0, candleWidth, canvasHeight)
  }

  if (isHovered) {
    ctx.fillStyle = 'rgba(154, 164, 245, 0.15)' // Semi-transparent blue
    ctx.fillRect(xPosition, 0, candleWidth, canvasHeight)
  }

  if (isSelected) {
    ctx.fillStyle = 'rgba(181, 127, 127, 0.15)' // Semi-transparent red
    ctx.fillRect(xPosition, 0, candleWidth, canvasHeight)
  }
}

export const drawPriceLabels = (
  ctx: CanvasRenderingContext2D,
  dimensions: Dimensions | null,
  visibleMaxPrice: number,
  visibleMinPrice: number,
  horizontalPadding: number,
  scaleYFunction: (price: number) => number
): void => {
  if (!dimensions) return

  const { height } = dimensions
  const topMargin = 10
  const bottomMargin = 22
  const minSpaceBetweenLabels = 30
  const effectiveHeight = height - topMargin - bottomMargin
  const maxNumberOfLabels = Math.floor(effectiveHeight / minSpaceBetweenLabels)
  const priceInterval = Math.max(
    1,
    Math.ceil((visibleMaxPrice - visibleMinPrice) / maxNumberOfLabels)
  )

  const leftAxisLineX = horizontalPadding
  ctx.beginPath()
  ctx.moveTo(leftAxisLineX, topMargin)
  ctx.lineTo(leftAxisLineX, height - bottomMargin)
  ctx.strokeStyle = 'white'
  ctx.fillStyle = 'white'
  ctx.stroke()

  let labelPrice = visibleMinPrice
  while (labelPrice <= visibleMaxPrice) {
    const roundedPrice = Math.round(labelPrice)
    const y = scaleYFunction(roundedPrice)

    if (y > topMargin && y < height - bottomMargin) {
      ctx.fillText(`${roundedPrice}`, leftAxisLineX - 19, y + 3)
      ctx.beginPath()
      ctx.moveTo(leftAxisLineX - 5, y)
      ctx.lineTo(leftAxisLineX, y)
      ctx.stroke()
    }
    labelPrice += priceInterval
  }
}

export const handleMouseMove = (
  event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  setMouseX: React.Dispatch<React.SetStateAction<number | null>>,
  setMouseY: React.Dispatch<React.SetStateAction<number | null>>,
  chartData: CandleStickData[],
  dimensions: Dimensions | null,
  horizontalPadding: number,
  rightPadding: number,
  viewWindowRef: React.MutableRefObject<ViewWindow>,
  isDragging: boolean,
  dragStart: DragStart,
  setViewWindow: React.Dispatch<React.SetStateAction<ViewWindow>>,
  setHoveredCandlestick: React.Dispatch<
    React.SetStateAction<CandleStickData | null>
  >,
  totalCandlesticks: number,
  visibleData: CandleStickData[],
  markerBoundsRef: React.RefObject<MarkerBoundary[]>,
  handleLoadBefore: (
    onLoadBefore: () => Promise<void>,
    setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>,
  handleLoadAfter: (
    onLoadAfter: () => Promise<void>,
    setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>,
  setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>,
  loadingDirection: string | null,
  setLoadingDirection: React.Dispatch<React.SetStateAction<string | null>>,
  setClickDuration: React.Dispatch<React.SetStateAction<number | null>>,
  onLoadBefore?: () => Promise<void>,
  onLoadAfter?: () => Promise<void>
) => {
  if (!dimensions) return

  if (!onLoadBefore || !onLoadAfter) {
    return
  }

  const canvas = canvasRef.current
  const currentViewWindow = viewWindowRef.current
  if (!canvas) return

  const ctx = canvas.getContext('2d')
  if (!ctx) return

  const rect = canvas.getBoundingClientRect()
  const candleWidth = getCandlestickWidth(
    currentViewWindow,
    dimensions,
    horizontalPadding,
    rightPadding
  )
  const mouseX = event.clientX - rect.left
  const mouseY = event.clientY - rect.top

  setMouseY(event.clientY - rect.top)
  setMouseY(mouseY)

  if (isDragging) {
    setClickDuration(Number.MAX_SAFE_INTEGER)
    // Calculate the amount of drag in terms of candlesticks
    const deltaX = mouseX - dragStart.x
    const deltaCandles = Math.round(deltaX / candleWidth)

    // Calculate the new start and end indices for the view window
    let newStart = Math.max(0, dragStart.start - deltaCandles)
    let newEnd = newStart + (currentViewWindow.end - currentViewWindow.start)

    // Adjust the view window if the end index goes beyond the total candlesticks
    if (newEnd > totalCandlesticks) {
      newEnd = totalCandlesticks
      newStart = newEnd - (currentViewWindow.end - currentViewWindow.start)
    }

    // Update the view window state
    setViewWindow({ start: newStart, end: newEnd })

    const currentYearInView = getCurrentYearInView(chartData, currentViewWindow)
    if (currentYearInView) {
      const previousYear = currentYearInView - 1
      const followingYear = currentYearInView + 1

      if (
        !isDataLoadedForYear(chartData, previousYear) &&
        loadingDirection !== 'newer'
      ) {
        setLoadingDirection('older')
        handleLoadBefore(onLoadBefore!, () => {
          setLoadingNewData(false)
          // After loading the previous year, check if the next year needs to be loaded
          if (
            !isDataLoadedForYear(chartData, followingYear) &&
            loadingDirection !== 'older'
          ) {
            handleLoadAfter(onLoadAfter!, () => {
              setLoadingNewData(false)
            })
          }
          setLoadingDirection(null)
        })
      } else if (
        !isDataLoadedForYear(chartData, followingYear) &&
        loadingDirection !== 'older'
      ) {
        setLoadingDirection('newer')
        handleLoadAfter(onLoadAfter, () => {
          setLoadingNewData(false)
          setLoadingDirection(null)
        })
      }
    }
  } else {
    const isOverMarker = markerBoundsRef.current?.some(
      marker =>
        mouseX >= marker.x &&
        mouseX <= marker.x + marker.width &&
        mouseY >= marker.y &&
        mouseY <= marker.y + marker.height
    )

    if (!isOverMarker) {
      setMouseX(mouseX)
      setMouseY(mouseY)

      const paddedWidth = dimensions.width - horizontalPadding - rightPadding
      const totalVisibleCandlesticks =
        currentViewWindow.end - currentViewWindow.start
      const effectiveCandlestickWidth = paddedWidth / totalVisibleCandlesticks

      let hoverIndex = Math.floor(
        (mouseX - horizontalPadding) / effectiveCandlestickWidth
      )
      hoverIndex = Math.max(0, hoverIndex) + currentViewWindow.start

      if (
        hoverIndex >= currentViewWindow.start &&
        hoverIndex < currentViewWindow.end
      ) {
        const dataIndex = hoverIndex - currentViewWindow.start
        if (dataIndex < visibleData.length) {
          const hoveredCandle = visibleData[dataIndex]
          setHoveredCandlestick(hoveredCandle)
          // Find the middle X position of the hovered candlestick for the vertical line
          const xPosition =
            horizontalPadding + dataIndex * effectiveCandlestickWidth
          const middleX = xPosition + effectiveCandlestickWidth / 2 - 1
          setMouseX(middleX)
        } else {
          setHoveredCandlestick(null)
          setMouseX(null)
        }
      } else {
        setHoveredCandlestick(null)
        setMouseX(null)
      }
    } else {
      // When over a marker, ensure no candlestick is set as hovered
      setHoveredCandlestick(null)
      setMouseX(null)
      setMouseY(null)
    }
  }
}

export const drawVerticalDottedLine = (
  ctx: CanvasRenderingContext2D,
  x: number,
  canvasHeight: number
) => {
  ctx.beginPath()
  ctx.setLineDash([5, 3])
  ctx.moveTo(x, 0)
  ctx.lineTo(x, canvasHeight)
  ctx.strokeStyle = '#FFFFFF'
  ctx.stroke()
  ctx.setLineDash([])
}

export const drawHorizontalDottedLine = (
  ctx: CanvasRenderingContext2D,
  y: number,
  canvasWidth: number
) => {
  ctx.beginPath()
  ctx.setLineDash([5, 3])
  ctx.moveTo(0, y)
  ctx.lineTo(canvasWidth, y)
  ctx.strokeStyle = '#FFFFFF'
  ctx.stroke()
  ctx.setLineDash([])
}

// Checks if data is loaded for a specific year
export const isDataLoadedForYear = (
  chartData: CandleStickData[],
  year: number
): boolean => {
  return chartData.some(candle => candle.date.getFullYear() === year)
}

// Gets the current year in view on the chart
export const getCurrentYearInView = (
  chartData: CandleStickData[],
  currentViewWindow: ViewWindow
): number | null => {
  if (
    chartData.length > 0 &&
    currentViewWindow.start >= 0 &&
    currentViewWindow.start < chartData.length
  ) {
    const firstVisibleCandlestickDate = chartData[currentViewWindow.start].date
    return firstVisibleCandlestickDate.getFullYear()
  }
  return null // Return null if there's no visible data or if the index is out of bounds
}

export const handleLoadBefore = async (
  onLoadBefore: () => Promise<void>,
  setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  setLoadingNewData(true)
  await onLoadBefore()
  setLoadingNewData(false)
}

export const handleLoadAfter = async (
  onLoadAfter: () => Promise<void>,
  setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  setLoadingNewData(true)
  await onLoadAfter()
  setLoadingNewData(false)
}

export const handleZoom = (
  zoomFactor: number,
  chartData: CandleStickData[],
  currentDimensions: Dimensions,
  currentViewWindow: ViewWindow,
  candlestickWidth: number,
  zoomLevel: number,
  setViewWindow: React.Dispatch<React.SetStateAction<ViewWindow>>,
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>,
  drawChart: (viewWindow: ViewWindow) => void
) => {
  const newZoomLevel = zoomLevel * zoomFactor

  // Calculate the minimum zoom level to fit all candlesticks
  const minZoomLevel =
    currentDimensions.width / (chartData.length * candlestickWidth)

  // Prevent zooming out too far
  if (newZoomLevel < minZoomLevel || newZoomLevel < 0.4) {
    return
  }

  // Calculate the center of the current view window
  const centerIndex = Math.floor(
    (currentViewWindow.start + currentViewWindow.end) / 2
  )

  // Calculate the new window width based on the current zoom level and canvas width
  const newWindowWidth = Math.floor(
    currentDimensions.width / (candlestickWidth * newZoomLevel)
  )

  let newStart = Math.max(0, centerIndex - Math.floor(newWindowWidth / 2))
  let newEnd = newStart + newWindowWidth

  // Adjust the start and end to ensure they are within the data range
  newStart = Math.max(0, newStart)
  newEnd = Math.min(chartData.length, newEnd)

  setViewWindow({ start: newStart, end: newEnd })
  setZoomLevel(newZoomLevel)

  // Redraw the chart with the new view window
  drawChart({ start: newStart, end: newEnd })
}

export const drawMonthLabels = (
  ctx: CanvasRenderingContext2D,
  visibleData: CandleStickData[],
  canvasWidth: number,
  canvasHeight: number,
  horizontalPadding: number,
  rightPadding: number,
  viewWindow: ViewWindow,
  dimensions: Dimensions | null
) => {
  let lastMonth = -1
  ctx.fillStyle = 'white'
  ctx.font = '12px Arial'
  ctx.textAlign = 'center'

  const candlestickWidth = getCandlestickWidth(
    viewWindow,
    dimensions,
    horizontalPadding,
    rightPadding
  )

  const labelGap = 17
  const lineHeight = 5
  const lineWidth = 1

  // Draw horizontal line across the bottom
  ctx.beginPath()
  ctx.moveTo(horizontalPadding, canvasHeight - labelGap - lineHeight)
  ctx.lineTo(canvasWidth - rightPadding, canvasHeight - labelGap - lineHeight)
  ctx.strokeStyle = 'white'
  ctx.lineWidth = lineWidth
  ctx.stroke()

  for (let i = 0; i < visibleData.length; i++) {
    const candle = visibleData[i]
    const month = candle.date.getMonth()

    if (month !== lastMonth) {
      const xPosition =
        horizontalPadding + i * candlestickWidth + candlestickWidth / 2

      if (
        xPosition - candlestickWidth / 2 >= horizontalPadding &&
        xPosition + candlestickWidth / 2 <= canvasWidth - rightPadding
      ) {
        ctx.beginPath()
        ctx.moveTo(xPosition, canvasHeight - labelGap - lineHeight)
        ctx.lineTo(xPosition, canvasHeight - labelGap)
        ctx.stroke()
        ctx.fillText(
          candle.date.toLocaleString('default', { month: 'short' }),
          xPosition,
          canvasHeight - labelGap + 10
        )
      }

      lastMonth = month
    }
  }
}

export const drawDateLabel = (
  ctx: CanvasRenderingContext2D,
  candle: CandleStickData,
  visibleData: CandleStickData[],
  canvasWidth: number,
  canvasHeight: number,
  horizontalPadding: number,
  rightPadding: number,
  viewWindow: ViewWindow,
  dimensions: Dimensions | null
) => {
  if (!dimensions) return

  const candleIndex = visibleData.indexOf(candle)

  if (candleIndex === -1) {
    return
  }

  const label = format(candle.date, 'yyyy-MM-dd')
  const paddedWidth = dimensions.width - horizontalPadding - rightPadding
  const candlestickWidth = paddedWidth / (viewWindow.end - viewWindow.start)

  const xPosition =
    horizontalPadding + candleIndex * candlestickWidth + candlestickWidth / 2

  const yPosition = canvasHeight - 30

  // Ensure the label does not extend beyond the canvas bounds
  const labelWidth = 70
  let adjustedXPosition = xPosition
  if (xPosition - labelWidth / 2 < horizontalPadding) {
    adjustedXPosition = horizontalPadding + labelWidth / 2
  } else if (xPosition + labelWidth / 2 > canvasWidth - rightPadding) {
    adjustedXPosition = canvasWidth - rightPadding - labelWidth / 2
  }

  ctx.fillStyle = colors.textGrey
  ctx.fillRect(
    adjustedXPosition - labelWidth / 2,
    yPosition + 8,
    labelWidth,
    20
  )

  ctx.fillStyle = colors.text_white
  ctx.font = '12px Arial'
  ctx.textAlign = 'center'
  ctx.fillText(label, adjustedXPosition, yPosition + 22)
}

export const handleCanvasClick = (
  event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  viewWindowRef: React.MutableRefObject<ViewWindow>,
  dimensions: Dimensions | null,
  horizontalPadding: number,
  rightPadding: number,
  visibleData: CandleStickData[],
  clickDuration: number | null,
  setClickDuration: React.Dispatch<React.SetStateAction<number | null>>,
  onChartClick?: (selectedItem: CandleStickData) => void,
  markerBoundsRef?: React.RefObject<
    Array<{
      x: number
      y: number
      width: number
      height: number
      candle: CandleStickData
    }>
  >
) => {
  if (!dimensions) return

  const clickThreshold = 500

  if (clickDuration === null || clickDuration > clickThreshold) {
    setClickDuration(null)
    return
  }

  const canvas = canvasRef.current
  if (!canvas) return

  const rect = canvas.getBoundingClientRect()
  const mouseX = event.clientX - rect.left
  const mouseY = event.clientY - rect.top // Capture mouseY for vertical coordinate

  // Check if the click is within any marker icon's boundary
  const clickedMarker = markerBoundsRef?.current?.find(
    ({ x, y, width, height }) =>
      mouseX >= x && mouseX <= x + width && mouseY >= y && mouseY <= y + height
  )

  if (clickedMarker) {
    // If a marker was clicked, trigger the onChartClick with the associated candlestick data
    if (onChartClick) {
      onChartClick(clickedMarker.candle)
    }
  } else {
    // Proceed with the existing logic if the click was not on a marker
    const currentViewWindow = viewWindowRef.current
    const paddedWidth = dimensions.width - horizontalPadding - rightPadding
    const totalVisibleCandlesticks =
      currentViewWindow.end - currentViewWindow.start
    const effectiveCandlestickWidth = paddedWidth / totalVisibleCandlesticks

    // Align the click index calculation with the hover logic
    let clickIndex = Math.floor(
      (mouseX - horizontalPadding) / effectiveCandlestickWidth
    )
    clickIndex = Math.max(0, clickIndex) + currentViewWindow.start

    if (
      clickIndex >= currentViewWindow.start &&
      clickIndex < currentViewWindow.end
    ) {
      const dataIndex = clickIndex - currentViewWindow.start
      if (dataIndex < visibleData.length) {
        const selectedItem = visibleData[dataIndex]

        if (onChartClick) {
          onChartClick(selectedItem)
        }
      }
    }
  }

  setClickDuration(null) // Reset the click duration after handling the click
}

export const updateViewWindow = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  totalCandlesticks: number,
  selectedCandlestick: CandleStickData | undefined,
  candlestickWidth: number,
  viewWindowRef: React.MutableRefObject<ViewWindow | null>,
  setViewWindow: React.Dispatch<React.SetStateAction<ViewWindow>>,
  chartData: CandleStickData[]
) => {
  // Ensure canvas is available
  if (canvasRef.current) {
    const canvasWidth = canvasRef.current.clientWidth
    const viewSize = Math.floor(canvasWidth / candlestickWidth)

    // Default view logic if selectedCandlestick is not provided or not found
    let start = Math.max(0, totalCandlesticks - viewSize)
    let end = totalCandlesticks

    // If selectedCandlestick is provided, adjust start and end to center on it
    if (selectedCandlestick) {
      const selectedIndex = chartData.findIndex(candle =>
        isSameDay(candle.date, selectedCandlestick.date)
      )

      if (selectedIndex >= 0) {
        // Calculate new start and end to center the view on selectedCandlestick
        start = Math.max(0, selectedIndex - Math.floor(viewSize / 2))
        end = Math.min(totalCandlesticks, start + viewSize)
      }
    }

    setViewWindow({ start, end })
    viewWindowRef.current = { start, end }
  }
}

export const handleMouseDown = (
  event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  viewWindowRef: React.MutableRefObject<ViewWindow>,
  horizontalPadding: number,
  rightPadding: number,
  mouseDownTimeRef: React.MutableRefObject<number | null>,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  setDragStart: React.Dispatch<React.SetStateAction<DragStart>>
) => {
  const currentViewWindow = viewWindowRef.current
  const x = event.clientX - horizontalPadding - rightPadding
  mouseDownTimeRef.current = new Date().getTime()
  setIsDragging(true)
  setDragStart({ x: x, start: currentViewWindow.start })
}

export const handleWheel = (
  event: React.WheelEvent<HTMLCanvasElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  chartData: CandleStickData[],
  dimensionsRef: React.MutableRefObject<Dimensions | null>,
  viewWindowRef: React.MutableRefObject<ViewWindow>,
  candlestickWidth: number,
  zoomLevel: number,
  setViewWindow: React.Dispatch<React.SetStateAction<ViewWindow>>,
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>,
  handleZoom: (
    zoomIn: number,
    chartData: CandleStickData[],
    dimensions: Dimensions,
    viewWindow: ViewWindow,
    candlestickWidth: number,
    zoomLevel: number,
    setViewWindow: React.Dispatch<React.SetStateAction<ViewWindow>>,
    setZoomLevel: React.Dispatch<React.SetStateAction<number>>,
    drawChart: (viewWindow: ViewWindow) => void
  ) => void,
  drawChart: (viewWindow: ViewWindow) => void
) => {
  if (!dimensionsRef.current) return

  const canvas = canvasRef.current
  if (!canvas) return

  const rect = canvas.getBoundingClientRect()
  const mouseX = event.clientX - rect.left

  if (event.deltaY < 0) {
    handleZoom(
      1.2,
      chartData,
      dimensionsRef.current,
      viewWindowRef.current,
      candlestickWidth,
      zoomLevel,
      setViewWindow,
      setZoomLevel,
      drawChart
    )
  } else {
    handleZoom(
      0.8333,
      chartData,
      dimensionsRef.current,
      viewWindowRef.current,
      candlestickWidth,
      zoomLevel,
      setViewWindow,
      setZoomLevel,
      drawChart
    )
  }
}

export const handleMouseLeave = (
  isDragging: boolean,
  setMouseX: React.Dispatch<React.SetStateAction<number | null>>,
  setMouseY: React.Dispatch<React.SetStateAction<number | null>>,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  setHoveredCandlestick: React.Dispatch<
    React.SetStateAction<CandleStickData | null>
  >
) => {
  setMouseX(null)
  setMouseY(null)
  setHoveredCandlestick(null)

  if (isDragging) {
    setIsDragging(false)
  }
}

export const handleMouseUp = (
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  clickDuration: number | null,
  setClickDuration: React.Dispatch<React.SetStateAction<number | null>>,
  mouseDownTimeRef: React.MutableRefObject<number | null>
) => {
  const mouseUpTime = new Date().getTime()
  const duration = mouseDownTimeRef.current
    ? mouseUpTime - mouseDownTimeRef.current
    : 0
  mouseDownTimeRef.current = null
  setIsDragging(false)

  if (clickDuration !== Number.MAX_SAFE_INTEGER) {
    setClickDuration(duration)
  }
}

export const handleTouchStart = (
  event: React.TouchEvent<HTMLCanvasElement>,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  setDragStart: React.Dispatch<React.SetStateAction<DragStart>>,
  viewWindowRef: React.MutableRefObject<ViewWindow>,
  initialPinchDistance: React.MutableRefObject<number | null>
): void => {
  if (event.touches.length === 2) {
    event.preventDefault()
  }

  initialPinchDistance.current = null
  const touch = event.touches[0]
  const x = touch.clientX
  setIsDragging(true)
  setDragStart({ x: x, start: viewWindowRef.current.start })
}

export const handleTouchMove = (
  event: React.TouchEvent<HTMLCanvasElement>,
  isDragging: boolean,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  chartData: CandleStickData[],
  dimensions: Dimensions | null,
  dimensionsRef: React.MutableRefObject<Dimensions | null>,
  horizontalPadding: number,
  rightPadding: number,
  viewWindowRef: React.MutableRefObject<ViewWindow>,
  dragStart: DragStart,
  setViewWindow: React.Dispatch<React.SetStateAction<ViewWindow>>,
  setHoveredCandlestick: React.Dispatch<
    React.SetStateAction<CandleStickData | null>
  >,
  totalCandlesticks: number,
  visibleData: CandleStickData[],
  handleLoadBefore: (
    onLoadBefore: () => Promise<void>,
    setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>,
  setLoadingNewData: React.Dispatch<React.SetStateAction<boolean>>,
  loadingDirection: string | null,
  setLoadingDirection: React.Dispatch<React.SetStateAction<string | null>>,
  initialPinchDistance: React.MutableRefObject<number | null>,
  candlestickWidth: number,
  zoomLevel: number,
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>,
  drawChart: (viewWindow: ViewWindow) => void,
  onLoadBefore?: () => Promise<void>,
  onLoadAfter?: () => Promise<void>
): void => {
  if (!dimensions) return
  if (!dimensionsRef.current) return

  if (!onLoadBefore || !onLoadAfter) {
    return
  }

  event.preventDefault()
  if (!isDragging) return

  const touch = event.touches[0]

  if (event.touches.length === 2) {
    event.preventDefault()
    const touches = event.touches

    if (touches.length === 2) {
      const touch1 = touches[0]
      const touch2 = touches[1]
      const currentPinchDistance = Math.sqrt(
        Math.pow(touch2.clientX - touch1.clientX, 2) +
          Math.pow(touch2.clientY - touch1.clientY, 2)
      )

      if (initialPinchDistance.current === null) {
        initialPinchDistance.current = currentPinchDistance
      } else {
        const zoomFactor = currentPinchDistance / initialPinchDistance.current

        handleZoom(
          zoomFactor,
          chartData,
          dimensionsRef.current,
          viewWindowRef.current,
          candlestickWidth,
          zoomLevel,
          setViewWindow,
          setZoomLevel,
          drawChart
        )
      }
      return
    }
  }

  const rect = canvasRef.current?.getBoundingClientRect()
  if (!rect) return
  const currentViewWindow = viewWindowRef.current

  const mouseX = touch.clientX - rect.left
  const candleWidth = getCandlestickWidth(
    viewWindowRef.current,
    dimensions,
    horizontalPadding,
    rightPadding
  )

  // Calculate the amount of drag in terms of candlesticks
  const deltaX = mouseX - dragStart.x
  const deltaCandles = Math.round(deltaX / candleWidth)

  // Calculate the new start and end indices for the view window
  let newStart = Math.max(0, dragStart.start - deltaCandles)
  let newEnd =
    newStart + (viewWindowRef.current.end - viewWindowRef.current.start)

  // Adjust the view window if the end index goes beyond the total candlesticks
  if (newEnd > totalCandlesticks) {
    newEnd = totalCandlesticks
    newStart =
      newEnd - (viewWindowRef.current.end - viewWindowRef.current.start)
  }

  // Update the view window state
  setViewWindow({ start: newStart, end: newEnd })

  const currentYearInView = getCurrentYearInView(chartData, currentViewWindow)
  if (currentYearInView) {
    const previousYear = currentYearInView - 1
    const followingYear = currentYearInView + 1

    if (
      !isDataLoadedForYear(chartData, previousYear) &&
      loadingDirection !== 'newer'
    ) {
      setLoadingDirection('older')
      handleLoadBefore(onLoadBefore, () => {
        setLoadingNewData(false)
        // After loading the previous year, check if the next year needs to be loaded
        if (
          !isDataLoadedForYear(chartData, followingYear) &&
          loadingDirection !== 'older'
        ) {
          handleLoadAfter(onLoadAfter, () => {
            setLoadingNewData(false)
          })
        }
        setLoadingDirection(null) // Reset loading direction once done
      })
    } else if (
      !isDataLoadedForYear(chartData, followingYear) &&
      loadingDirection !== 'older'
    ) {
      setLoadingDirection('newer')
      handleLoadAfter(onLoadAfter, () => {
        setLoadingNewData(false)
        setLoadingDirection(null) // Reset loading direction once done
      })
    }
  }
}

export const handleTouchEnd = (
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  initialPinchDistance: React.MutableRefObject<number | null>
): void => {
  setIsDragging(false)
  initialPinchDistance.current = null
}

export const drawOHLC = (
  ctx: CanvasRenderingContext2D,
  ohlc: OHLC,
  x: number,
  y: number
): void => {
  const labelSpacing = 20
  const groupSpacing = 10
  ctx.font = '12px Arial'

  let currentX = x

  const drawLabelAndValue = (label: string, value: number, color: string) => {
    ctx.fillStyle = 'white'
    ctx.fillText(label, currentX, y)
    currentX += ctx.measureText(label).width + labelSpacing

    ctx.fillStyle = color
    ctx.fillText(value.toString(), currentX, y)
    currentX += ctx.measureText(value.toString()).width + groupSpacing
  }

  const valueColor =
    ohlc.close > ohlc.open
      ? 'rgba(154, 164, 245, 1)'
      : ohlc.close < ohlc.open
      ? 'rgba(181, 127, 127, 1)'
      : 'white'

  drawLabelAndValue('O:', ohlc.open, valueColor)
  drawLabelAndValue('H:', ohlc.high, valueColor)
  drawLabelAndValue('L:', ohlc.low, valueColor)
  drawLabelAndValue('C:', ohlc.close, valueColor)
}
