import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit'
import { faFlag } from '@fortawesome/free-regular-svg-icons/faFlag'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons/faMinusSquare'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons/faPlusSquare'
import {
  faCamera,
  faMessage,
  faShare,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import { colors, fonts } from 'constants/index'
import React, { useEffect } from 'react'
import MenuWithOptions, { PopupMenuItem } from '../common/MenuWithOptions'
import { ShareIconsMobile } from './ShareIconsMobile/ShareIconsMobile'
import CustomTooltip from 'components/common/CustomTooltip'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { CameraIconsMobile } from './CameraIconsMobile/CameraIconsMobile'
import { useNavigate } from 'react-router-dom'

export enum MoreActionsMenuItemIds {
  DELETE,
  EDIT,
  CAMERA,
  REPORT,
  SHARE,
  BOOKMARK,
}

export const moreActionsMenuItems: PopupMenuItem[] = [
  {
    text: 'Share',
    id: MoreActionsMenuItemIds.SHARE,
    icon: faShare,
  },
  {
    text: 'Camera',
    id: MoreActionsMenuItemIds.CAMERA,
    icon: faCamera,
  },
  {
    text: 'Report',
    id: MoreActionsMenuItemIds.REPORT,
    icon: faFlag,
  },
]

export const moreActionsMenuItemsCreator: PopupMenuItem[] = [
  {
    text: 'Share',
    id: MoreActionsMenuItemIds.SHARE,
    icon: faShare,
  },
  {
    text: 'Camera',
    id: MoreActionsMenuItemIds.CAMERA,
    icon: faCamera,
  },
  {
    text: 'Delete',
    id: MoreActionsMenuItemIds.DELETE,
    icon: faWrench,
  },
  {
    text: 'Edit',
    id: MoreActionsMenuItemIds.EDIT,
    icon: faEdit,
  },
  {
    text: 'Report',
    id: MoreActionsMenuItemIds.REPORT,
    icon: faFlag,
  },
  // COMING SOON TODO
  // {
  //   text: 'Bookmark',
  //   id: MoreActionsMenuItemIds.BOOKMARK,
  //   icon: faBookmark,
  // },
]

interface Props {
  post: PostData
  commentId?: string
  numberOfReplies: number
  onTopicReplyClick?: (id: string) => void
  onCommentClick?: (id: string) => void
  onTopicBookmarkClick: (id: string) => void
  onShareActionsClick: (id: string, action: string | number) => void
  onCameraActionsClick: (topic: PostData, action: string | number) => void
  onMoreActionsClick: (id: string, action: string | number) => void
  onExpand?: () => void
  isExpanded?: boolean
  isCreator: boolean
}

export const PostIconsMobile: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [isShareIconsExpanded, setIsShareIconsExpanded] =
    React.useState<boolean>(false)
  const [isCameraIconsExpanded, setIsCameraIconsExpanded] =
    React.useState<boolean>(false)

  const onMoreActionsClick = (id: string, action: string | number) => {
    switch (action) {
      case MoreActionsMenuItemIds.SHARE:
        setIsShareIconsExpanded(true)
        break

      case MoreActionsMenuItemIds.CAMERA:
        setIsCameraIconsExpanded(true)
        break

      default:
        props.onMoreActionsClick(id, action)
    }
  }

  // hack to make overlay work on mobile
  useEffect(() => {
    const scrollComponent = document.querySelector(
      '.infinite-scroll-component'
    ) as HTMLElement

    if (isShareIconsExpanded || isCameraIconsExpanded) {
      if (scrollComponent) {
        scrollComponent.style.setProperty('overflow-y', 'hidden', 'important')
      }
    } else {
      if (scrollComponent) {
        scrollComponent.style.removeProperty('overflow-y')
      }
    }

    return () => {
      if (scrollComponent) {
        scrollComponent.style.removeProperty('overflow-y')
      }
    }
  }, [isShareIconsExpanded, isCameraIconsExpanded])

  return (
    <>
      {(isShareIconsExpanded || isCameraIconsExpanded) && (
        <Box
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: colors.search_bg,
            opacity: 0.9,
            zIndex: 9999999,
            WebkitTransform: 'translate3d(0, 0, 0)',
          }}
          onClick={() => {
            setIsShareIconsExpanded(false)
            setIsCameraIconsExpanded(false)
          }}
        />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {props.numberOfReplies > 0 && props.onExpand && (
          <CustomTooltip
            title={props.isExpanded ? 'Collapse Comments' : 'Expand Comments'}
          >
            <Box
              sx={{
                display: 'flex',
                marginRight: 3,
                cursor: 'pointer',
                color: colors.textGrey,
                transitionDuration: '50ms',

                '&:hover': {
                  color: colors.text_white,
                },
              }}
              onClick={() => {
                props.onExpand!()
              }}
            >
              <FontAwesomeIcon
                icon={props.isExpanded ? faMinusSquare : faPlusSquare}
                color={colors.textGrey}
                size="sm"
              />
              <Box
                sx={{
                  fontFamily: fonts.dm_sans,
                  fontSize: 12,
                  marginLeft: 1,
                }}
              >
                {props.numberOfReplies}
              </Box>
            </Box>
          </CustomTooltip>
        )}
        {props.onTopicReplyClick && (
          <CustomTooltip title="Reply">
            <Box
              sx={{
                display: 'flex',
                marginRight: 3,
                cursor: 'pointer',
                color: colors.textGrey,
                transitionDuration: '50ms',

                '&:hover': {
                  color: colors.text_white,
                },
              }}
              onClick={() => {
                props.onTopicReplyClick!(props.post.id)
              }}
            >
              <FontAwesomeIcon icon={faMessage} size="1x" />
              <Box
                sx={{
                  marginLeft: 1,
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {props.numberOfReplies > 0
                  ? props.commentId
                    ? 'Reply'
                    : props.numberOfReplies
                  : ''}
              </Box>
            </Box>
          </CustomTooltip>
        )}
        {props.onCommentClick && (
          <CustomTooltip title="Comments">
            <Box
              sx={{
                display: 'flex',
                marginRight: 4,
                cursor: 'pointer',
                color: colors.textGrey,
                transitionDelay: '50ms',

                '&:hover': {
                  color: colors.text_white,
                },
              }}
              onClick={() => {
                props.onCommentClick!(props.post.id)
              }}
            >
              <FontAwesomeIcon icon={faCommentAlt} size="sm" />
              {props.numberOfReplies > 0 && (
                <Box
                  sx={{
                    fontFamily: fonts.dm_sans,
                    fontSize: 12,
                    marginLeft: 1,
                  }}
                >
                  {props.numberOfReplies}
                </Box>
              )}
            </Box>
          </CustomTooltip>
        )}
        <MenuWithOptions
          menuIcon={faEllipsisH}
          size={'1x'}
          iconToolTip={'More Actions'}
          actionsMenuItems={
            props.isCreator ? moreActionsMenuItemsCreator : moreActionsMenuItems
          }
          onActionClick={action => {
            onMoreActionsClick(props.post.id, action)
          }}
        ></MenuWithOptions>
        {isShareIconsExpanded && (
          <ShareIconsMobile
            topicId={props.post.id}
            onReport={() => {
              navigate(
                `/${props.post.assetType}/${props.post.symbol}` +
                  `/report/${props.post?.id}`
              )
            }}
            onClose={() => {
              setIsShareIconsExpanded(false)
            }}
            onShareActionsClick={props.onShareActionsClick}
          />
        )}
        {isCameraIconsExpanded && (
          <CameraIconsMobile
            topic={props.post}
            onClose={() => {
              setIsCameraIconsExpanded(false)
            }}
            onCameraActionsClick={props.onCameraActionsClick}
          />
        )}
      </Box>
    </>
  )
}
