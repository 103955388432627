import React, { useCallback, useEffect, useState } from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useGetNotificationsQuery } from 'generated/graphql'
import NotificationList from 'components/Notification/NotificationList'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../constants'
import { FormControl, Input } from '@mui/material'
import ROUTES from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  IBaseNotification,
  IPostReplyNotification,
  IPricePredictionResultNotification,
} from 'types/notification'

const Notifications: React.FC = () => {
  const classes = useStyles(theme)
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  const [hasMoreNotifications, setHasMoreNotifications] = useState(true)
  const [notifications, setNotifications] = useState<IBaseNotification[]>([])
  const [notificationsOffset, setNotificationsOffset] = useState(null)

  const { data: getNotifications, fetchMore: fetchMoreNotifications } =
    useGetNotificationsQuery({
      fetchPolicy: 'cache-first',
    })

  const redirectToPage = () => {
    const hideState: any = {
      hideClose: false,
      navigateDelta: -1,
    }

    navigate(ROUTES.SENTIMENT_DISCOVERY, {
      state: hideState,
    })
  }

  const onClose = () => {
    navigate(-1)
  }

  const mapNotification = (
    item: any
  ): IPostReplyNotification | IPricePredictionResultNotification | null => {
    if (
      item.body.__typename === 'PostReply' ||
      item.body.__typename === 'CommentReply'
    ) {
      return {
        commentId: item.body.comment.id,
        postId: item.body.comment.post?.id,
        assetType: item.body.comment.post?.postAssetType,
        text: item.body.comment.text,
        createdAt: item.body.comment.createdAt,
        nickname: item.body.comment.user?.nickname,
        emailHash: item.body.comment.user?.emailHash,
        symbol: item.body.comment.post?.postSymbol,
        read: item.read,
      }
    } else if (item.body.__typename === 'PricePredictionResult') {
      return {
        read: item.read,
        roundId: item.body.vote.round.id,
        symbol: item.body.vote.round.symbolObj.symbol,
        endOfRoundTimestamp: item.body.vote.round.endOfRoundTimestamp,
      }
    }
    return null
  }

  const setLastNotificationOffset = (lastNotification: any) => {
    if (
      lastNotification &&
      (lastNotification.body.__typename === 'PostReply' ||
        lastNotification.body.__typename === 'CommentReply')
    ) {
      setNotificationsOffset(lastNotification.body.comment.createdAt)
    } else {
      setNotificationsOffset(null)
    }
  }

  const loadMoreNotifications = useCallback(async () => {
    const newData = await fetchMoreNotifications({
      variables: { offset: notificationsOffset },
    })

    if (newData.data.getNotifications.notifications.length === 0) {
      setHasMoreNotifications(false)
      return []
    } else {
      const updatedNotifications = newData.data.getNotifications.notifications
        .map(mapNotification)
        .filter(n => n !== null)

      const lastNotification =
        newData.data.getNotifications.notifications[
          newData.data.getNotifications.notifications.length - 1
        ]
      setLastNotificationOffset(lastNotification)

      setNotifications(prevNotifications => [
        ...prevNotifications,
        ...(updatedNotifications as IBaseNotification[]),
      ])

      return updatedNotifications
    }
  }, [fetchMoreNotifications, notificationsOffset])

  useEffect(() => {
    if (getNotifications) {
      const initialNotifications =
        getNotifications.getNotifications.notifications
          .map(mapNotification)
          .filter(n => n !== null)
      setNotifications(initialNotifications as IBaseNotification[])
      const lastNotification =
        getNotifications.getNotifications.notifications[
          getNotifications.getNotifications.notifications.length - 1
        ]
      setLastNotificationOffset(lastNotification)

      if (initialNotifications.length < 20) {
        setHasMoreNotifications(false)
      }
    }
  }, [getNotifications])

  return (
    <div className={classes.wrapper} id="notification-scroll-container">
      {deviceType === DeviceType.Desktop && (
        <>
          <div className={classes.topNavWrapper}>
            <div className={classes.logoWrapper}>
              <LogoWithoutText />
            </div>
            <div className={classes.inputWithIcon} onClick={redirectToPage}>
              <FormControl
                classes={{
                  root: classes.formControlRoot,
                }}
              >
                <Input
                  placeholder={'Search stocks e.g. Apple AAPL'}
                  inputProps={{}}
                  id={`input-search`}
                  readOnly={true}
                  onChange={() => {}}
                  name={'search'}
                  classes={{
                    input: classes.input,
                    root: classes.inputRoot,
                  }}
                />
              </FormControl>
              {deviceType === DeviceType.Desktop && (
                <FontAwesomeIcon
                  icon={faSearch}
                  className={classes.iconSearch}
                  color={colors.textGrey}
                />
              )}
            </div>
            <div className={classes.closeWithIcon} onClick={onClose}>
              <span>Close</span>
              <FontAwesomeIcon
                icon={faClose}
                className={classes.iconClose}
                color={colors.textGrey}
              />
            </div>
          </div>
          <h1 className={classes.title}>Notifications</h1>
        </>
      )}

      {deviceType === DeviceType.Mobile && (
        <div className={classes.topNavWrapper}>
          <span className={classes.title}>Notifications</span>
        </div>
      )}

      <InfiniteScroll
        dataLength={notifications.length}
        next={loadMoreNotifications}
        className={classes.scrollContainer}
        hasMore={hasMoreNotifications}
        loader={<h4>Loading...</h4>}
        scrollableTarget="notification-scroll-container"
      >
        <NotificationList notifications={notifications} />
      </InfiniteScroll>
    </div>
  )
}

export default Notifications
