import React, { useRef, useMemo, useEffect } from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'
import { ExDate } from '../../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faMessage,
} from '@fortawesome/free-solid-svg-icons'
import CandlestickChart from 'components/CandlestickChart'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { PostType, useAssetDataQuery } from 'generated/graphql'
import { CandleStickData } from 'components/CandlestickChart/interfaces'
import { extractHostname } from 'utils/urlUtils'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'

interface ShareableStoryPostProps {
  post: PostData
  noteText?: string
  isReadyForCapture?: boolean
  onReady?: () => void
}

function ShareableStoryPost({
  post,
  noteText,
  isReadyForCapture,
  onReady,
}: ShareableStoryPostProps) {
  const classes = useStyles(theme)
  const elementRef = useRef<HTMLDivElement>(null)
  const [selectedCandlestick, setSelectedCandlestick] = React.useState<
    CandleStickData | undefined
  >()

  const useAssetDataQueryResult = useAssetDataQuery({
    variables: {
      assetType: AssetTypeEnum.stock,
      code: post.symbol.toUpperCase(),
      date: post.date.getFullYear().toString(),
    },
    fetchPolicy: 'network-only',
  })

  const chartData = useMemo(() => {
    return useAssetDataQueryResult.data?.assetData?.length
      ? useAssetDataQueryResult.data.assetData.map(item => {
          const priceBarDate =
            item!.data.assetType === 'STOCK'
              ? new ExDate(item!.date)
              : new ExDate(item!.date, { tz: 'UTC' })

          return {
            ...item!.data,
            date: priceBarDate,
          }
        })
      : undefined
  }, [useAssetDataQueryResult.data?.assetData])

  useEffect(() => {
    const processImages = async () => {
      if (!chartData || !post.date) return

      let matchingCandlestick = chartData.find(
        (candlestick: { date: ExDate }) =>
          post.date.date === candlestick.date.date
      )

      if (!matchingCandlestick) {
        const sortedChartData = chartData.sort((a, b) => {
          return a.date.datetime.diff(b.date.date)
        })
        matchingCandlestick = sortedChartData.find(
          (candlestick: { date: ExDate }) =>
            candlestick.date.datetime.isAfter(post.date.datetime)
        )
      }

      setSelectedCandlestick(matchingCandlestick)

      await new Promise(resolve => setTimeout(resolve)) // Simulating an async operation

      const images = elementRef.current
        ? elementRef.current.getElementsByTagName('img')
        : []
      const loadPromises = Array.from(images).map(image => {
        if (image.complete) {
          return Promise.resolve()
        }
        return new Promise(resolve => {
          image.onload = resolve
          image.onerror = resolve
        })
      })

      await Promise.all(loadPromises)
    }

    processImages().catch(console.error)
  }, [chartData, post.date])

  return (
    <>
      <div className={classes.wrapper} ref={elementRef}>
        {chartData && (
          <div className={classes.chartWrapper}>
            <CandlestickChart
              chartData={chartData}
              selectedCandlestick={selectedCandlestick}
              highlightedDay={undefined}
              onReady={onReady}
            />
          </div>
        )}
        <div className={classes.postWithCommentWrapper}>
          <div className={classes.postBodyWrapper}>
            <div className={classes.postTitleLeft}>
              <div className={classes.postDate}>
                {getTimeElapsedRounded(post.date, post.createdAt)}
              </div>
              <div className={classes.postTitle}>
                {post.title}
                <span className={classes.topicHeadlineSource}>
                  (
                  {post.postType === PostType.Url
                    ? extractHostname(post.url!)
                    : 'fnchart.com'}
                  )
                </span>
              </div>
              <div className={classes.iconWrapper}>
                <FontAwesomeIcon icon={faMessage} className={classes.message} />
                <div className={classes.numberOfReplies}>
                  {post.numberOfReplies}
                </div>
              </div>
            </div>
            <div className={classes.postTitleRight}>
              <div className={classes.arrowsWrapper}>
                <FontAwesomeIcon icon={faArrowUp} className={classes.arrow} />
                <div className={classes.votes}>{post.voteNum}</div>
                <FontAwesomeIcon icon={faArrowDown} className={classes.arrow} />
              </div>
            </div>
          </div>
          {noteText && (
            <div className={classes.notesWrapper}>
              <div className={classes.notes}>{noteText}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ShareableStoryPost
