import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { colors } from '../../../constants'

interface SubmitTopicBarProps {
  onSubmitTopicClick: () => void
}

export const SubmitTopicBar: React.FC<SubmitTopicBarProps> = ({
  onSubmitTopicClick,
}) => {
  const classes = useStyles(theme)
  return (
    <div
      className={classes.submitTopicHeader}
      onClick={() => {
        onSubmitTopicClick()
      }}
    >
      <div className={classes.submitTopicHeaderTitleBox}>CREATE A TOPIC</div>
      <div className={classes.submitTopicHeaderAddIconBox}>
        <FontAwesomeIcon
          icon={faPencilAlt}
          color={colors.text_white}
          size="sm"
        />
      </div>
    </div>
  )
}
