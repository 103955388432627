import { Box } from '@mui/material'
import { UserCreator } from 'auth/userAuth'
import AuthBoxContainer from 'components/auth/common/AuthBoxContainer'
import AuthSubmitButton from 'components/auth/common/AuthSubmitButton'
import SpaceBetween from 'components/auth/common/SpaceBetween'
import TextInput from 'components/inputs/Text'
import * as R from 'ramda'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { colors, fonts, regex } from '../constants'

export interface UserInput {
  username: string | undefined
  password: string | undefined
}

interface Props {
  emailInput: string | undefined
  onSubmit: (variables: UserCreator) => void
  setEmailNotValidated: Dispatch<SetStateAction<boolean>>
}

export default function ManualSignUpPage({
  emailInput,
  onSubmit,
  setEmailNotValidated,
}: Props) {
  const { register, handleSubmit, clearErrors, errors } = useForm<UserCreator>()
  const [getUser, setUser] = useState('')
  const [getCurrUser, setCurrUser] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (getUser === getCurrUser) {
    } else {
      clearErrors('username')
    }
    clearErrors('username')
  }, [getUser])

  const onCreateUser = (data: UserCreator) => {
    if (!emailInput) {
      throw new Error('Please enter an email')
    }

    //onSubmit(R.dissoc('password_confirm', data) as UserCreator)

    const partialData = R.dissoc('password_confirm', data) as UserCreator

    const partialUserData: UserCreator = {
      ...partialData,
      email: emailInput,
    }

    onSubmit(partialUserData)
  }

  return (
    <AuthBoxContainer
      onBackButton={() => {
        setEmailNotValidated(prevFlag => !prevFlag)
      }}
      headingText={'Create your username and password.'}
      descriptionText="Your username is how other community members will see you. This name will be used to credit you for things you share on fnchart. What should we call you?"
    >
      <Box
        sx={{
          marginBottom: '20px',
          marginLeft: '-9px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          {' '}
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onCreateUser)}>
        <TextInput
          name="username"
          label="Username"
          type="username"
          ref={register({
            required:
              'The username cannot be blank. Please enter a valid username.',
          })}
          error={errors.username ? errors.username.message : undefined}
        />

        <SpaceBetween spaceSize={3} sx={{ mb: 3, mt: 3 }} />
        <TextInput
          name="password"
          label="Password"
          type="password"
          title="You have entered an invalid password. The password must be at least 8 characters, and must have at least one of each of the following: upper-case letter (A-Z), lower-case letter (a-z), and a number (0-9)."
          // autoComplete="new-password"
          ref={register({
            required:
              'The password cannot be blank. Please enter a valid password.',
            pattern: {
              value: regex.password,
              message:
                'You have entered an invalid password. The password must be at least 8 characters, and must have at least one of each of the following: upper-case letter (A-Z), lower-case letter (a-z), and a number (0-9).',
            },
          })}
          error={errors.password ? errors.password.message : undefined}
        />
        <>
          <SpaceBetween spaceSize={3} sx={{ mb: 3, mt: 3 }} />
          <AuthSubmitButton>Continue</AuthSubmitButton>
        </>
      </form>
      <Box
        component="p"
        sx={{
          textAlign: 'center',
          fontFamily: fonts.dm_sans,
          fontSize: 12,
          lineHeight: '18px',
          color: colors.textGrey,
          fontWeight: 'lighter',
        }}
      ></Box>
    </AuthBoxContainer>
  )
}
