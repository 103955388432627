import useStyles from './styles'
import theme from '../../../mui-theme'
import Button from '@mui/material/Button'

interface Props {
  onClick: () => void
}

export default function LoginButton(props: Props) {
  const classes = useStyles(theme)

  return (
    <Button
      onClick={() => props.onClick()}
      className={classes.loginBtn}
      sx={{
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      }}
    >
      Log In
    </Button>
  )
}
