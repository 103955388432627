import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'
import { checkboxClasses } from '@mui/material'

export default makeStyles({
  topicWrapper: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${colors.topic_border_bottom}`,

    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        // this is the class .topicLeft but cannot select it by name
        // as it gets a random number appended on the end of the class name each time
        '& > div:nth-child(1)': {
          backgroundColor: colors.drawer_bg_hover,
        },

        '& > div:nth-child(2)': {
          backgroundColor: colors.drawer_bg_hover,
        },

        // same as above for .topicRight
        '& > div:nth-child(3)': {
          backgroundColor: colors.drawer_bg_light_hover,
        },
      },
    },
  },

  story: {
    fontSize: 10,
    fontWeight: 600,
    color: colors.search_bg,
    backgroundColor: colors.orange,
    padding: '4px 8px',
    borderRadius: 100,
    width: 48,
    boxSizing: 'border-box',
    marginBottom: 8,
  },

  topicWrapperClearBackground: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${colors.topic_border_bottom}`,
  },

  topicLeftHighlighted: {
    animation: '$highlightLeft 2000ms ease-out',
  },

  topicRightHighlighted: {
    animation: '$highlightRight 2000ms ease-out',
  },

  '@keyframes highlightLeft': {
    '0%': {
      backgroundColor: colors.drawer_bg_highlight,
    },
    '100%': {
      backgroundColor: colors.drawer_bg,
    },
  },

  '@keyframes highlightRight': {
    '0%': {
      backgroundColor: colors.drawer_bg_light_highlight,
    },
    '100%': {
      backgroundColor: colors.drawer_bg_light,
    },
  },

  checkboxWrapper: {
    backgroundColor: colors.drawer_bg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    color: colors.white,

    [`& span.${checkboxClasses.checked}`]: {
      color: colors.blue,
      backgroundColor: colors.white,
      borderRadius: 0,
    },

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },

  topicLeft: {
    backgroundColor: colors.drawer_bg,
    padding: 16,
    flex: 1,
    transitionDuration: '50ms',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },

  topicLeftClearBackground: {
    padding: 16,
    flex: 1,
  },

  topicRight: {
    width: 55,
    backgroundColor: colors.drawer_bg_light,
    transitionDuration: '50ms',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },

  topicRightClearBackground: {
    width: 55,
  },

  topicHeadline: {
    paddingBottom: 8,
    color: colors.text_white,
    fontSize: 16,
    fontFamily: fonts.dm_sans,
    lineHeight: '22px',
    textAlign: 'left',
    fontWeight: 500,
  },

  topicHeadlineText: {
    cursor: 'pointer',
    wordBreak: 'break-word',
  },

  topicHeadlineSource: {
    color: colors.textGrey,
    fontSize: 12,
    lineHeight: '18px',
  },

  topicHeadlineUrl: {
    textDecoration: 'inherit',
    color: 'inherit',
    cursor: 'pointer',
  },

  topicPostDetails: {
    display: 'flex',
    paddingBottom: 17,
  },

  imageWrapper: {
    width: '100%',
  },

  image: {
    cursor: 'pointer',
  },

  topicUserName: {
    fontFamily: fonts.dm_sans,
    fontSize: 14,
    color: colors.blue_light,
    textDecoration: 'inherit',
    cursor: 'pointer',
  },

  topicPath: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 4,
    color: colors.cyan_blue_light_faded,
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    lineHeight: '18px',
    textDecoration: 'inherit',
    cursor: 'pointer',
  },

  topicTime: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    lineHeight: '18px',
    paddingLeft: 8,
    color: colors.textGrey,
  },

  topicPostIcons: {
    padding: '0 16px 16px 16px',
    display: 'flex',
  },

  ticker: {
    color: colors.blue_light,
    textDecoration: 'unset',
  },

  storyComment: {
    borderRadius: 8,
    padding: 16,
    color: colors.text_white,
    backgroundColor: colors.drawer_bg_light,
  },
})
