import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'

export default makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: colors.search_bg,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    minHeight: '100%',
    fontFamily: fonts.dm_sans,
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
    boxSizing: 'border-box',
  },
  topWrapper: {
    padding: '10px 16px 0 16px',
    borderBottom: `1px solid ${colors.drawer_border}`,
  },
  topNavBar: {
    display: 'flex',
    height: 48,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
  },
  headerRight: {
    width: 28,
    height: 28,
  },
  iconBackWrapper: {
    backgroundColor: colors.box_bg,
    width: 28,
    height: 28,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBack: {
    color: colors.white,
  },
  tab: {
    minWidth: 66,
    minHeight: 28,
    height: 28,
    color: `${colors.white_faded} !important`,
    backgroundColor: colors.tab_bg,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    opacity: 0.5,
    boxSizing: 'border-box',
    padding: '6px 8px',
    borderRadius: 6,
    marginRight: 8,
    textTransform: 'none',

    '&:last-child': {
      marginRight: 0,
    },
  },
  selectedTab: {
    opacity: 1,
  },
  tabsScroller: {
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
  },
  mainSectionWrapper: {
    padding: '13px 14px',
  },
  accountSettingsWrapper: {
    marginTop: 4,
  },
  avatarWrapper: {
    width: 60,
    height: 60,
  },
  profileHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  emailChangePhotoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
  },
  emailChangePhoto: {
    color: colors.blue_lighter,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 4,
  },
  email: {
    color: colors.textGreyLight,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
})
