import { makeStyles } from '@mui/styles'

export default makeStyles(
  {
    hideDialogActions: {
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  },
  { index: 1 }
)
