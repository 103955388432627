import { makeStyles } from '@mui/styles'
import { colors } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 40px)',
    },

    '&:hover': {
      cursor: 'crosshair',
    },
  },

  noPointerEvents: {
    pointerEvents: 'none',
  },
}))
