import { makeVar } from '@apollo/client'
import { v4 as uuid } from 'uuid'

export interface ErrorVar {
  id: string
  message: string
}

export interface SuccessVar {
  id: string
  message: string
}

export interface LoadingVar {
  id: string
}

export const globalErrors = makeVar<ErrorVar[]>([])
export const globalSuccess = makeVar<ErrorVar[]>([])
export const globalLoading = makeVar<LoadingVar[]>([])

export const setApolloError = (errorMessage: string) => {
  const errors = globalErrors()
  globalErrors([
    ...errors,
    {
      id: uuid(),
      message: errorMessage,
    },
  ])
}

export const setSuccess = (successMessage: string) => {
  const errors = globalSuccess()
  globalSuccess([
    ...errors,
    {
      id: uuid(),
      message: successMessage,
    },
  ])
}

export const setLoading = (id: string) => {
  const loading = globalLoading()
  globalLoading([...loading, { id: id }])
}
