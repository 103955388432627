import React from 'react'

type NotificationIconProps = {
  isSelected: boolean
}

const NotificationIcon: React.FC<NotificationIconProps> = ({ isSelected }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3484 23.6858C19.3484 25.674 17.7367 27.2858 15.7484 27.2858C13.7602 27.2858 12.1484 25.674 12.1484 23.6858"
      stroke={isSelected ? 'white' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      fill={isSelected ? 'white' : 'none'}
    />
    <path
      d="M7.65 13.8144C7.65 9.34085 11.2765 5.71436 15.75 5.71436V5.71436C20.2235 5.71436 23.85 9.34085 23.85 13.8144V18.6358L24.5066 21.5905C24.6315 22.1526 24.2038 22.6858 23.628 22.6858H7.87195C7.29618 22.6858 6.86848 22.1526 6.99339 21.5905L7.65 18.6358V13.8144Z"
      stroke={isSelected ? 'white' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      fill={isSelected ? 'white' : 'none'}
    />
  </svg>
)

export default NotificationIcon
