import React from 'react'
import ContactUsBox, { ContactUsForm } from 'components/ContactUs'
import { useCreateContactMutation } from 'generated/graphql'

const ContactUs: React.FC = () => {
  const [messageSent, setMessageSent] = React.useState(false)
  const [createContact] = useCreateContactMutation()

  async function submitQuery(form: ContactUsForm) {
    createContact({
      variables: {
        data: {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          message: form.message,
        },
      },
    }).then(
      () => {
        setMessageSent(true)
      },
      () => {}
    )
  }

  return <ContactUsBox messageSent={messageSent} onSubmit={submitQuery} />
}

export default ContactUs
