import {
  VotePostMutationHookResult,
  ChangePostVoteMutationHookResult,
  PostDataFragment,
  PostDataFragmentDoc,
  DeletePostVoteMutationHookResult,
} from '../generated/graphql'
import { VoteDirection } from '../components/TopicSidebar/VoteUpDown'
import gAnalytics, { gaVoteEvent } from 'utils/analytics/ga'

interface OnVoteProps {
  direction: VoteDirection
  postId: string
  userId?: string
  votePostMutationHookResult: VotePostMutationHookResult
  changePostVoteMutationHookResult: ChangePostVoteMutationHookResult
  deletePostMutationHookResult: DeletePostVoteMutationHookResult
  userVotePositive?: boolean | null
  callback?: () => void
}

export function onVote(props: OnVoteProps) {
  const {
    direction,
    postId,
    votePostMutationHookResult,
    changePostVoteMutationHookResult,
    deletePostMutationHookResult,
    userVotePositive,
  } = props

  const [votePost] = votePostMutationHookResult
  const [changePostVote] = changePostVoteMutationHookResult
  const [deletePostVote] = deletePostMutationHookResult

  const userVote = userVotePositive

  gaVoteEvent(
    'vote',
    `vote___post`,
    'submit',
    props.userId ?? 'unknown_user'
  ).catch(err => {
    console.error(`Google analytics failed to record the vote post event.`)
  })

  if (typeof userVote === 'boolean') {
    if (
      (userVote === true && direction === VoteDirection.up) ||
      (userVote === false && direction === VoteDirection.down)
    ) {
      deletePostVote({
        variables: {
          postId,
        },
        update(cache, { data: updateVoteData }) {
          if (!updateVoteData) {
            return
          }
          cache.updateFragment<PostDataFragment>(
            {
              id: `Post:${postId}`,
              fragment: PostDataFragmentDoc,
              fragmentName: 'PostData',
            },
            postData => {
              return postData
                ? {
                    ...postData,
                    votes: postData.votes + (userVote ? -1 : 1),
                    userVotePositive: null,
                  }
                : null
            }
          )

          if (props.callback) {
            props.callback()
          }
        },
      })
    } else {
      const positive = direction === VoteDirection.up ? true : false
      const voteNumChange = positive ? 2 : -2

      changePostVote({
        variables: {
          positive,
          postId,
        },
        update(cache, { data: changeVoteData }) {
          if (!changeVoteData) {
            return
          }
          cache.updateFragment<PostDataFragment>(
            {
              id: `Post:${postId}`,
              fragment: PostDataFragmentDoc,
              fragmentName: 'PostData',
            },
            postData => {
              return postData
                ? {
                    ...postData,
                    votes: postData.votes + voteNumChange,
                    userVotePositive: positive,
                  }
                : null
            }
          )

          if (props.callback) {
            props.callback()
          }
        },
      })
    }
  } else {
    const positive = direction === VoteDirection.up ? true : false
    const voteNumChange = positive ? 1 : -1

    votePost({
      variables: {
        positive,
        postId,
      },
      update(cache, { data: votePostData }) {
        if (!votePostData) {
          return
        }
        cache.updateFragment<PostDataFragment>(
          {
            id: `Post:${postId}`,
            fragment: PostDataFragmentDoc,
            fragmentName: 'PostData',
          },
          postData => {
            return postData
              ? {
                  ...postData,
                  votes: postData.votes + voteNumChange,
                  userVotePositive: positive,
                }
              : null
          }
        )

        if (props.callback) {
          props.callback()
        }
      },
    })
  }
}
