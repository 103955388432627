import React, { useState } from 'react'
import PricePredictionBox from 'components/PricePrediction'
import {
  GetPricePredictionRoundDocument,
  GetPricePredictionRoundQuery,
  GetPricePredictionRoundQueryVariables,
  PricePredictionRoundDetailsFragment,
  useAddUserWatchedSymbolMutation,
  useGetCurrentUserQuery,
  useGetPricePredictionRoundQuery,
  useSetPricePredictionVoteMutation,
} from 'generated/graphql'
import { setSuccess } from 'apollo/reactive-vars'
import SignUpModal from 'components/auth/SignUpModal/SignUpModal'
import ROUTES from 'constants/routes'

const PricePrediction: React.FC = () => {
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false)

  const handleSignUpModalClose = () => {
    setShowSignUpModal(false)
  }

  const { data } = useGetPricePredictionRoundQuery({
    fetchPolicy: 'cache-first',
  })

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  const [setPricePredictionVoteMutation] = useSetPricePredictionVoteMutation()
  const [addUserWatchedSymbol] = useAddUserWatchedSymbolMutation()

  const submitVote = async (higher: boolean) => {
    await setPricePredictionVoteMutation({
      variables: {
        roundId: data?.getPricePredictionRound?.id || '',
        higher: higher,
      },
      update(cache, mutationResult, { variables }) {
        const readQueryResult = cache.readQuery<
          GetPricePredictionRoundQuery,
          GetPricePredictionRoundQueryVariables
        >({
          query: GetPricePredictionRoundDocument,
        })

        const pricePredictionRound: PricePredictionRoundDetailsFragment = {
          ...readQueryResult?.getPricePredictionRound!,
          vote: {
            __typename: 'PricePredictionVote',
            higher: higher,
          },
        }

        cache.writeQuery({
          query: GetPricePredictionRoundDocument,
          data: {
            getPricePredictionRound: pricePredictionRound,
          },
        })
      },
    })
  }

  const addToWatchList = async () => {
    await addUserWatchedSymbol({
      variables: {
        symbol: data?.getPricePredictionRound?.symbol || '',
      },
    }).catch(e => {
      if (e.message.indexOf('duplicate key') > -1) {
        return
      } else {
        throw e
      }
    })

    setSuccess('Added to watchlist!')
  }

  return (
    <>
      <PricePredictionBox
        symbol={data?.getPricePredictionRound?.symbol || ''}
        symbolName={data?.getPricePredictionRound?.symbolObj?.name || ''}
        symbolType={data?.getPricePredictionRound?.symbolObj?.type || ''}
        startClose={data?.getPricePredictionRound?.startClose || 0}
        endOfVoting={data?.getPricePredictionRound?.endOfVotingTimestamp || ''}
        isVotingOpen={data?.getPricePredictionRound?.isVotingOpen || false}
        isLoggedIn={!!getCurrentUserData?.getCurrentUser}
        submitVote={submitVote}
        addToWatchList={addToWatchList}
        setShowSignUpModal={setShowSignUpModal}
      />
      <SignUpModal
        open={showSignUpModal}
        redirectUrl={ROUTES.PRICE_PREDICTION}
        onClose={handleSignUpModalClose}
      />
    </>
  )
}

export default PricePrediction
