import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import classNames from 'classnames'

import { Tab, Tabs } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../constants'
import UserAvatar from '../UserAvatar'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  name: string
  email: string
  tabItems: {
    id: string
    index: number
    label: string
  }[]
  selectedTabItemIndex: number
  children: [React.ReactNode, React.ReactNode, React.ReactNode]
  onBackClick: () => void
  onUserLinkClick: () => void
  onSignOutClick: () => void
}

export default function UserSettingsSidebar(props: Props) {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const [selectedTab, setSelectedTab] = React.useState(
    props.selectedTabItemIndex
  )

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newSelectedTabIndex: number
  ) => {
    setSelectedTab(newSelectedTabIndex)
  }

  return (
    <div className={classes.wrapper}>
      {deviceType === DeviceType.Mobile && (
        <div className={classes.mobileTitle}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.searchIcon}
            color={colors.white_faded}
            onClick={() => props.onBackClick()}
          />
          <div
            className={classes.signOutMobile}
            onClick={() => props.onSignOutClick()}
          >
            <span className={classes.signOutNameMobile}>Log out</span>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color={colors.textGreyLight}
              size="lg"
            />
          </div>
        </div>
      )}
      <div className={classes.profileDetails}>
        <div className={classes.avatarWrapper}>
          <UserAvatar email={props.email} />
        </div>
        <div className={classes.profileDetailsInfo}>
          <div>
            <span
              className={classes.name}
              onClick={() => props.onUserLinkClick()}
            >
              {props.name}
            </span>
          </div>
          <div>
            <span
              className={classes.email}
              onClick={() => props.onUserLinkClick()}
            >
              {props.email}
            </span>
          </div>
        </div>
        {deviceType === DeviceType.Desktop && (
          <div
            className={classes.signOut}
            onClick={() => props.onSignOutClick()}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color={colors.textGrey}
              size="sm"
            />
            <span className={classes.signOutName}>Sign out</span>
          </div>
        )}
      </div>
      <Tabs
        value={selectedTab}
        className={classes.tabs}
        onChange={handleTabChange}
        aria-label="user settings tabs"
        sx={{
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        }}
        classes={{
          indicator: classes.selectedTabIndicator,
        }}
      >
        {props.tabItems.map((item, i) => (
          <Tab
            key={i}
            className={classNames(
              classes.tab,
              selectedTab === i ? classes.selectedTab : ''
            )}
            label={<div className={classes.tabWrapper}>{item.label}</div>}
          />
        ))}
      </Tabs>
      {props.tabItems.map((item, i) => (
        <div
          className={classes.tabContent}
          key={i}
          style={{ display: i === selectedTab ? 'block' : 'none' }}
        >
          {props.children[item.index]}
        </div>
      ))}
    </div>
  )
}
