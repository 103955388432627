import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    maxWidth: 180,
    width: '100%',
    backgroundColor: colors.drawer_bg_right,

    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      backgroundColor: colors.search_bg,
    },
  },

  profileDetails: {
    padding: '20px 20px 14px 20px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
      padding: '30px 0 0 0',
      flexDirection: 'row',
    },
  },

  avatar: {
    cursor: 'pointer',
    marginBottom: 8,
    width: 46,
    height: 46,

    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
    },
  },

  avatarWrapper: {
    width: 20,
    height: 20,
    marginRight: 10,

    [theme.breakpoints.down('lg')]: {
      width: 24,
      height: 24,
      marginRight: 16,
    },
  },

  nameEmailWrapper: {
    width: '100%',
    textAlign: 'center',

    [theme.breakpoints.down('lg')]: {
      marginLeft: 30,
      textAlign: 'left',
    },
  },

  name: {
    marginBottom: 8,
    color: colors.text_white,
    fontFamily: fonts.dm_sans,
    fontSize: 14,
    height: 18,
    fontWeight: 500,
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      lineHeight: '22px',
      marginTop: 0,
      marginBottom: 4,
    },
  },

  email: {
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    width: '100%',
    color: colors.textGrey,
    letterSpacing: 0,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [theme.breakpoints.down('lg')]: {
      marginTop: 4,
      marginBottom: 0,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
  },

  menuList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    [theme.breakpoints.down('lg')]: {
      marginTop: 30,
    },
  },

  menuOption: {
    minHeight: '40px',
    height: 40,
    color: colors.textGrey,
    transitionDuration: '50ms',
    width: '100%',
    justifyContent: 'flex-start',

    '&:hover': {
      color: colors.text_white,
      backgroundColor: `${colors.menu_item_bg_hover}`,
    },

    [theme.breakpoints.down('lg')]: {
      padding: 0,
      marginBottom: 20,
    },
  },
  menuOptionWrapper: {
    fontFamily: fonts.dm_sans,
    fontSize: 13,
    width: 80,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,

    [theme.breakpoints.down('lg')]: {
      padding: 0,
      fontSize: 18,
      width: '100%',
      fontWeight: 500,
      lineHeight: '24px',
      color: colors.text_white,
    },
  },

  icon: {
    marginRight: 10,

    [theme.breakpoints.down('lg')]: {
      color: colors.textGreyLight,
      marginRight: 16,
    },
  },
}))
