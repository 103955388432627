import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { forwardRef, useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { gaPostEvent } from 'utils/analytics/ga'
import { dateFromURL, titleFromURL } from 'utils/urlUtils'
import CreateTopicPanel, {
  CreateTopicInput,
} from '../../components/TopicSidebar/CreateTopicPanel'
import {
  PostDataFragment,
  PostType,
  PostsDocument,
  PostsQuery,
  PostsQueryVariables,
  useCreatePostMutation,
  useGetCurrentUserQuery,
  useGetSymbolDateQuery,
  useGetSymbolQuery,
} from '../../generated/graphql'
import { ExDate, dateToExDate } from '../../constants'
import { DEBUG_ENV } from 'index'
import CreateTopicPanelMobile from 'components/TopicSidebar/CreateTopicPanelMobile'
import ROUTES from 'constants/routes'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { useEmailNotification } from 'hooks/useEmailNotification'

interface Props {
  nickname?: string | null
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const deviceType = useDeviceType()
  const { symbol } = useParams<'symbol' | 'editId'>()
  const codeUpper = symbol?.toUpperCase()
  const location = useLocation()
  const navigate = useNavigate()
  const [createPost] = useCreatePostMutation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { emailNotification, handleEmailNotificationChange } =
    useEmailNotification()
  const { data, loading, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })
  const [assetType, setAssetType] = useState<string>(AssetTypeEnum.stock)

  const { data: symbolQuery } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: symbol?.toUpperCase() || '',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data: symbolDateData, loading: symbolDateLoading } =
    useGetSymbolDateQuery({
      fetchPolicy: 'cache-first',
      variables: {
        symbol: (symbol as string).toUpperCase(),
      },
    })

  useEffect(() => {
    if (symbolQuery?.getSymbol) {
      setAssetType(symbolQuery.getSymbol.type.toLowerCase())
    }
  }, [symbolQuery])

  const url = searchParams.get('url')
  const toDate = searchParams.get('date')

  const headlineFromUrl = titleFromURL(url)
  const dateFromUrl = dateFromURL(url) ?? toDate // TODO: if undefined, check URL date

  const dateQueryParam = new URLSearchParams(location.search).get('date')

  const symbolDate = !!symbolDateData?.getSymbolDate?.date
    ? (symbolDateData.getSymbolDate.date as String)
    : undefined

  if (DEBUG_ENV) {
    if (!symbolDate)
      console.warn(
        `--> CreateTopicPanelIntegration -- Symbol: ${symbol} has no symbolDate response`
      )
  }

  const today = new ExDate(symbolDate)

  const selectedDay = new ExDate(dateFromUrl)

  if (DEBUG_ENV) {
    console.debug(
      `---> CreateTopicPanelIntegration() -- today: ${today}, selectedDay: ${selectedDay}`
    )
  }

  let postDate = {
    date: !!dateFromUrl
      ? selectedDay.date //toISOString().split('T')[0]
      : today.date, //toISOString().split('T')[0],
    today: !dateFromUrl,
  }

  const handleSubmit = (postData: CreateTopicInput) => {
    if (!!data?.getCurrentUser?.id) {
      gaPostEvent(
        'post_create',
        `post___${postData.postType}`,
        'submit',
        data?.getCurrentUser?.id ?? 'unknown_user'
      ).catch(err => {
        console.error(
          `Google analytics failed to record the create post event.`
        )
      })
    }

    handleEmailNotificationChange(postData.notifyViaEmail)

    const postDataDate = dateToExDate(new Date(postData.date))

    createPost({
      variables: {
        data: {
          date: postDataDate.toApiString(),
          title: postData.headline,
          postSymbol: codeUpper as string,
          postAssetType: assetType,
          text: postData.text,
          postType: postData.postType,
          url: postData.url,
          additionalSymbols: postData.additionalSymbols,
        },
      },
      update(cache, { data }) {
        if (!data) {
          return
        }

        const readQueryResult = cache.readQuery<
          PostsQuery,
          PostsQueryVariables
        >({
          query: PostsDocument,
          variables: {
            postSymbol: codeUpper as string,
            postAssetType: assetType.toUpperCase(),
          },
        })

        const createdPost = {
          ...data.createPost,
          user: {
            ...data.createPost.user,
            nickname: props.nickname,
          },
        } as PostDataFragment

        const posts = readQueryResult?.posts || []

        // Define a sort function
        const sortPosts = (a: PostDataFragment, b: PostDataFragment) => {
          if (b.date !== a.date) {
            return b.date.localeCompare(a.date)
          }

          if (b.votes !== a.votes) {
            return b.votes - a.votes
          }

          // If dates are equal, compare createdAt as Date objects
          const createdAtA = new Date(a.createdAt).getTime()
          const createdAtB = new Date(b.createdAt).getTime()

          return createdAtB - createdAtA
        }

        // If there are no existing posts, simply add the createdPost
        if (posts.length === 0) {
          cache.writeQuery({
            query: PostsDocument,
            data: {
              posts: [createdPost],
            },
            variables: {
              postSymbol: codeUpper as string,
              postAssetType: assetType.toUpperCase(),
            },
          })
          return
        }

        // Sort the current posts
        const sortedPosts = [...posts].sort(sortPosts)

        // Check if createdPost would be at the end after sorting
        const afterLoadedPosts =
          sortedPosts.length > 0 &&
          sortPosts(sortedPosts[sortedPosts.length - 1], createdPost) <= 0

        if (!afterLoadedPosts) {
          // Insert createdPost and re-sort
          const updatedPosts = [createdPost, ...sortedPosts].sort(sortPosts)

          // Remove the last post if the array exceeds its original length
          if (
            updatedPosts.length > sortedPosts.length &&
            updatedPosts.length > 20
          ) {
            updatedPosts.pop()
          }

          // Update the cache with the new array
          cache.writeQuery({
            query: PostsDocument,
            data: {
              posts: updatedPosts,
            },
            variables: {
              postSymbol: codeUpper as string,
              postAssetType: assetType.toUpperCase(),
            },
          })
        } else {
          // Update the cache with the original sorted array (without createdPost)
          cache.writeQuery({
            query: PostsDocument,
            data: {
              posts: sortedPosts,
            },
            variables: {
              postSymbol: codeUpper as string,
              postAssetType: assetType.toUpperCase(),
            },
          })
        }
      },
    })
      .then(result => {
        if (postData.postType === PostType.Story) {
          navigate(
            ROUTES.STORY_EDIT.replace(':symbol', symbol!)
              .replace(':type', assetType)
              .replace(':storyId', result.data?.createPost.id ?? '')
              .toLowerCase()
          )
        } else {
          navigate(
            ROUTES.ASSET.replace(':symbol', symbol!)
              .replace(':type', assetType)
              .toLowerCase()
          )
        }
      })
      .catch(e => {
        console.error('Post creation failed')
        console.error(e)
        if (DEBUG_ENV) console.error(e)
      })
  }

  return (
    <>
      {deviceType === DeviceType.Desktop && (
        <CreateTopicPanel
          type={codeUpper}
          symbolDate={today}
          data={{
            headline: headlineFromUrl,
            date: postDate.date,
            url: url ?? undefined,
            notifyViaEmail: emailNotification,
          }}
          onCancelNewTopicClick={() => {
            const replaceValue = dateQueryParam ? `?date=${dateQueryParam}` : ''
            navigate(location.pathname.replace('/create', replaceValue))
          }}
          onPostClick={handleSubmit}
        />
      )}
      {deviceType === DeviceType.Mobile && (
        <CreateTopicPanelMobile
          type={codeUpper}
          symbolDate={today}
          data={{
            headline: headlineFromUrl,
            date: postDate.date,
            url: url ?? undefined,
            notifyViaEmail: emailNotification,
          }}
          onCancelNewTopicClick={() => {
            const replaceValue = dateQueryParam ? `?date=${dateQueryParam}` : ''
            navigate(location.pathname.replace('/create', replaceValue))
          }}
          // TODO: move to separate function
          onPostClick={handleSubmit}
        />
      )}
    </>
  )
})
