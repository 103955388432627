import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export default makeStyles(
  {
    iconWrapper: {
      display: 'flex',
    },

    icon: {
      cursor: 'pointer',
      color: colors.textGrey,
      transitionDelay: '50ms',

      '&:hover': {
        color: colors.text_white,
      },
    },

    toolTipWrapper: {
      display: 'flex',
    },

    menuItemText: {},

    iconMenuItem: {
      marginRight: 9,
    },
  },
  { index: 1 }
)
