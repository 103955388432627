import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import Avatar from '@mui/material/Avatar'
import md5 from 'md5'
import classNames from 'classnames'

interface Props {
  email?: string
  emailHash?: string
  size?: number
  onClick?: () => void
}

const UserAvatar: React.FC<Props> = ({
  email,
  emailHash,
  size = 40,
  onClick,
}) => {
  const classes = useStyles(theme)
  const [imageSrc, setImageSrc] = useState<string>('')

  useEffect(() => {
    const defaultImageUrl = `${window.location.origin}/media/DefaultProfileImage.png`

    const getGravatarImageUrl = () => {
      let hash = email ? md5(email.toLowerCase().trim()) : emailHash || ''
      if (!hash) {
        return defaultImageUrl
      }

      return `https://secure.gravatar.com/avatar/${hash}?s=${size}&d=404`
    }

    const preloadImage = (src: string, fallbackSrc: string) => {
      const img = new Image()
      img.onload = () => setImageSrc(src)
      img.onerror = () => setImageSrc(fallbackSrc)
      img.src = src
    }

    const gravatarUrl = getGravatarImageUrl()
    preloadImage(gravatarUrl, defaultImageUrl)
  }, [email, emailHash, size])

  return (
    <Avatar
      alt="Profile Photo"
      src={imageSrc}
      className={classNames(classes.avatar, {
        [classes.avatarPointer]: onClick,
      })}
      onClick={onClick}
    />
  )
}

export default UserAvatar
