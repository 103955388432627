import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    color: colors.textGreyLight,
  },

  comment: {
    fontSize: 16,
    color: colors.text_white,
    backgroundColor: colors.drawer_bg_light,
    borderRadius: 8,
    padding: 16,
    cursor: 'pointer',
  },

  pencilIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 8,
  },

  arrow: {
    color: colors.textGreyLight,
    textAlign: 'center',
    cursor: 'pointer',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    '& > *': {
      outline: 'none',
    },
  },

  modalContent: {
    width: 375,
    backgroundColor: colors.search_bg,

    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },
  },

  header: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.drawer_border}`,
  },

  iconClose: {
    width: 20,
    height: 20,
    padding: 4,
    backgroundColor: colors.box_bg,
    borderRadius: 100,
    marginRight: 40,
  },

  headerText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    color: colors.text_white,
  },

  replyBtn: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.white,
    backgroundColor: colors.blue,
    borderRadius: 6,
    textTransform: 'none',
    height: 28,
    width: 68,

    '&:disabled': {
      color: colors.white,
      backgroundColor: colors.blue,
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },

  text: {
    height: 400,
    color: colors.text_white,
    backgroundColor: colors.search_bg,
  },
}))
