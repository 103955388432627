import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles(
  {
    root: {
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        backgroundColor: 'transparent',
      },
    },

    dialogContentRoot: {
      padding: 0,
    },

    dialogContentTextRoot: {
      marginBottom: 0,
    },

    paper: {
      boxShadow: 'none',
      overflow: 'hidden',
      width: 241,
      height: 'auto',
      backgroundColor: colors.main,
      position: 'absolute',
      margin: 0,

      [theme.breakpoints.down('lg')]: {
        width: '80%',
      },
    },

    markdownViewer: {
      width: '100%',
      height: 'calc(100% - 37px)',
      overflowY: 'auto',
    },

    header: {
      borderBottomColor: colors.drawer_bg,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      height: 36,
      fontSize: 12,
      display: 'flex',
    },

    tab: {
      fontSize: 12,
      minHeight: 36,
      color: colors.textGrey,
      fontFamily: fonts.dm_sans,
      minWidth: 64,
      padding: '6px 10px',
      textTransform: 'capitalize',
    },

    selectedTab: {
      color: `${colors.white} !important`,
    },

    tabPanel: {
      padding: '18px 32px 14px 32px',
    },

    selectedTabIndicator: {
      backgroundColor: colors.blue_light,
      color: colors.text_white,
    },

    selectedTabScroller: {
      height: 36,
      marginLeft: 6,
    },

    headerHelpIcon: {
      margin: '12px 16px 12px auto',
      cursor: 'pointer',
    },

    markdownEditorWrapper: {
      backgroundColor: colors.input_bg,
      color: colors.text_white,
      fontFamily: fonts.dm_sans,
    },

    markdownEditorWrapperInner: {
      display: 'flex',
      flexDirection: 'column',
    },

    markdownEditor: {
      backgroundColor: colors.input_bg,
      color: colors.text_white,
      fontFamily: fonts.dm_sans,
      padding: 16,
    },

    symbolPopover: {
      backgroundColor: colors.dropdown_background,
    },

    symbolDropdown: {
      borderTopColor: colors.drawer_bg,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
    },

    symbolList: {
      backgroundColor: colors.dropdown_background,
      width: '100%',
      maxHeight: 150,
      overflowY: 'auto',
    },

    listItem: {
      padding: '14px 16px',
      cursor: 'pointer',

      [theme.breakpoints.down('lg')]: {
        padding: '10px 12px',
      },
    },

    focusedItem: {
      backgroundColor: colors.drawer_bg,
    },

    listItemTextInline: {
      margin: 0,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
        display: 'inline',
      },
    },

    primaryText: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      color: colors.white_faded,

      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
      },
    },

    secondaryText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.white_faded,

      [theme.breakpoints.down('lg')]: {
        fontSize: 12,
      },
    },

    error: {
      borderBottom: `1px solid ${colors.red_light}`,
    },

    ticker: {
      color: colors.blue_light,
    },

    markdownInfo: {
      width: 241,
      backgroundColor: colors.main,
      fontSize: 10,
    },

    markdownInfoWrapper: {
      fontSize: 10,
      fontFamily: fonts.dm_sans,

      [theme.breakpoints.down('lg')]: {
        fontSize: 12,
      },
    },

    markdownInfoTopBar: {
      backgroundColor: colors.drawer_bg_right,
      height: 28,
    },

    closeIcon: {
      marginLeft: 'auto',
      display: 'flex',
      padding: '9px 16px 9px',
      cursor: 'pointer',
    },

    markdownInfoHeader: {
      color: colors.text_white,
      borderTopColor: colors.text_white,
      display: 'flex',
    },

    markdownInfoLeftColumn: {
      width: 'calc(100% - 100px)',
      borderRightColor: colors.input_bg,
      borderRightStyle: 'solid',
      borderRightWidth: 1,
      padding: '7px 15px 6px 15px',
      wordBreak: 'break-all',
    },

    markdownInfoRightColumn: {
      width: 100,
      padding: '7px 15px 6px 14px',
    },

    markdownInfoFullColumn: {
      padding: '7px 15px 6px 14px',
    },

    markdownInfoRowAlternate: {
      color: colors.textGrey,
      backgroundColor: colors.drawer_bg_right,
      display: 'flex',
    },

    markdownInfoRow: {
      color: colors.textGrey,
      display: 'flex',
    },

    italic: {
      fontStyle: 'italic',
    },

    bold: {
      fontWeight: 'bold',
    },

    list: {
      listStyle: 'inside',
      paddingInlineStart: '0px',
      marginBlockStart: '0px',
      marginBlockEnd: '0px',
    },

    link: {
      color: colors.blue_light,
      textDecoration: 'none',
    },

    formHelperRoot: {
      fontSize: 10,
      lineHeight: 1,
      marginTop: 5,
    },
  },
  { index: 1 }
)
