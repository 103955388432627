import { useEffect } from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'generated/graphql'
import ROUTES from 'constants/routes'
import { LoadingProgress } from 'components/LoadingProgress'

const RedirectSigninMobile = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const storedRedirectTo = localStorage.getItem('redirectTo')

  // Fetch the authToken from localStorage
  useEffect(() => {
    const interval = setInterval(() => {
      const token = window.localStorage.getItem('idToken')
      if (token) {
        console.log('Token found:', token)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const {
    data: user,
    loading,
    error,
  } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && user) {
      const currentUser = user.getCurrentUser
      if (!currentUser) {
        return
      }

      if (storedRedirectTo) {
        localStorage.removeItem('redirectTo')
        navigate(storedRedirectTo)
        return
      }

      if (currentUser.nickname === null) {
        navigate(ROUTES.CREATE_NICKNAME)
      } else if (currentUser.lastViewedSymbol) {
        navigate(
          ROUTES.ASSET.replace(':symbol', currentUser.lastViewedSymbol)
            .replace(':type', 'asset')
            .toLowerCase()
        )
      } else {
        navigate(ROUTES.SENTIMENT_DISCOVERY)
      }
    }
  }, [user, loading])

  return <div className={classes.wrapper}>{loading && <LoadingProgress />}</div>
}

export default RedirectSigninMobile
