import useStyles from './styles'
import theme from '../../mui-theme'
import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import {
  faArrowLeft,
  faClose,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl } from '@material-ui/core'
import { colors } from '../../constants'
import { Input } from '@mui/material'
import classNames from 'classnames'
import SearchResults from './SearchResults'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import News from './News'
import {
  GetUserWatchedSymbolsDocument,
  GetUserWatchedSymbolsQuery,
  PostsDocument,
  PostsQuery,
  PostsQueryVariables,
  useAddUserWatchedSymbolMutation,
  useChangeCommentVoteMutation,
  useChangePostVoteMutation,
  useDeleteCommentMutation,
  useDeleteCommentVoteMutation,
  useDeletePostMutation,
  useDeletePostVoteMutation,
  useGetNotificationsQuery,
  useGetPricePredictionRoundQuery,
  useRemoveUserWatchedSymbolMutation,
  useVoteCommentMutation,
  useVotePostMutation,
} from 'generated/graphql'
import { useLocation, useNavigate } from 'react-router-dom'
import { onVote } from '../../utils/onVote'
import { SymbolOption, SymbolTabItem } from './interfaces/symbolTabItem'
import {
  NEWS_TAB_ITEMS,
  TICKER_TAB_ITEMS,
  TabIdentifiers,
} from './constants/tabConstants'
import { NewsTabItem } from './interfaces/newsTabItem'
import { ApolloCache, useApolloClient } from '@apollo/client'
import SearchResultsMobile from './SearchResultsMobile'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import SignUpModal from 'components/auth/SignUpModal/SignUpModal'
import { DEBUG_ENV } from '../../index'
import { onShareActions } from 'utils/shareUtils'
import { MoreActionsMenuItemIds } from 'components/PostIcons/PostIcons'
import { trimURL } from 'utils/urlUtils'
import { onCommentVote } from 'utils/onCommentVote'
import { useTabs } from './hooks/useTabs'
import { useSymbolSearch } from './hooks/useSymbolSearch'
import useSearchAndSymbolManagement from './hooks/useSearchAndSymbolManagement'
import useFeedManagement from './hooks/useFeedManagement'
import AuthToolbar from 'components/common/AuthToolbar'
import ROUTES from 'constants/routes'
import { onCameraActions, onCameraActionsComment } from 'utils/cameraUtils'
import { IPricePredictionResultNotification } from 'types/notification'

interface Props {
  isLoggedIn?: boolean
  hideClose?: boolean
  userId?: string
  email?: string
  startTab?: TabIdentifiers
  focusSearchOnOpen?: boolean
  showMobileMenu?: boolean
  openRight?: boolean
  onClose?: () => void
  signUp?: () => void
  logIn?: () => void
  goToUserSettingsMobile?: () => void
  setOpenRight?: (open: boolean) => void
}

export interface SearchOverlayState {
  hideClose: boolean
  navigateDelta: number
}

export type TabVisibility = {
  [key in TabIdentifiers]: boolean
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  let navigate = useNavigate()
  const localInputRef = useRef<HTMLInputElement>(null)
  const { state } = useLocation()
  const hideState: SearchOverlayState = state
  const hideCloseState = useRef<boolean | undefined>(undefined)

  if (hideState?.hideClose === undefined || hideState?.hideClose === null) {
    hideCloseState.current = props?.hideClose
  } else {
    hideCloseState.current = Boolean(hideState.hideClose)
  }

  // generate a random string for the input to prevent the browser trying to autofill the input - a bit of a hack but can't find a better way
  const randomId = `input-${Math.random().toString(36).substr(2, 9)}`

  const client = useApolloClient()
  const [showSignUpModal, setShowSignUpModal] = React.useState<boolean>(false)
  const { symbol, setSymbol, getSymbols, searchSymbols } = useSymbolSearch()

  const mapNotification = (
    item: any
  ): IPricePredictionResultNotification | null => {
    if (
      item.body &&
      item.body.vote &&
      item.body.vote.round &&
      item.body.vote.round.symbolObj &&
      typeof item.body.vote.round.endOfRoundTimestamp === 'number'
    ) {
      return {
        read: item.read,
        roundId: item.body.vote.round.id,
        symbol: item.body.vote.round.symbolObj.symbol,
        endOfRoundTimestamp: item.body.vote.round.endOfRoundTimestamp,
        notificationId: item.id,
      }
    }
    return null
  }

  const { data: getNotifications } = useGetNotificationsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      unreadOnly: true,
      notificationType: 'PRICE_PREDICTION_RESULT',
      markAsRead: false,
    },
  })

  const notifications = getNotifications?.getNotifications.notifications || []

  const { data: pricePredictionRound } = useGetPricePredictionRoundQuery({
    fetchPolicy: 'cache-first',
  })

  const isPricePredictionRoundVotingOpen =
    pricePredictionRound?.getPricePredictionRound?.isVotingOpen &&
    pricePredictionRound.getPricePredictionRound.vote === null

  const latestPricePredictionNotification: IPricePredictionResultNotification | null =
    notifications
      .map(mapNotification)
      .filter(n => n !== null)
      .sort((a, b) => {
        if (
          a !== null &&
          b !== null &&
          typeof a.endOfRoundTimestamp === 'number' &&
          typeof b.endOfRoundTimestamp === 'number'
        ) {
          return b.endOfRoundTimestamp - a.endOfRoundTimestamp
        }
        return 0
      })
      .shift() || null

  const loadResultsTabData = useCallback(
    newSymbol => {
      getSymbols({
        variables: {
          data: {
            symbol: newSymbol || 'DEFAULT_NON_EXISTENT_VALUE',
          },
        },
      })
    },
    [getSymbols]
  )

  const { handleInputChange } = useSearchAndSymbolManagement(
    setSymbol,
    loadResultsTabData
  )

  const {
    selectedTab,
    setSelectedTab,
    tabVisibility,
    setTabVisibility,
    loadTab,
    watchedSymbols,
    trendingSymbols,
    mostWatchedSymbols,
  } = useTabs(TabIdentifiers.TRENDING, loadResultsTabData)

  const {
    feedItems,
    refetchFeed,
    refetchNewestPosts,
    refetchTrendingPosts,
    loadMoreFeedItems,
    loadMoreNewestPosts,
    trendingPosts,
    newestPosts,
    hasMoreFeedItems,
    hasMoreNewestPosts,
  } = useFeedManagement()

  const [newsTabItems, setNewsTabItems] =
    React.useState<NewsTabItem[]>(NEWS_TAB_ITEMS)

  const [trendingTabItems, setTrendingTabItems] = React.useState<SymbolTabItem>(
    TICKER_TAB_ITEMS[0]
  )
  const [resultsTabItems, setResultsTabItems] = React.useState<SymbolTabItem>(
    TICKER_TAB_ITEMS[1]
  )
  const [watchListTabItems, setWatchListTabItems] =
    React.useState<SymbolTabItem>(TICKER_TAB_ITEMS[2])
  const [mostWatchedTabItems, setMostWatchedTabItems] =
    React.useState<SymbolTabItem>(TICKER_TAB_ITEMS[3])

  const [selectedNewsTabItemIndex, setSelectedNewsTabItemIndex] =
    React.useState(0)

  const votePostMutationHookResult = useVotePostMutation({
    errorPolicy: 'ignore',
  })
  const changePostVoteMutationHookResult = useChangePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const deletePostMutationHookResult = useDeletePostVoteMutation({
    errorPolicy: 'ignore',
  })

  const voteCommentMutationHookResult = useVoteCommentMutation({
    errorPolicy: 'ignore',
  })
  const changeCommentVoteMutationHookResult = useChangeCommentVoteMutation({
    errorPolicy: 'ignore',
  })
  const deleteCommentMutationHookResult = useDeleteCommentVoteMutation({
    errorPolicy: 'ignore',
  })

  const [deletePost] = useDeletePostMutation()
  const [deleteComment] = useDeleteCommentMutation()

  const handleSignUpModalClose = () => {
    setShowSignUpModal(false)
  }

  const [addUserWatchedSymbol, addUserWatchedSymbolState] =
    useAddUserWatchedSymbolMutation({
      update(cache, mutationResult, { variables }) {
        if (!selectedTab) {
          return
        }

        const currentData: GetUserWatchedSymbolsQuery | null = cache.readQuery({
          query: GetUserWatchedSymbolsDocument,
        })

        const searchResults = getSearchResults(selectedTab)!.options
        const tickerOption = searchResults.find(
          (item: { symbol: string }) => item.symbol === variables?.symbol
        )

        // typename has to be symbol or it won't add to the cache, set others to asset
        // asset as this will then get swapped out with the correct type when page is loaded
        const symbolToAdd = {
          __typename: 'Symbol',
          symbol: variables?.symbol,
          type: 'asset',
          name: tickerOption?.name ?? 'Unavailable',
          exchangeShortName: 'Unavailable',
        }

        // Assuming the mutation returns the newly added symbol:
        const updatedWatchedSymbols = [
          ...(currentData?.getUserWatchedSymbols || []),
          symbolToAdd,
        ]

        cache.writeQuery({
          query: GetUserWatchedSymbolsDocument,
          data: { getUserWatchedSymbols: updatedWatchedSymbols },
        })

        loadTab(selectedTab, symbol)
      },
    })

  const [removeUserWatchedSymbol, removeUserWatchedSymbolState] =
    useRemoveUserWatchedSymbolMutation({
      update(cache, mutationResult, { variables }) {
        if (!selectedTab) {
          return
        }

        const currentData: GetUserWatchedSymbolsQuery | null = cache.readQuery({
          query: GetUserWatchedSymbolsDocument,
        })

        // remove symbol from cache
        const updatedWatchedSymbols =
          currentData?.getUserWatchedSymbols?.filter(
            watchedSymbol => watchedSymbol.symbol !== variables?.symbol
          )

        cache.writeQuery({
          query: GetUserWatchedSymbolsDocument,
          data: { getUserWatchedSymbols: updatedWatchedSymbols },
        })

        loadTab(selectedTab, symbol)
      },
    })

  const handleLoadMoreFeedItems = async () => {
    const newFeedItems = await loadMoreFeedItems()
    setNewsTabItems(prevItems => {
      if (!newFeedItems) {
        return prevItems
      }

      const existingFeedItems = prevItems[0].feedItems || []
      const updatedFeedItems = existingFeedItems.concat(newFeedItems)

      const updatedTabItems = [...prevItems]
      updatedTabItems[0] = {
        ...updatedTabItems[0],
        feedItems: updatedFeedItems,
      }
      return updatedTabItems
    })
  }

  const handleLoadMoreLatestNewsItems = async () => {
    const newLatestNews = await loadMoreNewestPosts()
    setNewsTabItems(prevItems => {
      if (!newLatestNews || newLatestNews.length === 0) {
        return prevItems
      }

      const existingLatestNews = prevItems[2].newsPosts || []
      const updatedLatestNews = existingLatestNews.concat(newLatestNews)

      const updatedTabItems = [...prevItems]
      updatedTabItems[2] = {
        ...updatedTabItems[2],
        newsPosts: updatedLatestNews,
      }
      return updatedTabItems
    })
  }

  const handleTabChange = (newTab: TabIdentifiers) => {
    setTabVisibility(prevTabVisibility => {
      const updatedTabVisibility = { ...prevTabVisibility }
      updatedTabVisibility[newTab] = true
      return updatedTabVisibility
    })

    if (newTab === selectedTab) {
      return
    }

    setSelectedTab(newTab)
    loadTab(newTab, symbol)
  }

  React.useEffect(() => {
    if (symbol) {
      getSymbols()
    }
  }, [symbol])

  React.useEffect(() => {
    if (!symbol) {
      const newResultTabItems: SymbolTabItem = { ...resultsTabItems }
      newResultTabItems.options = []
      setResultsTabItems(newResultTabItems)
      return
    }

    if (searchSymbols?.getSymbols) {
      const newResultTabItems: SymbolTabItem = { ...resultsTabItems }

      const newOptions: SymbolOption[] = searchSymbols.getSymbols.map(
        symbol => ({
          symbol: symbol.symbol,
          name: symbol.name,
          type: symbol.type,
          watched: watchedSymbols?.getUserWatchedSymbols
            ? watchedSymbols.getUserWatchedSymbols.findIndex(
                watchedSymbol => watchedSymbol.symbol === symbol?.symbol
              ) !== -1
            : false,
        })
      )

      newResultTabItems.options = newOptions
      setResultsTabItems(newResultTabItems)
      handleTabChange(TabIdentifiers.RESULTS)
    }
  }, [searchSymbols, watchedSymbols])

  React.useEffect(() => {
    if (mostWatchedSymbols?.getMostWatchedSymbols) {
      const newMostWatchedTabItems: SymbolTabItem = { ...mostWatchedTabItems }

      const newOptions: SymbolOption[] =
        mostWatchedSymbols.getMostWatchedSymbols.map(symbol => ({
          symbol: symbol.symbol,
          name: symbol.name,
          type: symbol.type,
          watched: watchedSymbols?.getUserWatchedSymbols
            ? watchedSymbols.getUserWatchedSymbols.findIndex(
                watchedSymbol => watchedSymbol.symbol === symbol?.symbol
              ) !== -1
            : false,
        }))

      newMostWatchedTabItems.options = newOptions
      setMostWatchedTabItems(newMostWatchedTabItems)
    }
  }, [mostWatchedSymbols, watchedSymbols])

  React.useEffect(() => {
    if (trendingSymbols?.getTrendingSymbols) {
      const newTrendingTabItems: SymbolTabItem = { ...trendingTabItems }

      const newOptions: SymbolOption[] = trendingSymbols.getTrendingSymbols.map(
        symbol => ({
          symbol: symbol.symbol.symbol,
          name: symbol.symbol.name,
          type: symbol.symbol.type,
          watched: watchedSymbols?.getUserWatchedSymbols
            ? watchedSymbols.getUserWatchedSymbols.findIndex(
                watchedSymbol => watchedSymbol.symbol === symbol?.symbol?.symbol
              ) !== -1
            : false,
        })
      )

      newTrendingTabItems.options = newOptions
      setTrendingTabItems(newTrendingTabItems)
    }
  }, [trendingSymbols, watchedSymbols])

  React.useEffect(() => {
    if (watchedSymbols?.getUserWatchedSymbols) {
      const newWatchedSymbolsTabItems: SymbolTabItem = { ...watchListTabItems }

      const updatedOptions: SymbolOption[] =
        watchedSymbols.getUserWatchedSymbols.map(symbol => ({
          symbol: symbol.symbol,
          name: symbol.name,
          type: symbol.type,
          watched: true,
        }))

      newWatchedSymbolsTabItems.options = updatedOptions
      setWatchListTabItems(newWatchedSymbolsTabItems)
    }
  }, [watchedSymbols])

  useEffect(() => {
    setNewsTabItems(prevItems => {
      const updatedTabItems = [...prevItems]
      updatedTabItems[1] = {
        ...updatedTabItems[1],
        newsPosts: trendingPosts,
      }
      return updatedTabItems
    })
  }, [trendingPosts])

  useEffect(() => {
    setNewsTabItems(prevItems => {
      const updatedTabItems = [...prevItems]
      updatedTabItems[0] = {
        ...updatedTabItems[0],
        feedItems: feedItems,
      }
      return updatedTabItems
    })
  }, [feedItems])

  useEffect(() => {
    setNewsTabItems(prevItems => {
      const updatedTabItems = [...prevItems]
      updatedTabItems[2] = {
        ...updatedTabItems[2],
        newsPosts: newestPosts,
      }
      return updatedTabItems
    })
  }, [newestPosts])

  useEffect(() => {
    // Clear the query cache and load initial data
    clearQueryCache()
    loadTab(TabIdentifiers.TRENDING, symbol)

    // Check if a startTab prop is provided
    if (
      props.startTab &&
      Object.values(TabIdentifiers).includes(props.startTab)
    ) {
      setSelectedTab(props.startTab)
      loadTab(props.startTab, symbol)
    }
  }, [props.startTab])

  const resultClick = (type: string, symbol: string) => {
    navigate(`/${type}/${symbol}`.toLowerCase(), { replace: true })
  }

  const newsClick = (type: string, symbol: string, newsPostId: string) => {
    navigate(`/${type}/${symbol}/topic/${newsPostId}`.toLowerCase())
  }

  const getSearchResults = (tab: TabIdentifiers) => {
    switch (tab) {
      case TabIdentifiers.TRENDING:
        return trendingTabItems
      case TabIdentifiers.RESULTS:
        return resultsTabItems
      case TabIdentifiers.WATCHLIST:
        return watchListTabItems
      case TabIdentifiers.MOST_WATCHED:
        return mostWatchedTabItems
    }
  }

  const watchedClick = (symbol: string) => {
    if (!selectedTab) {
      return
    }

    if (!props.userId) {
      setShowSignUpModal(true)
      return
    }

    const searchResults = getSearchResults(selectedTab)!.options
    const index = searchResults.findIndex(
      (item: { symbol: string }) => item.symbol === symbol
    )

    const newWatchedValue = !searchResults[index].watched

    // add to watchlist if set to watched
    if (newWatchedValue) {
      addUserWatchedSymbol({
        variables: {
          symbol: symbol,
        },
      }).then(res => {})
    } else {
      removeUserWatchedSymbol({
        variables: {
          symbol: symbol,
        },
      }).then(res => {})
    }
  }

  function onMoreActionsClick(
    postId: string,
    symbol: string,
    typeUpper: string,
    action: string | number
  ) {
    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        navigate(
          `/${typeUpper.toLowerCase()}/${symbol.toLowerCase()}/report/${postId}`
        )
        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        navigate(
          `/${typeUpper.toLowerCase()}/${symbol.toLowerCase()}/edit/${postId}`
        )
        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        console.log(
          `screens->Main->TopicSidebarIntegration->onMoreActionsClick->CASE: EDIT`
        )

        deletePost({
          variables: { postId },
          update(cache, { data }) {
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: postId,
              __typename: 'Post',
            })
            cache.evict({ id: normalizedId })
            cache.gc()

            const readQueryResult = cache.readQuery<
              PostsQuery,
              PostsQueryVariables
            >({
              query: PostsDocument,
              variables: {
                postSymbol: symbol?.toUpperCase() as string,
                postAssetType: typeUpper!,
              },
            })

            const posts = readQueryResult?.posts || []
            const updatedPosts = posts.filter(post => post.id !== postId)

            cache.writeQuery({
              query: PostsDocument,
              data: {
                posts: updatedPosts,
              },
              variables: {
                postSymbol: symbol?.toUpperCase() as string,
                postAssetType: typeUpper!,
              },
            })
          },
        })
          .then(() => {})
          .catch(e => console.log(e))

        break
      }
    }
  }

  function onMoreCommentActionsClick(
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) {
    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        window.open(
          `${window.location.origin}/asset/${symbol}/report/${postId}?commentid=${commentId}`
        )
        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        window.open(
          `${trimURL(
            window.location.origin
          )}/asset/${symbol}/topic/${postId}?commentid=${commentId}`
        )
        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        deleteComment({
          variables: { id: commentId },
          update(cache: ApolloCache<any>, { data }: any) {
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: commentId,
              __typename: 'Comment',
            })
            cache.modify({
              id: normalizedId,
              fields: {
                deleted() {
                  return true
                },
              },
            })
            cache.gc()
          },
        })
          .then(() => {
            navigate(`${window.location.pathname}`, { replace: true })
          })
          .catch((e: any) => console.log(e))

        break
      }
    }
  }

  function onPostClick(id: string, symbol: string) {
    let url = `/asset/${symbol}/topic/${id}`
    navigate(url)
  }

  function onCommentClick(id: string, symbol: string, commentId: string) {
    let url = `/asset/${symbol}/topic/${id}?commentid=${commentId}`
    navigate(url)
  }

  const clearQueryCache = () => {
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getSymbols' })
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getTrendingSymbols' })
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getWatchedSymbols' })
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getMostWatchedSymbols' })
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getTrendingPosts' })
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getNewestPosts' })
    client.cache.gc()
  }

  const handleClose = () => {
    if (
      !(hideState?.hideClose === undefined || hideState?.hideClose === null)
    ) {
      if (DEBUG_ENV)
        console.debug(
          `Clicked on close -- Navigate(${hideState.navigateDelta})`
        )
      navigate(hideState.navigateDelta)
    } else if (props?.onClose) {
      if (DEBUG_ENV) console.debug(`Clicked on close -- props.onClose)`)
      props.onClose()
    } else {
      if (DEBUG_ENV) console.debug('Clicked on close -- DEFAULT')
    }
  }

  const handleRefresh = async () => {
    switch (selectedTab) {
      case TabIdentifiers.FEED:
        await refetchFeed()
        break
      case TabIdentifiers.LATEST_NEWS:
        await refetchNewestPosts()
        break
      case TabIdentifiers.TRENDING_NEWS:
        await refetchTrendingPosts()
        break
      default:
        console.log('Unhandled tab for refresh')
    }
  }

  // call loadTab with 0 initially to load trending symbols
  useEffect(() => {
    clearQueryCache()
    loadTab(TabIdentifiers.TRENDING, symbol)
  }, [])

  useEffect(() => {
    if (props.focusSearchOnOpen && localInputRef.current) {
      localInputRef.current.focus()
    }
  }, [])

  // Global key press event listener
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (showSignUpModal || props.openRight || props.showMobileMenu) {
        return
      }

      if (
        localInputRef.current &&
        document.activeElement !== localInputRef.current
      ) {
        localInputRef.current.focus()
      }
    }

    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [props.openRight, showSignUpModal, props.showMobileMenu])

  // Update tab visibility for resultsTab
  useEffect(() => {
    const newTabVisibility = { ...tabVisibility }
    // Check if symbol has a non-empty value
    newTabVisibility[TabIdentifiers.RESULTS] = symbol.trim().length > 0
    setTabVisibility(newTabVisibility)
  }, [symbol])

  // Update tab visibility only for watchListTab
  useEffect(() => {
    const newTabVisibility = { ...tabVisibility }
    newTabVisibility[TabIdentifiers.WATCHLIST] =
      watchListTabItems.options.length > 0
    setTabVisibility(newTabVisibility)
  }, [watchListTabItems.options.length])

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputWrapper}>
        {deviceType === DeviceType.Desktop && (
          <div className={classes.logoWrapper}>
            <LogoWithoutText />
          </div>
        )}

        {!hideCloseState.current && deviceType === DeviceType.Mobile && (
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.iconLeftArrow}
            color={colors.textGrey}
            onClick={() => props.onClose && props.onClose()}
          />
        )}

        <div className={classes.inputWithIcon}>
          {deviceType === DeviceType.Mobile && (
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.iconSearch}
              color={colors.textGrey}
            />
          )}
          <FormControl
            classes={{
              root: classes.formControlRoot,
            }}
          >
            <Input
              placeholder={'Search stocks e.g. Apple AAPL'}
              id={randomId}
              inputRef={localInputRef}
              onChange={e => handleInputChange(e)}
              name={randomId}
              classes={{
                input: classNames(classes.input),
                root: classNames(classes.inputRoot),
              }}
            />
          </FormControl>
          {deviceType === DeviceType.Desktop && (
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.iconSearch}
              color={colors.textGrey}
            />
          )}
        </div>
        {!hideCloseState.current && deviceType === DeviceType.Desktop && (
          <div className={classes.closeWithIcon} onClick={handleClose}>
            <span>Close</span>
            <FontAwesomeIcon
              icon={faClose}
              className={classes.iconClose}
              color={colors.textGrey}
            />
          </div>
        )}
        {hideCloseState.current && (
          <AuthToolbar
            isLoggedIn={props.isLoggedIn}
            email={props.email}
            signUp={() => window.open(ROUTES.SIGNUP, '_self')}
            logIn={() => window.open(ROUTES.LOGIN, '_self')}
            goToUserSettingsMobile={props.goToUserSettingsMobile!}
            setOpenRight={props.setOpenRight!}
          />
        )}
      </div>
      <div className={classes.searchResultsWrapper}>
        {deviceType === DeviceType.Desktop && (
          <>
            <News
              currentUserId={props.userId}
              tabItems={newsTabItems}
              selectedTabItemIndex={selectedNewsTabItemIndex}
              setSelectedTab={setSelectedNewsTabItemIndex}
              hasMoreFeedItems={hasMoreFeedItems}
              hasMoreNewestPosts={hasMoreNewestPosts}
              latestPricePredictionNotification={
                latestPricePredictionNotification
              }
              isPricePredictionRoundVotingOpen={
                isPricePredictionRoundVotingOpen
              }
              newsClick={newsClick}
              loadMoreFeedItems={handleLoadMoreFeedItems}
              loadMoreLatestNews={handleLoadMoreLatestNewsItems}
              onVote={(direction, postId, userVotePositive) => {
                if (!props.userId) {
                  setShowSignUpModal(true)
                  return
                }

                onVote({
                  direction,
                  postId,
                  changePostVoteMutationHookResult,
                  votePostMutationHookResult,
                  deletePostMutationHookResult,
                  userId: props.userId,
                  userVotePositive,
                  callback: () => {
                    if (DEBUG_ENV) console.debug('onVote callback')
                  },
                })
              }}
              onCommentVote={(direction, commentId, userVotePositive) => {
                if (!props.userId) {
                  setShowSignUpModal(true)
                  return
                }

                onCommentVote({
                  direction,
                  commentId,
                  changeCommentVoteMutationHookResult,
                  voteCommentMutationHookResult,
                  deleteCommentMutationHookResult,
                  userId: props.userId,
                  userVotePositive,
                })
              }}
              onShareActionsClick={(
                id: string,
                shareText: string,
                symbol: string,
                action: string | number,
                commentId?: string
              ) => {
                onShareActions(id, action, symbol, shareText, commentId)
              }}
              onCameraActionsClick={onCameraActions}
              onCameraActionsCommentClick={onCameraActionsComment}
              onMoreActionsClick={onMoreActionsClick}
              onMoreCommentActionsClick={onMoreCommentActionsClick}
              onPostClick={onPostClick}
              onCommentClick={onCommentClick}
            />
            <SearchResults
              trendingTab={trendingTabItems}
              resultsTab={resultsTabItems}
              watchListTab={watchListTabItems}
              mostWatchedTab={mostWatchedTabItems}
              selectedTab={selectedTab}
              tabVisibility={tabVisibility}
              resultClick={resultClick}
              watchedClick={watchedClick}
              setSelectedTab={handleTabChange}
              setTabVisibility={setTabVisibility}
            />
          </>
        )}
        {deviceType === DeviceType.Mobile && (
          <SearchResultsMobile
            currentUserId={props.userId}
            trendingTab={trendingTabItems}
            resultsTab={resultsTabItems}
            watchListTab={watchListTabItems}
            mostWatchedTab={mostWatchedTabItems}
            feedNewsTab={newsTabItems[0]}
            trendingNewsTab={newsTabItems[1]}
            latestNewsTab={newsTabItems[2]}
            selectedTab={selectedTab}
            hasMoreFeedItems={hasMoreFeedItems}
            hasMoreNewestPosts={hasMoreNewestPosts}
            tabVisibility={tabVisibility}
            isPricePredictionRoundVotingOpen={isPricePredictionRoundVotingOpen}
            latestPricePredictionNotification={
              latestPricePredictionNotification
            }
            handleRefresh={handleRefresh}
            loadMoreFeedItems={handleLoadMoreFeedItems}
            loadMoreLatestNews={handleLoadMoreLatestNewsItems}
            resultClick={resultClick}
            watchedClick={watchedClick}
            setSelectedTab={handleTabChange}
            newsClick={newsClick}
            onVote={(direction, postId, userVotePositive) => {
              if (!props.userId) {
                setShowSignUpModal(true)
                return
              }

              onVote({
                direction,
                postId,
                changePostVoteMutationHookResult,
                votePostMutationHookResult,
                deletePostMutationHookResult,
                userId: props.userId,
                userVotePositive,
                callback: () => {
                  if (DEBUG_ENV) console.debug('onVote callback')
                },
              })
            }}
            onCommentVote={(direction, commentId, userVotePositive) => {
              onCommentVote({
                direction,
                commentId,
                changeCommentVoteMutationHookResult,
                voteCommentMutationHookResult,
                deleteCommentMutationHookResult,
                userId: props.userId,
                userVotePositive,
              })
            }}
            onShareActionsClick={(
              id: string,
              shareText: string,
              symbol: string,
              action: string | number,
              commentId?: string
            ) => {
              onShareActions(id, action, symbol, shareText, commentId)
            }}
            onCameraActionsClick={onCameraActions}
            onCameraActionsCommentClick={onCameraActionsComment}
            onMoreActionsClick={onMoreActionsClick}
            onMoreCommentActionsClick={onMoreCommentActionsClick}
            onPostClick={onPostClick}
            onCommentClick={onCommentClick}
          />
        )}
      </div>
      <SignUpModal open={showSignUpModal} onClose={handleSignUpModalClose} />
    </div>
  )
})
