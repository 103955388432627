import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: fonts.dm_sans,
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
    position: 'relative',
    backgroundColor: colors.search_bg,
    overflowY: 'auto',

    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
      backgroundColor: colors.drawer_bg_right,
    },
  },
  content: {
    display: 'flex',
    width: 300,
    padding: '80px 113px',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.box_bg,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '100%',
      padding: '0 38px',
      alignItems: 'center',
      backgroundColor: colors.drawer_bg_right,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: colors.white,
    textAlign: 'center',
    marginBottom: 24,
  },
  subTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: colors.white,
    textAlign: 'center',
    marginBottom: 18,
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGreyLight,
    textAlign: 'center',
    lineHeight: '20px',
    marginBottom: 24,
  },
  backBtn: {
    width: 226,
    height: 44,
    backgroundColor: colors.blue,
    color: colors.white,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 2.75,
    textTransform: 'uppercase',
    borderRadius: 0,
    marginBottom: 24,

    '&:hover': {
      backgroundColor: colors.blue_medium_light,
      boxShadow: 'none',
    },
  },
  report: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGreyLight,
    textAlign: 'center',
    lineHeight: '20px',
    marginBottom: 0,
    cursor: 'pointer',
  },
}))
