import { Box } from '@mui/material'
import { colors, fonts } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  text: string
  urlText: string
  url: string
}

export default function RedirectFooter({ text, urlText, url }: Props) {
  const deviceType = useDeviceType()

  return (
    <Box
      sx={{
        textAlign: 'center',
        fontFamily: fonts.dm_sans,
        fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
        lineHeight: deviceType === DeviceType.Mobile ? '20px' : '18px',
        fontWeight: deviceType === DeviceType.Mobile ? 400 : 400,
        '& a': {
          color: colors.blue_lighter,
          cursor: 'pointer',
        },
        padding: deviceType === DeviceType.Mobile ? 1 : 2,
      }}
    >
      {text}{' '}
      <Box
        component="button"
        sx={{
          backgroundColor: 'inherit',
          font: 'inherit',
          color: '#4385F4',
          border: 'none',
          cursor: 'pointer',
          textDecoration: 'none',
          display: 'inline',
          margin: '0 0 0 4px',
          padding: 0,
        }}
      >
        <a style={{ textDecoration: 'none' }} href={url}>
          {urlText}
        </a>
      </Box>
    </Box>
  )
}

/*

alreadyHaveAccount: {
    marginTop: '8px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.textGreyLight,
    alignSelf: 'center',
  },
  loginLink: {
    color: colors.blue_lighter,
    textDecoration: 'none',
  },

*/
