import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
// const height = 48
// const width = 48

export default makeStyles<typeof theme>(theme => ({
  img: {
    marginRight: '8px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))
