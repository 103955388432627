import * as React from 'react'

function LogoNoBetaImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="165"
      height="24"
      viewBox="0 0 165 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0425 24.0029C17.7625 24.0029 16.0825 22.2029 16.0825 19.9229C16.0825 17.7629 17.8825 15.9629 20.0425 15.9629C22.3225 15.9629 24.1225 17.7629 24.1225 19.9229C24.1225 22.2029 22.3225 24.0029 20.0425 24.0029Z"
        fill="#687AF0"
      />
      <path
        d="M0.119998 0.00390625H24.12V8.0439H16.08V15.9639H8.04V24.0039H0V0.00390625H0.119998Z"
        fill="#4C61EE"
      />
      <path
        d="M35.0023 20.563V11.2873H33.3809V8.73583H35.0023V7.35283C35.0023 5.92207 35.364 4.90489 36.0873 4.30061C36.8107 3.69667 37.7922 3.39453 39.0323 3.39453H40.344V5.99337H39.5092C38.9845 5.99337 38.6111 6.09719 38.3885 6.30347C38.1656 6.51043 38.0545 6.85999 38.0545 7.35283V8.73583H40.606V11.2873H38.0545V20.563H35.0023Z"
        fill="white"
      />
      <path
        d="M43.0146 20.5626V8.73544H45.9141L45.9478 10.7383C46.3129 10.0392 46.8418 9.48301 47.5336 9.06942C48.2248 8.65584 49.0391 8.44922 49.9771 8.44922C51.4397 8.44922 52.5762 8.91056 53.3871 9.83222C54.198 10.7542 54.6033 12.1057 54.6033 13.886V20.5626H51.5511V14.1719C51.5511 13.1547 51.3445 12.376 50.9312 11.8351C50.5177 11.2952 49.8736 11.0245 48.9997 11.0245C48.1414 11.0245 47.4378 11.3267 46.8895 11.9306C46.341 12.5349 46.0665 13.3773 46.0665 14.4581V20.5626H43.0146Z"
        fill="white"
      />
      <path
        d="M62.9091 20.8488C61.7013 20.8488 60.636 20.5865 59.714 20.0621C58.792 19.5374 58.0645 18.8068 57.5325 17.8682C56.9995 16.9306 56.7334 15.8574 56.7334 14.6492C56.7334 13.4417 56.9995 12.3684 57.5325 11.4298C58.0645 10.4922 58.792 9.76092 59.714 9.23624C60.636 8.71156 61.7013 8.44922 62.9091 8.44922C64.4192 8.44922 65.6911 8.84273 66.7245 9.62975C67.7579 10.4164 68.4176 11.5018 68.7035 12.8844H65.4844C65.3256 12.3123 65.0117 11.8635 64.5431 11.5374C64.0734 11.2118 63.5214 11.0484 62.8856 11.0484C62.0425 11.0484 61.3275 11.3668 60.7391 12.0022C60.1508 12.6384 59.8573 13.5206 59.8573 14.6492C59.8573 15.7781 60.1508 16.6603 60.7391 17.2961C61.3275 17.9319 62.0425 18.2496 62.8856 18.2496C63.5214 18.2496 64.0734 18.0911 64.5431 17.773C65.0117 17.4553 65.3256 17.0019 65.4844 16.4136H68.7035C68.4176 17.7488 67.7579 18.8221 66.7245 19.633C65.6911 20.4439 64.4192 20.8488 62.9091 20.8488"
        fill="white"
      />
      <path
        d="M71.0234 20.563V3.39453H74.0753V10.548C74.473 9.89629 75.0094 9.38372 75.685 9.00994C76.3606 8.6365 77.1358 8.44961 78.0101 8.44961C79.4723 8.44961 80.6051 8.91096 81.408 9.83261C82.2106 10.7546 82.6121 12.1061 82.6121 13.8864V20.563H79.5838V14.1723C79.5838 13.1551 79.3813 12.3764 78.976 11.8355C78.5708 11.2956 77.9301 11.0249 77.0562 11.0249C76.1976 11.0249 75.4863 11.3271 74.9222 11.931C74.3577 12.5353 74.0753 13.3777 74.0753 14.4585V20.563H71.0234Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M93.1294 18.9419L93.1301 20.5628H95.9716V13.2901C95.9716 11.7482 95.4867 10.5559 94.5169 9.71316C93.5472 8.87076 92.2202 8.44922 90.5351 8.44922C89.5494 8.44922 88.6589 8.62054 87.8643 8.96179C87.0696 9.30408 86.4218 9.79276 85.921 10.4285C85.4205 11.0647 85.1301 11.8115 85.0509 12.6699H88.0314C88.1425 12.0978 88.4284 11.6648 88.8894 11.3703C89.3508 11.0761 89.8914 10.9293 90.5112 10.9293C91.1948 10.9293 91.7665 11.12 92.2282 11.5014C92.6889 11.8832 92.9197 12.4792 92.9197 13.2901V13.5759H89.9149C88.1983 13.5759 86.9108 13.9179 86.0521 14.6014C85.1938 15.2853 84.7646 16.1751 84.7646 17.2719C84.7646 17.9239 84.9235 18.5203 85.2412 19.0605C85.5593 19.6011 86.0442 20.0344 86.6962 20.3601C87.3476 20.6858 88.1823 20.8488 89.1999 20.8488C90.1852 20.8488 90.992 20.6418 91.6198 20.2289C92.2479 19.8157 92.7366 19.2914 93.0865 18.6552L93.1294 18.9419ZM90.2481 15.4839H92.8476C92.7202 16.3267 92.4143 17.0341 91.9298 17.6062C91.4445 18.1783 90.7648 18.4645 89.8909 18.4645C89.3185 18.4645 88.8575 18.3375 88.5076 18.0831C88.158 17.8294 87.9832 17.4712 87.9832 17.0099C87.9832 16.5811 88.158 16.2194 88.5076 15.9252C88.8575 15.631 89.4375 15.4839 90.2481 15.4839Z"
        fill="white"
      />
      <path
        d="M101.576 10.0782V8.6596H98.4834V20.4964H101.576V17.6238V15.4728V15.1382H101.593C101.593 12.3168 103.598 11.2103 105.839 11.2103C105.896 11.2103 105.952 11.2169 106.009 11.219V8.51562C104.33 8.51562 102.79 9.1026 101.576 10.0782"
        fill="white"
      />
      <path
        d="M113.339 16.4616C113.339 17.0341 113.462 17.4276 113.709 17.6422C113.955 17.8571 114.38 17.964 114.984 17.964H116.463V20.5632H114.388C113.148 20.5632 112.154 20.2611 111.407 19.6568C110.66 19.0532 110.287 17.98 110.287 16.4378L110.286 11.2106L107.811 11.2107V8.51596L110.286 8.51582L110.287 5.56445H113.339L113.339 8.51582L117.087 8.51596V11.2107L113.339 11.2106L113.339 16.4616Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoNoBetaImage
