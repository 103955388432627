import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { DEBUG_ENV } from 'index'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import theme from '../mui-theme'
import client from 'apollo/client'
import ShareablePost from 'components/Shareable/Post'
import html2canvas from 'html2canvas'
import { setApolloError, setSuccess } from 'apollo/reactive-vars'
import ShareableComment from 'components/Shareable/Comment'
import ROUTES from '../constants/routes'
import React, { CSSProperties } from 'react'
import { PostType } from 'generated/graphql'
import { colors, fonts } from '../constants'
import ShareableStoryPost from 'components/Shareable/StoryPost'
import LogoNoBeta from 'components/Images/LogoNoBeta'
import UserAvatar from 'components/UserAvatar'

export enum CameraActionsMenuItemIds {
  DOWNLOAD_IMAGE,
  COPY_IMAGE,
  COPY_LINK,
  OPEN_IN_NEW_TAB,
}

function isInFlutterWebView() {
  return typeof window.Flutter !== 'undefined'
}

async function captureAndProcessImage(
  container: HTMLElement,
  component: React.ReactElement | React.ReactElement[],
  onSuccess: (blob: Blob | null) => void,
  onError: (error: any) => void,
  topic?: PostData
): Promise<void> {
  let totalWidth: number

  if (Array.isArray(component)) {
    const numberOfItems = component.length
    totalWidth = 391 * numberOfItems

    const storyPostWrapper: CSSProperties = {
      width: totalWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: colors.drawer_bg_right,
    }

    const storyChildPostsWrapper: CSSProperties = {
      display: 'flex',
      flexDirection: 'row',
    }

    const storyHeader: CSSProperties = {
      padding: '16px 24px',
      backgroundColor: colors.drawer_bg_right,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }

    const logoLeft: CSSProperties = {
      display: 'flex',
      justifyContent: 'flex-start',
    }

    const userDetailsRight: CSSProperties = {
      display: 'flex',
      justifyContent: 'flex-end',
    }

    const avatarWrapper: CSSProperties = {
      maxWidth: 32,
      maxHeight: 32,
      minWidth: 32,
      minHeight: 32,
      height: 32,
      zIndex: 1,
    }

    const userDetails: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: 16,
    }

    const usernameStory: CSSProperties = {
      fontFamily: fonts.dm_sans,
      fontSize: 16,
      fontWeight: 500,
      color: colors.text_white,
    }

    const followCounts: CSSProperties = {
      color: colors.textGreyLight,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
    }

    const storyTitle: CSSProperties = {
      backgroundColor: colors.search_bg,
    }

    const symbolTitle: CSSProperties = {
      display: 'flex',
      flexDirection: 'row',
      padding: '16px 16px 0 16px',
      backgroundColor: colors.search_bg,
      alignItems: 'flex-end',
    }

    const symbol: CSSProperties = {
      color: colors.white_faded,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '24px',
    }

    const symbolName: CSSProperties = {
      marginLeft: 8,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      color: colors.textGreyLight,
    }

    const storyTitleText: CSSProperties = {
      color: colors.text_white,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
      padding: 16,
    }

    const resolveFunctions: (() => void)[] = []

    const readinessPromises = component.map(
      () =>
        new Promise<void>(resolve => {
          resolveFunctions.push(resolve)
        })
    )

    ReactDOM.render(
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <div style={storyPostWrapper}>
            <div style={storyHeader}>
              <div style={logoLeft}>
                <LogoNoBeta />
              </div>
              <div style={userDetailsRight}>
                <div style={avatarWrapper}>
                  <UserAvatar emailHash={topic!.emailHash} />
                </div>
                <div style={userDetails}>
                  <div style={usernameStory}>{topic!.username}</div>
                  <div style={followCounts}>
                    {topic!.followingCount} Following&nbsp;&nbsp;
                    {topic!.followersCount} Followers
                  </div>
                </div>
              </div>
            </div>
            <div style={storyTitle}>
              <div style={symbolTitle}>
                <span style={symbol}>{topic!.symbol}</span>{' '}
                <span style={symbolName}>{topic!.symbolName}</span>
              </div>
              <div style={storyTitleText}>{topic!.title}</div>
            </div>
            <div style={storyChildPostsWrapper}>
              {component.map((c, index) => (
                <>
                  {React.cloneElement(c, {
                    onReady: () => {
                      resolveFunctions[index]()
                    },
                  })}
                </>
              ))}
            </div>
          </div>
        </ThemeProvider>
      </ApolloProvider>,
      container
    )

    // Wait for all component readiness promises to resolve
    await Promise.all(readinessPromises)
  } else {
    totalWidth = 391
    let resolveReadyPromise: () => void

    const isReadyPromise = new Promise<void>(resolve => {
      resolveReadyPromise = resolve
    })

    ReactDOM.render(
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          {React.cloneElement(component, {
            onReady: () => resolveReadyPromise(),
          })}
        </ThemeProvider>
      </ApolloProvider>,
      container
    )

    await isReadyPromise
  }

  setTimeout(() => {
    html2canvas(container, {
      useCORS: true,
      backgroundColor: 'transparent',
      scale: 3,
      width: totalWidth,
    })
      .then(canvas => {
        canvas.toBlob(blob => {
          onSuccess(blob)
        }, 'image/png')
      })
      .catch(error => {
        onError(error)
      })
      .finally(() => {
        ReactDOM.unmountComponentAtNode(container)
        document.body.removeChild(container)
      })
  })
}

function handleImageDownload(blob: Blob | null, filename: string): void {
  if (!blob) {
    setApolloError('Failed to generate image for download.')
    return
  }

  if (isInFlutterWebView()) {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result as string
      const message = { action: 'downloadImage', filename, content: base64data }
      window.Flutter!.postMessage(JSON.stringify(message))
      // Listen for a custom event to show success message
      window.addEventListener(
        'imageDownloadSuccess',
        () => {
          setSuccess('Image downloaded successfully.')
        },
        { once: true }
      )
    }
  } else {
    const imageUrl = URL.createObjectURL(blob)
    const downloadLink = document.createElement('a')
    downloadLink.href = imageUrl
    downloadLink.download = filename
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
}

function attemptToCopyImage(blob: Blob, filename: string): void {
  if (isInFlutterWebView()) {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result as string
      const message = { action: 'copyImage', filename, content: base64data }
      window.Flutter!.postMessage(JSON.stringify(message))
      // Listen for a custom event to show success message
      window.addEventListener(
        'imageCopySuccess',
        () => {
          setSuccess('Image copied to clipboard successfully.')
        },
        { once: true }
      )
    }
  } else {
    if (
      window.ClipboardItem &&
      navigator.clipboard &&
      typeof navigator.clipboard.write === 'function'
    ) {
      const item = new ClipboardItem({ 'image/png': blob })
      navigator.clipboard
        .write([item])
        .then(() => setSuccess('Image copied to clipboard successfully.'))
        .catch(error => {
          console.error('Clipboard write failed: ', error)
          // Fallback to downloading if clipboard write fails
          handleImageDownload(blob, filename)
        })
    } else {
      // Fallback if ClipboardItem is not supported or navigator.clipboard.write is not available
      console.warn('Clipboard write is not supported by this browser.')
      handleImageDownload(blob, filename)
    }
  }
}

function openInNewTab(url: string): void {
  window.open(url, '_blank')
}

function copyTextToClipboard(
  text: string,
  onSuccess: () => void,
  onError: (error: any) => void
): void {
  if (
    navigator.clipboard &&
    typeof navigator.clipboard.writeText === 'function'
  ) {
    navigator.clipboard.writeText(text).then(onSuccess).catch(onError)
  } else {
    onError('Clipboard API not supported.')
  }
}

export function onCameraActions(
  topic: PostData,
  action: string | number
): void {
  if (DEBUG_ENV) console.debug('onCameraActionsClick FIRED')

  const container = document.createElement('div')
  document.body.appendChild(container)
  let shareablePostComponent: JSX.Element | JSX.Element[]

  if (topic.postType === PostType.Story) {
    shareablePostComponent = []
    topic.childPosts
      ?.sort((a, b) => a.childPost.date.getTime() - b.childPost.date.getTime())
      .map((post, index) => {
        ;(shareablePostComponent as JSX.Element[]).push(
          <ShareableStoryPost
            post={post.childPost}
            noteText={post.noteText ?? undefined}
            key={index}
          />
        )
      })
  } else {
    shareablePostComponent = <ShareablePost post={topic} />
  }

  switch (action) {
    case CameraActionsMenuItemIds.DOWNLOAD_IMAGE:
      captureAndProcessImage(
        container,
        shareablePostComponent,
        blob => {
          handleImageDownload(blob, `${topic.title}.png`)
        },
        error => {
          setApolloError('Failed to capture image for download.')
        },
        topic
      )
      break

    case CameraActionsMenuItemIds.COPY_IMAGE:
      captureAndProcessImage(
        container,
        shareablePostComponent,
        blob => {
          // Here we attempt to copy the image, with a fallback to download
          attemptToCopyImage(blob!, `${topic.title}.png`)
        },
        error => {
          console.error('Error capturing image for clipboard:', error)
          setApolloError('Failed to capture image for clipboard.')
        },
        topic
      )
      break
    case CameraActionsMenuItemIds.COPY_LINK: {
      const link =
        window.location.origin +
        ROUTES.POST_GRAPHIC.replace(':type', topic.assetType.toLowerCase())
          .replace(':symbol', topic.symbol.toLowerCase())
          .replace(':topicId', topic.id)

      copyTextToClipboard(
        link,
        () => {
          setSuccess('Link copied to clipboard')
        },
        error => {
          console.error('Failed to copy link to clipboard:', error)
          // As a fallback, open the link in a new tab.
          openInNewTab(link)
        }
      )
      break
    }
    case CameraActionsMenuItemIds.OPEN_IN_NEW_TAB: {
      const url = ROUTES.POST_GRAPHIC.replace(
        ':type',
        topic.assetType.toLowerCase()
      )
        .replace(':symbol', topic.symbol.toLowerCase())
        .replace(':topicId', topic.id)
      openInNewTab(window.location.origin + url)
      break
    }
  }
}

export function onCameraActionsComment(
  commentWithParent: CommentData,
  action: string | number
) {
  if (DEBUG_ENV)
    console.debug(
      'screen->Main->TopicSideBarIntegration->onCameraActionsClick FIRED'
    )

  const container = document.createElement('div')
  document.body.appendChild(container)

  const shareableCommentComponent = (
    <ShareableComment commentWithParent={commentWithParent} />
  )

  switch (action) {
    case CameraActionsMenuItemIds.DOWNLOAD_IMAGE: {
      captureAndProcessImage(
        container,
        shareableCommentComponent,
        blob => {
          const filename = commentWithParent.parentPost
            ? `${commentWithParent.parentPost.title}.png`
            : 'download.png'
          handleImageDownload(blob, filename)
        },
        blob => {
          setApolloError('Failed to capture image for download.')
        }
      )

      break
    }
    case CameraActionsMenuItemIds.COPY_IMAGE:
      captureAndProcessImage(
        container,
        shareableCommentComponent,
        blob => {
          // Use attemptToCopyImage to try copying the image to the clipboard,
          // with a fallback to downloading the image if that fails.
          const filename = commentWithParent.parentPost
            ? `${commentWithParent.parentPost.title}.png`
            : 'default-download.png'
          attemptToCopyImage(blob!, filename)
        },
        error => {
          console.error('Error capturing image for clipboard:', error)
          setApolloError('Failed to capture image for clipboard.')
        }
      )
      break
    case CameraActionsMenuItemIds.COPY_LINK: {
      const link =
        window.location.origin +
        ROUTES.COMMENT_GRAPHIC.replace(
          ':type',
          commentWithParent.parentPost?.assetType?.toLowerCase()!
        )
          .replace(
            ':symbol',
            commentWithParent.parentPost?.symbol.toLowerCase()!
          )
          .replace(':topicId', commentWithParent.parentPost?.id!) +
        '?commentid=' +
        commentWithParent.id

      copyTextToClipboard(
        link,
        () => {
          setSuccess('Link copied to clipboard')
        },
        error => {
          console.error('Failed to copy link to clipboard:', error)
          // As a fallback, open the link in a new tab.
          openInNewTab(link)
        }
      )
      break
    }
    case CameraActionsMenuItemIds.OPEN_IN_NEW_TAB: {
      const url =
        ROUTES.COMMENT_GRAPHIC.replace(
          ':type',
          commentWithParent.parentPost?.assetType?.toLowerCase()!
        )
          .replace(
            ':symbol',
            commentWithParent.parentPost?.symbol.toLowerCase()!
          )
          .replace(':topicId', commentWithParent.parentPost?.id!) +
        '?commentid=' +
        commentWithParent.id
      openInNewTab(window.location.origin + url)
      break
    }
  }
}
