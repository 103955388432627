import { AppBar, Toolbar } from '@mui/material'
import theme from '../../mui-theme'
import useStyles from './styles'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import Logo from 'components/Images/Logo'
import { useLocation, useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { useEffect, useState } from 'react'
import { useUnsubscribeMutation } from 'generated/graphql'

export const Unsubscribe = () => {
  const classes = useStyles(theme)
  const deviceType = useDeviceType()
  const location = useLocation()
  const navigate = useNavigate()
  const [unsubscribeResult, setUnsubscribeResult] = useState('')
  const [error, setError] = useState('') // Add state for error
  const [unsubscribe] = useUnsubscribeMutation()

  const logoClick = () => {
    navigate(ROUTES.HOME)
  }

  // Function to parse the query string and extract 'token'
  const query = new URLSearchParams(location.search)
  const token = query.get('token')

  useEffect(() => {
    if (token) {
      unsubscribe({ variables: { token } })
        .then(response => {
          const emailType = response.data?.unsubscribe!
          setUnsubscribeResult(emailType)
          setError('') // Reset error on successful response
        })
        .catch(err => {
          console.error('Unsubscribe error:', err)
          setError('InvalidToken') // Set error state on failure
        })
    }
  }, [token, unsubscribe])

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          {deviceType === DeviceType.Mobile && (
            <div className={classes.LogoWithoutTextWrapper}>
              <LogoWithoutText />
            </div>
          )}
          {deviceType === DeviceType.Desktop && <Logo onClick={logoClick} />}
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            {error === 'InvalidToken'
              ? "The unsubscribe token is invalid. Please ensure you've used a valid link."
              : `You have unsubscribed from email notifications for notification type '${unsubscribeResult}'.`}
          </div>
        </div>
      </main>
    </div>
  )
}

export default Unsubscribe
