import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePostHog } from 'posthog-js/react'
import {
  globalErrors,
  globalLoading,
  globalSuccess,
} from './apollo/reactive-vars'

import { useReactiveVar } from '@apollo/client'

import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import { AppRoutes } from './constants/routes'
import { LoadingProgress } from 'components/LoadingProgress'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const App: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [getLoadingState, setLoadingState] = useState(false)
  const posthog = usePostHog()

  // Run the below block of code (incl. UseEffect) on every address change
  // Based on the documentation at https://www.robinwieruch.de/react-hooks-fetch-data/
  // Also based on: https://github.com/facebook/react/issues/14326
  // TODO: Future solution in react is: https://reactjs.org/docs/concurrent-mode-suspense.html
  //const [getUser] = useGetCurrentUserLazyQuery({})

  //let location = useLocation()

  //useAsyncEffect(
  //  async isMounted => {
  //    if (!isMounted()) return
  //    await getUser()
  //  },
  //  [location]
  //)

  // by default posthog doesn't capture page changes in our single page web app
  // manually tell posthog about those
  React.useEffect(() => {
    posthog?.capture('$pageview')
  }, [location])

  const errorMessages = useReactiveVar(globalErrors)
  const successMessages = useReactiveVar(globalSuccess)
  const loadingIds = useReactiveVar(globalLoading)

  function onDismissError() {
    globalErrors(errorMessages.slice(0, errorMessages.length - 1))
  }

  function onDismissSuccess() {
    globalSuccess(errorMessages.slice(0, errorMessages.length - 1))
  }

  function onDismissLoading() {
    globalLoading(loadingIds.slice(0, loadingIds.length - 1))
  }

  const errorMessage =
    (errorMessages.length && errorMessages[errorMessages.length - 1].message) ||
    undefined

  const successMessage =
    (successMessages.length &&
      successMessages[successMessages.length - 1].message) ||
    undefined

  const loadingId =
    (loadingIds.length && loadingIds[loadingIds.length - 1].id) || undefined

  return (
    <div className="App">
      <AppRoutes />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={onDismissError}
      >
        <Alert onClose={onDismissError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={onDismissSuccess}
      >
        <Alert onClose={onDismissSuccess} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {getLoadingState && <LoadingProgress />}
    </div>
  )
}

export default App
