import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import UserAvatar from 'components/UserAvatar'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { formatWithEllipsis } from 'utils/stringUtils'
import { IBaseNotification, IPostReplyNotification } from 'types/notification'

interface Props {
  notification: IPostReplyNotification
  limitTextCharacters?: number
  onClose?: () => void
}

const PostReplyNotificationRow: React.FC<Props> = props => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const handleNotificationClick = () => {
    props.onClose && props.onClose()
    navigate(
      `/${props.notification.assetType}/${props.notification.symbol}/topic/${props.notification.postId}?commentid=${props.notification.commentId}`.toLowerCase()
    )
  }

  return (
    <div
      onClick={handleNotificationClick}
      className={classNames(
        classes.notificationWrapper,
        !props.notification.read && classes.unreadNotification
      )}
    >
      <div className={classes.avatarWrapper}>
        <UserAvatar emailHash={props.notification.emailHash} />
      </div>
      <div className={classes.notificationContentWrapper}>
        <div className={classes.notificationTopContentWrapper}>
          <div className={classes.nickname}>{props.notification.nickname}</div>
          <div className={classes.symbol}>{props.notification.symbol}</div>
          <div className={classes.date}>
            {getTimeElapsedRounded(props.notification.createdAt)}
          </div>
        </div>
        <div className={classes.notificationBottomContentWrapper}>
          {formatWithEllipsis(
            props.notification.text,
            props.limitTextCharacters ?? 999
          )}
        </div>
      </div>
    </div>
  )
}

export default PostReplyNotificationRow
