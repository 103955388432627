import classNames from 'classnames'
import theme from '../../mui-theme'
import useStyles from './styles'
import { Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import UserAvatar from 'components/UserAvatar'
import { useGetCurrentUserQuery } from 'generated/graphql'
import { useUserOperations } from 'hooks/useUserOperations'
import AccountSettingsPanel from 'components/UserSettingsSidebar/AccountSettingsPanel'
import ProfileSettingsPanel from 'components/UserSettingsSidebar/ProfileSettingsPanel'
import NotificationSettingsPanel from 'components/NotificationSettingsPanel'

export const UserSettingsEditMobile = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const location = useLocation()

  const defaultTab = location.state?.selectedTab || 0
  const [selectedTabItemIndex, setSelectedTabItemIndex] = useState(defaultTab)

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  const { setPassword, profileSave, accountSave, changePasswordError } =
    useUserOperations()

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newSelectedTabIndex: number
  ) => {
    setSelectedTabItemIndex(newSelectedTabIndex)
  }

  const handleEditClick = () => {
    window.open('https://en.gravatar.com/emails/', '_blank')
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.topWrapper}>
        <div className={classes.topNavBar}>
          <div className={classes.iconBackWrapper}>
            <FontAwesomeIcon
              icon={faAngleLeft}
              className={classes.iconBack}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className={classes.header}>User Settings</div>
          <div className={classes.headerRight}></div>
        </div>
        <Tabs
          value={selectedTabItemIndex}
          onChange={handleTabChange}
          aria-label="user tabs"
          sx={{
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          }}
          classes={{
            scroller: classes.tabsScroller,
          }}
        >
          <Tab
            key={'0'}
            className={classNames(
              classes.tab,
              selectedTabItemIndex === 0 ? classes.selectedTab : ''
            )}
            label={<div>Profile</div>}
          />
          <Tab
            key={'1'}
            className={classNames(
              classes.tab,
              selectedTabItemIndex === 1 ? classes.selectedTab : ''
            )}
            label={<div>Account</div>}
          />
          <Tab
            key={'2'}
            className={classNames(
              classes.tab,
              selectedTabItemIndex === 2 ? classes.selectedTab : ''
            )}
            label={<div>Notifications</div>}
          />
        </Tabs>
      </div>
      <div className={classes.mainSectionWrapper}>
        {selectedTabItemIndex === 0 && (
          <>
            <div className={classes.profileHeader}>
              <div className={classes.avatarWrapper}>
                <UserAvatar
                  email={getCurrentUserData?.getCurrentUser.email}
                  size={120}
                />
              </div>
              <div className={classes.emailChangePhotoWrapper}>
                <div
                  className={classes.emailChangePhoto}
                  onClick={handleEditClick}
                >
                  Change photo
                </div>
                <div className={classes.email}>
                  {getCurrentUserData?.getCurrentUser.email}
                </div>
              </div>
            </div>
            <ProfileSettingsPanel
              id={getCurrentUserData?.getCurrentUser?.id || ''}
              username={getCurrentUserData?.getCurrentUser?.nickname || ''}
              reddit={getCurrentUserData?.getCurrentUser?.reddit || ''}
              twitter={getCurrentUserData?.getCurrentUser?.twitter || ''}
              facebook={getCurrentUserData?.getCurrentUser?.facebook || ''}
              instagram={getCurrentUserData?.getCurrentUser?.instagram || ''}
              tradingView={
                getCurrentUserData?.getCurrentUser?.tradingView || ''
              }
              onProfileSaveClick={profileSave}
            />
          </>
        )}
        {selectedTabItemIndex === 1 && (
          <div className={classes.accountSettingsWrapper}>
            <AccountSettingsPanel
              id={getCurrentUserData?.getCurrentUser?.id || ''}
              email={getCurrentUserData?.getCurrentUser?.email || ''}
              timezone={getCurrentUserData?.getCurrentUser?.timeZone || ''}
              social={getCurrentUserData?.getCurrentUser?.social}
              password_error={changePasswordError}
              onAccountSaveClick={accountSave}
              onPasswordChange={setPassword}
            />
          </div>
        )}
        {selectedTabItemIndex === 2 && (
          <div className={classes.accountSettingsWrapper}>
            <NotificationSettingsPanel />
          </div>
        )}
      </div>
    </div>
  )
}

export default UserSettingsEditMobile
