import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import {
  useGetCurrentUserQuery,
  useGetFollowersQuery,
  useGetFollowingQuery,
  useGetUserByNicknameQuery,
} from 'generated/graphql'
import UserOverlay from 'components/UserOverlay'
import { UserProfile } from 'components/UserOverlay/interfaces/user'
import { useNavigate, useParams } from 'react-router-dom'
import ROUTES from 'constants/routes'
import md5 from 'md5'
import { LoadingProgress } from 'components/LoadingProgress'

const User: React.FC = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const { username } = useParams<'username'>()
  const [fetchUser, setFetchUser] = useState(false)
  const [currentUserId, setCurrentUserId] = useState<string | undefined>()

  const { data: currentUser } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  const { data: currentUserFollowing } = useGetFollowingQuery({
    variables: { userId: currentUserId || '' },
    skip: !currentUserId,
    fetchPolicy: 'cache-first',
  })

  const { data: currentUserFollowers } = useGetFollowersQuery({
    variables: { userId: currentUserId || '' },
    skip: !currentUserId,
    fetchPolicy: 'cache-first',
  })

  const { data: getUserByNicknameCurrentUser } = useGetUserByNicknameQuery({
    variables: { nickname: currentUser?.getCurrentUser.nickname || '' },
    skip: !fetchUser,
  })

  const {
    data: getUserByNickname,
    loading,
    error,
  } = useGetUserByNicknameQuery({
    variables: { nickname: username || '' },
    skip: !fetchUser,
  })

  useEffect(() => {
    if (username) {
      setFetchUser(true)
    }
  }, [username])

  useEffect(() => {
    if (currentUser?.getCurrentUser) {
      setCurrentUserId(currentUser.getCurrentUser.id)
    }
  }, [currentUser])

  const isCurrentUserFollowing = !!currentUser?.getCurrentUser?.id
    ? currentUserFollowing?.getFollowing
        ?.map(following => following.id)
        .includes(getUserByNickname?.getUserByNickname?.id!)
    : false

  const userProfile: UserProfile | null = getUserByNickname?.getUserByNickname
    ? {
        userId: getUserByNickname.getUserByNickname.id,
        nickname: getUserByNickname.getUserByNickname.nickname,
        reddit: getUserByNickname.getUserByNickname.reddit,
        twitter: getUserByNickname.getUserByNickname.twitter,
        facebook: getUserByNickname.getUserByNickname.facebook,
        createdAt: getUserByNickname.getUserByNickname.createdAt,
        emailHash: getUserByNickname.getUserByNickname.emailHash,
        isCurrentUserFollowing: isCurrentUserFollowing ?? false,
        followersCount: getUserByNickname.getUserByNickname.followersCount,
        followingCount: getUserByNickname.getUserByNickname.followingCount,
        blocked: getUserByNickname.getUserByNickname.blocked,
      }
    : null

  const currentUserProfile: UserProfile | undefined =
    currentUser?.getCurrentUser
      ? {
          userId: currentUser?.getCurrentUser?.id,
          nickname: currentUser?.getCurrentUser?.nickname,
          reddit: currentUser?.getCurrentUser?.reddit,
          twitter: currentUser?.getCurrentUser?.twitter,
          facebook: currentUser?.getCurrentUser?.facebook,
          instagram: currentUser?.getCurrentUser?.instagram,
          tradingView: currentUser?.getCurrentUser?.tradingView,
          createdAt: currentUser?.getCurrentUser?.createdAt,
          email: currentUser?.getCurrentUser?.email,
          timeZone: currentUser?.getCurrentUser?.timeZone,
          social: currentUser?.getCurrentUser?.social,
          emailHash: md5(
            currentUser?.getCurrentUser?.email.toLowerCase().trim()
          ),
          isCurrentUserFollowing: isCurrentUserFollowing ?? false,
          followersCount:
            getUserByNicknameCurrentUser?.getUserByNickname?.followersCount ??
            0,
          followingCount:
            getUserByNicknameCurrentUser?.getUserByNickname?.followingCount ??
            0,
          followerIds: currentUserFollowers?.getFollowers?.map(
            follower => follower.id
          ),
        }
      : undefined

  if (error) {
    navigate(ROUTES.NOT_FOUND)
    return null
  }

  return (
    <div className={classes.wrapper}>
      {userProfile && (
        <UserOverlay
          currentUserProfile={currentUserProfile}
          userProfile={userProfile}
          hideClose={true}
        />
      )}
      {loading && <LoadingProgress />}
    </div>
  )
}

export default User
