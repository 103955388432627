import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles({
  header: {
    fontSize: 15,
    fontFamily: fonts.dm_sans,
    color: colors.text_white,
    textAlign: 'left',
  },

  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.drawer_bg,
  },

  topButton: {
    height: 55,
    width: '50%',
    backgroundColor: colors.dark_blue,
    color: colors.textGrey,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  buttonText: {
    fontFamily: fonts.dm_sans,
    marginLeft: 12,
    fontSize: 13,
    letterSpacing: 0,
  },

  buttonSelected: {
    backgroundColor: colors.blue,
    color: colors.white,
  },

  buttonsWrapper: {
    display: 'flex',
  },

  navigationWrapper: {
    height: 40,
    width: '100%',
    textAlign: 'left',
  },

  backArrow: {
    color: colors.textGrey,
    margin: '14px 16px 12px',
    cursor: 'pointer',
  },

  contentWrapper: {
    position: 'relative',
    height: 'calc(100% - 55px)',
    overflowY: 'auto',
  },

  formWrapper: {
    position: 'relative',
    padding: '16px 32px 16px 32px',
    height: 'calc(100% - 72px)',
  },

  form: {
    width: '100%',
    height: 'calc(100% - 43px)',
  },

  inputWrapper: {
    marginTop: 16,
    width: '100%',
    flex: 1,
  },

  textInputWrapper: {},

  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },

  button: {
    width: 'auto',
    marginBottom: 16,
    padding: '10px 24px',
    fontSize: 10,
    fontWeight: 700,
  },

  saveButton: {
    marginLeft: 8,
  },

  cancelButton: {
    color: colors.textGrey,
    backgroundColor: colors.drawer_bg,
    transitionDuration: '50ms',
    '&:hover': {
      backgroundColor: colors.input_bg,
      boxShadow: 'none',
    },
    boxShadow: 'none',
  },
})
