import React, { useState } from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import SearchOverlay from 'components/SearchOverlay'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'generated/graphql'
import { TabIdentifiers } from 'components/SearchOverlay/constants/tabConstants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import ROUTES from 'constants/routes'

const SentimentDiscovery: React.FC = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const deviceType = useDeviceType()
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  const goToUserSettingsMobile = () => {
    navigate(ROUTES.USER_MENU_MOBILE)
  }

  return (
    <div className={classes.wrapper}>
      <SearchOverlay
        hideClose={deviceType === DeviceType.Mobile ? true : false}
        startTab={TabIdentifiers.TRENDING}
        onClose={() => navigate(-1)}
        email={getCurrentUserData?.getCurrentUser?.email || ''}
        userId={getCurrentUserData?.getCurrentUser?.id || ''}
        signUp={() => window.open(ROUTES.SIGNUP, '_self')}
        logIn={() => window.open(ROUTES.LOGIN, '_self')}
        showMobileMenu={showMobileMenu}
        focusSearchOnOpen={true}
        goToUserSettingsMobile={goToUserSettingsMobile}
      />
    </div>
  )
}

export default SentimentDiscovery
