import { makeStyles } from '@mui/styles'
import theme from '../../mui-theme'
import { colors } from '../../constants'

export default makeStyles({
  wrapper: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    backgroundColor: colors.search_bg,
    boxSizing: 'border-box',

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      '&-thumb': {
        backgroundColor: colors.box_bg,
      },
    },

    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
})
