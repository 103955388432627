import Box from '@mui/material/Box'
import React from 'react'
import { useForm } from 'react-hook-form'
import AuthBoxContainer from './common/AuthBoxContainer'
import ResendVerificationLink from './common/ResendVerificationLink'

interface ConfirmEmailWithCodeBoxProps {
  onSubmit: (code: string) => void
  errors?: {
    code?: string
  }
  email: string
  onResendVerificationCode: () => void
}

interface ConfirmEmailWithCodeFormData {
  code: string
}

const ConfirmEmailWithCodeBox: React.FC<
  ConfirmEmailWithCodeBoxProps
> = props => {
  const { register, handleSubmit, errors, setError } =
    useForm<ConfirmEmailWithCodeFormData>()

  const onSubmit = (data: ConfirmEmailWithCodeFormData) => {
    props.onSubmit(data.code)
  }

  React.useEffect(() => {
    if (props.errors?.code) {
      setError('code', {
        type: 'manual',
        message: props.errors?.code,
      })
    }
  }, [props.errors?.code, setError])

  return (
    <AuthBoxContainer
      headingText={`Please confirm your email`}
      descriptionText={`We’ve sent a verification email to ${props.email}. Please check your email for the verification link. If you don't find it in your inbox, please check your spam folder."`}
    >
      <form onSubmit={handleSubmit(onSubmit)}></form>
      <Box
        sx={{
          textAlign: 'center',
          mt: 3,
        }}
      >
        <ResendVerificationLink
          onResendVerificationLink={props.onResendVerificationCode}
        />
      </Box>
    </AuthBoxContainer>
  )
}

export default ConfirmEmailWithCodeBox
