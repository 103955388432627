import React, { forwardRef } from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Menu, MenuItem } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { colors } from '../../../constants'

export interface PopupMenuItem {
  id: string | number
  text: string
  icon?: IconDefinition
  iconSvg?: React.FC<{}>
}

interface Props {
  iconToolTip?: string
  menuIcon: IconDefinition
  actionsMenuItems: PopupMenuItem[]
  size?: SizeProp
  backgroundColor?: string
  onActionClick: (id: string | number) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const [actionsEl, setActionsEl] = React.useState<Element | null>(null)

  const handleActionsClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setActionsEl(event.currentTarget)
  }

  const handleActionsClose = () => {
    setActionsEl(null)
  }

  const onActionClick = (id: string | number) => {
    props.onActionClick(id)
    setActionsEl(null)
  }

  return (
    <>
      <div className={classes.iconWrapper}>
        {props.iconToolTip && (
          <Tooltip title={props.iconToolTip}>
            <div className={classes.toolTipWrapper}>
              <FontAwesomeIcon
                icon={props.menuIcon}
                className={classes.icon}
                size={props.size || 'sm'}
                onClick={handleActionsClick}
              />
            </div>
          </Tooltip>
        )}
        {!props.iconToolTip && (
          <FontAwesomeIcon
            icon={props.menuIcon}
            className={classes.icon}
            size={props.size || 'sm'}
            onClick={handleActionsClick}
          />
        )}
      </div>
      <Menu
        anchorEl={actionsEl}
        keepMounted
        elevation={1}
        open={Boolean(actionsEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleActionsClose}
        PaperProps={{
          style: {
            backgroundColor: props.backgroundColor || colors.menu_bg,
          },
        }}
      >
        {props.actionsMenuItems.map(item => (
          <MenuItem
            sx={{
              '.MuiTouchRipple-root': {
                display: 'none',
              },
              width: item.iconSvg ? 242 : 'auto',
              display: 'flex',
              justifyContent: item.iconSvg ? 'space-between' : 'flex-start',
              '&.Mui-focusVisible': {
                backgroundColor: props.backgroundColor || 'inherit',
              },
              '&:hover': {
                backgroundColor: props.backgroundColor || 'inherit',
              },
              paddingTop: props.actionsMenuItems.length == 1 ? 0 : '6px',
              paddingBottom: props.actionsMenuItems.length == 1 ? 0 : '6px',
            }}
            key={item.id}
            onClick={() => onActionClick(item.id)}
          >
            {!!item.icon && (
              <FontAwesomeIcon
                icon={item.icon}
                className={classes.iconMenuItem}
                size="sm"
              />
            )}
            <span className={classes.menuItemText}>{item.text}</span>
            {!!item.iconSvg && <item.iconSvg />}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
})
