import React from 'react'

const ArrowRedCircleIcon: React.FC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="40" fill="#D36D6D" />
    <mask
      id="mask0_6741_73750"
      maskUnits="userSpaceOnUse"
      x="16"
      y="16"
      width="48"
      height="48"
    >
      <rect
        x="64"
        y="64"
        width="48"
        height="48"
        transform="rotate(180 64 64)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_6741_73750)">
      <path
        d="M41.1346 25L41.1346 50.6846L53.4 38.4192L55 40L40.0001 54.9999L25.0001 40L26.6001 38.4192L38.8654 50.6846L38.8654 25L41.1346 25Z"
        fill="white"
      />
    </g>
  </svg>
)

export default ArrowRedCircleIcon
