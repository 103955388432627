import React from 'react'

const ArrowBlueCircleIcon: React.FC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="40" fill="#4C61EE" />
    <mask
      id="mask0_6741_73742"
      maskUnits="userSpaceOnUse"
      x="16"
      y="16"
      width="48"
      height="48"
    >
      <rect x="16" y="16" width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6741_73742)">
      <path
        d="M38.8654 55V29.3154L26.6 41.5808L25 40L39.9999 25.0001L54.9999 40L53.3999 41.5808L41.1346 29.3154V55H38.8654Z"
        fill="white"
      />
    </g>
  </svg>
)

export default ArrowBlueCircleIcon
