import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

const drawerWidth = 180
const drawerWidthClosed = 70
const openCloseBtnSize = 24
const openCloseBtnOffset = (drawerWidthClosed - openCloseBtnSize) / 2
const logoImgSize = 29

export default makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  searchOverlayWrapper: {
    zIndex: 99999,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  },
  appBar: {
    zIndex: 10000,
    justifyContent: 'center',
    boxShadow: 'none',

    [theme.breakpoints.down('lg')]: {
      zIndex: 'unset',
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
  toolbarInner: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  toolbarWrapper: {
    display: 'flex',
    minHeight: 32,
    textAlign: 'center',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 16px',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
      margin: '0 0',
    },
  },
  toolbarTop: {
    display: 'flex',
    width: '100%',
    minHeight: 32,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbarBottom: {
    display: 'flex',
    paddingTop: '16px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  storyTitle: {
    color: colors.white_faded,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    padding: '6px 8px',
    backgroundColor: colors.tab_bg,
    borderRadius: '6px',
  },
  symbol: {
    color: colors.white_faded,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '24px',
    flexShrink: 0,
    flexGrow: 0,
  },

  symbolName: {
    color: colors.textGreyLight,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    marginLeft: 8,
    marginTop: 6,
    flexShrink: 0,
    flexGrow: 0,

    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  addTopicsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
    position: 'relative',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    backgroundColor: colors.drawer_bg,
    paddingTop: 70,
    height: 'calc(100% - 70px)',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: 'width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  drawerPaperStory: {
    backgroundColor: colors.drawer_bg,
    paddingTop: 118,
    height: 'calc(100% - 118px)',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: 'width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: 'width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    width: drawerWidthClosed,
  },
  drawerPaperRight: {
    backgroundColor: colors.drawer_bg_right,
  },
  LogoWithoutTextWrapper: {
    width: 30,
    height: 30,
  },
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    padding: 0,
    overflow: 'auto',
    backgroundColor: colors.drawer_bg_right,

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.drawer_bg,
    },
  },
  flex: {
    flex: 1,
  },
  avatar: {
    cursor: 'pointer',
  },
  openCloseBtn: {
    backgroundColor: colors.blue,
    color: colors.white,
    width: openCloseBtnSize,
    height: openCloseBtnSize,
    minWidth: openCloseBtnSize,
    borderRadius: 0,
    position: 'absolute',
    bottom: openCloseBtnOffset,
    right: openCloseBtnOffset,
    padding: 0,
  },
  closeBtnRight: {
    backgroundColor: 'transparent',
    color: colors.textGrey,
    right: '21px',
    top: '17px',
    padding: 0,
    width: '13px',
    height: '13px',
    position: 'absolute',
  },
  logoImg: {
    width: logoImgSize,
    height: logoImgSize,
    marginRight: '8px',
  },
  logoText: {
    fontFamily: fonts.dm_sans,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  searchIcon: {
    cursor: 'pointer',
    margin: '0 8px 0 0',
    height: 24,
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 'normal',
    letterSpacing: '2px',
    marginLeft: 'auto',
  },
})
