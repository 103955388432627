import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'
import MarkdownEditor from 'components/inputs/MarkdownEditor'
import useTickerValidation from 'hooks/useTickerValidation'
import { colors } from '../../../constants'
import { BrandButton } from 'components/common/BrandButton'
import { Checkbox, FormControlLabel, checkboxClasses } from '@mui/material'
import { AnyAaaaRecord } from 'dns'

interface CommentReplyMobileBoxProps {
  onSubmit: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean
  ) => Promise<any> | null
  onCancel: () => void
  onClose: () => void
  onTextChange?: (text: string) => void
  initialText?: string
  submitButtonText?: string
  hideCancelButton?: boolean
  replyNotificationEmail: boolean
}

interface CommentInput {
  text: string
  notifyViaEmail: boolean
}

const COMMENT_MIN_LENGTH = 1

export const CommentReplyMobileBox: React.FC<
  CommentReplyMobileBoxProps
> = props => {
  const { validateTicker, extractTickers, symbols } = useTickerValidation()
  const [touchStartY, setTouchStartY] = useState(null)
  const [boxBottom, setBoxBottom] = useState(0)
  const boxRef = useRef<HTMLDivElement>(null)
  const [initialTouchY, setInitialTouchY] = useState<number>(0)
  const [lastTouchY, setLastTouchY] = useState<number>(0)

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm<CommentInput>({
    defaultValues: {
      text: props.initialText,
      notifyViaEmail: false,
    },
  })

  const onSubmit = (data: CommentInput) => {
    extractTickers(data.text)
    props.onSubmit(data.text, symbols, data.notifyViaEmail)?.then(() => reset())
  }

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchY = e.touches[0].clientY
    setInitialTouchY(touchY)
    setLastTouchY(touchY)
    if (e.currentTarget.style) {
      e.currentTarget.style.transition = 'none'
    }
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const currentTouchY = e.touches[0].clientY
    const touchDelta = currentTouchY - initialTouchY

    if (boxRef.current) {
      const currentStyle = boxRef.current.style
      const newTransform = `translateY(${touchDelta}px)`
      requestAnimationFrame(() => {
        currentStyle.transform = newTransform
      })
    }
  }

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (boxRef.current && boxRef.current.style) {
      boxRef.current.style.transition = 'transform 0.3s ease'
      boxRef.current.style.transform = ''
    }

    const currentTouchY = e.changedTouches[0].clientY
    const dragDistance = currentTouchY - initialTouchY
    const closeThreshold = window.innerHeight * 0.3

    if (dragDistance > closeThreshold) {
      props.onClose()
    }

    setInitialTouchY(0)
  }

  useEffect(() => {
    register(
      { name: 'text' },
      {
        required: 'The text field is required',
        validate: validateTicker,
        minLength: {
          message: `The text length needs to be at least ${COMMENT_MIN_LENGTH} characters.`,
          value: COMMENT_MIN_LENGTH,
        },
      }
    )
  })

  return (
    <Box
      ref={boxRef}
      sx={{
        zIndex: 9999,
        touchAction: 'none',
        position: 'fixed',
        bottom: `${boxBottom}px`,
        transition: 'bottom 0.3s ease',
        left: 0,
        padding: '16px',
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: colors.drawer_bg_right,
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.20)',
      }}
    >
      <Box
        style={{ display: 'flex', justifyContent: 'center' }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Box
          sx={{
            width: '60px',
            height: '6px',
            backgroundColor: colors.search_bg,
            borderRadius: '100px',
            marginBottom: 2,
          }}
        ></Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={<MarkdownEditor name="text" />}
          control={control}
          label="Text"
          name="text"
          onInput={(input: string) => {
            if (input !== getValues().text) {
              props.onTextChange?.(input)
            }

            setValue('text', input, { shouldValidate: true })
            extractTickers(input)
          }}
          value={getValues().text}
          error={errors.text ? errors.text.message : undefined}
        />
        {!props.replyNotificationEmail && (
          <Controller
            name="notifyViaEmail"
            control={control}
            defaultValue={false}
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => onChange(e.target.checked)}
                    onBlur={onBlur}
                    checked={value}
                    name={name}
                    ref={ref}
                    sx={{
                      color: colors.white_faded,
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label="Notify me via email when someone replies"
                sx={{
                  color: colors.textGreyLight,
                  margin: '8px 0 0 0',
                  [`& span.${checkboxClasses.checked}`]: {
                    color: 'white',
                  },
                }}
              />
            )}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <BrandButton
            sx={{
              width: '100%',
              borderRadius: '8px',
              height: 46,
              marginTop: 2,
            }}
            type="submit"
          >
            {!!props?.submitButtonText ? props.submitButtonText : 'POST'}
          </BrandButton>
        </Box>
      </form>
    </Box>
  )
}
