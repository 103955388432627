import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Drawer, IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import Toolbar from '@mui/material/Toolbar'
import classNames from 'classnames'
import SearchOverlay from 'components/SearchOverlay'
import ROUTES from 'constants/routes'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import {
  useGetCurrentUserQuery,
  useGetSymbolQuery,
  useIncrementTrendingSymbolMutation,
} from 'generated/graphql'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from '../../constants'
import theme from '../../mui-theme'
import Logo from '../Images/Logo'
import useStyles from './styles'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import useWindowHeight from 'hooks/useWindowHeight'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import AuthToolbar from 'components/common/AuthToolbar'
import { LoadingProgress } from 'components/LoadingProgress'

interface Props {
  signUp: () => void
  logIn: () => void
  toggleLeftPanel?: (open: boolean) => void
  closeRightPanel?: () => void
  children: [React.ReactNode, React.ReactNode, React.ReactNode]
  isLoggedIn?: boolean
  email?: string
  nickname?: string
  userId?: string
  loading?: boolean
  errorMessage?: string
  isStoryEdit?: boolean
  submitStory?: () => void
  storyTitle?: string
}

const NavigationFrame: React.FC<Props> = props => {
  const deviceType = useDeviceType()
  const windowHeight = useWindowHeight(deviceType)
  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  let userLastViewedSymbol = 'spy'
  let userLastViewedSymbolType = 'stock'

  if (getCurrentUserData?.getCurrentUser) {
    userLastViewedSymbol =
      getCurrentUserData?.getCurrentUser?.lastViewedSymbol || ''
  }

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: getCurrentUserData?.getCurrentUser?.lastViewedSymbol || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  if (symbolData?.getSymbol?.type) {
    userLastViewedSymbolType = symbolData.getSymbol?.type.toLowerCase()
  }

  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openRight, setOpenRight] = useState(false)
  const classes = useStyles(theme)
  const location = useLocation()
  const [incrementTrendingSymbol] = useIncrementTrendingSymbolMutation()

  const handleDrawerOpen = () => {
    setOpen(prevOpen => {
      const newOpen = !prevOpen
      props.toggleLeftPanel && props.toggleLeftPanel(newOpen)
      return newOpen
    })
  }

  const goToUserSettingsMobile = () => {
    navigate(ROUTES.USER_MENU_MOBILE)
  }

  const handleRightPanelClose = () => {
    setOpenRight(false)
    props.closeRightPanel && props.closeRightPanel()
  }

  const getTicker = () => {
    const uppercasedPath = location.pathname.toUpperCase()

    const assetType = Object.values(AssetTypeEnum).find(type =>
      uppercasedPath.includes(type.toUpperCase())
    )

    if (assetType) {
      const ticker = uppercasedPath
        .split(`${assetType.toUpperCase()}/`)[1]
        .split('/')[0]
      return ticker
    } else {
      return 'Search Stock Ticker'
    }
  }

  const { data: currentSymbol } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: getTicker() || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  const logoClick = () => {
    if (props.isLoggedIn) {
      navigate(
        ROUTES.ASSET.replace(':symbol', userLastViewedSymbol)
          .replace(':type', userLastViewedSymbolType)
          .toLowerCase()
      )
    } else {
      navigate(ROUTES.HOME)
    }
  }

  const isFirstTimeLoggedIn = sessionStorage.getItem('isFirstTimeLoggedIn')

  React.useEffect(() => {
    if (isFirstTimeLoggedIn !== 'false') {
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
      sessionStorage.setItem('isFirstTimeLoggedIn', 'false')
    }
  }, [isFirstTimeLoggedIn])

  React.useEffect(() => {
    incrementTrendingSymbol({
      variables: {
        symbol: getTicker(),
      },
    })
  }, [])

  return (
    <>
      <div className={classes.root} style={{ height: `${windowHeight}px` }}>
        <AppBar
          position="absolute"
          className={classes.appBar}
          style={{ height: !!props.storyTitle ? '118px' : '70px' }}
        >
          <Toolbar className={classes.toolbar} disableGutters>
            <div
              className={classes.toolbarInner}
              style={{ alignItems: !!props.storyTitle ? 'start' : 'center' }}
            >
              {deviceType === DeviceType.Desktop && (
                <Logo onClick={logoClick} />
              )}
              {location.pathname !== '/' &&
                location.pathname !== '/bullsandbears' && (
                  <>
                    <div
                      className={classes.toolbarWrapper}
                      onClick={() =>
                        !props.isStoryEdit &&
                        navigate(ROUTES.SENTIMENT_DISCOVERY)
                      }
                    >
                      <div className={classes.toolbarTop}>
                        <div className={classes.symbol}>
                          {currentSymbol?.getSymbol?.symbol || ''}
                        </div>
                        <div className={classes.symbolName}>
                          {currentSymbol?.getSymbol?.name || ''}
                        </div>
                        {!props.isStoryEdit && (
                          <FontAwesomeIcon
                            icon={faSearch}
                            className={classes.searchIcon}
                            color={colors.textGrey}
                          />
                        )}
                        {props.isStoryEdit && (
                          <div style={{ marginLeft: 'auto' }}>&nbsp;</div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              <AuthToolbar
                isLoggedIn={props.isLoggedIn}
                email={props.email}
                logIn={props.logIn}
                signUp={props.signUp}
                goToUserSettingsMobile={goToUserSettingsMobile}
                setOpenRight={setOpenRight}
              />
            </div>
            {!!props.storyTitle && (
              <div className={classes.toolbarBottom}>
                <div className={classes.storyTitle}>{props.storyTitle}</div>
              </div>
            )}
          </Toolbar>
        </AppBar>

        {/* LEFT SIDE MENU */}
        {/* Hide on home page */}
        {deviceType === DeviceType.Desktop &&
          location.pathname !== '/' &&
          location.pathname !== '/bullsandbears' && (
            <Drawer
              variant="permanent"
              classes={{
                paper: classNames(
                  !!props.storyTitle
                    ? classes.drawerPaperStory
                    : classes.drawerPaper,
                  !open && classes.drawerPaperClose
                ),
              }}
              open={open}
            >
              {props.children[0]}
              <Button
                variant="contained"
                onClick={handleDrawerOpen}
                className={classes.openCloseBtn}
              >
                <FontAwesomeIcon icon={open ? faChevronLeft : faChevronRight} />
              </Button>
            </Drawer>
          )}
        {/* RIGHT SIDE USER MENU */}
        <Drawer
          anchor="right"
          open={props.isLoggedIn && openRight}
          onClose={() => handleRightPanelClose()}
          classes={{
            paper: classes.drawerPaperRight,
          }}
        >
          <IconButton
            onClick={() => handleRightPanelClose()}
            className={classes.closeBtnRight}
            edge="end"
            size="small"
          >
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
          {props.children[1]}
        </Drawer>
        <main
          className={classes.content}
          style={{ paddingTop: !!props.storyTitle ? '118px' : '70px' }}
        >
          {props.children[2]}
          {props.loading && <LoadingProgress />}
        </main>
      </div>
    </>
  )
}

export default NavigationFrame
