// useFeedManagement.js
import { useState, useEffect, useCallback, useMemo } from 'react'
import {
  useGetFeedQuery,
  useGetTrendingPostsQuery,
  useGetNewestPostsQuery,
  useGetCurrentUserQuery,
} from 'generated/graphql'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { NewsPostItem } from '../interfaces/newsTabItem'
import { mapPost } from 'utils/mappers/postsMapper'
import { mapSingleComment } from 'utils/mappers/commentsMapper'
import { ExDate } from '../../../constants'

const useFeedManagement = () => {
  const {
    data: feed,
    refetch: refetchFeed,
    fetchMore: fetchMoreFeed,
  } = useGetFeedQuery({
    fetchPolicy: 'cache-first',
  })
  const { data: trendingPosts, refetch: refetchTrendingPosts } =
    useGetTrendingPostsQuery({
      fetchPolicy: 'cache-first',
    })
  const {
    data: newestPosts,
    refetch: refetchNewestPosts,
    fetchMore: fetchMoreNewestPosts,
  } = useGetNewestPostsQuery({
    fetchPolicy: 'cache-first',
  })
  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  const [hasMoreFeedItems, setHasMoreFeedItems] = useState(true)
  const [hasMoreFeedItemsNextOffset, setHasMoreFeedItemsNextOffset] =
    useState(null)
  const [feedItems, setFeedItems] = useState<(PostData | CommentData)[]>([])

  const mapToNewsPostItem = (post: any): NewsPostItem => {
    const mappedPost = mapPost(post)

    return {
      post: mappedPost,
      type: post.postType,
      date: new ExDate(post.date),
      symbolName: null,
      isCreator: post.user?.id === getCurrentUserData?.getCurrentUser?.id,
    }
  }

  // Load more feed items
  const loadMoreFeedItems = useCallback(async () => {
    const newData = await fetchMoreFeed({
      variables: { offset: hasMoreFeedItemsNextOffset },
    })

    if (newData.data.getFeed.items.length === 0) {
      setHasMoreFeedItems(false)
      return []
    } else {
      const updatedFeed = newData.data.getFeed.items
        .map(feedItem => {
          if (feedItem.__typename === 'Post') {
            return mapPost(feedItem)
          } else if (feedItem.__typename === 'Comment') {
            return mapSingleComment(feedItem)
          }
          return null
        })
        .filter(item => item !== null) as (PostData | CommentData)[]

      setHasMoreFeedItemsNextOffset(newData.data.getFeed.nextOffset)
      setFeedItems(prevItems => [...prevItems, ...updatedFeed])

      return updatedFeed
    }
  }, [fetchMoreFeed, hasMoreFeedItemsNextOffset])

  // Initialize feed items
  useEffect(() => {
    if (feed?.getFeed) {
      const initialFeed = feed.getFeed.items
        .map(feedItem => {
          if (feedItem.__typename === 'Post') {
            return mapPost(feedItem)
          } else if (feedItem.__typename === 'Comment') {
            return mapSingleComment(feedItem)
          }
          return null
        })
        .filter(item => item !== null) as (PostData | CommentData)[]

      setFeedItems(initialFeed)
      setHasMoreFeedItemsNextOffset(feed.getFeed.nextOffset)
    }
  }, [feed])

  const mappedTrendingPosts = useMemo(() => {
    return trendingPosts?.getTrendingPosts.map(mapToNewsPostItem) || []
  }, [trendingPosts])

  const [hasMoreNewestPosts, setHasMoreNewestPosts] = useState(true)
  const [hasMoreNewestPostsNextOffset, setHasMoreNewestPostsNextOffset] =
    useState(null)
  const [newestItems, setNewestItems] = useState<NewsPostItem[]>([])

  const loadMoreNewestPosts = useCallback(async () => {
    if (!hasMoreNewestPosts) return []

    const newData = await fetchMoreNewestPosts({
      variables: { lastCreatedAt: hasMoreNewestPostsNextOffset },
    })

    if (newData.data.getNewestPosts.length === 0) {
      setHasMoreNewestPosts(false)
      return []
    } else {
      const updatedNewestPosts = newData.data.getNewestPosts.map(
        mapToNewsPostItem
      ) as NewsPostItem[]

      const nextOffset = newData.data.getNewestPosts.reduce((min, item) => {
        return item.createdAt < min ? item.createdAt : min
      }, newData.data.getNewestPosts[0].createdAt)

      setHasMoreNewestPostsNextOffset(nextOffset)
      setNewestItems(prevItems => [...prevItems, ...updatedNewestPosts])

      return updatedNewestPosts
    }
  }, [
    fetchMoreNewestPosts,
    hasMoreNewestPostsNextOffset,
    setNewestItems,
    setHasMoreNewestPosts,
    setHasMoreNewestPostsNextOffset,
  ])

  useEffect(() => {
    if (newestPosts?.getNewestPosts) {
      const initialNewestPosts = newestPosts?.getNewestPosts.map(
        mapToNewsPostItem
      ) as NewsPostItem[]
      const nextOffset = newestPosts?.getNewestPosts.reduce((min, item) => {
        return item.createdAt < min ? item.createdAt : min
      }, newestPosts?.getNewestPosts[0].createdAt)
      setNewestItems(initialNewestPosts)
      setHasMoreNewestPostsNextOffset(nextOffset)
    }
  }, [newestPosts])

  return {
    feedItems,
    refetchFeed,
    loadMoreFeedItems,
    refetchTrendingPosts,
    loadMoreNewestPosts,
    refetchNewestPosts,
    trendingPosts: mappedTrendingPosts,
    newestPosts: newestItems,
    hasMoreFeedItems,
    hasMoreFeedItemsNextOffset,
    hasMoreNewestPosts,
    hasMoreNewestPostsNextOffset,
  }
}

export default useFeedManagement
