import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import React, { useState } from 'react'

import { GetCurrentUserDocument } from 'generated/graphql'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { gaLoginEvent } from 'utils/analytics/ga'
import { usePostHog } from 'posthog-js/react'
import { setApolloError } from '../apollo/reactive-vars'
import { updatedAwsConfig, UserLoginer } from '../auth/userAuth'
import CenteredBoxScreenWrapper from '../components/auth/common/CenteredBoxScreenWrapper'
import LoginBox from '../components/auth/LoginBox'
import ROUTES from '../constants/routes'
import { useGetCurrentUserLazyQuery } from '../generated/graphql'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../constants'
import { DEBUG_ENV } from '../index'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { LoadingProgress } from 'components/LoadingProgress'

const Login: React.FC = () => {
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  const [getLoadingState, setLoadingState] = useState(false)
  const [user_name_error, setUserNameError] = useState<string | undefined>(
    undefined
  )
  const [user_password_error, setUserPasswordError] = useState<
    string | undefined
  >(undefined)
  const posthog = usePostHog()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  const GET_USER = GetCurrentUserDocument
  //const { loading, error, data, cache } = useQuery(GET_USER)

  const [getCurrentUser, { client }] = useGetCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  function onClose() {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  // TODO: this function runs async actions that may complete after the component has been unmounted
  // add proper cleanup logic or conditions
  async function loginUser(input: UserLoginer) {
    const { email, password, remember_user } = input

    if (remember_user) {
      Auth.configure({
        ...updatedAwsConfig,
        storage: window.localStorage,
      })
    } else {
      Auth.configure({
        ...updatedAwsConfig,
        storage: window.sessionStorage,
      })
    }

    gaLoginEvent(
      'login_attempt',
      'login___manual_start',
      'attempt',
      email
    ).catch(err => {
      console.error(`Google analytics failed to record the social login event.`)
    })

    setLoadingState(true)

    return Auth.signIn({
      password,
      username: email,
    })
      .then(result => {
        if (!!result?.getUsername()) {
          const res = getCurrentUser()

          sessionStorage.setItem('isFirstTimeLoggedIn', 'true')

          return res
        } else {
          setApolloError(
            'Something went wrong with your login... please try again.'
          )
        }
      })
      .then(data => {
        //client.cache.modify()

        gaLoginEvent(
          'login_finish',
          'login___manual_finish',
          'success',
          email
        ).catch(err => {
          console.error(
            `Google analytics failed to record the social login event.`
          )
        })

        if (!data?.data?.getCurrentUser?.id) {
          setApolloError(
            'Something went wrong with your login... please try again.'
          )
        } else {
          posthog?.identify(data?.data?.getCurrentUser?.id)

          if (redirectTo) {
            navigate(redirectTo, { replace: true })
            return
          }

          if (data?.data?.getCurrentUser?.lastViewedSymbol) {
            navigate(
              ROUTES.ASSET.replace(
                ':symbol',
                data?.data?.getCurrentUser?.lastViewedSymbol
              )
                .replace(':type', 'asset')
                .toLowerCase()
            )
          } else {
            navigate(ROUTES.SENTIMENT_DISCOVERY, { replace: true })
          }
        }
      })
      .catch(e => {
        gaLoginEvent(
          'failed_due_to_backend',
          'login___manual_error',
          'error',
          email
        ).catch(err => {
          console.error(
            `Google analytics failed to record the social login event.`
          )
        })

        switch (e.code) {
          case 'UserNotFoundException':
            //setUserNameError(e.message)
            setUserNameError(
              'Cannot find any user registered under this email address'
            )
            setLoadingState(false)
            break
          case 'NotAuthorizedException':
            //setUserPasswordError(e.message)
            setUserPasswordError(`
              Incorrect email and password combination.
              Hint: password is minimum 8 characters,
              and contains at least 1 uppercase character
              and one number
            `)
            setLoadingState(false)
            //setUserNameError(e.message)
            break
          case 'UserNotConfirmedException':
            if (DEBUG_ENV) console.debug('User not confirmed')
            navigate(ROUTES.CONFIRM_SIGNUP.replace(':email', email))
            break
          default:
            setApolloError(e.message || 'Something went wrong')
            setLoadingState(false)
        }
      })
  }

  return (
    <>
      {deviceType === DeviceType.Mobile && (
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          style={{
            position: 'absolute',
            top: 24,
            right: 24,
            cursor: 'pointer',
          }}
          color={colors.textGrey}
          onClick={onClose}
        />
      )}
      <CenteredBoxScreenWrapper>
        <LoginBox
          redirectTo={redirectTo}
          onForgotPassword={() => {
            gaLoginEvent(
              'login_attempt',
              'login___manual_forgot_password'
            ).catch(err => {
              console.error(
                `Google analytics failed to record the login forgot password event.`
              )
            })

            navigate(ROUTES.RESSET_PASSWORD)
          }}
          onSocial={provider => {
            gaLoginEvent('login_attempt', 'login___social').catch(err => {
              console.error(
                `Google analytics failed to record the social login event.`
              )
            })

            Auth.federatedSignIn({
              provider:
                provider === 'facebook'
                  ? CognitoHostedUIIdentityProvider.Facebook
                  : CognitoHostedUIIdentityProvider.Google,
            })
          }}
          onSubmit={input => loginUser(input as UserLoginer)}
          errors={{ email: user_name_error, password: user_password_error }}
          loading={getLoadingState}
        />
        {getLoadingState && <LoadingProgress />}
      </CenteredBoxScreenWrapper>
    </>
  )
}

export default Login
