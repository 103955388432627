import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export const submitTopicHeaderHeight = 55

export default makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.drawer_bg,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
  },

  topicMobileHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    height: 34,
    color: colors.white_faded,
    backgroundColor: colors.search_bg,
    borderTop: `1px solid ${colors.drawer_border}`,
    borderBottom: `1px solid ${colors.drawer_border}`,
    position: 'relative',
  },

  submitStoryButton: {
    height: submitTopicHeaderHeight,
    backgroundColor: colors.blue,
    color: colors.white,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: 3,
    fontWeight: 700,
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 73,
    },
  },

  todaysStock: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 9999999999,
    position: 'fixed',
    width: 343,
    left: 0,
    bottom: 0,
    padding: '14px 16px',
    backgroundColor: colors.blue,
    borderRadius: 8,
    margin: 16,
    boxSizing: 'border-box',
    fontFamily: fonts.dm_sans,

    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      width: 'calc(100% - 32px)',
    },
  },

  todaysStockHeader: {
    color: colors.white_faded,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
  },

  todaysStockReadTopics: {
    color: colors.white_faded,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 4,
  },

  predictPriceButton: {
    backgroundColor: colors.white,
    color: colors.blue,
    padding: '2px 12px',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: 6,
    border: 'none',
    cursor: 'pointer',
    marginTop: 8,
  },

  todaysStockCrossWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  todaysStockClose: {
    color: colors.white,
  },

  storyFilter: {
    height: 40,
    backgroundColor: colors.bell_bg,
    color: colors.text_white,
    padding: '8px 16px',
    boxSizing: 'border-box',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },

  currentFilter: {
    color: colors.text_white,
    marginRight: 8,
  },

  topicsList: {
    backgroundColor: colors.drawer_bg,
    height: `calc(100% - ${submitTopicHeaderHeight}px)`,
    overflow: 'hidden',

    '& .infinite-scroll-component__outerdiv': {
      height: '100%',
      backgroundColor: colors.drawer_bg_light,

      '& .infinite-scroll-component': {
        overflowY: 'overlay !important',
        'scrollbar-gutter': 'stable',

        [theme.breakpoints.down('lg')]: {
          backgroundColor: `${colors.search_bg} !important`,
        },
      },
    },

    [theme.breakpoints.down('lg')]: {
      height: `calc(100% - 35px)`,
      backgroundColor: colors.search_bg,
    },
  },

  postsBefore: {
    color: colors.text_white,
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    width: '100%',
    display: 'flex',

    [theme.breakpoints.down('lg')]: {
      borderBottom: `1px solid ${colors.drawer_border}`,
    },
  },

  postsBeforeLeft: {
    backgroundColor: colors.topic_posts_before_background,
    padding: '9px 16px',
    flex: 1,

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },

  postsBeforeRight: {
    backgroundColor: colors.topic_posts_before_background_right,
    width: '55px',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },
})
