import React from 'react'
import { colors } from '../../../constants'
import theme from '../../../mui-theme'
import useStyles from './styles'

import { Box, Modal } from '@mui/material'
import YouTube from 'react-youtube'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

interface Props {
  open: boolean
  onClose: () => void
}

const LandingVideoModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const classes = useStyles(theme)

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.modalContent}>
        <FontAwesomeIcon
          icon={faClose}
          className={classes.iconClose}
          color={colors.textGrey}
          onClick={onClose}
        />
        <YouTube
          videoId={'h-5fGsfWu4w'}
          className={classes.youtubeContainer}
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: 1,
              controls: 1,
              rel: 0,
              modestbranding: 0,
            },
          }}
        />
      </Box>
    </Modal>
  )
}

export default LandingVideoModal
