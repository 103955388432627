import useStyles from './styles'
import theme from '../../mui-theme'
import InfiniteScroll from 'react-infinite-scroll-component'
import Topic from './Topic'
import { VoteDirection } from './VoteUpDown'
import { SubmitTopicBar } from './SubmitTopicBar/SubmitTopicBar'
import { convertVoteDirection } from 'utils/voteUtils'
import { TopicListControls } from './Topic/index'
import { ExDate, colors } from '../../constants'
import {
  useGetPricePredictionRoundQuery,
  useGetSymbolDateQuery,
} from 'generated/graphql'
import { DEBUG_ENV } from 'index'
import { useEffect, useState } from 'react'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { TopicLoading } from './TopicLoading'
import { formatDateIgnoringTimezone } from 'utils/dateTimeUtils'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { IStoryPost } from 'types/story-posts'
import { faChevronDown, faCross, faX } from '@fortawesome/free-solid-svg-icons'
import MenuWithOptions, {
  PopupMenuItem,
} from 'components/common/MenuWithOptions'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props extends TopicListControls {
  userId?: string
  symbol: string
  selectedDate?: ExDate | undefined
  topics: PostData[]
  hasMoreTopics: boolean
  loadMoreTopics: () => void
  onSubmitTopicClick: () => void
  onVote: (
    direction: VoteDirection,
    id: string,
    userVotePositive?: boolean | null
  ) => void
  onTopicHover: (topic: PostData | null) => void
  onTopVisibleTopicDateChange?: (date: ExDate) => void
  isStoryEdit?: boolean
  isStoryView?: boolean
  storyPost?: PostData | null
  selectedStoryPosts?: IStoryPost[]
  setSelectedStoryPosts?: (selectedStoryPosts: IStoryPost[]) => void
  submitStory?: () => Promise<void>
  setFilteredTopicIds?: (filteredTopics: string[] | undefined) => void
}

export default function TopicSidebar(props: Props) {
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  // Ref for the InfiniteScroll component to attach scroll event listener
  const classes = useStyles(theme)
  const [separatorDate, setSeparatorDate] = useState<ExDate>(new ExDate())
  const [todaysStockOpen, setTodaysStockOpen] = useState<boolean>(true)
  const [topics, setTopics] = useState<{
    present: PostData[]
    past: PostData[]
  }>({ present: [], past: [] })

  const { data: pricePredictionRound } = useGetPricePredictionRoundQuery({
    fetchPolicy: 'cache-first',
  })

  const isPricePredictionRoundVotingOpenForSymbol =
    pricePredictionRound?.getPricePredictionRound?.isVotingOpen &&
    pricePredictionRound?.getPricePredictionRound?.symbol === props.symbol &&
    pricePredictionRound.getPricePredictionRound.vote === null

  const storeEditFilterMenuItems: PopupMenuItem[] = [
    {
      text: 'All',
      id: 'All',
    },
    // TODO: Implement this feature
    // {
    //   text: 'Your topics',
    //   id: 'YourTopics',
    // },
    {
      text: 'Selected',
      id: 'Selected',
    },
  ]
  const [currentFilter, setCurrentFilter] = useState(
    storeEditFilterMenuItems[0].id
  )

  const { data: symbolDateData, loading: symbolDateLoading } =
    useGetSymbolDateQuery({
      fetchPolicy: 'cache-first',
      variables: {
        symbol: props.symbol,
      },
    })

  const symbolDate = !!symbolDateData?.getSymbolDate?.date
    ? (symbolDateData.getSymbolDate.date as String)
    : undefined

  if (DEBUG_ENV) {
    if (!symbolDate)
      console.warn(
        `--> TopicSidebarIntegration -- Symbol: ${props.symbol} has no symbolDate response`
      )
  }

  const logTopVisibleTopic = (e: React.UIEvent | Event) => {
    if (deviceType === DeviceType.Desktop) return

    // Initialize scrollContainer as an HTMLElement, null at start.
    let scrollContainer: HTMLElement | null = null

    // Check if e.currentTarget exists and is an HTMLElement
    if ('currentTarget' in e && e.currentTarget instanceof HTMLElement) {
      scrollContainer = e.currentTarget
      // Otherwise, check if e.target exists and is an HTMLElement
    } else if ('target' in e && e.target && e.target instanceof HTMLElement) {
      scrollContainer = e.target
    }

    // Proceed if scrollContainer is valid
    if (scrollContainer) {
      const topicsElements = scrollContainer.querySelectorAll(
        '[data-topic="true"]'
      )

      for (let topic of topicsElements) {
        const topicElement = topic as HTMLElement
        const rect = topicElement.getBoundingClientRect()
        const scrollRect = scrollContainer.getBoundingClientRect()

        // Check if the topic element is within the viewport of the scroll container
        if (rect.top >= scrollRect.top && rect.bottom <= scrollRect.bottom) {
          const topicDate = topicElement.dataset.topicDate ?? 'Unknown Date'
          // Call the callback prop to notify the parent component about the date change
          props.onTopVisibleTopicDateChange?.(
            new ExDate(formatDateIgnoringTimezone(topicDate))
          )
          break
        }
      }
    }
  }

  const updateSelectedStoryPosts = (checkedStoryPost: IStoryPost) => {
    let updatedSelectedStoryPosts = props.selectedStoryPosts || []

    if (checkedStoryPost.noteText) {
      // Remove the post if it already exists to avoid duplicates
      updatedSelectedStoryPosts = updatedSelectedStoryPosts.filter(
        (storyPost: IStoryPost) =>
          storyPost.childPostId !== checkedStoryPost.childPostId
      )
      // Add the checkedStoryPost since storyText is populated
      updatedSelectedStoryPosts = [
        ...updatedSelectedStoryPosts,
        checkedStoryPost,
      ]
    } else {
      // Toggle the presence of checkedStoryPost in selectedStoryPosts
      if (
        updatedSelectedStoryPosts.some(
          storyPost => storyPost.childPostId === checkedStoryPost.childPostId
        )
      ) {
        updatedSelectedStoryPosts = updatedSelectedStoryPosts.filter(
          (storyPost: IStoryPost) =>
            storyPost.childPostId !== checkedStoryPost.childPostId
        )
      } else {
        updatedSelectedStoryPosts = [
          ...updatedSelectedStoryPosts,
          checkedStoryPost,
        ]
      }
    }

    props.setSelectedStoryPosts!(updatedSelectedStoryPosts)
  }

  useEffect(() => {
    if (!!props?.selectedDate && !!symbolDateData?.getSymbolDate) {
      const symbolDate = new ExDate(symbolDateData.getSymbolDate.date)

      if (props.selectedDate < symbolDate) {
        setSeparatorDate(props.selectedDate)
      } else {
        setSeparatorDate(symbolDate)
      }
    } else if (!!props?.selectedDate && !symbolDateData?.getSymbolDate) {
      setSeparatorDate(props.selectedDate)
    } else if (!props?.selectedDate && !!symbolDateData?.getSymbolDate) {
      const symbolDate = new ExDate(symbolDateData.getSymbolDate.date)
      setSeparatorDate(symbolDate)
    }
  }, [symbolDateData, props.selectedDate])

  useEffect(() => {
    setTopics({
      present: props.topics.filter(item => {
        const itemDate = item.date
        return itemDate.getTime() >= separatorDate.getTime()
      }),
      past: props.topics.filter(item => {
        const itemDate = item.date
        return itemDate.getTime() < separatorDate.getTime()
      }),
    })
  }, [props.topics, separatorDate])

  const filterPosts = (action: string | number): void => {
    if (action === 'All') {
      setCurrentFilter('All')
      props.setFilteredTopicIds!(undefined)
    } else if (action === 'Selected') {
      setCurrentFilter('Selected')
      props.setFilteredTopicIds!(
        props.selectedStoryPosts!.map(post => post.childPostId)
      )
    }
  }

  return (
    <div className={classes.wrapper} id="topics-sidebar">
      {deviceType === DeviceType.Mobile && (
        <div className={classes.topicMobileHeader}>Topics</div>
      )}
      {deviceType === DeviceType.Desktop && !props.isStoryEdit && (
        <SubmitTopicBar onSubmitTopicClick={() => props.onSubmitTopicClick()} />
      )}
      {props.isStoryEdit && !!props.storyPost && (
        <>
          <button
            className={classes.submitStoryButton}
            onClick={props.submitStory}
          >
            SUBMIT STORY
          </button>
          <Topic
            key={props.storyPost.id}
            post={props.storyPost}
            onTopicHeaderClick={props.onTopicHeaderClick}
            onCommentClick={props.onCommentClick}
            onTopicBookmarkClick={props.onTopicBookmarkClick}
            onShareActionsClick={props.onShareActionsClick}
            onCameraActionsClick={props.onCameraActionsClick}
            onMoreActionsClick={props.onMoreActionsClick}
            onTopicReplyClick={() => {}}
            isCreator={false}
            onVote={direction => {
              props.onVote(
                direction,
                props.storyPost!.id,
                props.storyPost!.userVoteDirection !== null &&
                  props.storyPost!.userVoteDirection !== undefined
                  ? convertVoteDirection(props.storyPost!.userVoteDirection)
                  : undefined
              )
            }}
            isHovered={(isHovered: boolean) => {
              props.onTopicHover(isHovered ? props.storyPost! : null)
            }}
            isStoryEdit={props.isStoryEdit}
            isStoryView={props.isStoryView}
            selectedStoryPosts={props.selectedStoryPosts}
          />
          <div className={classes.storyFilter}>
            <div className={classes.currentFilter}>{currentFilter}</div>
            <MenuWithOptions
              menuIcon={faChevronDown}
              iconToolTip={'Share'}
              actionsMenuItems={storeEditFilterMenuItems}
              onActionClick={action => filterPosts(action)}
            ></MenuWithOptions>
          </div>
        </>
      )}
      <div className={classes.topicsList}>
        <InfiniteScroll
          dataLength={props.topics.length ?? 0}
          style={{ backgroundColor: colors.drawer_bg_right }}
          next={props.loadMoreTopics}
          onScroll={logTopVisibleTopic}
          hasMore={props.hasMoreTopics}
          loader={
            <>
              {!props.isStoryView && (
                <>
                  <TopicLoading />
                  <TopicLoading />
                  <TopicLoading />
                  <TopicLoading />
                  <TopicLoading />
                  <TopicLoading />
                </>
              )}
            </>
          }
          scrollableTarget="topics-sidebar"
          height="100%"
        >
          <div>
            {todaysStockOpen && isPricePredictionRoundVotingOpenForSymbol && (
              <div className={classes.todaysStock}>
                <div>
                  <div className={classes.todaysStockHeader}>
                    Today's stock 📈
                  </div>
                  <div className={classes.todaysStockReadTopics}>
                    Read topics and predict price
                  </div>
                  <button
                    className={classes.predictPriceButton}
                    onClick={() => {
                      navigate(ROUTES.PRICE_PREDICTION)
                    }}
                  >
                    Predict price
                  </button>
                </div>
                <div
                  className={classes.todaysStockCrossWrapper}
                  onClick={() => setTodaysStockOpen(false)}
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className={classes.todaysStockClose}
                  />
                </div>
              </div>
            )}
            {topics.present.map(item => (
              <Topic
                key={item.id}
                post={item}
                onTopicHeaderClick={props.onTopicHeaderClick}
                onCommentClick={props.onCommentClick}
                onTopicBookmarkClick={props.onTopicBookmarkClick}
                onShareActionsClick={props.onShareActionsClick}
                onCameraActionsClick={props.onCameraActionsClick}
                onMoreActionsClick={props.onMoreActionsClick}
                onTopicReplyClick={() => {}}
                isCreator={item.userId === props.userId}
                onVote={direction => {
                  props.onVote(
                    direction,
                    item.id,
                    item.userVoteDirection !== null &&
                      item.userVoteDirection !== undefined
                      ? convertVoteDirection(item.userVoteDirection)
                      : undefined
                  )
                }}
                isHovered={(isHovered: boolean) => {
                  props.onTopicHover(isHovered ? item : null)
                }}
                isStoryEdit={props.isStoryEdit}
                isStoryView={props.isStoryView}
                checkTopic={(e: any, storyPost: IStoryPost) =>
                  updateSelectedStoryPosts(storyPost)
                }
                selectedStoryPosts={props.selectedStoryPosts}
              />
            ))}
            {topics.past.length > 0 && (
              <div className={classes.postsBefore}>
                <div className={classes.postsBeforeLeft}>
                  {!!props?.selectedDate
                    ? `Posts before selected bar (${separatorDate.date})`
                    : `Posts before most recent bar (${separatorDate.date})`}
                </div>
                <div className={classes.postsBeforeRight}></div>
              </div>
            )}
            {topics.past.map(item => (
              <Topic
                key={item.id}
                post={item}
                onTopicHeaderClick={props.onTopicHeaderClick}
                onCommentClick={props.onCommentClick}
                onTopicBookmarkClick={props.onTopicBookmarkClick}
                onShareActionsClick={props.onShareActionsClick}
                onCameraActionsClick={props.onCameraActionsClick}
                onMoreActionsClick={props.onMoreActionsClick}
                onTopicReplyClick={() => {}}
                isCreator={item.userId === props.userId}
                onVote={direction => {
                  props.onVote(
                    direction,
                    item.id,
                    item.userVoteDirection !== null &&
                      item.userVoteDirection !== undefined
                      ? convertVoteDirection(item.userVoteDirection)
                      : undefined
                  )
                }}
                isHovered={(isHovered: boolean) => {
                  props.onTopicHover(isHovered ? item : null)
                }}
                isStoryEdit={props.isStoryEdit}
                isStoryView={props.isStoryView}
                checkTopic={(e: any, storyPost: IStoryPost) =>
                  updateSelectedStoryPosts(storyPost)
                }
                selectedStoryPosts={props.selectedStoryPosts}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  )
}
