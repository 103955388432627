import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useGetCommentQuery } from 'generated/graphql'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faClose,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../constants'
import { FormControl, Input } from '@mui/material'
import ROUTES from 'constants/routes'
import ShareableComment from 'components/Shareable/Comment'
import { mapSingleComment } from 'utils/mappers/commentsMapper'

const CommentGraphic: React.FC = ref => {
  const classes = useStyles(theme)
  const deviceType = useDeviceType()
  const navigate = useNavigate()

  const {
    type: postType,
    symbol: symbol,
    topicId: postId,
  } = useParams<'type' | 'symbol' | 'topicId'>()

  const [searchParams, setSearchParams] = useSearchParams()
  const commentId = searchParams.get('commentid')

  const redirectToPage = () => {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  const onClose = () => {
    navigate(
      ROUTES.ASSET.replace(':type', postType!.toLowerCase()).replace(
        ':symbol',
        symbol!
      ) +
        '/topic/' +
        postId +
        '?commentid=' +
        commentId
    )
  }

  const { data: getComment } = useGetCommentQuery({
    variables: {
      id: commentId!,
    },
  })

  const commentWithParent = getComment?.getComment
    ? mapSingleComment(getComment.getComment)
    : undefined

  return (
    <div className={classes.wrapper}>
      {deviceType === DeviceType.Desktop && (
        <div className={classes.topNavWrapper}>
          <div className={classes.logoWrapper}>
            <LogoWithoutText />
          </div>
          <div className={classes.inputWithIcon} onClick={redirectToPage}>
            <FormControl
              classes={{
                root: classes.formControlRoot,
              }}
            >
              <Input
                placeholder={'Search stocks e.g. Apple AAPL'}
                inputProps={{}}
                id={`input-search`}
                readOnly={true}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {}}
                name={'search'}
                classes={{
                  input: classes.input,
                  root: classes.inputRoot,
                }}
              />
            </FormControl>
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.iconSearch}
              color={colors.textGrey}
            />
          </div>
          <div className={classes.closeWithIcon} onClick={onClose}>
            <span>Close</span>
            <FontAwesomeIcon
              icon={faClose}
              className={classes.iconClose}
              color={colors.textGrey}
            />
          </div>
        </div>
      )}

      {deviceType === DeviceType.Mobile && (
        <div className={classes.topNavWrapper}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.iconLeftArrow}
            color={colors.textGrey}
            onClick={onClose}
          />
          <span className={classes.username}>&nbsp;</span>
        </div>
      )}
      <div className={classes.shareableComment}>
        {commentWithParent && (
          <ShareableComment commentWithParent={commentWithParent} />
        )}
      </div>
    </div>
  )
}

export default CommentGraphic
