import {
  useDeleteCurrentUserMutation,
  useGetCurrentUserQuery,
} from 'generated/graphql'
import theme from '../../mui-theme'
import useStyles from './styles'
import DeleteUser from 'components/auth/DeleteUser/DeleteUser'
import { useUserOperations } from 'hooks/useUserOperations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export const DeleteUserMobile = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const { signOut } = useUserOperations()

  const [deleteUser] = useDeleteCurrentUserMutation()
  const { data: getCurrentUserData, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  return (
    <div className={classes.wrapper}>
      <div className={classes.topNavBar}>
        <div className={classes.iconBackWrapper}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            className={classes.iconBack}
            onClick={() => navigate(-1)}
          />
        </div>
        <div className={classes.header}>User Settings</div>
        <div className={classes.headerRight}></div>
      </div>
      <div className={classes.deleteUserWrapper}>
        <DeleteUser
          username={getCurrentUserData?.getCurrentUser.nickname?.toLowerCase()!}
          email={getCurrentUserData?.getCurrentUser.email?.toLowerCase()!}
          deleteAccount={() => {
            deleteUser()
              .then(({ data }) => {
                signOut()
              })
              .catch(error => {
                console.error('Error deleting account', error)
              })
          }}
        />
      </div>
    </div>
  )
}

export default DeleteUserMobile
