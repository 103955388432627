import theme from '../../../mui-theme'
import useStyles from './styles'
import LogoNoBetaImage from './LogoNoBeta'

interface Props {
  onClick?: () => void
}

const Logo = ({ onClick }: Props) => {
  const classes = useStyles(theme)

  return (
    <div
      onClick={onClick}
      className={classes.wrapper}
      style={{ cursor: 'pointer' }}
    >
      <LogoNoBetaImage className={classes.img} />
    </div>
  )
}

export default Logo
