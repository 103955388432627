import { makeStyles } from '@mui/styles'
import { colors } from '../../constants'

export default makeStyles(theme => ({
  wrapper: {
    backgroundColor: colors.search_bg,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 750,
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '100%',
    },
  },
  header: {
    display: 'flex',
    padding: 16,
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
  },
  headerText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    color: colors.text_white,
  },
  iconClose: {
    width: 20,
    height: 20,
    padding: 4,
    backgroundColor: colors.box_bg,
    borderRadius: 100,
    cursor: 'pointer',
  },
  body: {
    padding: '24px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  symbol: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.text_white,
  },
  symbolName: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.textGreyLight,
    marginTop: 8,
  },
  today: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.textGreyLight,
    marginTop: 24,
  },
  endClose: {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: '30px',
    color: colors.white,
    marginTop: 8,
  },
  emoji: {
    marginTop: 16,
    width: 48,
    height: 48,
  },
  addToWatchList: {
    cursor: 'pointer',
    color: colors.white_faded,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    marginTop: 38,
  },
  resultMessage: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '30px',
    color: colors.white,
    letterSpacing: -0.4,
    marginTop: 16,
  },
  checkLatestPosts: {
    color: colors.blue_lighter,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: 8,
    cursor: 'pointer',
  },
  playNextChallengeButton: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '16px',
    textTransorm: 'uppercase',
    backgroundColor: colors.blue,
    borderRadius: 8,
    letterSpacing: 2.75,
    height: 48,
    width: 'calc(100% - 32px)',
    maxWidth: 400,
    margin: 'auto 16px 48px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
