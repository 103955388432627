import React from 'react'
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import {
  useReportCommentMutation,
  useReportPostMutation,
} from '../../generated/graphql'
import ReportTopicPanel, {
  ReportTopicInput,
} from '../../components/TopicSidebar/ReportTopicPanel'
import ReportCommentPanel from '../../components/TopicSidebar/ReportCommentPanel'
import { DEBUG_ENV } from '../../index'

export const PostReportIntegration: React.FC<{}> = () => {
  let { code, reportId } = useParams<'code' | 'reportId'>()

  const [reportPost] = useReportPostMutation()
  const [reportComment] = useReportCommentMutation()

  let navigate = useNavigate()
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()
  const commentId = searchParams.get('commentid')

  let panelData: Partial<ReportTopicInput> | undefined = undefined

  return (
    <>
      {!commentId && (
        <ReportTopicPanel
          data={panelData}
          onCancelReportTopicClick={() =>
            navigate(location.pathname.replace('report', 'topic'))
          }
          onReportClick={reportData => {
            reportPost({
              variables: {
                data: {
                  postId: reportId as string,
                  type: 'default',
                  message: reportData.message,
                },
              },
              update(cache, { data }) {
                if (!data) {
                  return
                }
              },
            })
              .then(() =>
                navigate(location.pathname.replace('report', 'topic'))
              )
              .catch(e => {
                if (DEBUG_ENV) console.error(e)
              })
          }}
        />
      )}
      {commentId && (
        <ReportCommentPanel
          data={panelData}
          onCancelReportCommentClick={() =>
            navigate(
              `${location.pathname.replace('report', 'topic')}${
                location.search
              }`
            )
          }
          onReportClick={reportData => {
            reportComment({
              variables: {
                data: {
                  commentId: commentId as string,
                  type: 'default',
                  message: reportData.message,
                },
              },
              update(cache, { data }) {
                if (!data) {
                  return
                }
              },
            })
              .then(() =>
                navigate(
                  `${location.pathname.replace('report', 'topic')}${
                    location.search
                  }`
                )
              )
              .catch(e => {
                if (DEBUG_ENV) console.error(e)
              })
          }}
        />
      )}
    </>
  )
}
