import { makeStyles } from '@mui/styles'
import { colors } from '../../../../constants'
import theme from '../../../../mui-theme'

export default makeStyles({
  tabContent: {
    width: '100%',
    height: 'calc(100% - 29px)',
    overflowY: 'auto',
    marginTop: 16,

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },

    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: colors.search_bg,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.drawer_bg,
      '&:hover': {
        backgroundColor: colors.drawer_bg,
      },
    },
  },

  wrapper: {
    marginBottom: 8,
    marginRight: 8,

    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      marginRight: 0,
    },
  },

  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    alignSelf: 'center',
  },

  noUsersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 0',

    [theme.breakpoints.down('lg')]: {
      padding: '32px 16px',
    },
  },

  noUsersIcon: {
    color: colors.textGreyLight,
    fontSize: 27,
  },

  noUsersText: {
    color: colors.textGreyLight,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '22px',
    marginTop: 16,
  },

  userWrapper: {
    display: 'flex',
    padding: 16,
    marginBottom: 8,
    backgroundColor: colors.search_bg,
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
      padding: '16px',
      marginBottom: 0,
      borderBottom: `1px solid ${colors.drawer_border}`,
    },

    '&:first-child': {
      marginTop: 0,
    },
  },

  userDetailsWrapper: {
    marginLeft: 16,
    flexGrow: 1,
  },

  followers: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.textGreyLight,
  },

  following: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.textGreyLight,
    marginLeft: 16,
  },

  lineWrapper: {
    display: 'flex',
    marginTop: 4,
  },

  usernameWrapper: {
    display: 'flex',
  },

  username: {
    color: colors.white_faded,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  isFollowingYou: {
    color: colors.textGreyLight,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: 8,
  },

  followingBtn: {
    display: 'flex',
    padding: '8px 24px',
    textTransform: 'none',
    alignSelf: 'center',
    color: colors.white_faded,
    backgroundColor: colors.box_bg,
    borderRadius: 100,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    height: 36,
  },

  followBtn: {
    display: 'flex',
    padding: '8px 24px',
    textTransform: 'none',
    alignSelf: 'center',
    color: colors.white_faded,
    backgroundColor: colors.blue,
    borderRadius: 100,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    height: 36,
  },
})
