import { useState, useCallback } from 'react'

// Import the correct GraphQL hooks
import {
  useGetUserWatchedSymbolsLazyQuery,
  useGetTrendingSymbolsLazyQuery,
  useGetMostWatchedSymbolsLazyQuery,
} from 'generated/graphql'
import { TabIdentifiers } from '../constants/tabConstants'
import { TabVisibility } from '..'

const initialTabVisibility: TabVisibility = {
  [TabIdentifiers.TRENDING]: true,
  [TabIdentifiers.RESULTS]: false,
  [TabIdentifiers.WATCHLIST]: true,
  [TabIdentifiers.MOST_WATCHED]: true,
  [TabIdentifiers.FEED]: true,
  [TabIdentifiers.TRENDING_NEWS]: true,
  [TabIdentifiers.LATEST_NEWS]: true,
}

export const useTabs = (
  initialTab: TabIdentifiers,
  loadResultsTabData: (symbol: string) => void
) => {
  const [selectedTab, setSelectedTab] = useState<TabIdentifiers | null>(null)
  const [tabVisibility, setTabVisibility] =
    useState<Record<TabIdentifiers, boolean>>(initialTabVisibility)

  // Define your lazy GraphQL queries
  const [getUserWatchedSymbols, { data: watchedSymbols }] =
    useGetUserWatchedSymbolsLazyQuery({ fetchPolicy: 'cache-first' })
  const [getTrendingSymbols, { data: trendingSymbols }] =
    useGetTrendingSymbolsLazyQuery({ fetchPolicy: 'cache-first' })
  const [getMostWatchedSymbols, { data: mostWatchedSymbols }] =
    useGetMostWatchedSymbolsLazyQuery({ fetchPolicy: 'cache-first' })

  // Load content for the selected tab
  const loadTab = useCallback(
    (tab: TabIdentifiers, currentSymbol: string) => {
      switch (tab) {
        case TabIdentifiers.TRENDING:
          getUserWatchedSymbols().then(() => {
            getTrendingSymbols()
          })
          break
        case TabIdentifiers.RESULTS:
          loadResultsTabData(currentSymbol)
          break
        case TabIdentifiers.WATCHLIST:
          getUserWatchedSymbols()
          break
        case TabIdentifiers.MOST_WATCHED:
          getMostWatchedSymbols()
          break
        default:
          break
      }
    },
    [
      getUserWatchedSymbols,
      getTrendingSymbols,
      loadResultsTabData,
      getMostWatchedSymbols,
    ]
  )

  return {
    selectedTab,
    setSelectedTab,
    tabVisibility,
    setTabVisibility,
    loadTab,
    watchedSymbols,
    trendingSymbols,
    mostWatchedSymbols,
  }
}
