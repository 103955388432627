import theme from '../../mui-theme'
import useStyles from './styles'
import { useGetCurrentUserQuery } from 'generated/graphql'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faCog,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons'
import ROUTES from 'constants/routes'
import UserAvatar from 'components/UserAvatar'
import { useUserOperations } from 'hooks/useUserOperations'

export const UserMenuMobile = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const { signOut } = useUserOperations()

  const { data: getCurrentUserData, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  return (
    <div className={classes.wrapper}>
      <>
        <div className={classes.topNavBar}>
          <div className={classes.iconBackWrapper}>
            <FontAwesomeIcon
              icon={faAngleLeft}
              className={classes.iconBack}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className={classes.headerRight}></div>
        </div>
        {!getCurrentUserData?.getCurrentUser && (
          <div className={classes.menu}>
            <div
              className={classes.menuItem}
              onClick={() => navigate(ROUTES.LOGIN)}
            >
              Log in
            </div>
            <div
              className={classes.menuItem}
              onClick={() => navigate(ROUTES.SIGNUP)}
            >
              Sign up
            </div>
          </div>
        )}
        {!!getCurrentUserData?.getCurrentUser && (
          <div className={classes.menu}>
            <div
              className={classes.menuItem}
              onClick={() => {
                navigate(
                  ROUTES.USER.replace(
                    ':username',
                    getCurrentUserData?.getCurrentUser.nickname?.toLowerCase()!
                  ).toLowerCase()
                )
              }}
            >
              <div className={classes.avatarWrapper}>
                <UserAvatar email={getCurrentUserData?.getCurrentUser.email} />
              </div>{' '}
              Profile
            </div>
            <div
              className={classes.menuItem}
              onClick={() => navigate(ROUTES.USER_SETTINGS_MOBILE)}
            >
              <FontAwesomeIcon icon={faCog} className={classes.icon} />
              Settings
            </div>
            <div className={classes.menuItem} onClick={() => signOut()}>
              <FontAwesomeIcon icon={faSignOut} className={classes.icon} />
              Log out
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default UserMenuMobile
