import { Box, LinearProgress } from '@mui/material'

export const LoadingProgress = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
      }}
    >
      <LinearProgress
        sx={{
          width: '100%',
        }}
        color="secondary"
      />
    </Box>
  )
}
