import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(
  {
    loginBtn: {      
      boxShadow: 'none',
      color: colors.white,
      fontFamily: fonts.dm_sans,
      fontSize: 11,
      letterSpacing: 2.44,
      fontWeight: 'bold',
      height: 32,
      width: 88,
      borderRadius: 0,

      '&:hover': {
        color: colors.textGrey
      },
    }
  },
  { index: 1 }
)
