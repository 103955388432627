import { TutorialStepItem } from '../interfaces/tutorialInterfaces'

export const TUTORIAL_STEP_ITEMS: TutorialStepItem[] = [
  {
    imageSrc: '/media/TutorialPage1.png',
    title: "Welcome to fnchart 🎉<br />Let's start by taking a quick tour!",
    body: 'We are dedicated to helping you find and generate actionable investing ideas based on company fundamentals—starting with the story behind the price chart',
  },
  {
    imageSrc: '/media/TutorialPage2.png',
    title:
      'Read trending topics and popular accounts topics on Sentiment Discovery Dashboard™',
    body: 'Cut through media noise: use the feed to see what news & stocks users you follow are posting & commenting on. Check trending topics to see what site-wide users are really excited about.',
  },
  {
    imageSrc: '/media/TutorialPage3.png',
    title: 'Keep an eye on trending stocks and stay connected with them',
    body: 'See what tickers everyone is excited about today by checking the trending tickers list. See what stocks our global user-base thinks are worth keeping your eye on by checking the most watched list.',
  },
  {
    imageSrc: '/media/TutorialPage4.png',
    title: 'Explore what caused a move and discuss it with the community',
    body: 'Just click on any bar on the chart to load the news for that day and the days prior. Use this to finally be able to answer the question “Why did this stock’s price crash/skyrocket last week/month/year”.',
  },
  {
    imageSrc: '/media/TutorialPage5.png',
    title: 'Analyze stocks, craft your story, and share with the community',
    body: 'Read through historical news about a stock to find the context behind why the price is doing what it’s doing. Identify themes that may signal investment opportunities that others are not seeing.',
  },
  {
    imageSrc: '/media/TutorialPage6.png',
    title: 'Learn to invest with the daily price prediction challenge with fun',
    body: 'Get over your fear of pulling the trigger by training your brain to make fast decisions with limited information. This is also a great way to learn about stocks before they become popular.',
  },
]
