import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'
import CancelButton from 'components/common/CancelButton'
import MarkdownEditor from 'components/inputs/MarkdownEditor'
import useTickerValidation from 'hooks/useTickerValidation'
import { BrandButton } from 'components/common/BrandButton'
import { Checkbox, FormControlLabel, checkboxClasses } from '@mui/material'
import { colors } from '../../../constants'

interface CommentReplyBoxProps {
  onSubmit: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean
  ) => Promise<any> | null
  onCancel: () => void
  onTextChange?: (text: string) => void
  initialText?: string
  submitButtonText?: string
  hideCancelButton?: boolean
  replyNotificationEmail: boolean
}

interface CommentInput {
  text: string
  notifyViaEmail: boolean
}

const COMMENT_MIN_LENGTH = 1

export const CommentReplyBox: React.FC<CommentReplyBoxProps> = props => {
  const { validateTicker, extractTickers, symbols } = useTickerValidation()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm<CommentInput>({
    defaultValues: {
      text: props.initialText,
      notifyViaEmail: false,
    },
  })

  const onCancel = () => {
    props.onCancel()
  }

  const onSubmit = (data: CommentInput) => {
    extractTickers(data.text)
    props.onSubmit(data.text, symbols, data.notifyViaEmail)?.then(() => reset())
  }

  useEffect(() => {
    register(
      { name: 'text' },
      {
        required: 'The text field is required',
        validate: validateTicker,
        minLength: {
          message: `The text length needs to be at least ${COMMENT_MIN_LENGTH} characters.`,
          value: COMMENT_MIN_LENGTH,
        },
      }
    )
  })

  return (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={<MarkdownEditor name="text" />}
          control={control}
          label="Text"
          name="text"
          onInput={(input: string) => {
            if (input !== getValues().text) {
              props.onTextChange?.(input)
            }

            setValue('text', input, { shouldValidate: true })
            extractTickers(input)
          }}
          value={getValues().text}
          error={errors.text ? errors.text.message : undefined}
        />
        {!props.replyNotificationEmail && (
          <Controller
            name="notifyViaEmail"
            control={control}
            defaultValue={false}
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => onChange(e.target.checked)}
                    onBlur={onBlur}
                    checked={value}
                    name={name}
                    ref={ref}
                    sx={{
                      color: colors.white_faded,
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label="Notify me via email when someone replies"
                sx={{
                  color: colors.textGreyLight,
                  margin: '8px 0 0 0',
                  [`& span.${checkboxClasses.checked}`]: {
                    color: 'white',
                  },
                }}
              />
            )}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 2,
          }}
        >
          {!!props?.hideCancelButton ? (
            <></>
          ) : (
            <CancelButton
              sx={{
                width: 'auto',
                pl: 3,
                pr: 3,
              }}
              onClick={() => onCancel()}
            >
              Cancel
            </CancelButton>
          )}
          <BrandButton
            sx={{ width: 'auto', pl: 3, pr: 3, ml: 1 }}
            type="submit"
          >
            {!!props?.submitButtonText ? props.submitButtonText : 'POST'}
          </BrandButton>
        </Box>
      </form>
    </Box>
  )
}
