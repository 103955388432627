import React, { useRef, useMemo, useEffect } from 'react'
import LogoWhiteImage from 'components/Images/LogoWhite/LogoWhite'
import theme from '../../../mui-theme'
import useStyles from './styles'
import { ExDate } from '../../../constants'
import UserAvatar from 'components/UserAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faMessage,
} from '@fortawesome/free-solid-svg-icons'
import CandlestickChart from 'components/CandlestickChart'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { useAssetDataQuery, useGetSymbolQuery } from 'generated/graphql'
import { CandleStickData } from 'components/CandlestickChart/interfaces'
import { extractHostname } from 'utils/urlUtils'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import LogoNoBeta from 'components/Images/LogoNoBeta'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'

interface ShareablePostProps {
  post: PostData
  noteText?: string
  isReadyForCapture?: boolean
  onReady?: () => void
}

function ShareablePost({
  post,
  noteText,
  isReadyForCapture,
  onReady,
}: ShareablePostProps) {
  const classes = useStyles(theme)
  const elementRef = useRef<HTMLDivElement>(null)
  const [selectedCandlestick, setSelectedCandlestick] = React.useState<
    CandleStickData | undefined
  >()

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: post.symbol,
      },
    },
    fetchPolicy: 'cache-first',
  })

  const useAssetDataQueryResult = useAssetDataQuery({
    variables: {
      assetType: AssetTypeEnum.stock,
      code: post.symbol.toUpperCase(),
      date: post.date.getFullYear().toString(),
    },
    fetchPolicy: 'network-only',
  })

  const chartData = useMemo(() => {
    return useAssetDataQueryResult.data?.assetData?.length
      ? useAssetDataQueryResult.data.assetData.map(item => {
          const priceBarDate =
            item!.data.assetType === 'STOCK'
              ? new ExDate(item!.date)
              : new ExDate(item!.date, { tz: 'UTC' })

          return {
            ...item!.data,
            date: priceBarDate,
          }
        })
      : undefined
  }, [useAssetDataQueryResult.data?.assetData])

  useEffect(() => {
    const processImages = async () => {
      if (!chartData || !post.date) return

      // First, try to find the candlestick that matches the parsed date
      let matchingCandlestick = chartData.find(
        (candlestick: { date: ExDate }) =>
          post.date.date === candlestick.date.date
      )

      // If no matching candlestick is found, find the first candlestick after the parsed date
      if (!matchingCandlestick) {
        const sortedChartData = chartData.sort((a, b) => {
          return a.date.datetime.diff(b.date.date)
        })
        matchingCandlestick = sortedChartData.find(
          (candlestick: { date: ExDate }) =>
            candlestick.date.datetime.isAfter(post.date.datetime)
        )
      }

      setSelectedCandlestick(matchingCandlestick)

      await new Promise(resolve => setTimeout(resolve)) // Simulating an async operation

      const images = elementRef.current
        ? elementRef.current.getElementsByTagName('img')
        : []
      const loadPromises = Array.from(images).map(image => {
        if (image.complete) {
          return Promise.resolve()
        }
        return new Promise(resolve => {
          image.onload = resolve
          image.onerror = resolve
        })
      })

      await Promise.all(loadPromises)
    }

    processImages().catch(console.error)
  }, [chartData, post.date])

  return (
    <>
      <div className={classes.wrapper} ref={elementRef}>
        <div className={classes.titleWrapper}>
          <LogoNoBeta />
        </div>
        <div className={classes.postTitleWrapper}>
          <div className={classes.postTitleLeft}>
            <div className={classes.symbolName}>
              {symbolData?.getSymbol?.name}
            </div>
            <div className={classes.postTitle}>{post.title}</div>
            <div className={classes.userWrapper}>
              <div className={classes.avatarWrapper}>
                <UserAvatar emailHash={post.emailHash} />
              </div>
              <div className={classes.username}>{post.username}</div>
              <div className={classes.symbol}>${post.symbol}</div>
              <div className={classes.topicTime}>
                {getTimeElapsedRounded(post.date, post.createdAt)}
              </div>
            </div>
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faMessage} className={classes.message} />
              <div className={classes.numberOfReplies}>
                {post.numberOfReplies}
              </div>
            </div>
          </div>
          <div className={classes.postTitleRight}>
            <div className={classes.arrowsWrapper}>
              <FontAwesomeIcon icon={faArrowUp} className={classes.arrow} />
              <div className={classes.votes}>{post.voteNum}</div>
              <FontAwesomeIcon icon={faArrowDown} className={classes.arrow} />
            </div>
          </div>
        </div>
        {chartData && (
          <div className={classes.chartWrapper}>
            <CandlestickChart
              chartData={chartData}
              selectedCandlestick={selectedCandlestick}
              highlightedDay={undefined}
              onReady={onReady}
            />
          </div>
        )}
        {!noteText && post.text && (
          <div className={classes.postTextWrapper}>{post.text}</div>
        )}
        {noteText && <div className={classes.postTextWrapper}>{noteText}</div>}
        {!noteText && post.url && (
          <div className={classes.postTextWrapper}>
            {extractHostname(post.url)}
          </div>
        )}
      </div>
    </>
  )
}

export default ShareablePost
