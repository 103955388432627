import React, { useRef, useMemo, useEffect } from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'
import { ExDate } from '../../../constants'
import UserAvatar from 'components/UserAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faMessage,
} from '@fortawesome/free-solid-svg-icons'
import CandlestickChart from 'components/CandlestickChart'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { useAssetDataQuery, useGetSymbolQuery } from 'generated/graphql'
import { CandleStickData } from 'components/CandlestickChart/interfaces'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import { CommentData } from 'components/common/CommentWithParent/Comment.interfaces'
import LogoNoBeta from 'components/Images/LogoNoBeta'
import MarkdownViewer from 'components/inputs/MarkdownViewer'
import classNames from 'classnames'

interface ShareableCommentProps {
  commentWithParent: CommentData
  isReadyForCapture?: boolean
  onReady?: () => void
}

function ShareableComment({
  commentWithParent,
  isReadyForCapture,
  onReady,
}: ShareableCommentProps) {
  const classes = useStyles(theme)
  const elementRef = useRef<HTMLDivElement>(null)
  const [selectedCandlestick, setSelectedCandlestick] = React.useState<
    CandleStickData | undefined
  >()

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: commentWithParent.parentPost?.symbol!,
      },
    },
    fetchPolicy: 'cache-first',
  })

  const useAssetDataQueryResult = useAssetDataQuery({
    variables: {
      assetType: AssetTypeEnum.stock,
      code: commentWithParent.parentPost!.symbol!.toUpperCase(),
      date: commentWithParent.parentPost!.date!.getFullYear().toString(),
    },
    fetchPolicy: 'network-only',
  })

  const chartData = useMemo(() => {
    return useAssetDataQueryResult.data?.assetData?.length
      ? useAssetDataQueryResult.data.assetData.map(item => {
          const priceBarDate =
            item!.data.assetType === 'STOCK'
              ? new ExDate(item!.date)
              : new ExDate(item!.date, { tz: 'UTC' })

          return {
            ...item!.data,
            date: priceBarDate,
          }
        })
      : undefined
  }, [useAssetDataQueryResult.data?.assetData])

  useEffect(() => {
    const processImages = async () => {
      if (!chartData || !commentWithParent.parentPost!.date!) return

      // First, try to find the candlestick that matches the parsed date
      let matchingCandlestick = chartData.find(
        (candlestick: { date: ExDate }) =>
          commentWithParent.parentPost!.date!.date === candlestick.date.date
      )

      // If no matching candlestick is found, find the first candlestick after the parsed date
      if (!matchingCandlestick) {
        const sortedChartData = chartData.sort((a, b) => {
          return a.date.datetime.diff(b.date.date)
        })
        matchingCandlestick = sortedChartData.find(
          (candlestick: { date: ExDate }) =>
            candlestick.date.datetime.isAfter(
              commentWithParent.parentPost!.date!.datetime
            )
        )
      }

      setSelectedCandlestick(matchingCandlestick)

      await new Promise(resolve => setTimeout(resolve, 100)) // Simulating an async operation

      const images = elementRef.current
        ? elementRef.current.getElementsByTagName('img')
        : []
      const loadPromises = Array.from(images).map(image => {
        if (image.complete) {
          return Promise.resolve()
        }
        return new Promise(resolve => {
          image.onload = resolve
          image.onerror = resolve
        })
      })

      await Promise.all(loadPromises)
    }

    processImages().catch(console.error)
  }, [chartData, commentWithParent.parentPost!.date])

  return (
    <>
      <div className={classes.wrapper} ref={elementRef}>
        <div className={classes.titleWrapper}>
          <LogoNoBeta />
        </div>
        <div className={classes.postTitleWrapper}>
          <div className={classes.postTitleLeft}>
            <div className={classes.symbolName}>
              {symbolData?.getSymbol?.name}
            </div>
            <div className={classes.postTitle}>
              {commentWithParent.parentPost!.title}
            </div>
            <div className={classes.userWrapper}>
              <div className={classes.avatarWrapper}>
                <UserAvatar
                  emailHash={commentWithParent.parentPost!.emailHash}
                />
              </div>
              <div className={classes.username}>
                {commentWithParent.parentPost!.username}
              </div>
              <div className={classes.symbol}>
                ${commentWithParent.parentPost!.symbol}
              </div>
              <div className={classes.topicTime}>
                {getTimeElapsedRounded(
                  commentWithParent.parentPost!.date,
                  commentWithParent.parentPost!.createdAt
                )}
              </div>
            </div>
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faMessage} className={classes.message} />
              <div className={classes.numberOfReplies}>
                {commentWithParent.parentPost!.numberOfReplies}
              </div>
            </div>
          </div>
          <div className={classes.postTitleRight}>
            <div className={classes.arrowsWrapper}>
              <FontAwesomeIcon icon={faArrowUp} className={classes.arrow} />
              <div className={classes.votes}>
                {commentWithParent.parentPost!.voteNum}
              </div>
              <FontAwesomeIcon icon={faArrowDown} className={classes.arrow} />
            </div>
          </div>
        </div>
        {chartData && (
          <div className={classes.chartWrapper}>
            <CandlestickChart
              chartData={chartData}
              selectedCandlestick={selectedCandlestick}
              highlightedDay={undefined}
              onReady={onReady}
            />
          </div>
        )}
        <div className={classes.commentsWrapper}>
          <div className={classes.commentsInnerWrapper}>
            {commentWithParent.parentComment && (
              <div className={classes.commentWrapper}>
                <div className={classes.postTitleLeft}>
                  <div className={classes.userWrapper}>
                    <div className={classes.avatarWrapper}>
                      <UserAvatar
                        emailHash={commentWithParent.parentComment!.emailHash}
                      />
                    </div>
                    <div className={classes.commentUsername}>
                      {commentWithParent.parentComment!.username}
                    </div>
                    <div className={classes.commentTime}>
                      {getTimeElapsedRounded(
                        commentWithParent.parentComment!.date,
                        commentWithParent.parentComment!.createdAt
                      )}
                    </div>
                  </div>
                  <div className={classes.commentTextWrapper}>
                    <MarkdownViewer
                      markdownSource={commentWithParent.parentComment!.content}
                    />
                  </div>
                  <div className={classes.iconWrapperComment}>
                    <FontAwesomeIcon
                      icon={faMessage}
                      className={classes.message}
                    />
                    <div className={classes.numberOfReplies}>
                      {commentWithParent.parentComment!.numberOfReplies}
                    </div>
                  </div>
                </div>
                <div className={classes.postTitleRight}>
                  <div className={classes.arrowsWrapper}>
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className={classes.arrowComment}
                    />
                    <div className={classes.votes}>
                      {commentWithParent.parentComment!.voteNum}
                    </div>
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      className={classes.arrowComment}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className={classNames(
                classes.commentWrapper,
                commentWithParent.parentComment && classes.commentIndent
              )}
            >
              <div className={classes.postTitleLeft}>
                <div className={classes.userWrapper}>
                  <div className={classes.avatarWrapper}>
                    <UserAvatar emailHash={commentWithParent.emailHash} />
                  </div>
                  <div className={classes.commentUsername}>
                    {commentWithParent.username}
                  </div>
                  <div className={classes.commentTime}>
                    {getTimeElapsedRounded(
                      commentWithParent.date,
                      commentWithParent.createdAt
                    )}
                  </div>
                </div>
                <div className={classes.commentTextWrapper}>
                  <MarkdownViewer markdownSource={commentWithParent.content} />
                </div>
                <div className={classes.iconWrapperComment}>
                  <FontAwesomeIcon
                    icon={faMessage}
                    className={classes.message}
                  />
                  <div className={classes.numberOfReplies}>
                    {commentWithParent.numberOfReplies}
                  </div>
                </div>
              </div>
              <div className={classes.postTitleRight}>
                <div className={classes.arrowsWrapper}>
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className={classes.arrowComment}
                  />
                  <div className={classes.votes}>
                    {commentWithParent.voteNum}
                  </div>
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    className={classes.arrowComment}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShareableComment
