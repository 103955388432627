export const NAVIGATION_SIDEBAR_ACTION_NAMES = {
  CHART: 'Chart',
} as const

export const NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES = {
  ABOUT: 'About',
  PRIVACYPOLICY: 'Privacy Policy',
  TERMSOFSERVICE: 'Terms of Services',
  SITERULES: 'Site rules',
  CONTACT: 'Contact',
} as const
