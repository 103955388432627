import useStyles from './styles'
import theme from '../../mui-theme'
import HomeIcon from 'components/Icons/HomeIcon'
import SearchIcon from 'components/Icons/SearchIcon'
import PlusIcon from 'components/Icons/PlusIcon'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import {
  GetNotificationsDocument,
  GetUnreadNotificationsCountDocument,
  useGetCurrentUserQuery,
  useGetSymbolQuery,
  useGetUnreadNotificationsCountQuery,
} from 'generated/graphql'
import NotificationBellMobile from 'components/Notification/NotificationBellMobile'
import { useApolloClient } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import MessageIcon from 'components/Icons/MessageIcon'
import { useState } from 'react'
import SignUpModal from 'components/auth/SignUpModal/SignUpModal'

export default function MobileNav() {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const client = useApolloClient()
  const location = useLocation()
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false)

  const handleSignUpModalClose = () => {
    setShowSignUpModal(false)
  }

  const { data: getUnreadNotifications } = useGetUnreadNotificationsCountQuery({
    fetchPolicy: 'cache-first',
  })

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  let userLastViewedSymbol = 'spy'
  let userLastViewedSymbolType = 'stock'

  if (getCurrentUserData?.getCurrentUser) {
    userLastViewedSymbol =
      getCurrentUserData?.getCurrentUser?.lastViewedSymbol || ''
  }

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: getCurrentUserData?.getCurrentUser?.lastViewedSymbol || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  if (symbolData?.getSymbol?.type) {
    userLastViewedSymbolType = symbolData.getSymbol?.type.toLowerCase()
  }

  const updateCacheAfterPopupClosed = () => {
    const notificationsData = client.readQuery({
      query: GetNotificationsDocument,
    })

    if (notificationsData) {
      const updatedNotifications =
        notificationsData.getNotifications.notifications.map(
          (notification: any) => ({
            ...notification,
            read: true,
          })
        )

      client.writeQuery({
        query: GetNotificationsDocument,
        data: {
          getNotifications: {
            ...notificationsData.getNotifications,
            notifications: updatedNotifications,
          },
        },
      })
    }

    // Update the unread notifications count
    const unreadNotificationsData = client.readQuery({
      query: GetUnreadNotificationsCountDocument,
    })

    if (unreadNotificationsData) {
      client.writeQuery({
        query: GetUnreadNotificationsCountDocument,
        data: {
          getUnreadNotificationsCount: 0,
        },
      })
    }
  }

  // Generate the dynamic asset creation path
  const dynamicAssetCreatePath = getCurrentUserData?.getCurrentUser
    ? ROUTES.ASSET.replace(':symbol', userLastViewedSymbol)
        .replace(':type', userLastViewedSymbolType)
        .toLowerCase() + '/create'
    : ''

  // Determine if the current path matches the dynamic asset creation path
  const isOnPlusIconPage = location.pathname === dynamicAssetCreatePath

  const handleHomeClick = () => {
    navigate(ROUTES.HOME)
  }

  const handleSearchClick = () => {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  const handlePlusClick = () => {
    if (getCurrentUserData?.getCurrentUser) {
      // User is logged in
      const assetPath =
        ROUTES.ASSET.replace(':symbol', userLastViewedSymbol)
          .replace(':type', userLastViewedSymbolType)
          .toLowerCase() + '/create'
      navigate(assetPath)
    } else {
      // User is not logged in
      setShowSignUpModal(true)
    }
  }

  const handleNotificationClick = () => {
    if (getCurrentUserData?.getCurrentUser) {
      // User is logged in
      updateCacheAfterPopupClosed()
      navigate(ROUTES.NOTIFICATIONS)
    } else {
      // User is not logged in, show sign up modal
      setShowSignUpModal(true)
    }
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div onClick={handleHomeClick}>
          <HomeIcon isSelected={location.pathname === ROUTES.HOME} />
        </div>
        <div onClick={handleSearchClick}>
          <SearchIcon
            isSelected={location.pathname === ROUTES.SENTIMENT_DISCOVERY}
          />
        </div>
        <div onClick={handlePlusClick}>
          <PlusIcon isSelected={isOnPlusIconPage} />
        </div>
        <div onClick={handleNotificationClick}>
          <NotificationBellMobile
            isSelected={location.pathname === ROUTES.NOTIFICATIONS}
            unreadNotifications={
              getUnreadNotifications?.getUnreadNotificationsCount || 0
            }
          />
        </div>
        <div className={classes.disabledIcon}>
          <MessageIcon />
        </div>
      </div>
      <SignUpModal open={showSignUpModal} onClose={handleSignUpModalClose} />
    </>
  )
}
