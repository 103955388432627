import { makeStyles } from '@mui/styles'
import { colors } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    zIndex: 1,

    // [theme.breakpoints.down('lg')]: {
    //   height: 'calc(100% - 73px)',
    // },
  },

  drawerPaperRight: {
    backgroundColor: colors.drawer_bg_right,
  },

  closeBtnRight: {
    backgroundColor: 'transparent',
    color: colors.textGrey,
    right: '21px',
    top: '17px',
    padding: 0,
    width: '13px',
    height: '13px',
    position: 'absolute',
  },
})
