import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(
  {
    markdownResult: {
      wordBreak: 'break-word',
      display: 'block',
      lineHeight: 1.42857143,
      fontSize: 12,

      '@global': {
        'h1, h2, h3': {
          marginTop: 20,
          marginBottom: 10,
        },

        'h4, h5, h6': {
          marginTop: 10,
          marginBottom: 10,
        },

        h1: {
          fontSize: 36,
        },

        h2: {
          fontSize: 30,
        },

        h3: {
          fontSize: 24,
        },

        h4: {
          fontSize: 18,
        },

        h5: {
          fontSize: 14,
        },

        h6: {
          fontSize: 12,
        },

        'p, li, h1, h2, h3, h4, h5, h6': {
          fontFamily: fonts.dm_sans,
          color: colors.text_white,
        },

        'p, li': {
          fontSize: 12,
        },

        p: {
          margin: '0 0 10px',
        },

        'b, strong': {
          fontWeight: 'bold',
        },

        'menu, ol, ul': {
          listStyleType: 'disc',
        },

        'ul, ol': {
          marginTop: 0,
          marginBottom: 10,
          paddingInlineStart: '40px',
        },

        ol: {
          display: 'block',
          listStyleType: 'decimal',
        },

        li: {
          display: 'list-item',
        },

        'li ul, li ol': {
          marginBottom: 0,
        },

        em: {
          fontStyle: 'italic',
        },

        a: {
          color: colors.blue_light,
          textDecoration: 'none',
        },

        hr: {
          marginTop: 20,
          marginBottom: 20,
          border: 0,
          borderTop: `1px solid ${colors.text_white}`,
        },

        blockquote: {
          padding: '10px 20px',
          margin: '0 0 20px',
          fontSize: 17.5,
          borderLeft: `5px solid ${colors.text_white}`,
        },

        'blockquote p:last-child': {
          margin: 0,
        },

        pre: {
          display: 'block',
          padding: '9.5px',
          margin: '0 0 10px',
          fontSize: '13px',
          lineHeight: 1.42857143,
          color: colors.text_white,
          wordBreak: 'break-all',
          wordWrap: 'break-word',
          backgroundColor: colors.drawer_bg,
          border: `1px solid ${colors.border_light_grey}`,
          borderRadius: 4,
        },

        code: {
          padding: '2px 4px',
          fontSize: '90%',
          color: colors.text_white,
          backgroundColor: colors.drawer_bg,
          borderRadius: '4px',

          '.hljs-keyword': {
            color: '#333',
            fontWeight: 'bold',
          },

          '.hljs-built_in, .hljs-builtin-name': {
            color: '#0086b3',
          },

          '.hljs-number, .hljs-literal, .hljs-variable, .hljs-template-variable, .hljs-tag .hljs-attr':
            {
              color: '#008080',
            },
        },

        table: {
          width: '100%',
          maxWidth: '100%',
          marginBottom: '20px',
          color: colors.text_white,
        },

        'table > caption + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > th, table > thead:first-child > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > td':
          {
            borderTop: 0,
          },

        'table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td':
          {
            padding: '8px',
            lineHeight: 1.42857143,
            verticalAlign: 'top',
            borderTop: '1px solid #ddd',
          },

        'table > thead > tr > th': {
          verticalAlign: 'bottom',
          borderBottom: '2px solid #ddd',
          textAlign: 'left',
        },

        img: {
          maxWidth: '35%',
        },
      },
    },

    ticker: {
      color: colors.blue_light,
    },

    isPreview: {
      backgroundColor: colors.input_bg,
      padding: 10,
    },
  },
  { index: 1 }
)
