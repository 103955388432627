import Link from '@mui/material/Link'
import React from 'react'
import { colors, fonts } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface ResendVerificationCodeProps {
  onResendVerificationLink: () => void
}

const ResendVerificationLink: React.FC<ResendVerificationCodeProps> = props => {
  const deviceType = useDeviceType()

  return (
    <Link
      sx={{
        fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
        fontFamily: fonts.dm_sans,
        fontWeight: deviceType === DeviceType.Mobile ? 400 : 'lighter',
        color: colors.blue_light,
        textDecoration: 'none',
      }}
      component="button"
      variant="body1"
      onClick={() => props.onResendVerificationLink()}
    >
      Resend verification link
    </Link>
  )
}

export default ResendVerificationLink
