import { Tab, Tabs } from '@mui/material'
import { forwardRef } from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'
import React from 'react'
import classNames from 'classnames'
import AccountSettingsPanel from 'components/UserSettingsSidebar/AccountSettingsPanel'
import ProfileSettingsPanel from 'components/UserSettingsSidebar/ProfileSettingsPanel'
import { UserProfile } from '../interfaces/user'
import { useUserOperations } from 'hooks/useUserOperations'
import NotificationSettingsPanel from 'components/NotificationSettingsPanel'
import { useEmailNotification } from 'hooks/useEmailNotification'

export interface UserSettingsProps {
  currentUser: UserProfile
}

export const getTickerUrl = (ticker?: string): string => {
  return '/asset/' + ticker!.toLowerCase()
}

export default forwardRef<HTMLInputElement, UserSettingsProps>(props => {
  const classes = useStyles(theme)
  const [selectedTabItemIndex, setSelectedTabItemIndex] = React.useState(0)
  const { emailNotification, handleEmailNotificationChange } =
    useEmailNotification()

  const { setPassword, accountSave, profileSave, changePasswordError } =
    useUserOperations()

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newSelectedTabIndex: number
  ) => {
    setSelectedTabItemIndex(newSelectedTabIndex)
  }

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={selectedTabItemIndex}
        className={classes.tabs}
        onChange={handleTabChange}
        aria-label="user tabs"
        sx={{
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        }}
        classes={{
          indicator: classes.selectedTabIndicator,
        }}
      >
        <Tab
          key={'0'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 0 ? classes.selectedTab : ''
          )}
          label={<div>Account</div>}
        />
        <Tab
          key={'1'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 1 ? classes.selectedTab : ''
          )}
          label={<div>Profile</div>}
        />
        <Tab
          key={'2'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 2 ? classes.selectedTab : ''
          )}
          label={<div>Notifications</div>}
        />
      </Tabs>
      <div className={classes.tabContent}>
        {selectedTabItemIndex === 0 && (
          <AccountSettingsPanel
            id={props.currentUser.userId || ''}
            email={props.currentUser.email || ''}
            timezone={props.currentUser?.timeZone || ''}
            social={props.currentUser?.social || false}
            password_error={changePasswordError}
            onAccountSaveClick={accountSave}
            onPasswordChange={setPassword}
          />
        )}
        {selectedTabItemIndex === 1 && (
          <ProfileSettingsPanel
            id={props.currentUser?.userId || ''}
            username={props.currentUser?.nickname || ''}
            reddit={props.currentUser?.reddit || ''}
            twitter={props.currentUser?.twitter || ''}
            facebook={props.currentUser?.facebook || ''}
            instagram={props.currentUser?.instagram || ''}
            tradingView={props.currentUser?.tradingView || ''}
            onProfileSaveClick={profileSave}
          />
        )}
        {selectedTabItemIndex === 2 && <NotificationSettingsPanel />}
      </div>
    </div>
  )
})
