import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.drawer_bg_right,
    fontFamily: fonts.dm_sans,
    color: colors.white,
    fontStyle: 'normal',
    borderRadius: '10px',
    outline: 'none',
    width: '746px',
    height: '555px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
    },
  },
  header: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
    marginBottom: '24px',
  },
  listItem: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    display: 'flex',
    marginBottom: '8px',
  },
  SignUpWithEmail: {
    textAlign: 'center',
    color: colors.blue_lighter,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '4px',
    textDecoration: 'none',
  },
  icon: {
    display: 'flex',
    marginRight: '8px',
  },
  image: {
    height: '100%',
    width: '105%',
  },
  imageSection: {
    flexShrink: 0,
    height: '100%',
    width: 372,
    borderRadius: '10px 0 0 10px',
  },
  textContent: {
    position: 'relative',
    width: 406,
    padding: '48px 58px 48px 48px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
      padding: '0 38px',
      alignSelf: 'center',
    },
  },
  iconClose: {
    position: 'absolute',
    right: '24px',
    top: '16px',
    cursor: 'pointer',
  },
  signUpButton: {
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  alreadyHaveAccount: {
    marginTop: '8px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.textGreyLight,
    alignSelf: 'center',
  },
  loginLink: {
    color: colors.blue_lighter,
    textDecoration: 'none',
  },
}))
