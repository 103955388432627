import AuthBoxContainer from 'components/auth/common/AuthBoxContainer'
import AuthSubmitButton from 'components/auth/common/AuthSubmitButton'
import CenteredBoxScreenWrapper from 'components/auth/common/CenteredBoxScreenWrapper'
import SpaceBetween from 'components/auth/common/SpaceBetween'
import ROUTES from 'constants/routes'
import { useGetCurrentUserQuery } from 'generated/graphql'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const VerifyEmailComplete = () => {
  const navigate = useNavigate()

  const { data, loading, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  useEffect(() => {
    setTimeout(() => {
      if (!!data?.getCurrentUser) {
        navigate(ROUTES.SENTIMENT_DISCOVERY)
      } else {
        navigate(ROUTES.LOGIN)
      }
    }, 2000)
  }, [])

  // function to set description text based on whether or not the user is logged in
  const descriptionText = () => {
    if (!!data?.getCurrentUser) {
      return `Use our Sentiment Discovery Dashboard™ to find out about an interesting stock, or other security, to learn about and discuss with others.`
    } else {
      return `Currently rerouting to our log in page...`
    }
  }

  return (
    <CenteredBoxScreenWrapper>
      <AuthBoxContainer
        headingText={`Your account has been created`}
        descriptionText={descriptionText()}
      >
        <SpaceBetween spaceSize={3} sx={{ mb: 3, mt: 3 }} />

        <AuthSubmitButton>FIND STOCKS TO DISCUSS</AuthSubmitButton>
      </AuthBoxContainer>
    </CenteredBoxScreenWrapper>
  )
}

export default VerifyEmailComplete
