import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useNavigate } from 'react-router-dom'
import CenteredBoxScreenWrapper from 'components/auth/common/CenteredBoxScreenWrapper'
import { Button } from '@mui/material'
import ROUTES from 'constants/routes'
import ComingSoonTooltip from 'components/common/ComingSoonTooltip'

interface Props {
  lastViewedSymbol?: string | null
  lastViewedSymbolType?: string | null
}

export const NotFound: React.FC<Props> = props => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      <CenteredBoxScreenWrapper>
        <div className={classes.content}>
          <div className={classes.title}>404</div>
          <div className={classes.subTitle}>Page not found</div>
          <div className={classes.description}>
            This page doesn't exist or has been removed. We suggest you navigate
            back home.
          </div>
          <Button
            onClick={() => {
              navigate(ROUTES.HOME)
            }}
            className={classes.backBtn}
          >
            Back to Homepage
          </Button>
          <ComingSoonTooltip title="Coming soon (Report)">
            <div className={classes.report}>Report this error</div>
          </ComingSoonTooltip>
        </div>
      </CenteredBoxScreenWrapper>
    </div>
  )
}

export default NotFound
