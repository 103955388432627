import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import {
  IBaseNotification,
  IPostReplyNotification,
  IPricePredictionResultNotification,
} from 'types/notification'
import PostReplyNotificationRow from '../PostReplyNotificationRow'
import PricePredictionResultNotificationRow from '../PricePredictionResultNotificationRow'
import { Tab, Tabs } from '@mui/material'
import classNames from 'classnames'

// Add a new prop for the notifications
interface Props {
  notifications: IBaseNotification[]
}

const NotificationList: React.FC<Props> = ({ notifications }) => {
  const classes = useStyles(theme)

  const [selectedTabItemIndex, setSelectedTabItemIndex] = useState(0)

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newSelectedTabIndex: number
  ) => {
    setSelectedTabItemIndex(newSelectedTabIndex)
  }

  const isPostReplyNotification = (
    notification: IBaseNotification
  ): notification is IPostReplyNotification => {
    return 'text' in notification
  }

  const isPricePredictionResultNotification = (
    notification: IBaseNotification
  ): notification is IPricePredictionResultNotification => {
    return 'roundId' in notification
  }

  useEffect(() => {
    const firstUnreadNotification = notifications.find(
      notification => !notification.read
    )

    if (firstUnreadNotification) {
      if (isPricePredictionResultNotification(firstUnreadNotification)) {
        setSelectedTabItemIndex(1)
      } else {
        setSelectedTabItemIndex(0)
      }
    }
  }, [notifications])

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={selectedTabItemIndex}
        onChange={handleTabChange}
        aria-label="user tabs"
        sx={{
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        }}
        classes={{
          scroller: classes.tabsScroller,
        }}
      >
        <Tab
          key={'0'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 0 ? classes.selectedTab : ''
          )}
          label={<div>Comments</div>}
        />
        <Tab
          key={'1'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 1 ? classes.selectedTab : ''
          )}
          label={<div>Price predictions</div>}
        />
      </Tabs>
      {notifications.map(notification => {
        if (
          isPostReplyNotification(notification) &&
          selectedTabItemIndex === 0
        ) {
          return (
            <PostReplyNotificationRow
              limitTextCharacters={70}
              notification={notification}
            />
          )
        } else if (
          isPricePredictionResultNotification(notification) &&
          selectedTabItemIndex === 1
        ) {
          return (
            <PricePredictionResultNotificationRow
              limitTextCharacters={70}
              notification={notification}
            />
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export default NotificationList
