import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalInner: {
    backgroundColor: colors.drawer_bg_right,
    padding: 80,
    borderRadius: 16,
  },
  button: {
    fontSize: 16,

    [theme.breakpoints.down('lg')]: {
      borderRadius: 8,
    },
  },
  cancelButton: {
    marginTop: 24,
    height: 40,
    color: colors.textGreyLight,
    backgroundColor: colors.drawer_bg_right,
    textTransform: 'none',
  },
}))
