import { useState, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import theme from '../mui-theme'

export enum DeviceType {
  Mobile,
  Tablet,
  Desktop,
  Undefined,
}

export const useDeviceType = () => {
  // Helper function to get device type based on window width
  const getDeviceType = () => {
    const width = window.innerWidth
    if (width < theme.breakpoints.values.md) {
      return DeviceType.Mobile
    } else if (
      width >= theme.breakpoints.values.sm &&
      width < theme.breakpoints.values.md
    ) {
      return DeviceType.Tablet
    } else {
      return DeviceType.Desktop
    }
  }

  // Initialize state with the result of getDeviceType or Undefined if window is not defined (e.g., SSR)
  const [deviceType, setDeviceType] = useState(
    typeof window !== 'undefined' ? getDeviceType() : DeviceType.Undefined
  )

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  useEffect(() => {
    if (isMobile) {
      setDeviceType(DeviceType.Mobile)
    } else if (isTablet) {
      setDeviceType(DeviceType.Tablet)
    } else {
      setDeviceType(DeviceType.Desktop)
    }
  }, [isMobile, isTablet])

  return deviceType
}
