import Button from '@mui/material/Button'
import { ReactNode } from 'react'
import { colors, fonts } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  onClick?: () => void
  children: ReactNode
}

const AuthSubmitButton = ({ onClick, children }: Props) => {
  const deviceType = useDeviceType()

  return (
    <Button
      onClick={onClick}
      variant="contained"
      type="submit"
      sx={{
        color: colors.white,
        backgroundColor: colors.blue,
        borderRadius: deviceType === DeviceType.Mobile ? 2 : 0,
        width: '100%',
        fontSize: 11,
        letterSpacing: 2.44,
        fontFamily: fonts.dm_sans,
        lineHeight: 1,
        padding: deviceType === DeviceType.Mobile ? '17px 0' : '14px 0',
        '&:hover': {
          backgroundColor: colors.blue_medium_light,
          boxShadow: 'none',
        },
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      }}
    >
      {children}
    </Button>
  )
}

export default AuthSubmitButton
