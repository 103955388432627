import { USER_SIDEBAR_ACTION_NAMES } from './userSidebarConstants'

export const userSidebarTabItems = [
  {
    id: USER_SIDEBAR_ACTION_NAMES.ACCOUNT,
    index: 0,
    label: 'Account',
  },
  {
    id: USER_SIDEBAR_ACTION_NAMES.PROFILE,
    index: 1,
    label: 'Profile',
  },
  {
    id: USER_SIDEBAR_ACTION_NAMES.NOTIFICATIONS,
    index: 2,
    label: 'Notifications',
  },
]
