import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
const height = 34
const width = 32

export default makeStyles<typeof theme>(theme => ({
  img: {
    width: width,
    height: height,
  },
  wrapper: {
    display: 'flex',
  },
}))
