import { forwardRef, MutableRefObject } from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import React from 'react'
import Box from '@mui/material/Box'
import { CSSProperties } from '@mui/styles'

interface Props {
  title: string
  style?: CSSProperties | null
  children: React.ReactNode
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const [show, setShow] = React.useState(false)

  var rootRef: MutableRefObject<HTMLDivElement | null | undefined> =
    React.useRef()

  var dialogRef: MutableRefObject<HTMLDivElement | null | undefined> =
    React.useRef()

  const attachDialog = () => {
    setShow(true)

    setTimeout(() => {
      if (dialogRef.current && rootRef.current) {
        const rootRefPos = rootRef.current.getBoundingClientRect()
        const dialogRefPos = dialogRef.current.getBoundingClientRect()

        dialogRef.current.style.left = `${(
          rootRefPos.left -
          (dialogRefPos.width - rootRefPos.width / 2) / 2
        ).toString()}px`
        dialogRef.current.style.top = `${(
          rootRefPos.top +
          rootRefPos.height +
          10
        ).toString()}px`
      }
    })
  }

  return (
    <>
      <Box
        onClick={() => attachDialog()}
        ref={rootRef as MutableRefObject<HTMLDivElement>}
        sx={[
          !!props.style ? props.style : {},
          {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        ]}
      >
        <Box sx={{ pointerEvents: 'none' }}>{props.children}</Box>
        {show && (
          <div
            className={classes.dialog}
            ref={dialogRef as MutableRefObject<HTMLDivElement>}
          >
            {props.title}
          </div>
        )}
      </Box>
      {show && (
        <div
          className={classes.overlayBackground}
          onClick={() => setShow(false)}
        ></div>
      )}
    </>
  )
})
