import React from 'react'
import Box from '@mui/material/Box'
import { colors } from '../../constants'
import { useListChildCommentsQuery } from '../../generated/graphql'

import Comment, { Props } from './Comment'
import { CommentLoading } from './CommentLoading'
import { DEBUG_ENV } from '../../index'
import { CommentData } from 'components/common/CommentWithParent/Comment.interfaces'
import { mapComments } from 'utils/mappers/commentsMapper'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'

interface CommentListWrapperProps extends Props {
  userId: string | undefined
  replyNotificationEmail: boolean
  commentWithParent: CommentData
}

export const CommentListWrapper: React.FC<CommentListWrapperProps> = props => {
  const { data, loading } = useListChildCommentsQuery({
    variables: {
      commentId: props.commentWithParent?.id,
    },
  })

  if (loading && props.commentWithParent.numberOfReplies) {
    return (
      <Box sx={{ ml: 2.5, borderLeft: `2px solid ${colors.border}` }}>
        {Array(props.commentWithParent.numberOfReplies)
          .fill(0)
          .map(() => (
            <CommentLoading />
          ))}
      </Box>
    )
  }

  const onMoreActions = (
    id: string,
    action: string | number,
    commentId: string,
    state: {
      get: boolean
      set: Function
    }
  ) => {
    if (DEBUG_ENV)
      console.debug(
        `components->Comment->CommentListWrapper->onMoreActions() FIRED`
      )
    if (DEBUG_ENV)
      console.debug(`
    id: ${id}\n
    action: ${action}\n
    commentId: ${commentId}\n
    state: ${state.get}
    `)
    return props.onMoreActionsClick(id, action, commentId, state)
  }

  return (
    <Box sx={{ ml: 2.5, borderLeft: `2px solid ${colors.border}` }}>
      {data?.listChildComments &&
        mapComments(data?.listChildComments).map((commentData, index) => (
          <Comment
            commentWithParent={commentData}
            userId={props.userId}
            onReply={props.onReply}
            replyNotificationEmail={props.replyNotificationEmail}
            onUpdateComment={props.onUpdateComment}
            onCommentReplyClick={props.onCommentReplyClick}
            onTopicBookmarkClick={props.onTopicBookmarkClick}
            onCameraActionsClick={props.onCameraActionsClick}
            onShareActionsClick={props.onShareActionsClick}
            onMoreActionsClick={onMoreActions}
            onVote={(direction, commentId) =>
              props.onVote(
                direction,
                commentId,
                commentData.userVoteDirection === VoteDirection.up
              )
            }
            renderChildComments={() => (
              <CommentListWrapper {...props} commentWithParent={commentData} />
            )}
            key={commentData?.id || index}
            isCreator={props.userId === commentData.userId}
          />
        ))}
    </Box>
  )
}
