import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
  },

  tabs: {
    height: 28,
    minHeight: 28,
    borderBottom: `1px solid ${colors.drawer_border}`,
    padding: '0 16px',
  },

  tab: {
    display: 'inline-block',
    verticalAlign: 'top',
    height: 28,
    minHeight: 28,
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: 'auto',
    padding: 0,
    marginRight: 32,
    textTransform: 'capitalize',
  },

  selectedTab: {
    color: `${colors.text_white} !important`,

    '& svg': {
      color: colors.text_white,
    },
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
    bottom: 0,
  },

  body: {
    marginTop: 16,
    overflowY: 'auto',
    height: 'calc(100% - 29px)',

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },

    '&::-webkit-scrollbar': {
      '&-thumb': {
        backgroundColor: colors.box_bg,
      },
    },

    '& .infinite-scroll-component__outerdiv': {
      height: '100%',

      '& .infinite-scroll-component': {
        overflowY: 'overlay !important',
        'scrollbar-gutter': 'stable',
      },
    },
  },

  resultRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 20,
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    padding: '14px 16px',
    color: colors.text_white,
    cursor: 'pointer',
    borderBottom: `1px solid ${colors.drawer_border}`,

    '&:hover': {
      backgroundColor: colors.drawer_bg_right,
      borderRadius: 8,
    },
  },

  predictResultHereFeedImage: {
    width: '100%',
    paddingBottom: 16,
  },

  predictionResult: {
    padding: 16,
    borderBottom: `1px solid ${colors.drawer_border}`,
  },

  predictionResultHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },

  fnchartIcon: {
    width: 32,
  },

  fnchartText: {
    color: colors.text_white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    marginLeft: 8,
  },

  seeResultButton: {
    color: colors.text_white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    cursor: 'pointer',
    backgroundColor: colors.blue,
    textTransform: 'none',
    width: 106,
    marginLeft: 'auto',
  },

  resultIsHereText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: -0.4,
    marginBottom: 16,
  },

  value: {
    float: 'right',
    textAlign: 'right',
    maxWidth: '70%',
  },

  key: {
    display: 'flex',
    maxWidth: '30%',
  },

  keyText: {
    marginRight: 8,
    marginLeft: 8,
  },

  keyIcon: {
    alignSelf: 'center',
  },

  noResultsWrapper: {
    maxWidth: 300,
    padding: '0 16px',
    marginTop: 64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
  },

  noResultsTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.text_white,
  },

  noResultsBody: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.textGreyLight,
  },
})
