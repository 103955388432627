import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

const wrapperHeight = 77
const inputHeight = 57

export default makeStyles(
  {
    root: {
      width: '100%',
      paddingTop: 4,
    },

    multiLineTextWrapper: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: colors.text_input_inactive,
      borderBottomStyle: 'solid',

      '&:before': {
        content: `''`,
        borderBottom: 'none',
      },

      '&:after': {
        content: `''`,
        borderBottom: `1px solid ${colors.blue_light}`,
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'scaleX(0)',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -1,
      },

      '&:focus-within': {
        '&:after': {
          transform: 'scaleX(1)',
        },
      },
    },

    noUnderline: {
      borderBottom: 'none',

      '&:after': {
        borderBottom: 'none',
      },
    },

    multiLineTextWrapperError: {
      '&:after': {
        borderBottom: `1px solid ${colors.error}`,
        transform: 'scaleX(1)',
      },
    },

    input: {
      width: '100%',
      height: inputHeight,
      color: colors.text_white,
      padding: 0,
      fontSize: 12,
      fontFamily: fonts.dm_sans,
    },

    inputFullHeight: {
      height: wrapperHeight,
    },

    underline: {
      '&:after': {
        borderBottom: 'none',
      },

      '&:before': {
        borderBottom: 'none !important',
      },
    },

    inputBox: {
      width: '100%',
      marginTop: 0,
      backgroundColor: colors.input_bg,
    },

    clearBackground: {
      backgroundColor: 'inherit !important',
    },

    borderRadius: {
      borderRadius: 4,
    },

    inputRoot: {
      padding: '0px 10px',
      boxSizing: 'border-box',
      width: '100%',
    },

    inputRootNoLabel: {
      padding: 10,
    },

    formLabelRoot: {
      color: colors.textGrey,
      fontFamily: fonts.dm_sans,
      fontSize: 11,
      lineHeight: 1,
      marginBottom: 8,
      display: 'flex',
    },

    inputLabelRoot: {
      padding: '5px 10px',
      color: colors.text_input_inactive,
      transform: 'translateY(10px)',
      fontSize: 12,
      lineHeight: 1,
    },

    labelFocused: {
      color: `${colors.blue_light} !important`,
    },

    labelShrink: {
      transform: 'translateY(3px)',
      fontSize: 10,
    },

    formHelperRoot: {
      fontSize: 10,
      lineHeight: 1,
      marginTop: 5,
    },
    iconRight: {
      margin: 'auto 18px',
      cursor: 'pointer',
    },
  },
  { index: 100 }
)
