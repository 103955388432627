import theme from '../../mui-theme'
import useStyles from './styles'

export const PrivacyPolicy = () => {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>Privacy Policy</div>
        <div className={classes.lastRevised}>
          Effective September 12, 2023. Last Revised September 12, 2023
        </div>
        <div className={classes.bodyText}>
          <p>
            fnchart Inc (“Provider”) provides a community platform and
            application program interface (API) on a software as a service and
            platform as a service basis (“Service”, as further defined below).
            The Service is provided on a subscription basis as further described
            in this Agreement and on the Website under specific subscription
            plans offered by Provider from time to time. The Services are
            provided through the website https://fnchart.com (the “Website”).
            This Agreement applies to anyone (“You”/”Customer”) who subscribes
            for or otherwise uses the Services.
          </p>
          <p>
            PLEASE READ THIS POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR
            INFORMATION. IF YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT USE
            THE SERVICES.
          </p>
          <h3 className={classes.subHeader}>Scope of this Policy</h3>
          <p>
            For purposes of this Policy: An "Authorized User" means an
            individual who accesses the Services as, or on behalf of, a
            Customer, including Customer personnel. A "Customer" means an entity
            that has contracted with Provider (such as through an applicable
            Services Agreement or our Terms of Use) to access our Services. A
            "Site Visitor" means an individual who visits our Site outside of
            the context of our Customers' use of the Services. This Policy
            applies to our handling of information about Site Visitors,
            prospective Customers, and Customers and Authorized Users (in
            relation to their procurement of the Services and management of
            their relationship with the Provider). We refer collectively to
            these categories of individuals as "you" throughout this Policy.
          </p>
          <h3 className={classes.subHeader}>The Information We Collect</h3>
          <p>
            We collect, store, and use certain information from or about you for
            the purposes described below:
          </p>
          <h3 className={classes.subHeader}>Information You Provide To Us</h3>
          <p>
            We collect a variety of information that you provide directly to us.
            For example, we collect information from you when you: Sign up for
            and use the Services Communicate with us (for example, when you
            communicate with our customer service teams or submit requests or
            questions to us via online forms, email, phone, chat, or otherwise)
            Interact with us when we reach out to you by phone or email in our
            sales and marketing efforts Attend our conferences or events or
            interact with us at other conferences or events The types of data we
            collect directly from you include: First and last name Business
            contact information (email address, phone number, postal address)
            Personal contact information (email address, phone number, postal
            address) Username and password associated with the Services Your
            position and role in your company or organization In accordance with
            applicable law, photos, videos, or audio recordings of sales calls
            by voice or video chat Any other information you choose to directly
            provide to us in connection with your use of the Site or Services
          </p>
          <h3 className={classes.subHeader}>
            Information We Collect Through Automated Means
          </h3>
          <p>
            When you use our Site or Services, we and our service providers (who
            are third parties that perform services on our behalf) automatically
            collect certain information about your device and how you use the
            Site or Services, including your IP address, browser type, browser
            language, operating system, the state or country from which you
            accessed the Site or Services, software and hardware attributes
            (including device IDs), number of clicks, pages viewed and the order
            of those pages, the amount of time spent on particular pages, the
            date and time you used the Site or Services, and other similar
            information. From your IP address, we may be able to infer your
            general location (e.g., city/state or postal code). To collect this
            information, we and our service providers may use "cookies" or
            similar tools that track, measure, and analyze the behaviors and
            usage patterns of our users. Cookies are small data files that can
            be stored on your browser and device so we can recognize you when
            you return. We use cookies for analytics purposes, to deliver
            certain features of the Site or Services, to help us understand how
            users engage with the Site or Services, and to improve your
            experience. You may set your web browser to notify you when you
            receive a cookie and to accept or refuse certain cookies. However,
            if you elect not to accept cookies, some functionality and areas of
            the Site or Services may be restricted. To learn how to manage your
            cookies, please follow the instructions from your specific browser,
            or if accessing the Site or Services via a mobile device, refer to
            the manufacturer's instructions.
          </p>
          <h3 className={classes.subHeader}>
            Information We Collect From Others
          </h3>
          <p>
            From time to time, we may collect information about you from other
            sources, including our affiliates, marketing vendors, and
            third-party databases. We use this information to supplement the
            information that we collect directly from you and for any of the
            purposes described in the next section. When you "like" or "follow"
            us on Facebook, LinkedIn, Twitter or other social media sites, we
            may collect some information from you including your name, email
            address, and any comments or content you post relevant to us.
          </p>
          <h3 className={classes.subHeader}>How We Use Your Information</h3>
          <p>
            We use your information for various purposes depending on the types
            of information we have collected from and about you to: Provide and
            maintain our Site and Services Respond to your inquiries and
            requests for information, and provide you with more effective and
            efficient customer service Engage in analysis and research regarding
            use of the Site and Services, improve the Site and Services, and
            develop new products and services Measure the effectiveness of our
            advertising and deliver relevant advertising to you To suggest
            Services that may be of interest to you Secure our Site and Services
            and resolve technical issues being reported Comply with any
            procedures, laws, and regulations which apply to us where it is
            necessary for our legitimate interests or the legitimate interests
            of others Establish, exercise, or defend our legal rights where it
            is necessary for our legitimate interests or the legitimate
            interests of others
          </p>
          <p>
            Aggregate/De-Identified Information: We may aggregate and/or
            de-identify any information collected through our Services so that
            such information can no longer be linked to you or your device
            ("Aggregate/De-Identified Information"). We may use such information
            for any purpose, including without limitation for research and
            marketing purposes, and may also share such data with any third
            parties, including advertisers, partners, and sponsors.
          </p>
          <h3 className={classes.subHeader}>
            Legal Bases For Use Of Your Information
          </h3>
          <p>
            The legal bases for using your information as set out in this Policy
            are as follows: Where we need to perform the contract we are about
            to enter into or have entered into with you for the Site or Services
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests Where we need to comply with a legal or
            regulatory obligation Where we have your consent to process your
            information in a certain way
          </p>
          <h3 className={classes.subHeader}>
            Online Analytics And Advertising
          </h3>
          <p>
            ANALYTICS. We may use third-party web analytics services (such as
            those of Google Analytics) on our Site or Services to collect and
            analyze usage information through cookies and similar tools; engage
            in auditing, research, or reporting; assist with fraud prevention;
            and provide certain features to you. To prevent Google Analytics
            from using your information for analytics, you may install the
            Google Analytics Opt-out Browser Add-on by clicking here. If you
            receive email from us, we may use certain analytics tools, such as
            clear GIFs to capture data such as when you open our message or
            click on any links or banners our email contains. This data allows
            us to gauge the effectiveness of our communications and marketing
            campaigns. ONLINE ADVERTISING. We do not show ads. DO NOT TRACK
            ("DNT"). DNT is a privacy preference that web users can set in
            certain web browsers. We are committed to providing you with
            meaningful choices about the information collected on our Services
            through cookies and similar technologies, and that is why we provide
            the variety of opt-out mechanisms listed above. However, we do not
            currently recognize or respond to browser-initiated DNT signals.
          </p>
          <h3 className={classes.subHeader}>
            How We Share And Disclose Your Information
          </h3>
          <span>We will share your information in the following ways:</span>
          <p>
            Service Providers. We provide access to or share your information
            with select third parties who help us deliver or improve our Site
            and Services or perform services on our behalf, including billing
            and payment, advertising and marketing, analytics, fraud prevention,
            research, data storage or processing, security, and consulting and
            legal services. Affiliates and Subsidiaries. We may share the
            information we collect within the Provider's family of companies for
            the purposes outlined in this Policy. Your Company. If you are an
            Authorized User, we may provide your information to the company you
            are engaged or employed by to fulfill and enforce our applicable
            sales agreement with your company, and to inform your company
            regarding usage, support, or training needs. Protection of the
            Provider and Others. By using the Site or Services, you acknowledge
            and agree that we may access, retain, and disclose the information
            we collect and maintain about you if required to do so by applicable
            law or in a good faith belief that such access, retention or
            disclosure is reasonably necessary to: (a) comply with legal process
            (e.g. a subpoena or court order); (b) enforce any contracts with
            you; (c) respond to claims that any content violates the rights of
            third parties; and/or (d) protect the rights, property or personal
            safety of the Provider, its agents and affiliates, its users and/or
            the public. Business Transactions. In accordance with applicable
            legal obligations, your information may be provided to third parties
            in connection with a merger or acquisition (including transfers made
            as part of insolvency or bankruptcy proceedings) involving all or
            part of the Provider or our assets, or as part of a corporate
            reorganization or stock sale or other change in corporate control or
            business change, including for the purpose of determining whether to
            proceed or continue with such transaction or business relationship.
            Aggregate/De-Identified Information. From time to time, we may share
            Aggregate/De-identified Information about use of the Site or
            Services or our user base with partners and others in our
            discretion, but such information will not identify you personally.
            With Your Consent or at Your Direction. In addition to the sharing
            described in this Policy, we may share information about you with
            third parties whenever you consent to or direct such sharing.
          </p>
          <h3 className={classes.subHeader}>Retention Of Your Information</h3>
          <p>
            We keep your information for no longer than necessary for the
            purposes for which it is processed. The length of time for which we
            retain information depends on the purposes for which we collect and
            use it and/or as required to comply with applicable laws.
          </p>
          <h3 className={classes.subHeader}>Your Rights And Your Choices</h3>
          <span>
            You have certain rights with respect to your information as further
            described in this section.
          </span>
          <h3 className={classes.subHeader}>Your Legal Rights</h3>
          <p>
            If you would like further information in relation to your legal
            rights under applicable law or would like to exercise any of them,
            please contact us using the information in the "Contact Information"
            section below at any time. Your local laws (e.g., if you are a
            citizen or resident of the European Economic Area or California) may
            permit you to request that we: provide access to and/or a copy of
            certain information we hold about you update information which is
            out of date or incorrect delete certain information that we are
            holding about you restrict the way that we process and disclose
            certain of your information Such laws may also permit you to revoke
            your consent to the processing of your information for certain
            purposes. We will consider all requests and provide our response
            within the time period stated by applicable law and as otherwise
            required by applicable law. Please note, however, that certain
            information may be exempt from such requests in some circumstances,
            which may include if we need to keep processing your information for
            our legitimate interests or to comply with a legal obligation. We
            may request you provide us with information necessary to confirm
            your identity before responding to your request.
          </p>
          <h3 className={classes.subHeader}>Marketing Communications</h3>
          <p>
            If, in accordance with applicable legal requirements, we send you
            marketing communications regarding our Services or the services of
            third parties that we believe will be interesting to you, you can
            ask us to stop sending such communications at any time by contacting
            us using the information in the "Contact Information" section
            below.  In our marketing email messages, you can also opt out by
            following the instructions located at the bottom of such emails.
            Please note that, regardless of your request, we may still use and
            share certain information as permitted by this Policy or as required
            by applicable law. For example, you may not opt out of certain
            transactional emails from us, such as those confirming your requests
            or providing you with updates regarding our Policy or other terms.
          </p>
          <h3 className={classes.subHeader}>Third Party Links And Features</h3>
          <p>
            Our Services may contain links to third-party websites. If you
            choose to visit these sites and use their services, please note that
            we are not responsible for their content or privacy practices. The
            collection, use, and disclosure of your information will be subject
            to the privacy policies of the third-party websites, and not this
            Policy. We urge you to read the privacy policies of these third
            parties.
          </p>
          <h3 className={classes.subHeader}>International Users</h3>
          <p>
            Your information is maintained and processed by us and our
            third-party service providers in Canada, the United States, and
            elsewhere. Thus, your information may be maintained, processed, and
            stored in other jurisdictions that may have different data
            protection laws than those in your country of residence. In the
            event that your information is transferred in these ways, please
            note that we comply with applicable legal requirements governing the
            transfer of information across borders. By using the Services, you
            agree to and acknowledge these transfers.
          </p>
          <h3 className={classes.subHeader}>How We Protect Your Information</h3>
          <p>
            The Provider takes a variety of technical and organizational
            security measures to protect the information provided to us from
            loss, misuse, and unauthorized access, disclosure, alteration, or
            destruction. However, no Internet or email transmission is ever
            fully secure or error free. Please keep this in mind when disclosing
            any information to us online.
          </p>
          <h3 className={classes.subHeader}>
            Privacy Information For California Users
          </h3>
          <p>
            If you are a California resident, California law requires us to
            provide you with some additional information regarding how we
            collect, use, and share your "personal information" (as defined in
            the California Consumer Privacy Act ("CCPA")).
          </p>
          <h3 className={classes.subHeader}>
            Categories of Personal Information We Collect and How We Use Them
          </h3>
          <p>
            Throughout this Policy, we discuss in detail the specific pieces of
            personal information we collect from and about our users. Under the
            CCPA, we are also required to provide you with the "categories" of
            personal information we collect and how we use each category of
            personal information. The categories we collect are:
          </p>
          <p>
            Do Not Sell Rights. Please note that the CCPA sets forth certain
            obligations for businesses that "sell" personal information to third
            parties. We do not engage in such activity as defined in the CCPA
            and have not engaged in such activity in the past twelve months from
            the Effective Date of this Policy.
          </p>
          <h3 className={classes.subHeader}>Your California Privacy Rights</h3>
          <p>
            The CCPA allows you to make certain requests about your personal
            information. Specifically, the CCPA allows you to request us to:
            Inform you about the categories of personal information we collect
            or disclose about you; the categories of sources of such
            information; the business or commercial purpose for collecting your
            personal information; and the categories of third parties with whom
            we share/disclose personal information. Provide access to and/or a
            copy of certain personal information we hold about you. Delete
            certain personal information we have about you. Provide you with
            information about the financial incentives that we offer to you, if
            any.
          </p>
          <p>
            The CCPA further provides you with the right not to be discriminated
            against (as provided for in applicable law) for exercising your
            rights. Please note that certain information may be exempt from such
            requests under California law. For example, we need certain
            information to provide the Services to you. We also will take
            reasonable steps to verify your identity before responding to a
            request. If we are unable to verify you, we shall have the right,
            but not the obligation, to request additional information from you.
            If you would like further information regarding your legal rights
            under California law or would like to exercise any of them, or if
            you are an authorized agent making a request on a California
            consumer's behalf, please contact us using the contact information
            provided below.
          </p>
          <h3 className={classes.subHeader}>Shine the Light Disclosure</h3>
          <p>
            The California "Shine the Light" law gives residents of California
            the right under certain circumstances to request information from us
            regarding the manner in which we share certain categories of
            personal information (as defined in the Shine the Light law) with
            third parties for their direct marketing purposes. We do not share
            your personal information with third parties for their own direct
            marketing purposes.
          </p>
          <h3 className={classes.subHeader}>Changes To Our Policy</h3>
          <p>
            We reserve the right to amend this Policy at any time to reflect
            changes in the law, our data collection and use practices, or
            advances in technology. We will make the revised Policy accessible
            on the Services, so you should review the Policy periodically. You
            can know if the Policy has changed since the last time you reviewed
            it by checking the "Effective Date" at the beginning of the
            document. If we make a material change to the Policy, you will be
            provided with appropriate notice in accordance with legal
            requirements. By continuing to use the Services, you are confirming
            that you have read and understood the latest version of this Policy.
          </p>
          <h3 className={classes.subHeader}>Contact Information</h3>
          <p>
            The Provider welcomes your comments and questions regarding this
            Policy and the collection and use of your information. If you have
            questions or concerns, please email us at info@fnchart.com.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
