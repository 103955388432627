import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  Timestamp: any
}

export type AnalyticsEntry = {
  __typename?: 'AnalyticsEntry'
  date: Scalars['Date']
  createdAt: Scalars['Timestamp']
  totalUsers: Scalars['Float']
  totalStocksWatched: Scalars['Float']
  totalUsersFollowed: Scalars['Float']
  totalUsersWatchingAtLeastOneStock: Scalars['Float']
  totalUsersFollowingAtLeastOneUser: Scalars['Float']
  totalNotifications: Scalars['Float']
  totalNotificationsRead: Scalars['Float']
  totalComments: Scalars['Float']
  totalPostVotes: Scalars['Float']
  totalCommentVotes: Scalars['Float']
}

export type AssetData = {
  __typename?: 'AssetData'
  id: Scalars['ID']
  date: Scalars['String']
  symbol: Scalars['String']
  data: AssetValue
}

export type AssetValue = {
  __typename?: 'AssetValue'
  close: Scalars['Float']
  high: Scalars['Float']
  low: Scalars['Float']
  open: Scalars['Float']
  volume: Scalars['Float']
  assetType: Scalars['String']
}

export type CheckDuplicateUrlInput = {
  postSymbol: Scalars['String']
  url: Scalars['String']
}

export type CheckDuplicateUserInput = {
  nickname: Scalars['String']
}

export type ChildPost = {
  __typename?: 'ChildPost'
  childPostId: Scalars['ID']
  noteText?: Maybe<Scalars['String']>
  childPost?: Maybe<Post>
}

export type ChildPostInput = {
  childPostId: Scalars['ID']
  noteText?: Maybe<Scalars['String']>
}

export type Comment = {
  __typename?: 'Comment'
  id: Scalars['ID']
  text: Scalars['String']
  createdAt: Scalars['Timestamp']
  votes: Scalars['Float']
  numberOfReplies: Scalars['Float']
  deleted: Scalars['Boolean']
  userVotePositive?: Maybe<Scalars['Boolean']>
  user?: Maybe<User>
  post?: Maybe<Post>
  parentComment?: Maybe<Comment>
  childComments: Array<Comment>
}

export type CommentInput = {
  text: Scalars['String']
  postId: Scalars['String']
  parentCommentId?: Maybe<Scalars['String']>
  /** additional symbols to crosspost to */
  additionalSymbols?: Maybe<Array<Scalars['String']>>
}

export type CommentReply = {
  __typename?: 'CommentReply'
  comment: Comment
}

export type Contact = {
  __typename?: 'Contact'
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  message: Scalars['String']
}

export type ContactInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  message: Scalars['String']
}

export enum DateConfidence {
  Low = 'LOW',
  High = 'HIGH',
}

export type DateResponse = {
  __typename?: 'DateResponse'
  date: Scalars['Date']
  confidence: DateConfidence
}

export type Feed = {
  __typename?: 'Feed'
  items: Array<PostOrComment>
  nextOffset?: Maybe<Scalars['Timestamp']>
}

export type Mutation = {
  __typename?: 'Mutation'
  createComment: Comment
  updateComment: Scalars['Boolean']
  deleteComment: Scalars['Boolean']
  voteComment: Scalars['Boolean']
  deleteCommentVote: Scalars['Boolean']
  reportComment: Scalars['Boolean']
  createContact: Contact
  markNotifications: Scalars['Boolean']
  createPost: Post
  updatePost: Scalars['Boolean']
  deletePost: Scalars['Boolean']
  votePost: Scalars['Boolean']
  deletePostVote: Scalars['Boolean']
  reportPost: Scalars['Boolean']
  setStoryPosts: Scalars['Boolean']
  setPricePredictionVote: Scalars['Boolean']
  incrementTrendingSymbol: Scalars['Boolean']
  blockUser: Scalars['Boolean']
  unblockUser: Scalars['Boolean']
  deleteCurrentUser: Scalars['Boolean']
  updateUserAccount: Scalars['Boolean']
  updateUserProfile: Scalars['Boolean']
  updateLastViewedSymbol: Scalars['Boolean']
  unsubscribe: Scalars['String']
  updateNotificationSetttings: Scalars['Boolean']
  followUser: Scalars['Boolean']
  unfollowUser: Scalars['Boolean']
  addUserWatchedSymbol: Scalars['Boolean']
  removeUserWatchedSymbol: Scalars['Boolean']
}

export type MutationCreateCommentArgs = {
  data: CommentInput
}

export type MutationUpdateCommentArgs = {
  data: UpdateCommentInput
}

export type MutationDeleteCommentArgs = {
  id: Scalars['ID']
}

export type MutationVoteCommentArgs = {
  commentId: Scalars['String']
  positive: Scalars['Boolean']
}

export type MutationDeleteCommentVoteArgs = {
  commentId: Scalars['String']
}

export type MutationReportCommentArgs = {
  data: ReportCommentInput
}

export type MutationCreateContactArgs = {
  data: ContactInput
}

export type MutationMarkNotificationsArgs = {
  ids: Array<Scalars['ID']>
  read: Scalars['Boolean']
}

export type MutationCreatePostArgs = {
  data: PostInput
}

export type MutationUpdatePostArgs = {
  data: UpdatePostInput
}

export type MutationDeletePostArgs = {
  id: Scalars['ID']
}

export type MutationVotePostArgs = {
  postId: Scalars['String']
  positive: Scalars['Boolean']
}

export type MutationDeletePostVoteArgs = {
  postId: Scalars['String']
}

export type MutationReportPostArgs = {
  data: ReportPostInput
}

export type MutationSetStoryPostsArgs = {
  parentStoryId: Scalars['ID']
  childPosts: Array<ChildPostInput>
}

export type MutationSetPricePredictionVoteArgs = {
  roundId: Scalars['ID']
  higher: Scalars['Boolean']
}

export type MutationIncrementTrendingSymbolArgs = {
  symbol: Scalars['String']
}

export type MutationBlockUserArgs = {
  blockedUserId: Scalars['ID']
}

export type MutationUnblockUserArgs = {
  blockedUserId: Scalars['ID']
}

export type MutationUpdateUserAccountArgs = {
  data: UpdateUserAccount
}

export type MutationUpdateUserProfileArgs = {
  data: UpdateUserProfile
}

export type MutationUpdateLastViewedSymbolArgs = {
  data: UpdateLastViewedSymbol
}

export type MutationUnsubscribeArgs = {
  token: Scalars['String']
}

export type MutationUpdateNotificationSetttingsArgs = {
  data: UpdateNotificationsSettings
}

export type MutationFollowUserArgs = {
  userId: Scalars['ID']
}

export type MutationUnfollowUserArgs = {
  userId: Scalars['ID']
}

export type MutationAddUserWatchedSymbolArgs = {
  symbol: Scalars['String']
}

export type MutationRemoveUserWatchedSymbolArgs = {
  symbol: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  id: Scalars['ID']
  read: Scalars['Boolean']
  body: NotificationBody
}

export type NotificationBody = PostReply | CommentReply | PricePredictionResult

export type NotificationsList = {
  __typename?: 'NotificationsList'
  notifications: Array<Notification>
  nextOffset?: Maybe<Scalars['Timestamp']>
}

export type Post = {
  __typename?: 'Post'
  id: Scalars['ID']
  postType: PostType
  title: Scalars['String']
  text?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  date: Scalars['Date']
  postSymbol: Scalars['String']
  postAssetType: Scalars['String']
  createdAt: Scalars['Timestamp']
  votes: Scalars['Float']
  numberOfReplies: Scalars['Float']
  image?: Maybe<Scalars['String']>
  userVotePositive?: Maybe<Scalars['Boolean']>
  user?: Maybe<User>
  comments: Array<Comment>
  storyPosts: Array<ChildPost>
  symbol?: Maybe<Symbol>
}

export type PostCommentsArgs = {
  offset?: Maybe<Scalars['Int']>
}

export type PostInput = {
  title: Scalars['String']
  text?: Maybe<Scalars['String']>
  date: Scalars['Date']
  postSymbol: Scalars['String']
  postAssetType: Scalars['String']
  postType: PostType
  url?: Maybe<Scalars['String']>
  /** additional symbols to crosspost to */
  additionalSymbols?: Maybe<Array<Scalars['String']>>
  image?: Maybe<Scalars['String']>
}

export type PostOrComment = Post | Comment

export type PostReply = {
  __typename?: 'PostReply'
  comment: Comment
}

export enum PostType {
  Url = 'URL',
  Text = 'TEXT',
  Story = 'STORY',
}

export type PricePredictionResult = {
  __typename?: 'PricePredictionResult'
  vote: PricePredictionVote
}

export type PricePredictionRound = {
  __typename?: 'PricePredictionRound'
  id: Scalars['ID']
  startDate: Scalars['Date']
  symbol: Scalars['String']
  startClose: Scalars['Float']
  endClose?: Maybe<Scalars['Float']>
  isVotingOpen: Scalars['Boolean']
  endOfVotingTimestamp: Scalars['Timestamp']
  endOfRoundTimestamp: Scalars['Timestamp']
  symbolObj?: Maybe<Symbol>
  /**
   * Get the currently logged in user's vote for this round.
   *
   * Will return `None` if the user hasn't voted.
   */
  vote?: Maybe<PricePredictionVote>
}

export type PricePredictionVote = {
  __typename?: 'PricePredictionVote'
  id: Scalars['ID']
  userId: Scalars['ID']
  roundId: Scalars['ID']
  higher: Scalars['Boolean']
  win: Scalars['Boolean']
  round: PricePredictionRound
}

export type PrivateUser = {
  __typename?: 'PrivateUser'
  id: Scalars['ID']
  picture?: Maybe<Scalars['String']>
  email: Scalars['String']
  nickname?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  tradingView?: Maybe<Scalars['String']>
  reddit?: Maybe<Scalars['String']>
  lastViewedSymbol?: Maybe<Scalars['String']>
  social: Scalars['Boolean']
  createdAt: Scalars['Timestamp']
  replyNotificationEmail: Scalars['Boolean']
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  analytics: Array<AnalyticsEntry>
  assetData: Array<Maybe<AssetData>>
  checkDuplicateEmail: Scalars['Boolean']
  checkDuplicateUrl: Scalars['Boolean']
  checkDuplicateUser: Scalars['Boolean']
  checkSymbolsExist: Array<Scalars['String']>
  getComment: Comment
  getCurrentUser: PrivateUser
  getExchangeDate: DateResponse
  getFeed: Feed
  getFollowers: Array<User>
  getFollowing: Array<User>
  getMostWatchedSymbols: Array<Symbol>
  getNewestCommentsForUser: Array<Comment>
  getNewestPosts: Array<Post>
  getNewestPostsForUser: Array<Post>
  getNotifications: NotificationsList
  getPost: Post
  getPricePredictionRound?: Maybe<PricePredictionRound>
  getPricePredictionVote?: Maybe<PricePredictionVote>
  getRelatedPosts: Array<Post>
  getStoryPosts: Array<ChildPost>
  getSymbol?: Maybe<Symbol>
  getSymbolDate: DateResponse
  getSymbols: Array<Symbol>
  getTrendingPosts: Array<Post>
  getTrendingSymbols: Array<TrendingSymbol>
  getUnreadNotificationsCount: Scalars['Float']
  getUser: User
  getUserByNickname: User
  getUserWatchedSymbols: Array<Symbol>
  healthCheck: Scalars['Boolean']
  listChildComments: Array<Comment>
  posts: Array<Post>
  searchPosts: Array<SearchPostResult>
}

export type QueryAnalyticsArgs = {
  startDate?: Maybe<Scalars['Date']>
}

export type QueryAssetDataArgs = {
  code: Scalars['String']
  date: Scalars['String']
  assetType: Scalars['String']
}

export type QueryCheckDuplicateEmailArgs = {
  email: Scalars['String']
}

export type QueryCheckDuplicateUrlArgs = {
  data: CheckDuplicateUrlInput
}

export type QueryCheckDuplicateUserArgs = {
  data: CheckDuplicateUserInput
}

export type QueryCheckSymbolsExistArgs = {
  data: Array<Scalars['String']>
}

export type QueryGetCommentArgs = {
  id: Scalars['ID']
}

export type QueryGetExchangeDateArgs = {
  exchangeShortName: Scalars['String']
}

export type QueryGetFeedArgs = {
  offset?: Maybe<Scalars['Timestamp']>
}

export type QueryGetFollowersArgs = {
  userId: Scalars['ID']
}

export type QueryGetFollowingArgs = {
  userId: Scalars['ID']
}

export type QueryGetNewestCommentsForUserArgs = {
  userId: Scalars['ID']
  lastCreatedAt?: Maybe<Scalars['Timestamp']>
}

export type QueryGetNewestPostsArgs = {
  lastCreatedAt?: Maybe<Scalars['Timestamp']>
}

export type QueryGetNewestPostsForUserArgs = {
  userId: Scalars['ID']
  lastCreatedAt?: Maybe<Scalars['Timestamp']>
}

export type QueryGetNotificationsArgs = {
  unreadOnly?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Timestamp']>
  limit?: Maybe<Scalars['Int']>
  notificationType?: Maybe<Scalars['String']>
  markAsRead?: Maybe<Scalars['Boolean']>
}

export type QueryGetPostArgs = {
  id: Scalars['ID']
}

export type QueryGetPricePredictionRoundArgs = {
  startDate?: Maybe<Scalars['Date']>
}

export type QueryGetPricePredictionVoteArgs = {
  roundId: Scalars['ID']
}

export type QueryGetRelatedPostsArgs = {
  postId: Scalars['ID']
}

export type QueryGetStoryPostsArgs = {
  parentStoryId: Scalars['ID']
}

export type QueryGetSymbolArgs = {
  data: QuerySymbolInput
}

export type QueryGetSymbolDateArgs = {
  symbol: Scalars['String']
}

export type QueryGetSymbolsArgs = {
  data: QuerySymbolInput
}

export type QueryGetUserArgs = {
  id: Scalars['ID']
}

export type QueryGetUserByNicknameArgs = {
  nickname: Scalars['String']
}

export type QueryListChildCommentsArgs = {
  offset?: Maybe<Scalars['Int']>
  commentId: Scalars['ID']
}

export type QueryPostsArgs = {
  offset?: Maybe<Scalars['Float']>
  toDate?: Maybe<Scalars['Date']>
  postAssetType: Scalars['String']
  postSymbol: Scalars['String']
  ids?: Maybe<Array<Scalars['ID']>>
  postTypes?: Maybe<Array<PostType>>
}

export type QuerySearchPostsArgs = {
  searchText: Scalars['String']
  symbol?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
}

export type QuerySymbolInput = {
  symbol: Scalars['String']
}

export type ReportCommentInput = {
  type: Scalars['String']
  message: Scalars['String']
  commentId: Scalars['String']
}

export type ReportPostInput = {
  type: Scalars['String']
  message: Scalars['String']
  postId: Scalars['String']
}

export type SearchPostResult = {
  __typename?: 'SearchPostResult'
  postId: Scalars['String']
  confidence: Scalars['Float']
  post: Post
}

export type Symbol = {
  __typename?: 'Symbol'
  symbol: Scalars['String']
  name: Scalars['String']
  type: Scalars['String']
  exchangeShortName: Scalars['String']
}

export type TrendingSymbol = {
  __typename?: 'TrendingSymbol'
  symbol: Symbol
  score: Scalars['Float']
}

export type UpdateCommentInput = {
  commentId: Scalars['ID']
  text: Scalars['String']
  /** additional symbols to crosspost to */
  additionalSymbols?: Maybe<Array<Scalars['String']>>
}

export type UpdateLastViewedSymbol = {
  lastViewedSymbol?: Maybe<Scalars['String']>
}

export type UpdateNotificationsSettings = {
  replyNotificationEmail?: Maybe<Scalars['Boolean']>
}

export type UpdatePostInput = {
  id: Scalars['ID']
  title: Scalars['String']
  text?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['Date']>
  postType?: Maybe<PostType>
  url?: Maybe<Scalars['String']>
  /** additional symbols to crosspost to */
  additionalSymbols?: Maybe<Array<Scalars['String']>>
}

export type UpdateUserAccount = {
  id: Scalars['ID']
  timeZone?: Maybe<Scalars['String']>
}

export type UpdateUserProfile = {
  id: Scalars['ID']
  nickname: Scalars['String']
  twitter?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  tradingView?: Maybe<Scalars['String']>
  reddit?: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  picture?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  createdAt: Scalars['Timestamp']
  twitter?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  tradingView?: Maybe<Scalars['String']>
  reddit?: Maybe<Scalars['String']>
  emailHash: Scalars['String']
  followersCount: Scalars['Float']
  followingCount: Scalars['Float']
  blocked: Scalars['Boolean']
}

export type _Service = {
  __typename?: '_Service'
  sdl?: Maybe<Scalars['String']>
}

export type AssetDataQueryVariables = Exact<{
  code: Scalars['String']
  date: Scalars['String']
  assetType: Scalars['String']
}>

export type AssetDataQuery = { __typename?: 'Query' } & {
  assetData: Array<
    Maybe<
      { __typename: 'AssetData' } & Pick<
        AssetData,
        'id' | 'date' | 'symbol'
      > & {
          data: { __typename?: 'AssetValue' } & Pick<
            AssetValue,
            'close' | 'high' | 'low' | 'open' | 'volume' | 'assetType'
          >
        }
    >
  >
}

export type CommentDataFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  | 'id'
  | 'text'
  | 'createdAt'
  | 'votes'
  | 'userVotePositive'
  | 'numberOfReplies'
  | 'deleted'
> & {
    user?: Maybe<
      { __typename?: 'User' } & Pick<User, 'id' | 'nickname' | 'emailHash'>
    >
  }

export type CommentsDataFragment = { __typename?: 'Post' } & {
  comments: Array<
    { __typename?: 'Comment' } & {
      post?: Maybe<{ __typename?: 'Post' } & PostDataFragment>
    } & CommentDataFragment
  >
}

export type CreateCommentMutationVariables = Exact<{
  data: CommentInput
}>

export type CreateCommentMutation = { __typename?: 'Mutation' } & {
  createComment: { __typename?: 'Comment' } & CommentDataFragment
}

export type UpdateCommentMutationVariables = Exact<{
  data: UpdateCommentInput
}>

export type UpdateCommentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateComment'
>

export type GetCommentQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCommentQuery = { __typename?: 'Query' } & {
  getComment: { __typename?: 'Comment' } & {
    user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'nickname'>>
    post?: Maybe<
      { __typename?: 'Post' } & Pick<
        Post,
        | 'id'
        | 'postType'
        | 'title'
        | 'text'
        | 'date'
        | 'createdAt'
        | 'url'
        | 'postSymbol'
        | 'postAssetType'
        | 'votes'
        | 'userVotePositive'
        | 'numberOfReplies'
      > & {
          user?: Maybe<
            { __typename?: 'User' } & Pick<
              User,
              'id' | 'nickname' | 'emailHash'
            >
          >
        }
    >
    parentComment?: Maybe<{ __typename?: 'Comment' } & CommentDataFragment>
  } & CommentDataFragment
}

export type GetNewestCommentsForUserQueryVariables = Exact<{
  userId: Scalars['ID']
  lastCreatedAt?: Maybe<Scalars['Timestamp']>
}>

export type GetNewestCommentsForUserQuery = { __typename?: 'Query' } & {
  getNewestCommentsForUser: Array<
    { __typename?: 'Comment' } & {
      user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'nickname'>>
      post?: Maybe<
        { __typename?: 'Post' } & Pick<
          Post,
          | 'id'
          | 'postType'
          | 'title'
          | 'text'
          | 'date'
          | 'createdAt'
          | 'url'
          | 'postSymbol'
          | 'postAssetType'
          | 'votes'
          | 'userVotePositive'
          | 'numberOfReplies'
        > & {
            symbol?: Maybe<
              { __typename?: 'Symbol' } & Pick<Symbol, 'symbol' | 'name'>
            >
            user?: Maybe<
              { __typename?: 'User' } & Pick<User, 'id' | 'nickname'>
            >
          }
      >
      parentComment?: Maybe<{ __typename?: 'Comment' } & CommentDataFragment>
    } & CommentDataFragment
  >
}

export type CheckDuplicateUrlQueryVariables = Exact<{
  data: CheckDuplicateUrlInput
}>

export type CheckDuplicateUrlQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkDuplicateUrl'
>

export type ListChildCommentsQueryVariables = Exact<{
  commentId: Scalars['ID']
}>

export type ListChildCommentsQuery = { __typename?: 'Query' } & {
  listChildComments: Array<
    { __typename?: 'Comment' } & {
      post?: Maybe<
        { __typename?: 'Post' } & Pick<
          Post,
          | 'id'
          | 'postType'
          | 'title'
          | 'text'
          | 'date'
          | 'createdAt'
          | 'url'
          | 'postSymbol'
          | 'postAssetType'
          | 'votes'
          | 'userVotePositive'
          | 'numberOfReplies'
        > & {
            user?: Maybe<
              { __typename?: 'User' } & Pick<User, 'id' | 'nickname'>
            >
          }
      >
      parentComment?: Maybe<{ __typename?: 'Comment' } & CommentDataFragment>
    } & CommentDataFragment
  >
}

export type VoteCommentMutationVariables = Exact<{
  commentId: Scalars['String']
  positive: Scalars['Boolean']
}>

export type VoteCommentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'voteComment'
>

export type ChangeCommentVoteMutationVariables = Exact<{
  commentId: Scalars['String']
  positive: Scalars['Boolean']
}>

export type ChangeCommentVoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCommentVote' | 'voteComment'
>

export type DeleteCommentVoteMutationVariables = Exact<{
  commentId: Scalars['String']
}>

export type DeleteCommentVoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCommentVote'
>

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteCommentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteComment'
>

export type ReportCommentMutationVariables = Exact<{
  data: ReportCommentInput
}>

export type ReportCommentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'reportComment'
>

export type CreateContactMutationVariables = Exact<{
  data: ContactInput
}>

export type CreateContactMutation = { __typename?: 'Mutation' } & {
  createContact: { __typename?: 'Contact' } & Pick<
    Contact,
    'firstName' | 'lastName' | 'email' | 'message'
  >
}

export type PostFeedDataFragment = { __typename?: 'Post' } & Pick<
  Post,
  | 'id'
  | 'postType'
  | 'title'
  | 'date'
  | 'createdAt'
  | 'url'
  | 'postSymbol'
  | 'postAssetType'
  | 'votes'
  | 'userVotePositive'
  | 'numberOfReplies'
  | 'image'
> & { postText: Post['text'] } & {
    symbol?: Maybe<{ __typename?: 'Symbol' } & Pick<Symbol, 'symbol' | 'name'>>
    user?: Maybe<
      { __typename?: 'User' } & Pick<
        User,
        'id' | 'nickname' | 'emailHash' | 'followersCount' | 'followingCount'
      >
    >
    storyPosts: Array<
      { __typename?: 'ChildPost' } & Pick<ChildPost, 'noteText'> & {
          childPost?: Maybe<
            { __typename?: 'Post' } & Pick<
              Post,
              | 'id'
              | 'postType'
              | 'title'
              | 'text'
              | 'date'
              | 'createdAt'
              | 'url'
              | 'postSymbol'
              | 'postAssetType'
              | 'votes'
              | 'userVotePositive'
              | 'numberOfReplies'
            > & {
                user?: Maybe<
                  { __typename?: 'User' } & Pick<
                    User,
                    | 'id'
                    | 'nickname'
                    | 'emailHash'
                    | 'followersCount'
                    | 'followingCount'
                  >
                >
              }
          >
        }
    >
  }

export type CommentFeedDataFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  | 'id'
  | 'createdAt'
  | 'votes'
  | 'userVotePositive'
  | 'numberOfReplies'
  | 'deleted'
> & { commentText: Comment['text'] } & {
    user?: Maybe<
      { __typename?: 'User' } & Pick<User, 'id' | 'nickname' | 'emailHash'>
    >
    parentComment?: Maybe<
      { __typename?: 'Comment' } & Pick<
        Comment,
        | 'id'
        | 'createdAt'
        | 'votes'
        | 'userVotePositive'
        | 'numberOfReplies'
        | 'deleted'
      > & { commentText: Comment['text'] } & {
          user?: Maybe<
            { __typename?: 'User' } & Pick<
              User,
              'id' | 'nickname' | 'emailHash'
            >
          >
        }
    >
    post?: Maybe<{ __typename?: 'Post' } & PostFeedDataFragment>
  }

export type GetFeedQueryVariables = Exact<{
  offset?: Maybe<Scalars['Timestamp']>
}>

export type GetFeedQuery = { __typename?: 'Query' } & {
  getFeed: { __typename?: 'Feed' } & Pick<Feed, 'nextOffset'> & {
      items: Array<
        | ({ __typename?: 'Post' } & PostFeedDataFragment)
        | ({ __typename?: 'Comment' } & CommentFeedDataFragment)
      >
    }
}

export type GetUnreadNotificationsCountQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUnreadNotificationsCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getUnreadNotificationsCount'
>

export type GetNotificationsQueryVariables = Exact<{
  unreadOnly?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Timestamp']>
  limit?: Maybe<Scalars['Int']>
  notificationType?: Maybe<Scalars['String']>
  markAsRead?: Maybe<Scalars['Boolean']>
}>

export type GetNotificationsQuery = { __typename?: 'Query' } & {
  getNotifications: { __typename?: 'NotificationsList' } & Pick<
    NotificationsList,
    'nextOffset'
  > & {
      notifications: Array<
        { __typename?: 'Notification' } & Pick<Notification, 'id' | 'read'> & {
            body:
              | ({ __typename?: 'PostReply' } & {
                  comment: { __typename?: 'Comment' } & Pick<
                    Comment,
                    'id' | 'text' | 'createdAt'
                  > & {
                      user?: Maybe<
                        { __typename?: 'User' } & Pick<
                          User,
                          'nickname' | 'emailHash'
                        >
                      >
                      post?: Maybe<
                        { __typename?: 'Post' } & Pick<
                          Post,
                          'id' | 'postSymbol' | 'postAssetType'
                        >
                      >
                    }
                })
              | ({ __typename?: 'CommentReply' } & {
                  comment: { __typename?: 'Comment' } & Pick<
                    Comment,
                    'id' | 'text' | 'createdAt'
                  > & {
                      user?: Maybe<
                        { __typename?: 'User' } & Pick<
                          User,
                          'nickname' | 'emailHash'
                        >
                      >
                      post?: Maybe<
                        { __typename?: 'Post' } & Pick<
                          Post,
                          'id' | 'postSymbol' | 'postAssetType'
                        >
                      >
                    }
                })
              | ({ __typename?: 'PricePredictionResult' } & {
                  vote: { __typename?: 'PricePredictionVote' } & Pick<
                    PricePredictionVote,
                    'id'
                  > & {
                      round: { __typename?: 'PricePredictionRound' } & Pick<
                        PricePredictionRound,
                        'id' | 'endOfRoundTimestamp'
                      > & {
                          symbolObj?: Maybe<
                            { __typename?: 'Symbol' } & Pick<Symbol, 'symbol'>
                          >
                        }
                    }
                })
          }
      >
    }
}

export type MarkNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID']
  read: Scalars['Boolean']
}>

export type MarkNotificationsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'markNotifications'
>

export type PostDataFragment = { __typename?: 'Post' } & Pick<
  Post,
  | 'id'
  | 'postType'
  | 'title'
  | 'text'
  | 'date'
  | 'createdAt'
  | 'url'
  | 'postSymbol'
  | 'postAssetType'
  | 'votes'
  | 'userVotePositive'
  | 'numberOfReplies'
  | 'image'
> & {
    symbol?: Maybe<{ __typename?: 'Symbol' } & Pick<Symbol, 'symbol' | 'name'>>
    user?: Maybe<
      { __typename?: 'User' } & Pick<
        User,
        'id' | 'nickname' | 'emailHash' | 'followersCount' | 'followingCount'
      >
    >
    storyPosts: Array<
      { __typename?: 'ChildPost' } & Pick<ChildPost, 'noteText'> & {
          childPost?: Maybe<
            { __typename?: 'Post' } & Pick<
              Post,
              | 'id'
              | 'postType'
              | 'title'
              | 'text'
              | 'date'
              | 'createdAt'
              | 'url'
              | 'postSymbol'
              | 'postAssetType'
              | 'votes'
              | 'userVotePositive'
              | 'numberOfReplies'
            > & {
                user?: Maybe<
                  { __typename?: 'User' } & Pick<
                    User,
                    | 'id'
                    | 'nickname'
                    | 'emailHash'
                    | 'followersCount'
                    | 'followingCount'
                  >
                >
              }
          >
        }
    >
  }

export type ChildPostDataFragment = { __typename?: 'ChildPost' } & Pick<
  ChildPost,
  'childPostId' | 'noteText'
>

export type PostsQueryVariables = Exact<{
  postSymbol: Scalars['String']
  postAssetType: Scalars['String']
  toDate?: Maybe<Scalars['Date']>
  offset?: Maybe<Scalars['Float']>
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>
  postTypes?: Maybe<Array<PostType> | PostType>
}>

export type PostsQuery = { __typename?: 'Query' } & {
  posts: Array<{ __typename?: 'Post' } & PostDataFragment>
}

export type CreatePostMutationVariables = Exact<{
  data: PostInput
}>

export type CreatePostMutation = { __typename?: 'Mutation' } & {
  createPost: { __typename?: 'Post' } & Pick<
    Post,
    | 'id'
    | 'title'
    | 'postType'
    | 'text'
    | 'date'
    | 'createdAt'
    | 'url'
    | 'postSymbol'
    | 'postAssetType'
    | 'votes'
    | 'userVotePositive'
    | 'numberOfReplies'
  > & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
}

export type DeletePostMutationVariables = Exact<{
  postId: Scalars['ID']
}>

export type DeletePostMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePost'
>

export type UpdatePostMutationVariables = Exact<{
  data: UpdatePostInput
}>

export type UpdatePostMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updatePost'
>

export type GetPostQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetPostQuery = { __typename?: 'Query' } & {
  getPost: { __typename?: 'Post' } & PostDataFragment & CommentsDataFragment
}

export type GetNewestPostsForUserQueryVariables = Exact<{
  userId: Scalars['ID']
  lastCreatedAt?: Maybe<Scalars['Timestamp']>
}>

export type GetNewestPostsForUserQuery = { __typename?: 'Query' } & {
  getNewestPostsForUser: Array<{ __typename?: 'Post' } & PostDataFragment>
}

export type GetRelatedPostsQueryVariables = Exact<{
  postId: Scalars['ID']
}>

export type GetRelatedPostsQuery = { __typename?: 'Query' } & {
  getRelatedPosts: Array<{ __typename?: 'Post' } & PostDataFragment>
}

export type VotePostMutationVariables = Exact<{
  postId: Scalars['String']
  positive: Scalars['Boolean']
}>

export type VotePostMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'votePost'
>

export type ChangePostVoteMutationVariables = Exact<{
  postId: Scalars['String']
  positive: Scalars['Boolean']
}>

export type ChangePostVoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePostVote' | 'votePost'
>

export type DeletePostVoteMutationVariables = Exact<{
  postId: Scalars['String']
}>

export type DeletePostVoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePostVote'
>

export type ReportPostMutationVariables = Exact<{
  data: ReportPostInput
}>

export type ReportPostMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'reportPost'
>

export type GetNewestPostsQueryVariables = Exact<{
  lastCreatedAt?: Maybe<Scalars['Timestamp']>
}>

export type GetNewestPostsQuery = { __typename?: 'Query' } & {
  getNewestPosts: Array<{ __typename?: 'Post' } & PostDataFragment>
}

export type GetTrendingPostsQueryVariables = Exact<{ [key: string]: never }>

export type GetTrendingPostsQuery = { __typename?: 'Query' } & {
  getTrendingPosts: Array<{ __typename?: 'Post' } & PostDataFragment>
}

export type GetStoryPostsQueryVariables = Exact<{
  parentStoryId: Scalars['ID']
}>

export type GetStoryPostsQuery = { __typename?: 'Query' } & {
  getStoryPosts: Array<{ __typename?: 'ChildPost' } & ChildPostDataFragment>
}

export type SetStoryPostsMutationVariables = Exact<{
  parentStoryId: Scalars['ID']
  childPosts: Array<ChildPostInput> | ChildPostInput
}>

export type SetStoryPostsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setStoryPosts'
>

export type PricePredictionRoundDetailsFragment = {
  __typename?: 'PricePredictionRound'
} & Pick<
  PricePredictionRound,
  | 'id'
  | 'startDate'
  | 'symbol'
  | 'startClose'
  | 'endClose'
  | 'isVotingOpen'
  | 'endOfVotingTimestamp'
  | 'endOfRoundTimestamp'
> & {
    symbolObj?: Maybe<
      { __typename?: 'Symbol' } & Pick<Symbol, 'symbol' | 'name' | 'type'>
    >
    vote?: Maybe<
      { __typename?: 'PricePredictionVote' } & Pick<
        PricePredictionVote,
        'higher'
      >
    >
  }

export type PricePredictionVoteDetailsFragment = {
  __typename?: 'PricePredictionVote'
} & Pick<
  PricePredictionVote,
  'id' | 'userId' | 'roundId' | 'higher' | 'win'
> & {
    round: {
      __typename?: 'PricePredictionRound'
    } & PricePredictionRoundDetailsFragment
  }

export type GetPricePredictionRoundQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>
}>

export type GetPricePredictionRoundQuery = { __typename?: 'Query' } & {
  getPricePredictionRound?: Maybe<
    {
      __typename?: 'PricePredictionRound'
    } & PricePredictionRoundDetailsFragment
  >
}

export type GetPricePredictionVoteQueryVariables = Exact<{
  roundId: Scalars['ID']
}>

export type GetPricePredictionVoteQuery = { __typename?: 'Query' } & {
  getPricePredictionVote?: Maybe<
    { __typename?: 'PricePredictionVote' } & PricePredictionVoteDetailsFragment
  >
}

export type SetPricePredictionVoteMutationVariables = Exact<{
  roundId: Scalars['ID']
  higher: Scalars['Boolean']
}>

export type SetPricePredictionVoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setPricePredictionVote'
>

export type SymbolFieldsFragment = { __typename?: 'Symbol' } & Pick<
  Symbol,
  'symbol' | 'name' | 'type'
>

export type DateResponseFieldsFragment = { __typename?: 'DateResponse' } & Pick<
  DateResponse,
  'date' | 'confidence'
>

export type GetSymbolsQueryVariables = Exact<{
  data: QuerySymbolInput
}>

export type GetSymbolsQuery = { __typename?: 'Query' } & {
  getSymbols: Array<{ __typename?: 'Symbol' } & SymbolFieldsFragment>
}

export type GetSymbolQueryVariables = Exact<{
  data: QuerySymbolInput
}>

export type GetSymbolQuery = { __typename?: 'Query' } & {
  getSymbol?: Maybe<{ __typename?: 'Symbol' } & SymbolFieldsFragment>
}

export type CheckSymbolsExistQueryVariables = Exact<{
  symbols: Array<Scalars['String']> | Scalars['String']
}>

export type CheckSymbolsExistQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkSymbolsExist'
>

export type GetTrendingSymbolsQueryVariables = Exact<{ [key: string]: never }>

export type GetTrendingSymbolsQuery = { __typename?: 'Query' } & {
  getTrendingSymbols: Array<
    { __typename?: 'TrendingSymbol' } & Pick<TrendingSymbol, 'score'> & {
        symbol: { __typename?: 'Symbol' } & Pick<
          Symbol,
          'symbol' | 'name' | 'type' | 'exchangeShortName'
        >
      }
  >
}

export type GetSymbolDateQueryVariables = Exact<{
  symbol: Scalars['String']
}>

export type GetSymbolDateQuery = { __typename?: 'Query' } & {
  getSymbolDate: { __typename?: 'DateResponse' } & DateResponseFieldsFragment
}

export type GetExchangeDateQueryVariables = Exact<{
  exchangeShortName: Scalars['String']
}>

export type GetExchangeDateQuery = { __typename?: 'Query' } & {
  getExchangeDate: { __typename?: 'DateResponse' } & DateResponseFieldsFragment
}

export type IncrementTrendingSymbolMutationVariables = Exact<{
  symbol: Scalars['String']
}>

export type IncrementTrendingSymbolMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'incrementTrendingSymbol'>

export type UserDataFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'emailHash'
  | 'nickname'
  | 'createdAt'
  | 'twitter'
  | 'facebook'
  | 'instagram'
  | 'tradingView'
  | 'reddit'
  | 'followersCount'
  | 'followingCount'
>

export type GetFollowingQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetFollowingQuery = { __typename?: 'Query' } & {
  getFollowing: Array<{ __typename?: 'User' } & UserDataFragment>
}

export type GetFollowersQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetFollowersQuery = { __typename?: 'Query' } & {
  getFollowers: Array<{ __typename?: 'User' } & UserDataFragment>
}

export type FollowUserMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type FollowUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'followUser'
>

export type UnfollowUserMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type UnfollowUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'unfollowUser'
>

export type SymbolDataFragment = { __typename?: 'Symbol' } & Pick<
  Symbol,
  'symbol' | 'name' | 'type' | 'exchangeShortName'
>

export type GetUserWatchedSymbolsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUserWatchedSymbolsQuery = { __typename?: 'Query' } & {
  getUserWatchedSymbols: Array<{ __typename?: 'Symbol' } & SymbolDataFragment>
}

export type GetMostWatchedSymbolsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetMostWatchedSymbolsQuery = { __typename?: 'Query' } & {
  getMostWatchedSymbols: Array<{ __typename?: 'Symbol' } & SymbolDataFragment>
}

export type AddUserWatchedSymbolMutationVariables = Exact<{
  symbol: Scalars['String']
}>

export type AddUserWatchedSymbolMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addUserWatchedSymbol'
>

export type RemoveUserWatchedSymbolMutationVariables = Exact<{
  symbol: Scalars['String']
}>

export type RemoveUserWatchedSymbolMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'removeUserWatchedSymbol'>

export type CurrentUserDataFragment = { __typename?: 'PrivateUser' } & Pick<
  PrivateUser,
  | 'id'
  | 'email'
  | 'timeZone'
  | 'nickname'
  | 'reddit'
  | 'twitter'
  | 'facebook'
  | 'instagram'
  | 'tradingView'
  | 'lastViewedSymbol'
  | 'social'
  | 'createdAt'
  | 'replyNotificationEmail'
>

export type CheckDuplicateUserQueryVariables = Exact<{
  data: CheckDuplicateUserInput
}>

export type CheckDuplicateUserQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkDuplicateUser'
>

export type CheckDuplicateEmailQueryVariables = Exact<{
  data: Scalars['String']
}>

export type CheckDuplicateEmailQuery = { __typename?: 'Query' } & Pick<
  Query,
  'checkDuplicateEmail'
>

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = { __typename?: 'Query' } & {
  getCurrentUser: { __typename?: 'PrivateUser' } & CurrentUserDataFragment
}

export type GetUserByNicknameQueryVariables = Exact<{
  nickname: Scalars['String']
}>

export type GetUserByNicknameQuery = { __typename?: 'Query' } & {
  getUserByNickname: { __typename?: 'User' } & Pick<
    User,
    | 'id'
    | 'picture'
    | 'nickname'
    | 'createdAt'
    | 'reddit'
    | 'twitter'
    | 'facebook'
    | 'instagram'
    | 'tradingView'
    | 'emailHash'
    | 'followersCount'
    | 'followingCount'
    | 'blocked'
  >
}

export type UpdateUserProfileMutationVariables = Exact<{
  data: UpdateUserProfile
}>

export type UpdateUserProfileMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateUserProfile'
>

export type UpdateUserAccountMutationVariables = Exact<{
  data: UpdateUserAccount
}>

export type UpdateUserAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateUserAccount'
>

export type UpdateLastViewedSymbolMutationVariables = Exact<{
  data: UpdateLastViewedSymbol
}>

export type UpdateLastViewedSymbolMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateLastViewedSymbol'
>

export type DeleteCurrentUserMutationVariables = Exact<{ [key: string]: never }>

export type DeleteCurrentUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCurrentUser'
>

export type UpdateNotificationSetttingsMutationVariables = Exact<{
  data: UpdateNotificationsSettings
}>

export type UpdateNotificationSetttingsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateNotificationSetttings'>

export type UnsubscribeMutationVariables = Exact<{
  token: Scalars['String']
}>

export type UnsubscribeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'unsubscribe'
>

export type BlockUserMutationVariables = Exact<{
  blockedUserId: Scalars['ID']
}>

export type BlockUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'blockUser'
>

export type UnblockUserMutationVariables = Exact<{
  blockedUserId: Scalars['ID']
}>

export type UnblockUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'unblockUser'
>

export const PostDataFragmentDoc = gql`
  fragment PostData on Post {
    id
    postType
    title
    text
    date
    createdAt
    url
    postSymbol
    postAssetType
    votes
    userVotePositive
    numberOfReplies
    image
    symbol {
      symbol
      name
    }
    user {
      id
      nickname
      emailHash
      followersCount
      followingCount
    }
    storyPosts {
      noteText
      childPost {
        id
        postType
        title
        text
        date
        createdAt
        url
        postSymbol
        postAssetType
        votes
        userVotePositive
        numberOfReplies
        user {
          id
          nickname
          emailHash
          followersCount
          followingCount
        }
      }
    }
  }
`
export const CommentDataFragmentDoc = gql`
  fragment CommentData on Comment {
    id
    text
    createdAt
    votes
    userVotePositive
    numberOfReplies
    createdAt
    deleted
    user {
      id
      nickname
      emailHash
    }
  }
`
export const CommentsDataFragmentDoc = gql`
  fragment CommentsData on Post {
    comments {
      post {
        ...PostData
      }
      ...CommentData
    }
  }
  ${PostDataFragmentDoc}
  ${CommentDataFragmentDoc}
`
export const PostFeedDataFragmentDoc = gql`
  fragment PostFeedData on Post {
    id
    postType
    title
    postText: text
    date
    createdAt
    url
    postSymbol
    postAssetType
    votes
    userVotePositive
    numberOfReplies
    image
    symbol {
      symbol
      name
    }
    user {
      id
      nickname
      emailHash
      followersCount
      followingCount
    }
    storyPosts {
      noteText
      childPost {
        id
        postType
        title
        text
        date
        createdAt
        url
        postSymbol
        postAssetType
        votes
        userVotePositive
        numberOfReplies
        user {
          id
          nickname
          emailHash
          followersCount
          followingCount
        }
      }
    }
  }
`
export const CommentFeedDataFragmentDoc = gql`
  fragment CommentFeedData on Comment {
    id
    commentText: text
    createdAt
    votes
    userVotePositive
    numberOfReplies
    createdAt
    deleted
    user {
      id
      nickname
      emailHash
    }
    parentComment {
      id
      commentText: text
      createdAt
      votes
      userVotePositive
      numberOfReplies
      createdAt
      deleted
      user {
        id
        nickname
        emailHash
      }
    }
    post {
      ...PostFeedData
    }
  }
  ${PostFeedDataFragmentDoc}
`
export const ChildPostDataFragmentDoc = gql`
  fragment ChildPostData on ChildPost {
    childPostId
    noteText
  }
`
export const PricePredictionRoundDetailsFragmentDoc = gql`
  fragment PricePredictionRoundDetails on PricePredictionRound {
    id
    startDate
    symbol
    symbolObj {
      symbol
      name
      type
    }
    vote {
      higher
    }
    startClose
    endClose
    isVotingOpen
    endOfVotingTimestamp
    endOfRoundTimestamp
  }
`
export const PricePredictionVoteDetailsFragmentDoc = gql`
  fragment PricePredictionVoteDetails on PricePredictionVote {
    id
    userId
    roundId
    round {
      ...PricePredictionRoundDetails
    }
    higher
    win
  }
  ${PricePredictionRoundDetailsFragmentDoc}
`
export const SymbolFieldsFragmentDoc = gql`
  fragment SymbolFields on Symbol {
    symbol
    name
    type
  }
`
export const DateResponseFieldsFragmentDoc = gql`
  fragment DateResponseFields on DateResponse {
    date
    confidence
  }
`
export const UserDataFragmentDoc = gql`
  fragment UserData on User {
    id
    emailHash
    nickname
    createdAt
    twitter
    facebook
    instagram
    tradingView
    reddit
    followersCount
    followingCount
  }
`
export const SymbolDataFragmentDoc = gql`
  fragment SymbolData on Symbol {
    symbol
    name
    type
    exchangeShortName
  }
`
export const CurrentUserDataFragmentDoc = gql`
  fragment CurrentUserData on PrivateUser {
    id
    email
    timeZone
    nickname
    reddit
    twitter
    facebook
    instagram
    tradingView
    lastViewedSymbol
    social
    createdAt
    replyNotificationEmail
  }
`
export const AssetDataDocument = gql`
  query AssetData($code: String!, $date: String!, $assetType: String!) {
    assetData(code: $code, date: $date, assetType: $assetType)
      @rest(
        type: "[AssetData]"
        path: "{args.assetType}/{args.code}/{args.date}"
      ) {
      id
      date
      symbol
      data {
        close
        high
        low
        open
        volume
        assetType
      }
      __typename
    }
  }
`

/**
 * __useAssetDataQuery__
 *
 * To run a query within a React component, call `useAssetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetDataQuery({
 *   variables: {
 *      code: // value for 'code'
 *      date: // value for 'date'
 *      assetType: // value for 'assetType'
 *   },
 * });
 */
export function useAssetDataQuery(
  baseOptions: Apollo.QueryHookOptions<AssetDataQuery, AssetDataQueryVariables>
) {
  return Apollo.useQuery<AssetDataQuery, AssetDataQueryVariables>(
    AssetDataDocument,
    baseOptions
  )
}
export function useAssetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetDataQuery,
    AssetDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<AssetDataQuery, AssetDataQueryVariables>(
    AssetDataDocument,
    baseOptions
  )
}
export type AssetDataQueryHookResult = ReturnType<typeof useAssetDataQuery>
export type AssetDataLazyQueryHookResult = ReturnType<
  typeof useAssetDataLazyQuery
>
export type AssetDataQueryResult = Apollo.QueryResult<
  AssetDataQuery,
  AssetDataQueryVariables
>
export const CreateCommentDocument = gql`
  mutation CreateComment($data: CommentInput!) {
    createComment(data: $data) {
      ...CommentData
    }
  }
  ${CommentDataFragmentDoc}
`
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, baseOptions)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationResult =
  Apollo.MutationResult<CreateCommentMutation>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>
export const UpdateCommentDocument = gql`
  mutation UpdateComment($data: UpdateCommentInput!) {
    updateComment(data: $data)
  }
`
export type UpdateCommentMutationFn = Apollo.MutationFunction<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >(UpdateCommentDocument, baseOptions)
}
export type UpdateCommentMutationHookResult = ReturnType<
  typeof useUpdateCommentMutation
>
export type UpdateCommentMutationResult =
  Apollo.MutationResult<UpdateCommentMutation>
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>
export const GetCommentDocument = gql`
  query GetComment($id: ID!) {
    getComment(id: $id) {
      user {
        id
        nickname
      }
      post {
        id
        postType
        title
        text
        date
        createdAt
        url
        postSymbol
        postAssetType
        votes
        userVotePositive
        numberOfReplies
        user {
          id
          nickname
          emailHash
        }
      }
      parentComment {
        ...CommentData
      }
      ...CommentData
    }
  }
  ${CommentDataFragmentDoc}
`

/**
 * __useGetCommentQuery__
 *
 * To run a query within a React component, call `useGetCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentQuery,
    GetCommentQueryVariables
  >
) {
  return Apollo.useQuery<GetCommentQuery, GetCommentQueryVariables>(
    GetCommentDocument,
    baseOptions
  )
}
export function useGetCommentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentQuery,
    GetCommentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCommentQuery, GetCommentQueryVariables>(
    GetCommentDocument,
    baseOptions
  )
}
export type GetCommentQueryHookResult = ReturnType<typeof useGetCommentQuery>
export type GetCommentLazyQueryHookResult = ReturnType<
  typeof useGetCommentLazyQuery
>
export type GetCommentQueryResult = Apollo.QueryResult<
  GetCommentQuery,
  GetCommentQueryVariables
>
export const GetNewestCommentsForUserDocument = gql`
  query GetNewestCommentsForUser($userId: ID!, $lastCreatedAt: Timestamp) {
    getNewestCommentsForUser(userId: $userId, lastCreatedAt: $lastCreatedAt) {
      user {
        id
        nickname
      }
      post {
        id
        postType
        title
        text
        date
        createdAt
        url
        postSymbol
        postAssetType
        votes
        userVotePositive
        numberOfReplies
        symbol {
          symbol
          name
        }
        user {
          id
          nickname
        }
      }
      parentComment {
        ...CommentData
      }
      ...CommentData
    }
  }
  ${CommentDataFragmentDoc}
`

/**
 * __useGetNewestCommentsForUserQuery__
 *
 * To run a query within a React component, call `useGetNewestCommentsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewestCommentsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewestCommentsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      lastCreatedAt: // value for 'lastCreatedAt'
 *   },
 * });
 */
export function useGetNewestCommentsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewestCommentsForUserQuery,
    GetNewestCommentsForUserQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNewestCommentsForUserQuery,
    GetNewestCommentsForUserQueryVariables
  >(GetNewestCommentsForUserDocument, baseOptions)
}
export function useGetNewestCommentsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewestCommentsForUserQuery,
    GetNewestCommentsForUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNewestCommentsForUserQuery,
    GetNewestCommentsForUserQueryVariables
  >(GetNewestCommentsForUserDocument, baseOptions)
}
export type GetNewestCommentsForUserQueryHookResult = ReturnType<
  typeof useGetNewestCommentsForUserQuery
>
export type GetNewestCommentsForUserLazyQueryHookResult = ReturnType<
  typeof useGetNewestCommentsForUserLazyQuery
>
export type GetNewestCommentsForUserQueryResult = Apollo.QueryResult<
  GetNewestCommentsForUserQuery,
  GetNewestCommentsForUserQueryVariables
>
export const CheckDuplicateUrlDocument = gql`
  query CheckDuplicateUrl($data: CheckDuplicateUrlInput!) {
    checkDuplicateUrl(data: $data)
  }
`

/**
 * __useCheckDuplicateUrlQuery__
 *
 * To run a query within a React component, call `useCheckDuplicateUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicateUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicateUrlQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckDuplicateUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckDuplicateUrlQuery,
    CheckDuplicateUrlQueryVariables
  >
) {
  return Apollo.useQuery<
    CheckDuplicateUrlQuery,
    CheckDuplicateUrlQueryVariables
  >(CheckDuplicateUrlDocument, baseOptions)
}
export function useCheckDuplicateUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckDuplicateUrlQuery,
    CheckDuplicateUrlQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CheckDuplicateUrlQuery,
    CheckDuplicateUrlQueryVariables
  >(CheckDuplicateUrlDocument, baseOptions)
}
export type CheckDuplicateUrlQueryHookResult = ReturnType<
  typeof useCheckDuplicateUrlQuery
>
export type CheckDuplicateUrlLazyQueryHookResult = ReturnType<
  typeof useCheckDuplicateUrlLazyQuery
>
export type CheckDuplicateUrlQueryResult = Apollo.QueryResult<
  CheckDuplicateUrlQuery,
  CheckDuplicateUrlQueryVariables
>
export const ListChildCommentsDocument = gql`
  query ListChildComments($commentId: ID!) {
    listChildComments(commentId: $commentId) {
      post {
        id
        postType
        title
        text
        date
        createdAt
        url
        postSymbol
        postAssetType
        votes
        userVotePositive
        numberOfReplies
        user {
          id
          nickname
        }
      }
      parentComment {
        ...CommentData
      }
      ...CommentData
    }
  }
  ${CommentDataFragmentDoc}
`

/**
 * __useListChildCommentsQuery__
 *
 * To run a query within a React component, call `useListChildCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChildCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChildCommentsQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useListChildCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListChildCommentsQuery,
    ListChildCommentsQueryVariables
  >
) {
  return Apollo.useQuery<
    ListChildCommentsQuery,
    ListChildCommentsQueryVariables
  >(ListChildCommentsDocument, baseOptions)
}
export function useListChildCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChildCommentsQuery,
    ListChildCommentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListChildCommentsQuery,
    ListChildCommentsQueryVariables
  >(ListChildCommentsDocument, baseOptions)
}
export type ListChildCommentsQueryHookResult = ReturnType<
  typeof useListChildCommentsQuery
>
export type ListChildCommentsLazyQueryHookResult = ReturnType<
  typeof useListChildCommentsLazyQuery
>
export type ListChildCommentsQueryResult = Apollo.QueryResult<
  ListChildCommentsQuery,
  ListChildCommentsQueryVariables
>
export const VoteCommentDocument = gql`
  mutation VoteComment($commentId: String!, $positive: Boolean!) {
    voteComment(positive: $positive, commentId: $commentId)
  }
`
export type VoteCommentMutationFn = Apollo.MutationFunction<
  VoteCommentMutation,
  VoteCommentMutationVariables
>

/**
 * __useVoteCommentMutation__
 *
 * To run a mutation, you first call `useVoteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteCommentMutation, { data, loading, error }] = useVoteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      positive: // value for 'positive'
 *   },
 * });
 */
export function useVoteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoteCommentMutation,
    VoteCommentMutationVariables
  >
) {
  return Apollo.useMutation<VoteCommentMutation, VoteCommentMutationVariables>(
    VoteCommentDocument,
    baseOptions
  )
}
export type VoteCommentMutationHookResult = ReturnType<
  typeof useVoteCommentMutation
>
export type VoteCommentMutationResult =
  Apollo.MutationResult<VoteCommentMutation>
export type VoteCommentMutationOptions = Apollo.BaseMutationOptions<
  VoteCommentMutation,
  VoteCommentMutationVariables
>
export const ChangeCommentVoteDocument = gql`
  mutation ChangeCommentVote($commentId: String!, $positive: Boolean!) {
    deleteCommentVote(commentId: $commentId)
    voteComment(positive: $positive, commentId: $commentId)
  }
`
export type ChangeCommentVoteMutationFn = Apollo.MutationFunction<
  ChangeCommentVoteMutation,
  ChangeCommentVoteMutationVariables
>

/**
 * __useChangeCommentVoteMutation__
 *
 * To run a mutation, you first call `useChangeCommentVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCommentVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCommentVoteMutation, { data, loading, error }] = useChangeCommentVoteMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      positive: // value for 'positive'
 *   },
 * });
 */
export function useChangeCommentVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeCommentVoteMutation,
    ChangeCommentVoteMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangeCommentVoteMutation,
    ChangeCommentVoteMutationVariables
  >(ChangeCommentVoteDocument, baseOptions)
}
export type ChangeCommentVoteMutationHookResult = ReturnType<
  typeof useChangeCommentVoteMutation
>
export type ChangeCommentVoteMutationResult =
  Apollo.MutationResult<ChangeCommentVoteMutation>
export type ChangeCommentVoteMutationOptions = Apollo.BaseMutationOptions<
  ChangeCommentVoteMutation,
  ChangeCommentVoteMutationVariables
>
export const DeleteCommentVoteDocument = gql`
  mutation DeleteCommentVote($commentId: String!) {
    deleteCommentVote(commentId: $commentId)
  }
`
export type DeleteCommentVoteMutationFn = Apollo.MutationFunction<
  DeleteCommentVoteMutation,
  DeleteCommentVoteMutationVariables
>

/**
 * __useDeleteCommentVoteMutation__
 *
 * To run a mutation, you first call `useDeleteCommentVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentVoteMutation, { data, loading, error }] = useDeleteCommentVoteMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentVoteMutation,
    DeleteCommentVoteMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteCommentVoteMutation,
    DeleteCommentVoteMutationVariables
  >(DeleteCommentVoteDocument, baseOptions)
}
export type DeleteCommentVoteMutationHookResult = ReturnType<
  typeof useDeleteCommentVoteMutation
>
export type DeleteCommentVoteMutationResult =
  Apollo.MutationResult<DeleteCommentVoteMutation>
export type DeleteCommentVoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentVoteMutation,
  DeleteCommentVoteMutationVariables
>
export const DeleteCommentDocument = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id)
  }
`
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, baseOptions)
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>
export type DeleteCommentMutationResult =
  Apollo.MutationResult<DeleteCommentMutation>
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>
export const ReportCommentDocument = gql`
  mutation ReportComment($data: ReportCommentInput!) {
    reportComment(data: $data)
  }
`
export type ReportCommentMutationFn = Apollo.MutationFunction<
  ReportCommentMutation,
  ReportCommentMutationVariables
>

/**
 * __useReportCommentMutation__
 *
 * To run a mutation, you first call `useReportCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCommentMutation, { data, loading, error }] = useReportCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportCommentMutation,
    ReportCommentMutationVariables
  >
) {
  return Apollo.useMutation<
    ReportCommentMutation,
    ReportCommentMutationVariables
  >(ReportCommentDocument, baseOptions)
}
export type ReportCommentMutationHookResult = ReturnType<
  typeof useReportCommentMutation
>
export type ReportCommentMutationResult =
  Apollo.MutationResult<ReportCommentMutation>
export type ReportCommentMutationOptions = Apollo.BaseMutationOptions<
  ReportCommentMutation,
  ReportCommentMutationVariables
>
export const CreateContactDocument = gql`
  mutation CreateContact($data: ContactInput!) {
    createContact(data: $data) {
      firstName
      lastName
      email
      message
    }
  }
`
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, baseOptions)
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>
export const GetFeedDocument = gql`
  query GetFeed($offset: Timestamp) {
    getFeed(offset: $offset) {
      items {
        ... on Post {
          ...PostFeedData
        }
        ... on Comment {
          ...CommentFeedData
        }
      }
      nextOffset
    }
  }
  ${PostFeedDataFragmentDoc}
  ${CommentFeedDataFragmentDoc}
`

/**
 * __useGetFeedQuery__
 *
 * To run a query within a React component, call `useGetFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFeedQuery, GetFeedQueryVariables>
) {
  return Apollo.useQuery<GetFeedQuery, GetFeedQueryVariables>(
    GetFeedDocument,
    baseOptions
  )
}
export function useGetFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeedQuery, GetFeedQueryVariables>
) {
  return Apollo.useLazyQuery<GetFeedQuery, GetFeedQueryVariables>(
    GetFeedDocument,
    baseOptions
  )
}
export type GetFeedQueryHookResult = ReturnType<typeof useGetFeedQuery>
export type GetFeedLazyQueryHookResult = ReturnType<typeof useGetFeedLazyQuery>
export type GetFeedQueryResult = Apollo.QueryResult<
  GetFeedQuery,
  GetFeedQueryVariables
>
export const GetUnreadNotificationsCountDocument = gql`
  query GetUnreadNotificationsCount {
    getUnreadNotificationsCount
  }
`

/**
 * __useGetUnreadNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >(GetUnreadNotificationsCountDocument, baseOptions)
}
export function useGetUnreadNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >(GetUnreadNotificationsCountDocument, baseOptions)
}
export type GetUnreadNotificationsCountQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationsCountQuery
>
export type GetUnreadNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationsCountLazyQuery
>
export type GetUnreadNotificationsCountQueryResult = Apollo.QueryResult<
  GetUnreadNotificationsCountQuery,
  GetUnreadNotificationsCountQueryVariables
>
export const GetNotificationsDocument = gql`
  query GetNotifications(
    $unreadOnly: Boolean
    $offset: Timestamp
    $limit: Int
    $notificationType: String
    $markAsRead: Boolean
  ) {
    getNotifications(
      unreadOnly: $unreadOnly
      offset: $offset
      limit: $limit
      notificationType: $notificationType
      markAsRead: $markAsRead
    ) {
      notifications {
        id
        read
        body {
          ... on PostReply {
            comment {
              id
              text
              createdAt
              user {
                nickname
                emailHash
              }
              post {
                id
                postSymbol
                postAssetType
              }
            }
          }
          ... on CommentReply {
            comment {
              id
              text
              createdAt
              user {
                nickname
                emailHash
              }
              post {
                id
                postSymbol
                postAssetType
              }
            }
          }
          ... on PricePredictionResult {
            vote {
              id
              round {
                id
                symbolObj {
                  symbol
                }
                endOfRoundTimestamp
              }
            }
          }
        }
      }
      nextOffset
    }
  }
`

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      unreadOnly: // value for 'unreadOnly'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      notificationType: // value for 'notificationType'
 *      markAsRead: // value for 'markAsRead'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    baseOptions
  )
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument, baseOptions)
}
export type GetNotificationsQueryHookResult = ReturnType<
  typeof useGetNotificationsQuery
>
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const MarkNotificationsDocument = gql`
  mutation MarkNotifications($ids: [ID!]!, $read: Boolean!) {
    markNotifications(ids: $ids, read: $read)
  }
`
export type MarkNotificationsMutationFn = Apollo.MutationFunction<
  MarkNotificationsMutation,
  MarkNotificationsMutationVariables
>

/**
 * __useMarkNotificationsMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsMutation, { data, loading, error }] = useMarkNotificationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useMarkNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsMutation,
    MarkNotificationsMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkNotificationsMutation,
    MarkNotificationsMutationVariables
  >(MarkNotificationsDocument, baseOptions)
}
export type MarkNotificationsMutationHookResult = ReturnType<
  typeof useMarkNotificationsMutation
>
export type MarkNotificationsMutationResult =
  Apollo.MutationResult<MarkNotificationsMutation>
export type MarkNotificationsMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsMutation,
  MarkNotificationsMutationVariables
>
export const PostsDocument = gql`
  query Posts(
    $postSymbol: String!
    $postAssetType: String!
    $toDate: Date
    $offset: Float
    $ids: [ID!]
    $postTypes: [PostType!]
  ) {
    posts(
      postSymbol: $postSymbol
      postAssetType: $postAssetType
      toDate: $toDate
      offset: $offset
      ids: $ids
      postTypes: $postTypes
    ) {
      ...PostData
    }
  }
  ${PostDataFragmentDoc}
`

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      postSymbol: // value for 'postSymbol'
 *      postAssetType: // value for 'postAssetType'
 *      toDate: // value for 'toDate'
 *      offset: // value for 'offset'
 *      ids: // value for 'ids'
 *      postTypes: // value for 'postTypes'
 *   },
 * });
 */
export function usePostsQuery(
  baseOptions: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>
) {
  return Apollo.useQuery<PostsQuery, PostsQueryVariables>(
    PostsDocument,
    baseOptions
  )
}
export function usePostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>
) {
  return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(
    PostsDocument,
    baseOptions
  )
}
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>
export type PostsQueryResult = Apollo.QueryResult<
  PostsQuery,
  PostsQueryVariables
>
export const CreatePostDocument = gql`
  mutation CreatePost($data: PostInput!) {
    createPost(data: $data) {
      id
      title
      postType
      text
      date
      createdAt
      url
      postSymbol
      postAssetType
      votes
      userVotePositive
      numberOfReplies
      user {
        id
      }
    }
  }
`
export type CreatePostMutationFn = Apollo.MutationFunction<
  CreatePostMutation,
  CreatePostMutationVariables
>

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostMutation,
    CreatePostMutationVariables
  >
) {
  return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(
    CreatePostDocument,
    baseOptions
  )
}
export type CreatePostMutationHookResult = ReturnType<
  typeof useCreatePostMutation
>
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<
  CreatePostMutation,
  CreatePostMutationVariables
>
export const DeletePostDocument = gql`
  mutation DeletePost($postId: ID!) {
    deletePost(id: $postId)
  }
`
export type DeletePostMutationFn = Apollo.MutationFunction<
  DeletePostMutation,
  DeletePostMutationVariables
>

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useDeletePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePostMutation,
    DeletePostMutationVariables
  >
) {
  return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(
    DeletePostDocument,
    baseOptions
  )
}
export type DeletePostMutationHookResult = ReturnType<
  typeof useDeletePostMutation
>
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<
  DeletePostMutation,
  DeletePostMutationVariables
>
export const UpdatePostDocument = gql`
  mutation UpdatePost($data: UpdatePostInput!) {
    updatePost(data: $data)
  }
`
export type UpdatePostMutationFn = Apollo.MutationFunction<
  UpdatePostMutation,
  UpdatePostMutationVariables
>

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostMutation,
    UpdatePostMutationVariables
  >
) {
  return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(
    UpdatePostDocument,
    baseOptions
  )
}
export type UpdatePostMutationHookResult = ReturnType<
  typeof useUpdatePostMutation
>
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostMutation,
  UpdatePostMutationVariables
>
export const GetPostDocument = gql`
  query GetPost($id: ID!) {
    getPost(id: $id) {
      ...PostData
      ...CommentsData
    }
  }
  ${PostDataFragmentDoc}
  ${CommentsDataFragmentDoc}
`

/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostQuery(
  baseOptions: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>
) {
  return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(
    GetPostDocument,
    baseOptions
  )
}
export function useGetPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>
) {
  return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(
    GetPostDocument,
    baseOptions
  )
}
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>
export type GetPostQueryResult = Apollo.QueryResult<
  GetPostQuery,
  GetPostQueryVariables
>
export const GetNewestPostsForUserDocument = gql`
  query GetNewestPostsForUser($userId: ID!, $lastCreatedAt: Timestamp) {
    getNewestPostsForUser(userId: $userId, lastCreatedAt: $lastCreatedAt) {
      ...PostData
    }
  }
  ${PostDataFragmentDoc}
`

/**
 * __useGetNewestPostsForUserQuery__
 *
 * To run a query within a React component, call `useGetNewestPostsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewestPostsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewestPostsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      lastCreatedAt: // value for 'lastCreatedAt'
 *   },
 * });
 */
export function useGetNewestPostsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewestPostsForUserQuery,
    GetNewestPostsForUserQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNewestPostsForUserQuery,
    GetNewestPostsForUserQueryVariables
  >(GetNewestPostsForUserDocument, baseOptions)
}
export function useGetNewestPostsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewestPostsForUserQuery,
    GetNewestPostsForUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNewestPostsForUserQuery,
    GetNewestPostsForUserQueryVariables
  >(GetNewestPostsForUserDocument, baseOptions)
}
export type GetNewestPostsForUserQueryHookResult = ReturnType<
  typeof useGetNewestPostsForUserQuery
>
export type GetNewestPostsForUserLazyQueryHookResult = ReturnType<
  typeof useGetNewestPostsForUserLazyQuery
>
export type GetNewestPostsForUserQueryResult = Apollo.QueryResult<
  GetNewestPostsForUserQuery,
  GetNewestPostsForUserQueryVariables
>
export const GetRelatedPostsDocument = gql`
  query GetRelatedPosts($postId: ID!) {
    getRelatedPosts(postId: $postId) {
      ...PostData
    }
  }
  ${PostDataFragmentDoc}
`

/**
 * __useGetRelatedPostsQuery__
 *
 * To run a query within a React component, call `useGetRelatedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedPostsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetRelatedPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRelatedPostsQuery,
    GetRelatedPostsQueryVariables
  >
) {
  return Apollo.useQuery<GetRelatedPostsQuery, GetRelatedPostsQueryVariables>(
    GetRelatedPostsDocument,
    baseOptions
  )
}
export function useGetRelatedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelatedPostsQuery,
    GetRelatedPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRelatedPostsQuery,
    GetRelatedPostsQueryVariables
  >(GetRelatedPostsDocument, baseOptions)
}
export type GetRelatedPostsQueryHookResult = ReturnType<
  typeof useGetRelatedPostsQuery
>
export type GetRelatedPostsLazyQueryHookResult = ReturnType<
  typeof useGetRelatedPostsLazyQuery
>
export type GetRelatedPostsQueryResult = Apollo.QueryResult<
  GetRelatedPostsQuery,
  GetRelatedPostsQueryVariables
>
export const VotePostDocument = gql`
  mutation VotePost($postId: String!, $positive: Boolean!) {
    votePost(positive: $positive, postId: $postId)
  }
`
export type VotePostMutationFn = Apollo.MutationFunction<
  VotePostMutation,
  VotePostMutationVariables
>

/**
 * __useVotePostMutation__
 *
 * To run a mutation, you first call `useVotePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVotePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [votePostMutation, { data, loading, error }] = useVotePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      positive: // value for 'positive'
 *   },
 * });
 */
export function useVotePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VotePostMutation,
    VotePostMutationVariables
  >
) {
  return Apollo.useMutation<VotePostMutation, VotePostMutationVariables>(
    VotePostDocument,
    baseOptions
  )
}
export type VotePostMutationHookResult = ReturnType<typeof useVotePostMutation>
export type VotePostMutationResult = Apollo.MutationResult<VotePostMutation>
export type VotePostMutationOptions = Apollo.BaseMutationOptions<
  VotePostMutation,
  VotePostMutationVariables
>
export const ChangePostVoteDocument = gql`
  mutation ChangePostVote($postId: String!, $positive: Boolean!) {
    deletePostVote(postId: $postId)
    votePost(positive: $positive, postId: $postId)
  }
`
export type ChangePostVoteMutationFn = Apollo.MutationFunction<
  ChangePostVoteMutation,
  ChangePostVoteMutationVariables
>

/**
 * __useChangePostVoteMutation__
 *
 * To run a mutation, you first call `useChangePostVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePostVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePostVoteMutation, { data, loading, error }] = useChangePostVoteMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      positive: // value for 'positive'
 *   },
 * });
 */
export function useChangePostVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePostVoteMutation,
    ChangePostVoteMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangePostVoteMutation,
    ChangePostVoteMutationVariables
  >(ChangePostVoteDocument, baseOptions)
}
export type ChangePostVoteMutationHookResult = ReturnType<
  typeof useChangePostVoteMutation
>
export type ChangePostVoteMutationResult =
  Apollo.MutationResult<ChangePostVoteMutation>
export type ChangePostVoteMutationOptions = Apollo.BaseMutationOptions<
  ChangePostVoteMutation,
  ChangePostVoteMutationVariables
>
export const DeletePostVoteDocument = gql`
  mutation DeletePostVote($postId: String!) {
    deletePostVote(postId: $postId)
  }
`
export type DeletePostVoteMutationFn = Apollo.MutationFunction<
  DeletePostVoteMutation,
  DeletePostVoteMutationVariables
>

/**
 * __useDeletePostVoteMutation__
 *
 * To run a mutation, you first call `useDeletePostVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostVoteMutation, { data, loading, error }] = useDeletePostVoteMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useDeletePostVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePostVoteMutation,
    DeletePostVoteMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePostVoteMutation,
    DeletePostVoteMutationVariables
  >(DeletePostVoteDocument, baseOptions)
}
export type DeletePostVoteMutationHookResult = ReturnType<
  typeof useDeletePostVoteMutation
>
export type DeletePostVoteMutationResult =
  Apollo.MutationResult<DeletePostVoteMutation>
export type DeletePostVoteMutationOptions = Apollo.BaseMutationOptions<
  DeletePostVoteMutation,
  DeletePostVoteMutationVariables
>
export const ReportPostDocument = gql`
  mutation ReportPost($data: ReportPostInput!) {
    reportPost(data: $data)
  }
`
export type ReportPostMutationFn = Apollo.MutationFunction<
  ReportPostMutation,
  ReportPostMutationVariables
>

/**
 * __useReportPostMutation__
 *
 * To run a mutation, you first call `useReportPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPostMutation, { data, loading, error }] = useReportPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportPostMutation,
    ReportPostMutationVariables
  >
) {
  return Apollo.useMutation<ReportPostMutation, ReportPostMutationVariables>(
    ReportPostDocument,
    baseOptions
  )
}
export type ReportPostMutationHookResult = ReturnType<
  typeof useReportPostMutation
>
export type ReportPostMutationResult = Apollo.MutationResult<ReportPostMutation>
export type ReportPostMutationOptions = Apollo.BaseMutationOptions<
  ReportPostMutation,
  ReportPostMutationVariables
>
export const GetNewestPostsDocument = gql`
  query GetNewestPosts($lastCreatedAt: Timestamp) {
    getNewestPosts(lastCreatedAt: $lastCreatedAt) {
      ...PostData
    }
  }
  ${PostDataFragmentDoc}
`

/**
 * __useGetNewestPostsQuery__
 *
 * To run a query within a React component, call `useGetNewestPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewestPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewestPostsQuery({
 *   variables: {
 *      lastCreatedAt: // value for 'lastCreatedAt'
 *   },
 * });
 */
export function useGetNewestPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNewestPostsQuery,
    GetNewestPostsQueryVariables
  >
) {
  return Apollo.useQuery<GetNewestPostsQuery, GetNewestPostsQueryVariables>(
    GetNewestPostsDocument,
    baseOptions
  )
}
export function useGetNewestPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewestPostsQuery,
    GetNewestPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetNewestPostsQuery, GetNewestPostsQueryVariables>(
    GetNewestPostsDocument,
    baseOptions
  )
}
export type GetNewestPostsQueryHookResult = ReturnType<
  typeof useGetNewestPostsQuery
>
export type GetNewestPostsLazyQueryHookResult = ReturnType<
  typeof useGetNewestPostsLazyQuery
>
export type GetNewestPostsQueryResult = Apollo.QueryResult<
  GetNewestPostsQuery,
  GetNewestPostsQueryVariables
>
export const GetTrendingPostsDocument = gql`
  query GetTrendingPosts {
    getTrendingPosts {
      ...PostData
    }
  }
  ${PostDataFragmentDoc}
`

/**
 * __useGetTrendingPostsQuery__
 *
 * To run a query within a React component, call `useGetTrendingPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrendingPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTrendingPostsQuery,
    GetTrendingPostsQueryVariables
  >
) {
  return Apollo.useQuery<GetTrendingPostsQuery, GetTrendingPostsQueryVariables>(
    GetTrendingPostsDocument,
    baseOptions
  )
}
export function useGetTrendingPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrendingPostsQuery,
    GetTrendingPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTrendingPostsQuery,
    GetTrendingPostsQueryVariables
  >(GetTrendingPostsDocument, baseOptions)
}
export type GetTrendingPostsQueryHookResult = ReturnType<
  typeof useGetTrendingPostsQuery
>
export type GetTrendingPostsLazyQueryHookResult = ReturnType<
  typeof useGetTrendingPostsLazyQuery
>
export type GetTrendingPostsQueryResult = Apollo.QueryResult<
  GetTrendingPostsQuery,
  GetTrendingPostsQueryVariables
>
export const GetStoryPostsDocument = gql`
  query GetStoryPosts($parentStoryId: ID!) {
    getStoryPosts(parentStoryId: $parentStoryId) {
      ...ChildPostData
    }
  }
  ${ChildPostDataFragmentDoc}
`

/**
 * __useGetStoryPostsQuery__
 *
 * To run a query within a React component, call `useGetStoryPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryPostsQuery({
 *   variables: {
 *      parentStoryId: // value for 'parentStoryId'
 *   },
 * });
 */
export function useGetStoryPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStoryPostsQuery,
    GetStoryPostsQueryVariables
  >
) {
  return Apollo.useQuery<GetStoryPostsQuery, GetStoryPostsQueryVariables>(
    GetStoryPostsDocument,
    baseOptions
  )
}
export function useGetStoryPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStoryPostsQuery,
    GetStoryPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetStoryPostsQuery, GetStoryPostsQueryVariables>(
    GetStoryPostsDocument,
    baseOptions
  )
}
export type GetStoryPostsQueryHookResult = ReturnType<
  typeof useGetStoryPostsQuery
>
export type GetStoryPostsLazyQueryHookResult = ReturnType<
  typeof useGetStoryPostsLazyQuery
>
export type GetStoryPostsQueryResult = Apollo.QueryResult<
  GetStoryPostsQuery,
  GetStoryPostsQueryVariables
>
export const SetStoryPostsDocument = gql`
  mutation SetStoryPosts($parentStoryId: ID!, $childPosts: [ChildPostInput!]!) {
    setStoryPosts(parentStoryId: $parentStoryId, childPosts: $childPosts)
  }
`
export type SetStoryPostsMutationFn = Apollo.MutationFunction<
  SetStoryPostsMutation,
  SetStoryPostsMutationVariables
>

/**
 * __useSetStoryPostsMutation__
 *
 * To run a mutation, you first call `useSetStoryPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStoryPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStoryPostsMutation, { data, loading, error }] = useSetStoryPostsMutation({
 *   variables: {
 *      parentStoryId: // value for 'parentStoryId'
 *      childPosts: // value for 'childPosts'
 *   },
 * });
 */
export function useSetStoryPostsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStoryPostsMutation,
    SetStoryPostsMutationVariables
  >
) {
  return Apollo.useMutation<
    SetStoryPostsMutation,
    SetStoryPostsMutationVariables
  >(SetStoryPostsDocument, baseOptions)
}
export type SetStoryPostsMutationHookResult = ReturnType<
  typeof useSetStoryPostsMutation
>
export type SetStoryPostsMutationResult =
  Apollo.MutationResult<SetStoryPostsMutation>
export type SetStoryPostsMutationOptions = Apollo.BaseMutationOptions<
  SetStoryPostsMutation,
  SetStoryPostsMutationVariables
>
export const GetPricePredictionRoundDocument = gql`
  query GetPricePredictionRound($startDate: Date) {
    getPricePredictionRound(startDate: $startDate) {
      ...PricePredictionRoundDetails
    }
  }
  ${PricePredictionRoundDetailsFragmentDoc}
`

/**
 * __useGetPricePredictionRoundQuery__
 *
 * To run a query within a React component, call `useGetPricePredictionRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricePredictionRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricePredictionRoundQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetPricePredictionRoundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPricePredictionRoundQuery,
    GetPricePredictionRoundQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPricePredictionRoundQuery,
    GetPricePredictionRoundQueryVariables
  >(GetPricePredictionRoundDocument, baseOptions)
}
export function useGetPricePredictionRoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPricePredictionRoundQuery,
    GetPricePredictionRoundQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPricePredictionRoundQuery,
    GetPricePredictionRoundQueryVariables
  >(GetPricePredictionRoundDocument, baseOptions)
}
export type GetPricePredictionRoundQueryHookResult = ReturnType<
  typeof useGetPricePredictionRoundQuery
>
export type GetPricePredictionRoundLazyQueryHookResult = ReturnType<
  typeof useGetPricePredictionRoundLazyQuery
>
export type GetPricePredictionRoundQueryResult = Apollo.QueryResult<
  GetPricePredictionRoundQuery,
  GetPricePredictionRoundQueryVariables
>
export const GetPricePredictionVoteDocument = gql`
  query GetPricePredictionVote($roundId: ID!) {
    getPricePredictionVote(roundId: $roundId) {
      ...PricePredictionVoteDetails
    }
  }
  ${PricePredictionVoteDetailsFragmentDoc}
`

/**
 * __useGetPricePredictionVoteQuery__
 *
 * To run a query within a React component, call `useGetPricePredictionVoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricePredictionVoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricePredictionVoteQuery({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useGetPricePredictionVoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPricePredictionVoteQuery,
    GetPricePredictionVoteQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPricePredictionVoteQuery,
    GetPricePredictionVoteQueryVariables
  >(GetPricePredictionVoteDocument, baseOptions)
}
export function useGetPricePredictionVoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPricePredictionVoteQuery,
    GetPricePredictionVoteQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPricePredictionVoteQuery,
    GetPricePredictionVoteQueryVariables
  >(GetPricePredictionVoteDocument, baseOptions)
}
export type GetPricePredictionVoteQueryHookResult = ReturnType<
  typeof useGetPricePredictionVoteQuery
>
export type GetPricePredictionVoteLazyQueryHookResult = ReturnType<
  typeof useGetPricePredictionVoteLazyQuery
>
export type GetPricePredictionVoteQueryResult = Apollo.QueryResult<
  GetPricePredictionVoteQuery,
  GetPricePredictionVoteQueryVariables
>
export const SetPricePredictionVoteDocument = gql`
  mutation SetPricePredictionVote($roundId: ID!, $higher: Boolean!) {
    setPricePredictionVote(roundId: $roundId, higher: $higher)
  }
`
export type SetPricePredictionVoteMutationFn = Apollo.MutationFunction<
  SetPricePredictionVoteMutation,
  SetPricePredictionVoteMutationVariables
>

/**
 * __useSetPricePredictionVoteMutation__
 *
 * To run a mutation, you first call `useSetPricePredictionVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPricePredictionVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPricePredictionVoteMutation, { data, loading, error }] = useSetPricePredictionVoteMutation({
 *   variables: {
 *      roundId: // value for 'roundId'
 *      higher: // value for 'higher'
 *   },
 * });
 */
export function useSetPricePredictionVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPricePredictionVoteMutation,
    SetPricePredictionVoteMutationVariables
  >
) {
  return Apollo.useMutation<
    SetPricePredictionVoteMutation,
    SetPricePredictionVoteMutationVariables
  >(SetPricePredictionVoteDocument, baseOptions)
}
export type SetPricePredictionVoteMutationHookResult = ReturnType<
  typeof useSetPricePredictionVoteMutation
>
export type SetPricePredictionVoteMutationResult =
  Apollo.MutationResult<SetPricePredictionVoteMutation>
export type SetPricePredictionVoteMutationOptions = Apollo.BaseMutationOptions<
  SetPricePredictionVoteMutation,
  SetPricePredictionVoteMutationVariables
>
export const GetSymbolsDocument = gql`
  query GetSymbols($data: QuerySymbolInput!) {
    getSymbols(data: $data) {
      ...SymbolFields
    }
  }
  ${SymbolFieldsFragmentDoc}
`

/**
 * __useGetSymbolsQuery__
 *
 * To run a query within a React component, call `useGetSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSymbolsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetSymbolsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSymbolsQuery,
    GetSymbolsQueryVariables
  >
) {
  return Apollo.useQuery<GetSymbolsQuery, GetSymbolsQueryVariables>(
    GetSymbolsDocument,
    baseOptions
  )
}
export function useGetSymbolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSymbolsQuery,
    GetSymbolsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetSymbolsQuery, GetSymbolsQueryVariables>(
    GetSymbolsDocument,
    baseOptions
  )
}
export type GetSymbolsQueryHookResult = ReturnType<typeof useGetSymbolsQuery>
export type GetSymbolsLazyQueryHookResult = ReturnType<
  typeof useGetSymbolsLazyQuery
>
export type GetSymbolsQueryResult = Apollo.QueryResult<
  GetSymbolsQuery,
  GetSymbolsQueryVariables
>
export const GetSymbolDocument = gql`
  query GetSymbol($data: QuerySymbolInput!) {
    getSymbol(data: $data) {
      ...SymbolFields
    }
  }
  ${SymbolFieldsFragmentDoc}
`

/**
 * __useGetSymbolQuery__
 *
 * To run a query within a React component, call `useGetSymbolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSymbolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSymbolQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetSymbolQuery(
  baseOptions: Apollo.QueryHookOptions<GetSymbolQuery, GetSymbolQueryVariables>
) {
  return Apollo.useQuery<GetSymbolQuery, GetSymbolQueryVariables>(
    GetSymbolDocument,
    baseOptions
  )
}
export function useGetSymbolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSymbolQuery,
    GetSymbolQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetSymbolQuery, GetSymbolQueryVariables>(
    GetSymbolDocument,
    baseOptions
  )
}
export type GetSymbolQueryHookResult = ReturnType<typeof useGetSymbolQuery>
export type GetSymbolLazyQueryHookResult = ReturnType<
  typeof useGetSymbolLazyQuery
>
export type GetSymbolQueryResult = Apollo.QueryResult<
  GetSymbolQuery,
  GetSymbolQueryVariables
>
export const CheckSymbolsExistDocument = gql`
  query CheckSymbolsExist($symbols: [String!]!) {
    checkSymbolsExist(data: $symbols)
  }
`

/**
 * __useCheckSymbolsExistQuery__
 *
 * To run a query within a React component, call `useCheckSymbolsExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSymbolsExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSymbolsExistQuery({
 *   variables: {
 *      symbols: // value for 'symbols'
 *   },
 * });
 */
export function useCheckSymbolsExistQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckSymbolsExistQuery,
    CheckSymbolsExistQueryVariables
  >
) {
  return Apollo.useQuery<
    CheckSymbolsExistQuery,
    CheckSymbolsExistQueryVariables
  >(CheckSymbolsExistDocument, baseOptions)
}
export function useCheckSymbolsExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckSymbolsExistQuery,
    CheckSymbolsExistQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CheckSymbolsExistQuery,
    CheckSymbolsExistQueryVariables
  >(CheckSymbolsExistDocument, baseOptions)
}
export type CheckSymbolsExistQueryHookResult = ReturnType<
  typeof useCheckSymbolsExistQuery
>
export type CheckSymbolsExistLazyQueryHookResult = ReturnType<
  typeof useCheckSymbolsExistLazyQuery
>
export type CheckSymbolsExistQueryResult = Apollo.QueryResult<
  CheckSymbolsExistQuery,
  CheckSymbolsExistQueryVariables
>
export const GetTrendingSymbolsDocument = gql`
  query GetTrendingSymbols {
    getTrendingSymbols {
      symbol {
        symbol
        name
        type
        exchangeShortName
      }
      score
    }
  }
`

/**
 * __useGetTrendingSymbolsQuery__
 *
 * To run a query within a React component, call `useGetTrendingSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingSymbolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrendingSymbolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTrendingSymbolsQuery,
    GetTrendingSymbolsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTrendingSymbolsQuery,
    GetTrendingSymbolsQueryVariables
  >(GetTrendingSymbolsDocument, baseOptions)
}
export function useGetTrendingSymbolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrendingSymbolsQuery,
    GetTrendingSymbolsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTrendingSymbolsQuery,
    GetTrendingSymbolsQueryVariables
  >(GetTrendingSymbolsDocument, baseOptions)
}
export type GetTrendingSymbolsQueryHookResult = ReturnType<
  typeof useGetTrendingSymbolsQuery
>
export type GetTrendingSymbolsLazyQueryHookResult = ReturnType<
  typeof useGetTrendingSymbolsLazyQuery
>
export type GetTrendingSymbolsQueryResult = Apollo.QueryResult<
  GetTrendingSymbolsQuery,
  GetTrendingSymbolsQueryVariables
>
export const GetSymbolDateDocument = gql`
  query GetSymbolDate($symbol: String!) {
    getSymbolDate(symbol: $symbol) {
      ...DateResponseFields
    }
  }
  ${DateResponseFieldsFragmentDoc}
`

/**
 * __useGetSymbolDateQuery__
 *
 * To run a query within a React component, call `useGetSymbolDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSymbolDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSymbolDateQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetSymbolDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSymbolDateQuery,
    GetSymbolDateQueryVariables
  >
) {
  return Apollo.useQuery<GetSymbolDateQuery, GetSymbolDateQueryVariables>(
    GetSymbolDateDocument,
    baseOptions
  )
}
export function useGetSymbolDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSymbolDateQuery,
    GetSymbolDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetSymbolDateQuery, GetSymbolDateQueryVariables>(
    GetSymbolDateDocument,
    baseOptions
  )
}
export type GetSymbolDateQueryHookResult = ReturnType<
  typeof useGetSymbolDateQuery
>
export type GetSymbolDateLazyQueryHookResult = ReturnType<
  typeof useGetSymbolDateLazyQuery
>
export type GetSymbolDateQueryResult = Apollo.QueryResult<
  GetSymbolDateQuery,
  GetSymbolDateQueryVariables
>
export const GetExchangeDateDocument = gql`
  query GetExchangeDate($exchangeShortName: String!) {
    getExchangeDate(exchangeShortName: $exchangeShortName) {
      ...DateResponseFields
    }
  }
  ${DateResponseFieldsFragmentDoc}
`

/**
 * __useGetExchangeDateQuery__
 *
 * To run a query within a React component, call `useGetExchangeDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeDateQuery({
 *   variables: {
 *      exchangeShortName: // value for 'exchangeShortName'
 *   },
 * });
 */
export function useGetExchangeDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExchangeDateQuery,
    GetExchangeDateQueryVariables
  >
) {
  return Apollo.useQuery<GetExchangeDateQuery, GetExchangeDateQueryVariables>(
    GetExchangeDateDocument,
    baseOptions
  )
}
export function useGetExchangeDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExchangeDateQuery,
    GetExchangeDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetExchangeDateQuery,
    GetExchangeDateQueryVariables
  >(GetExchangeDateDocument, baseOptions)
}
export type GetExchangeDateQueryHookResult = ReturnType<
  typeof useGetExchangeDateQuery
>
export type GetExchangeDateLazyQueryHookResult = ReturnType<
  typeof useGetExchangeDateLazyQuery
>
export type GetExchangeDateQueryResult = Apollo.QueryResult<
  GetExchangeDateQuery,
  GetExchangeDateQueryVariables
>
export const IncrementTrendingSymbolDocument = gql`
  mutation IncrementTrendingSymbol($symbol: String!) {
    incrementTrendingSymbol(symbol: $symbol)
  }
`
export type IncrementTrendingSymbolMutationFn = Apollo.MutationFunction<
  IncrementTrendingSymbolMutation,
  IncrementTrendingSymbolMutationVariables
>

/**
 * __useIncrementTrendingSymbolMutation__
 *
 * To run a mutation, you first call `useIncrementTrendingSymbolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementTrendingSymbolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementTrendingSymbolMutation, { data, loading, error }] = useIncrementTrendingSymbolMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useIncrementTrendingSymbolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncrementTrendingSymbolMutation,
    IncrementTrendingSymbolMutationVariables
  >
) {
  return Apollo.useMutation<
    IncrementTrendingSymbolMutation,
    IncrementTrendingSymbolMutationVariables
  >(IncrementTrendingSymbolDocument, baseOptions)
}
export type IncrementTrendingSymbolMutationHookResult = ReturnType<
  typeof useIncrementTrendingSymbolMutation
>
export type IncrementTrendingSymbolMutationResult =
  Apollo.MutationResult<IncrementTrendingSymbolMutation>
export type IncrementTrendingSymbolMutationOptions = Apollo.BaseMutationOptions<
  IncrementTrendingSymbolMutation,
  IncrementTrendingSymbolMutationVariables
>
export const GetFollowingDocument = gql`
  query GetFollowing($userId: ID!) {
    getFollowing(userId: $userId) {
      ...UserData
    }
  }
  ${UserDataFragmentDoc}
`

/**
 * __useGetFollowingQuery__
 *
 * To run a query within a React component, call `useGetFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFollowingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFollowingQuery,
    GetFollowingQueryVariables
  >
) {
  return Apollo.useQuery<GetFollowingQuery, GetFollowingQueryVariables>(
    GetFollowingDocument,
    baseOptions
  )
}
export function useGetFollowingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowingQuery,
    GetFollowingQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetFollowingQuery, GetFollowingQueryVariables>(
    GetFollowingDocument,
    baseOptions
  )
}
export type GetFollowingQueryHookResult = ReturnType<
  typeof useGetFollowingQuery
>
export type GetFollowingLazyQueryHookResult = ReturnType<
  typeof useGetFollowingLazyQuery
>
export type GetFollowingQueryResult = Apollo.QueryResult<
  GetFollowingQuery,
  GetFollowingQueryVariables
>
export const GetFollowersDocument = gql`
  query GetFollowers($userId: ID!) {
    getFollowers(userId: $userId) {
      ...UserData
    }
  }
  ${UserDataFragmentDoc}
`

/**
 * __useGetFollowersQuery__
 *
 * To run a query within a React component, call `useGetFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFollowersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFollowersQuery,
    GetFollowersQueryVariables
  >
) {
  return Apollo.useQuery<GetFollowersQuery, GetFollowersQueryVariables>(
    GetFollowersDocument,
    baseOptions
  )
}
export function useGetFollowersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowersQuery,
    GetFollowersQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetFollowersQuery, GetFollowersQueryVariables>(
    GetFollowersDocument,
    baseOptions
  )
}
export type GetFollowersQueryHookResult = ReturnType<
  typeof useGetFollowersQuery
>
export type GetFollowersLazyQueryHookResult = ReturnType<
  typeof useGetFollowersLazyQuery
>
export type GetFollowersQueryResult = Apollo.QueryResult<
  GetFollowersQuery,
  GetFollowersQueryVariables
>
export const FollowUserDocument = gql`
  mutation FollowUser($userId: ID!) {
    followUser(userId: $userId)
  }
`
export type FollowUserMutationFn = Apollo.MutationFunction<
  FollowUserMutation,
  FollowUserMutationVariables
>

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFollowUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FollowUserMutation,
    FollowUserMutationVariables
  >
) {
  return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(
    FollowUserDocument,
    baseOptions
  )
}
export type FollowUserMutationHookResult = ReturnType<
  typeof useFollowUserMutation
>
export type FollowUserMutationResult = Apollo.MutationResult<FollowUserMutation>
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<
  FollowUserMutation,
  FollowUserMutationVariables
>
export const UnfollowUserDocument = gql`
  mutation UnfollowUser($userId: ID!) {
    unfollowUser(userId: $userId)
  }
`
export type UnfollowUserMutationFn = Apollo.MutationFunction<
  UnfollowUserMutation,
  UnfollowUserMutationVariables
>

/**
 * __useUnfollowUserMutation__
 *
 * To run a mutation, you first call `useUnfollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowUserMutation, { data, loading, error }] = useUnfollowUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnfollowUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowUserMutation,
    UnfollowUserMutationVariables
  >
) {
  return Apollo.useMutation<
    UnfollowUserMutation,
    UnfollowUserMutationVariables
  >(UnfollowUserDocument, baseOptions)
}
export type UnfollowUserMutationHookResult = ReturnType<
  typeof useUnfollowUserMutation
>
export type UnfollowUserMutationResult =
  Apollo.MutationResult<UnfollowUserMutation>
export type UnfollowUserMutationOptions = Apollo.BaseMutationOptions<
  UnfollowUserMutation,
  UnfollowUserMutationVariables
>
export const GetUserWatchedSymbolsDocument = gql`
  query GetUserWatchedSymbols {
    getUserWatchedSymbols {
      ...SymbolData
    }
  }
  ${SymbolDataFragmentDoc}
`

/**
 * __useGetUserWatchedSymbolsQuery__
 *
 * To run a query within a React component, call `useGetUserWatchedSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWatchedSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWatchedSymbolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserWatchedSymbolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserWatchedSymbolsQuery,
    GetUserWatchedSymbolsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUserWatchedSymbolsQuery,
    GetUserWatchedSymbolsQueryVariables
  >(GetUserWatchedSymbolsDocument, baseOptions)
}
export function useGetUserWatchedSymbolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserWatchedSymbolsQuery,
    GetUserWatchedSymbolsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUserWatchedSymbolsQuery,
    GetUserWatchedSymbolsQueryVariables
  >(GetUserWatchedSymbolsDocument, baseOptions)
}
export type GetUserWatchedSymbolsQueryHookResult = ReturnType<
  typeof useGetUserWatchedSymbolsQuery
>
export type GetUserWatchedSymbolsLazyQueryHookResult = ReturnType<
  typeof useGetUserWatchedSymbolsLazyQuery
>
export type GetUserWatchedSymbolsQueryResult = Apollo.QueryResult<
  GetUserWatchedSymbolsQuery,
  GetUserWatchedSymbolsQueryVariables
>
export const GetMostWatchedSymbolsDocument = gql`
  query GetMostWatchedSymbols {
    getMostWatchedSymbols {
      ...SymbolData
    }
  }
  ${SymbolDataFragmentDoc}
`

/**
 * __useGetMostWatchedSymbolsQuery__
 *
 * To run a query within a React component, call `useGetMostWatchedSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostWatchedSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostWatchedSymbolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMostWatchedSymbolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMostWatchedSymbolsQuery,
    GetMostWatchedSymbolsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMostWatchedSymbolsQuery,
    GetMostWatchedSymbolsQueryVariables
  >(GetMostWatchedSymbolsDocument, baseOptions)
}
export function useGetMostWatchedSymbolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostWatchedSymbolsQuery,
    GetMostWatchedSymbolsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMostWatchedSymbolsQuery,
    GetMostWatchedSymbolsQueryVariables
  >(GetMostWatchedSymbolsDocument, baseOptions)
}
export type GetMostWatchedSymbolsQueryHookResult = ReturnType<
  typeof useGetMostWatchedSymbolsQuery
>
export type GetMostWatchedSymbolsLazyQueryHookResult = ReturnType<
  typeof useGetMostWatchedSymbolsLazyQuery
>
export type GetMostWatchedSymbolsQueryResult = Apollo.QueryResult<
  GetMostWatchedSymbolsQuery,
  GetMostWatchedSymbolsQueryVariables
>
export const AddUserWatchedSymbolDocument = gql`
  mutation AddUserWatchedSymbol($symbol: String!) {
    addUserWatchedSymbol(symbol: $symbol)
  }
`
export type AddUserWatchedSymbolMutationFn = Apollo.MutationFunction<
  AddUserWatchedSymbolMutation,
  AddUserWatchedSymbolMutationVariables
>

/**
 * __useAddUserWatchedSymbolMutation__
 *
 * To run a mutation, you first call `useAddUserWatchedSymbolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserWatchedSymbolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserWatchedSymbolMutation, { data, loading, error }] = useAddUserWatchedSymbolMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useAddUserWatchedSymbolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserWatchedSymbolMutation,
    AddUserWatchedSymbolMutationVariables
  >
) {
  return Apollo.useMutation<
    AddUserWatchedSymbolMutation,
    AddUserWatchedSymbolMutationVariables
  >(AddUserWatchedSymbolDocument, baseOptions)
}
export type AddUserWatchedSymbolMutationHookResult = ReturnType<
  typeof useAddUserWatchedSymbolMutation
>
export type AddUserWatchedSymbolMutationResult =
  Apollo.MutationResult<AddUserWatchedSymbolMutation>
export type AddUserWatchedSymbolMutationOptions = Apollo.BaseMutationOptions<
  AddUserWatchedSymbolMutation,
  AddUserWatchedSymbolMutationVariables
>
export const RemoveUserWatchedSymbolDocument = gql`
  mutation RemoveUserWatchedSymbol($symbol: String!) {
    removeUserWatchedSymbol(symbol: $symbol)
  }
`
export type RemoveUserWatchedSymbolMutationFn = Apollo.MutationFunction<
  RemoveUserWatchedSymbolMutation,
  RemoveUserWatchedSymbolMutationVariables
>

/**
 * __useRemoveUserWatchedSymbolMutation__
 *
 * To run a mutation, you first call `useRemoveUserWatchedSymbolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserWatchedSymbolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserWatchedSymbolMutation, { data, loading, error }] = useRemoveUserWatchedSymbolMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useRemoveUserWatchedSymbolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserWatchedSymbolMutation,
    RemoveUserWatchedSymbolMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveUserWatchedSymbolMutation,
    RemoveUserWatchedSymbolMutationVariables
  >(RemoveUserWatchedSymbolDocument, baseOptions)
}
export type RemoveUserWatchedSymbolMutationHookResult = ReturnType<
  typeof useRemoveUserWatchedSymbolMutation
>
export type RemoveUserWatchedSymbolMutationResult =
  Apollo.MutationResult<RemoveUserWatchedSymbolMutation>
export type RemoveUserWatchedSymbolMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserWatchedSymbolMutation,
  RemoveUserWatchedSymbolMutationVariables
>
export const CheckDuplicateUserDocument = gql`
  query CheckDuplicateUser($data: CheckDuplicateUserInput!) {
    checkDuplicateUser(data: $data)
  }
`

/**
 * __useCheckDuplicateUserQuery__
 *
 * To run a query within a React component, call `useCheckDuplicateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicateUserQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckDuplicateUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckDuplicateUserQuery,
    CheckDuplicateUserQueryVariables
  >
) {
  return Apollo.useQuery<
    CheckDuplicateUserQuery,
    CheckDuplicateUserQueryVariables
  >(CheckDuplicateUserDocument, baseOptions)
}
export function useCheckDuplicateUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckDuplicateUserQuery,
    CheckDuplicateUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CheckDuplicateUserQuery,
    CheckDuplicateUserQueryVariables
  >(CheckDuplicateUserDocument, baseOptions)
}
export type CheckDuplicateUserQueryHookResult = ReturnType<
  typeof useCheckDuplicateUserQuery
>
export type CheckDuplicateUserLazyQueryHookResult = ReturnType<
  typeof useCheckDuplicateUserLazyQuery
>
export type CheckDuplicateUserQueryResult = Apollo.QueryResult<
  CheckDuplicateUserQuery,
  CheckDuplicateUserQueryVariables
>
export const CheckDuplicateEmailDocument = gql`
  query CheckDuplicateEmail($data: String!) {
    checkDuplicateEmail(email: $data)
  }
`

/**
 * __useCheckDuplicateEmailQuery__
 *
 * To run a query within a React component, call `useCheckDuplicateEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicateEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicateEmailQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckDuplicateEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckDuplicateEmailQuery,
    CheckDuplicateEmailQueryVariables
  >
) {
  return Apollo.useQuery<
    CheckDuplicateEmailQuery,
    CheckDuplicateEmailQueryVariables
  >(CheckDuplicateEmailDocument, baseOptions)
}
export function useCheckDuplicateEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckDuplicateEmailQuery,
    CheckDuplicateEmailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CheckDuplicateEmailQuery,
    CheckDuplicateEmailQueryVariables
  >(CheckDuplicateEmailDocument, baseOptions)
}
export type CheckDuplicateEmailQueryHookResult = ReturnType<
  typeof useCheckDuplicateEmailQuery
>
export type CheckDuplicateEmailLazyQueryHookResult = ReturnType<
  typeof useCheckDuplicateEmailLazyQuery
>
export type CheckDuplicateEmailQueryResult = Apollo.QueryResult<
  CheckDuplicateEmailQuery,
  CheckDuplicateEmailQueryVariables
>
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    getCurrentUser {
      ...CurrentUserData
    }
  }
  ${CurrentUserDataFragmentDoc}
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const GetUserByNicknameDocument = gql`
  query GetUserByNickname($nickname: String!) {
    getUserByNickname(nickname: $nickname) {
      id
      picture
      nickname
      createdAt
      reddit
      twitter
      facebook
      instagram
      tradingView
      emailHash
      followersCount
      followingCount
      blocked
    }
  }
`

/**
 * __useGetUserByNicknameQuery__
 *
 * To run a query within a React component, call `useGetUserByNicknameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByNicknameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByNicknameQuery({
 *   variables: {
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useGetUserByNicknameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByNicknameQuery,
    GetUserByNicknameQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUserByNicknameQuery,
    GetUserByNicknameQueryVariables
  >(GetUserByNicknameDocument, baseOptions)
}
export function useGetUserByNicknameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByNicknameQuery,
    GetUserByNicknameQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUserByNicknameQuery,
    GetUserByNicknameQueryVariables
  >(GetUserByNicknameDocument, baseOptions)
}
export type GetUserByNicknameQueryHookResult = ReturnType<
  typeof useGetUserByNicknameQuery
>
export type GetUserByNicknameLazyQueryHookResult = ReturnType<
  typeof useGetUserByNicknameLazyQuery
>
export type GetUserByNicknameQueryResult = Apollo.QueryResult<
  GetUserByNicknameQuery,
  GetUserByNicknameQueryVariables
>
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($data: UpdateUserProfile!) {
    updateUserProfile(data: $data)
  }
`
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, baseOptions)
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<UpdateUserProfileMutation>
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>
export const UpdateUserAccountDocument = gql`
  mutation UpdateUserAccount($data: UpdateUserAccount!) {
    updateUserAccount(data: $data)
  }
`
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAccountMutation,
    UpdateUserAccountMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserAccountMutation,
    UpdateUserAccountMutationVariables
  >(UpdateUserAccountDocument, baseOptions)
}
export type UpdateUserAccountMutationHookResult = ReturnType<
  typeof useUpdateUserAccountMutation
>
export type UpdateUserAccountMutationResult =
  Apollo.MutationResult<UpdateUserAccountMutation>
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>
export const UpdateLastViewedSymbolDocument = gql`
  mutation UpdateLastViewedSymbol($data: UpdateLastViewedSymbol!) {
    updateLastViewedSymbol(data: $data)
  }
`
export type UpdateLastViewedSymbolMutationFn = Apollo.MutationFunction<
  UpdateLastViewedSymbolMutation,
  UpdateLastViewedSymbolMutationVariables
>

/**
 * __useUpdateLastViewedSymbolMutation__
 *
 * To run a mutation, you first call `useUpdateLastViewedSymbolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastViewedSymbolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastViewedSymbolMutation, { data, loading, error }] = useUpdateLastViewedSymbolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLastViewedSymbolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLastViewedSymbolMutation,
    UpdateLastViewedSymbolMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateLastViewedSymbolMutation,
    UpdateLastViewedSymbolMutationVariables
  >(UpdateLastViewedSymbolDocument, baseOptions)
}
export type UpdateLastViewedSymbolMutationHookResult = ReturnType<
  typeof useUpdateLastViewedSymbolMutation
>
export type UpdateLastViewedSymbolMutationResult =
  Apollo.MutationResult<UpdateLastViewedSymbolMutation>
export type UpdateLastViewedSymbolMutationOptions = Apollo.BaseMutationOptions<
  UpdateLastViewedSymbolMutation,
  UpdateLastViewedSymbolMutationVariables
>
export const DeleteCurrentUserDocument = gql`
  mutation DeleteCurrentUser {
    deleteCurrentUser
  }
`
export type DeleteCurrentUserMutationFn = Apollo.MutationFunction<
  DeleteCurrentUserMutation,
  DeleteCurrentUserMutationVariables
>

/**
 * __useDeleteCurrentUserMutation__
 *
 * To run a mutation, you first call `useDeleteCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrentUserMutation, { data, loading, error }] = useDeleteCurrentUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCurrentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCurrentUserMutation,
    DeleteCurrentUserMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteCurrentUserMutation,
    DeleteCurrentUserMutationVariables
  >(DeleteCurrentUserDocument, baseOptions)
}
export type DeleteCurrentUserMutationHookResult = ReturnType<
  typeof useDeleteCurrentUserMutation
>
export type DeleteCurrentUserMutationResult =
  Apollo.MutationResult<DeleteCurrentUserMutation>
export type DeleteCurrentUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteCurrentUserMutation,
  DeleteCurrentUserMutationVariables
>
export const UpdateNotificationSetttingsDocument = gql`
  mutation UpdateNotificationSetttings($data: UpdateNotificationsSettings!) {
    updateNotificationSetttings(data: $data)
  }
`
export type UpdateNotificationSetttingsMutationFn = Apollo.MutationFunction<
  UpdateNotificationSetttingsMutation,
  UpdateNotificationSetttingsMutationVariables
>

/**
 * __useUpdateNotificationSetttingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSetttingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSetttingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSetttingsMutation, { data, loading, error }] = useUpdateNotificationSetttingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNotificationSetttingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSetttingsMutation,
    UpdateNotificationSetttingsMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateNotificationSetttingsMutation,
    UpdateNotificationSetttingsMutationVariables
  >(UpdateNotificationSetttingsDocument, baseOptions)
}
export type UpdateNotificationSetttingsMutationHookResult = ReturnType<
  typeof useUpdateNotificationSetttingsMutation
>
export type UpdateNotificationSetttingsMutationResult =
  Apollo.MutationResult<UpdateNotificationSetttingsMutation>
export type UpdateNotificationSetttingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNotificationSetttingsMutation,
    UpdateNotificationSetttingsMutationVariables
  >
export const UnsubscribeDocument = gql`
  mutation Unsubscribe($token: String!) {
    unsubscribe(token: $token)
  }
`
export type UnsubscribeMutationFn = Apollo.MutationFunction<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeMutation,
    UnsubscribeMutationVariables
  >
) {
  return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(
    UnsubscribeDocument,
    baseOptions
  )
}
export type UnsubscribeMutationHookResult = ReturnType<
  typeof useUnsubscribeMutation
>
export type UnsubscribeMutationResult =
  Apollo.MutationResult<UnsubscribeMutation>
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>
export const BlockUserDocument = gql`
  mutation BlockUser($blockedUserId: ID!) {
    blockUser(blockedUserId: $blockedUserId)
  }
`
export type BlockUserMutationFn = Apollo.MutationFunction<
  BlockUserMutation,
  BlockUserMutationVariables
>

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      blockedUserId: // value for 'blockedUserId'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUserMutation,
    BlockUserMutationVariables
  >
) {
  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
    baseOptions
  )
}
export type BlockUserMutationHookResult = ReturnType<
  typeof useBlockUserMutation
>
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUserMutation,
  BlockUserMutationVariables
>
export const UnblockUserDocument = gql`
  mutation UnblockUser($blockedUserId: ID!) {
    unblockUser(blockedUserId: $blockedUserId)
  }
`
export type UnblockUserMutationFn = Apollo.MutationFunction<
  UnblockUserMutation,
  UnblockUserMutationVariables
>

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      blockedUserId: // value for 'blockedUserId'
 *   },
 * });
 */
export function useUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnblockUserMutation,
    UnblockUserMutationVariables
  >
) {
  return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(
    UnblockUserDocument,
    baseOptions
  )
}
export type UnblockUserMutationHookResult = ReturnType<
  typeof useUnblockUserMutation
>
export type UnblockUserMutationResult =
  Apollo.MutationResult<UnblockUserMutation>
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<
  UnblockUserMutation,
  UnblockUserMutationVariables
>
