import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles({
  authToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
  },

  notificationBellWrapper: {
    cursor: 'pointer',
    position: 'relative',
  },

  userAvatar: {
    width: 40,
    height: 40,
    marginLeft: '19px',

    [theme.breakpoints.down('lg')]: {
      width: 32,
      height: 32,
      marginLeft: '12px',
    },
  },

  menuButton: {
    height: 32,
    marginLeft: 12,
    padding: 0,
    color: colors.white_faded,
  },

  buttonsWrapper: {
    width: 200,
  },
})
