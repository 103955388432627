import React from 'react'

type SearchIconProps = {
  isSelected: boolean
}

const SearchIcon: React.FC<SearchIconProps> = ({ isSelected }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.8"
      cy="14.9001"
      r="8.8"
      stroke={isSelected ? '#FFFFFF' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0883 26.5887L20.0007 21.144"
      stroke={isSelected ? '#FFFFFF' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchIcon
