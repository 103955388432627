import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

const headerHeight = 70

export default makeStyles({
  root: {
    display: 'flex',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
  appBar: {
    zIndex: 10000,
    height: headerHeight,
    justifyContent: 'center',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
  LogoWithoutTextWrapper: {
    width: 30,
    height: 30,
  },
  wrapper: {
    fontFamily: fonts.dm_sans,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.drawer_bg_right,
    minHeight: '100%',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },
  content: {
    padding: 0,
    overflow: 'auto',
    flexGrow: 1,
    paddingTop: 70,
    backgroundColor: colors.drawer_bg_right,

    [theme.breakpoints.down('lg')]: {
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.search_bg,
    },
  },
  header: {
    marginTop: 80,
    fontSize: 18,
    color: colors.text_white,
    lineHeight: '24px',
  },
})
