import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles(
  {
    postWrapper: {
      display: 'flex',
      backgroundColor: colors.drawer_bg_right,
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.breakpoints.down('lg')]: {
        backgroundColor: colors.search_bg,
        // borderBottom: `1px solid ${colors.drawer_border}`,
        borderRadius: 0,
        marginTop: 0,
      },

      '&:first-child': {
        marginTop: 0,
      },
    },

    symbolDetails: {
      display: 'flex',
      marginBottom: 8,
    },

    symbolTitle: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.white_faded,
      marginLeft: 8,
    },

    story: {
      fontSize: 10,
      fontWeight: 600,
      color: colors.search_bg,
      backgroundColor: colors.orange,
      padding: '4px 8px',
      borderRadius: 100,
      width: 48,
      boxSizing: 'border-box',
      marginBottom: 8,
    },

    postIconsWrapper: {
      display: 'flex',
      marginTop: 16,
    },

    postText: {
      marginTop: 16,
      color: colors.white_faded,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
    },

    voteUpDownWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 24,
    },

    postDetails: {},

    postTitle: {
      cursor: 'pointer',
      color: colors.white_faded,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      wordBreak: 'break-word',
    },

    urlSource: {
      textDecoration: 'inherit',
      color: 'inherit',
      cursor: 'pointer',
    },

    url: {
      color: colors.textGreyLight,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
    },

    lineWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 8,
    },

    avatarWrapper: {
      display: 'flex',
      marginRight: 8,
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      alignSelf: 'center',
    },

    usernameWrapper: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },

    username: {
      color: colors.blue_lighter,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },

    symbol: {
      cursor: 'pointer',
      marginLeft: 8,
      color: colors.blue_darker,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },

    date: {
      marginLeft: 8,
      color: colors.textGreyLight,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  { index: 1 }
)
