import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.dm_sans,
    color: colors.white,
    fontStyle: 'normal',
    borderRadius: '16px',
    width: '365px',
    height: '448px',
    boxSizing: 'border-box',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '100%',
    },
  },
  areYouSureClickedContent: {
    height: '194px',
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '30px',
    textAlign: 'center',
    color: colors.white,
    width: 333,
    alignItems: 'center',
  },
  areYouSureClickedTitle: {
    margin: '0 0 16px 0',
  },
  body: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.textGreyLight,
    marginTop: 8,
  },
  bodyContinue: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.textGreyLight,
  },
  pleaseNoteList: {
    margin: 0,
    paddingInlineStart: 24,
  },
  buttonWrapper: {
    width: '100%',
    marginTop: 40,
  },
  button: {
    fontSize: 16,

    [theme.breakpoints.down('lg')]: {
      borderRadius: 8,
    },
  },
  cancelButton: {
    marginTop: 24,
    height: 40,
    color: colors.textGreyLight,
    backgroundColor: colors.drawer_bg_right,
    textTransform: 'none',
  },
}))
