import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { useDeviceType, DeviceType } from 'utils/screenSizeUtils'
import { colors, fonts } from '../../constants'

export const PostLoading: React.FC = props => {
  const deviceType = useDeviceType()

  return (
    <Box
      sx={{
        backgroundColor:
          deviceType === DeviceType.Mobile
            ? colors.search_bg
            : colors.drawer_bg_right,
        padding: deviceType === DeviceType.Mobile ? '16px' : '24px 32px',
        borderBottom:
          deviceType === DeviceType.Mobile
            ? `1px solid ${colors.border}`
            : 'none',
        marginBottom: deviceType === DeviceType.Mobile ? '16px' : '8px',
        borderRadius: deviceType === DeviceType.Mobile ? 0 : '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Skeleton
            width="50%"
            height={22.5}
            sx={{ bgcolor: colors.textGrey, marginBottom: 1 }}
          />

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Skeleton
              variant="circular"
              width={32}
              height={32}
              sx={{
                bgcolor: colors.textGrey,
                marginRight: '8px',
              }}
            />
            <Skeleton
              width={140}
              height={32}
              sx={{ bgcolor: colors.textGrey }}
            />
          </Box>

          <Skeleton
            width={'50%'}
            height={18}
            sx={{
              bgcolor: colors.textGrey,
              marginTop: '16px',
            }}
          />

          <Skeleton
            width={202}
            height={21}
            sx={{
              bgcolor: colors.textGrey,
              marginTop: '16px',
            }}
          />
        </Box>

        <Box
          sx={{
            width: 18,
            minHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Skeleton width={18} height={117} sx={{ bgcolor: colors.textGrey }} />
        </Box>
      </Box>
    </Box>
  )
}
