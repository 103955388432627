import classNames from 'classnames'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faNoteSticky,
  faPencil,
} from '@fortawesome/free-solid-svg-icons'
import { Box, Button, Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import { colors } from '../../../constants'
import Topic from '../Topic'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { VoteDirection } from '../VoteUpDown'
import MultiLineText from 'components/inputs/MultiLineText'

interface Props {
  post: PostData
  isCreator: boolean
  isStoryEdit?: boolean
  comment?: string
  isChecked?: boolean
  onVote: (direction: VoteDirection) => void
  onReply: (id: string, comment: string) => void
}

export default function StoryComment(props: Props) {
  const classes = useStyles(theme)
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState(props.comment || '')

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setComment(props.comment || '')
  }, [props.comment])

  return (
    <>
      <div className={classes.wrapper}>
        {!props.comment && (
          <FontAwesomeIcon
            icon={faNoteSticky}
            className={classNames(classes.arrow)}
            onClick={() => {
              setOpen(true)
            }}
          />
        )}
        {!!props.comment && (
          <div
            className={classes.comment}
            onClick={() => {
              setOpen(true)
            }}
          >
            <div className={classes.pencilIcon}>
              <FontAwesomeIcon
                icon={faPencil}
                className={classNames(classes.arrow)}
              />
            </div>
            {props.comment}
          </div>
        )}
      </div>
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.header}>
            <FontAwesomeIcon
              icon={faClose}
              className={classes.iconClose}
              color={colors.textGrey}
              onClick={onClose}
            />
            <div className={classes.headerText}>Note</div>
            <Button
              className={classes.replyBtn}
              disabled={comment.length === 0}
              onClick={() => {
                props.onReply(props.post.id, comment)
                onClose()
              }}
              sx={{
                '.MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
            >
              {!props.comment ? 'Add' : 'Update'}
            </Button>
          </Box>
          <Box>
            <Topic
              post={props.post}
              isCreator={props.isCreator}
              readonly={true}
              clearBackground={true}
              onVote={props.onVote}
            />
          </Box>
          <Box className={classes.text}>
            <MultiLineText
              name="comment"
              placeholder="Add your comment"
              clearBackground={true}
              value={comment}
              no_label={true}
              rows={16}
              padding={'12px 16px 16px 16px'}
              onChange={(value: string) => {
                setComment(value)
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
