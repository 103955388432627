import Box from '@mui/material/Box'
import React from 'react'
import { useForm } from 'react-hook-form'
import { regex } from '../../constants'
import TextInput from '../inputs/Text'
import AuthBoxContainer from './common/AuthBoxContainer'
import AuthSubmitButton from './common/AuthSubmitButton'
import ResendVerificationLink from './common/ResendVerificationLink'
import SpaceBetween from './common/SpaceBetween'
import { Typography } from '@mui/material'

interface CreateNewPasswordBoxProps {
  onSubmit: (data: CreateNewPasswordForm) => void
  onResendVerificationCode: () => void
  errors?: {
    code?: string
    password?: string
  }
}

interface CreateNewPasswordForm {
  password: string
  password_confirm: string
  code: string
}

const CreateNewPasswordBox: React.FC<CreateNewPasswordBoxProps> = props => {
  const { register, handleSubmit, errors, watch } =
    useForm<CreateNewPasswordForm>()

  const onSubmit = (data: CreateNewPasswordForm) => {
    props.onSubmit(data)
  }

  return (
    <AuthBoxContainer
      headingText="Create new password"
      descriptionText={
        <Typography sx={{ fontSize: 14, letterSpacing: 0.4 }}>
          Please input the code we sent to your email, and create a new
          password.
        </Typography>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <SpaceBetween spaceSize={4} sx={{ mb: 4, mt: 3 }}>
          <TextInput
            name="code"
            label="Code (6 numbers)"
            ref={register({
              required: 'Please enter the code. This is a required field.',
              pattern: {
                value: regex.verification_code,
                message: 'You have entered an invalid code.',
              },
            })}
            error={errors.code?.message || props.errors?.code}
          />
          <TextInput
            name="password"
            label="New Password"
            type="password"
            autoComplete="new-password"
            ref={register({
              required:
                'The password cannot be blank. Please enter a valid password.',
              pattern: {
                value: regex.password,
                message:
                  'You have entered an invalid password. The password must be at least 10 characters, and must have at least one of each of the following: upper-case letter (A-Z), lower-case letter (a-z), number (0-9), and a special character.',
              },
            })}
            error={errors.password ? errors.password.message : undefined}
          />
        </SpaceBetween>
        <AuthSubmitButton>SAVE PASSWORD</AuthSubmitButton>
      </form>
      <Box
        sx={{
          textAlign: 'center',
          mt: 3,
        }}
      >
        <ResendVerificationLink
          onResendVerificationLink={props.onResendVerificationCode}
        />
      </Box>
    </AuthBoxContainer>
  )
}

export default CreateNewPasswordBox
