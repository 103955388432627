import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles({
  wrapper: {
    display: 'flex',
    marginTop: 24,
    marginBottom: 32,
    paddingRight: 16,
    boxSizing: 'border-box',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      marginBottom: 0,
      padding: 16,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${colors.drawer_border}`,
    },
  },

  symbolDetails: {
    display: 'flex',
    cursor: 'pointer',
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 14,
    color: colors.white_faded,
    height: 24,
    alignItems: 'center',
    lineHeight: '24px',
  },

  symbolTitle: {
    display: 'flex',
    marginLeft: 8,
  },

  title: {
    cursor: 'pointer',
    fontFamily: fonts.dm_sans,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '22px',
    color: colors.white_faded,
    marginTop: 8,
    wordBreak: 'break-word',
  },

  url: {
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    color: colors.textGreyLight,
    marginTop: 8,
    textDecoration: 'none',
  },

  usernameSymbolWrapper: {
    marginTop: 8,
    display: 'flex',
  },

  username: {
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.blue_lighter,
    cursor: 'pointer',
  },

  symbol: {
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '22px',
    color: colors.cyan_blue_light_faded,
    marginLeft: 8,
    textDecoration: 'none',
  },

  createdAt: {
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '22px',
    color: colors.textGreyLight,
    marginLeft: 8,
  },

  postIconsWrapper: {
    paddingTop: 12,
  },

  imageWrapper: {
    width: '100%',
  },

  image: {
    cursor: 'pointer',
    width: '100%',
    maxWidth: '600px',
    marginTop: 8,
    paddingRight: 16,
    boxSizing: 'border-box',
  },
})
