import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import LogoNoBeta from 'components/Images/LogoNoBeta'
import LandingVideoModal from './LandingVideoModal/LandingVideoModal'
import classNames from 'classnames'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  userId?: string | null
  lastViewedSymbol?: string | null
  lastViewedSymbolType?: string | null
}

export const Landing: React.FC<Props> = props => {
  const deviceType = useDeviceType()

  const classes = useStyles(theme)
  const navigate = useNavigate()
  const currentYear = new Date().getFullYear()
  const [showLandingVideoModal, setShowLandingVideoModal] =
    React.useState<boolean>(false)

  const handleShowLandingVideoModallose = () => {
    setShowLandingVideoModal(false)
  }

  const goToChart = () => {
    if (props.lastViewedSymbol && props.lastViewedSymbolType) {
      navigate(
        ROUTES.ASSET.replace(':symbol', props.lastViewedSymbol)
          .replace(':type', props.lastViewedSymbolType)
          .toLowerCase()
      )
    } else {
      navigate(ROUTES.SENTIMENT_DISCOVERY)
    }
  }

  // if user is logged in then call goToChart
  React.useEffect(() => {
    if (props.userId) {
      goToChart()
    }
  }, [props.userId])

  const handleImageClick = () => {
    navigate('/sentiment-discovery')
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.mainContent}>
            <div className={classes.topContentSection}>
              <div className={classes.contentLeft}>
                <div className={classes.contentTextLeft}>
                  <div className={classes.sectionMainHeader}>
                    <span>Want to know why your stock's price fell today?</span>
                  </div>
                  <div className={classes.sectionMainBody}>
                    <span>
                      Click on any bar to see the context behind the stock's
                      price movement that day.
                    </span>
                  </div>
                  <div className={classes.buttons}>
                    <Button onClick={goToChart} className={classes.joinBtn}>
                      Click here now to find out!
                    </Button>
                    <Button
                      onClick={() => setShowLandingVideoModal(true)}
                      className={classes.watchVideoBtn}
                    >
                      Watch demo
                    </Button>
                  </div>
                </div>
              </div>
              <div className={classes.contentRight}>
                <div
                  className={classes.chartImageWrapper}
                  onClick={handleImageClick}
                >
                  <img
                    width={deviceType === DeviceType.Desktop ? 740 : '100%'}
                    src="/media/AboutPageSiteScreenshot.png"
                    alt="About page site screenshot"
                  />
                </div>
              </div>
            </div>
            <div className={classes.contentSection}>
              <div className={classes.contentLeft}>
                <div
                  className={classes.imageWrapper}
                  onClick={handleImageClick}
                >
                  <img
                    width={deviceType === DeviceType.Desktop ? 455 : '100%'}
                    src="/media/AboutPageExploreMove.png"
                    alt="About page explore what caused a move"
                  />
                </div>
              </div>
              <div className={classes.contentRight}>
                <div className={classes.contentText}>
                  <div className={classes.sectionHeader}>
                    <span>Explore what caused a move</span>
                  </div>
                  <div className={classes.sectionBody}>
                    <span>
                      Explore the pivotal news moments, both current and
                      historical, that have propelled a stock's price. Just
                      click on any price bar on the chart, and find news &
                      analysis explaining the price move that day.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                classes.contentSection,
                deviceType === DeviceType.Mobile ? classes.reverseOrder : null
              )}
            >
              <div className={classes.contentLeft}>
                <div className={classes.contentText}>
                  <div className={classes.sectionHeader}>
                    <span>Discover the Future Big Movers</span>
                  </div>
                  <div className={classes.sectionBody}>
                    <span>
                      Discover the popular stocks among investors today.
                      {deviceType === DeviceType.Desktop && <br />}
                      <br />
                      Uncover the stocks that may be the growth-leaders of
                      tomorrow, with our innovative Sentiment Discovery
                      Dashboard™
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.contentRight}>
                <div
                  className={classes.imageWrapper}
                  onClick={handleImageClick}
                >
                  <img
                    width={deviceType === DeviceType.Desktop ? 455 : '100%'}
                    src="/media/AboutPageFutureMovers.png"
                    alt="About page future movers"
                  />
                </div>
              </div>
            </div>
            <div className={classes.contentSection}>
              <div className={classes.contentLeft}>
                <div
                  className={classes.imageWrapper}
                  onClick={handleImageClick}
                >
                  <img
                    width={deviceType === DeviceType.Desktop ? 455 : '100%'}
                    src="/media/AboutPageCastYourVote.png"
                    alt="About page cast your vote"
                  />
                </div>
              </div>
              <div className={classes.contentRight}>
                <div className={classes.contentText}>
                  <div className={classes.sectionHeader}>
                    <span>Cast Your Vote on the Most Insightful Headlines</span>
                  </div>
                  <div className={classes.sectionBody}>
                    <span>
                      Vote on the ranking of the headline you feel truly capture
                      the real reasons behind what drove a move in a stock's
                      price that day.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                classes.contentSection,
                deviceType === DeviceType.Mobile ? classes.reverseOrder : null
              )}
            >
              <div className={classes.contentLeft}>
                <div className={classes.contentText}>
                  <div className={classes.sectionHeader}>
                    <span>Engage in Collaborative Insights </span>
                  </div>
                  <div className={classes.sectionBody}>
                    <span>
                      Contribute to the analysis of a headline, or discuss
                      another user's analysis, to create a deeper understanding
                      of the underlying reasons driving the move in a stock's
                      price that day.
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.contentRight}>
                <div
                  className={classes.imageWrapper}
                  onClick={handleImageClick}
                >
                  <img
                    width={deviceType === DeviceType.Desktop ? 455 : '100%'}
                    src="/media/AboutPageEngageInCollabInsights.png"
                    alt="About page engage in collab insights"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottomBanner}>
            <div className={classes.bottomBannerLeft}>
              <div className={classes.bottomBannerLeftInner}>
                <div className={classes.bottomBannerText}>
                  <div>
                    Next time you want to know why your stock’s price fell
                  </div>
                </div>
                <Button onClick={goToChart} className={classes.joinTheCommBtn}>
                  Click here now to find out!
                </Button>
              </div>
            </div>
            {deviceType === DeviceType.Desktop && (
              <img
                className={classes.bottomBannerImg}
                width={367}
                src="/media/AboutPageBottomImage.png"
                alt="About page bottom image"
              />
            )}
            {deviceType === DeviceType.Mobile && (
              <img
                className={classes.bottomBannerImg}
                width={'100%'}
                src="/media/AboutPageBottomImageWide.png"
                alt="About page bottom image"
              />
            )}
          </div>
        </div>
        {deviceType === DeviceType.Desktop && (
          <footer className={classes.footer}>
            <div className={classes.footerInner}>
              <div className={classes.leftFooter}>
                <div>
                  <LogoNoBeta />
                  <div className={classes.footerCopyright}>
                    © 2018-{currentYear} fnchart Inc.
                  </div>
                </div>
              </div>
              <div className={classes.rightFooter}>
                <div className={classes.footerLinksRow}>
                  <Link
                    to={ROUTES.ABOUT}
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About
                  </Link>
                  <a
                    href="https://twitter.com/fnchart"
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                  <Link
                    to={ROUTES.TERMS_OF_SERVICE}
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </Link>
                </div>
                <div className={classes.footerLinksRow}>
                  <Link
                    to={ROUTES.SITE_RULES}
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Site rules
                  </Link>
                  <a
                    href="https://www.youtube.com/@fnchart"
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Youtube
                  </a>
                  <Link
                    to={ROUTES.PRIVACY_POLICY}
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div className={classes.footerLinksRow}>
                  <Link
                    to={ROUTES.CONTACT_US}
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/fnchart/"
                    className={classes.footerLinksColumn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </footer>
        )}
      </div>
      <LandingVideoModal
        open={showLandingVideoModal}
        onClose={handleShowLandingVideoModallose}
      />
    </>
  )
}

export default Landing
