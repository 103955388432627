import { forwardRef } from 'react'
import theme from '../../../../mui-theme'
import useStyles from './styles'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines } from '@fortawesome/free-solid-svg-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Post from 'components/common/Post'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { PostLoading } from 'components/UserOverlay/PostLoading'

export interface PostsProps {
  userId?: string
  posts: PostData[]
  hasMorePosts: boolean
  onVote: (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onPostClick: (id: string, symbol: string) => void
  loadMorePosts: () => void
}

export default forwardRef<HTMLInputElement, PostsProps>((props, ref) => {
  const classes = useStyles(theme)

  return (
    <div id="tab-content" className={classes.tabContent}>
      <InfiniteScroll
        dataLength={props.posts.length}
        className={classes.wrapper}
        next={() => {
          props.loadMorePosts()
        }}
        hasMore={props.hasMorePosts}
        loader={
          <div>
            <PostLoading />
            <PostLoading />
            <PostLoading />
            <PostLoading />
            <PostLoading />
          </div>
        }
        scrollableTarget="tab-content"
      >
        {props.posts.map((post, index) => (
          <div className={classes.postWrapper}>
            <Post
              key={index}
              userId={props.userId}
              post={post}
              index={index}
              onVote={props.onVote}
              onShareActionsClick={props.onShareActionsClick}
              onCameraActionsClick={props.onCameraActionsClick}
              onMoreActionsClick={props.onMoreActionsClick}
              onPostClick={props.onPostClick}
              // loadMorePosts={props.loadMorePosts}
            />
          </div>
        ))}
      </InfiniteScroll>
      {props.posts.length === 0 && (
        <div className={classes.noPostsWrapper}>
          <FontAwesomeIcon
            icon={faFileLines}
            size="lg"
            className={classes.noPostsCommentsIcon}
          />
          <div className={classes.noPostsText}>
            hmm... looks like there are no posts yet
          </div>
        </div>
      )}
    </div>
  )
})
