import React from 'react'
import DeleteUser from '../DeleteUser/DeleteUser'
import theme from '../../../mui-theme'
import useStyles from './styles'
import { Modal } from '@mui/material'
import CancelButton from 'components/common/CancelButton'
import classNames from 'classnames'

interface Props {
  username: string
  email: string
  open: boolean
  onClose: () => void
  deleteAccount: () => void
}

const DeleteUserModal: React.FC<Props> = ({
  username,
  email,
  open,
  onClose,
  deleteAccount,
}: Props) => {
  const classes = useStyles(theme)

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.modalInner}>
        <DeleteUser
          username={username}
          email={email}
          deleteAccount={deleteAccount}
        />
        <CancelButton
          className={classNames(classes.button, classes.cancelButton)}
          onClick={() => onClose()}
        >
          Cancel
        </CancelButton>
      </div>
    </Modal>
  )
}

export default DeleteUserModal
