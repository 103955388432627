import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { IPricePredictionResultNotification } from 'types/notification'
import ROUTES from 'constants/routes'
import TickerImage from 'components/common/TickerImage'

interface Props {
  notification: IPricePredictionResultNotification
  limitTextCharacters?: number
  onClose?: () => void
}

const PostReplyNotificationRow: React.FC<Props> = props => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const handleNotificationClick = () => {
    props.onClose && props.onClose()
    navigate(
      ROUTES.PRICE_PREDICTION_RESULT.replace(
        ':roundId',
        props.notification.roundId.toString()
      )
    )
  }

  return (
    <div
      onClick={handleNotificationClick}
      className={classNames(
        classes.notificationWrapper,
        !props.notification.read && classes.unreadNotification
      )}
    >
      <TickerImage symbol={props.notification.symbol} width={32} height={32} />
      <div className={classes.notificationContentWrapper}>
        <div className={classes.notificationTopContentWrapper}>
          <div className={classes.nickname}>Result is here</div>
          <div className={classes.symbol}>{props.notification.symbol}</div>
          <div className={classes.date}>
            {getTimeElapsedRounded(props.notification.endOfRoundTimestamp)}
          </div>
        </div>
        <div className={classes.notificationBottomContentWrapper}>
          Check result
        </div>
      </div>
    </div>
  )
}

export default PostReplyNotificationRow
