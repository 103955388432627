import { makeStyles } from '@mui/styles'
import { colors } from '../../../../constants'

export default makeStyles({
  commentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    padding: 16,
    backgroundColor: colors.drawer_bg,
    borderRadius: 8,
  },

  postIconsWrapper: {
    display: 'flex',
    marginTop: 16,
  },

  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  usernameWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },

  username: {
    color: colors.blue_lighter,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  avatarWrapper: {
    display: 'flex',
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    alignSelf: 'center',
  },

  date: {
    marginLeft: 8,
    color: colors.textGreyLight,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  commentText: {
    color: colors.white_faded,
    marginTop: 8,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  deletedComment: {
    color: colors.textGrey,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  voteUpDownWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
  },
})
