import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { colors, fonts } from '../../../constants'

const CancelButton = styled(Button)({
  color: colors.textGrey,
  backgroundColor: colors.drawer_bg,
  boxShadow: 'none',
  borderRadius: 0,
  width: '100%',
  fontSize: 10,
  letterSpacing: 2.44,
  fontFamily: fonts.dm_sans,
  lineHeight: 1,
  padding: '14px 0',
  '&:hover': {
    backgroundColor: colors.input_bg,
  },

  '.MuiTouchRipple-root': {
    display: 'none'
  }
})

export default CancelButton
