import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'

export const SiteRules: React.FC = () => {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>Site Rules</div>
        <div className={classes.lastRevised}>Last Revised April 22, 2022</div>
        <div className={classes.bodyText}>
          <p>
            Fnchart is a vast network of communities that are created, run, and
            populated by you, the Fnchart users. Through these communities, you
            can post, comment, vote, discuss, learn, debate, support, and
            connect with people who share your interests, and we encourage you
            to find—or even create—your home on Fnchart.
          </p>

          <p>
            While not every community may be for you (and you may find some
            unrelatable or even offensive), no community should be used as a
            weapon. Communities should create a sense of belonging for their
            members, not try to diminish it for others. Likewise, everyone on
            Fnchart should have an expectation of privacy and safety, so please
            respect the privacy and safety of others.
          </p>

          <p>
            Every community on Fnchart is defined by its users. Some of these
            users help manage the community as moderators. The culture of each
            community is shaped explicitly, by the community rules enforced by
            moderators, and implicitly, by the upvotes, downvotes, and
            discussions of its community members. Please abide by the rules of
            communities in which you participate and do not interfere with those
            in which you are not a member.
          </p>

          <p>
            Below the rules governing each community are the platform-wide rules
            that apply to everyone on Fnchart. These rules are enforced by us,
            the admins.
          </p>

          <p>
            Fnchart and its communities are only what we make of them together,
            and can only exist if we operate by a shared set of rules. We ask
            that you abide by not just the letter of these rules, but the spirit
            as well.
          </p>
        </div>
        <div className={classes.header}>Rules</div>
        <div className={classes.subHeader}>Rule 1</div>
        <div className={classes.bodyText}>
          <p>
            Remember the human. Fnchart is a place for creating community and
            belonging, not for attacking marginalized or vulnerable groups of
            people. Everyone has a right to use Fnchart free of harassment,
            bullying, and threats of violence. Communities and users that incite
            violence or that promote hate based on identity or vulnerability
            will be banned.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 2</div>
        <div className={classes.bodyText}>
          <p>
            Abide by community rules. Post authentic content into communities
            where you have a personal interest, and do not cheat or engage in
            content manipulation (including spamming, vote manipulation, ban
            evasion, or subscriber fraud) or otherwise interfere with or disrupt
            Fnchart communities.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 3</div>
        <div className={classes.bodyText}>
          <p>
            Respect the privacy of others. Instigating harassment, for example
            by revealing someone’s personal or confidential information, is not
            allowed. Never post or threaten to post intimate or
            sexually-explicit media of someone without their consent.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 4</div>
        <div className={classes.bodyText}>
          <p>
            Do not post or encourage the posting of sexual or suggestive content
            involving minors.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 5</div>
        <div className={classes.bodyText}>
          <p>
            You don’t have to use your real name to use Fnchart, but don’t
            impersonate an individual or an entity in a misleading or deceptive
            manner.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 6</div>
        <div className={classes.bodyText}>
          <p>
            Ensure people have predictable experiences on Fnchart by properly
            labeling content and communities, particularly content that is
            graphic, sexually-explicit, or offensive.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 7</div>
        <div className={classes.bodyText}>
          <p>
            Keep it legal, and avoid posting illegal content or soliciting or
            facilitating illegal or prohibited transactions.
          </p>
        </div>
        <div className={classes.subHeader}>Rule 8</div>
        <div className={classes.bodyText}>
          <p>
            Don’t break the site or do anything that interferes with normal use
            of Fnchart.
          </p>
        </div>
        <div className={classes.header}>Enforcement</div>
        <div className={classes.subHeader}></div>
        <div className={classes.bodyText}>
          <p>
            We have a variety of ways of enforcing our rules, including, but not
            limited to
          </p>
          <ul>
            <li>Asking you nicely to knock it off</li>
            <li>Asking you less nicely</li>
            <li>Temporary or permanent suspension of accounts</li>
            <li>
              Removal of privileges from, or adding restrictions to, accounts
            </li>
            <li>
              Adding restrictions to Fnchart communities, such as adding NSFW
              tags or Quarantining
            </li>
            <li>Removal of content</li>
            <li>Banning of Fnchart communities</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SiteRules
