import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    fontFamily: fonts.dm_sans,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.drawer_bg_right,
    minHeight: '100%',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },
  content: {
    width: 700,
    marginBottom: 20,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      padding: '0 20px',
      boxSizing: 'border-box',
    },
  },
  header: {
    color: colors.text_white,
    fontSize: 18,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: 20,

    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },

    '&:first-of-type': {
      marginTop: 80,

      [theme.breakpoints.down('lg')]: {
        marginTop: 34,
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '30px',
        textAlign: 'center',
      },
    },
  },
  subHeader: {
    color: colors.text_white,
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: 16,
    marginTop: 20,

    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  lastRevised: {
    color: colors.textGrey,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    paddingTop: 16,
    paddingBottom: 16,

    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
  bodyText: {
    color: colors.white_faded,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,

    '& > p': {
      marginTop: 0,
      marginBottom: 25,
    },
  },
}))
