import theme from '../../mui-theme'
import useStyles from './styles'

export const Disclosures = () => {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>
          <h1>Disclosures</h1>
        </div>
        <div className={classes.bodyText}>
          <h3>GENERAL DISCLAIMER.</h3>
          <p>
            Please read important legal disclosures that apply to your
            relationship with fnchart Inc.
          </p>
          <p>
            Online trading has inherent risk due to system response, execution
            price, speed, liquidity, market data and access times that may vary
            due to market conditions, system performance, market volatility,
            size and type of order and other factors. An investor should
            understand these and additional risks before trading.
          </p>
          <p>
            The information provided is not warranted as to completeness or
            accuracy and is subject to change without notice. The information
            provided should not be considered legal or tax advice. Consult an
            attorney or tax professional regarding your specific situation. The
            projections or other information regarding the likelihood of various
            investment outcomes are hypothetical in nature, are not guaranteed
            for accuracy or completeness, do not reflect actual investment
            results and are not guarantees of future results. All investments
            involve risk, losses may exceed the principal invested, and the past
            performance of a security, industry, sector, market, or financial
            product does not guarantee future results or returns. Consider the
            investment objectives, risks, charges, and expenses of a fund before
            investing. Contact us for a prospectus containing this information.
          </p>
          <p>
            Investors should consider the investment objectives, risks, and
            charges and expenses of a mutual fund or Exchange Traded Fund
            (“ETF”) carefully before investing. Before investing in any mutual
            fund or ETF, you should consider its investment objective, risks,
            charges and expenses. Contact us at support@stinvest.co for a
            prospectus, offering circular or, if available, a summary prospectus
            containing this information. Read it carefully. ETFs are subject to
            market fluctuation and the risks of their underlying investments.
            ETFs are subject to management fees and other expenses. Unlike
            mutual funds, ETF shares are bought and sold at market price, which
            may be higher or lower than their NAV, and are not individually
            redeemed from the fund. Investment returns will fluctuate and are
            subject to market volatility, so that an investor's shares, when
            redeemed or sold, may be worth more or less than their original
            cost. ETFs are subject to risks similar to those of stocks. Some
            specialized exchange-traded funds can be subject to additional
            market risks. Leveraged and inverse exchange traded products are not
            designed for buy and hold investors or investors who do not intend
            to manage their investment on a daily basis. These products are for
            sophisticated investors who understand their risks (including the
            effect of daily compounding of leveraged investment results), and
            who intend to actively monitor and manage their investments on a
            daily basis.
          </p>
          <br />

          <h3>CONTENT & SOCIAL MEDIA DISCLOSURE.</h3>
          <p>
            Content, news, research, tools, and securities symbols are for
            educational and illustrative purposes only and do not imply a
            recommendation or solicitation to buy or sell a particular security
            or to engage in any particular investment strategy. Companies that
            are not affiliated with us may supply some of the content. The
            source of all third-party Content is clearly and prominently
            identified. We have not been involved in the preparation, adoption
            or editing of third party content and we do not endorse or approve
            such content. The information provided is not warranted as to
            completeness or accuracy and is subject to change without notice.
            The projections or other information regarding the likelihood of
            various investment outcomes are hypothetical in nature, are not
            guaranteed for accuracy or completeness, do not reflect actual
            investment results and are not guarantees of future results. All
            investments involve risk, losses may exceed the principal invested,
            and the past performance of a security, industry, sector, market, or
            financial product does not guarantee future results or returns.
            Before investing in a fund, consider the fund’s investment
            objectives, risks, charges, and expenses. Contact us for a
            prospectus containing this information.
          </p>
          <p>
            Opinions, statements, or views posted on our social media pages are
            communications by the persons posting them, and they are not adopted
            or endorsed by us. We may, in our sole discretion, remove comments
            or users at our discretion. We will remove comments that are
            unlawful, abusive, defamatory, offensive, or that contain
            profanities. We will remove spam, and treat as spam a high number of
            messages on the same topic.
          </p>
          <p>
            We do not control or endorse any advertising, messages or content
            that the providers of the social media sites may publish on our
            social media pages. Third party marks appearing herein are the
            property of their respective owners.
          </p>
          <p>
            For customer service related to your brokerage account, please email
            us at support@stinvest.co. Do not post personal, account or
            transaction information on our social media sites. ST Invest LLC
            does not accept instructions to effect securities transactions or
            funds transfers via communications posted on social media sites.
          </p>

          <h3>BUSINESS CONTINUITY PLAN DISCLOSURE.</h3>

          <h5>Overview</h5>
          <p>
            fnchart Inc (“FNCHART”) has created and implemented a Business
            Continuity Plan (“BCP”) in an effort to mitigate the effects related
            to unforeseen business interruptions. The BCP is designed to enable
            the Firm to continue or resume trading operations as soon as
            possible in the event of an emergency or significant business
            disruption (“SBD”).
          </p>
          <h5>Communications and Locations of Employees During a Disruption</h5>
          <p>
            In the event of an SBD, FNCHART will communicate with its employees
            in several different ways. The Firm will employ a calling tree,
            which would be implemented by management in the event of an SBD.
            Recovery time for most of the applications is expected to range from
            immediate to 24 hours.
          </p>
          <h5>Communications with Customers</h5>
          <p>
            FNCHART is currently able to communicate with its customers using
            the fnchart twitter feed at https://twitter.com/fnchart and via
            email. In the event of an SBD, FNCHART will assess which means of
            communication are still available and use the means closest in speed
            to the means used in the past. Additionally, if all traditional
            means of communication are not available, FNCHART will post
            emergency contact information and instructions on its web site
            regarding access to FNCHART representatives.
          </p>
          <h5>Books & Records</h5>
          <p>
            FNCHART maintains its hard-copy books and records and electronic
            records primarily at its Main Office and back-up books and records
            at alternate locations. If records are lost as the result of a
            business disruption or for any other reason, FNCHART has the means
            to physically recover data from both physical and electronic back-up
            locations.
          </p>
          <h5>Vendors and Counterparties</h5>
          <p>
            FNCHART’s BCP may need to rely upon the recovery and restoration of
            services provided by various critical business constituents at both
            its primary and alternate business locations. FNCHART has considered
            the extent to which it may be able to depend upon these business
            relationships during an SBD as a part of its decision to do business
            with the constituent. Where possible it has engaged and/or vetted
            alternate arrangements in order to avoid business disruptions in the
            case of a business constituent not being able to provide needed
            goods or services in an emergency.
          </p>
          <h5>Disclosure</h5>
          <p>
            FNCHART’s BCP is designed to address the continued operation of the
            Firm in the event of an emergency situation or SBD. However, all
            risks of business interruption cannot be eliminated and FNCHART
            cannot guarantee that systems will always be available or
            recoverable following an SBD. Furthermore, FNCHART has no control
            over and must rely upon the disaster recovery plans of its various
            critical business constituents and vendors. In the event that
            material changes are made to this BCP, FNCHART will post a notice on
            its twitter account referenced in the foregoing.
          </p>
          <p>
            The information contained in this disclosure is provided by FNCHART
            for informational purposes only, and nothing contained herein shall
            be construed to amend, supplement or otherwise modify any of the
            terms and conditions set forth in any customer agreement between you
            and FNCHART.
          </p>

          <br />
          <p>
            fnchart Inc may update these Disclosures at any time, and may do so
            without notice, neither before nor after the update.
          </p>
          <br />

          <p>Market data provided by FINANCIAL MODELING PREP SAS.</p>
          <p>
            Please read important legal disclosures that apply to your brokerage
            account.
          </p>
          <p>
            Securities products are:
            <ul>
              <li>Not FDIC insured;</li>
              <li>Not bank guaranteed; and,</li>
              <li>May lose value.</li>
            </ul>
          </p>
          <p>© 2019 fnchart Inc All rights reserved.</p>
          <p>fnchart Inc V.01 09/12/2023</p>
        </div>
      </div>
    </div>
  )
}

export default Disclosures
