import Box from '@mui/material/Box'
import React from 'react'
import { colors, fonts } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
interface AlreadyRegisteredSectionProps {
  onGoToLogIn: () => void
}

const AlreadyRegisteredSection: React.FC<AlreadyRegisteredSectionProps> = ({
  onGoToLogIn,
}) => {
  const deviceType = useDeviceType()

  return (
    <Box
      sx={{
        textAlign: 'center',
        fontFamily: fonts.dm_sans,
        fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
        lineHeight: deviceType === DeviceType.Mobile ? '20px' : '18px',
        fontWeight: deviceType === DeviceType.Mobile ? 400 : 400,
        color: colors.textGreyLight,
        '& a': {
          color: colors.blue_light,
          cursor: 'pointer',
        },
        padding: deviceType === DeviceType.Mobile ? 3 : 2,
      }}
    >
      Already have an account?{' '}
      <Box
        component="button"
        sx={{
          backgroundColor: 'inherit',
          font: 'inherit',
          color: colors.blue_lighter,
          border: 'none',
          cursor: 'pointer',
          textDecoration: 'none',
          display: 'inline',
          margin: '0 0 0 4px',
          padding: 0,
        }}
        onClick={() => onGoToLogIn()}
      >
        Log In
      </Box>
    </Box>
  )
}

export default AlreadyRegisteredSection
