import useStyles from './styles'
import theme from '../../mui-theme'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { colors, fonts } from '../../constants'

interface Props {
  selectedId: string
  tabItems: {
    id: string
    label: string
  }[]
  tabClick: (id: string) => void
}

export default function TabBar(props: Props) {
  const classes = useStyles(theme)

  const handleTabChange = (_event: any, selectedTabId: string) => {
    props.tabClick(selectedTabId)
  }

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={props.selectedId}
        className={classes.tabs}
        onChange={handleTabChange}
        aria-label="tab bar tabs"
        classes={{
          indicator: classes.selectedTabIndicator,
          scroller: classes.scroller,
          flexContainer: classes.flexContainer,
        }}
      >
        {props.tabItems.map((item, i) => (
          <Tab
            key={item.id}
            sx={{
              fontSize: 12,
              color: colors.textGrey,
              fontFamily: fonts.dm_sans,
              padding: '6px 0px',
              margin:'0px 24px',
              minWidth: 30,
              textTransform: 'capitalize',
              fontWeight: 400,
              lineHeight: 14,

              '&.Mui-selected': {
                color: colors.text_white,
              }
            }}
            value={item.id}
            label={item.label}
          />
        ))}
      </Tabs>
    </div>
  )
}
