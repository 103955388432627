import { AssetTypeEnum } from 'enum/assetTypeEnum'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useApolloNetworkStatus } from '../apollo/client'
import NavigationFrame from '../components/NavigationFrame'
import NavigationSidebar from '../components/NavigationSidebar'
import ROUTES from '../constants/routes'
import {
  ChildPostDataFragment,
  GetStoryPostsDocument,
  PostType,
  PostsDocument,
  PostsQuery,
  PostsQueryVariables,
  useGetCurrentUserQuery,
  useGetPostQuery,
  useGetSymbolQuery,
  useSetStoryPostsMutation,
} from '../generated/graphql'
import About from './About/About'
import { BullsAndBears } from './BullsAndBears/BullsAndBears'
import ContactUs from './ContactUs/ContactUs'
import Disclosures from './Disclosures/Disclosures'
import Landing from './Landing/Landing'
import Main from './Main/Main'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import SiteRules from './SiteRules/SiteRules'
import TermsofService from './TermsofService/TermsofService'
import { DEBUG_ENV } from '../index'
import NotFound from './NotFound/NotFound'
import CreateTopicPanelIntegration from './Main/CreateTopicPanelIntegration'
import ViewTopicIntegration from './Main/ViewTopicIntegration'
import SignUpModal from 'components/auth/SignUpModal/SignUpModal'
import { PostEditIntegration } from './Main/PostEditIntegration'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES } from 'constants/navigationConstants'
import { userSidebarTabItems } from 'constants/userSidebarTabItems'
import { userSidebarMenuItems } from 'constants/userSidebarMenuItems'
import { navigationFooterMenuItems } from 'constants/navigationFooterMenuItems'
import { navigationMenuItems } from 'constants/navigationMenuItems'
import UserSidebarContent from 'components/UserSidebarContent'
import { useUserOperations } from 'hooks/useUserOperations'
import { IStoryPost } from 'types/story-posts'
import { setSuccess } from 'apollo/reactive-vars'

const Chart: React.FC = () => {
  const deviceType = useDeviceType()
  const location = useLocation()
  const navigate = useNavigate()
  const fullPageMobileRoutes = ['/create', '/topic', '/edit']
  const isFullPageMobileRoute = fullPageMobileRoutes.some(route =>
    location.pathname.includes(route)
  )

  const isStoryEdit = location.pathname.includes('/story-edit')
  const isStoryView = location.pathname.includes('/story')
  const [storyTitle, setStoryTitle] = useState<string>('')

  const topicId =
    isStoryEdit || isStoryView ? location.pathname.split('/').pop() : null

  const [selectedStoryPosts, setSelectedStoryPosts] = useState<IStoryPost[]>([])

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: getCurrentUserData?.getCurrentUser?.lastViewedSymbol || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  const { data: postData } = useGetPostQuery({
    variables: { id: topicId! },
    fetchPolicy: 'cache-first',
    skip: !topicId,
  })

  const [setStoryPosts] = useSetStoryPostsMutation({
    update(cache, mutationResult, { variables }) {
      const updatedData = selectedStoryPosts.map(storyPost => {
        const post: ChildPostDataFragment = {
          __typename: 'ChildPost',
          childPostId: storyPost.childPostId,
          noteText: storyPost.noteText,
        }
        return post
      })

      cache.writeQuery({
        query: GetStoryPostsDocument,
        variables: { parentStoryId: topicId! },
        data: { getStoryPosts: updatedData },
      })

      const readQueryResult = cache.readQuery<PostsQuery, PostsQueryVariables>({
        query: PostsDocument,
        variables: {
          postSymbol: symbolData?.getSymbol?.symbol.toUpperCase() as string,
          postAssetType: symbolData?.getSymbol?.type.toUpperCase() as string,
        },
      })

      const posts = readQueryResult?.posts || []
      let updatedPosts = posts.map(post => {
        if (post.id === variables?.parentStoryId) {
          return {
            ...post,
            storyPosts: updatedData,
          }
        }

        return post
      })

      cache.writeQuery({
        query: PostsDocument,
        data: {
          posts: updatedPosts,
        },
        variables: {
          postSymbol: symbolData?.getSymbol?.symbol.toUpperCase() as string,
          postAssetType: symbolData?.getSymbol?.type.toUpperCase() as string,
        },
      })
    },
  })

  const {
    signOut,
    setPassword,
    accountSave,
    profileSave,
    changePasswordError,
    onMenuItemClick,
  } = useUserOperations()
  const status = useApolloNetworkStatus()

  const [showSignUpModal, setShowSignUpModal] = React.useState<boolean>(false)
  const [openUserSettingsSidebar, setOpenUserSettingsSidebar] = useState(false)
  const [openNavigationSidebar, setOpenNavigationSidebar] = useState(false)
  const [selectedtabItemIndex, setSelectedtabItemIndex] = useState(0)

  function onBackClick() {
    setOpenUserSettingsSidebar(false)
  }

  //LINK API
  function onNavigationMenuItemClick(id: string) {
    switch (id) {
      case NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.ABOUT:
        window.open(ROUTES.ABOUT, '_blank')
        break
      case NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.SITERULES:
        window.open(ROUTES.SITE_RULES, '_blank')
        break
      case NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.CONTACT:
        window.open(ROUTES.CONTACT_US, '_blank')
        break
      case NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.PRIVACYPOLICY:
        window.open(ROUTES.PRIVACY_POLICY, '_blank')
        break
      case NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.TERMSOFSERVICE:
        window.open(ROUTES.TERMS_OF_SERVICE, '_blank')
        break
      default:
        if (getCurrentUserData?.getCurrentUser?.lastViewedSymbol) {
          navigate(
            ROUTES.ASSET.replace(
              ':symbol',
              getCurrentUserData?.getCurrentUser?.lastViewedSymbol
            )
              .replace(
                ':type',
                symbolData?.getSymbol?.type || AssetTypeEnum.stock
              )
              .toLowerCase()
          )
        } else {
          navigate(ROUTES.SENTIMENT_DISCOVERY)
        }
        break
    }
  }

  function onCloseRightPanel() {
    setOpenUserSettingsSidebar(false)
  }

  function onToggleLeftPanel(open: boolean) {
    setOpenNavigationSidebar(open)
  }

  const handleSignUpModalClose = React.useCallback(() => {
    setShowSignUpModal(false)
  }, [])

  const submitStory = async () => {
    await setStoryPosts({
      variables: {
        parentStoryId: topicId!,
        childPosts: selectedStoryPosts.map(storyPost => ({
          childPostId: storyPost.childPostId,
          noteText: storyPost.noteText,
        })),
      },
    })

    navigate(
      ROUTES.ASSET.replace(':type', symbolData?.getSymbol?.type!)
        .replace(':symbol', symbolData?.getSymbol?.symbol!)
        .toLowerCase()
    )

    setSuccess('Story updated successfully')
  }

  const memoizedProps = React.useMemo(
    () => ({
      userId: getCurrentUserData?.getCurrentUser?.id,
      nickname: getCurrentUserData?.getCurrentUser?.nickname,
      replyNotificationEmail:
        getCurrentUserData?.getCurrentUser?.replyNotificationEmail || false,
    }),
    [getCurrentUserData]
  )

  useEffect(() => {
    if (postData?.getPost && postData?.getPost.postType === PostType.Story) {
      setStoryTitle(postData?.getPost.title || '')
    } else {
      setStoryTitle('')
    }
  }, [postData])

  return (
    <>
      {deviceType === DeviceType.Mobile && isFullPageMobileRoute && (
        <Routes>
          <Route
            path={`${ROUTES.ASSET}/create`}
            element={
              <CreateTopicPanelIntegration
                nickname={getCurrentUserData?.getCurrentUser?.nickname || ''}
              />
            }
          />
          <Route
            path={`${ROUTES.ASSET}/topic/:topicId`}
            element={
              <ViewTopicIntegration
                userId={getCurrentUserData?.getCurrentUser?.id || ''}
                nickname={getCurrentUserData?.getCurrentUser?.nickname || ''}
                replyNotificationEmail={
                  getCurrentUserData?.getCurrentUser?.replyNotificationEmail ||
                  false
                }
                setSignUpModalOpen={(isOpen: boolean) =>
                  setShowSignUpModal(isOpen)
                }
              />
            }
          />
          <Route
            path={`${ROUTES.ASSET}/edit/:editId`}
            element={<PostEditIntegration />}
          />
        </Routes>
      )}
      {(deviceType === DeviceType.Desktop || !isFullPageMobileRoute) && (
        <>
          <NavigationFrame
            signUp={() => window.open(ROUTES.SIGNUP, '_self')}
            logIn={() => window.open(ROUTES.LOGIN, '_self')}
            closeRightPanel={onCloseRightPanel}
            toggleLeftPanel={onToggleLeftPanel}
            isLoggedIn={!!getCurrentUserData?.getCurrentUser}
            nickname={getCurrentUserData?.getCurrentUser?.nickname || 'Guest'}
            email={getCurrentUserData?.getCurrentUser?.email || ''}
            userId={getCurrentUserData?.getCurrentUser?.id || ''}
            loading={
              status.numPendingQueries > 0 || status.numPendingMutations > 0
            }
            isStoryEdit={isStoryEdit}
            submitStory={submitStory}
            storyTitle={storyTitle}
          >
            <NavigationSidebar
              expanded={openNavigationSidebar}
              navMenuItems={navigationMenuItems}
              navFooterMenuItems={navigationFooterMenuItems}
              onMenuItemClick={onNavigationMenuItemClick}
            />
            <UserSidebarContent
              getCurrentUserData={getCurrentUserData}
              userSidebarMenuItems={userSidebarMenuItems}
              onMenuItemClick={onMenuItemClick}
              DEBUG_ENV={DEBUG_ENV}
              navigate={navigate}
              openUserSettingsSidebar={openUserSettingsSidebar}
              userSidebarTabItems={userSidebarTabItems}
              selectedtabItemIndex={selectedtabItemIndex}
              onBackClick={onBackClick}
              signOut={signOut}
              changePasswordError={changePasswordError}
              accountSave={accountSave}
              setPassword={setPassword}
              profileSave={profileSave}
              setOpenUserSettingsSidebar={setOpenUserSettingsSidebar}
              setSelectedtabItemIndex={setSelectedtabItemIndex}
            />
            <Routes>
              <Route
                path={`${ROUTES.STORY_EDIT}/*`}
                element={
                  <Main
                    {...memoizedProps}
                    setShowSignUpModal={setShowSignUpModal}
                    isStoryEdit={true}
                    filteredPostTypes={[PostType.Text, PostType.Url]}
                    selectedStoryPosts={selectedStoryPosts}
                    setSelectedStoryPosts={setSelectedStoryPosts}
                    submitStory={submitStory}
                  />
                }
              />
              <Route
                path={ROUTES.STORY}
                element={
                  <Main
                    {...memoizedProps}
                    setShowSignUpModal={setShowSignUpModal}
                    selectedStoryPosts={selectedStoryPosts}
                    setSelectedStoryPosts={setSelectedStoryPosts}
                    isStoryView={true}
                  />
                }
              />
              <Route
                path={`${ROUTES.ASSET}/*`}
                element={
                  <Main
                    {...memoizedProps}
                    setShowSignUpModal={setShowSignUpModal}
                  />
                }
              />
              <Route path={ROUTES.ABOUT} element={<About />} />
              <Route
                path={ROUTES.BULLS_AND_BEARS}
                element={<BullsAndBears />}
              />

              <Route path={ROUTES.SITE_RULES} element={<SiteRules />} />
              <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route
                path={ROUTES.TERMS_OF_SERVICE}
                element={<TermsofService domainUrl={window.location.origin} />}
              />
              <Route path={ROUTES.DISCLOSURES} element={<Disclosures />} />
              <Route
                path={ROUTES.HOME}
                element={
                  <Landing
                    userId={getCurrentUserData?.getCurrentUser?.id}
                    lastViewedSymbol={
                      getCurrentUserData?.getCurrentUser?.lastViewedSymbol
                    }
                    lastViewedSymbolType={
                      symbolData?.getSymbol?.type.toLowerCase() ||
                      AssetTypeEnum.stock
                    }
                  />
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            {/* </> */}
          </NavigationFrame>
        </>
      )}
      <SignUpModal open={showSignUpModal} onClose={handleSignUpModalClose} />
    </>
  )
}

export default Chart
