import useStyles from './styles'
import theme from '../../mui-theme'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import UserAvatar from '../UserAvatar'
import ComingSoonTooltip from '../common/ComingSoonTooltip'
import { USER_SIDEBAR_ACTION_NAMES } from 'constants/userSidebarConstants'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  name: string
  email: string
  menuItems: {
    icon: IconDefinition
    id: string
    text: string
  }[]
  onMenuItemClick: (
    id: string,
    setOpenUserSettingsSidebar: Dispatch<SetStateAction<boolean>>,
    setSelectedtabItemIndex: Dispatch<SetStateAction<number>>
  ) => void
  onUserLinkClick: () => void
  setOpenUserSettingsSidebar: Dispatch<SetStateAction<boolean>>
  setSelectedtabItemIndex: Dispatch<SetStateAction<number>>
}

export default function UserSidebar(props: Props) {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <div className={classes.profileDetails}>
        <div className={classes.avatar}>
          <UserAvatar email={props.email} />
        </div>
        <div className={classes.nameEmailWrapper}>
          <p className={classes.name} onClick={() => props.onUserLinkClick()}>
            {props.name}
          </p>
          <p className={classes.email} onClick={() => props.onUserLinkClick()}>
            {props.email}
          </p>
        </div>
      </div>

      <MenuList className={classes.menuList}>
        {props.menuItems.map((item, i) => (
          <MenuItem
            key={i}
            className={classes.menuOption}
            sx={{
              '.MuiTouchRipple-root': {
                display: 'none',
              },
            }}
            onClick={() =>
              props.onMenuItemClick(
                item.id,
                props.setOpenUserSettingsSidebar,
                props.setSelectedtabItemIndex
              )
            }
          >
            <>
              <div className={classes.menuOptionWrapper}>
                {item.id !== USER_SIDEBAR_ACTION_NAMES.PROFILE && (
                  <FontAwesomeIcon className={classes.icon} icon={item.icon} />
                )}
                {''}
                {item.id === USER_SIDEBAR_ACTION_NAMES.PROFILE && (
                  <>
                    <div className={classes.avatarWrapper}>
                      <UserAvatar email={props.email} size={60} />
                    </div>
                    {''}
                  </>
                )}
                {item.text}
              </div>
            </>
          </MenuItem>
        ))}
      </MenuList>
    </div>
  )
}
