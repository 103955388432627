import { useState, useEffect } from 'react'
import {
  useGetCurrentUserQuery,
  useUpdateNotificationSetttingsMutation,
} from 'generated/graphql'

export function useEmailNotification() {
  const [emailNotification, setEmailNotification] = useState(false)
  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })
  const [updateNotificationSettings] = useUpdateNotificationSetttingsMutation()

  // Effect to update local state when the current user data is fetched
  useEffect(() => {
    if (
      getCurrentUserData?.getCurrentUser?.replyNotificationEmail !== undefined
    ) {
      setEmailNotification(
        getCurrentUserData.getCurrentUser.replyNotificationEmail
      )
    }
  }, [getCurrentUserData])

  // Function to handle email notification change
  const handleEmailNotificationChange = (checked: boolean) => {
    updateNotificationSettings({
      variables: {
        data: {
          replyNotificationEmail: checked,
        },
      },
      update: (cache, { data }) => {
        const normalizedId = cache.identify({
          id: getCurrentUserData?.getCurrentUser.id,
          __typename: 'PrivateUser',
        })

        cache.modify({
          id: normalizedId,
          fields: {
            replyNotificationEmail() {
              return checked
            },
          },
        })
      },
    })
      .then(() => {
        // Update the state to reflect the change immediately
        setEmailNotification(checked)
      })
      .catch(error => {
        // Handle potential error here
        console.error('Failed to update notification settings', error)
      })
  }

  return { emailNotification, handleEmailNotificationChange }
}
