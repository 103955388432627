import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
import { colors, fonts } from '../../../constants'

export default makeStyles<typeof theme>(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.9)', // Change the opacity here
    },
  },
  iconClose: {
    position: 'absolute',
    right: 3,
    top: 3,
    cursor: 'pointer',
    color: colors.textGreyLight,
  },
  modalContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    paddingTop: 30,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: fonts.dm_sans,
    color: colors.white,
    fontStyle: 'normal',
    borderRadius: '10px',
    outline: 'none',
    width: '100%',
    maxWidth: '1122px',
    height: '100%',
    maxHeight: '629px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
  },
  youtubeContainer: {
    width: '100%',
    height: '100%',
  },
}))
