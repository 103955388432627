import React from 'react'
import { useParams } from 'react-router-dom'
import PricePredictionResultBox from 'components/PricePredictionResult'
import {
  useAddUserWatchedSymbolMutation,
  useGetPricePredictionVoteQuery,
} from 'generated/graphql'
import { setSuccess } from 'apollo/reactive-vars'

const PricePredictionResult: React.FC = () => {
  const { roundId } = useParams<'roundId'>()

  const [addUserWatchedSymbol] = useAddUserWatchedSymbolMutation()
  const { data: voteData } = useGetPricePredictionVoteQuery({
    variables: { roundId: roundId! },
    fetchPolicy: 'cache-first',
    skip: !roundId,
  })

  const addToWatchList = async () => {
    await addUserWatchedSymbol({
      variables: {
        symbol: voteData?.getPricePredictionVote?.round.symbol || '',
      },
    }).catch(e => {
      if (e.message.indexOf('duplicate key') > -1) {
        return
      } else {
        throw e
      }
    })

    setSuccess('Added to watchlist!')
  }

  return (
    <PricePredictionResultBox
      symbol={voteData?.getPricePredictionVote?.round?.symbol || ''}
      symbolName={
        voteData?.getPricePredictionVote?.round?.symbolObj?.name || ''
      }
      symbolType={
        voteData?.getPricePredictionVote?.round?.symbolObj?.type || ''
      }
      endClose={voteData?.getPricePredictionVote?.round.endClose || 0}
      endOfVoting={
        voteData?.getPricePredictionVote?.round.endOfVotingTimestamp || ''
      }
      higher={voteData?.getPricePredictionVote?.higher || undefined}
      win={voteData?.getPricePredictionVote?.win || false}
      addToWatchList={addToWatchList}
    />
  )
}

export default PricePredictionResult
