import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
import { colors } from '../../../constants'

export default makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -16,
    height: 'calc(100% - 130px)',

    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },

  header: {
    color: colors.white,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: 12,
  },
  tab: {
    minWidth: 66,
    minHeight: 28,
    height: 28,
    color: `${colors.white_faded} !important`,
    backgroundColor: colors.tab_bg,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    opacity: 0.5,
    boxSizing: 'border-box',
    padding: '6px 8px',
    borderRadius: 6,
    marginRight: 8,
    textTransform: 'none',

    '&:last-child': {
      marginRight: 0,
    },
  },
  selectedTab: {
    opacity: 1,
  },
  tabsScroller: {
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',

    [theme.breakpoints.down('lg')]: {
      borderBottom: `1px solid ${colors.drawer_border}`,
    },
  },
})
