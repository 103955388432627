import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

interface Props {
  unreadNotifications: number
  onClick?: () => void
}

const NotificationBell: React.FC<Props> = props => {
  const classes = useStyles(theme)

  return (
    <div className={classes.notificationWrapper} onClick={props.onClick}>
      <FontAwesomeIcon icon={faBell} size="sm" />
      {props.unreadNotifications > 0 && (
        <div className={classes.notificationCount}>
          {props.unreadNotifications}
        </div>
      )}
    </div>
  )
}

export default NotificationBell
