import React from 'react'

type HomeIconProps = {
  isSelected: boolean
}

const HomeIcon: React.FC<HomeIconProps> = ({ isSelected }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13.7535C7 13.3764 7.17413 13.0203 7.47185 12.7888L15.2496 6.7394C15.691 6.39612 16.309 6.39612 16.7504 6.7394L24.5281 12.7888C24.8259 13.0203 25 13.3764 25 13.7535V24.8889C25 25.5639 24.4528 26.1111 23.7778 26.1111H8.22222C7.54721 26.1111 7 25.5639 7 24.8889V13.7535Z"
      stroke={isSelected ? 'white' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={isSelected ? 'white' : 'none'}
    />
  </svg>
)

export default HomeIcon
