import { Auth } from '@aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import ResetPasswordBox from '../components/auth/ResetPasswordBox'
import CenteredBoxScreenWrapper from '../components/auth/common/CenteredBoxScreenWrapper'
import ROUTES from '../constants/routes'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../constants'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

const ResetPassword: React.FC = () => {
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>()

  const handleResetPassword = (email: string) => {
    Auth.forgotPassword(email)
      .then(() => {
        navigate(ROUTES.CREATE_NEW_PASSWORD.replace(':email', email))
      })
      .catch(error => {
        switch (error.code) {
          case 'UserNotFoundException':
            setError('No user found with this email address.')
            break
          case 'LimitExceededException':
            setError('Attempt limit exceeded, please try after some time.')
            break
          default:
            setError('Something went wrong.')
            break
        }
      })
  }

  function onClose() {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  return (
    <>
      {deviceType === DeviceType.Mobile && (
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          style={{
            position: 'absolute',
            top: 24,
            right: 24,
            cursor: 'pointer',
          }}
          color={colors.textGrey}
          onClick={onClose}
        />
      )}
      <CenteredBoxScreenWrapper>
        <ResetPasswordBox
          onSubmit={handleResetPassword}
          errors={{ email: error }}
        />
      </CenteredBoxScreenWrapper>
    </>
  )
}

export default ResetPassword
