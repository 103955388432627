import { Box } from '@mui/material'
import NotificationSettingsPanel from 'components/NotificationSettingsPanel'
import UserSettingsSidebar from 'components/UserSettingsSidebar'
import AccountSettingsPanel, {
  AccountSettingsInput,
} from 'components/UserSettingsSidebar/AccountSettingsPanel'
import ProfileSettingsPanel, {
  ProfileSettingsInput,
} from 'components/UserSettingsSidebar/ProfileSettingsPanel'
import UserSidebar from 'components/UserSidebar'
import React, { Dispatch, SetStateAction } from 'react'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface UserSidebarContentProps {
  getCurrentUserData: any
  userSidebarMenuItems: any[]
  onMenuItemClick: (
    id: string,
    setOpenUserSettingsSidebar: Dispatch<SetStateAction<boolean>>,
    setSelectedtabItemIndex: Dispatch<SetStateAction<number>>
  ) => void
  DEBUG_ENV: boolean
  navigate: (path: string) => void
  openUserSettingsSidebar: boolean
  userSidebarTabItems: any[]
  selectedtabItemIndex: number
  onBackClick: () => void
  signOut: () => Promise<false | undefined>
  changePasswordError: string
  accountSave: (input: AccountSettingsInput) => void
  setPassword: (oldPassword: string, newPassword: string) => Promise<boolean>
  profileSave: (input: ProfileSettingsInput) => void
  setOpenUserSettingsSidebar: Dispatch<SetStateAction<boolean>>
  setSelectedtabItemIndex: Dispatch<SetStateAction<number>>
}

const UserSidebarContent: React.FC<UserSidebarContentProps> = ({
  getCurrentUserData,
  userSidebarMenuItems,
  onMenuItemClick,
  DEBUG_ENV,
  navigate,
  openUserSettingsSidebar,
  userSidebarTabItems,
  selectedtabItemIndex,
  onBackClick,
  signOut,
  changePasswordError,
  accountSave,
  setPassword,
  profileSave,
  setOpenUserSettingsSidebar,
  setSelectedtabItemIndex,
}) => {
  const deviceType = useDeviceType()

  return (
    <>
      {!openUserSettingsSidebar && (
        <UserSidebar
          name={getCurrentUserData?.getCurrentUser?.nickname || 'Guest'}
          email={getCurrentUserData?.getCurrentUser?.email || ''}
          menuItems={userSidebarMenuItems}
          onMenuItemClick={onMenuItemClick}
          onUserLinkClick={() => {
            if (DEBUG_ENV) console.debug(`onUserLinkClick`)
            navigate(`/user/${getCurrentUserData?.getCurrentUser?.nickname}`)
          }}
          setOpenUserSettingsSidebar={setOpenUserSettingsSidebar}
          setSelectedtabItemIndex={setSelectedtabItemIndex}
        />
      )}
      {openUserSettingsSidebar && (
        <UserSettingsSidebar
          name={getCurrentUserData?.getCurrentUser?.nickname || 'Guest'}
          email={getCurrentUserData?.getCurrentUser?.email || ''}
          tabItems={userSidebarTabItems}
          selectedTabItemIndex={selectedtabItemIndex}
          onBackClick={onBackClick}
          onSignOutClick={signOut}
          onUserLinkClick={() => {
            if (DEBUG_ENV) console.debug(`onUserLinkClick`)
            navigate(`/user/${getCurrentUserData?.getCurrentUser?.nickname}`)
          }}
        >
          <Box
            sx={{
              height: '100%',
              padding:
                deviceType === DeviceType.Mobile ? 0 : '18px 32px 14px 32px',
            }}
          >
            <AccountSettingsPanel
              id={getCurrentUserData?.getCurrentUser?.id || ''}
              email={getCurrentUserData?.getCurrentUser?.email || ''}
              timezone={getCurrentUserData?.getCurrentUser?.timeZone || ''}
              social={getCurrentUserData?.getCurrentUser?.social}
              password_error={changePasswordError}
              onAccountSaveClick={accountSave}
              onPasswordChange={setPassword}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              padding:
                deviceType === DeviceType.Mobile ? 0 : '18px 32px 14px 32px',
            }}
          >
            <ProfileSettingsPanel
              id={getCurrentUserData?.getCurrentUser?.id || ''}
              username={getCurrentUserData?.getCurrentUser?.nickname || ''}
              reddit={getCurrentUserData?.getCurrentUser?.reddit || ''}
              twitter={getCurrentUserData?.getCurrentUser?.twitter || ''}
              facebook={getCurrentUserData?.getCurrentUser?.facebook || ''}
              instagram={getCurrentUserData?.getCurrentUser?.instagram || ''}
              tradingView={
                getCurrentUserData?.getCurrentUser?.tradingView || ''
              }
              onProfileSaveClick={profileSave}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              padding:
                deviceType === DeviceType.Mobile ? 0 : '18px 32px 14px 32px',
            }}
          >
            <NotificationSettingsPanel />
          </Box>
        </UserSettingsSidebar>
      )}
    </>
  )
}

export default UserSidebarContent
