import { makeStyles } from '@mui/styles'
import theme from '../../mui-theme'
import { colors } from '../../constants'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: colors.search_bg,
    position: 'absolute',
  },
}))
