import { colors } from '../../constants'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useDeviceType } from 'utils/screenSizeUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { Button } from '@mui/material'

interface Props {
  symbol: string
  symbolName: string
  symbolType: string
  endClose: number
  endOfVoting: Date
  higher: boolean | undefined
  win: boolean
  addToWatchList: () => void
}

export default function PricePredictionResultBox(props: Props) {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const onClose = () => {
    navigate(-1)
  }

  const goToSymbol = () => {
    navigate(
      ROUTES.ASSET.replace(':symbol', props.symbol.toLowerCase())
        .replace(':type', props.symbolType.toLowerCase())
        .toLowerCase()
    )
  }

  const goToNextChallenge = () => {
    navigate(ROUTES.PRICE_PREDICTION)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.header}>
          <div style={{ width: 20 }}>&nbsp;</div>
          <div className={classes.headerText}>Price Prediction</div>
          <FontAwesomeIcon
            icon={faClose}
            className={classes.iconClose}
            color={colors.textGrey}
            onClick={onClose}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.symbol}>{props.symbol}</div>
          <div className={classes.symbolName}>{props.symbolName}</div>
          <div className={classes.today}>Today</div>
          <div className={classes.endClose}>{props.endClose} USD</div>
          {props.win && (
            <img
              className={classes.emoji}
              src={'/media/PartyPopper.png'}
              alt="Party Popper"
            />
          )}
          {!props.win && (
            <img
              className={classes.emoji}
              src={'/media/SweatingEmoji.png'}
              alt="Party Popper"
            />
          )}
          <div className={classes.resultMessage}>
            {props.win ? 'Congrats! Price ' : 'Oh no! Price '}
            {props.higher ? 'inceased' : 'decreased'}
          </div>
          <div className={classes.checkLatestPosts} onClick={goToSymbol}>
            Check out the latest posts
          </div>
        </div>
        <Button
          className={classes.playNextChallengeButton}
          onClick={goToNextChallenge}
          sx={{
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          }}
        >
          PLAY NEXT CHALLENGE
        </Button>
      </div>
    </div>
  )
}
