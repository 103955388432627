import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'

export const convertVoteDirection = (direction: VoteDirection) => {
  let directionResult: boolean | null = null

  if (direction === VoteDirection.down) {
    directionResult = false
  }

  if (direction === VoteDirection.up) {
    directionResult = true
  }

  return directionResult
}

export const convertToVoteDirection = (
  userVote?: boolean | null
): VoteDirection | null => {
  if (userVote === true) {
    return VoteDirection.up
  }

  if (userVote === false) {
    return VoteDirection.down
  }

  return null
}
