import { forwardRef } from 'react'
import theme from '../../../../mui-theme'
import useStyles from './styles'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScroll from 'react-infinite-scroll-component'
import CommentWithParent from 'components/common/CommentWithParent'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'

export interface CommentsProps {
  userId?: string
  comments: CommentData[]
  hasMoreComments: boolean
  onVote: (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  loadMoreComments: () => void
  onPostClick: (id: string, symbol: string) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
}

export default forwardRef<HTMLInputElement, CommentsProps>((props, ref) => {
  const classes = useStyles(theme)

  return (
    <div id="tab-content" className={classes.tabContent}>
      <InfiniteScroll
        dataLength={props.comments.length}
        next={props.loadMoreComments}
        hasMore={props.hasMoreComments}
        loader={<div>Loading more comments...</div>}
        className={classes.wrapper}
        scrollableTarget="tab-content"
      >
        {props.comments.length === 0 && (
          <div className={classes.noCommentsWrapper}>
            <FontAwesomeIcon
              icon={faComments}
              size="lg"
              className={classes.noCommentsCommentsIcon}
            />
            <div className={classes.noCommentsText}>
              hmm... looks like there are no comments yet
            </div>
          </div>
        )}
        {props.comments.map((comment: CommentData, index: number) => (
          <div className={classes.commentWrapper}>
            <CommentWithParent
              userId={props.userId}
              comment={comment}
              index={index}
              onVote={props.onVote}
              onShareActionsClick={props.onShareActionsClick}
              onCameraActionsClick={props.onCameraActionsClick}
              onCameraActionsCommentClick={props.onCameraActionsCommentClick}
              onMoreActionsClick={props.onMoreActionsClick}
              onMoreCommentActionsClick={props.onMoreCommentActionsClick}
              onPostClick={props.onPostClick}
              onCommentClick={props.onCommentClick}
            />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
})
