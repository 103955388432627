import { useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useGetCurrentUserQuery } from '../generated/graphql'
import CreateNewPassword from '../screens/CreateNewPassword'
import Login from '../screens/Login'
import RessetPassword from '../screens/ResetPassword'
import ResetPasswordSuccess from '../screens/ResetPasswordSuccess'
import SignUp from '../screens/SignUp'

import { Hub } from '@aws-amplify/core'
import ConfirmSignUp from 'screens/ConfirmSignUp'
import CreateNickname from 'screens/CreateNickname'
import RedirectSignin from 'screens/RedirectSignin/RedirectSignin'
import RedirectSignout from 'screens/RedirectSignout'
import SentimentDiscovery from 'screens/SentimentDiscovery/SentimentDiscovery'
import VerifyEmailComplete from 'screens/VerifyEmailComplete'
import gAnalytics from 'utils/analytics/ga'
import { DEBUG_ENV } from '../index'
import User from 'screens/User/User'
import NotFound from 'screens/NotFound/NotFound'
import Notifications from 'screens/Notifications/Notifications'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import MobileNav from 'components/MobileNav'
import Chart from '../screens/Chart'
import Home from 'screens/Home/Home'
import RedirectSigninMobile from 'screens/RedirectSigninMobile/RedirectSigninMobile'
import UserMenuMobile from 'screens/UserMenuMobile/UserMenuMobile'
import UserSettingsMobile from 'screens/UserSettingsMobile/UserSettingsMobile'
import UserSettingsEditMobile from 'screens/UserSettingsEditMobile/UserSettingsEditMobile'
import { DeleteUserMobile } from 'screens/DeleteUser/DeleteUser'
import { Unsubscribe } from 'screens/Unsubscribe/Unsubscribe'
import PostGraphic from 'screens/PostGraphic/PostGraphic'
import CommentGraphic from 'screens/CommentGraphic/CommentGraphic'
import PricePrediction from 'screens/PricePrediction'
import PricePredictionResult from 'screens/PricePredictionResult'

export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  ACCOUNT_CREATED: '/account-created',
  BULLS_AND_BEARS: '/bullsandbears',
  NOT_FOUND: '/not-found',
  CONTACT_US: '/contact-us',
  SITE_RULES: '/site-rules',
  SENTIMENT_DISCOVERY: '/sentiment-discovery',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONFIRM_SIGNUP: '/confirm-signup/:email',
  CONFIRM_SIGNUP_CODE: '/confirm-signup/:email/:code',
  CREATE_USER_AND_PASS: '/create-user-and-pass',
  TEST_WITHOUT_PARAMS: '/test',
  ASSET: '/:type/:symbol',
  RESSET_PASSWORD: '/reset-password',
  RESSET_PASSWORD_SUCCESS: '/reset-password-success',
  CREATE_NEW_PASSWORD: '/create-new-password/:email',
  CREATE_NICKNAME: '/create-nickname',
  VERIFY_EMAIL_COMPLETE: `/verify-email-complete`,
  REDIRECT_SIGNIN: `/redirect-signin`,
  REDIRECT_SIGNIN_MOBILE: `/redirect-signin-mobile`,
  REDIRECT_SIGNOUT: `/redirect-signout`,
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  POST_GRAPHIC: '/:type/:symbol/topic-graphic/:topicId',
  COMMENT_GRAPHIC: '/:type/:symbol/comment-graphic/:topicId',
  STORY: '/:type/:symbol/story/:storyId',
  STORY_EDIT: '/:type/:symbol/story-edit/:storyId',
  DISCLOSURES: '/disclosures',
  USER: '/user/:username',
  USER_MENU_MOBILE: '/user-menu-mobile',
  USER_SETTINGS_MOBILE: '/user-settings-mobile',
  USER_SETTINGS_EDIT_MOBILE: '/user-settings-edit-mobile',
  PRICE_PREDICTION: '/price-prediction',
  PRICE_PREDICTION_RESULT: '/price-prediction-result/:roundId',
  DELETE_USER: '/delete-user',
  NOTIFICATIONS: '/notifications',
  UNSUBSCRIBE: '/unsubscribe',
} as const

export default ROUTES

//Check in routes Main Page where if User is Logged in, navigate to create username, else sen disc, else default symbol ticker

const trace = `---> ROUTES -- `

const mobileNavExclusionList = [
  ROUTES.USER_MENU_MOBILE,
  ROUTES.USER_SETTINGS_MOBILE,
  ROUTES.USER_SETTINGS_EDIT_MOBILE,
  ROUTES.DELETE_USER,
]

export const AppRoutes = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const deviceType = useDeviceType()

  const { data: user, loading } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  })

  const currentUser = useMemo(() => {
    if (user?.getCurrentUser) {
      const { id, nickname, lastViewedSymbol } = user.getCurrentUser
      return { id, nickname, lastViewedSymbol }
    }
    return null
  }, [
    user?.getCurrentUser?.id,
    user?.getCurrentUser?.nickname,
    user?.getCurrentUser?.lastViewedSymbol,
  ])

  useEffect(() => {
    if (loading) {
      if (DEBUG_ENV) console.debug(trace + ' LOADING...')
      return
    }

    if (currentUser && !currentUser.nickname) {
      navigate(ROUTES.CREATE_NICKNAME)
    } else if (
      currentUser &&
      currentUser.nickname &&
      !currentUser.lastViewedSymbol
    ) {
      navigate(ROUTES.SENTIMENT_DISCOVERY)
    }
  }, [currentUser, navigate, loading])

  // get code and state query string parameters from URL
  const url = new URL(window.location.href)

  const code = url.searchParams.get('code')
  const state = url.searchParams.get('state')

  // if code and state are present, then user has just signed in via social provider
  // the main reason for this if statement is to prevent the addition of the hash to the URL (else if below)
  // which causes the page to reload before amplify can process the auth event
  if (code && state) {
    Hub.listen('auth', data => {
      if (DEBUG_ENV)
        console.debug(
          trace + 'A new auth event has happened:',
          data.payload.data
        )
    })
  }

  if (!!user?.getCurrentUser?.id) {
    const usr = user.getCurrentUser
    gAnalytics.identify(usr.id, {
      username: usr.nickname,
    })
  } else {
    if (DEBUG_ENV) console.debug(trace + 'NO USER')
  }

  //if (DEBUG_ENV) console.debug(`INSIDE gAnalytics.page() -- path: ${location.pathname}`)
  gAnalytics.page({ path: location.pathname })

  const shouldExcludeMobileNav = mobileNavExclusionList.some(
    route =>
      route === location.pathname ||
      new RegExp('^' + route.replace(/:\w+/g, '\\w+') + '$').test(
        location.pathname
      )
  )

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2,
        flexDirection: 'column',
      }}
    >
      <div
        style={
          deviceType === DeviceType.Mobile
            ? {
                height: shouldExcludeMobileNav ? '100%' : 'calc(100% - 73px)',
                position: 'relative',
                zIndex: 100,
              }
            : {
                height: '100%',
              }
        }
      >
        {!!user?.getCurrentUser ? (
          <Routes>
            <Route
              path={ROUTES.USER_MENU_MOBILE}
              element={<UserMenuMobile />}
            />
            <Route
              path={ROUTES.PRICE_PREDICTION}
              element={<PricePrediction />}
            />
            <Route path={ROUTES.UNSUBSCRIBE} element={<Unsubscribe />} />
            <Route
              path={ROUTES.PRICE_PREDICTION_RESULT}
              element={<PricePredictionResult />}
            />
            <Route path={ROUTES.POST_GRAPHIC} element={<PostGraphic />} />
            <Route path={ROUTES.COMMENT_GRAPHIC} element={<CommentGraphic />} />
            <Route path={ROUTES.DELETE_USER} element={<DeleteUserMobile />} />
            <Route
              path={ROUTES.USER_SETTINGS_EDIT_MOBILE}
              element={<UserSettingsEditMobile />}
            />
            <Route
              path={ROUTES.USER_SETTINGS_MOBILE}
              element={<UserSettingsMobile />}
            />
            <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
            <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
            <Route
              path={ROUTES.SENTIMENT_DISCOVERY}
              element={<SentimentDiscovery />}
            />{' '}
            <Route path={ROUTES.USER} element={<User />} />
            <Route path={ROUTES.CREATE_NICKNAME} element={<CreateNickname />} />
            <Route
              path={ROUTES.VERIFY_EMAIL_COMPLETE}
              element={<VerifyEmailComplete />}
            />
            <Route
              path={ROUTES.REDIRECT_SIGNOUT}
              element={<RedirectSignout />}
            />
            <Route path="/*" element={<Chart />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={ROUTES.UNSUBSCRIBE} element={<Unsubscribe />} />
            <Route
              path={ROUTES.USER_MENU_MOBILE}
              element={<UserMenuMobile />}
            />
            <Route
              path={ROUTES.PRICE_PREDICTION}
              element={<PricePrediction />}
            />
            <Route
              path={ROUTES.USER_MENU_MOBILE}
              element={<UserMenuMobile />}
            />
            <Route path={ROUTES.POST_GRAPHIC} element={<PostGraphic />} />
            <Route path={ROUTES.COMMENT_GRAPHIC} element={<CommentGraphic />} />
            <Route path={ROUTES.DELETE_USER} element={<DeleteUserMobile />} />
            <Route
              path={ROUTES.USER_SETTINGS_EDIT_MOBILE}
              element={<UserSettingsEditMobile />}
            />
            <Route
              path={ROUTES.USER_SETTINGS_MOBILE}
              element={<UserSettingsMobile />}
            />
            <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
            <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.SIGNUP} element={<SignUp />} />
            <Route
              path={ROUTES.CONFIRM_SIGNUP_CODE}
              element={<ConfirmSignUp />}
            />
            <Route path={ROUTES.CONFIRM_SIGNUP} element={<ConfirmSignUp />} />
            <Route path={ROUTES.REDIRECT_SIGNIN} element={<RedirectSignin />} />
            <Route
              path={ROUTES.REDIRECT_SIGNIN_MOBILE}
              element={<RedirectSigninMobile />}
            />
            <Route
              path={ROUTES.REDIRECT_SIGNOUT}
              element={<RedirectSignout />}
            />
            {/*<Route path={ROUTES.TEST} element={<ConfirmSignUp />} /> */}
            <Route
              path={ROUTES.VERIFY_EMAIL_COMPLETE}
              element={<VerifyEmailComplete />}
            />
            <Route path={ROUTES.RESSET_PASSWORD} element={<RessetPassword />} />
            <Route
              path={ROUTES.CREATE_NEW_PASSWORD}
              element={<CreateNewPassword />}
            />
            <Route
              path={ROUTES.RESSET_PASSWORD_SUCCESS}
              element={<ResetPasswordSuccess />}
            />
            <Route
              path={ROUTES.SENTIMENT_DISCOVERY}
              element={<SentimentDiscovery />}
            />
            <Route path={ROUTES.USER} element={<User />} />
            <Route path={ROUTES.CREATE_NICKNAME} element={<CreateNickname />} />
            <Route path="/*" element={<Chart />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </div>
      {deviceType === DeviceType.Mobile && !shouldExcludeMobileNav && (
        <MobileNav />
      )}
    </div>
  )
}
