import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectSignout: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // for now we just redirect to the home page
    // we may want to add a "you have been signed out" message in the future
    navigate('/')
  }, [])

  return <></>
}

export default RedirectSignout
