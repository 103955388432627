import { Dispatch, SetStateAction, useState } from 'react'
import Auth from '@aws-amplify/auth'
import { useApolloClient } from '@apollo/client'
import { setApolloError, setSuccess } from 'apollo/reactive-vars'
import {
  useGetCurrentUserQuery,
  useUpdateUserAccountMutation,
  useUpdateUserProfileMutation,
} from 'generated/graphql'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { DEBUG_ENV } from 'index'
import { ProfileSettingsInput } from 'components/UserSettingsSidebar/ProfileSettingsPanel'
import { AccountSettingsInput } from 'components/UserSettingsSidebar/AccountSettingsPanel'
import { USER_SIDEBAR_ACTION_NAMES } from 'constants/userSidebarConstants'

export const useUserOperations = () => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const [changePasswordError, setChangePasswordError] = useState<string>('')

  const [updateUserProfile] = useUpdateUserProfileMutation()
  const [updateUserAccount] = useUpdateUserAccountMutation()
  const { data: getCurrentUserData, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  type OnMenuItemClick = (
    id: string,
    setOpenUserSettingsSidebar: Dispatch<SetStateAction<boolean>>,
    setSelectedtabItemIndex: Dispatch<SetStateAction<number>>
  ) => void

  const setPassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<boolean> => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(cognitoUser, oldPassword, newPassword)
      setSuccess('Password changed successfully')
      return true
    } catch (error: any) {
      setApolloError(error.message)
      setChangePasswordError(error.message)
      return false
    }
  }

  const accountSave = async (input: AccountSettingsInput): Promise<void> => {
    try {
      await updateUserAccount({
        variables: {
          data: {
            id: input.id,
            timeZone: input.timezone,
          },
        },
      })
      setSuccess('Account updated successfully')
      refetch()
    } catch (error) {
      setApolloError('Error updating account')
    }
  }

  const profileSave = async (input: ProfileSettingsInput): Promise<void> => {
    try {
      await updateUserProfile({
        variables: {
          data: {
            id: input.id,
            nickname: input.username,
            reddit: input.reddit || null,
            twitter: input.twitter || null,
            facebook: input.facebook || null,
            instagram: input.instagram || null,
            tradingView: input.tradingView || null,
          },
        },
      })
      setSuccess('Profile updated successfully')
      refetch()
    } catch (error) {
      setApolloError('Error updating profile')
    }
  }

  const signOut = async (): Promise<false | undefined> => {
    try {
      // Sign out from the authentication service
      await Auth.signOut()
      if (DEBUG_ENV) console.debug('LOGOUT')

      // Clear the token from local storage
      window.localStorage.removeItem('idToken')

      // Reset the Apollo client store to clear the cache
      await client.clearStore()
    } catch (e) {
      console.error('Error during sign out:', e)
      return false
    }

    try {
      await Auth.currentAuthenticatedUser().then(res => {
        if (DEBUG_ENV) console.debug(`currentAuthUser():`)
        console.table(res)
      })

      // Refetch the user to update the app state
      refetch()

      // Navigate to the sign-out redirect route
      navigate(ROUTES.REDIRECT_SIGNOUT)
    } catch (e) {
      // Log out was successful
      console.info('User logged out successfully...')

      // Refetch the user to update the app state
      refetch()

      // Redirect to the sign-out page
      window.open(ROUTES.REDIRECT_SIGNOUT, '_self')
    }
  }

  const onMenuItemClick: OnMenuItemClick = (
    id,
    setOpenUserSettingsSidebar,
    setSelectedtabItemIndex
  ) => {
    switch (id) {
      case USER_SIDEBAR_ACTION_NAMES.PROFILE:
        navigate(
          ROUTES.USER.replace(
            ':username',
            getCurrentUserData?.getCurrentUser?.nickname || ''
          ).toLowerCase()
        )
        break
      case USER_SIDEBAR_ACTION_NAMES.SETTINGS:
        setOpenUserSettingsSidebar(true)
        setSelectedtabItemIndex(0)
        break
      case USER_SIDEBAR_ACTION_NAMES.SIGNOUT:
        signOut()
        break
    }
  }

  return {
    setPassword,
    accountSave,
    profileSave,
    signOut,
    changePasswordError,
    onMenuItemClick,
  }
}
