import { TypedTypePolicies } from '../generated/type-policies'
// import { FieldMergeFunction, FieldReadFunction } from '@apollo/client';

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      assetData: {
        keyArgs: ['assetType', 'code'],
        merge(existing: any[] = [], incoming) {
          return [
            ...existing,
            ...incoming.filter(
              (item: any) =>
                !existing.find(
                  existingItem => existingItem.__ref === item.__ref
                )
            ),
          ]
        },
        read(existing: any[], options) {
          if (existing) {
            const { readField } = options
            // TODO: Figure out a way to sort it on load/merge instead of on read.
            return [...existing].sort((itema: any, itemb: any) => {
              const a = readField('date', itema) as string
              const b = readField('date', itemb) as string
              return a.localeCompare(b)
            })
          }

          return existing
        },
      },
      // posts: {
      // keyArgs: ['type', 'fromDate'],
      // merge(existing: any[] = [], incoming) {
      //   return [
      //     ...existing,
      //     ...incoming.filter(
      //       (item: any) =>
      //         !existing.find(
      //           existingItem => existingItem.__ref === item.__ref
      //         )
      //     ),
      //   ]
      // },
      // },
    },
  },
}

export interface DateResponse {
  date: string
  confidence: string
  __typename: 'DateResponse' // Make sure this matches the actual GraphQL type
}

//Merges previous res with new res therefore data is stale in the sense that old data will stay
