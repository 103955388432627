import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 'calc(100% - 170px)',
    },
  },

  tabs: {
    height: 28,
    minHeight: 28,
    borderBottom: `1px solid ${colors.drawer_border}`,

    [theme.breakpoints.down('lg')]: {
      padding: '0 16px',
    },
  },

  tab: {
    display: 'inline-block',
    verticalAlign: 'top',
    height: 28,
    minHeight: 28,
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: 'auto',
    padding: 0,
    marginRight: 32,
    textTransform: 'capitalize',
  },

  selectedTab: {
    color: `${colors.text_white} !important`,

    '& svg': {
      color: colors.text_white,
    },
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
    bottom: 0,
  },

  tabContent: {
    paddingTop: 24,
  },
})
