import TextInput from '../inputs/Text'
import MultiLineTextInput from '../inputs/MultiLineText'
import SpaceBetween from '../auth/common/SpaceBetween'
import { useForm } from 'react-hook-form'
import { colors, fonts, regex } from '../../constants'
import Box from '@mui/material/Box'
import useStyles from './styles'
import theme from '../../mui-theme'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { BrandButton } from 'components/common/BrandButton'

interface Props {
  messageSent: boolean
  onSubmit: (data: ContactUsForm) => void
}

export interface ContactUsForm {
  firstName: string
  lastName: string
  email: string
  message: string
}

export default function ContactUsBox(props: Props) {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const { register, handleSubmit, errors } = useForm<ContactUsForm>()

  const onSubmit = (data: ContactUsForm) => {
    props.onSubmit(data as ContactUsForm)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            flexDirection: 'column',
            color: colors.text_white,
            fontFamily: fonts.dm_sans,
          }}
        >
          <div className={classes.title}>
            We'd love to hear your feedback or just say hello.
          </div>
          <div className={classes.subTitle}>
            We are currently working on Beta. We would appreciate your feedback
            or any comments.
          </div>
          <Box width={deviceType === DeviceType.Mobile ? 300 : 360}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SpaceBetween spaceSize={3} sx={{ mb: 3, mt: 3 }}>
                <TextInput
                  name="firstName"
                  label="First name"
                  ref={register({
                    required:
                      'Please enter your first name. This is a required field.',
                  })}
                  error={
                    errors.firstName ? errors.firstName.message : undefined
                  }
                />
                <TextInput
                  name="lastName"
                  label="Last name"
                  ref={register({
                    required:
                      'Please enter the last name. This is a required field.',
                  })}
                  error={errors.lastName ? errors.lastName.message : undefined}
                />
                <TextInput
                  name="email"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  ref={register({
                    required:
                      'Please enter your email address. This is a required field.',
                    pattern: {
                      value: regex.email,
                      message: 'You have entered an invalid email address.',
                    },
                  })}
                  error={errors.email ? errors.email.message : undefined}
                />
                <MultiLineTextInput
                  name="message"
                  label="Message"
                  rows={deviceType === DeviceType.Mobile ? 3 : 7}
                  ref={register({
                    required: 'The message field is required',
                  })}
                  error={errors.message ? errors.message.message : undefined}
                />
              </SpaceBetween>
              <BrandButton variant="contained" type="submit">
                Send
              </BrandButton>
            </form>
          </Box>
          {props.messageSent && (
            <div className={classes.messageSent}>
              Your message was sent. Thank you!
            </div>
          )}
        </Box>
      </div>
    </div>
  )
}
