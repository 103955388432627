import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { setApolloError } from 'apollo/reactive-vars'
import { UserCreator } from 'auth/userAuth'
import CenteredBoxScreenWrapper from 'components/auth/common/CenteredBoxScreenWrapper'
import ROUTES from 'constants/routes'
import {
  CheckDuplicateUserInput,
  useCheckDuplicateUserLazyQuery,
} from 'generated/graphql'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gaSignupEvent } from 'utils/analytics/ga'

import SocialSignUpPage from 'components/auth/SocialSignUpPage/SocialSignUpPage'
import ManualSignUpPage from './ManualSignUpPage'
import { colors } from '../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

export default function SignUp() {
  const deviceType = useDeviceType()
  const navigate = useNavigate()

  const [emailInput, setEmailInput] = useState<string>('')

  const [emailNotValidated, setEmailNotValidated] = useState<boolean>(true)

  const [checkDuplicateUser, { loading, data }] =
    useCheckDuplicateUserLazyQuery()

  const checkNickname = async (nickname: string) => {
    try {
      const result = await checkDuplicateUser({
        variables: {
          data: {
            nickname,
          } as CheckDuplicateUserInput,
        },
      }).then(result => {
        //if (DEBUG_ENV) console.debug('username check finished!')
        //console.table(result)
        if (!!result.data?.checkDuplicateUser) {
          setApolloError(`This username is taken. Please pick a different one.`)
        }

        return result.data?.checkDuplicateUser
      })

      return result
    } catch (err: any) {
      // TODO: Fix error-handling -- distinguish between if user exists vs somethinge else -- if user exists don't show error
      const code = err?.graphQLErrors[0]?.extensions?.code
    }
  }

  const createUser = async (userData: UserCreator) => {
    const { email, password, username: nickname } = userData

    await gaSignupEvent(
      'create_user_start',
      'sign_up___manual_start',
      'attempt'
    )

    try {
      const noDuplicateUser = await checkNickname(nickname)
      if (!noDuplicateUser) {
        const sub = await Auth.signUp({
          password,
          username: email,
          attributes: {
            email,
            nickname,
          },
        })

        try {
          await gaSignupEvent('create_user_finish', 'sign_up___manual_finish')
        } catch (error) {
          console.error(`Google analytics failed to record the signup event.`)
        }

        navigate(ROUTES.CONFIRM_SIGNUP.replace(':email', email))
      }
    } catch (error) {
      try {
        await gaSignupEvent('error', 'sign_up___error', 'fail')
      } catch (error) {
        console.error(
          `There was an error creating the user, and google analytics failed to record the signup_error event.`
        )
      }
    }
  }

  function onClose() {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  useEffect(() => {
    gaSignupEvent('page_visit', 'sign_up___page_visit').catch(err => {
      console.error(
        `Google analytics failed to record the signup page-visit event.`
      )
    })
  }, [])

  return (
    <>
      {deviceType === DeviceType.Mobile && (
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          style={{
            position: 'absolute',
            top: 24,
            right: 24,
            cursor: 'pointer',
          }}
          color={colors.textGrey}
          onClick={onClose}
        />
      )}
      <CenteredBoxScreenWrapper>
        {emailNotValidated ? (
          <SocialSignUpPage
            onGoToLogIn={() => navigate(ROUTES.LOGIN)}
            emailInput={emailInput}
            setEmailInput={setEmailInput}
            setEmailNotValidated={setEmailNotValidated}
            onSocial={provider =>
              Auth.federatedSignIn({
                provider:
                  provider === 'facebook'
                    ? CognitoHostedUIIdentityProvider.Facebook
                    : CognitoHostedUIIdentityProvider.Google,
              })
            }
            onSubmit={() => {}}
          />
        ) : (
          <ManualSignUpPage
            emailInput={emailInput}
            setEmailNotValidated={setEmailNotValidated}
            onSubmit={createUser}
          />
        )}
      </CenteredBoxScreenWrapper>
    </>
  )
}
