import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles<typeof theme>(
  theme => ({
    form: {
      width: '100%',

      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
      },
    },

    wrapper: {
      width: '100%',
      boxSizing: 'border-box',

      [theme.breakpoints.down('lg')]: {
        boxSizing: 'border-box',
        padding: 0,
        maxWidth: '100%',
        height: '100%',
      },
    },

    formFieldsWrapper: {},

    inputWrapper: {
      marginBottom: 16,
      width: '100%',
      flex: 1,

      [theme.breakpoints.down('lg')]: {
        marginBottom: 26,
        maxWidth: '100%',
      },
    },

    buttonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column-reverse',
        boxSizing: 'border-box',
      },
    },

    saveButton: {
      borderRadius: 0,
      marginLeft: 8,
      width: '81px',
      padding: '10px 0',
      marginBottom: 16,

      [theme.breakpoints.down('lg')]: {
        width: '100%',
        margin: 0,
        borderRadius: 8,
        height: 46,
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '14px',
        letterSpacing: 2.75,
      },
    },

    cancelButton: {
      width: '81px',
      marginBottom: 16,
      padding: '10px 0',
      backgroundColor: colors.drawer_bg_right,

      [theme.breakpoints.down('lg')]: {
        width: '100%',
        margin: '16px 0 0 0',
        borderRadius: 8,
        height: 46,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        textTransform: 'none',
        letterSpacing: 0,
      },
    },

    externalProviderWrapper: {
      display: 'flex',
    },

    externalProviderCross: {
      color: colors.red_light,
      marginRight: 8,
    },

    externalProviderText: {
      fontSize: 14,
      fontFamily: fonts.dm_sans,
      color: colors.textGreyLight,
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
  { index: 1 }
)
