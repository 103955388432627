import { dateToExDate } from '../constants'
import moment from 'moment'

export function getTimeElapsedRounded(
  postDate: Date,
  createdDate: Date = new Date(),
  daysBeforeDateTimeShown: number = 1
) {
  var now = moment()

  // Check if postDate is today, if not, handle as postDate immediately
  if (!moment(postDate).isSame(now, 'day')) {
    let dateTimeFrom = dateToExDate(postDate) // Convert postDate to exDate

    // If the date is beyond the daysBeforeDateTimeShown, return the date
    if (now.diff(dateTimeFrom.datetime, 'days') > daysBeforeDateTimeShown - 1) {
      return dateTimeFrom.toApiString
        ? dateTimeFrom.toApiString()
        : dateTimeFrom.datetime.toISOString()
    }
  }

  // If createdDate is used or postDate is today
  var dateTimeFrom = { datetime: moment(createdDate) }

  if (now.diff(dateTimeFrom.datetime, 'days') > 0) {
    return `${now.diff(dateTimeFrom.datetime, 'days')}d`
  }

  if (now.diff(dateTimeFrom.datetime, 'hours') > 0) {
    return `${now.diff(dateTimeFrom.datetime, 'hours')}h`
  }

  if (now.diff(dateTimeFrom.datetime, 'minutes') > 0) {
    return `${now.diff(dateTimeFrom.datetime, 'minutes')}m`
  }

  if (now.diff(dateTimeFrom.datetime, 'seconds') >= 0) {
    return `${now.diff(dateTimeFrom.datetime, 'seconds')}s`
  }
}

export function showDayAndTime(dateInput: Date) {
  var now = moment()
  var dateTimeFrom = dateToExDate(dateInput)

  if (now.isSame(dateTimeFrom.datetime, 'day')) {
    return `Today ${dateTimeFrom.datetime.format('HH:mm')}`
  }

  if (now.add(-7, 'day').isBefore(dateTimeFrom.datetime, 'day')) {
    return dateTimeFrom.datetime.format('dddd HH:mm')
  }

  return dateTimeFrom.datetime.format('DD MMM YYYY, HH:mm')
}

export function formatDateIgnoringTimezone(dateString: string) {
  const date = new Date(dateString)
  const isoString = date.toISOString()
  const datePart = isoString.split('T')[0]
  return datePart
}
