import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useStyles from './styles'
import theme from '../../../mui-theme'
import TextInput from '../../inputs/Text'
import { regex } from '../../../constants'
import CancelButton from 'components/common/CancelButton'
import { BrandButton } from 'components/common/BrandButton'

export interface ProfileSettingsInput {
  id: string
  username: string
  reddit: string
  twitter: string
  facebook: string
  instagram: string
  tradingView: string
}

interface Props {
  id: string
  username: string
  reddit: string
  twitter: string
  facebook: string
  instagram: string
  tradingView: string
  onProfileSaveClick: (variables: ProfileSettingsInput) => void
}

export default function ProfileSettingsPanel(props: Props) {
  const classes = useStyles(theme)
  const { register, handleSubmit, errors, setValue } =
    useForm<ProfileSettingsInput>()

  const loadValues = () => {
    if (props) {
      setValue('username', props.username)
      setValue('reddit', props.reddit)
      setValue('twitter', props.twitter)
      setValue('facebook', props.facebook)
      setValue('instagram', props.instagram)
      setValue('tradingView', props.tradingView)
    }
  }

  const onCancel = () => {
    loadValues()
  }

  const onSubmit = (data: ProfileSettingsInput) => {
    data.id = props.id
    props.onProfileSaveClick(data)
  }

  useEffect(() => {
    loadValues()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.formFieldsWrapper}>
          <div className={classes.inputWrapper}>
            <TextInput
              name="username"
              label="User name"
              label_outside_input={true}
              ref={register({
                required: 'The user name field is required',
              })}
              error={errors.username?.message}
            />
          </div>

          <div className={classes.inputWrapper}>
            <TextInput
              name="reddit"
              label="Reddit"
              label_outside_input={true}
              ref={register({
                pattern: {
                  value: regex.url,
                  message: 'The value entered must be a valid URL',
                },
              })}
              error={errors.reddit ? errors.reddit.message : undefined}
            />
          </div>

          <div className={classes.inputWrapper}>
            <TextInput
              name="twitter"
              label="Twitter"
              label_outside_input={true}
              ref={register({
                pattern: {
                  value: regex.url,
                  message: 'The value entered must be a valid URL',
                },
              })}
              error={errors.twitter ? errors.twitter.message : undefined}
            />
          </div>

          <div className={classes.inputWrapper}>
            <TextInput
              name="facebook"
              label="Facebook"
              label_outside_input={true}
              ref={register({
                pattern: {
                  value: regex.url,
                  message: 'The value entered must be a valid URL',
                },
              })}
              error={errors.facebook ? errors.facebook.message : undefined}
            />
          </div>

          <div className={classes.inputWrapper}>
            <TextInput
              name="instagram"
              label="Instagram"
              label_outside_input={true}
              ref={register({
                pattern: {
                  value: regex.url,
                  message: 'The value entered must be a valid URL',
                },
              })}
              error={errors.instagram ? errors.instagram.message : undefined}
            />
          </div>

          <div className={classes.inputWrapper}>
            <TextInput
              name="tradingView"
              label="TradingView"
              label_outside_input={true}
              ref={register({
                pattern: {
                  value: regex.url,
                  message: 'The value entered must be a valid URL',
                },
              })}
              error={
                errors.tradingView ? errors.tradingView.message : undefined
              }
            />
          </div>
        </div>

        <div className={classes.buttonWrapper}>
          <CancelButton
            className={classes.cancelButton}
            onClick={() => onCancel()}
          >
            Cancel
          </CancelButton>
          <BrandButton
            variant="contained"
            className={classes.saveButton}
            type="submit"
          >
            Save
          </BrandButton>
        </div>
      </form>
    </div>
  )
}
