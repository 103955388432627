import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  checkboxWarningText: {
    color: '#D36D6D',
    paddingLeft: '0.75rem',
  },
  checkboxContainer: {
    position: 'relative',
    bottom: '20px',
  },
}))
