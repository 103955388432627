import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { handleTopicHeaderClick, trimURL } from 'utils/urlUtils'
import TopicSidebar from '../../../components/TopicSidebar'
import { VoteDirection } from '../../../components/TopicSidebar/VoteUpDown'
import {
  PostType,
  PostsDocument,
  PostsQuery,
  PostsQueryVariables,
  useChangePostVoteMutation,
  useDeletePostMutation,
  useDeletePostVoteMutation,
  usePostsQuery,
  useVotePostMutation,
} from '../../../generated/graphql'
import { onVote } from '../../../utils/onVote'
import { ExDate } from '../../../constants'
import { DEBUG_ENV } from '../../../index'
import { MoreActionsMenuItemIds } from 'components/PostIcons/PostIcons'
import { formatWithEllipsis } from 'utils/stringUtils'
import { ShareActionsMenuItemIds } from 'utils/shareUtils'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { mapPost } from 'utils/mappers/postsMapper'
import { onCameraActions } from 'utils/cameraUtils'
import { IStoryPost } from 'types/story-posts'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

interface TopicSidebarIntegrationProps {
  userId?: string
  isStoryEdit?: boolean
  isStoryView?: boolean
  storyPost?: PostData | null
  filteredTopicIds?: string[]
  filteredPostTypes?: PostType[]
  selectedStoryPosts?: IStoryPost[]
  setSelectedStoryPosts?: (selectedStoryPosts: IStoryPost[]) => void
  onTopicHover: (topicId: PostData | null) => void
  setSignUpModalOpen: (isOpen: boolean) => void
  onTopVisibleTopicDateChange?: (date: ExDate) => void
  onFilteredDatesLoaded?: (dates: ExDate[]) => void
  submitStory?: () => Promise<void>
}

export const TopicSidebarIntegration: React.FC<
  TopicSidebarIntegrationProps
> = props => {
  const votePostMutationHookResult = useVotePostMutation({
    errorPolicy: 'ignore',
  })
  const changePostVoteMutationHookResult = useChangePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const deletePostMutationHookResult = useDeletePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const [deletePost] = useDeletePostMutation()
  let { type, symbol } = useParams<'type' | 'symbol' | 'editId'>()
  const tickerUpper = symbol?.toUpperCase()
  const typeUpper = type?.toUpperCase()
  const location = useLocation()
  const dateQueryParam = new URLSearchParams(location.search).get('date')
  const history = useNavigate()
  const navigate = useNavigate()
  const dateStr = useQuery().get('date')
  const offset = useRef<number | undefined>(undefined)
  const toDate = useRef<string | undefined>(undefined)
  if (dateStr) {
    toDate.current = dateStr
  } else {
    toDate.current = undefined
  }
  const [hasMoreTopics, setHasMoreTopics] = useState(true)

  const [filteredTopicIds, setFilteredTopicIds] = useState<
    string[] | undefined
  >()

  if (!tickerUpper) {
    const FINAL_URL = dateQueryParam ? '' + `?date=${dateQueryParam}` : ''

    navigate(FINAL_URL, { replace: true })
  }

  // usePostsQuery will now run whenever assetType changes
  const {
    data: posts,
    fetchMore,
    loading: postsLoading,
  } = usePostsQuery({
    fetchPolicy: 'cache-first',
    skip: props.isStoryView && !props.filteredTopicIds?.length,
    variables: {
      postSymbol: tickerUpper as string,
      postAssetType: typeUpper as string,
      toDate: toDate.current,
      ids: filteredTopicIds || props.filteredTopicIds,
      postTypes: props.filteredPostTypes,
    },
  })

  function handleCommentClick(id: string) {
    const BASE_URL = `${location.pathname}${
      location.pathname.endsWith('/') ? '' : '/'
    }topic/${id}`

    const FINAL_URL = dateQueryParam
      ? BASE_URL + `?date=${dateQueryParam}`
      : BASE_URL

    navigate(FINAL_URL, { replace: true })
  }

  function onShareActionsClick(id: string, action: string | number) {
    const foundTopic = posts?.posts?.find(x => x.id === id)
    const shareUrl = `${window.location.href.split('?')[0]}/topic/${id}`

    switch (action) {
      case ShareActionsMenuItemIds.TWITTER: {
        window.open(
          `https://twitter.com/intent/post?text=${formatWithEllipsis(
            foundTopic?.title + '',
            50
          )}&url=${encodeURIComponent(shareUrl)}&hashtags=${
            foundTopic?.postSymbol
          },fnchart,investing,trading,stocks,markets`,
          '_blank'
        )
        break
      }
      case ShareActionsMenuItemIds.REDDIT: {
        window.open(
          `http://www.reddit.com/submit?url=${encodeURIComponent(
            shareUrl
          )}&title=${foundTopic?.postSymbol}%20${encodeURIComponent(
            formatWithEllipsis(foundTopic?.title + '', 290)
          )}`,
          '_blank'
        )
        break
      }
      case ShareActionsMenuItemIds.FACEBOOK: {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareUrl
          )}%2F&amp;src=sdkpreparse`,
          '_blank'
        )
        break
      }
      case ShareActionsMenuItemIds.GMAIL: {
        window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Check%20out%20this%20news%20about%20${
            foundTopic?.postSymbol
          }&body='${foundTopic?.title}%20${encodeURIComponent(
            shareUrl
          )}'&ui=2&tf=1&pli=1`,
          '_blank'
        )
        break
      }
      case ShareActionsMenuItemIds.EMAIL: {
        window.open(
          `mailto:?subject=Check out this news about ${
            foundTopic?.postSymbol
          }&body=${foundTopic?.title} ${encodeURIComponent(shareUrl)}`,
          '_blank'
        )
        break
      }
    }
  }

  const topics: PostData[] =
    posts?.posts?.map(post => {
      let userVoteDirection = null
      const userVote = post.userVotePositive

      if (userVote === true) {
        userVoteDirection = VoteDirection.up
      } else if (userVote === false) {
        userVoteDirection = VoteDirection.down
      }

      const topic = mapPost(post)
      return topic
    }) || []

  if (props.onFilteredDatesLoaded) {
    props.onFilteredDatesLoaded(
      posts?.posts?.map(post => new ExDate(post.date)) || []
    )
  }

  function onMoreActionsClick(postId: string, action: string | number) {
    if (DEBUG_ENV)
      console.debug(
        'screen->Main->TopicSideBarIntegration->onMoreActionsClick FIRED'
      )
    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        navigate(`${location.pathname}/report/${postId}`, { replace: true })

        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->TopicSidebarIntegration->onMoreActionsClick->CASE: EDIT`
          )

        navigate(
          `/${type!.toLowerCase()}/${symbol!.toLowerCase()}/edit/${postId}`
        )
        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->TopicSidebarIntegration->onMoreActionsClick->CASE: EDIT`
          )

        deletePost({
          variables: { postId },
          update(cache, { data }) {
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: postId,
              __typename: 'Post',
            })
            cache.evict({ id: normalizedId })
            cache.gc()

            const readQueryResult = cache.readQuery<
              PostsQuery,
              PostsQueryVariables
            >({
              query: PostsDocument,
              variables: {
                postSymbol: symbol?.toUpperCase() as string,
                postAssetType: typeUpper!,
              },
            })

            const posts = readQueryResult?.posts || []
            const updatedPosts = posts.filter(post => post.id !== postId)

            cache.writeQuery({
              query: PostsDocument,
              data: {
                posts: updatedPosts,
              },
              variables: {
                postSymbol: symbol?.toUpperCase() as string,
                postAssetType: typeUpper!,
              },
            })
          },
        })
          .then(() =>
            history(String(trimURL(location.pathname)).replace('/create', ''))
          )
          .catch(e => {
            if (DEBUG_ENV) console.error(e)
          })

        break
      }
    }
  }

  const handleLoadMore = () => {
    if (!postsLoading && posts?.posts?.length) {
      offset.current = posts?.posts?.length
      if (DEBUG_ENV)
        console.debug(
          `--> TopicSidebarIntegration -- offset: ${offset.current}`
        )
      fetchMore({
        variables: {
          offset: offset?.current ?? posts.posts.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.posts.length === 0) {
            setHasMoreTopics(false)
            return prev
          }

          const oldPosts = prev.posts ?? []
          const newPosts = fetchMoreResult.posts ?? []

          return {
            ...fetchMoreResult,
            posts: [...oldPosts, ...newPosts],
          }
        },
      })
    }
  }

  const handleSubmitTopic = () => {
    // if user doesn't exist, open modal
    if (!props.userId) {
      props.setSignUpModalOpen(true)
      return
    }

    const BASE_URL = `/${type}/${symbol}/create`
    const FINAL_URL = dateQueryParam
      ? BASE_URL + `?date=${dateQueryParam}`
      : BASE_URL
    history(FINAL_URL)
  }

  const handleVote = (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => {
    if (!props.userId) {
      props.setSignUpModalOpen(true)
      return
    }

    onVote({
      direction,
      postId,
      changePostVoteMutationHookResult,
      votePostMutationHookResult,
      deletePostMutationHookResult,
      userId: props.userId,
      userVotePositive,
    })
  }

  const handleReply = () => {
    // if user doesn't exist, open modal
    if (!props.userId) {
      props.setSignUpModalOpen(true)
      return Promise.resolve()
    }
  }

  const selectDate = () => {
    if (dateStr) {
      //return new ExDate(dateStr, { tz: 'UTC' })
      return new ExDate(dateStr)
    } else {
      return undefined
    }
  }

  useEffect(() => {
    setHasMoreTopics(true)
    handleLoadMore()
  }, [dateStr])

  useEffect(() => {
    if (posts?.posts && posts?.posts?.length < 20) {
      setHasMoreTopics(false)
    }
  }, [posts])

  return (
    <TopicSidebar
      userId={props.userId}
      symbol={tickerUpper ?? ''}
      selectedDate={selectDate()}
      topics={topics}
      hasMoreTopics={hasMoreTopics}
      loadMoreTopics={handleLoadMore}
      onTopicReplyClick={handleReply}
      onSubmitTopicClick={handleSubmitTopic}
      onTopicHeaderClick={handleTopicHeaderClick}
      onCommentClick={handleCommentClick}
      onTopicBookmarkClick={() => {
        if (DEBUG_ENV) console.debug.bind(console)
      }}
      onShareActionsClick={onShareActionsClick}
      onCameraActionsClick={onCameraActions}
      onMoreActionsClick={onMoreActionsClick}
      onVote={handleVote}
      onTopicHover={(topic: PostData | null) => props.onTopicHover(topic)}
      onTopVisibleTopicDateChange={props.onTopVisibleTopicDateChange}
      isStoryEdit={props.isStoryEdit}
      isStoryView={props.isStoryView}
      storyPost={props.storyPost}
      selectedStoryPosts={props.selectedStoryPosts}
      setSelectedStoryPosts={props.setSelectedStoryPosts}
      setFilteredTopicIds={setFilteredTopicIds}
      submitStory={props.submitStory}
    />
  )
}
