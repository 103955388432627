import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import { PostType, useGetPostQuery } from 'generated/graphql'
import ShareablePost from 'components/Shareable/Post'
import { mapPost } from 'utils/mappers/postsMapper'
import { useNavigate, useParams } from 'react-router-dom'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faClose,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../constants'
import { FormControl, Input } from '@mui/material'
import ROUTES from 'constants/routes'
import ShareableStoryPost from 'components/Shareable/StoryPost'
import LogoNoBeta from 'components/Images/LogoNoBeta'
import UserAvatar from 'components/UserAvatar'

const PostGraphic: React.FC = ref => {
  const classes = useStyles(theme)
  const deviceType = useDeviceType()
  const navigate = useNavigate()

  const {
    type: postType,
    symbol: symbol,
    topicId: postId,
  } = useParams<'type' | 'symbol' | 'topicId'>()

  const redirectToPage = () => {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  const onClose = () => {
    navigate(
      ROUTES.ASSET.replace(':type', postType!.toLowerCase()).replace(
        ':symbol',
        symbol!
      ) +
        '/topic/' +
        postId
    )
  }

  const { data: getPost } = useGetPostQuery({
    variables: {
      id: postId!,
    },
  })

  const post = getPost?.getPost ? mapPost(getPost.getPost) : undefined

  if (!post) return null

  let content

  if (post.postType === PostType.Story && post.childPosts) {
    const totalWidth = 391 * post.childPosts.length

    content = (
      <div className={classes.storyPostWrapper} style={{ width: totalWidth }}>
        <div className={classes.storyHeader}>
          <div className={classes.logoLeft}>
            <LogoNoBeta />
          </div>
          <div className={classes.userDetailsRight}>
            <div className={classes.avatarWrapper}>
              <UserAvatar emailHash={post.emailHash} />
            </div>
            <div className={classes.userDetails}>
              <div className={classes.usernameStory}>{post.username}</div>
              <div className={classes.followCounts}>
                {post.followingCount} Following&nbsp;&nbsp;{post.followersCount}{' '}
                Followers
              </div>
            </div>
          </div>
        </div>
        <div className={classes.storyTitle}>
          <div className={classes.symbolTitle}>
            <span className={classes.symbol}>{post.symbol}</span>{' '}
            <span className={classes.symbolName}>{post.symbolName}</span>
          </div>
          <div className={classes.storyTitleText}>{post.title}</div>
        </div>
        <div className={classes.storyChildPostsWrapper}>
          {post.childPosts
            ?.sort(
              (a, b) => a.childPost.date.getTime() - b.childPost.date.getTime()
            )
            .map((childPost, index) => (
              <ShareableStoryPost
                post={childPost.childPost}
                noteText={childPost.noteText ?? undefined}
                key={index}
              />
            ))}
        </div>
      </div>
    )
  } else {
    content = <ShareablePost post={post} />
  }

  return (
    <div className={classes.wrapper}>
      {deviceType === DeviceType.Desktop && (
        <div className={classes.topNavWrapper}>
          <div className={classes.logoWrapper}>
            <LogoWithoutText />
          </div>
          <div className={classes.inputWithIcon} onClick={redirectToPage}>
            <FormControl
              classes={{
                root: classes.formControlRoot,
              }}
            >
              <Input
                placeholder={'Search stocks e.g. Apple AAPL'}
                inputProps={{}}
                id={`input-search`}
                readOnly={true}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {}}
                name={'search'}
                classes={{
                  input: classes.input,
                  root: classes.inputRoot,
                }}
              />
            </FormControl>
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.iconSearch}
              color={colors.textGrey}
            />
          </div>
          <div className={classes.closeWithIcon} onClick={onClose}>
            <span>Close</span>
            <FontAwesomeIcon
              icon={faClose}
              className={classes.iconClose}
              color={colors.textGrey}
            />
          </div>
        </div>
      )}

      {deviceType === DeviceType.Mobile && (
        <div className={classes.topNavWrapper}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.iconLeftArrow}
            color={colors.textGrey}
            onClick={onClose}
          />
          <span className={classes.username}>&nbsp;</span>
        </div>
      )}
      <div className={classes.shareablePost}>{content}</div>
    </div>
  )
}

export default PostGraphic
