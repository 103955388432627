import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
const Height = 16
const Width = 20

export default makeStyles<typeof theme>(theme => ({
  img: {
    width: Width,
    height: Height,
    marginRight: '8px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))
