import React, { forwardRef } from 'react'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import useStyles from './styles'
import theme from '../../../mui-theme'
import classNames from 'classnames'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../../constants'

interface Props {
  error?: string
  name: string
  value?: string
  label?: string
  placeholder?: string
  label_outside_input?: boolean
  no_label?: boolean
  no_underline?: boolean
  hasBorderRadius?: boolean
  hideErrorText?: boolean
  iconRight?: IconDefinition
  rows?: number
  minRows?: number
  maxRows?: number
  clearBackground?: boolean
  padding?: string
  onChange?: (e: string) => void
  iconRightClick?: () => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)

  return (
    <>
      <div
        className={classNames(
          classes.multiLineTextWrapper,
          props.error ? classes.multiLineTextWrapperError : '',
          props.no_underline ? classes.noUnderline : ''
        )}
      >
        <FormControl
          classes={{
            root: classes.root,
          }}
        >
          {!props.no_label && props.label_outside_input && (
            <FormLabel
              htmlFor={`input-${props.name}`}
              error={!!props.error}
              classes={{
                root: classes.formLabelRoot,
                focused: classes.labelFocused,
              }}
            >
              {props.label}
            </FormLabel>
          )}
          <div
            className={classNames(
              classes.inputBox,
              props.hasBorderRadius && classes.borderRadius,
              props.clearBackground && classes.clearBackground
            )}
          >
            {!props.no_label && !props.label_outside_input && (
              <InputLabel
                htmlFor={`input-${props.name}`}
                error={!!props.error}
                classes={{
                  root: classes.inputLabelRoot,
                  focused: classes.labelFocused,
                  shrink: classes.labelShrink,
                }}
              >
                {props.label}
              </InputLabel>
            )}
            <Input
              error={!!props.error}
              id={`input-${props.name}`}
              inputRef={ref}
              name={props.name}
              placeholder={props.placeholder}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                props.onChange && props.onChange(e.target.value)
              }}
              multiline
              rows={
                props.minRows && props.maxRows
                  ? undefined
                  : props.rows ?? (props.label_outside_input ? 4 : 3)
              }
              value={props.value}
              minRows={props.minRows}
              maxRows={props.maxRows}
              classes={{
                input: classNames(classes.input),
                underline: classNames(classes.underline),
                root: classNames(
                  classes.inputRoot,
                  props.no_label ? classes.inputRootNoLabel : null
                ),
              }}
              style={{
                padding: props.padding,
              }}
            />
          </div>
        </FormControl>
        {!!props.iconRight && (
          <FontAwesomeIcon
            icon={props.iconRight}
            className={classes.iconRight}
            color={colors.textGrey}
            onClick={() => {
              !!props.iconRightClick && props.iconRightClick()
            }}
          />
        )}
      </div>
      {props.error && !props.hideErrorText && (
        <FormHelperText
          error={true}
          classes={{
            root: classes.formHelperRoot,
          }}
        >
          {props.error}
        </FormHelperText>
      )}
    </>
  )
})
