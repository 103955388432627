//import GoogleAnalytics from '@analytics/google-analytics'
import { Analytics } from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { GOOGLE_ANALYTICS_ID, DEV_ENVIRONMENT } from '../../constants/index'

const DEBUG_ENV =
  !!process.env.REACT_APP_DEPLOYMENT_ENV &&
  !!process.env.REACT_APP_LOG_DETAIL_LEVEL
    ? process.env.REACT_APP_DEPLOYMENT_ENV !== 'PRODUCTION' &&
      process.env.REACT_APP_LOG_DETAIL_LEVEL !== 'PRODUCTION'
    : false

// PROTOCOL DOCUMENTATION
// https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters

// Documentation on suggested tags:
// https://support.google.com/analytics/answer/9267735

const gAnalytics = Analytics({
  app: 'fnchart-webapp',
  version: '200',
  plugins: [
    googleAnalytics({
      measurementIds: [GOOGLE_ANALYTICS_ID],
      debug: DEV_ENVIRONMENT,
    }),
  ],
})

export const gaSignupEvent = async (
  step: string,
  event: string,
  type?: string
) => {
  await gAnalytics.track(event, {
    method: 'webapp',
    step: step,
    type: type ?? 'success',
  })
}

export const gaLoginEvent = async (
  step: string,
  event: string,
  type?: string,
  id?: string
) => {
  await gAnalytics.track(event, {
    id: id,
    method: 'webapp',
    step: step,
    type: type ?? 'success',
  })
}

export const gaPostEvent = async (
  step: string,
  event: string,
  type?: string,
  id?: string
) => {
  await gAnalytics.track(event, {
    id: id,
    method: 'webapp',
    step: step,
    type: type ?? 'success',
  })
}

export const gaVoteEvent = async (
  step: string,
  event: string,
  type?: string,
  id?: string
) => {
  await gAnalytics.track(event, {
    id: id,
    method: 'webapp',
    step: step,
    type: type ?? 'success',
  })
}

//gAnalytics.plugins.enable('google-analytics').then(() => {
if (DEBUG_ENV) console.debug('ENABLED: analytics/google-analytics')
//})

export default gAnalytics
