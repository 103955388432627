import {
  faArrowLeft,
  faBook,
  faCommentAlt,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {
  CheckDuplicateUrlInput,
  PostType,
  useCheckDuplicateUrlLazyQuery,
} from 'generated/graphql'
import useTickerValidation from 'hooks/useTickerValidation'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { setApolloError as setServerError } from '../../../apollo/reactive-vars'
import { ExDate, colors, regex } from '../../../constants'
import theme from '../../../mui-theme'
import { dateFromURL, titleFromURL } from '../../../utils/urlUtils'
import DatePicker from '../../inputs/DatePicker'
import MarkdownEditor from '../../inputs/MarkdownEditor'
import TextInput from '../../inputs/Text'
import useStyles from './styles'
import { DEBUG_ENV } from 'index'
import {
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  checkboxClasses,
} from '@mui/material'
import { BrandButton } from 'components/common/BrandButton'

export interface CreateTopicMobileInput {
  headline: string
  postSymbol: string
  date: string
  text: string
  postType: PostType
  url: string
  ticker: string
  notifyViaEmail: boolean
  additionalSymbols: string[] | undefined
}

interface Props {
  children?: React.ReactNode
  type?: string
  isEdit?: boolean
  onCancelNewTopicClick: () => void
  onPostClick: (data: CreateTopicMobileInput) => void
  data?: Partial<CreateTopicMobileInput>
  symbolDate: ExDate
}

const TITLE_MIN_LENGTH = 5
const TITLE_MAX_LENGTH = 1000

const TEXT_MIN_LENGTH = 5

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const { validateTicker, extractTickers, symbols } = useTickerValidation()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    control,
  } = useForm<CreateTopicMobileInput>({
    defaultValues: props.data,
  })

  const wrapperRef = useRef<HTMLDivElement>(null)
  const formWrapperRef = useRef<HTMLDivElement>(null)

  const getInitialTabValue = () => {
    switch (props?.data?.postType) {
      case PostType.Url:
        return 1
      case PostType.Text:
        return 2
      case PostType.Story:
        return 0
      default:
        return 0
    }
  }

  const [tabValue, setTabValue] = React.useState(getInitialTabValue())

  // If there is no text, then it is a link
  const [markdownEditorFocused, setMarkdownEditorFocused] = useState(false)
  const [topicType, setTopicType] = useState(
    props?.data?.postType ?? PostType.Story
  )
  const [urlTaken, setUrlTaken] = useState(false)
  const [getURL, setURL] = useState('')
  const [getCurrURL, setCurrURL] = useState('')
  const [checkURL, { loading, data }] = useCheckDuplicateUrlLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (getURL === getCurrURL) {
    } else {
      setUrlTaken(false)
      clearErrors('url')
    }
    setUrlTaken(false)
    clearErrors('url')
  }, [getURL])

  const isDuplicateURL = () => {
    const res = checkURL({
      variables: {
        data: {
          url: getURL,
          postSymbol: props.type,
        } as CheckDuplicateUrlInput,
      },
    }).then(result => {
      if (!!result.data?.checkDuplicateUrl) {
        //setErrorStateURL()
        setUrlTaken(true)
        setCurrURL(getURL)
        setError('url', {
          message: `There is already a post with this URL on this security...`,
        })
        setServerError(
          `There is already a post with this URL on this security...`
        )
        return result
      } else {
        //clearErrorStateURL()
        setUrlTaken(false)
        setCurrURL(getURL)
        clearErrors('url')

        return result
      }
    })

    return res
  }

  const onBlur = (e: any) => {
    if (!getURL?.length) {
      setUrlTaken(false)
      clearErrors('url')
      return {}
    }

    if (getCurrURL === getURL) {
      return {}
    }

    // This bit of code will check if there is a URL entered,
    // If there is, then it will try to extract a headline from it
    if (getValues('headline')?.length === 0) {
      const headline = titleFromURL(getURL) ?? ''

      setValue('headline', headline)
    }

    // Get date if it's in the URL
    if (getValues('date')?.length === 0) {
      console.debug(`--> CreateTopicPanel -- `)
      const newDate = dateFromURL(getURL) ?? ''

      if (!!newDate?.length) {
        setValue('date', new ExDate(newDate).toISOString())
      } else {
        setValue('date', props.symbolDate.toISOString())
      }
    }

    setCurrURL(getURL)

    return isDuplicateURL()
  }

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newSelectedTabValue: number
  ) => {
    if (props.isEdit) {
      return
    }

    setTabValue(newSelectedTabValue)

    if (newSelectedTabValue === 1) {
      setTopicType(PostType.Url)
    } else if (newSelectedTabValue === 2) {
      setTopicType(PostType.Text)
    } else {
      setTopicType(PostType.Story)
    }
  }

  const onPost = (data: CreateTopicMobileInput) => {
    let payload = {
      ...data,
      type: props.data?.postSymbol,
      postType: topicType,
    } as CreateTopicMobileInput

    extractTickers(payload.text)
    payload.additionalSymbols = symbols ?? undefined

    const res = isDuplicateURL().then(result => {
      if (!result.data?.checkDuplicateUrl) {
        props.onPostClick(payload)
      }
    })
  }

  // validation for markdown editor has to be done here due to it being an
  // editable div
  useEffect(() => {
    if (topicType === PostType.Text) {
      register(
        { name: 'text' },
        {
          required: 'The text field is required',
          validate: validateTicker,
          minLength: {
            message: `The text length needs to be at least ${TEXT_MIN_LENGTH} characters.`,
            value: TEXT_MIN_LENGTH,
          },
        }
      )
    }
  })

  useEffect(() => {
    function handleResize() {
      if (wrapperRef.current) {
        wrapperRef.current.style.minHeight = `${window.innerHeight}px`
      }
    }

    // Set initial height on mount and on resize
    handleResize()
    window.addEventListener('resize', handleResize)

    // Clean up
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  let formDate: ExDate = props.symbolDate

  if (!!props?.data?.date) {
    formDate = new ExDate(props?.data?.date)

    if (DEBUG_ENV)
      console.debug(`props?.data?.date !== undefined: ${!!props?.data?.date}`)
  }

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <div className={classes.navigationWrapper}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={classes.backArrow}
          size="sm"
          onClick={() => props.onCancelNewTopicClick()}
        />
      </div>
      {!markdownEditorFocused && (
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="user settings tabs"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.selectedTabIndicator,
            scroller: classes.selectedTabScroller,
          }}
        >
          <Tab
            disableRipple={true}
            key={0}
            className={classNames(
              classes.tab,
              tabValue === 0 ? classes.selectedTab : ''
            )}
            label={
              <div>
                <FontAwesomeIcon icon={faBook} size="sm" />
                <span className={classes.tabText}>Story</span>
              </div>
            }
          />
          <Tab
            disableRipple={true}
            key={1}
            className={classNames(
              classes.tab,
              tabValue === 1 ? classes.selectedTab : ''
            )}
            label={
              <div>
                <FontAwesomeIcon icon={faLink} size="sm" />
                <span className={classes.tabText}>Link</span>
              </div>
            }
          />
          <Tab
            disableRipple={true}
            key={2}
            className={classNames(
              classes.tab,
              tabValue === 2 ? classes.selectedTab : ''
            )}
            label={
              <div>
                <FontAwesomeIcon icon={faCommentAlt} size="sm" />
                <span className={classes.tabText}>Text</span>
              </div>
            }
          />
        </Tabs>
      )}

      <div className={classes.contentWrapper}>
        <form onSubmit={handleSubmit(onPost)} className={classes.form}>
          <h2 className={classes.header}>
            {props.isEdit ? 'Edit Topic' : 'Create a Topic'}{' '}
            <span className={classes.headerTicker}>at {props.type}</span>
          </h2>
          <div className={classes.formWrapper} ref={formWrapperRef}>
            <div
              className={classes.inputWrapper}
              style={{ display: markdownEditorFocused ? 'none' : 'block' }}
            >
              <TextInput
                name="headline"
                label="Headline"
                ref={register({
                  required: 'The headline field is required',
                  minLength: {
                    message: `The text length needs to be at least ${TITLE_MIN_LENGTH} characters.`,
                    value: TITLE_MIN_LENGTH,
                  },
                  maxLength: {
                    message: ` The comment length cannot exceed ${TITLE_MAX_LENGTH} characters`,
                    value: TITLE_MAX_LENGTH,
                  },
                })}
                error={errors.headline ? errors.headline.message : undefined}
              />
            </div>
            <div
              className={classes.inputWrapper}
              style={{ display: markdownEditorFocused ? 'none' : 'block' }}
            >
              <Controller
                as={<DatePicker />}
                control={control}
                name="date"
                selectedDate={formDate.datetime.toDate()}
                defaultValue={formDate.datetime.toDate()}
                ref={{ required: 'The date field is required' }}
                autoOk
                error={errors.date ? errors.date.message : undefined}
              />
            </div>
            {tabValue === 2 && (
              <div
                className={classNames(
                  classes.inputWrapper,
                  classes.textInputWrapper
                )}
              >
                <Controller
                  as={
                    <MarkdownEditor
                      name="text"
                      onFocus={() => {
                        setMarkdownEditorFocused(true)

                        setTimeout(() => {
                          window.scrollTo(0, 0)
                        }, 500)
                      }}
                      onFocusExit={() => setMarkdownEditorFocused(false)}
                    />
                  }
                  control={control}
                  label="Text"
                  name="text"
                  onInput={(input: string) => {
                    setValue('text', input, { shouldValidate: true })
                    extractTickers(input)
                  }}
                  value={getValues().text}
                  error={errors.text ? errors.text.message : undefined}
                />
              </div>
            )}
            {tabValue === 1 && (
              <div
                className={classNames(
                  classes.inputWrapper,
                  classes.urlInputWrapper
                )}
              >
                <TextInput
                  name="url"
                  label="URL"
                  onBlur={e => onBlur(e)}
                  onFocus={() => clearErrors('url')}
                  onChange={async e => {
                    setURL(e)
                    await sleep(100)
                  }}
                  ref={register({
                    required: 'The URL field is required',
                    pattern: {
                      value: regex.url,
                      message: 'The value entered must be a valid URL',
                    },
                  })}
                  error={errors.url ? errors.url.message : undefined}
                />
              </div>
            )}
            {topicType !== PostType.Story && !props?.data?.notifyViaEmail && (
              <Controller
                name="notifyViaEmail"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => onChange(e.target.checked)}
                        onBlur={onBlur}
                        checked={value}
                        name={name}
                        ref={ref}
                        sx={{
                          color: colors.white_faded,
                          padding: 0,
                          marginRight: 1,
                        }}
                      />
                    }
                    label="Notify me via email when someone replies"
                    sx={{
                      color: colors.textGreyLight,
                      margin: '8px 0 0 0',
                      [`& span.${checkboxClasses.checked}`]: {
                        color: 'white',
                      },
                    }}
                  />
                )}
              />
            )}
          </div>
          <div className={classes.buttonWrapper}>
            <BrandButton
              variant="contained"
              className={classNames(classes.button)}
              type="submit"
            >
              {topicType === PostType.Story
                ? 'SELECT TOPICS'
                : props.isEdit
                ? 'SAVE'
                : 'POST'}
            </BrandButton>
          </div>
        </form>
      </div>
    </div>
  )
})
