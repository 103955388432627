import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import SearchOverlay from 'components/SearchOverlay'
import { useGetCurrentUserQuery } from 'generated/graphql'
import ROUTES from 'constants/routes'
import { userSidebarMenuItems } from 'constants/userSidebarMenuItems'
import { DEBUG_ENV } from 'index'
import { useNavigate } from 'react-router-dom'
import { userSidebarTabItems } from 'constants/userSidebarTabItems'
import UserSidebarContent from 'components/UserSidebarContent'
import { useUserOperations } from 'hooks/useUserOperations'
import { Drawer, IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import TutorialModal from 'components/TutorialModal'

const Search: React.FC = () => {
  const classes = useStyles(theme)
  const [openRight, setOpenRight] = useState(false)
  const [openUserSettingsSidebar, setOpenUserSettingsSidebar] = useState(false)
  const navigate = useNavigate()
  const [selectedtabItemIndex, setSelectedtabItemIndex] = useState(0)
  const {
    signOut,
    setPassword,
    accountSave,
    profileSave,
    changePasswordError,
    onMenuItemClick,
  } = useUserOperations()

  const [showTutorial, setShowTutorial] = useState(() => {
    return localStorage.getItem('tutorialShown') !== 'true'
  })

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  function onCloseRightPanel() {
    setOpenUserSettingsSidebar(false)
  }

  const handleRightPanelClose = () => {
    setOpenRight(false)
    onCloseRightPanel()
  }

  const goToUserSettingsMobile = () => {
    navigate(ROUTES.USER_MENU_MOBILE)
  }

  useEffect(() => {
    if (showTutorial) {
      localStorage.setItem('tutorialShown', 'true')
    }
  }, [showTutorial])

  return (
    <div className={classes.wrapper}>
      <SearchOverlay
        isLoggedIn={!!getCurrentUserData?.getCurrentUser}
        email={getCurrentUserData?.getCurrentUser?.email || ''}
        userId={getCurrentUserData?.getCurrentUser?.id || ''}
        signUp={() => window.open(ROUTES.SIGNUP, '_self')}
        logIn={() => window.open(ROUTES.LOGIN, '_self')}
        hideClose={true}
        focusSearchOnOpen={false}
        openRight={openRight}
        goToUserSettingsMobile={goToUserSettingsMobile}
        setOpenRight={setOpenRight}
      />
      <Drawer
        anchor="right"
        open={!!getCurrentUserData?.getCurrentUser && openRight}
        onClose={() => handleRightPanelClose()}
        classes={{
          paper: classes.drawerPaperRight,
        }}
      >
        <IconButton
          onClick={() => handleRightPanelClose()}
          className={classes.closeBtnRight}
          edge="end"
          size="small"
        >
          <FontAwesomeIcon icon={faTimes} size="xs" />
        </IconButton>
        <UserSidebarContent
          getCurrentUserData={getCurrentUserData}
          userSidebarMenuItems={userSidebarMenuItems}
          onMenuItemClick={onMenuItemClick}
          DEBUG_ENV={DEBUG_ENV}
          navigate={navigate}
          openUserSettingsSidebar={openUserSettingsSidebar}
          userSidebarTabItems={userSidebarTabItems}
          selectedtabItemIndex={selectedtabItemIndex}
          onBackClick={() => {}}
          signOut={signOut}
          changePasswordError={changePasswordError}
          accountSave={accountSave}
          setPassword={setPassword}
          profileSave={profileSave}
          setOpenUserSettingsSidebar={setOpenUserSettingsSidebar}
          setSelectedtabItemIndex={setSelectedtabItemIndex}
        />
      </Drawer>
      <TutorialModal
        open={showTutorial}
        onClose={() => setShowTutorial(false)}
      />
    </div>
  )
}

export default Search
