import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  KeyboardEvent,
} from 'react'
import { FormControl } from '@material-ui/core'
import { DEBUG_ENV } from '../index'

export const useKeyCombination = (
  keys: string[],
  callback: Function,
  node = null
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback)
  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  const isApple = () => {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
  }

  // handle what happens on key press
  const handleKeyPress = useCallback(
    event => {
      // check if one of the key is part of the ones we want
      if (isApple() && event.metaKey && keys.every(key => event.key === key)) {
        //if (DEBUG_ENV) console.debug(`command + v clicked: ${e.which}`)
        callbackRef.current(event)
      } else if (
        !isApple() &&
        event.ctrlKey &&
        keys.every(key => event.key === key)
      ) {
        //if (DEBUG_ENV) console.debug(`ctrl + v clicked: ${event.which}`)
        callbackRef.current(event)
      }

      //if (event.control && event.key === 86) {
      //  if (DEBUG_ENV) console.debug(`--> Keys META + V clicked`)
      //} else {
      //  if (DEBUG_ENV) console.debug(
      //    `---> event.which: ${event.which} && event.key: ${event.key}`
      //  )
      //}
    },
    [keys]
  )

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document
    // attach the event listener
    targetNode && targetNode.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () =>
      targetNode && targetNode.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress, node])
}
