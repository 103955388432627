import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles({
  wrapper: {
    width: '60%',
    padding: 32,
    backgroundColor: colors.drawer_bg_right,
    boxSizing: 'border-box',
  },

  trendingLatestWrapper: {
    boxSizing: 'border-box',
  },

  predictResultHereFeedImage: {
    width: '100%',
    paddingBottom: 16,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },

  predictionResult: {
    padding: '16px 0',
    borderBottom: `1px solid ${colors.drawer_border}`,
    maxWidth: 600,
    paddingRight: 16,
  },

  predictionResultHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },

  fnchartIcon: {
    width: 32,
  },

  fnchartText: {
    color: colors.text_white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    marginLeft: 8,
  },

  seeResultButton: {
    color: colors.text_white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    cursor: 'pointer',
    backgroundColor: colors.blue,
    textTransform: 'none',
    width: 106,
    marginLeft: 'auto',
  },

  resultIsHereText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: -0.4,
    marginBottom: 16,
  },

  tabs: {
    height: 28,
    minHeight: 28,
    borderBottom: `1px solid ${colors.drawer_border}`,
  },

  tab: {
    display: 'inline-block',
    verticalAlign: 'top',
    height: 28,
    minHeight: 28,
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: 'auto',
    padding: 0,
    marginRight: 32,
    textTransform: 'capitalize',
  },

  selectedTab: {
    color: `${colors.text_white} !important`,

    '& svg': {
      color: colors.text_white,
    },
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
    bottom: 0,
  },

  body: {
    marginTop: 16,
  },

  resultRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 20,
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '20px',
    padding: '10px 32px',
    color: colors.text_white,
  },

  value: {
    float: 'right',
    textAlign: 'right',
    maxWidth: '70%',
  },

  key: {
    maxWidth: '30%',
  },

  newsPostWrapper: {
    height: 'calc(100% - 44px)',
    marginTop: 16,
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'inline-block',
    width: '100%',

    '&::-webkit-scrollbar': {
      '&-thumb': {
        backgroundColor: colors.box_bg,
      },
    },

    '& .infinite-scroll-component__outerdiv': {
      height: '100%',

      '& .infinite-scroll-component': {
        overflowY: 'overlay !important',
        'scrollbar-gutter': 'stable',
        paddingRight: 8,

        '&::-webkit-scrollbar': {
          '&-thumb': {
            backgroundColor: colors.box_bg,
          },
        },
      },
    },
  },
})
