import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'

export const submitTopicHeaderHeight = 55

export default makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    marginBottom: 72, 
    backgroundColor: colors.drawer_bg,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
  },

  menuList: {
    width: '100%',
    padding: 0
  },

  menuOption: {
    minHeight: 55,
    height: 55,
    color: colors.textGrey,
    transitionDuration: '50ms',

    '&:hover': {
      color: colors.text_white,
      backgroundColor: `${colors.menu_item_bg_hover}`,
    },
  },

  selectedMenuOption: {
    color: colors.text_white,

    '&:before': {
      content: `''`,
      backgroundColor: colors.blue_light,
      color: colors.text_white,
      position: 'absolute',
      height: '100%',
      width: 4,
      left: 0
    },
  },

  menuOptionWrapper: {
    fontFamily: fonts.dm_sans,
    fontSize: 13,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },

  icon: {
    marginRight: 10,
  },

  footerMenuList: {
    width: '100%',
    marginTop: 'auto'
  },

  footerMenuOption: {
    color: colors.textGrey,

    '&:hover': {
      backgroundColor: `${colors.drawer_bg}`,
    },
  },

  footerMenuOptionWrapper: {
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    width: 80,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
})
