import React, { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { UserCreator } from '../../../auth/userAuth'
import { colors, fonts, regex } from '../../../constants'
import theme from '../../../mui-theme'
import TextInput from '../../inputs/Text'
import AlreadyRegisteredSection from '../common/AlreadyRegisteredSection'
import AuthBoxContainer from '../common/AuthBoxContainer'
import AuthSubmitButton from '../common/AuthSubmitButton'
import { SocialProvider } from '../common/SocialSignupOption'
import SpaceBetween from '../common/SpaceBetween'
import useStyles from './styles'

//import debounce from 'lodash.debounce'
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Box, Checkbox, Typography, checkboxClasses } from '@mui/material'
import { setApolloError } from 'apollo/reactive-vars'
import ROUTES from 'constants/routes'
import { useCheckDuplicateEmailLazyQuery } from 'generated/graphql'
import SocialButton from '../common/SocialButton'
import { DEBUG_ENV } from '../../../index'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  setEmailInput: (newValue: string) => void
  onSubmit: (variables: UserCreator) => void
  onSocial: (provider: SocialProvider) => void
  emailInput: string

  onGoToLogIn: () => void
  setEmailNotValidated: Dispatch<SetStateAction<boolean>>
}

const SocialSignUpPage: React.FC<Props> = ({
  onGoToLogIn,
  setEmailInput,
  setEmailNotValidated,
  emailInput,
}: Props) => {
  const deviceType = useDeviceType()
  const [checkDuplicateEmail] = useCheckDuplicateEmailLazyQuery()

  const { register, handleSubmit, errors } = useForm<UserCreator>()
  const [rulesAgreed, setRulesAgreed] = useState<boolean>(false)
  const [promotionsAgreed, setPromotionsAgreed] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<boolean>(false)
  const classes = useStyles(theme)

  const checkEmail = async (email: string) => {
    try {
      const result = checkDuplicateEmail({
        variables: {
          data: email,
        },
      }).then(({ error, data }) => {
        if (error) {
          setApolloError(`A server error has occurred.`)
          return true
        }
        if (!!data?.checkDuplicateEmail) {
          setApolloError(
            `This email address is taken. Please pick a different one.`
          )
        }

        return data?.checkDuplicateEmail ?? true
      })

      return result
    } catch (err: any) {
      console.error(err)
    }
  }

  const onSubmit = async () => {
    if (!rulesAgreed) {
      setErrorState(!errorState)
      return
    }

    try {
      const noDuplicateUser = await checkEmail(emailInput)
      if (!noDuplicateUser) {
        setEmailNotValidated(prevFlag => !prevFlag)
      }
    } catch (error) {}
  }

  const handleRulesCheckboxChange = () => {
    if (DEBUG_ENV)
      console.debug(`Clicked on checkbox - handleRulesCheckboxChange`)
    setRulesAgreed(!rulesAgreed)
    if (errorState) {
      setErrorState(errorState => !errorState)
    }
  }

  const handlePromotionalCheckboxChange = () => {
    if (DEBUG_ENV)
      console.debug(`Clicked on checkbox - handlePromotionalCheckboxChange`)
    setPromotionsAgreed(!promotionsAgreed)
  }

  return (
    <AuthBoxContainer
      headingText={'Sign Up'}
      descriptionText={
        <Typography sx={{ fontSize: 14, letterSpacing: 0.4 }}>
          By continuing, you are setting up an fnchart account and agree to our{' '}
          <Link
            target="_blank"
            to={ROUTES.TERMS_OF_SERVICE}
            style={{ textDecoration: 'none', color: '#5993F7' }}
          >
            {'Terms of Service'}
          </Link>
          {','}
          <Link
            target="_blank"
            to={ROUTES.SITE_RULES}
            style={{ textDecoration: 'none', color: '#5993F7' }}
          >
            {' Site Rules'}
          </Link>
          {', and'}
          <Link
            target="_blank"
            to={ROUTES.PRIVACY_POLICY}
            style={{ textDecoration: 'none', color: '#5993F7' }}
          >
            {' Privacy Policy'}
          </Link>
          {'.'}
        </Typography>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            marginTop: '16px',
            marginBottom: '32px',
            marginLeft: '-9px',
            fontSize: '12px',
            [`& span, & span.${checkboxClasses.checked}`]: {
              color: 'white',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <Checkbox onChange={handleRulesCheckboxChange} />
            <span>
              Agree to site rules, terms of service, and privacy policy{' '}
            </span>
          </Box>
          {errorState && (
            <p className={classes.checkboxWarningText}>
              You cannot signup if you don't agree with our rules, terms of
              service, and privacy policy
            </p>
          )}
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
          >
            {' '}
            <Checkbox onChange={handlePromotionalCheckboxChange} />
            <span>
              Agree to receive promotional & marketing emails (OPTIONAL)
            </span>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <Checkbox onChange={handlePromotionalCheckboxChange} />
            <span>
              Agree to use of data for personalized advertising (OPTIONAL)
            </span>
          </Box>
        </Box>
        <SocialButton
          text={'Continue with Google'}
          iconUrl={'./media/GoogleIcon.png'}
          onHandleClick={() => {
            if (rulesAgreed === false) {
              // TODO: throw error at user informing them that this is a required field
              setErrorState(!errorState)
              return
            }
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Google,
            })
          }}
          socialProvider={'google'}
        />
        <SocialButton
          text={'Continue with Facebook'}
          iconUrl={'./media/FacebookIcon.png'}
          onHandleClick={() => {
            if (rulesAgreed === false) {
              // TODO: throw error at user informing them that this is a required field
              setErrorState(!errorState)
              return
            }

            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Facebook,
            })
          }}
          socialProvider={'facebook'}
        />
        <SocialButton
          text={'Continue with Amazon'}
          iconUrl={'./media/AmazonIcon.png'}
          onHandleClick={() => {
            if (!rulesAgreed) {
              // TODO: throw error at user informing them that this is a required field
              setErrorState(!errorState)

              // setApolloError(
              //   `You cannot signup if you don't agree with our rules, terms of service, and privacy policy`
              // )
              return
            }

            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Amazon,
            })
          }}
          socialProvider={'amazon'}
        />
        <SpaceBetween spaceSize={3} sx={{ mb: 3, mt: 3 }}>
          <Box
            sx={{
              '& span': {},
              width: '100%',
              position: 'relative',
              borderTopWidth: 1,
              borderTopColor: colors.textGrey,
              borderTopStyle: 'solid',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: deviceType === DeviceType.Mobile ? '34px' : '40px',
              marginBottom: deviceType === DeviceType.Mobile ? '34px' : '40px',
            }}
          >
            <Box
              component="span"
              sx={{
                position: 'absolute',
                top: '0',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                fontFamily: fonts.dm_sans,
                fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
                lineHeight: deviceType === DeviceType.Mobile ? '20px' : '18px',
                fontWeight: deviceType === DeviceType.Mobile ? 400 : 'lighter',
                backgroundColor:
                  deviceType === DeviceType.Mobile
                    ? colors.drawer_bg_right
                    : colors.box_bg,
                padding: '0 7px',
              }}
            >
              OR
            </Box>
          </Box>
          <TextInput
            name="email"
            label="Email"
            autoComplete="email"
            onChange={async e => {
              setEmailInput(e)
            }}
            ref={register({
              required:
                'Please enter your email address. This is a required field.',
              pattern: {
                value: regex.email,
                message: 'You have entered an invalid email address.',
              },
            })}
            error={errors.email ? errors.email.message : undefined}
          />
        </SpaceBetween>
        <AuthSubmitButton>Continue</AuthSubmitButton>
      </form>
      <AlreadyRegisteredSection onGoToLogIn={onGoToLogIn} />
    </AuthBoxContainer>
  )
}

export default SocialSignUpPage
