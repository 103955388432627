import classNames from 'classnames'
import React, { forwardRef, useEffect } from 'react'
import ReactMarkdown, { uriTransformer } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import theme from '../../../mui-theme'
import useStyles from './styles'

interface Props {
  markdownSource: string
  isPreview?: boolean
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)

  const [markdown, setMarkdown] = React.useState<string>('')

  useEffect(() => {
    setMarkdown(
      props.markdownSource.replace(/\$\[([^\]]+)\]/g, (match, ticker) => {
        // Correctly use the ticker extracted from the regex capturing group
        return (
          `<a ` +
          `href="/asset/${ticker.toLowerCase()}" ` +
          `class="${classes.ticker}" ` +
          `target="_blank" ` +
          `rel="noopener noreferrer">` +
          `${ticker}` +
          `</a>`
        )
      })
    )
  }, [props.markdownSource, classes.ticker])

  const renderLink = ({ href, children, ...props }: any) => (
    <a
      href={uriTransformer(href)}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  )

  return (
    <div
      className={classNames(
        classes.markdownResult,
        props.isPreview && classes.isPreview
      )}
    >
      <ReactMarkdown
        children={markdown}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: renderLink,
        }}
      ></ReactMarkdown>
    </div>
  )
})
