import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export default makeStyles(
  {
    root: {
      width: '100%',
      padding: 4,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    labelFocused: {
      fontSize: '14px !important',
      color: `${colors.text_white} !important`,
      fontWeight: 400,
      lineHeight: '20px',
    },
    formLabelRoot: {
      fontSize: 14,
      color: colors.text_white,
      fontWeight: 400,
      lineHeight: '20px',
    },
    switchRoot: {
      padding: 0,
      boxSizing: 'border-box',
      width: 54,
      height: 32,
      marginLeft: 32,
    },
    switchBase: {
      padding: 0,
      transitionDuration: '300ms',

      color: colors.textGrey,
      '&.Mui-checked': {
        color: colors.white,
        transform: 'translateX(21px)',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: colors.blue,
        opacity: 1,
      },
    },
    checked: {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colors.textGreyLight,
        opacity: 1,
        border: 0,
      },
    },
    track: {
      width: 54,
      height: 32,
      backgroundColor: colors.textGreyLight,

      borderRadius: 100,
      opacity: 1,
    },
    thumb: {
      // border: '6px solid #fff',
      color: colors.white,
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      margin: 4,
    },
  },
  { index: 1 }
)
