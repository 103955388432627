import { ChangeEvent, forwardRef } from 'react'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { Switch } from '@mui/material'

interface Props {
  label?: string
  value?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)

  return (
    <>
      <FormControl
        classes={{
          root: classes.root,
        }}
      >
        <FormLabel
          classes={{
            root: classes.formLabelRoot,
            focused: classes.labelFocused,
          }}
        >
          {props.label}
        </FormLabel>
        <Switch
          checked={props.value}
          classes={{
            root: classes.switchRoot,
            thumb: classes.thumb,
            switchBase: classes.switchBase,
            track: classes.track,
            checked: classes.checked,
          }}
          onChange={props.onChange}
        />
      </FormControl>
    </>
  )
})
