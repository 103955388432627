import theme from '../../mui-theme'
import useStyles from './styles'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import ROUTES from 'constants/routes'

export const UserSettingsMobile = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      <div className={classes.topNavBar}>
        <div className={classes.iconBackWrapper}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            className={classes.iconBack}
            onClick={() => navigate(-1)}
          />
        </div>
        <div className={classes.header}>Settings</div>
        <div className={classes.headerRight}></div>
      </div>
      <div className={classes.sectionHeader}>User settings</div>
      <div
        className={classes.menuLink}
        onClick={() =>
          navigate(ROUTES.USER_SETTINGS_EDIT_MOBILE, {
            state: { selectedTab: 0 },
          })
        }
      >
        Profile
      </div>
      <div
        className={classes.menuLink}
        onClick={() =>
          navigate(ROUTES.USER_SETTINGS_EDIT_MOBILE, {
            state: { selectedTab: 1 },
          })
        }
      >
        Account
      </div>
      <div
        className={classes.menuLink}
        onClick={() =>
          navigate(ROUTES.USER_SETTINGS_EDIT_MOBILE, {
            state: { selectedTab: 2 },
          })
        }
      >
        Notifications
      </div>
      <div className={classes.sectionHeader}>About</div>
      <div className={classes.menuLink} onClick={() => navigate(ROUTES.ABOUT)}>
        About fnchart
      </div>
      <div
        className={classes.menuLink}
        onClick={() => navigate(ROUTES.SITE_RULES)}
      >
        Site rules
      </div>
      <div
        className={classes.menuLink}
        onClick={() => navigate(ROUTES.TERMS_OF_SERVICE)}
      >
        Terms of Service
      </div>
      <div
        className={classes.menuLink}
        onClick={() => navigate(ROUTES.PRIVACY_POLICY)}
      >
        Privacy Policy
      </div>
      <div className={classes.sectionHeader}>Support</div>
      <div
        className={classes.menuLink}
        onClick={() => navigate(ROUTES.CONTACT_US)}
      >
        Contact
      </div>
      <div
        className={classes.menuLink}
        onClick={() => navigate(ROUTES.DELETE_USER)}
      >
        Delete account
      </div>
    </div>
  )
}

export default UserSettingsMobile
