import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import TickBlueCircleImage from './TickBlueCircleImage'

const TickBlueCircle: React.FC = () => {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <TickBlueCircleImage className={classes.img} />
    </div>
  )
}

export default TickBlueCircle
