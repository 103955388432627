import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    fontFamily: fonts.dm_sans,
    width: '100%',
    position: 'relative',
    backgroundColor: colors.search_bg,
    overflowY: 'auto',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 20px 20px',
      boxSizing: 'border-box',
    },
  },
  content: {
    marginBottom: 88,
    display: 'flex',
    width: 1121,
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },

  mainContent: {
    width: '100%',
  },

  contentSection: {
    marginTop: 80,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('lg')]: {
      marginTop: 32,
      flexDirection: 'column',
      borderBottom: `1px solid ${colors.drawer_border}`,

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },

  reverseOrder: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
    },
  },

  topContentSection: {
    marginTop: 120,
    marginBottom: 53,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.down('lg')]: {
      marginTop: 32,
      marginBottom: 53,
      flexDirection: 'column',
    },
  },
  contentLeft: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'start',
    width: '50%',

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      width: '100%',
    },
  },
  contentText: {
    width: 460,
    paddingBottom: 27,

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 32,
    },
  },

  contentTextLeft: {
    width: 460,
    paddingBottom: 27,
    textAlign: 'left',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  contentRight: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'end',

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'start',
    },
  },

  imageWrapper: {
    '& img': {
      borderRadius: 15,
    },
    cursor: 'pointer',
  },

  chartImageWrapper: {
    position: 'absolute',
    left: 0,
    '& img': {
      borderRadius: 15,
    },
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      position: 'relative',
    },
  },

  sectionMainHeader: {
    color: colors.text_white,
    fontSize: 48,
    fontWeight: 500,
    lineHeight: '52px',
    letterSpacing: 0,
    paddingBottom: 40,

    [theme.breakpoints.down('lg')]: {
      fontSize: 32,
      lineHeight: '38px',
      letterSpacing: 2,
      textAlign: 'center',
      paddingBottom: 32,
    },
  },
  sectionHeader: {
    color: colors.text_white,
    fontSize: 40,
    fontWeight: 500,
    lineHeight: '46px',
    letterSpacing: 0,
    paddingBottom: 40,

    [theme.breakpoints.down('lg')]: {
      marginTop: 16,
      fontSize: 28,
      lineHeight: '32px',
      paddingBottom: 16,
      letterSpacing: 1,
    },
  },

  sectionMainBody: {
    color: colors.text_white,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '28px',

    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
      textAlign: 'center',
    },
  },

  sectionBody: {
    color: colors.white_faded,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '28px',

    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 460,
    paddingTop: 40,

    [theme.breakpoints.down('lg')]: {
      paddingTop: 32,
      width: '100%',
      flexDirection: 'column',
    },
  },
  joinBtn: {
    fontFamily: fonts.dm_sans,
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
    height: 50,
    borderRadius: 100,
    padding: '14px 15px 13px 15px',
    width: 280,
    textTransform: 'none',
    letterSpacing: 0,

    //backgroundColor: colors.blue,
    //color: colors.white,

    backgroundColor: colors.white,
    color: colors.search_bg,

    '&:hover': {
      //backgroundColor: colors.white,
      //color: colors.search_bg,

      backgroundColor: colors.blue,
      color: colors.white,
    },

    [theme.breakpoints.down('lg')]: {
      alignSelf: 'center',
      fontSize: 16,
      lineHeight: '20px',
      width: 230,
    },
  },
  watchVideoBtn: {
    alignSelf: 'flex-end',
    textDecoration: 'underline',
    color: colors.blue_lighter,
    fontWeight: 500,
    fontSize: 20,
    height: 50,
    lineHeight: '24px',
    padding: '17px 25px 13px 25px',
    textTransform: 'none',

    '&:hover': {
      color: colors.blue_darker,
      textDecoration: 'underline',
    },

    [theme.breakpoints.down('lg')]: {
      marginTop: 8,
      alignSelf: 'center',
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  bottomBanner: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 120,
    width: 1121,
    height: 340,
    backgroundColor: colors.blue,
    borderRadius: 15,

    [theme.breakpoints.down('lg')]: {
      marginTop: 64,
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
  },
  bottomBannerLeft: {
    width: 785,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },

  bottomBannerLeftInner: {
    paddingLeft: 49,
    paddingTop: 73,

    [theme.breakpoints.down('lg')]: {
      padding: 24,
    },
  },

  bottomBannerText: {
    width: 563,
    color: colors.white_faded,
    fontSize: 40,
    fontWeight: 500,
    lineHeight: '46px',
    letterSpacing: 0,
    paddingBottom: 40,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      fontSize: 28,
      lineHeight: '32px',
      paddingBottom: 24,
    },
  },

  joinTheCommBtn: {
    fontFamily: fonts.dm_sans,
    borderRadius: 100,
    width: 280,
    letterSpacing: 0,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'none',
    padding: '14px 15px 13px 15px',

    backgroundColor: colors.white,
    color: colors.search_bg,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.white,

    '&:hover': {
      //backgroundColor: colors.white,
      //color: colors.search_bg,

      backgroundColor: colors.blue,
      color: colors.white,
      borderStyle: 'solid',
      borderColor: colors.white,
      borderWidth: 1,
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      lineHeight: '20px',
      marginBottom: 12,
    },
  },

  bottomBannerImg: {
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,

    [theme.breakpoints.down('lg')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
    },
  },

  footer: {
    borderTop: `1px solid ${colors.drawer_border}`,
  },
  footerInner: {
    width: 1121,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 40,
    paddingBottom: 144,
  },
  leftFooter: {
    width: '50%',
  },
  rightFooter: {
    width: '50%',
  },
  footerCopyright: {
    color: colors.textGreyLight,
    marginTop: 24,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
  },
  footerLinksRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 24,
  },
  footerLinksColumn: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
    color: colors.white_faded,
    textDecoration: 'none',
    width: '33.33%',
  },
}))
