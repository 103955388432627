import { Auth } from '@aws-amplify/auth'
import awsConfig from './aws-exports'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(',')

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(',')

export let updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    //redirectSignIn: isLocalhost
    //  ? localRedirectSignIn
    //  : productionRedirectSignIn,
    //redirectSignOut: isLocalhost
    //  ? localRedirectSignOut
    //  : productionRedirectSignOut,
    redirectSignIn: awsConfig.oauth.redirectSignIn,
    redirectSignOut: awsConfig.oauth.redirectSignOut,
  },
}

export const initiateAuth = () => {
  Auth.configure(updatedAwsConfig)
}

export interface UserInputer {
  email: string
  password: string
  [ix: string]: string | boolean | undefined
}

export interface UserLoginer extends UserInputer {
  shouldRemember?: boolean
  [index: string]: string | boolean | undefined
}

export interface UserCreator extends UserInputer {
  username: string
  [ix: string]: string | undefined
}

export interface UserOutputer {
  id: string
  email: string
  username: string
  email_verified: boolean | null
  [ix: string]: string | boolean | null
}
