import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import LogoWithoutTextImage from './LogoWithoutText'
import ROUTES from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery, useGetSymbolQuery } from 'generated/graphql'

const LogoWithoutText: React.FC = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const { data: getCurrentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  let userLastViewedSymbol = 'spy'
  let userLastViewedSymbolType = 'stock'

  if (getCurrentUserData?.getCurrentUser) {
    userLastViewedSymbol =
      getCurrentUserData?.getCurrentUser?.lastViewedSymbol || ''
  }

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: getCurrentUserData?.getCurrentUser?.lastViewedSymbol || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  if (symbolData?.getSymbol?.type) {
    userLastViewedSymbolType = symbolData.getSymbol?.type.toLowerCase()
  }

  function navigateToSymbol(): void {
    navigate(
      ROUTES.ASSET.replace(':symbol', userLastViewedSymbol)
        .replace(':type', userLastViewedSymbolType)
        .toLowerCase()
    )
  }

  return (
    <div className={classes.wrapper} onClick={navigateToSymbol}>
      <LogoWithoutTextImage className={classes.img} />
    </div>
  )
}

export default LogoWithoutText
