import React, { useState, useEffect, ReactElement } from 'react'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

interface CustomTooltipProps extends Omit<TooltipProps, 'title'> {
  title: React.ReactNode
  children: ReactElement
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  title,
  children,
  ...props
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isMobile) {
    return children
  }

  return (
    <Tooltip title={title || ''} {...props}>
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
