import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: fonts.dm_sans,
    width: '100%',
    alignItems: 'stretch',
    position: 'relative',
    backgroundColor: colors.drawer_bg_right,
    overflowY: 'auto',

    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 20px 20px',
      boxSizing: 'border-box',
    },
  },
  content: {
    marginTop: 52,
    marginBottom: 88,
    display: 'flex',
    width: 1162,
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.drawer_bg_right,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginTop: 32,
    },
  },
  startBtn: {
    boxShadow: 'none',
    color: colors.menu_bg,
    fontFamily: fonts.dm_sans,
    letterSpacing: 2.75,
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 'bold',
    height: 36,
    border: `1px solid ${colors.white}`,
    borderRadius: 20,
    backgroundColor: colors.white,
    padding: '12px 24px',
    marginTop: 48,
    '&:hover': {
      color: colors.white,
    },
  },
  logoWrapper: {
    paddingTop: 161,
  },
  aboutText: {
    color: colors.text_white,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: 0.5,

    [theme.breakpoints.down('lg')]: {
      fontSize: 32,
      lineHeight: '38px',
      letterSpacing: 2,
      textAlign: 'center',
      paddingBottom: 32,
      paddingTop: 0,
    },
  },
  aboutTextWrapper: {
    width: 407,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  aboutVideoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 360,

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  videoWrapper: {
    width: 640,
    height: '100%',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  boxWrapper: {
    paddingTop: 47,
    display: 'flex',
    justifyContent: 'space-between',
    width: 1162,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      paddingTop: 0,
      flexDirection: 'column',
    },
  },
  box: {
    backgroundColor: colors.box_bg,
    width: 257,
    height: 'auto',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginTop: 32,
    },
  },
  innerBox: {
    margin: '32px 24px',
  },
  icon: {
    color: colors.white,
  },
  pencilIcon: {
    color: colors.blue,
  },
  messageIcon: {
    color: colors.very_soft_blue,
  },
  arrowUp: {
    color: colors.blue,
  },
  arrowDown: {
    color: colors.red_light,
    paddingLeft: 9,
  },
  boxTitle: {
    fontSize: 18,
    lineHeight: '24px',
    color: colors.text_white,
    marginTop: 34,
  },
  boxText: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.textGrey,
    marginTop: 8,
  },
}))
