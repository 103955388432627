import { ApolloCache } from '@apollo/client'
import { UserOverlayProps } from '..'
import {
  GetFollowersDocument,
  GetFollowersQuery,
  GetFollowersQueryVariables,
  GetFollowingDocument,
  GetFollowingQuery,
  GetFollowingQueryVariables,
  User,
} from 'generated/graphql'
import { FollowerData, FollowingData } from '../interfaces/userTabItem'

export const updateGetFollowingCacheAfterFollow = (
  cache: ApolloCache<any>,
  userId: string,
  props: UserOverlayProps,
  followings: FollowingData[],
  followers: FollowerData[]
): void => {
  if (!props.currentUserProfile) return

  // Update followers count of the user being followed
  cache.modify({
    id: cache.identify({ __typename: 'User', id: userId }),
    fields: {
      followersCount(existingCount = 0) {
        return existingCount + 1
      },
    },
  })

  const existingData = cache.readQuery<GetFollowingQuery>({
    query: GetFollowingDocument,
    variables: { userId: props.currentUserProfile.userId },
  })

  if (!existingData) return

  // Find the user being followed
  let foundFollowingUser =
    followings.find(user => user.userId === userId) ||
    followers.find(user => user.userId === userId)

  if (!foundFollowingUser && userId === props.userProfile?.userId) {
    foundFollowingUser = {
      userId: props.userProfile.userId,
      username: props.userProfile.nickname,
      emailHash: props.userProfile.emailHash,
      followers: props.userProfile.followersCount,
      following: props.userProfile.followingCount,
      isCurrentUserFollowing: props.userProfile.isCurrentUserFollowing,
      isFollowerOfCurrentUser: followers.some(
        follower => follower.userId === props.currentUserProfile?.userId
      ),
    }
  }

  if (!foundFollowingUser) return

  const newFollowingUser = {
    __typename: 'User',
    id: foundFollowingUser.userId,
    nickname: foundFollowingUser.username,
    emailHash: foundFollowingUser.emailHash,
    createdAt: null,
    followersCount: foundFollowingUser.followers + 1,
    followingCount: foundFollowingUser.following,
    twitter: null,
    facebook: null,
    instagram: null,
    tradingView: null,
    reddit: null,
  }

  // only update if existing data doesn't contain new following user
  if (existingData.getFollowing.some(user => user.id === newFollowingUser.id))
    return

  const updatedData = {
    ...existingData,
    getFollowing: [...existingData.getFollowing, newFollowingUser],
  }

  cache.writeQuery({
    query: GetFollowingDocument,
    variables: { userId: props.currentUserProfile.userId },
    data: updatedData,
  })
}

export const updateGetFollowerCacheAfterFollow = (
  cache: ApolloCache<any>,
  userId: string,
  props: UserOverlayProps
) => {
  const existingData = cache.readQuery<GetFollowersQuery>({
    query: GetFollowersDocument,
    variables: { userId: userId },
  })

  // Update following count of the current user profile
  cache.modify({
    id: cache.identify({
      __typename: 'User',
      id: props.currentUserProfile?.userId,
    }),
    fields: {
      followingCount(existingCount = 0) {
        return existingCount + 1
      },
    },
  })

  if (!props.currentUserProfile || !existingData) return

  // newFollowerUser should be the currentUserProfile (logged in user)
  const newFollowerUser: User = {
    __typename: 'User',
    id: props.currentUserProfile.userId,
    nickname: props.currentUserProfile.nickname,
    emailHash: props.currentUserProfile.emailHash,
    createdAt: null,
    followersCount: props.currentUserProfile.followersCount,
    followingCount: props.currentUserProfile.followingCount + 1,
    twitter: null,
    facebook: null,
    instagram: null,
    tradingView: null,
    reddit: null,
    blocked: false,
  }

  // only update if existing data doesn't contain the current user as follower
  if (
    existingData.getFollowers
      .map((user: any) => user.id)
      .includes(newFollowerUser.id)
  )
    return

  const updatedData = {
    ...existingData,
    getFollowers: [...existingData.getFollowers, newFollowerUser],
  }

  cache.writeQuery({
    query: GetFollowersDocument,
    variables: { userId: userId },
    data: updatedData,
  })
}

export const updateGetFollowingCacheAfterUnfollow = (
  cache: ApolloCache<any>,
  userId: string,
  props: UserOverlayProps
) => {
  if (props.currentUserProfile === undefined) return

  cache.modify({
    id: cache.identify({ __typename: 'User', id: userId }),
    fields: {
      followersCount(existingCount = 0) {
        console.log(existingCount)
        return existingCount - 1
      },
    },
  })

  const existingData = cache.readQuery<
    GetFollowingQuery,
    GetFollowingQueryVariables
  >({
    query: GetFollowingDocument,
    variables: { userId: props.currentUserProfile.userId },
  })

  if (!existingData || !existingData.getFollowing) return

  const updatedFollowing = existingData.getFollowing.filter(
    user => user.id !== userId
  )

  const updatedData = {
    ...existingData,
    getFollowing: updatedFollowing,
  }

  cache.writeQuery({
    query: GetFollowingDocument,
    variables: { userId: props.currentUserProfile.userId },
    data: updatedData,
  })
}

export const updateGetFollowerCacheAfterUnfollow = (
  cache: ApolloCache<any>,
  userId: string,
  props: UserOverlayProps
) => {
  const existingData = cache.readQuery<
    GetFollowersQuery,
    GetFollowersQueryVariables
  >({
    query: GetFollowersDocument,
    variables: { userId: userId },
  })

  cache.modify({
    id: cache.identify({
      __typename: 'User',
      id: props.currentUserProfile?.userId,
    }),
    fields: {
      followingCount(existingCount = 0) {
        return existingCount - 1
      },
    },
  })

  if (!existingData || !existingData.getFollowers) return

  const updatedFollowers = existingData.getFollowers.filter(
    user => user.id !== props.currentUserProfile?.userId
  )

  const updatedData = {
    ...existingData,
    getFollowers: updatedFollowers,
  }

  cache.writeQuery({
    query: GetFollowersDocument,
    variables: { userId: userId },
    data: updatedData,
  })
}
