import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import classNames from 'classnames'
import React, { forwardRef, useEffect } from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'

interface Props {
  error?: string
  name: string
  title?: string
  label?: string
  placeholder?: string
  placeholderHtml?: React.ReactNode
  pattern?: string
  disabled?: boolean
  label_outside_input?: boolean
  no_label?: boolean
  no_underline?: boolean
  type?: string
  autoComplete?: string
  onChange?: (e: string) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)

  return (
    <>
      <FormControl
        classes={{
          root: classes.root,
        }}
      >
        {!props.no_label && props.label_outside_input && (
          <FormLabel
            htmlFor={`input-${props.name}`}
            error={!!props.error}
            classes={{
              root: classes.formLabelRoot,
              focused: classes.labelFocused,
            }}
          >
            {props.label}
          </FormLabel>
        )}
        <div className={classes.inputBox}>
          {!props.no_label && !props.label_outside_input && (
            <InputLabel
              htmlFor={`input-${props.name}`}
              error={!!props.error}
              classes={{
                root: classes.inputLabelRoot,
                focused: classes.labelFocused,
                shrink: classes.labelShrink,
              }}
            >
              {props.label}
            </InputLabel>
          )}
          <Input
            inputProps={{
              pattern: props.pattern,
              title: props.title,
            }}
            error={!!props.error}
            id={`input-${props.name}`}
            autoComplete={props.autoComplete}
            inputRef={ref}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              props.onChange && props.onChange(e.target.value)
            }}
            onBlur={e => {
              if (!!props?.onBlur) {
                props.onBlur(e)
              }
            }}
            onFocus={e => {
              if (!!props?.onBlur) {
                props.onBlur(e)
              }
            }}
            name={props.name}
            placeholder={props.placeholder}
            type={props.type}
            disabled={props.disabled}
            readOnly={props.disabled}
            classes={{
              input: classNames(
                classes.input,
                props.label_outside_input && classes.inputFullHeight
              ),
              underline: classNames(
                classes.underline,
                props.no_underline && classes.noUnderline
              ),
              root: classNames(
                props.label_outside_input
                  ? classes.inputRootNoMarginTop
                  : classes.inputRootMarginTop,
                classes.inputRoot,
                props.no_underline
                  ? classes.noUnderline
                  : classes.inputRootUnderline
              ),
            }}
          />
          {props.placeholderHtml && (
            <div className={classes.placeholderOverlay}>
              {props.placeholderHtml}
            </div>
          )}
        </div>
      </FormControl>
      {props.error && (
        <FormHelperText
          error={true}
          classes={{
            root: classes.formHelperRoot,
          }}
        >
          {props.error}
        </FormHelperText>
      )}
    </>
  )
})
