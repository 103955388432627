import React from 'react'
import { Link } from 'react-router-dom'
import { colors } from '../../../constants'
import theme from '../../../mui-theme'
import useStyles from './styles'

//import debounce from 'lodash.debounce'
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Modal, Typography } from '@mui/material'
import TickBlueCircle from 'components/Images/TickBlueCircle'
import ROUTES from 'constants/routes'
import SocialButton from '../common/SocialButton'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  open: boolean
  redirectUrl?: string
  onClose: () => void
}

const SignUpModal: React.FC<Props> = ({
  open,
  redirectUrl,
  onClose,
}: Props) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)

  const handleSocialLogin = (provider: CognitoHostedUIIdentityProvider) => {
    if (redirectUrl) {
      localStorage.setItem('redirectTo', redirectUrl + '?referrer=signin')
    }

    Auth.federatedSignIn({ provider })
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <>
        {deviceType === DeviceType.Mobile && (
          <FontAwesomeIcon
            icon={faClose}
            className={classes.iconClose}
            size={'lg'}
            color={colors.textGrey}
            onClick={onClose}
          />
        )}
        <Box className={classes.modalContent}>
          {deviceType === DeviceType.Desktop && (
            <Box className={classes.imageSection}>
              <img
                className={classes.image}
                src="/media/SignUpModalImage.png"
                alt="Sign Up"
              />
            </Box>
          )}
          <Box className={classes.textContent}>
            {deviceType === DeviceType.Desktop && (
              <FontAwesomeIcon
                icon={faClose}
                className={classes.iconClose}
                color={colors.textGrey}
                onClick={onClose}
              />
            )}
            <Typography variant="h6" className={classes.header}>
              Sign Up and Join the fnchart Community
            </Typography>
            <Box className={classes.listItem}>
              <div className={classes.icon}>
                <TickBlueCircle />
              </div>
              <div>
                Create a post and connect with like-minded people who share your
                interests
              </div>
            </Box>
            <Box className={classes.listItem}>
              <div className={classes.icon}>
                <TickBlueCircle />
              </div>
              <div>
                Comment on posts, vote on your favorite content, and have your
                say
              </div>
            </Box>
            <Box className={classes.listItem}>
              <div className={classes.icon}>
                <TickBlueCircle />
              </div>
              <div>
                Stay updated on the latest news, updates, and promotions
              </div>
            </Box>
            <Box
              sx={{
                marginTop: '-2px',
                marginBottom: '-21px',
              }}
            >
              <SocialButton
                text={'Continue with Google'}
                iconUrl={'/media/GoogleIcon.png'}
                onHandleClick={() =>
                  handleSocialLogin(CognitoHostedUIIdentityProvider.Google)
                }
                socialProvider={'google'}
              />
            </Box>
            <Box
              sx={{
                marginTop: '-2px',
                marginBottom: '-21px',
              }}
            >
              <SocialButton
                text={'Sign up with Facebook'}
                iconUrl={'/media/FacebookIcon.png'}
                socialProvider={'facebook'}
                onHandleClick={() =>
                  handleSocialLogin(CognitoHostedUIIdentityProvider.Facebook)
                }
              />
            </Box>
            <Box>
              <SocialButton
                text={'Sign up with Amazon'}
                iconUrl={'/media/AmazonIcon.png'}
                socialProvider={'amazon'}
                onHandleClick={() =>
                  handleSocialLogin(CognitoHostedUIIdentityProvider.Amazon)
                }
              />
            </Box>
            <Link to={ROUTES.SIGNUP} className={classes.SignUpWithEmail}>
              Sign up with email
            </Link>
            <div className={classes.alreadyHaveAccount} onClick={onClose}>
              Already have an account?{' '}
              <Link
                to={
                  ROUTES.LOGIN +
                  '?redirectTo=' +
                  redirectUrl +
                  '?referrer=signin'
                }
                className={classes.loginLink}
              >
                Log in
              </Link>
            </div>
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default SignUpModal
