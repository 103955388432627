import { format } from 'date-fns'
import moment, { Moment } from 'moment'
import 'moment-timezone'

export const DEV_ENVIRONMENT =
  process.env?.REACT_APP_DEPLOYMENT_ENV !== 'PRODUCTION'

export const TAG_PREFIX = {
  ASSET: '$',
}

export const colors = {
  main: '#141420',
  bell_bg: '#30323E',
  drawer_bg: '#292B38',
  drawer_bg_hover: '#2F323E',
  drawer_bg_highlight: '#3E404C',
  drawer_border: '#3a3c48',
  drawer_bg_light: '#31333f',
  drawer_bg_light_hover: '#373945',
  drawer_bg_light_highlight: '#464754',
  drawer_bg_right: '#20222E',
  chart_bg_color: '#0D0D14',
  dropdown_background: '#313340',
  dropdown_hoverover: '#3B3D4D',
  menu_bg: '#141420',
  menu_item_bg_hover: '#35374480',
  blue: '#4C61EE',
  blue_light: '#4385F4',
  blue_lighter: '#5993F7',
  blue_darker: '#557AB9',
  very_soft_blue: '#9AA5EF',
  red_light: '#D36D6D',
  cyan_blue_light_faded: '#728ab8',
  blue_medium_light: '#394bbf',
  blue_faded: '#4859c4',
  blue_faded_light: '#687AF0',
  dark_blue: '#3a4692',
  red: '#E10040',
  white: '#FFFFFF',
  white_faded: '#F2F4F5',
  textGrey: '#838692',
  textGreyLight: '#A0A4B2',
  greyDark: '#484A57',
  greyDarker: '#5D5D5E',
  input_bg: '#313341',
  text_white: '#ffffff',
  text_input_inactive: '#5D5F6D',
  border_light_grey: '#585965',
  error: '#FF6E6E',
  box_bg: '#292B38',
  topic_posts_before_background: '#404250',
  topic_posts_before_background_right: '#484A59',
  topic_border_bottom: '#414350',
  black: '#000000',
  border: '#414350',
  search_bg: '#14141F',
  orange: '#E79E18',
  search_input_bg: '#1C1B25',
  tab_bg: '#393C49',
} as const

export const fonts = {
  dm_sans: "'DM Sans', sans-serif",
}

// TODO: investigate useless escape
/* eslint-disable no-useless-escape */

export const regex = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  username: /^\w*$/,
  url: /^(?:(ftp|http|https)?:\/\/)?(?:www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.)[a-zA-Z]{2,}([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
  referral: /[a-zA-Z0-9@:%._\+~#=]{2,256}/,
  message: /[a-zA-Z0-9@:%._\+~#=]{4,256}/,
  code: /[a-zA-Z0-9@:%._\+~#=]{2,256}/,
  verification_code: /[0-9]{6}/,
}

export const timezones = moment.tz.names().map(x => ({
  value: x,
  label: x,
}))

export const GOOGLE_ANALYTICS_ID = DEV_ENVIRONMENT
  ? 'G-8JQP9TMYJ0' // bidecho.com GA webapp property
  : 'G-0DL9EF8HHC' // fnchart.com GA webapp property

export interface IExch {
  exchDate?: string
  symbolDate?: string
  tz?: string
}

//export const utcToExchangeMoment = (date: string, exch?: IExch) => {
//  const tz = exch?.tz ?? 'America/New_York'
//  const close = exch?.close ?? '16:59:59.000'
//  return moment.tz(date + 'T' + close, tz)
//}

export interface IExDate {
  date: string
  datetime: Moment
  toApiString(): string
}

const convertToTwoDigitString = (date: number) => {
  // Convert the number to a string
  let numberString = date.toString()

  // Add a leading zero if the length is 1
  if (numberString.length === 1) {
    numberString = '0' + numberString
  }

  return numberString
}

export class ExDate extends Date implements IExDate {
  public date: string
  public datetime: Moment

  constructor(dateStr?: any, exch?: IExch) {
    const DEFAULT_TIMEZONE = 'America/New_York'
    let effectiveDateStr: string

    if (typeof dateStr === 'number') {
      // Handle Unix timestamp
      effectiveDateStr = format(new Date(dateStr), 'yyyy-MM-dd')
    } else if (typeof dateStr === 'string' && dateStr) {
      // Handle date string
      effectiveDateStr = dateStr.split('T')[0]
    } else {
      // Default to current date if dateStr is not provided or invalid
      effectiveDateStr = format(new Date(), 'yyyy-MM-dd')
    }

    // Determine the timezone
    const timezone = exch?.tz || DEFAULT_TIMEZONE

    // Adjust the date string for timezone
    const { adjDateStr, adjDateStrUTC } = dateAdj(effectiveDateStr, timezone)

    // Call the parent constructor with the adjusted date string
    super(adjDateStr)

    // Set the date and datetime properties
    this.date = adjDateStr.split('T')[0]
    this.datetime = moment(adjDateStrUTC).tz(timezone)
  }

  public toApiString(): string {
    return this.date
  }

  //public toString(): string {
  //  return this.date
  //}

  public toISOString(): string {
    return this.date + 'T00:00:00.000Z'
  }
}

const dateAdj = (dateStr: string, tz?: string) => {
  // If the date string is in the format 'YYYY-MM-DD',
  // modify it to be at local midnight or 11pm depending on which side of UTC we are
  // Intent is for UTC TIME to be adjusted from local TIME, but for the DATE to remain unchanged between LOCAL and UTC datetime
  // biggest UTC offsets are +14 (east of UTC) and -12 (west of UTC) -- ie. NY USA is -5 and Karachi Pakistan is +5
  // 2023-12-20 23:00:00 +14 is UTC 2023-12-20 09:00:00
  // 2023-12-20 00:00:00 -12 is UTC 2023-12-20 12:00:00
  let localOffset: number
  if (tz) {
    // TODO: we should have a hashmap of possible timezones to compare against instead of doing a regex match
    localOffset = tz.match('^[A-Za-z_]+/[A-Za-z_]+$')
      ? moment.tz(tz).utcOffset() / 60
      : new Date().getTimezoneOffset() / 60
  } else {
    localOffset = new Date().getTimezoneOffset() / 60
  }

  const adjDateStr =
    dateStr +
    `T` +
    convertToTwoDigitString(0 - localOffset > 0 ? 23 : 0).toString() +
    `:00:00`

  const adjDateStrUTC =
    dateStr +
    `T` +
    convertToTwoDigitString(
      0 - localOffset > 0 ? 23 + localOffset - 2 : 0 + localOffset + 2
    ).toString() +
    `:00:00Z`

  return { adjDateStr, adjDateStrUTC }
}

export const dateToExDate = (date: Date) => {
  return new ExDate(format(date, 'yyyy-MM-dd'), {
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
}
