import CreateNicknameBox from 'components/auth/CreateNicknameBox/CreateNicknameBox'
import CenteredBoxScreenWrapper from 'components/auth/common/CenteredBoxScreenWrapper'

export default function CreateNickname() {
  return (
    <CenteredBoxScreenWrapper>
      <CreateNicknameBox />
    </CenteredBoxScreenWrapper>
  )
}
