import { makeStyles } from '@mui/styles'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(() => ({
  avatar: {
    width: '100% !important',
    height: '100% !important',
  },
  avatarPointer: {
    cursor: 'pointer',
  },
}))
