import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
import { colors } from '../../../constants'

export default makeStyles<typeof theme>(() => ({
  notificationWrapper: {
    position: 'relative',
    color: colors.white,
  },
  notificationCount: {
    position: 'absolute',
    top: -10,
    right: -10,
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: colors.red,
    borderRadius: '100px',
    padding: '4px 2px',
    width: 22,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
}))
