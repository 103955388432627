import { isSameDay } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import {
  drawCandlestick,
  drawDateLabel,
  drawHorizontalDottedLine,
  drawMonthLabels,
  drawOHLC,
  drawPriceLabels,
  drawVerticalDottedLine,
  handleCanvasClick,
  handleLoadAfter,
  handleLoadBefore,
  handleMouseDown,
  handleMouseLeave,
  handleMouseMove,
  handleMouseUp,
  handleTouchEnd,
  handleTouchMove,
  handleTouchStart,
  handleWheel,
  handleZoom,
  scaleY,
  updateViewWindow,
} from './chartUtils'
import theme from '../../mui-theme'
import useStyles from './styles'
import { CandleStickData, Dimensions, MarkerBoundary, OHLC } from './interfaces'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { ExDate } from '../../constants'

const CandlestickChart: React.FC<{
  chartData: CandleStickData[]
  onLoadBefore?: () => Promise<any>
  onLoadAfter?: () => Promise<any>
  onChartClick?: (selectedDate: CandleStickData) => void
  selectedCandlestick?: CandleStickData | undefined
  highlightedDay: ExDate | undefined
  markedDays?: ExDate[] | undefined
  onReady?: () => void
}> = ({
  chartData,
  onLoadBefore,
  onLoadAfter,
  onChartClick,
  selectedCandlestick,
  highlightedDay,
  markedDays,
  onReady,
}) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState<Dimensions | null>(null)
  const dimensionsRef = useRef<Dimensions | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, start: 0 })
  const prevDataLengthRef = useRef(chartData.length)
  const [mouseX, setMouseX] = useState<number | null>(null)
  const [mouseY, setMouseY] = useState<number | null>(null)
  const mouseDownTimeRef = useRef<number | null>(null)
  const [clickDuration, setClickDuration] = useState<number | null>(null)
  const initialPinchDistance = useRef<number | null>(null)
  const markerBoundsRef = useRef<MarkerBoundary[]>([])

  const candlestickWidth = 10
  const horizontalPadding = 40
  const rightPadding = 20
  const totalCandlesticks = chartData.length
  const [viewWindow, setViewWindow] = useState({ start: 0, end: 0 })
  const viewWindowRef = useRef(viewWindow)
  const prevHighlightedDay: ExDate | undefined = usePrevious(highlightedDay)

  const [hoveredCandlestick, setHoveredCandlestick] =
    useState<CandleStickData | null>(null)
  const canvasHeight = canvasRef.current?.height ?? 400
  const visibleData = chartData?.slice(viewWindow.start, viewWindow.end)
  const visiblePrices = visibleData.map(c => [c.high, c.low]).flat()
  const visibleMaxPrice = Math.max(...visiblePrices)
  const visibleMinPrice = Math.min(...visiblePrices)
  const [zoomLevel, setZoomLevel] = useState(1) // 1 is the default zoom level
  const [loadingNewData, setLoadingNewData] = useState(false)
  const [loadingDirection, setLoadingDirection] = useState<string | null>(null) // 'older', 'newer', or null
  const chartDataRef = useRef(chartData)
  const hoveredCandlestickRef = useRef(hoveredCandlestick)
  const [dimensionsInitialCallComplete, setDimensionsInitialCallComplete] =
    useState(false)

  // SVG for chart marker in story view
  const svgString = `<svg width="27" height="15" viewBox="0 0 27 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H20.25L27 7.5L20.25 15H0V0Z" fill="white" /></svg>`
  const createMarkerIcon = () => {
    const url = `data:image/svg+xml;base64,${btoa(svgString)}`
    const img = new Image()
    img.src = url
    return img
  }

  const markerIcon = createMarkerIcon()

  function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>()

    useEffect(() => {
      ref.current = value
    }, [value])

    return ref.current
  }

  const scaleYFunction = (price: number) => {
    return scaleY(price, visibleMaxPrice, visibleMinPrice, canvasHeight)
  }

  /*********************************************************************************************
   * DRAW
   **********************************************************************************************/
  const drawChart = (currentViewWindow = viewWindowRef.current) => {
    const canvas = canvasRef.current
    const canvasHeight = canvasRef.current?.height ?? 400

    if (!canvas) return
    const ctx = canvas.getContext('2d')
    if (!ctx) return
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const paddedWidth = canvas.width - horizontalPadding - rightPadding

    if (hoveredCandlestick) {
      const ohlc: OHLC = {
        open: hoveredCandlestick.open,
        high: hoveredCandlestick.high,
        low: hoveredCandlestick.low,
        close: hoveredCandlestick.close,
      }

      // Draw OHLC values at the top left corner
      drawOHLC(ctx, ohlc, 80, 20)
    }

    const sortedData: CandleStickData[] = chartData.sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    )
    const visibleData: CandleStickData[] = sortedData.slice(
      currentViewWindow.start,
      currentViewWindow.end
    )

    // Calculate the width of each candlestick based on the adjusted padded area
    const paddedCandlestickWidth = Math.max(
      1,
      paddedWidth / visibleData.length - 2
    )

    // Reset marker bounds before each draw
    markerBoundsRef.current = []

    visibleData.forEach((candle, index) => {
      const xPosition = horizontalPadding + index * (paddedCandlestickWidth + 2)
      const isHovered = candle === hoveredCandlestick
      const isSelected = selectedCandlestick
        ? isSameDay(candle.date, selectedCandlestick?.date)
        : false

      const isHighlighted =
        highlightedDay && isSameDay(candle.date, highlightedDay)

      drawCandlestick(
        ctx,
        candle,
        xPosition,
        paddedCandlestickWidth,
        isHovered,
        isSelected,
        canvasHeight,
        scaleYFunction,
        isHighlighted
      )

      if (markedDays && markedDays.some(day => isSameDay(candle.date, day))) {
        const markerXPosition = xPosition - 27

        const candleMidYPosition =
          (scaleYFunction(candle.high) + scaleYFunction(candle.low)) / 2
        const markerHeight = 15
        const markerYPosition = candleMidYPosition - markerHeight / 2

        if (!markerIcon.complete) {
          markerIcon.onload = () =>
            ctx.drawImage(markerIcon, markerXPosition, markerYPosition, 27, 15)
        } else {
          ctx.drawImage(markerIcon, markerXPosition, markerYPosition, 27, 15)
        }

        // After drawing the marker, store its boundary for click detection
        markerBoundsRef.current.push({
          x: markerXPosition,
          y: markerYPosition,
          width: 27,
          height: 15,
          candle: candle,
        })
      }
    })

    drawPriceLabels(
      ctx,
      dimensions,
      visibleMaxPrice,
      visibleMinPrice,
      horizontalPadding,
      scaleYFunction
    )
    drawMonthLabels(
      ctx,
      visibleData,
      canvas.width,
      canvasHeight,
      horizontalPadding,
      rightPadding,
      viewWindowRef.current,
      dimensionsRef.current
    )

    if (hoveredCandlestick && deviceType === DeviceType.Desktop) {
      drawDateLabel(
        ctx,
        hoveredCandlestick,
        visibleData,
        canvas.width,
        canvasHeight,
        horizontalPadding,
        rightPadding,
        viewWindowRef.current,
        dimensionsRef.current
      )
    }

    if (deviceType === DeviceType.Desktop) {
      if (mouseX !== null) {
        drawVerticalDottedLine(ctx, mouseX, canvas.height)
      }
      if (mouseY !== null) {
        drawHorizontalDottedLine(ctx, mouseY, canvas.width)
      }
    }

    // after the dimensions are set, the next call to drawChart is the final one
    // so we can set onReady at this point. This has to be done for the downloadable
    // content. I haven't yet figured out a better way to do this.
    if (dimensionsInitialCallComplete) {
      onReady && onReady()
    }
  }

  /*********************************************************************************************
   * MOVEMENT
   **********************************************************************************************/
  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const handleMouseMoveEvent = (event: any) =>
        handleMouseMove(
          event,
          canvasRef,
          setMouseX,
          setMouseY,
          chartData,
          dimensions,
          horizontalPadding,
          rightPadding,
          viewWindowRef,
          isDragging,
          dragStart,
          setViewWindow,
          setHoveredCandlestick,
          totalCandlesticks,
          visibleData,
          markerBoundsRef,
          handleLoadBefore,
          handleLoadAfter,
          setLoadingNewData,
          loadingDirection,
          setLoadingDirection,
          setClickDuration,
          onLoadBefore,
          onLoadAfter
        )
      canvas.addEventListener('mousemove', handleMouseMoveEvent)
      return () => {
        canvas.removeEventListener('mousemove', handleMouseMoveEvent)
      }
    }
  }, [
    visibleData,
    isDragging,
    dragStart,
    viewWindow,
    totalCandlesticks,
    dimensions,
  ])

  /*********************************************************************************************
   * ZOOM
   **********************************************************************************************/
  useEffect(() => {
    const canvas = canvasRef.current

    const wrappedHandleWheel = (event: any) =>
      handleWheel(
        event,
        canvasRef,
        chartDataRef.current,
        dimensionsRef,
        viewWindowRef,
        candlestickWidth,
        zoomLevel,
        setViewWindow,
        setZoomLevel,
        handleZoom,
        drawChart
      )

    if (canvas) {
      canvas.addEventListener('wheel', wrappedHandleWheel)
      return () => {
        canvas.removeEventListener('wheel', wrappedHandleWheel)
      }
    }
  }, [zoomLevel])

  useEffect(() => {
    drawChart(viewWindowRef.current)
  }, [
    hoveredCandlestick,
    selectedCandlestick,
    zoomLevel,
    mouseX,
    mouseY,
    markedDays,
    viewWindow,
  ])

  const updateCanvasSize = () => {
    setTimeout(() => {
      if (canvasRef.current && canvasRef.current.parentElement) {
        const parentStyle = window.getComputedStyle(
          canvasRef.current.parentElement
        )
        const width = parseInt(parentStyle.width, 10)
        const height = parseInt(parentStyle.height, 10)

        setDimensions({ width, height })
        dimensionsRef.current = { width, height }
      }
    }, 100)
  }

  useEffect(() => {
    const canvasContainer = containerRef.current
    if (canvasContainer) {
      const resizeObserver = new ResizeObserver(entries => {
        setTimeout(() => {
          for (let entry of entries) {
            updateCanvasSize()
            updateViewWindow(
              canvasRef,
              totalCandlesticks,
              selectedCandlestick,
              candlestickWidth,
              viewWindowRef,
              setViewWindow,
              chartData
            )

            drawChart()
          }
        })
      })

      resizeObserver.observe(canvasContainer)

      return () => resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    updateViewWindow(
      canvasRef,
      totalCandlesticks,
      selectedCandlestick,
      candlestickWidth,
      viewWindowRef,
      setViewWindow,
      chartData
    )

    drawChart()
  }, [selectedCandlestick])

  useEffect(() => {
    if (!dimensions) return

    if (dimensions.width === 0 && dimensions.height === 0) {
      return
    }

    const canvas = canvasRef.current
    if (canvas) {
      canvas.width = dimensions.width
      canvas.height = dimensions.height

      if (canvas.width === 0 && canvas.height === 0) {
        return
      }

      updateViewWindow(
        canvasRef,
        totalCandlesticks,
        selectedCandlestick,
        candlestickWidth,
        viewWindowRef,
        setViewWindow,
        chartData
      )

      drawChart()

      if (dimensionsInitialCallComplete === false) {
        setDimensionsInitialCallComplete(true)
      }
    }
  }, [dimensions])

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const wrappedHandleMouseMove = (event: any) =>
        handleMouseMove(
          event,
          canvasRef,
          setMouseX,
          setMouseY,
          chartDataRef.current,
          dimensionsRef.current,
          horizontalPadding,
          rightPadding,
          viewWindowRef,
          isDragging,
          dragStart,
          setViewWindow,
          setHoveredCandlestick,
          totalCandlesticks,
          visibleData,
          markerBoundsRef,
          handleLoadBefore,
          handleLoadAfter,
          setLoadingNewData,
          loadingDirection,
          setLoadingDirection,
          setClickDuration,
          onLoadBefore,
          onLoadAfter
        )

      const wrappedHandleCanvasClick = (event: any) =>
        handleCanvasClick(
          event,
          canvasRef,
          viewWindowRef,
          dimensions,
          horizontalPadding,
          rightPadding,
          visibleData,
          clickDuration,
          setClickDuration,
          onChartClick,
          markerBoundsRef
        )

      const wrappedHandleMouseDown = (event: any) =>
        handleMouseDown(
          event,
          viewWindowRef,
          horizontalPadding,
          rightPadding,
          mouseDownTimeRef,
          setIsDragging,
          setDragStart
        )

      const wrappedHandleMouseLeave = () =>
        handleMouseLeave(
          isDragging,
          setMouseX,
          setMouseY,
          setIsDragging,
          setHoveredCandlestick
        )
      const wrappedHandleMouseUp = () =>
        handleMouseUp(
          setIsDragging,
          clickDuration,
          setClickDuration,
          mouseDownTimeRef
        )

      canvas.addEventListener('mousedown', wrappedHandleMouseDown)
      canvas.addEventListener('mousemove', wrappedHandleMouseMove)
      canvas.addEventListener('mouseup', wrappedHandleMouseUp)
      canvas.addEventListener('mouseleave', wrappedHandleMouseLeave)
      canvas.addEventListener('click', wrappedHandleCanvasClick)

      return () => {
        canvas.removeEventListener('mousedown', wrappedHandleMouseDown)
        canvas.removeEventListener('mousemove', wrappedHandleMouseMove)
        canvas.removeEventListener('mouseup', wrappedHandleMouseUp)
        canvas.removeEventListener('mouseleave', wrappedHandleMouseLeave)
        canvas.removeEventListener('click', wrappedHandleCanvasClick)
      }
    }
  }, [
    isDragging,
    dragStart,
    viewWindow,
    totalCandlesticks,
    dimensions,
    visibleData,
    onChartClick,
    chartData,
  ])

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const wrappedHandleTouchStart = (event: any) =>
        handleTouchStart(
          event,
          setIsDragging,
          setDragStart,
          viewWindowRef,
          initialPinchDistance
        )

      const wrappedHandleTouchMove = (event: any) =>
        handleTouchMove(
          event,
          isDragging,
          canvasRef,
          chartData,
          dimensions,
          dimensionsRef,
          horizontalPadding,
          rightPadding,
          viewWindowRef,
          dragStart,
          setViewWindow,
          setHoveredCandlestick,
          totalCandlesticks,
          visibleData,
          handleLoadBefore,
          setLoadingNewData,
          loadingDirection,
          setLoadingDirection,
          initialPinchDistance,
          candlestickWidth,
          zoomLevel,
          setZoomLevel,
          drawChart,
          onLoadBefore,
          onLoadAfter
        )

      const wrappedHandleTouchEnd = () =>
        handleTouchEnd(setIsDragging, initialPinchDistance)

      canvas.addEventListener('touchstart', wrappedHandleTouchStart)
      canvas.addEventListener('touchmove', wrappedHandleTouchMove)
      canvas.addEventListener('touchend', wrappedHandleTouchEnd)
      canvas.addEventListener('touchcancel', wrappedHandleTouchEnd)

      return () => {
        canvas.removeEventListener('touchstart', wrappedHandleTouchStart)
        canvas.removeEventListener('touchmove', wrappedHandleTouchMove)
        canvas.removeEventListener('touchend', wrappedHandleTouchEnd)
        canvas.removeEventListener('touchcancel', wrappedHandleTouchEnd)
      }
    }
  }, [isDragging, dragStart])

  /*********************************************************************************************
   * LOAD MORE DATA
   **********************************************************************************************/
  useEffect(() => {
    if (!loadingNewData) {
      const newDataLength = chartData.length
      const prevDataLength = prevDataLengthRef.current

      if (newDataLength > prevDataLength) {
        const isAddingToStart = chartData[0].date < chartDataRef.current[0].date
        const newDataCount = newDataLength - prevDataLength

        let newViewWindow
        if (isAddingToStart) {
          newViewWindow = {
            start: viewWindow.start + newDataCount,
            end: viewWindow.end + newDataCount,
          }
        } else {
          const visibleCandlesticks = viewWindow.end - viewWindow.start
          newViewWindow = {
            start: viewWindow.start,
            end: Math.min(
              newDataLength,
              viewWindow.start + visibleCandlesticks
            ),
          }
        }

        setViewWindow(newViewWindow)
        drawChart(newViewWindow)

        prevDataLengthRef.current = newDataLength
        viewWindowRef.current = newViewWindow
      }
    }
  }, [chartData.length, loadingNewData])

  useEffect(() => {
    viewWindowRef.current = viewWindow
  }, [viewWindow])

  useEffect(() => {
    chartDataRef.current = chartData
  }, [chartData])

  useEffect(() => {
    hoveredCandlestickRef.current = hoveredCandlestick
  }, [hoveredCandlestick])

  useEffect(() => {
    const highlightedDayTimestamp = highlightedDay && highlightedDay.getTime()
    const prevHighlightedDayTimestamp =
      prevHighlightedDay && prevHighlightedDay.getTime()
    if (highlightedDayTimestamp !== prevHighlightedDayTimestamp) {
      drawChart()
    }
  }, [highlightedDay, prevHighlightedDay])

  return (
    <div ref={containerRef} id="canvasContainer" className={classes.wrapper}>
      <canvas
        ref={canvasRef}
        style={{ width: '100%', height: '100%', display: 'block' }}
      />
    </div>
  )
}

export default CandlestickChart
