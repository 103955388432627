import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

const wrapperHeight = 42

export default makeStyles<typeof theme>(
  theme => ({
    root: {
      width: '100%',
    },

    optionWrapper: {
      width: 'calc(100% - 30px)',
      padding: '10px 15px',
      display: 'block',
      cursor: 'pointer',
      color: colors.text_white,
      transitionDelay: '50ms',

      '&:hover': {
        backgroundColor: colors.dropdown_hoverover,
      },
    },

    autoCompleteWrapper: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: colors.text_input_inactive,
      borderBottomStyle: 'solid',

      '&:before': {
        content: `''`,
        borderBottom: 'none',
      },

      '&:after': {
        content: `''`,
        borderBottom: `1px solid ${colors.blue_light}`,
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'scaleX(0)',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -1,
      },

      '&:focus-within': {
        '&:after': {
          transform: 'scaleX(1)',
        },
      },

      '.MuiInput-underline': {
        '&:after': {
          borderBottom: '0',
        },
      },
    },

    inputRoot: {
      boxSizing: 'border-box',
      width: '100%',
      height: wrapperHeight,
    },

    selectBox: {
      width: '100%',
      height: wrapperHeight,
      marginTop: 0,
      backgroundColor: colors.dropdown_background,
    },

    formLabelRoot: {
      fontFamily: fonts.dm_sans,
      fontSize: 11,
      lineHeight: 1,
      marginBottom: 8,
      display: 'flex',

      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        marginBottom: 4,
      },
    },

    formLabelNotFocused: {
      color: colors.textGrey,
    },

    formLabelIsFocused: {
      color: `${colors.blue_light}`,
    },

    formHelperRoot: {
      fontSize: 10,
      lineHeight: 1,
      marginTop: 5,
    },

    iconRight: {
      margin: 'auto 18px',
      cursor: 'pointer',
    },

    popper: {
      zIndex: 9999,

      '& .MuiAutocomplete-paper': {
        backgroundColor: colors.dropdown_background,
        color: colors.text_white,
        fontSize: 12,
        marginTop: 2,
        borderRadius: 0,
        fontFamily: fonts.dm_sans,

        '& .MuiAutocomplete-listbox': {
          padding: 0,

          '& .MuiAutocomplete-option': {
            padding: 0,
          },
        },

        '& .MuiAutocomplete-noOptions': {
          color: colors.textGrey,
        },
      },
    },
  }),
  { index: 1 }
)
