import {
  faEnvelopeSquare,
  faGear,
  faSignOutAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { USER_SIDEBAR_ACTION_NAMES } from './userSidebarConstants'

export const userSidebarMenuItems = [
  {
    text: 'Profile',
    id: USER_SIDEBAR_ACTION_NAMES.PROFILE,
  },
  {
    icon: faGear,
    text: 'Settings',
    id: USER_SIDEBAR_ACTION_NAMES.SETTINGS,
  },
  {
    icon: faSignOutAlt,
    text: 'Sign out',
    id: USER_SIDEBAR_ACTION_NAMES.SIGNOUT,
  },
]
