import { makeStyles } from '@mui/styles'
import { colors } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles({
  wrapper: {
    width: '100%',
    backgroundColor: colors.drawer_bg_right,
  },

  tabs: {
    padding: '0 32px 0 32px',

    [theme.breakpoints.down('lg')]: {
      minHeight: 40,
    },
  },

  scroller: {
    height: 55,

    [theme.breakpoints.down('lg')]: {
      height: 40,
    },
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
    bottom: 52,
    height: 3,

    [theme.breakpoints.down('lg')]: {
      bottom: 37,
    },
  },

  flexContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
