import Box from '@mui/material/Box'
import React, { ReactNode } from 'react'
import { colors, fonts } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface HeadSectionProps {
  headerText: string
  descriptionText?: ReactNode
  hasBottomSpace?: string
}

const HeadSection: React.FC<HeadSectionProps> = ({
  headerText,
  descriptionText,
}) => {
  const deviceType = useDeviceType()

  return (
    <>
      <Box
        component="h2"
        sx={{
          fontFamily: fonts.dm_sans,
          fontSize: 24,
          fontWeight: 500,
          lineHeight: '30px',
          marginTop: deviceType === DeviceType.Mobile ? 0 : 2,
          marginBottom: 0,
          textAlign: deviceType === DeviceType.Mobile ? 'center' : 'start',
        }}
      >
        {headerText}
      </Box>
      <Box
        component="p"
        sx={{
          fontFamily: fonts.dm_sans,
          fontSize: deviceType === DeviceType.Mobile ? '14px' : '12px',
          lineHeight: deviceType === DeviceType.Mobile ? '20px' : 1.3,
          color:
            deviceType === DeviceType.Mobile
              ? colors.white_faded
              : colors.text_white,
          fontWeight: deviceType === DeviceType.Mobile ? 400 : 'lighter',
          typography: 'body1',
          marginBottom: '0',
          marginTop: deviceType === DeviceType.Mobile ? 4 : 2,
        }}
      >
        {descriptionText}
      </Box>
    </>
  )
}

export default HeadSection
