import useStyles from './styles'
import theme from '../../mui-theme'
import Switch from 'components/inputs/Switch'
import { ChangeEvent } from 'react'
import { useEmailNotification } from 'hooks/useEmailNotification'

export default function NotificationSettingsPanel() {
  const classes = useStyles(theme)
  const { emailNotification, handleEmailNotificationChange } =
    useEmailNotification()

  return (
    <div className={classes.wrapper}>
      <Switch
        label={
          'Email notification when someone replies to your post or comment'
        }
        value={emailNotification}
        onChange={(_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
          handleEmailNotificationChange(checked)
        }
      />
    </div>
  )
}
