import { UserTabItem } from '../interfaces/userTabItem'

export const USER_TAB_ITEMS: UserTabItem[] = [
  {
    id: 'Posts',
    index: 0,
    label: 'Posts',
  },
  {
    id: 'Comments',
    index: 1,
    label: 'Comments',
  },
  {
    id: 'Following',
    index: 2,
    label: 'Following',
  },
  {
    id: 'Followers',
    index: 3,
    label: 'Followers',
  },
]
