import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export const submitTopicHeaderHeight = 55

export default makeStyles({
  submitTopicHeader: {
    display: 'flex',
    height: submitTopicHeaderHeight,
    cursor: 'pointer',

    '&:hover': {
      // this is the class .submitTopicHeaderTitleBox but cannot select it by name
      // as it gets a random number appended on the end of the class name each time
      '& > div:nth-child(1)': {
        backgroundColor: colors.blue_medium_light,
      },

      // same as above for .submitTopicHeaderAddIconBox
      '& > div:nth-child(2)': {
        backgroundColor: colors.blue_faded,
      },
    },
  },

  submitTopicHeaderTitleBox: {
    display: 'flex',
    width: '100%',
    backgroundColor: colors.blue,
    color: colors.white,
    fontFamily: fonts.dm_sans,
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: 3,
    justifyContent: 'center',
    alignItems: 'center',
    transitionDuration: '50ms',
  },

  submitTopicHeaderAddIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.blue_faded_light,
    flexBasis: 61,
    flexShrink: 0,
    flexGrow: 0,
    transitionDuration: '50ms',
  },
})
