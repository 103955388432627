import theme from '../../mui-theme'
import useStyles from './styles'
import { Modal } from '@mui/material'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { useDeviceType } from 'utils/screenSizeUtils'
import { useState } from 'react'
import { colors } from '../../constants'
import { TUTORIAL_STEP_ITEMS } from './constants/tutorialConstants'

interface Props {
  open: boolean
  onClose: () => void
}

export default function TutorialModal({ open, onClose }: Props) {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const [step, setStep] = useState(0)
  const count = 6

  const handleNext = () => {
    if (step < count - 1) {
      setStep(step + 1)
    } else {
      onClose()
    }
  }

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1)
    } else {
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.modal}>
        <div className={classes.modalInner}>
          <img
            className={classes.tutorialImage}
            src={TUTORIAL_STEP_ITEMS[step].imageSrc}
            alt="Party Popper"
          />
          <div className={classes.tutorialBody}>
            <h1
              className={classes.title}
              dangerouslySetInnerHTML={{
                __html: TUTORIAL_STEP_ITEMS[step].title,
              }}
            ></h1>
            <div className={classes.body}>{TUTORIAL_STEP_ITEMS[step].body}</div>
            <div className={classes.buttonsWrapper}>
              <button className={classes.skipButton} onClick={handleBack}>
                {step === 0 ? 'SKIP' : 'BACK'}
              </button>
              <div className={classes.radioButtonWrapper}>
                {Array.from({ length: count }, (_, index) => (
                  <RadioButtonUncheckedIcon
                    key={index}
                    className={classes.radioButton}
                    style={{
                      color:
                        step === index ? colors.textGreyLight : colors.greyDark,
                      backgroundColor:
                        step === index ? colors.textGreyLight : colors.greyDark,
                    }}
                  />
                ))}
              </div>
              <button className={classes.nextButton} onClick={handleNext}>
                NEXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
