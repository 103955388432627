import { Box, Checkbox, checkboxClasses } from '@mui/material'
import {
  CheckDuplicateUserInput,
  useCheckDuplicateUserLazyQuery,
  useGetCurrentUserQuery,
  useGetSymbolQuery,
  useUpdateUserProfileMutation,
} from 'generated/graphql'
import { useForm } from 'react-hook-form'
import theme from '../../../mui-theme'
import TextInput from '../../inputs/Text'
import AuthBoxContainer from '../common/AuthBoxContainer'
import useStyles from './styles'

import { faker } from '@faker-js/faker'
import ROUTES from 'constants/routes'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gaSignupEvent } from 'utils/analytics/ga'
import { DEBUG_ENV } from '../../../index'
import { BrandButton } from 'components/common/BrandButton'

interface CreateNicknameInput {
  nickname: string
}

export default function CreateNicknameBox() {
  const navigate = useNavigate()

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  })

  const [checkDuplicateUser] = useCheckDuplicateUserLazyQuery()

  const [rulesAgreed, setRulesAgreed] = useState<boolean>(false)
  const [promotionsAgreed, setPromotionsAgreed] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<boolean>(false)

  const classes = useStyles(theme)

  const { data: symbol } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: data?.getCurrentUser?.lastViewedSymbol || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    const userLastViewedSymbol = data?.getCurrentUser
      ?.lastViewedSymbol as string

    const type = symbol?.getSymbol?.type as string

    if (data?.getCurrentUser?.nickname) {
      if (data?.getCurrentUser!.lastViewedSymbol) {
        navigate(
          ROUTES.ASSET.replace(':type', type)
            .replace(':symbol', userLastViewedSymbol)
            .replace(':type', 'asset')
            .toLowerCase()
        )
      } else {
        navigate(ROUTES.SENTIMENT_DISCOVERY)
      }
    }
  }, [symbol])

  const handleRulesCheckboxChange = () => {
    if (DEBUG_ENV)
      console.debug(`Clicked on checkbox - handleRulesCheckboxChange`)
    setRulesAgreed(!rulesAgreed)
    if (errorState) {
      setErrorState(errorState => !errorState)
    }
  }

  const handlePromotionalCheckboxChange = () => {
    if (DEBUG_ENV)
      console.debug(`Clicked on checkbox - handlePromotionalCheckboxChange`)
    setPromotionsAgreed(!promotionsAgreed)
  }

  const [updateUserProfile] = useUpdateUserProfileMutation()

  const { register, handleSubmit, errors, setError } =
    useForm<CreateNicknameInput>({
      defaultValues: {
        nickname: faker.internet.userName(),
      },
    })

  useEffect(() => {
    gaSignupEvent('page_visit', 'sign_up___page_visit___social').catch(err => {
      if (DEBUG_ENV)
        console.error(
          `Google analytics failed to record the social signup page-visit event.`
        )
    })
  }, [])

  const onSubmit = (formData: CreateNicknameInput) => {
    if (!rulesAgreed) {
      setErrorState(!errorState)
      return
    }

    gaSignupEvent('create_user_start', 'sign_up___social_start').catch(err => {
      console.error(
        `Google analytics failed to record the social signup event START.`
      )
    })

    if (!!data?.getCurrentUser?.id) {
      const userData = data?.getCurrentUser
      checkDuplicateUser({
        variables: {
          data: {
            nickname: formData.nickname,
          } as CheckDuplicateUserInput,
        },
      }).then(result => {
        if (result.data?.checkDuplicateUser) {
          setError('nickname', {
            message: 'This username is taken. Please pick a different one.',
          })
        } else {
          gaSignupEvent('create_user_finish', 'sign_up___social_finish').catch(
            err => {
              console.error(
                `Google analytics failed to record the social signup event FINISH.`
              )
            }
          )

          updateUserProfile({
            variables: {
              data: {
                id: userData.id,
                nickname: formData.nickname,
                twitter: userData.twitter || null,
                facebook: userData.facebook || null,
                instagram: userData.instagram || null,
                tradingView: userData.tradingView || null,
              },
            },
            update(cache, { data }) {
              const normalizedId = cache.identify({
                id: userData.id,
                __typename: 'PrivateUser',
              })
              cache.modify({
                id: normalizedId,
                fields: {
                  nickname() {
                    return formData.nickname
                  },
                },
              })
            },
          }).then(
            () => {
              navigate(ROUTES.VERIFY_EMAIL_COMPLETE)
            },
            () => {}
          )
        }
      })
    }
  }

  const descriptionStyle = {
    marginBottom: '1rem',
  }

  const createNicknameText = (
    <p>
      <div style={descriptionStyle}>
        Your username is how other community members will see you.
      </div>{' '}
      <div style={descriptionStyle}>
        This name will be used to credit you for things you share on fnchart.{' '}
      </div>
      <div style={descriptionStyle}> What should we call you?</div>
    </p>
  )

  return (
    <AuthBoxContainer
      headingText="Create your username"
      descriptionText={createNicknameText}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            mt: 3,
            mb: 3,
          }}
        />
        <div className={classes.checkboxContainer}>
          <Box
            sx={{
              marginBottom: '20px',
              marginLeft: '-9px',
              fontSize: '13px',
              [`& span, & span.${checkboxClasses.checked}`]: {
                color: 'white',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              {' '}
              <Checkbox name="rules" onChange={handleRulesCheckboxChange} />
              <span>
                Agree to site rules, terms of service, and privacy policy
              </span>
            </Box>
            {errorState && (
              <p className={classes.checkboxWarningText}>
                You cannot signup if you don't agree with our rules, terms of
                service, and privacy policy
              </p>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              {' '}
              <Checkbox
                name="marketing"
                onChange={handlePromotionalCheckboxChange}
              />
              <span>
                Agree to receive promotional & marketing emails (OPTIONAL)
              </span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {' '}
              <Checkbox
                name="marketing"
                onChange={handlePromotionalCheckboxChange}
              />
              <span>
                Agree to use of data for personalized advertising (OPTIONAL)
              </span>
            </Box>
          </Box>
          <TextInput
            name="nickname"
            label="Enter your nickname"
            ref={register({
              required:
                'Please enter your nick name. This is a required field.',
            })}
            error={errors.nickname ? errors.nickname.message : undefined}
          />
        </div>
        <BrandButton variant="contained" type="submit">
          FINISH CREATING ACCOUNT
        </BrandButton>
      </form>
    </AuthBoxContainer>
  )
}
