import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.drawer_bg_right,
  },

  navigationWrapper: {
    height: 34,
    width: '100%',
    textAlign: 'left',
    marginBottom: 8,
  },

  tabsRoot: {
    minHeight: 48,
    height: 48,
  },

  tab: {
    fontSize: 12,
    minHeight: 36,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: '33.333%',
    padding: '16px 10px',
    textTransform: 'capitalize',
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
  },

  selectedTabScroller: {
    height: 48,
    borderBottom: `1px solid ${colors.drawer_border}`,
    boxSizing: 'border-box',
    padding: '0 16px',
  },

  selectedTab: {
    color: `${colors.white} !important`,
  },

  tabText: {
    paddingLeft: 8,
  },

  header: {
    fontSize: 16,
    fontFamily: fonts.dm_sans,
    color: colors.white_faded,
    textAlign: 'left',
    margin: 0,
    padding: 16,
  },

  headerTicker: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.textGreyLight,
    marginLeft: 4,
  },

  topButton: {
    height: 55,
    width: '50%',
    backgroundColor: colors.dark_blue,
    color: colors.textGrey,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  buttonText: {
    fontFamily: fonts.dm_sans,
    marginLeft: 12,
    fontSize: 13,
    letterSpacing: 0,
  },

  buttonSelected: {
    backgroundColor: colors.blue,
    color: colors.white,
  },

  backArrow: {
    color: colors.textGrey,
    margin: '14px 16px 12px',
    cursor: 'pointer',
  },

  contentWrapper: {
    position: 'relative',
    display: 'flex',
    height: 'calc(100% - 90px)',
    overflowY: 'auto',
    flexGrow: 1,
    zIndex: 100,
  },

  form: {
    width: '100%',
    minHeight: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  formWrapper: {
    flexGrow: 1,
    padding: '0 16px 16px 16px',
    overflowY: 'auto',
  },

  inputWrapper: {
    marginBottom: 24,
    width: '100%',
    flex: 1,
  },

  textInputWrapper: {
    marginBottom: 0,
  },

  urlInputWrapper: {
    marginBottom: 0,
  },

  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.drawer_border}`,
    padding: 16,
    boxSizing: 'border-box',
    backgroundColor: colors.drawer_bg_right,
  },

  button: {
    height: 46,
    padding: '10px 0',
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '14px',
    letterSpacing: 2.75,
  },

  checkboxRoot: {
    zIndex: 0,
  },

  cancelButton: {
    color: colors.textGrey,
    backgroundColor: colors.drawer_bg,
    transitionDuration: '50ms',
    '&:hover': {
      backgroundColor: colors.input_bg,
      boxShadow: 'none',
    },
    boxShadow: 'none',
  },
})
