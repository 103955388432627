import * as React from 'react'

function ChartIconImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="9.27273" height="32" fill="white" />
      <rect
        x="12.3636"
        y="10.666"
        width="9.27273"
        height="21.3333"
        fill="white"
      />
      <rect
        x="24.7273"
        y="21.333"
        width="9.27273"
        height="10.6667"
        fill="white"
      />
    </svg>
  )
}

export default ChartIconImage
