import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Auth } from '@aws-amplify/auth'

import gAnalytics, { gaSignupEvent } from 'utils/analytics/ga'
import { setApolloError, setSuccess } from '../apollo/reactive-vars'
import ConfirmEmailWithCodeBox from '../components/auth/ConfirmEmailWithCode'
import CenteredBoxScreenWrapper from '../components/auth/common/CenteredBoxScreenWrapper'
import ROUTES from '../constants/routes'

export interface ConfirmVariables {
  code: string
}

const ConfirmSignUp: React.FC = () => {
  const navigate = useNavigate()

  const { email, code } = useParams<'email' | 'code'>()

  useEffect(() => {
    if (code) {
      confirmUser(code)
    }
  }, [])

  async function confirmUser(code: string) {
    // TODO: remove type casting
    Auth.confirmSignUp(email as string, code)
      .then(() => navigate(ROUTES.VERIFY_EMAIL_COMPLETE))
      .catch(error => {
        setApolloError(error.message || 'Something went wrong.')
      })

    gaSignupEvent('confirm_email', 'sign_up___confirmed_email').catch(err => {
      console.error(
        `Google analytics failed to record the signup email confirmation event.`
      )
    })
  }

  async function resendCode() {
    // TODO: remove type casting
    Auth.resendSignUp(email as string)
      .then(() => setSuccess('The code has been resent'))
      .catch(error => {
        setApolloError(error.message || 'Something went wrong.')
      })

    gaSignupEvent('confirm_email', 'sign_up___confirmed_email_resend').catch(
      err => {
        console.error(
          `Google analytics failed to record the signup email confirmation resend event.`
        )
      }
    )
  }

  if (code) {
    return null
  }

  return (
    <CenteredBoxScreenWrapper>
      <ConfirmEmailWithCodeBox
        email={email!}
        onResendVerificationCode={resendCode}
        onSubmit={confirmUser}
      />
    </CenteredBoxScreenWrapper>
  )
}

export default ConfirmSignUp
