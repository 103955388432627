import React from 'react'
import Box from '@mui/material/Box'
import { colors, fonts } from '../../constants'
import Skeleton from '@mui/material/Skeleton'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

export const CommentLoading: React.FC = props => {
  const deviceType = useDeviceType()

  return (
    <Box
      sx={{
        borderTop: `1px solid ${colors.border}`,
        backgroundColor: colors.box_bg,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflow: 'hidden',
          borderBottom: `1px solid ${colors.border}`,
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.drawer_bg,
            padding: 2,
            flex: 1,
            width: 200, // fix for long strings within flex
          }}
        >
          <Box
            sx={{
              display: 'flex',
              pb: 2,
            }}
          >
            {' '}
            <Skeleton
              width={100}
              sx={{ bgcolor: colors.textGrey }}
              height={14}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: fonts.dm_sans,
                fontSize: 11,
                pl: 1,
                color: colors.textGrey,
              }}
            >
              <Skeleton
                width={30}
                sx={{ bgcolor: colors.textGrey }}
                height={11}
              />
            </Box>
          </Box>
          <Box
            component="p"
            sx={{
              color: colors.text_white,
              fontFamily: fonts.dm_sans,
              fontSize: 13,
              lineHeight: 1.5,
              pb: 2,
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
            }}
          >
            <>
              <Skeleton
                sx={{ bgcolor: colors.textGrey, mb: 0.3 }}
                height={13}
              />
              <Skeleton
                sx={{ bgcolor: colors.textGrey, mb: 0.3 }}
                height={13}
              />
              <Skeleton
                sx={{ bgcolor: colors.textGrey, mb: 0.3 }}
                height={13}
              />
              <Skeleton
                sx={{ bgcolor: colors.textGrey, mb: 0.3 }}
                height={13}
              />
              <Skeleton
                sx={{ bgcolor: colors.textGrey }}
                height={13}
                width="60%"
              />
            </>
          </Box>
          <Box>
            <Skeleton
              sx={{ bgcolor: colors.textGrey }}
              width={200}
              height={30}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: 61,
            backgroundColor:
              deviceType === DeviceType.Mobile
                ? colors.box_bg
                : colors.drawer_bg_light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Skeleton height={130} width={30} sx={{ bgcolor: colors.textGrey }} />
        </Box>
      </Box>
    </Box>
  )
}
