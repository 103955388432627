import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

const inputWrapperHeight = 70
const formControlHeight = 44

export default makeStyles<typeof theme>(
  theme => ({
    wrapper: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.search_bg,
      boxSizing: 'border-box',
    },

    inputWrapper: {
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '0 30px 0 24px',
      display: 'flex',
      justifyContent: 'flex-start',
      height: inputWrapperHeight,
      width: '100%',
      marginRight: 8,
      marginBottom: 24,
      position: 'relative',

      [theme.breakpoints.down('lg')]: {
        padding: '0 16px',
      },

      '&:before': {
        content: `''`,
        borderBottom: 'none',
      },

      '&:focus-within': {
        '&:after': {
          transform: 'scaleX(1)',
        },
      },

      '.MuiInput-underline': {
        '&:after': {
          borderBottom: '0',
        },
      },
    },

    inputWithIcon: {
      display: 'flex',
      width: 'calc(100% - 618px)',
      borderRadius: 16.15,
      backgroundColor: colors.search_input_bg,

      [theme.breakpoints.down('lg')]: {
        padding: '0 16px',
        width: '100%',
        marginRight: 16,
        backgroundColor: colors.drawer_bg_right,
      },
    },

    formControlRoot: {
      height: formControlHeight,
      width: 'calc(100% - 41px)',
      marginRight: 15.5,
      marginLeft: 32,

      [theme.breakpoints.down('lg')]: {
        marginLeft: 0,
      },
    },

    logoWrapper: {
      height: 24,
      width: 24,
      marginRight: 60,
    },

    closeWithIcon: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontFamily: fonts.dm_sans,
      color: colors.textGrey,
      fontSize: 16,
      fontWeight: 500,
      alignSelf: 'center',
      marginLeft: 'auto',
    },

    iconClose: {
      marginLeft: 8,
    },

    input: {
      width: '100%',
      color: colors.text_white,
      padding: 0,
      fontSize: 16,
      fontFamily: fonts.dm_sans,
    },

    inputRoot: {
      boxSizing: 'border-box',
      width: '100%',
      height: inputWrapperHeight,

      '&:before': {
        content: `''`,
        borderBottom: 'none !important',
      },

      '&:after': {
        content: `''`,
        borderBottom: 'none !important',
      },
    },

    iconSearch: {
      alignSelf: 'center',
      cursor: 'pointer',
      fontSize: 18,
      paddingRight: 23,
    },

    searchResultsWrapper: {
      padding: '0 108px 30px 108px',
      display: 'flex',
      height: 'calc(100% - 124px)',

      [theme.breakpoints.down('lg')]: {
        padding: 0,
        height: 'calc(100% - 94px)',
      },
    },

    iconLeftArrow: {
      alignSelf: 'center',
      marginRight: 16,
    },
  }),
  { index: 1 }
)
