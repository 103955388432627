import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    display: 'flex',
    marginTop: 24,
    marginBottom: 16,
    paddingBottom: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
    width: '100%',
    borderBottom: `1px solid ${colors.drawer_border}`,

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      marginBottom: 0,
      padding: 16,
      boxSizing: 'border-box',
    },
  },
}))
