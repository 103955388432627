import React from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'
import UserAvatar from 'components/UserAvatar'
import ExclamationCircleIcon from 'components/Icons/ExclamationCircle'
import { BrandButton } from 'components/common/BrandButton'
import classNames from 'classnames'

interface Props {
  username: string
  email: string
  deleteAccount: () => void
}

const DeleteUser: React.FC<Props> = ({
  username,
  email,
  deleteAccount,
}: Props) => {
  const classes = useStyles(theme)
  const [areYouSureClicked, setAreYouSureClicked] = React.useState(false)

  return (
    <>
      {!areYouSureClicked && (
        <div className={classes.wrapper}>
          <div className={classes.avatarWrapper}>
            <UserAvatar size={120} email={email} />
          </div>
          <h2 className={classes.title}>
            Are you sure you want to delete your account?
          </h2>
          <ExclamationCircleIcon />
          <div className={classes.body}>
            This action is irreversible and will permanently delete all your
            data associated with this account.
            <br />
            <br />
            Please Note:
            <ul className={classes.pleaseNoteList}>
              <li>You will lose access to all your saved information.</li>
              <li>Your account cannot be recovered once deleted.</li>
              <li>
                Your username and email address will become available for others
                to use.
              </li>
            </ul>
          </div>

          <div className={classes.buttonWrapper}>
            <BrandButton
              variant="contained"
              className={classNames(classes.button)}
              onClick={() => setAreYouSureClicked(true)}
              type="submit"
            >
              DELETE ACCOUNT
            </BrandButton>
          </div>
        </div>
      )}
      {areYouSureClicked && (
        <div
          className={classNames(
            classes.wrapper,
            classes.areYouSureClickedContent
          )}
        >
          <h2
            className={classNames(
              classes.title,
              classes.areYouSureClickedTitle
            )}
          >
            Delete fnchart account
          </h2>
          <div className={classes.bodyContinue}>
            You are requesting to delete "{username}". By deleting your account,
            you'll lose access to all your data and settings associated with it.
          </div>
          <div className={classes.buttonWrapper}>
            <BrandButton
              variant="contained"
              className={classNames(classes.button)}
              onClick={() => deleteAccount()}
              type="submit"
            >
              CONTINUE DELETING ACCOUNT
            </BrandButton>
          </div>
        </div>
      )}
    </>
  )
}

export default DeleteUser
