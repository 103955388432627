import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles({
  wrapper: {
    width: '40%',
    height: '100%',
    marginLeft: 64,
  },

  tabs: {
    height: 28,
    minHeight: 28,
    borderBottom: `1px solid ${colors.drawer_border}`,
  },

  tab: {
    display: 'inline-block',
    verticalAlign: 'top',
    height: 28,
    minHeight: 28,
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: 'auto',
    padding: 0,
    marginRight: 32,
    textTransform: 'capitalize',
  },

  selectedTab: {
    color: `${colors.text_white} !important`,

    '& svg': {
      color: colors.text_white,
    },
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
    bottom: 0,
  },

  body: {
    marginTop: 16,
    overflowY: 'auto',
    height: 'calc(100% - 44px)',

    '&::-webkit-scrollbar': {
      '&-thumb': {
        backgroundColor: colors.box_bg,
      },
    },
  },

  noResultsWrapper: {
    maxWidth: 300,
    marginTop: 64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
  },

  noResultsTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.text_white,
  },

  noResultsBody: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.textGreyLight,
  },

  resultRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 24,
    fontFamily: fonts.dm_sans,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    padding: '14px 16px',
    color: colors.text_white,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: colors.drawer_bg_right,
      borderRadius: 8,
    },
  },

  value: {
    float: 'right',
    textAlign: 'right',
    maxWidth: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '24px',
  },

  key: {
    display: 'flex',
    maxWidth: '30%',
    lineHeight: '24px',
  },

  symbolIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },

  keyText: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '24px',
    textAlign: 'center',
    marginLeft: 8,
  },

  keyIcon: {
    alignSelf: 'center',
  },
})
