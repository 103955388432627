import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { colors } from '../../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faEnvelope, faFlag } from '@fortawesome/free-solid-svg-icons'
import { ShareActionsMenuItemIds } from 'utils/shareUtils'

interface ShareIconsMobileProps {
  topicId: string
  onClose: () => void
  onReport: () => void
  onShareActionsClick: (id: string, action: string | number) => void
}

export const ShareIconsMobile: React.FC<ShareIconsMobileProps> = props => {
  const [touchStartY, setTouchStartY] = useState(null)
  const [boxBottom, setBoxBottom] = useState(0)

  const onTouchStart = (e: any) => {
    e.stopPropagation()

    setTouchStartY(e.touches[0].clientY)
  }

  const onTouchMove = (e: any) => {
    e.stopPropagation()

    if (touchStartY != null) {
      const currentTouchY = e.touches[0].clientY
      const touchDelta = currentTouchY - touchStartY

      if (touchDelta !== 0) {
        let newBottom = boxBottom - touchDelta
        newBottom = Math.min(newBottom, window.innerHeight)

        setBoxBottom(newBottom)
        setTouchStartY(currentTouchY)
      }
    }
  }

  const onTouchEnd = (e: any) => {
    e.stopPropagation()

    const threshold = window.outerHeight / 10

    if (-boxBottom > threshold) {
      setBoxBottom(-window.innerHeight)
      props.onClose()
    } else {
      setBoxBottom(0)
    }

    setTouchStartY(null)
  }

  return (
    <Box
      onTouchStart={e => {
        onTouchStart(e)
        e.currentTarget.style.transition = 'none'
      }}
      onTouchMove={onTouchMove}
      onTouchEnd={e => {
        onTouchEnd(e)
        e.currentTarget.style.transition = 'bottom 0.3s ease'
      }}
      sx={{
        zIndex: 999999999,
        touchAction: 'none',
        position: 'fixed',
        bottom: `${boxBottom}px`,
        transition: 'bottom 0.3s ease',
        left: 0,
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: colors.drawer_bg_right,
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.20)',
        WebkitTransform: 'translate3d(0, 0, 0)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '6px',
          paddingTop: 1,
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '60px',
            height: '6px',
            backgroundColor: colors.search_bg,
            borderRadius: '100px',
            marginBottom: 2,
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '24px',
          color: colors.white_faded,
          padding: '16px 24px 0px 24px',
        }}
      >
        Share this post
      </Box>
      <Box
        sx={{
          paddingBottom: '24px',
          borderBottom: `1px solid ${colors.drawer_border}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            color: colors.textGreyLight,
            padding: '16px 24px 0px 24px',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onShareActionsClick(
                props.topicId,
                ShareActionsMenuItemIds.TWITTER
              )
            }
          >
            <FontAwesomeIcon style={{}} icon={faTwitter} size={'xl'} />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              X
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onShareActionsClick(
                props.topicId,
                ShareActionsMenuItemIds.REDDIT
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faReddit}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Reddit
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onShareActionsClick(
                props.topicId,
                ShareActionsMenuItemIds.FACEBOOK
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faFacebookF}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Facebook
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onShareActionsClick(
                props.topicId,
                ShareActionsMenuItemIds.GMAIL
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faGoogle}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Gmail
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onShareActionsClick(
                props.topicId,
                ShareActionsMenuItemIds.EMAIL
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faEnvelope}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Email
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '16px 24px',
          color: colors.textGreyLight,
        }}
        onClick={props.onReport}
      >
        <FontAwesomeIcon icon={faFlag} size={'xl'} />
        <Box
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            color: colors.white_faded,
            marginLeft: 2,
          }}
        >
          Report this post
        </Box>
      </Box>
    </Box>
  )
}
