import { forwardRef } from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomTooltip from '../CustomTooltip'
import { extractHostname, handleTopicHeaderClick } from 'utils/urlUtils'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { PostIcons } from 'components/PostIcons/PostIcons'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import VoteUpDown, { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { PostData } from '../CommentWithParent/Comment.interfaces'
import { convertVoteDirection } from 'utils/voteUtils'
import UserAvatar from 'components/UserAvatar'
import { useLocation, useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import MarkdownViewer from 'components/inputs/MarkdownViewer'
import { PostType } from 'generated/graphql'
import TickerImage from '../TickerImage'

interface Props {
  userId?: string
  post: PostData
  index: number
  onVote: (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onPostClick: (id: string, symbol: string) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div key={props.index} className={classes.postWrapper}>
      <div className={classes.postDetails}>
        {props.post.postType === PostType.Story && (
          <div className={classes.story}>STORY</div>
        )}
        <div className={classes.symbolDetails}>
          <TickerImage symbol={props.post.symbol} />
          <div className={classes.symbolTitle}>{props.post.symbolName}</div>
        </div>
        <div className={classes.postTitle}>
          {props.post!.postType === PostType.Text && (
            <div
              onClick={() => {
                handleTopicHeaderClick(
                  location,
                  navigate,
                  props.post?.assetType!,
                  props.post?.symbol!,
                  props.post!.id!
                )
              }}
            >
              {props.post!.title}{' '}
              <span className={classes.url}>(fnchart.com)</span>
            </div>
          )}
          {props.post.postType === PostType.Story && (
            <span
              onClick={() =>
                navigate(
                  ROUTES.STORY.replace(
                    ':type',
                    props.post.assetType.toLowerCase()
                  )
                    .replace(':symbol', props.post.symbol.toLowerCase())
                    .replace(':storyId', props.post!.id.toLowerCase())
                )
              }
              style={{ cursor: 'pointer' }}
            >
              {props.post!.title}{' '}
              <span className={classes.url}>
                (fnchart.com) &nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="1x" />
              </span>
            </span>
          )}
          {props.post!.postType === PostType.Url && !!props.post!.url && (
            <div
              onClick={() => {
                handleTopicHeaderClick(
                  location,
                  navigate,
                  props.post?.assetType!,
                  props.post?.symbol!,
                  undefined,
                  props.post!.url!
                )
              }}
            >
              {props.post!.title}{' '}
              <CustomTooltip title={props.post!.url!.toLowerCase()}>
                <span className={classes.urlSource}>
                  <span className={classes.url}>
                    ({extractHostname(props.post!.url!)}) &nbsp;&nbsp;
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      size="1x"
                    />
                  </span>
                </span>
              </CustomTooltip>
            </div>
          )}
        </div>
        <div className={classes.lineWrapper}>
          <div
            className={classes.usernameWrapper}
            onClick={() => {
              navigate(
                ROUTES.USER.replace(
                  ':username',
                  props.post.username!
                ).toLowerCase()
              )
            }}
          >
            <div className={classes.avatarWrapper}>
              <UserAvatar emailHash={props.post.emailHash} />
            </div>
            <div className={classes.username}>{props.post.username}</div>
          </div>
          <div
            className={classes.symbol}
            onClick={() => {
              navigate(
                ROUTES.ASSET.replace(':symbol', props.post.symbol!)
                  .replace(':type', props.post.assetType)
                  .toLowerCase()
              )
            }}
          >
            ${props.post.symbol}
          </div>
          <div className={classes.date}>
            {getTimeElapsedRounded(props.post.date, props.post.createdAt)}
          </div>
        </div>
        {props.post.text && (
          <div className={classes.postText}>
            <MarkdownViewer
              markdownSource={props.post.text}
              isPreview={false}
            ></MarkdownViewer>
          </div>
        )}
        <div className={classes.postIconsWrapper}>
          <PostIcons
            post={props.post}
            numberOfReplies={props.post.numberOfReplies ?? 0}
            onTopicBookmarkClick={/* TODO: Add bookmarks feature */ () => {}}
            onMoreActionsClick={(id: string, action: string | number) =>
              props.onMoreActionsClick(
                id,
                props.post.symbol,
                props.post.assetType,
                action
              )
            }
            onShareActionsClick={(id: string, action: string | number) =>
              props.onShareActionsClick(
                id,
                props.post.title,
                props.post.symbol,
                action
              )
            }
            onCameraActionsClick={(
              topicInfo: PostData,
              action: string | number
            ) => props.onCameraActionsClick(topicInfo, action)}
            isCreator={props.userId === props.post.userId}
            onCommentClick={(id: string) => {
              props.onPostClick(id, props.post.symbol)
            }}
          />
        </div>
      </div>
      <div className={classes.voteUpDownWrapper}>
        <VoteUpDown
          numVotes={props.post.voteNum || 0}
          onVoteDownClick={() =>
            props.onVote(
              VoteDirection.down,
              props.post.id,
              props.post.userVoteDirection !== null
                ? convertVoteDirection(props.post.userVoteDirection!)
                : undefined
            )
          }
          onVoteUpClick={() =>
            props.onVote(
              VoteDirection.up,
              props.post.id,
              props.post.userVoteDirection !== null
                ? convertVoteDirection(props.post.userVoteDirection!)
                : undefined
            )
          }
          voteDirection={props.post.userVoteDirection}
        />
      </div>
    </div>
  )
})
