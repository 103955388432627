import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import { PostIcons } from 'components/PostIcons/PostIcons'
import MarkdownViewer from 'components/inputs/MarkdownViewer'
import React, { useEffect } from 'react'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { colors, fonts } from '../../../constants'
import { ensureHttpProtocol, extractHostname } from '../../../utils/urlUtils'
import { CommentReplyBox } from '../CommentReply/CommentReplyBox'
import VoteUpDown, { VoteDirection } from '../VoteUpDown'
import { formatWithEllipsis } from 'utils/stringUtils'
import ROUTES from 'constants/routes'
import { CommentReplyMobileBox } from '../CommentReplyMobile/CommentReplyMobileBox'
import UserAvatar from 'components/UserAvatar'
import { PostIconsMobile } from 'components/PostIconsMobile/PostIconsMobile'
import CustomTooltip from 'components/common/CustomTooltip'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import { PostType } from 'generated/graphql'

export interface TopicControls {
  onTopicHeaderClick?(
    location: any,
    navigate: NavigateFunction,
    assetType: string,
    symbol: string,
    props: { id?: string; url?: string }
  ): void
  onTopicReplyClick?: (id: string) => void
  onCommentClick?: (id: string) => void
  onTopicBookmarkClick?: (id: string) => void
  onShareActionsClick?: (id: string, action: string | number) => void
  onCameraActionsClick?: (topicInfo: PostData, action: string | number) => void
  onMoreActionsClick?: (id: string, action: string | number) => void
}

interface Props extends TopicControls {
  userId: string | undefined
  post: PostData
  replyNotificationEmail: boolean
  disableRespondCommentExpansion?: boolean
  onVote: (direction: VoteDirection) => void
  onReply: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    state: { get: boolean; set: Function }
  ) => Promise<any>
  isCreator: boolean
  commentTextChange?: (text: string) => void
}

const Topic: React.FC<Props> = (props: Props) => {
  const deviceType = useDeviceType()
  const location: any = useLocation()
  const navigate = useNavigate()

  const splitLocation = location.pathname.split('/')

  const tickerURL = '/' + splitLocation.slice(1, 3).join('/')

  const [isRespondCommentExpanded, setIsRespondCommentExpanded] =
    React.useState<boolean>(false)

  const [initialText, setInitialText] = React.useState<string>('')

  const url = ensureHttpProtocol(props.post.url || '')

  const [headlineWithFormatting, setHeadlineWithFormatting] =
    React.useState<string>('')

  // puts a span around the tickers so ticker text is blue
  useEffect(() => {
    setHeadlineWithFormatting(
      props.post.title.replace(/\$[A-Z]+[\S]*/g, function (match: string) {
        return (
          '<a target="_blank" ' +
          'href="/asset/' +
          match.replace('$', '').toLowerCase() +
          '" style="color: ' +
          colors.blue_light +
          '; text-decoration: unset;">' +
          match +
          '</a>'
        )
      })
    )
  }, [props.post.title])

  useEffect(() => {
    setIsRespondCommentExpanded(
      deviceType === DeviceType.Mobile && (props.post.numberOfReplies ?? 0) > 0
        ? false
        : true
    )
  }, [deviceType])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundColor:
            deviceType === DeviceType.Mobile
              ? colors.drawer_bg_right
              : colors.drawer_bg,
          padding: 2,
          flex: 1,
          width: 'calc(100% - 93px)',
        }}
      >
        {props.post.postType === PostType.Story && (
          <Box
            sx={{
              fontSize: 10,
              fontWeight: 600,
              color: colors.search_bg,
              backgroundColor: colors.orange,
              padding: '4px 8px',
              borderRadius: 100,
              width: 48,
              boxSizing: 'border-box',
              marginBottom: 1,
            }}
          >
            STORY
          </Box>
        )}
        <Box
          sx={{
            paddingBottom: 1,
            color: colors.text_white,
            fontSize: 15,
            fontFamily: fonts.dm_sans,
            lineHeight: '20px',
            textAlign: 'left',
          }}
        >
          <Box
            component="span"
            sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
            onClick={() => {
              const { id, url } = props.post
              props.onTopicHeaderClick!(
                location,
                navigate,
                props.post.assetType,
                props.post.symbol,
                url ? { url } : { id }
              )
            }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: headlineWithFormatting }}
            ></span>{' '}
          </Box>
          {!props.post.url && (
            <>
              <Box
                component="span"
                sx={{ color: colors.textGrey, fontSize: 11 }}
              >
                (fnchart.com)
              </Box>
            </>
          )}
          {!!props.post.url && (
            <CustomTooltip title={props.post.url}>
              <Box
                component="a"
                sx={{
                  textDecoration: 'inherit',
                  cursor: 'pointer',
                  color: colors.textGrey,
                  fontSize: 11,
                }}
                href={url}
                rel="noreferrer"
                target="_blank"
              >
                ({extractHostname(props.post.url)}) &nbsp;
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  size="1x"
                  /*className={classNames(classes.icon, classes.messageIcon)}*/
                />
              </Box>
            </CustomTooltip>
          )}
        </Box>
        {!!props.post.image && (
          <Box sx={{ width: '100%' }}>
            <Box
              component="a"
              sx={{ cursor: 'pointer' }}
              href={url}
              rel="noreferrer"
              target="_blank"
            >
              <img
                style={{
                  width: '100%',
                  maxWidth: 600,
                  marginBottom: 8,
                }}
                src={props.post.image}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', paddingBottom: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: fonts.dm_sans,
              fontSize: 14,
              color: colors.blue_light,
              textDecoration: 'inherit',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(
                ROUTES.USER.replace(
                  ':username',
                  props.post.username!
                ).toLowerCase()
              )
            }}
          >
            <Box sx={{ width: 32, height: 32, marginRight: 1 }}>
              {props.post.emailHash && (
                <UserAvatar emailHash={props.post.emailHash} />
              )}
            </Box>
            {formatWithEllipsis(props.post.username ?? '', 10)}
          </Box>
          <Box
            component="a"
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 0.5,
              color: colors.cyan_blue_light_faded,
              fontFamily: fonts.dm_sans,
              fontSize: 11,
              textDecoration: 'inherit',
              cursor: 'pointer',
            }}
            href={tickerURL}
          >
            {props.post.symbol}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: fonts.dm_sans,
              fontSize: 11,
              paddingLeft: 1,
              color: colors.textGrey,
            }}
          >
            {getTimeElapsedRounded(props.post.date, props.post.createdAt)}
          </Box>
        </Box>
        {props.post.text && (
          <Box
            sx={{
              paddingBottom: 1,
              color: colors.text_white,
              fontSize: 13,
              fontFamily: fonts.dm_sans,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            <Box component="span" sx={{ cursor: 'pointer' }}>
              <MarkdownViewer markdownSource={props.post.text} />
            </Box>
          </Box>
        )}
        <Box>
          {deviceType === DeviceType.Desktop && (
            <PostIcons
              post={props.post}
              numberOfReplies={props.post.numberOfReplies ?? 0}
              onTopicReplyClick={
                props.onTopicReplyClick
                  ? (id: string) => {
                      props.onTopicReplyClick!(id)

                      if (props.userId) {
                        setIsRespondCommentExpanded(!isRespondCommentExpanded)
                      }
                    }
                  : undefined
              }
              onTopicBookmarkClick={props.onTopicBookmarkClick!}
              onShareActionsClick={props.onShareActionsClick!}
              onCameraActionsClick={props.onCameraActionsClick!}
              onMoreActionsClick={props.onMoreActionsClick!}
              onCommentClick={props.onCommentClick}
              isCreator={props.isCreator}
            />
          )}
          {deviceType === DeviceType.Mobile && (
            <PostIconsMobile
              post={props.post}
              numberOfReplies={props.post.numberOfReplies ?? 0}
              onTopicReplyClick={
                props.onTopicReplyClick
                  ? (id: string) => {
                      props.onTopicReplyClick!(id)

                      if (props.userId) {
                        setIsRespondCommentExpanded(!isRespondCommentExpanded)
                      }
                    }
                  : undefined
              }
              onTopicBookmarkClick={props.onTopicBookmarkClick!}
              onShareActionsClick={props.onShareActionsClick!}
              onCameraActionsClick={props.onCameraActionsClick!}
              onMoreActionsClick={props.onMoreActionsClick!}
              isCreator={props.isCreator}
            />
          )}
          {!props.disableRespondCommentExpansion &&
            isRespondCommentExpanded &&
            deviceType === DeviceType.Desktop && (
              <CommentReplyBox
                onCancel={() => {
                  setInitialText('')
                }}
                onTextChange={props.commentTextChange}
                onSubmit={(text, symbols, replyNotificationEmail) => {
                  return props.onReply(text, symbols, replyNotificationEmail, {
                    get: isRespondCommentExpanded,
                    set: setIsRespondCommentExpanded,
                  })
                }}
                replyNotificationEmail={props.replyNotificationEmail}
                initialText={initialText}
                submitButtonText={'REPLY'}
                hideCancelButton={true}
              />
            )}
          {!props.disableRespondCommentExpansion &&
            isRespondCommentExpanded &&
            deviceType === DeviceType.Mobile && (
              <CommentReplyMobileBox
                onCancel={() => {
                  setInitialText('')
                }}
                onTextChange={props.commentTextChange}
                onSubmit={(text, symbols, replyNotificationEmail) => {
                  return props.onReply(text, symbols, replyNotificationEmail, {
                    get: isRespondCommentExpanded,
                    set: setIsRespondCommentExpanded,
                  })
                }}
                onClose={() => {
                  setIsRespondCommentExpanded(false)
                }}
                replyNotificationEmail={props.replyNotificationEmail}
                initialText={initialText}
                submitButtonText={'REPLY'}
                hideCancelButton={true}
              />
            )}
        </Box>
      </Box>
      <Box
        sx={{
          width: 61,
          backgroundColor:
            deviceType === DeviceType.Mobile
              ? colors.drawer_bg_right
              : colors.drawer_bg_light,
        }}
      >
        <VoteUpDown
          numVotes={props.post.voteNum || 0}
          onVoteDownClick={() => props.onVote(VoteDirection.down)}
          onVoteUpClick={() => props.onVote(VoteDirection.up)}
          voteDirection={props.post.userVoteDirection}
        />
      </Box>
    </Box>
  )
}

export default Topic
function useStyles(theme: any) {
  throw new Error('Function not implemented.')
}
