import React from 'react'

type PlusIconProps = {
  isSelected: boolean
}

const PlusIcon: React.FC<PlusIconProps> = ({ isSelected }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      stroke={isSelected ? '#FFFFFF' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="10"
      y1="15.5139"
      x2="22"
      y2="15.5139"
      stroke={isSelected ? '#FFFFFF' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="16"
      y1="22"
      x2="16"
      y2="10"
      stroke={isSelected ? '#FFFFFF' : '#A0A4B2'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PlusIcon
