import React, {
  ChangeEvent,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react'
import classNames from 'classnames'
import MarkdownViewer from '../MarkdownViewer'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMarkdown } from '@fortawesome/free-brands-svg-icons/faMarkdown'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  FormHelperText,
  Input,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tab,
  Tabs,
} from '@mui/material'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { colors } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { useSymbolSearch } from 'components/SearchOverlay/hooks/useSymbolSearch'
import { formatWithEllipsis } from 'utils/stringUtils'

interface SymbolResult {
  symbol: string
  name: string
}

interface Props {
  error?: string
  name: string
  label?: string
  value?: string
  no_underline?: boolean
  onFocus?: () => void
  onFocusExit?: () => void
  onInput?: (value: string) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const [tabValue, setTabValue] = React.useState(0)
  const [markdownSource, setMarkdownSource] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const headerRef: MutableRefObject<HTMLDivElement | null | undefined> =
    React.useRef()
  const [dialogStyle, setDialogStyle] = React.useState<React.CSSProperties>()
  const [symbolResults, setSymbolResults] = useState<SymbolResult[]>([])

  const { symbol, setSymbol, getSymbols, searchSymbols } = useSymbolSearch()
  const textInputRef = useRef<HTMLTextAreaElement | null>(null)
  const listItemRefs = useRef<Array<HTMLLIElement | null>>([])
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, selectionStart, selectionEnd } = event.target
    setMarkdownSource(value)

    const modifiedValue = value.replace(/\n/g, '\\\n')

    if (props.onInput) {
      props.onInput(modifiedValue)
    }

    const textUpToCursor = value.substring(0, selectionStart ?? 0)
    const lastDollarIndex = textUpToCursor.lastIndexOf('$')

    // Check if the text after the last dollar sign up to the cursor position
    // contains a space or a newline. If it does, don't show the popover.
    const textAfterDollarSign = textUpToCursor.substring(lastDollarIndex + 1)
    const invalidCharactersAfterDollar = /[\s\n]/.test(textAfterDollarSign)

    if (
      lastDollarIndex !== -1 &&
      selectionStart !== null &&
      !invalidCharactersAfterDollar
    ) {
      const newSymbol = textAfterDollarSign

      if (newSymbol.trim() === '') {
        setDropdownOpen(false)
      } else {
        if (newSymbol !== symbol) {
          setSymbol(newSymbol)
        }
        setDropdownOpen(true)
      }
    } else {
      setDropdownOpen(false)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
    const headerRefClientRect = headerRef.current?.getBoundingClientRect()

    if (headerRefClientRect?.x && headerRefClientRect?.y) {
      if (deviceType === DeviceType.Mobile) {
        console.log(headerRefClientRect?.width)
        setDialogStyle({
          left: '10%',
          top: `calc(50% - 142.5px)`,
        })
        return
      }

      setDialogStyle({
        left: headerRefClientRect?.x + headerRefClientRect?.width - 241 + 'px',
        top: headerRefClientRect?.y + 36 + 'px',
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newSelectedTabValue: number
  ) => {
    setTabValue(newSelectedTabValue)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (dropdownOpen) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault() // Prevent the page from scrolling
          setFocusedIndex(prevIndex => {
            const nextIndex = Math.min(prevIndex + 1, symbolResults.length - 1)
            scrollToItem(nextIndex)
            return nextIndex
          })
          break
        case 'ArrowUp':
          event.preventDefault() // Prevent scrolling the input
          setFocusedIndex(prevIndex => {
            const nextIndex = Math.max(prevIndex - 1, 0)
            scrollToItem(nextIndex)
            return nextIndex
          })
          break
        case 'Enter':
          // Only intercept Enter if a selection is focused
          if (focusedIndex >= 0) {
            event.preventDefault()
            selectResult(focusedIndex)
          }
          break
        default:
          break
      }
    }
  }

  const scrollToItem = (index: number) => {
    const ref = listItemRefs.current[index]
    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }

  const selectResult = (index: number) => {
    const selectedItem = symbolResults[index]
    const inputElement = textInputRef.current
    if (!inputElement) return

    const { value, selectionStart } = inputElement
    const textUpToCursor = value.substring(0, selectionStart ?? 0)
    const lastDollarIndex = textUpToCursor.lastIndexOf('$')

    if (lastDollarIndex === -1 || selectionStart === null) return

    // Build the new string
    const beforeText = value.substring(0, lastDollarIndex)
    const afterText = value.substring(selectionStart)
    const newText = `${beforeText}$[${selectedItem.symbol}]${afterText}`

    // Update the markdown source with the new text
    setMarkdownSource(newText)

    const modifiedValue = newText.replace(/\n/g, '\\\n')

    if (props.onInput) {
      props.onInput(modifiedValue)
    }

    // Update the textarea value (this is necessary as we're directly manipulating the text)
    inputElement.value = newText

    // Move the cursor right after the newly inserted text
    const newPosition = beforeText.length + `$[${selectedItem.symbol}]`.length
    inputElement.setSelectionRange(newPosition, newPosition)

    // Close the popover after selection
    setDropdownOpen(false)
    setFocusedIndex(-1)
    setSymbol('')

    inputElement.focus()
  }

  useEffect(() => {
    if (symbol) {
      getSymbols({
        variables: {
          data: {
            symbol,
          },
        },
      })
    } else {
      setSymbolResults([])
      setDropdownOpen(false)
    }
  }, [symbol, getSymbols])

  useEffect(() => {
    if (searchSymbols?.getSymbols) {
      const newResults: SymbolResult[] = searchSymbols.getSymbols
        .slice(0, 16)
        .map(symbol => ({
          symbol: symbol.symbol,
          name: formatWithEllipsis(symbol.name, 20),
        }))
      setSymbolResults(newResults)
    }
  }, [searchSymbols])

  useEffect(() => {
    if (dropdownOpen && symbolResults.length > 0) {
      setFocusedIndex(0)
    } else {
      setFocusedIndex(-1)
    }
  }, [dropdownOpen, symbolResults])

  useEffect(() => {
    listItemRefs.current = listItemRefs.current.slice(0, symbolResults.length)
  }, [symbolResults.length])

  useEffect(() => {
    const initialValue = props.value ? props.value.replace(/\\\n/g, '\n') : ''
    setMarkdownSource(initialValue)
  }, [props.value])

  return (
    <>
      <div
        className={classNames(
          classes.markdownEditorWrapper,
          props.error && classes.error
        )}
      >
        <div
          className={classes.header}
          ref={headerRef as MutableRefObject<HTMLDivElement>}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="user settings tabs"
            classes={{
              indicator: classes.selectedTabIndicator,
              scroller: classes.selectedTabScroller,
            }}
          >
            <Tab
              disableRipple={true}
              key={0}
              className={classNames(
                classes.tab,
                tabValue === 0 ? classes.selectedTab : ''
              )}
              label={'Edit Text'}
            />
            <Tab
              disableRipple={true}
              key={1}
              className={classNames(
                classes.tab,
                tabValue === 1 ? classes.selectedTab : ''
              )}
              label={'Preview'}
            />
          </Tabs>
          <div
            className={classNames(classes.headerHelpIcon)}
            onClick={handleClickOpen}
          >
            <FontAwesomeIcon icon={faMarkdown} />
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            BackdropProps={{
              classes: {
                root: classes.root,
              },
            }}
            PaperProps={{
              style: dialogStyle,
              classes: {
                root: classes.paper,
              },
            }}
          >
            <DialogContent className={classes.dialogContentRoot}>
              <DialogContentText className={classes.dialogContentTextRoot}>
                <div className={classes.markdownInfoWrapper}>
                  <div className={classes.markdownInfoTopBar}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      color={colors.textGrey}
                      size="1x"
                      className={classes.closeIcon}
                      onClick={handleClose}
                    />
                  </div>
                  <div className={classes.markdownInfoHeader}>
                    <div className={classes.markdownInfoLeftColumn}>
                      You Type:
                    </div>
                    <div className={classes.markdownInfoRightColumn}>
                      You See:
                    </div>
                  </div>
                  <div className={classes.markdownInfoRowAlternate}>
                    <div className={classes.markdownInfoLeftColumn}>
                      *Italic*
                    </div>
                    <div className={classes.markdownInfoRightColumn}>
                      <span className={classes.italic}>Italic</span>
                    </div>
                  </div>
                  <div className={classes.markdownInfoRow}>
                    <div className={classes.markdownInfoLeftColumn}>
                      **Bold**
                    </div>
                    <div className={classes.markdownInfoRightColumn}>
                      <span className={classes.bold}>Bold</span>
                    </div>
                  </div>
                  <div className={classes.markdownInfoRowAlternate}>
                    <div className={classes.markdownInfoLeftColumn}>
                      * Item 1<br />* Item 2<br />* Item 3{' '}
                    </div>
                    <div className={classes.markdownInfoRightColumn}>
                      <ul className={classes.list}>
                        <li>Item 1</li>
                        <li>Item 2</li>
                        <li>Item 3</li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.markdownInfoRow}>
                    <div className={classes.markdownInfoLeftColumn}>
                      <span>[fnchart.com](https:/www.fnchart.com/)</span>
                    </div>
                    <div className={classes.markdownInfoRightColumn}>
                      <a
                        className={classes.link}
                        href="https://www.fnchart.com/"
                      >
                        fnchart.com
                      </a>
                    </div>
                  </div>
                  <div className={classes.markdownInfoRowAlternate}>
                    <div className={classes.markdownInfoLeftColumn}>
                      <span>
                        ![fnchart image alt
                        text](https://fnchart.com/favicon/android-chrome-96x96.png
                        "fnchart logo title")
                      </span>
                    </div>
                    <div className={classes.markdownInfoRightColumn}>
                      <img
                        src="https://fnchart.com/favicon/android-chrome-36x36.png"
                        alt="fnchart image alt text"
                      />
                    </div>
                  </div>
                  <div className={classes.markdownInfoRow}>
                    <div className={classes.markdownInfoFullColumn}>
                      For the full list of markdown syntax, please visit{' '}
                      <a
                        className={classes.link}
                        href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"
                        target="_blank"
                      >
                        Markdown Guide
                      </a>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
        <span
          id="input-container"
          style={{ display: tabValue === 1 ? 'none' : 'flex' }}
          className={classes.markdownEditorWrapperInner}
        >
          <Input
            error={!!props.error}
            className={classes.markdownEditor}
            inputRef={textInputRef}
            value={markdownSource}
            name={props.name}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={e => {
              if (props.onFocus) {
                props.onFocus()
              }
            }}
            onBlur={e => {
              if (props.onFocusExit) {
                props.onFocusExit()
              }
            }}
            multiline
            minRows={deviceType == DeviceType.Mobile ? 4 : 8}
            maxRows={deviceType == DeviceType.Mobile ? 4 : 12}
          />
          {dropdownOpen && (
            <div className={classes.symbolDropdown}>
              <List className={classes.symbolList} aria-label="search results">
                {symbolResults.map((result, index) => (
                  <ListItem
                    key={index}
                    ref={el => (listItemRefs.current[index] = el)}
                    className={classNames(classes.listItem, {
                      [classes.focusedItem]:
                        deviceType !== DeviceType.Mobile &&
                        index === focusedIndex,
                    })}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => selectResult(index)}
                  >
                    <ListItemText
                      primary={result.symbol}
                      secondary={result.name}
                      primaryTypographyProps={{
                        className: classes.primaryText,
                      }}
                      secondaryTypographyProps={{
                        className: classes.secondaryText,
                      }}
                      className={classes.listItemTextInline}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </span>
        <div
          style={{ display: tabValue === 0 ? 'none' : 'flex' }}
          className={classes.markdownViewer}
        >
          <MarkdownViewer
            markdownSource={props.value ? props.value : ''}
            isPreview={true}
          ></MarkdownViewer>
        </div>
      </div>
      {props.error && (
        <FormHelperText
          error={true}
          classes={{
            root: classes.formHelperRoot,
          }}
        >
          {props.error}
        </FormHelperText>
      )}
    </>
  )
})
