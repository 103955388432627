import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'

interface SpaceBetweenProps {
  spaceSize: number
  sx?: SxProps
}

const SpaceBetween: React.FC<SpaceBetweenProps> = ({
  children,
  spaceSize,
  sx,
}) => (
  <Box sx={sx}>
    {Array.isArray(children)
      ? children.map((child, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: index === children.length - 1 ? 0 : spaceSize,
            }}
          >
            {child}
          </Box>
        ))
      : children}
  </Box>
)

export default SpaceBetween
