import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export default makeStyles({
  wrapper: {
    width: 391,
    borderRadius: 20,
  },
  titleWrapper: {
    padding: '16px 24px',
    backgroundColor: colors.search_bg,
  },
  postTitleWrapper: {
    backgroundColor: colors.search_bg,
    padding: '16px 24px',
    borderBottom: `1px solid ${colors.drawer_border}`,
    display: 'flex',
    flexDirection: 'row',
  },
  postTitleLeft: {
    width: 'calc(100% - 48px)',
  },
  symbolName: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 8,
  },
  postTitle: {
    color: colors.white,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: 8,
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarWrapper: {
    maxWidth: 32,
    maxHeight: 32,
    minWidth: 32,
    minHeight: 32,
    minwidth: 32,
    height: 32,
    zIndex: 1,
  },
  username: {
    color: colors.text_white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: 8,
  },
  symbol: {
    color: colors.text_white,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: 8,
  },
  topicTime: {
    color: colors.text_white,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: 8,
  },
  iconWrapper: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  message: {
    lineHeight: '16px',
  },
  numberOfReplies: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 8,
  },
  postTitleRight: {
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowsWrapper: {
    paddingLeft: 16,
    width: 22,
    height: 72,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  arrow: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    width: 15,
    height: 15,
    lineHeight: '20px',
    cursor: 'pointer',
    alignSelf: 'center',
  },
  votes: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    alignSelf: 'center',
  },
  chartWrapper: {
    width: '100%',
    height: 232,
    backgroundColor: colors.search_bg,
    borderBottom: `1px solid ${colors.drawer_border}`,
    padding: '13px 0',
  },
  postTextWrapper: {
    color: colors.white_faded,
    backgroundColor: colors.search_bg,
    padding: '16px',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
})
