import { NavigateFunction } from 'react-router-dom'
import type { Location } from '@remix-run/router'
import { DEBUG_ENV } from 'index'

const BLACKLIST_URLS = ['www.youtube.com', 'www.youtu.be']

export function extractHostname(url: string) {
  var hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }

  hostname = hostname.split(':')[0]
  hostname = hostname.split('?')[0]

  return hostname
}

export function ensureHttpProtocol(url?: string) {
  if (url?.startsWith('http://') || url?.startsWith('https://')) {
    return url
  }
  return `http://${url}`
}

enum CaptureState {
  NotFound = 0,
  Found = 1,
  Captured = 2,
}

export function trimURL(pathname: string): string {
  const total_amount = pathname.split('/').length

  const fixed_path = pathname
    .split('/')
    .reduce(
      (accumulator, currentValue, currentIndex, array) => {
        if (currentIndex < total_amount) {
          if (accumulator.state === CaptureState.Found) {
            accumulator.state = CaptureState.Captured
            accumulator.url.push(currentValue)
          } else if (accumulator.state !== CaptureState.Captured) {
            if (currentValue === 'asset') {
              accumulator.state = CaptureState.Found
              accumulator.url.push(currentValue)
            } else {
              accumulator.url.push(currentValue)
            }
          }
        }

        return accumulator
      },
      { state: CaptureState.NotFound, url: [] as string[] }
    )
    .url.join('/')

  return fixed_path
}

export function titleFromURL(
  url: string | undefined | null
): string | undefined {
  if (!url?.length) {
    return
  }

  if (BLACKLIST_URLS.includes(extractHostname(url))) {
    return
  }

  const parts = url?.split('/')?.filter(part => part !== '')

  if (parts) {
    const headline_parts = parts[parts?.length - 1].replaceAll('-', ' ')

    const headline_parts_split = headline_parts.split('.')

    const headline_parts_rev =
      headline_parts_split.length > 1
        ? headline_parts_split[headline_parts_split?.length - 2]
        : headline_parts_split[0]

    const headline_cap = headline_parts_rev
      .split(' ')
      .map(word => {
        return word[0].toUpperCase() + word.substring(1)
      })
      .join(' ')

    const headline = headline_cap.includes('%')
      ? decodeURIComponent(headline_cap)
      : headline_cap

    return headline_cap
  } else {
    return undefined
  }
}

export function dateFromURL(
  url: string | undefined | null
): string | undefined {
  const parts = url?.split('/')?.filter(part => part !== '')

  if (parts) {
    // Regular expression to match the date format "yyyy/mm/dd" in the URL or yyyy/m/d
    const dateRegex = /(\d{4}\/\d{1,2}\/\d{1,2})/

    // Use the regular expression to extract the date from the URL
    const match = parts.join('/').match(dateRegex)

    if (!(match && match[1])) {
      return undefined
    }

    const extractedDate = match[1]

    return extractedDate
  }
}

export function handleTopicHeaderClick(
  location: any,
  navigate: NavigateFunction,
  assetType: string,
  symbol: string,
  id?: string,
  url?: string
) {
  if (id) {
    navigate(`/${assetType.toLowerCase()}/${symbol.toLowerCase()}/topic/${id}`)
  } else {
    const urlWithHttp = ensureHttpProtocol(url)
    if (urlWithHttp.includes(window.location.hostname)) {
      const urlObj = new URL(urlWithHttp)
      navigate(urlObj.pathname + urlObj.search)
    } else {
      window.open(urlWithHttp, '_blank')
    }
  }
}
