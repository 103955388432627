import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export default makeStyles({
  notificationWrapper: {
    zIndex: 100,
    width: 340,
    height: 302,
    backgroundColor: colors.drawer_bg_right,
    position: 'absolute',
    left: -290,
    top: 40,
    boxShadow: `0px 5px 20px 0px ${colors.menu_bg}`,
  },
  header: {
    color: colors.white,
    fontSize: 13,
    fontWeight: 400,
    padding: '10px 16px',
    borderBottom: `1px solid ${colors.border}`,
  },
  seeAll: {
    color: colors.blue_lighter,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    padding: 16,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
  },
})
