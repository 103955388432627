import React from 'react'

// Define a type for the component props to include width and height
interface CameraIconProps {
  width?: number
  height?: number
}

const CameraIcon: React.FC<CameraIconProps> = ({ width = 40, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.8" cx="20" cy="20" r="20" fill="#4C61EE" />
    <mask
      id="mask0_6135_53246"
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="24"
      height="24"
    >
      <rect x="8.75" y="8.75" width="22.8571" height="22.8571" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6135_53246)">
      <path
        d="M20.1785 25.05C21.2712 25.05 22.1977 24.67 22.9577 23.9099C23.7178 23.1498 24.0978 22.2234 24.0978 21.1306C24.0978 20.0378 23.7178 19.1114 22.9577 18.3513C22.1977 17.5913 21.2712 17.2112 20.1785 17.2112C19.0857 17.2112 18.1592 17.5913 17.3992 18.3513C16.6391 19.1114 16.2591 20.0378 16.2591 21.1306C16.2591 22.2234 16.6391 23.1498 17.3992 23.9099C18.1592 24.67 19.0857 25.05 20.1785 25.05ZM20.1785 23.6215C19.4752 23.6215 18.8842 23.3821 18.4055 22.9035C17.9269 22.4249 17.6876 21.8339 17.6876 21.1306C17.6876 20.4273 17.9269 19.8363 18.4055 19.3577C18.8842 18.8791 19.4752 18.6397 20.1785 18.6397C20.8818 18.6397 21.4727 18.8791 21.9514 19.3577C22.43 19.8363 22.6693 20.4273 22.6693 21.1306C22.6693 21.8339 22.43 22.4249 21.9514 22.9035C21.4727 23.3821 20.8818 23.6215 20.1785 23.6215ZM12.8525 28.2734C12.3714 28.2734 11.9642 28.1068 11.6309 27.7734C11.2975 27.4401 11.1309 27.0329 11.1309 26.5518V15.7094C11.1309 15.2283 11.2975 14.8211 11.6309 14.4878C11.9642 14.1544 12.3714 13.9878 12.8525 13.9878H15.7609L17.5228 12.083H22.8341L24.596 13.9878H27.5044C27.9855 13.9878 28.3927 14.1544 28.7261 14.4878C29.0594 14.8211 29.2261 15.2283 29.2261 15.7094V26.5518C29.2261 27.0329 29.0594 27.4401 28.7261 27.7734C28.3927 28.1068 27.9855 28.2734 27.5044 28.2734H12.8525ZM12.8525 26.8449H27.5044C27.5899 26.8449 27.6601 26.8174 27.7151 26.7625C27.77 26.7075 27.7975 26.6373 27.7975 26.5518V15.7094C27.7975 15.6239 27.77 15.5537 27.7151 15.4987C27.6601 15.4438 27.5899 15.4163 27.5044 15.4163H23.9587L22.2114 13.5116H18.1455L16.3982 15.4163H12.8525C12.767 15.4163 12.6968 15.4438 12.6418 15.4987C12.5869 15.5537 12.5594 15.6239 12.5594 15.7094V26.5518C12.5594 26.6373 12.5869 26.7075 12.6418 26.7625C12.6968 26.8174 12.767 26.8449 12.8525 26.8449Z"
        fill="white"
      />
    </g>
  </svg>
)

export default CameraIcon
