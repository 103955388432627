import { makeStyles } from '@mui/styles'
import theme from '../../mui-theme'
import { colors, fonts } from '../../constants'

const inputWrapperHeight = 70
const formControlHeight = 44

export default makeStyles({
  wrapper: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    backgroundColor: colors.search_bg,
    boxSizing: 'border-box',

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      '&-thumb': {
        backgroundColor: colors.box_bg,
      },
    },

    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },

  closeWithIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: fonts.dm_sans,
    color: colors.textGrey,
    fontSize: 16,
    fontWeight: 500,
    alignSelf: 'center',
    marginLeft: 'auto',
  },

  iconClose: {
    marginLeft: 8,
  },

  scrollContainer: {
    padding: '0 108px',

    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },

  formControlRoot: {
    height: formControlHeight,
    width: 'calc(100% - 41px)',
    marginRight: 15.5,
    marginLeft: 32,

    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },

  inputWithIcon: {
    display: 'flex',
    width: 'calc(100% - 618px)',
    borderRadius: 16.15,
    backgroundColor: colors.search_input_bg,

    [theme.breakpoints.down('lg')]: {
      padding: '0 16px',
      width: '100%',
      backgroundColor: colors.drawer_bg_right,
    },
  },

  input: {
    width: '100%',
    color: colors.text_white,
    padding: 0,
    fontSize: 16,
    fontFamily: fonts.dm_sans,
  },

  inputRoot: {
    boxSizing: 'border-box',
    width: '100%',
    height: inputWrapperHeight,

    '&:before': {
      content: `''`,
      borderBottom: 'none !important',
    },

    '&:after': {
      content: `''`,
      borderBottom: 'none !important',
    },
  },

  iconSearch: {
    alignSelf: 'center',
    cursor: 'pointer',
    fontSize: 18,
    paddingRight: 23,
  },

  topNavWrapper: {
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
    position: 'sticky',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 30px 0 24px',
    display: 'flex',
    justifyContent: 'flex-start',
    height: inputWrapperHeight,
    backgroundColor: colors.search_bg,
    marginRight: 8,
    marginBottom: 24,

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      height: 50,
      marginBottom: 0,
      padding: 0,
    },

    '&:before': {
      content: `''`,
      borderBottom: 'none',
    },

    '&:focus-within': {
      '&:after': {
        transform: 'scaleX(1)',
      },
    },

    '.MuiInput-underline': {
      '&:after': {
        borderBottom: '0',
      },
    },
  },

  logoWrapper: {
    height: 24,
    width: 24,
    marginRight: 60,
  },

  drawerPaperRight: {
    backgroundColor: colors.drawer_bg_right,
  },

  closeBtnRight: {
    backgroundColor: 'transparent',
    color: colors.textGrey,
    right: '21px',
    top: '17px',
    padding: 0,
    width: '13px',
    height: '13px',
    position: 'absolute',
  },

  iconLeftArrow: {
    alignSelf: 'center',
  },

  title: {
    padding: '0 108px',
    color: colors.white,
    fontFamily: fonts.dm_sans,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: 12,
    marginRight: 16,
    flexGrow: 1,

    [theme.breakpoints.down('lg')]: {
      fontSize: 24,
      fontWeight: 500,
      color: colors.white,
      lineHeight: '30px',
      marginBottom: 0,
      marginRight: 0,
      padding: '16px 16px 4px 16px',
    },
  },
})
