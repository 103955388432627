import React, { forwardRef } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import useStyles from './styles'
import theme from '../../../mui-theme'
import classNames from 'classnames'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { ExDate } from '../../../constants'

interface Props {
  error?: string
  name?: string
  placeholder?: string
  no_underline?: boolean
  selectedDate?: Date
  onChange?: (date: Date | null | undefined) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const [value, setValue] = React.useState<Date | null>(
    props.selectedDate || null
  )
  const [open, setOpen] = React.useState<boolean>(false)

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setValue(date)
      props.onChange && props.onChange(date)
      setOpen(false)
    }
  }

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <div className={classes.inputBox}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={value}
              onChange={handleDateChange}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              renderInput={params => (
                <TextField variant="filled" inputMode="numeric" {...params} />
              )}
              mask="____/__/__"
              inputFormat="yyyy/MM/dd"
              inputRef={ref}
              disablePast={false}
              showToolbar={false}
              InputProps={{
                classes: {
                  input: classNames(classes.input),
                  underline: classNames(
                    classes.underline,
                    props.no_underline && classes.noUnderline
                  ),
                  root: classNames(
                    classes.inputRoot,
                    props.no_underline
                      ? classes.noUnderline
                      : classes.inputRootUnderline
                  ),
                },
              }}
              DialogProps={{
                classes: { paper: classes.hideDialogActions },
              }}
            />
          </LocalizationProvider>
        </div>
      </FormControl>
      {props.error && (
        <FormHelperText
          error={true}
          classes={{
            root: classes.formHelperRoot,
          }}
        >
          {props.error}
        </FormHelperText>
      )}
    </div>
  )
})
