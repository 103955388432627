import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

const inputWrapperHeight = 70
const formControlHeight = 44

export default makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.search_bg,
    boxSizing: 'border-box',
    overflowY: 'hidden',

    [theme.breakpoints.down('lg')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },

  tabs: {
    minHeight: 'auto',
  },

  tab: {
    fontSize: 18,
    fontWeight: 500,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: 'auto',
    padding: 0,
    marginRight: 40,
    textTransform: 'capitalize',
  },

  selectedTab: {
    color: `${colors.white} !important`,
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
  },

  selectedTabScroller: {
    height: 36,
    paddingBottom: 12,
  },

  inputWithIcon: {
    display: 'flex',
    width: 'calc(100% - 618px)',
    borderRadius: 16.15,
    backgroundColor: colors.search_input_bg,

    [theme.breakpoints.down('lg')]: {
      padding: '0 16px',
      width: '100%',
      backgroundColor: colors.drawer_bg_right,
    },
  },

  formControlRoot: {
    height: formControlHeight,
    width: 'calc(100% - 41px)',
    marginRight: 15.5,
    marginLeft: 32,

    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },

  iconSearch: {
    alignSelf: 'center',
    cursor: 'pointer',
    fontSize: 18,
    paddingRight: 23,
  },

  input: {
    width: '100%',
    color: colors.text_white,
    padding: 0,
    fontSize: 16,
    fontFamily: fonts.dm_sans,
  },

  inputRoot: {
    boxSizing: 'border-box',
    width: '100%',
    height: inputWrapperHeight,

    '&:before': {
      content: `''`,
      borderBottom: 'none !important',
    },

    '&:after': {
      content: `''`,
      borderBottom: 'none !important',
    },
  },

  bodyWrapper: {
    padding: '0 108px 30px 108px',
    display: 'flex',
    height: 'calc(100% - 124px)',
    boxSizing: 'border-box',

    [theme.breakpoints.down('lg')]: {
      padding: 0,
      height: 'calc(100% - 34px)',
      flexDirection: 'column-reverse',
    },
  },

  userTabsWrapper: {
    flexDirection: 'column',
    width: 'calc(100% - 392px)',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 'calc(100% - 170px)',
    },
  },

  tabsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  },

  menuRoot: {
    marginTop: 8,
  },

  menuList: {
    borderRadius: '8px',
    padding: 0,
  },

  moreIcon: {
    alignSelf: 'center',
    cursor: 'pointer',
    backgroundColor: colors.box_bg,
    borderRadius: 100,
    padding: 8,
  },

  deleteAccountText: {
    color: colors.red_light,
    fontSize: 16,
    fontFamily: fonts.dm_sans,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },

  trashIcon: {
    color: colors.red_light,
    marginLeft: 'auto',
  },

  topNavWrapper: {
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 30px 0 24px',
    display: 'flex',
    justifyContent: 'flex-start',
    height: inputWrapperHeight,
    width: '100%',
    marginRight: 8,
    marginBottom: 12,
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 16px',
      height: 34,
      marginBottom: 0,
      borderBottom: `1px solid ${colors.drawer_border}`,
    },

    '&:before': {
      content: `''`,
      borderBottom: 'none',
    },

    '&:focus-within': {
      '&:after': {
        transform: 'scaleX(1)',
      },
    },

    '.MuiInput-underline': {
      '&:after': {
        borderBottom: '0',
      },
    },
  },

  logoWrapper: {
    height: 24,
    width: 24,
    marginRight: 60,
  },

  username: {
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    fontWeight: 400,
    color: colors.textGreyLight,
    marginRight: 16,

    [theme.breakpoints.down('lg')]: {
      alignSelf: 'center',
      flexGrow: 1,
      textAlign: 'center',
    },
  },

  closeWithIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: fonts.dm_sans,
    color: colors.textGrey,
    fontSize: 16,
    fontWeight: 500,
    alignSelf: 'center',
    marginLeft: 'auto',
  },

  iconClose: {
    marginLeft: 8,
  },

  iconLeftArrow: {
    alignSelf: 'center',
  },
})
