import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'

interface TickerImageProps {
  symbol: string
  width?: number
  height?: number
}

const TickerImage: React.FC<TickerImageProps> = ({ symbol, width, height }) => {
  const classes = useStyles()
  const [isImageLoaded, setIsImageLoaded] = useState(true)

  useEffect(() => {
    setIsImageLoaded(true)
  }, [symbol])

  const imgStyles = {
    ...(width && { width }),
    ...(height && { height }),
  }

  return (
    <div className={classes.symbolContainer}>
      {isImageLoaded ? (
        <img
          className={classes.symbolIcon}
          src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
          alt={symbol}
          onError={() => setIsImageLoaded(false)}
          style={imgStyles}
        />
      ) : (
        <div className={classes.fallbackCircle} style={imgStyles}>
          {symbol.charAt(0)}
        </div>
      )}
    </div>
  )
}

export default TickerImage
