import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'

export default makeStyles({
  wrapper: {
    padding: 4,
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexWrap: 'wrap',
  },
  label: {
    fontSize: 14,
    color: colors.text_white,
    fontWeight: 400,
    lineHeight: '20px',
  },
})
