import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles<typeof theme>(
  theme => ({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    arrowCounter: {
      width: 18,
      textAlign: 'center',
      flexDirection: 'column',
    },

    numVotes: {
      color: colors.text_white,
      fontFamily: fonts.dm_sans,
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: 1,
      textAlign: 'center',
      fontWeight: 500,
      marginTop: 8,
      marginBottom: 8,
    },

    numVotesLarge: {
      fontSize: 14,
      lineHeight: '20px',
    },

    arrow: {
      color: colors.textGrey,
      textAlign: 'center',
      cursor: 'pointer',
    },

    votedUp: {
      color: colors.blue_light,
    },

    votedDown: {
      color: colors.red_light,
    },
  }),
  { index: 1 }
)
