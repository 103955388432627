import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'

export default makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: fonts.dm_sans,

    '&:focus-visible': {
      outline: 'none',
    },
  },
  modalInner: {
    backgroundColor: colors.search_bg,
    width: 550,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  tutorialImage: {
    height: 403,
    borderRadius: '20px 20px 0 0',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '100%',
    },
  },
  tutorialBody: {
    padding: 32,
    boxSizing: 'border-box',
    width: '100%',
  },
  title: {
    color: colors.text_white,
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: '-0.4px',
    lineHeight: '30px',
    margin: 0,

    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
  },
  body: {
    color: colors.textGreyLight,
    fontSize: 16,
    fontWeight: 400,
    paddingTop: 16,
  },
  buttonsWrapper: {
    display: 'flex',
    marginTop: 40,
    justifyContent: 'space-between',
  },
  radioButtonWrapper: {
    display: 'flex',
    width: 110,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioButton: {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  skipButton: {
    width: 99,
    padding: '16px 24px',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '2.75px',
    lineHeight: '16px',
    color: colors.textGreyLight,
    backgroundColor: colors.box_bg,
    borderRadius: 10,
    border: 'none',
    cursor: 'pointer',
  },
  nextButton: {
    width: 99,
    padding: '16px 24px',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '2.75px',
    lineHeight: '16px',
    color: colors.white_faded,
    backgroundColor: colors.blue,
    borderRadius: 10,
    border: 'none',
    cursor: 'pointer',
  },
}))
