import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'

export default makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: colors.search_bg,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    minHeight: '100%',
    fontFamily: fonts.dm_sans,
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
    padding: '10px 16px',
    boxSizing: 'border-box',
  },
  topNavBar: {
    display: 'flex',
    height: 48,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerRight: {
    width: 28,
    height: 28,
  },
  iconBackWrapper: {
    backgroundColor: colors.box_bg,
    width: 28,
    height: 28,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBack: {
    color: colors.white,
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 26,
  },
  menuItem: {
    display: 'flex',
    marginBottom: 24,
    cursor: 'pointer',
    color: colors.text_white,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    alignItems: 'center',
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    alignSelf: 'center',
    marginRight: 16,
  },
  icon: {
    marginRight: 16,
    color: colors.textGreyLight,
  },
})
