import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CreateTopicPanel, {
  CreateTopicInput,
} from '../../components/TopicSidebar/CreateTopicPanel'
import {
  PostDataFragment,
  PostType,
  PostsDocument,
  PostsQuery,
  PostsQueryVariables,
  useGetPostQuery,
  useGetSymbolDateQuery,
  useGetSymbolQuery,
  useUpdatePostMutation,
} from '../../generated/graphql'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { ExDate, dateToExDate } from '../../constants'
import { DEBUG_ENV } from '../../index'
import CreateTopicPanelMobile from 'components/TopicSidebar/CreateTopicPanelMobile'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { useEmailNotification } from 'hooks/useEmailNotification'
import ROUTES from 'constants/routes'

interface PostEditProps {
  offset?: number
  toDate?: string
}

interface PostDate {
  date: string | undefined
  today: boolean
}

export const PostEditIntegration: React.FC<{}> = props => {
  const deviceType = useDeviceType()
  let { symbol, editId } = useParams<'symbol' | 'editId'>()
  const postDate = useRef<PostDate>({
    date: undefined,
    today: true,
  })
  const navigate = useNavigate()
  let location = useLocation()
  // TODO: create global loading indicator.
  const [updatePost] = useUpdatePostMutation()
  const { emailNotification, handleEmailNotificationChange } =
    useEmailNotification()
  const [assetType, setAssetType] = useState<string>(AssetTypeEnum.stock)
  const symbolUpperCase = symbol?.toUpperCase()
  if (DEBUG_ENV) {
    if (!symbol?.toUpperCase().length) console.error('No symbol supplied!')
  }
  const { data: symbolQuery } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: symbolUpperCase || '',
      },
    },
    fetchPolicy: 'cache-first',
  })

  const { data: symbolDateData, loading: symbolDateLoading } =
    useGetSymbolDateQuery({
      fetchPolicy: 'cache-first',
      variables: {
        symbol: symbolUpperCase || '',
      },
    })

  useEffect(() => {
    if (symbolQuery?.getSymbol) {
      setAssetType(symbolQuery.getSymbol.type.toLowerCase())
    }
  }, [symbolQuery])

  // TODO: Get list of posts here doesn't work, as we need to incorporate the correct offset or a toDate
  const state: PostEditProps = location.state

  const { offset, toDate } = state ?? {}

  const { data: getPost } = useGetPostQuery({
    variables: {
      id: editId as string,
    },
    // fetchPolicy: 'cache-first',
  })

  const editedPost = getPost?.getPost

  let panelData: Partial<CreateTopicInput> | undefined = undefined

  if (editedPost) {
    panelData = {}

    // editedPost.date is stored as an ISO date string
    const postDate = new ExDate(editedPost.date)

    panelData.headline = editedPost.title
    panelData.postType = editedPost.postType
    panelData.date = postDate.datetime.toDate().toISOString()
    panelData.text = editedPost.text || undefined
    panelData.url = editedPost.url || undefined
    panelData.notifyViaEmail = emailNotification
  }

  postDate.current = {
    date: !!toDate ? toDate : undefined,
    today: !toDate,
  }

  const navigateBackToPreviousPage = () => {
    const date = postDate.current.today ? '' : postDate.current.date

    navigate(
      location.pathname.replace(/\/edit.*$/, date ? `?date=${date}` : '')
    )
  }

  const sortPosts = (a: PostDataFragment, b: PostDataFragment) => {
    if (b.date !== a.date) {
      return b.date.localeCompare(a.date)
    }

    if (b.votes !== a.votes) {
      return b.votes - a.votes
    }

    const createdAtA = new ExDate(a.createdAt).getTime()
    const createdAtB = new ExDate(b.createdAt).getTime()

    return createdAtB - createdAtA
  }

  // Cache is not updating because we are missing the "offset" number for the getPosts query
  const handleSubmit = (postData: CreateTopicInput) => {
    console.debug(
      `---> PostEditIntegration -- onPostClick -- postDate: ${postData.date}`
    )

    handleEmailNotificationChange(postData.notifyViaEmail)

    const postDataDate = dateToExDate(new Date(postData.date))

    updatePost({
      variables: {
        data: {
          id: editId as string,
          date: postDataDate.toApiString(),
          title: postData.headline,
          text: postData.text,
          url: postData.url,
          additionalSymbols: postData.additionalSymbols,
        },
      },
      update(cache, { data }) {
        if (!data) {
          return
        }

        const readQueryResult = cache.readQuery<
          PostsQuery,
          PostsQueryVariables
        >({
          query: PostsDocument,
          variables: {
            postSymbol: symbolUpperCase as string,
            postAssetType: assetType.toUpperCase(),
          },
        })

        const posts = readQueryResult?.posts || []
        let updatedPosts = posts.map(post => {
          if (post.id === editId) {
            return {
              ...post,
              date: postDataDate.toApiString(),
              title: postData.headline,
              text: postData.text ?? null,
              url: postData.url ?? null,
            }
          }

          return post
        })

        updatedPosts = updatedPosts.sort(sortPosts)

        // Check if the edited post is the last post
        const editedPostIndex = updatedPosts.findIndex(
          post => post.id === editId
        )
        const wouldBeLast = editedPostIndex === updatedPosts.length - 1

        if (wouldBeLast) {
          // Remove the last post from the array
          updatedPosts.pop()
        }

        if (updatedPosts.length > 0) {
          cache.writeQuery({
            query: PostsDocument,
            data: {
              posts: updatedPosts,
            },
            variables: {
              postSymbol: symbolUpperCase as string,
              postAssetType: assetType.toUpperCase(),
            },
          })
        }
      },
    })
      .then(() => {
        if (postData.postType === PostType.Story) {
          navigate(
            ROUTES.STORY_EDIT.replace(':symbol', symbol!)
              .replace(':type', assetType)
              .replace(':storyId', editId!)
              .toLowerCase()
          )
        } else {
          navigateBackToPreviousPage()
        }
      })
      .catch(e => {
        if (DEBUG_ENV) console.error(e)
      })
  }

  const symbolDate = !!symbolDateData?.getSymbolDate?.date
    ? (symbolDateData.getSymbolDate.date as String)
    : undefined

  if (DEBUG_ENV) {
    if (!symbolDate)
      console.warn(
        `--> CreateTopicPanelIntegration -- Symbol: ${symbol} has no symbolDate response`
      )
  }

  const handleBackButtonClick = () => {
    navigateBackToPreviousPage()
  }

  return (
    <>
      {panelData && (
        <>
          {deviceType === DeviceType.Desktop && (
            <CreateTopicPanel
              symbolDate={new ExDate(symbolDate as String)}
              isEdit={true}
              type={symbolUpperCase}
              data={panelData}
              onCancelNewTopicClick={handleBackButtonClick}
              onPostClick={handleSubmit}
            />
          )}
          {deviceType === DeviceType.Mobile && (
            <CreateTopicPanelMobile
              symbolDate={new ExDate(symbolDate as String)}
              isEdit={true}
              type={symbolUpperCase}
              data={panelData}
              onCancelNewTopicClick={handleBackButtonClick}
              onPostClick={handleSubmit}
            />
          )}
        </>
      )}
    </>
  )
}
