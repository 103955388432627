import { makeStyles } from '@mui/styles'
import { colors } from '../../../../constants'
import theme from '../../../../mui-theme'

export default makeStyles({
  noPostsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 0',
  },

  postWrapper: {
    padding: '24px 32px',
    backgroundColor: colors.drawer_bg_right,
    borderRadius: 8,
    marginBottom: 8,

    [theme.breakpoints.down('lg')]: {
      padding: 16,
      backgroundColor: colors.search_bg,
      borderBottom: `1px solid ${colors.drawer_border}`,
      borderRadius: 0,
    },
  },

  noPostsCommentsIcon: {
    color: colors.textGreyLight,
    fontSize: 27,
  },

  noPostsText: {
    color: colors.textGreyLight,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '22px',
    marginTop: 16,
  },

  tabContent: {
    width: '100%',
    height: 'calc(100% - 29px)',
    overflowY: 'auto',
    marginTop: 24,

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },

    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: colors.search_bg,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.drawer_bg,
      '&:hover': {
        backgroundColor: colors.drawer_bg,
      },
    },
  },

  wrapper: {
    marginBottom: 8,
    marginRight: 8,

    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      marginRight: 0,
    },
  },
})
