import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

const inputWrapperHeight = 70
const formControlHeight = 44

export default makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.search_bg,
    overflowY: 'auto',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },

  topNavWrapper: {
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 30px 0 24px',
    display: 'flex',
    justifyContent: 'flex-start',
    height: inputWrapperHeight,
    width: '100%',
    marginBottom: 12,
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 16px',
      height: 34,
      marginBottom: 0,
      borderBottom: `1px solid ${colors.drawer_border}`,
    },

    '&:before': {
      content: `''`,
      borderBottom: 'none',
    },

    '&:focus-within': {
      '&:after': {
        transform: 'scaleX(1)',
      },
    },

    '.MuiInput-underline': {
      '&:after': {
        borderBottom: '0',
      },
    },
  },

  logoWrapper: {
    height: 24,
    width: 24,
    marginRight: 60,
  },

  inputWithIcon: {
    display: 'flex',
    width: 'calc(100% - 618px)',
    borderRadius: 16.15,
    backgroundColor: colors.search_input_bg,

    [theme.breakpoints.down('lg')]: {
      padding: '0 16px',
      width: '100%',
      backgroundColor: colors.drawer_bg_right,
    },
  },

  formControlRoot: {
    height: formControlHeight,
    width: 'calc(100% - 41px)',
    marginRight: 15.5,
    marginLeft: 32,

    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },

  input: {
    width: '100%',
    color: colors.text_white,
    padding: 0,
    fontSize: 16,
    fontFamily: fonts.dm_sans,
  },

  inputRoot: {
    boxSizing: 'border-box',
    width: '100%',
    height: inputWrapperHeight,

    '&:before': {
      content: `''`,
      borderBottom: 'none !important',
    },

    '&:after': {
      content: `''`,
      borderBottom: 'none !important',
    },
  },

  iconSearch: {
    alignSelf: 'center',
    cursor: 'pointer',
    fontSize: 18,
    paddingRight: 23,
  },

  username: {
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    fontWeight: 400,
    color: colors.textGreyLight,
    marginRight: 16,

    [theme.breakpoints.down('lg')]: {
      alignSelf: 'center',
      flexGrow: 1,
      textAlign: 'center',
    },
  },

  closeWithIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: fonts.dm_sans,
    color: colors.textGrey,
    fontSize: 16,
    fontWeight: 500,
    alignSelf: 'center',
    marginLeft: 'auto',
  },

  iconClose: {
    marginLeft: 8,
  },

  iconLeftArrow: {
    alignSelf: 'center',
  },

  shareablePost: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: colors.search_bg,
    overflowY: 'auto',
    height: 'calc(100% - 82px)',

    [theme.breakpoints.down('lg')]: {
      display: 'block',
      height: 'calc(100% - 34px)',
    },

    '&::-webkit-scrollbar': {
      width: 12,
      height: 12,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: colors.search_bg,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.drawer_bg,
      '&:hover': {
        backgroundColor: colors.drawer_bg,
      },
    },
  },

  storyPostWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: colors.drawer_bg_right,
  },

  storyChildPostsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  storyHeader: {
    padding: '16px 24px',
    backgroundColor: colors.drawer_bg_right,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  logoLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },

  userDetailsRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  avatarWrapper: {
    maxWidth: 32,
    maxHeight: 32,
    minWidth: 32,
    minHeight: 32,
    height: 32,
    zIndex: 1,
  },

  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 16,
  },

  usernameStory: {
    fontFamily: fonts.dm_sans,
    fontSize: 16,
    fontWeight: 500,
    color: colors.text_white,
  },

  followCounts: {
    color: colors.textGreyLight,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },

  storyTitle: {
    backgroundColor: colors.search_bg,
  },

  symbolTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 0 16px',
    backgroundColor: colors.search_bg,
    alignItems: 'flex-end',
  },

  symbol: {
    color: colors.white_faded,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '24px',
  },

  symbolName: {
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.textGreyLight,
  },

  storyTitleText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    padding: 16,
  },
})
