import { Tab, Tabs } from '@mui/material'
import { forwardRef } from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'
import React from 'react'
import classNames from 'classnames'
import Posts from './Posts'
import {
  FollowerData,
  FollowingData,
  UserTabItem,
} from '../interfaces/userTabItem'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import Comments from './Comments'
import Following from './Following'
import Followers from './Followers'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'

export interface UserActivityProps {
  currentUserId?: string
  tabs: UserTabItem[]
  hasMorePosts: boolean
  posts: PostData[]
  comments: CommentData[]
  followings: FollowingData[]
  followers: FollowerData[]
  hasMoreComments: boolean
  isViewingOwnProfile: boolean
  onVote: (
    direction: VoteDirection,
    topicId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onCommentVote: (
    direction: VoteDirection,
    commentId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
  onPostClick: (id: string, symbol: string) => void
  loadMorePosts: () => void
  loadMoreComments: () => void
  loadMoreFollowing: () => void
  onFollowUser: (userId: string) => void
  onUnfollowUser: (userId: string) => void
  setShowSignUpModal: (show: boolean) => void
}

export const getTickerUrl = (ticker?: string): string => {
  return '/asset/' + ticker!.toLowerCase()
}

export default forwardRef<HTMLInputElement, UserActivityProps>((props, ref) => {
  const classes = useStyles(theme)
  const [selectedTabItemIndex, setSelectedTabItemIndex] = React.useState(0)

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newSelectedTabIndex: number
  ) => {
    setSelectedTabItemIndex(newSelectedTabIndex)
  }

  const renderTabContent = () => {
    switch (selectedTabItemIndex) {
      case 0:
        return (
          <Posts
            userId={props.currentUserId}
            hasMorePosts={props.hasMorePosts}
            posts={props.posts}
            onVote={props.onVote}
            onPostClick={props.onPostClick}
            onShareActionsClick={props.onShareActionsClick}
            onCameraActionsClick={props.onCameraActionsClick}
            onMoreActionsClick={props.onMoreActionsClick}
            loadMorePosts={props.loadMorePosts}
          />
        )
      case 1:
        return (
          <Comments
            userId={props.currentUserId}
            hasMoreComments={props.hasMoreComments}
            comments={props.comments}
            onVote={props.onCommentVote}
            onShareActionsClick={props.onShareActionsClick}
            onCameraActionsClick={props.onCameraActionsClick}
            onCameraActionsCommentClick={props.onCameraActionsCommentClick}
            onMoreActionsClick={props.onMoreActionsClick}
            onMoreCommentActionsClick={props.onMoreCommentActionsClick}
            loadMoreComments={props.loadMoreComments}
            onPostClick={props.onPostClick}
            onCommentClick={props.onCommentClick}
          />
        )
      case 2:
        return (
          <Following
            currentUserId={props.currentUserId}
            followingUsers={props.followings}
            hasMoreFollowingUsers={false}
            isViewingOwnProfile={props.isViewingOwnProfile}
            loadMoreFollowingUsers={props.loadMoreFollowing}
            onFollowUser={props.onFollowUser}
            onUnfollowUser={props.onUnfollowUser}
            setShowSignUpModal={props.setShowSignUpModal}
          />
        )
      case 3:
        return (
          <Followers
            currentUserId={props.currentUserId}
            followers={props.followers}
            hasMoreFollowers={false}
            isViewingOwnProfile={props.isViewingOwnProfile}
            loadMoreFollowers={props.loadMoreFollowing}
            onFollowUser={props.onFollowUser}
            onUnfollowUser={props.onUnfollowUser}
            setShowSignUpModal={props.setShowSignUpModal}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={selectedTabItemIndex}
        className={classes.tabs}
        onChange={handleTabChange}
        aria-label="user tabs"
        sx={{
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        }}
        classes={{
          indicator: classes.selectedTabIndicator,
        }}
      >
        <Tab
          key={'0'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 0 ? classes.selectedTab : ''
          )}
          label={<div>{props.tabs[0].label}</div>}
        />
        <Tab
          key={'1'}
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 1 ? classes.selectedTab : ''
          )}
          label={<div>{props.tabs[1].label}</div>}
        />
        <Tab
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 2 ? classes.selectedTab : ''
          )}
          label={
            <div>
              {props.followings.length} {props.tabs[2].label}
            </div>
          }
        />
        <Tab
          className={classNames(
            classes.tab,
            selectedTabItemIndex === 3 ? classes.selectedTab : ''
          )}
          label={
            <div>
              {props.followers.length} {props.tabs[3].label}
            </div>
          }
        />
      </Tabs>
      {renderTabContent()}
    </div>
  )
})
