import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import React, { ReactNode } from 'react'
import { colors } from '../../../constants'
import HeadSection from '../common/HeadSection'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface AuthBoxProps {
  headingText: string
  descriptionText?: ReactNode
  onBackButton?: () => void
}

const AuthBoxContainer: React.FC<AuthBoxProps> = props => {
  const deviceType = useDeviceType()

  const commonStyles: SxProps<Theme> = {
    maxWidth: 460,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    color: colors.text_white,
    justifyContent: 'center',
    position: 'relative',
  }

  // Explicitly declare the type of the sx prop
  const sxStyles: SxProps<Theme> =
    deviceType === DeviceType.Mobile
      ? {
          ...commonStyles,
          padding: '0 38px',
          height: '100%',
          backgroundColor: colors.drawer_bg_right,
        }
      : {
          ...commonStyles,
          padding: '64px 80px',
          backgroundColor: colors.box_bg,
        }

  return (
    <Box sx={sxStyles}>
      {props.onBackButton && (
        <>
          <Box
            onClick={props.onBackButton}
            component="button"
            sx={{
              all: 'unset',
              position: 'absolute',
              cursor: 'pointer',
              top: '64px',
              left: '80px',
              width: '28px',
              height: '28px',
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Box>
          <Box sx={{ height: '40px' }} />
        </>
      )}
      <HeadSection
        headerText={props.headingText}
        descriptionText={props.descriptionText}
      />
      <Box>{props.children}</Box>
    </Box>
  )
}

export default AuthBoxContainer
