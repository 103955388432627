import { useState } from 'react'
import { useCheckSymbolsExistQuery } from 'generated/graphql'
import { debounce } from 'lodash'

const useTickerValidation = () => {
  const [symbols, setSymbols] = useState<string[]>([])
  const debouncedSetSymbol = debounce((tickerArray: string[]) => {
    setSymbols(tickerArray)
  }, 300)

  const { data: symbolsFound, refetch } = useCheckSymbolsExistQuery({
    variables: {
      symbols: symbols,
    },
    fetchPolicy: 'cache-first',
  })

  const validateTicker = () => {
    // calculate symbols not foun from symbol input and symbols found from api
    const symbolsNotFound = ([] as string[])
      .concat(typeof symbols === 'string' ? [symbols] : symbols)
      .filter(ticker => !symbolsFound?.checkSymbolsExist?.includes(ticker))

    if (!symbolsNotFound) {
      return true
    }

    return (
      symbolsNotFound?.length === 0 ||
      `We cannot find stock(s) matching the ticker(s): ${symbolsNotFound?.join(
        ', '
      )}`
    )
  }

  const extractTickers = (input: string) => {
    const tickerRegex = /\$\[([^\]]+)\]/g
    let match
    const tickerArray = []

    while ((match = tickerRegex.exec(input)) !== null) {
      if (match[1]) {
        tickerArray.push(match[1])
      }
    }

    debouncedSetSymbol(tickerArray)
  }

  return { validateTicker, extractTickers, symbols }
}

export default useTickerValidation
