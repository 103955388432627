import React from 'react'
import Box from '@mui/material/Box'
import { SubmitTopicBar } from '../SubmitTopicBar/SubmitTopicBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { colors, fonts } from '../../../constants'
import { onVote as onPostVote } from '../../../utils/onVote'
import {
  GetCommentQuery,
  GetPostQuery,
  GetRelatedPostsQuery,
  PostType,
} from 'generated/graphql'
import { onCommentVote } from 'utils/onCommentVote'
import { DEBUG_ENV } from 'index'
import { CommentListWrapper } from 'components/Comment/CommentListWrapper'
import Comment from 'components/Comment/Comment'
import Topic from '../Topic/Topic'
import { CommentLoading } from 'components/Comment/CommentLoading'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { mapComments } from 'utils/mappers/commentsMapper'
import { VoteDirection } from '../VoteUpDown'
import { handleTopicHeaderClick } from 'utils/urlUtils'
import { mapPosts } from 'utils/mappers/postsMapper'

interface ViewTopicProps {
  userId?: string
  post?: PostData
  commentWithParent?: CommentData
  commentid: string | null
  replyNotificationEmail: boolean
  getPost?: GetPostQuery | undefined
  getComment?: GetCommentQuery | undefined
  getRelatedPosts?: GetRelatedPostsQuery
  changeCommentVoteMutationHookResult: any
  voteCommentMutationHookResult: any
  deleteCommentMutationHookResult: any
  changePostVoteMutationHookResult: any
  votePostMutationHookResult: any
  deletePostMutationHookResult: any
  onMoreActionsComment: (
    id: string,
    action: string | number,
    commentId: string,
    state: {
      get: boolean
      set: Function
    }
  ) => void
  onShareActionsClick: (
    id: string,
    action: string | number,
    commentText: string,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onCommentReply: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    state: {
      get: boolean
      set: Function
    },
    commentId?: string | undefined
  ) => any
  onUpdateComment: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    commentId: string,
    state: { get: boolean; set: Function }
  ) => any
  onMoreActionsTopic: (postId: string, action: string | number) => void
  setSignUpModalOpen: Function
}

const ViewTopic: React.FC<ViewTopicProps> = ({
  userId,
  post,
  commentWithParent,
  commentid,
  getPost,
  getComment,
  getRelatedPosts,
  replyNotificationEmail,
  changeCommentVoteMutationHookResult,
  voteCommentMutationHookResult,
  deleteCommentMutationHookResult,
  changePostVoteMutationHookResult,
  votePostMutationHookResult,
  deletePostMutationHookResult,
  onMoreActionsComment,
  onShareActionsClick,
  onCameraActionsClick,
  onCameraActionsCommentClick,
  onCommentReply,
  onUpdateComment,
  onMoreActionsTopic,
  setSignUpModalOpen,
}) => {
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
      }}
    >
      {/*********************************************************************************************
       * SUBMIT A TOPIC BUTTON + NAVIGATION/SORT HEADER
       **********************************************************************************************/}
      <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
        {deviceType === DeviceType.Desktop && (
          <SubmitTopicBar
            onSubmitTopicClick={() => {
              // if user doesn't exist, open modal
              if (!userId) {
                setSignUpModalOpen(true)
                return
              }

              const date = searchParams.get('date')
              const type = location.pathname.split('/')[1]
              const symbol = location.pathname.split('/')[2]

              date
                ? navigate(`/${type}/${symbol}/create?date=${date}`)
                : navigate(`/${type}/${symbol}/create`)
            }}
          />
        )}
        <Box
          sx={{
            backgroundColor:
              deviceType === DeviceType.Mobile
                ? colors.drawer_bg_right
                : 'inherit',
            height: deviceType === DeviceType.Mobile ? 34 : 40,
            width: '100%',
            textAlign: 'left',
            borderBottom: `1px solid ${colors.border}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            '& svg': {
              color: colors.textGrey,
              margin: '14px 16px 12px',
              cursor: 'pointer',
            },
          }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="sm"
            title={commentid ? 'Back to topic' : 'Back to topics'}
            onClick={() => {
              if (commentid) {
                const param = searchParams.get('commentid')

                if (param) {
                  searchParams.delete('commentid')
                  setSearchParams(searchParams)
                }
              } else {
                const curLocation = location.pathname.split('/')

                const type = curLocation[1]
                const ticker = curLocation[2]

                const date = searchParams.get('date')

                if (date) {
                  navigate(`/${type}/${ticker}/?date=${date}`)
                } else {
                  navigate(`/${type}/${ticker}`)
                }
              }
            }}
          />
          {deviceType === DeviceType.Mobile && (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: colors.white_faded,
                fontSize: 12,
                fontWeight: 400,
                lineHeight: '16px',
              }}
            >
              Comments
            </Box>
          )}
          <Box
            sx={{
              height: '100%',
              width: deviceType === DeviceType.Mobile ? 44 : 61,
              backgroundColor:
                deviceType === DeviceType.Mobile
                  ? colors.drawer_bg_right
                  : colors.drawer_bg_light,
            }}
          />
        </Box>
      </Box>

      {/*********************************************************************************************
       * COMMENT ONLY
       **********************************************************************************************/}
      {post && commentid && (
        <Box sx={{ flex: 1, overflow: 'overlay' }}>
          {getComment?.getComment && commentWithParent && (
            <>
              <Comment
                commentWithParent={commentWithParent}
                userId={userId}
                isCreator={getComment?.getComment?.user?.id === userId}
                replyNotificationEmail={replyNotificationEmail}
                onMoreActionsClick={onMoreActionsComment}
                onCameraActionsClick={onCameraActionsCommentClick}
                onShareActionsClick={onShareActionsClick}
                onTopicBookmarkClick={
                  /* TODO: Add bookmarks feature */ () => {}
                }
                onCommentReplyClick={() => {
                  if (!userId) {
                    setSignUpModalOpen(true)
                  }
                }}
                onReply={onCommentReply}
                onUpdateComment={onUpdateComment}
                onVote={(direction: any, commentId: any) => {
                  if (!userId) {
                    setSignUpModalOpen(true)
                    return
                  }

                  onCommentVote({
                    direction,
                    commentId,
                    changeCommentVoteMutationHookResult,
                    voteCommentMutationHookResult,
                    deleteCommentMutationHookResult,
                    userId: userId,
                    userVotePositive: getComment?.getComment?.userVotePositive,
                  })
                }}
                renderChildComments={() => (
                  <>
                    {getComment?.getComment && (
                      <CommentListWrapper
                        userId={userId}
                        commentWithParent={commentWithParent}
                        replyNotificationEmail={replyNotificationEmail}
                        onMoreActionsClick={onMoreActionsComment}
                        onShareActionsClick={onShareActionsClick}
                        onCameraActionsClick={onCameraActionsCommentClick}
                        onTopicBookmarkClick={
                          /* TODO: Add bookmarks feature */ () => {}
                        }
                        onCommentReplyClick={() => {
                          if (!userId) {
                            setSignUpModalOpen(true)
                          }
                        }}
                        onReply={onCommentReply}
                        onUpdateComment={onUpdateComment}
                        onVote={(
                          direction: any,
                          commentId: any,
                          userVotePositive: any
                        ) => {
                          if (!userId) {
                            setSignUpModalOpen(true)
                            return
                          }

                          onCommentVote({
                            direction,
                            commentId,
                            changeCommentVoteMutationHookResult,
                            voteCommentMutationHookResult,
                            deleteCommentMutationHookResult,
                            userId: userId,
                            userVotePositive,
                          })
                        }}
                      />
                    )}
                  </>
                )}
              />
            </>
          )}
        </Box>
      )}

      {/*********************************************************************************************
       * TOPIC & COMMENTS
       **********************************************************************************************/}
      {!commentid && (
        <Box sx={{ flex: 1, overflow: 'overlay' }}>
          {getPost?.getPost && (
            <>
              <Topic
                userId={userId}
                post={post!}
                replyNotificationEmail={replyNotificationEmail}
                isCreator={getPost.getPost.user?.id === userId}
                onTopicHeaderClick={(args: any) => {
                  handleTopicHeaderClick(
                    location,
                    navigate,
                    post?.assetType!,
                    post!.symbol,
                    undefined,
                    post?.postType === PostType.Url ? post.url! : undefined
                  )
                }}
                onTopicBookmarkClick={(args: any) => {
                  if (DEBUG_ENV) console.debug(args)
                }}
                onShareActionsClick={(id: string, action: string | number) => {
                  onShareActionsClick(id, action, getPost.getPost?.title || '')
                }}
                onCameraActionsClick={(
                  topic: PostData,
                  action: string | number
                ) => {
                  onCameraActionsClick(topic, action)
                }}
                onMoreActionsClick={onMoreActionsTopic}
                onTopicReplyClick={() => {
                  // if user doesn't exist, open modal
                  if (!userId) {
                    setSignUpModalOpen(true)
                    return Promise.resolve()
                  }
                }}
                commentTextChange={() => {
                  if (!userId) {
                    setSignUpModalOpen(true)
                    return Promise.resolve()
                  }
                }}
                onReply={onCommentReply}
                onVote={(direction: any) => {
                  if (!userId) {
                    setSignUpModalOpen(true)
                    return
                  }

                  onPostVote({
                    direction,
                    postId: post?.id!,
                    changePostVoteMutationHookResult,
                    votePostMutationHookResult,
                    deletePostMutationHookResult,
                    userId: userId,
                    userVotePositive: getPost.getPost?.userVotePositive,
                  })
                }}
              />
              {post &&
                (getPost.getPost?.numberOfReplies || 1
                  ? mapComments(getPost.getPost?.comments).map(comment => {
                      return (
                        <Comment
                          key={comment.id}
                          userId={userId}
                          commentWithParent={comment}
                          isCreator={comment?.userId === userId}
                          replyNotificationEmail={replyNotificationEmail}
                          onMoreActionsClick={onMoreActionsComment}
                          onCameraActionsClick={onCameraActionsCommentClick}
                          onShareActionsClick={onShareActionsClick}
                          onTopicBookmarkClick={
                            /* TODO: Add bookmarks feature */ () => {}
                          }
                          onCommentReplyClick={() => {
                            if (!userId) {
                              setSignUpModalOpen(true)
                            }
                          }}
                          onReply={onCommentReply}
                          onUpdateComment={onUpdateComment}
                          onVote={(direction: any, commentId: any) => {
                            if (!userId) {
                              setSignUpModalOpen(true)
                              return
                            }

                            onCommentVote({
                              direction,
                              commentId,
                              changeCommentVoteMutationHookResult,
                              voteCommentMutationHookResult,
                              deleteCommentMutationHookResult,
                              userId: userId,
                              userVotePositive:
                                comment.userVoteDirection === VoteDirection.up,
                            })
                          }}
                          renderChildComments={() => (
                            <CommentListWrapper
                              userId={userId}
                              commentWithParent={comment!}
                              replyNotificationEmail={replyNotificationEmail}
                              onMoreActionsClick={onMoreActionsComment}
                              onShareActionsClick={onShareActionsClick}
                              onCameraActionsClick={onCameraActionsCommentClick}
                              onTopicBookmarkClick={
                                /* TODO: Add bookmarks feature */ () => {}
                              }
                              onCommentReplyClick={() => {
                                if (!userId) {
                                  setSignUpModalOpen(true)
                                }
                              }}
                              onReply={onCommentReply}
                              onUpdateComment={onUpdateComment}
                              onVote={(
                                direction: any,
                                commentId: any,
                                userVotePositive: any
                              ) => {
                                if (!userId) {
                                  setSignUpModalOpen(true)
                                  return
                                }

                                onCommentVote({
                                  direction,
                                  commentId,
                                  changeCommentVoteMutationHookResult,
                                  voteCommentMutationHookResult,
                                  deleteCommentMutationHookResult,
                                  userId: userId,
                                  userVotePositive,
                                })
                              }}
                            />
                          )}
                        />
                      )
                    })
                  : Array(getPost.getPost?.numberOfReplies)
                      .fill(0)
                      .map(() => <CommentLoading />))}
              {!!getRelatedPosts &&
                getRelatedPosts.getRelatedPosts?.length > 0 && (
                  <Box
                    sx={{
                      color: colors.text_white,
                      fontFamily: fonts.dm_sans,
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: '16px',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors.topic_posts_before_background,
                        padding: '9px 16px',
                        flex: 1,
                      }}
                    >
                      Related posts
                    </Box>
                    <Box
                      sx={{
                        backgroundColor:
                          colors.topic_posts_before_background_right,
                        width: '61px',
                      }}
                    ></Box>
                  </Box>
                )}
              {!!getRelatedPosts &&
                mapPosts(getRelatedPosts.getRelatedPosts).map((post, index) => (
                  <Box
                    sx={{
                      borderBottom: `1px solid ${colors.border}`,
                    }}
                    key={post.id}
                  >
                    <Topic
                      userId={userId}
                      post={post}
                      replyNotificationEmail={replyNotificationEmail}
                      isCreator={getPost.getPost.user?.id === userId}
                      disableRespondCommentExpansion={true}
                      onTopicHeaderClick={(args: any) => {
                        let url = `/${post.assetType}/${post.symbol}/topic/${post.id}`
                        navigate(url)
                      }}
                      onTopicBookmarkClick={(args: any) => {
                        if (DEBUG_ENV) console.debug(args)
                      }}
                      onShareActionsClick={(
                        id: string,
                        action: string | number
                      ) => {
                        onShareActionsClick(
                          id,
                          action,
                          getPost.getPost?.title || ''
                        )
                      }}
                      onCameraActionsClick={(
                        topic: PostData,
                        action: string | number
                      ) => {
                        onCameraActionsClick(topic, action)
                      }}
                      onMoreActionsClick={onMoreActionsTopic}
                      onCommentClick={() => {
                        let url = `/${post.assetType}/${post.symbol}/topic/${post.id}`
                        navigate(url)
                      }}
                      commentTextChange={() => {
                        if (!userId) {
                          setSignUpModalOpen(true)
                          return Promise.resolve()
                        }
                      }}
                      onReply={onCommentReply}
                      onVote={(direction: any) => {
                        if (!userId) {
                          setSignUpModalOpen(true)
                          return
                        }

                        onPostVote({
                          direction,
                          postId: post.id,
                          changePostVoteMutationHookResult,
                          votePostMutationHookResult,
                          deletePostMutationHookResult,
                          userId: userId,
                          userVotePositive: getPost.getPost?.userVotePositive,
                        })
                      }}
                    />
                  </Box>
                ))}
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ViewTopic
