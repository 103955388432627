import * as React from 'react'

function SpeechImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 0.5C18.8672 0.5 20 1.63281 20 3L20 14.25C20 15.6563 18.8672 16.75 17.5 16.75H11.875L6.99219 20.4219C6.875 20.5 6.79687 20.5 6.71875 20.5C6.44531 20.5 6.25 20.3438 6.25 20.0312L6.25 16.75H2.5C1.09375 16.75 0 15.6562 0 14.25L0 3C0 1.63281 1.09375 0.5 2.5 0.5L17.5 0.5ZM18.125 14.25L18.125 3C18.125 2.6875 17.8125 2.375 17.5 2.375L2.5 2.375C2.14844 2.375 1.875 2.6875 1.875 3L1.875 14.25C1.875 14.6016 2.14844 14.875 2.5 14.875L8.125 14.875V17.2188L10.7422 15.2656L11.25 14.875H17.5C17.8125 14.875 18.125 14.6016 18.125 14.25Z"
        fill="#A0A4B2"
      />
    </svg>
  )
}

export default SpeechImage
