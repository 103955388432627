import React from 'react'
import useStyles from './styles'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import VoteUpDown, { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { convertVoteDirection } from 'utils/voteUtils'
import { CommentData } from '../Comment.interfaces'
import UserAvatar from 'components/UserAvatar'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import MarkdownViewer from 'components/inputs/MarkdownViewer'
import { CommentIcons } from 'components/CommentIcons/CommentIcons'

interface ChildCommentProps {
  userId?: string
  comment: CommentData
  onVote: (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
}

const ChildComment: React.FC<ChildCommentProps> = ({
  userId,
  comment,
  onVote,
  onShareActionsClick,
  onCameraActionsCommentClick,
  onMoreCommentActionsClick,
  onCommentClick,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.commentWrapper}>
      {!comment.deleted && (
        <>
          <div>
            <div className={classes.lineWrapper}>
              <div
                className={classes.usernameWrapper}
                onClick={() => {
                  navigate(
                    ROUTES.USER.replace(
                      ':username',
                      comment.username!
                    ).toLowerCase()
                  )
                }}
              >
                <div className={classes.avatarWrapper}>
                  <UserAvatar emailHash={comment.emailHash} />
                </div>
                <div className={classes.username}>{comment.username}</div>
              </div>
              <div className={classes.date}>
                {getTimeElapsedRounded(comment.date, comment.createdAt)}
              </div>
            </div>
            <div className={classes.commentText}>
              <MarkdownViewer
                markdownSource={comment.content}
                isPreview={false}
              ></MarkdownViewer>
            </div>
            <div className={classes.postIconsWrapper}>
              <CommentIcons
                comment={comment}
                numberOfReplies={comment.numberOfReplies}
                onMoreActionsClick={(id: string, action: string | number) =>
                  onMoreCommentActionsClick(
                    comment.parentPost!.id,
                    id,
                    comment.parentPost!.symbol,
                    comment.parentPost!.assetType,
                    action
                  )
                }
                onShareActionsClick={(id: string, action: string | number) =>
                  onShareActionsClick(
                    id,
                    comment.content,
                    comment.parentPost!.symbol,
                    action,
                    comment.id
                  )
                }
                onCameraActionsClick={onCameraActionsCommentClick}
                isCreator={userId === comment.userId}
                onCommentClick={onCommentClick}
              />
            </div>
          </div>
          <div className={classes.voteUpDownWrapper}>
            <VoteUpDown
              numVotes={comment.voteNum || 0}
              onVoteDownClick={() =>
                onVote(
                  VoteDirection.down,
                  comment.id,
                  comment.userVoteDirection !== null
                    ? convertVoteDirection(comment.userVoteDirection!)
                    : undefined
                )
              }
              onVoteUpClick={() =>
                onVote(
                  VoteDirection.up,
                  comment.id,
                  comment.userVoteDirection !== null
                    ? convertVoteDirection(comment.userVoteDirection!)
                    : undefined
                )
              }
              voteDirection={comment.userVoteDirection}
            />
          </div>
        </>
      )}
      {(comment.deleted || comment.content === '') && (
        <div className={classes.deletedComment}>Comment deleted</div>
      )}
    </div>
  )
}

export default ChildComment
