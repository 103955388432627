import { Box, Button } from '@mui/material'
import { SocialProvider } from './SocialSignupOption'

interface Props {
  text: string
  iconUrl: string
  socialProvider: SocialProvider
  onHandleClick?: () => void
}

export default function SocialButton({
  text,
  iconUrl,
  socialProvider,
  onHandleClick,
}: Props) {
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '9999px',
        width: '100%',
        backgroundColor: '#14141f',
        color: 'white',
        position: 'relative',
        padding: '10px',
        marginTop: '18px',
        marginBottom: '12px',
        '&:hover': {
          backgroundColor: 'black',
        },
        textTransform: 'none',
      }}
      onClick={onHandleClick}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '10px',
          display: 'flex',
          justifyContent: 'center',
          img: { height: '24px' },
        }}
      >
        <img src={iconUrl} alt="Social media login button icon" />
      </Box>
      {text}
    </Button>
  )
}
