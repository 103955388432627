import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'

export enum VoteDirection {
  up,
  down,
}

interface Props {
  numVotes: number
  largeText?: boolean
  voteDirection: VoteDirection | null | undefined
  onVoteUpClick: () => void
  onVoteDownClick: () => void
}

export default function VoteUpDown(props: Props) {
  const classes = useStyles(theme)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(false)
  }, [props.numVotes])

  const handleVote = (direction: 'up' | 'down') => {
    if (!isDisabled) {
      setIsDisabled(true)
      if (direction === 'up') {
        props.onVoteUpClick()
      } else {
        props.onVoteDownClick()
      }
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.arrowCounter}>
        <FontAwesomeIcon
          icon={faArrowUp}
          className={classNames(
            classes.arrow,
            props.voteDirection === VoteDirection.up ? classes.votedUp : null
          )}
          onClick={() => handleVote('up')}
        />
        <div
          className={classNames(
            classes.numVotes,
            props.largeText ? classes.numVotesLarge : null
          )}
        >
          {props.numVotes}
        </div>
        <FontAwesomeIcon
          icon={faArrowDown}
          className={classNames(
            classes.arrow,
            props.voteDirection === VoteDirection.down
              ? classes.votedDown
              : null
          )}
          onClick={() => handleVote('down')}
        />
      </div>
    </div>
  )
}
