import Auth from '@aws-amplify/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { setApolloError, setSuccess } from '../apollo/reactive-vars'
import CreateNewPasswordBox from '../components/auth/CreateNewPasswordBox'
import CenteredBoxScreenWrapper from '../components/auth/common/CenteredBoxScreenWrapper'
import ROUTES from '../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../constants'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

const CreateNewPassword: React.FC = () => {
  const deviceType = useDeviceType()
  const { email } = useParams()
  const navigate = useNavigate()

  function onClose() {
    navigate(ROUTES.SENTIMENT_DISCOVERY)
  }

  return (
    <>
      {deviceType === DeviceType.Mobile && (
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          style={{
            position: 'absolute',
            top: 24,
            right: 24,
            cursor: 'pointer',
          }}
          color={colors.textGrey}
          onClick={onClose}
        />
      )}
      <CenteredBoxScreenWrapper>
        <CreateNewPasswordBox
          onSubmit={data => {
            const { code, password } = data
            Auth.forgotPasswordSubmit(email!, code, password)
              .then(() => {
                navigate(ROUTES.RESSET_PASSWORD_SUCCESS)
              })
              .catch(error => {
                setApolloError(error.message || 'Something went wrong.')
              })
          }}
          onResendVerificationCode={() => {
            Auth.forgotPassword(email!)
              .then(() => setSuccess('The code has been resent'))
              .catch(error => {
                setApolloError(error.message || 'Something went wrong.')
              })
          }}
        />
      </CenteredBoxScreenWrapper>
    </>
  )
}

export default CreateNewPassword
