import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import useStyles from './styles'

interface SimpleDatePickerProps {
  open: boolean
  selectedDate?: Date
  onChange: (date: Date | null, keyboardInputValue?: string) => void
  onClose?: () => void
}

const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({
  open,
  selectedDate,
  onChange,
  onClose,
}) => {
  const classes = useStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        open={open}
        value={selectedDate}
        onChange={onChange}
        onClose={onClose}
        showToolbar={false}
        DialogProps={{
          classes: { paper: classes.hideDialogActions },
        }}
        renderInput={({ inputRef, inputProps }) => (
          <input ref={inputRef} {...inputProps} style={{ display: 'none' }} />
        )}
      />
    </LocalizationProvider>
  )
}

export default SimpleDatePicker
