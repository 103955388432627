import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export const useStyles = makeStyles(
  {
    symbolIcon: {
      width: 24,
      height: 24,
      objectFit: 'cover',
      borderRadius: '50%',
      backgroundColor: colors.black,
    },

    fallbackCircle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: colors.topic_posts_before_background,
      color: colors.textGreyLight,
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'uppercase',
    },

    symbolContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { index: 1 }
)
