import { isSameDay } from 'date-fns'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { Resizable } from 're-resizable'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useKeyCombination } from 'utils/useKeyCombination'
import CandlestickChart from '../../components/CandlestickChart'
import TabBar from '../../components/TabBar'
import { ExDate, dateToExDate, regex } from '../../constants'
import {
  CurrentUserDataFragmentDoc,
  PostType,
  PrivateUser,
  useAssetDataQuery,
  useGetPostQuery,
  useGetStoryPostsQuery,
  useGetSymbolDateQuery,
  useGetSymbolQuery,
  useUpdateLastViewedSymbolMutation,
} from '../../generated/graphql'
import theme from '../../mui-theme'
import CreateTopicPanelIntegration from './CreateTopicPanelIntegration'
import { PostEditIntegration } from './PostEditIntegration'
import { PostReportIntegration } from './PostReportIntegration'
import { TopicSidebarIntegration } from './TopicSidebarIntegration/TopicSidebarIntegration'
import ViewTopicIntegration from './ViewTopicIntegration'
import useStyles from './styles'
import SimpleDatePicker from 'components/inputs/SimpleDatePicker'
import { CandleStickData } from 'components/CandlestickChart/interfaces'
import { DEBUG_ENV } from 'index'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { IStoryPost } from 'types/story-posts'
import { mapPost } from 'utils/mappers/postsMapper'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

interface Props {
  userId?: string
  nickname?: string | null
  replyNotificationEmail: boolean
  isStoryView?: boolean
  isStoryEdit?: boolean
  filteredTopicIds?: string[]
  filteredPostTypes?: PostType[]
  selectedStoryPosts?: IStoryPost[]
  setSelectedStoryPosts?: (selectedStoryPosts: IStoryPost[]) => void
  setShowSignUpModal: (isOpen: boolean) => void
  submitStory?: () => Promise<void>
}

const Main: React.FC<Props> = props => {
  const deviceType = useDeviceType()
  const { type, symbol, storyId } = useParams<'type' | 'symbol' | 'storyId'>()
  const [dateToHighlight, setDateToHighlight] = useState<ExDate | undefined>(
    undefined
  )
  const [markedDays, setMarkedDays] = useState<ExDate[]>([])

  const codeLower = symbol?.toLowerCase()
  const codeUpper = symbol?.toUpperCase()
  const typeLower = type?.toLowerCase()
  const navigate = useNavigate()

  // The following code will get the asset type and redirect to the correct URL
  // if 'asset' is passed in the URL. This is done as there is a scenario where we
  // didn't know the asset type when generating the URL to navigate to this page.
  const urlContainsAsset = type?.toLowerCase() === 'asset'

  const { data: symbolData } = useGetSymbolQuery({
    variables: {
      data: {
        symbol: codeUpper ? codeUpper : '',
      },
    },
    fetchPolicy: 'cache-first',
    skip: !urlContainsAsset || !symbol,
  })

  const { data: storyPostsData } = useGetStoryPostsQuery({
    variables: { parentStoryId: storyId as string },
    skip: !(props.isStoryEdit || props.isStoryView) || !storyId,
  })

  const { data: storyPostData } = useGetPostQuery({
    variables: { id: storyId as string },
    skip: !props.isStoryEdit || !storyId,
  })

  const classes = useStyles(theme)
  const dateStr = useQuery().get('date')
  const [updateLastViewedSymbol] = useUpdateLastViewedSymbolMutation()
  const [showDatePicker, setShowDatePicker] = React.useState<boolean>(false)
  const [storyPost, setStoryPost] = useState<PostData | null>(null)

  const [selectedCandlestick, setSelectedCandlestick] = React.useState<
    CandleStickData | undefined
  >()

  const [selectedId, setSelectedId] = React.useState('today')
  const [tabItems, setTabItems] = React.useState([
    {
      id: 'today',
      label: 'Today',
    },
  ])

  const { data: symbolDateData, loading: symbolDateLoading } =
    useGetSymbolDateQuery({
      fetchPolicy: 'cache-first',
      variables: {
        symbol: (symbol as string).toUpperCase(),
      },
    })

  const symbolDate = !!symbolDateData?.getSymbolDate?.date
    ? (symbolDateData.getSymbolDate.date as String)
    : undefined

  if (DEBUG_ENV) {
    if (!symbolDate)
      console.warn(
        `--> Main -- Symbol: ${codeUpper} has no symbolDate response`
      )
  }

  const today = new ExDate(symbolDate)

  const currentYear = today.datetime.year()
  let chosenYearNumber = currentYear
  let chosenYearString = currentYear.toString()
  let checkMonth = today.datetime.month() + 1

  if (dateStr) {
    const dateStrExDate = new ExDate(dateStr)
    chosenYearNumber = dateStrExDate.datetime.year()
    chosenYearString = chosenYearNumber.toString()
    checkMonth = dateStrExDate.datetime.month() + 1
  }

  if (checkMonth < 6) {
    const previousYear = chosenYearNumber - 1
    chosenYearString = `${previousYear}/${chosenYearNumber}`
  }

  const handlePaste = (event: any) => {
    navigator.clipboard?.readText().then((pastedData: string) => {
      if (window.location.href.indexOf('/create') === -1) {
        const currentUrl = window.location.href
        // Check if the current URL contains '/create' or '/edit'
        if (currentUrl.includes('/create') || currentUrl.includes('/edit')) {
          // Ignore the paste event
          return
        }

        if (regex && regex?.url?.test(pastedData)) {
          navigate(
            `/${typeLower}/${codeLower}/create?url=${encodeURIComponent(
              pastedData
            )}`,
            {
              replace: true,
            }
          )
        }
      }
    })
  }

  useKeyCombination(['v'], handlePaste) // Meta on OSX | Ctrl on Win/*nix + ['char'] combo

  const useAssetDataQueryResult = useAssetDataQuery({
    variables: {
      assetType: AssetTypeEnum.stock,
      code: codeUpper as string,
      date: chosenYearString,
    },
  })

  useEffect(() => {
    if (deviceType === DeviceType.Mobile && !dateStr) {
      setTabItems(currentItems => {
        const alreadyHasSelectDate = currentItems.some(
          item => item.id === 'select-date'
        )
        const lessThanTwoItems = currentItems.length < 2

        if (!alreadyHasSelectDate && lessThanTwoItems) {
          return [
            ...currentItems,
            {
              id: 'select-date',
              label: 'Select a Date',
            },
          ]
        }
        return currentItems
      })
    }
  }, [deviceType, tabItems.length])

  // on page load call mutation to update last viewed stock
  useEffect(() => {
    if (props.userId && codeUpper) {
      updateLastViewedSymbol({
        variables: {
          data: {
            lastViewedSymbol: codeUpper,
          },
        },
        update: (cache, { data }) => {
          if (!data?.updateLastViewedSymbol) {
            return
          }

          cache.updateFragment<PrivateUser>(
            {
              id: `PrivateUser:${props.userId}`,
              fragment: CurrentUserDataFragmentDoc,
            },
            currentUser => {
              return currentUser
                ? {
                    ...currentUser,
                    lastViewedSymbol: codeUpper as string,
                  }
                : null
            }
          )
        },
      })
        .then(() => {})
        .catch(err => {
          console.error(`---> Main - ${err}`)
        })
    }
  }, [codeUpper, props.userId, updateLastViewedSymbol])

  const assetData = useAssetDataQueryResult.data?.assetData

  const oldestYear =
    assetData?.length && assetData[0]
      ? new ExDate(assetData[0]?.date).datetime.year()
      : currentYear

  const latestYear =
    assetData?.length && assetData[assetData.length - 1]
      ? new ExDate(assetData[assetData.length - 1]!.date).datetime.year()
      : currentYear

  const chartData = useMemo(() => {
    const t = useAssetDataQueryResult.data?.assetData?.length
      ? useAssetDataQueryResult.data.assetData.map(item => {
          const priceBarDate =
            item!.data.assetType === 'STOCK'
              ? new ExDate(item!.date)
              : new ExDate(item!.date, { tz: 'UTC' })

          return {
            ...item!.data,
            date: priceBarDate,
          }
        })
      : undefined

    return t
  }, [useAssetDataQueryResult.data?.assetData])

  const tabClick = (id: string) => {
    setSelectedId(id)

    if (id === 'today') {
      tabItems.splice(1)

      if (chartData) {
        // Currently don't have a candlestick for today so set to undefined
        // and push todays date onto history
        setSelectedCandlestick(undefined)

        navigate(
          {
            search: '',
          },
          { replace: true }
        )
      }
    }

    if (id === 'select-date') {
      setShowDatePicker(true)
    }
  }

  const chartDoubleClick = (candlestickData: CandleStickData) => {
    const date = dateToExDate(candlestickData.date)

    // Fix date selected on chart
    const cData = {
      ...candlestickData,
      date: date,
    }

    tabItems.splice(1)
    tabItems.push({
      id: date.toApiString(),
      label: date.toApiString(),
    })

    setSelectedId(date.toApiString())
    setSelectedCandlestick(cData)

    navigate({
      search: `?date=${date.toApiString()}`,
    })
  }

  const setDatePickerClick = (date: Date | null) => {
    if (date === null) {
      return
    }

    const dateStr = dateToExDate(date)

    tabItems.splice(1)
    tabItems.push({
      id: dateStr.toApiString(),
      label: dateStr.toApiString(),
    })

    setSelectedId(dateStr.toApiString())
    setShowDatePicker(false)

    navigate({
      search: `?date=${dateStr.toApiString()}`,
    })
  }

  useEffect(() => {
    if (chartData && dateStr) {
      const parsedDate = new ExDate(dateStr)

      // Find the candlestick that matches the parsed date
      const matchingCandlestick = chartData?.find(
        (candlestick: { date: ExDate }) =>
          parsedDate.date === candlestick.date.date
      )

      // Set the found candlestick as the selectedCandlestick
      if (matchingCandlestick) {
        setSelectedCandlestick(matchingCandlestick)
      }
    }
  }, [chartData, dateStr])

  useEffect(() => {
    if (dateStr) {
      const newTabItem = {
        id: dateStr,
        label: dateStr,
      }

      setTabItems(currentItems => [...currentItems, newTabItem])
      setSelectedId(dateStr)
    }
  }, [])

  useEffect(() => {
    if (urlContainsAsset && symbolData?.getSymbol?.type && symbol) {
      const actualType = symbolData.getSymbol.type

      // Replace 'ASSET' (case-insensitive) in the current URL with actualType
      const currentURL = window.location.href
      const newURL = currentURL.replace(/asset/i, actualType)

      window.location.href = newURL
    }
  }, [type, symbolData])

  useEffect(() => {
    if (storyPostsData && props.setSelectedStoryPosts) {
      const storyPosts: IStoryPost[] = storyPostsData.getStoryPosts.map(
        storyPost => {
          return {
            childPostId: storyPost.childPostId,
            noteText: storyPost.noteText,
          }
        }
      )

      props.setSelectedStoryPosts(storyPosts)
    }
  }, [
    storyPostsData,
    props.isStoryEdit,
    props.isStoryView,
    props.setSelectedStoryPosts,
  ])

  useEffect(() => {
    if (!!storyPostData) {
      setStoryPost(mapPost(storyPostData?.getPost))
    }
  }, [storyPostData])

  return (
    <>
      {/* SHOW CHART & TOPIC SIDE-BAR ONLY IF USER LOGGED-IN */}
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div
            style={{
              height:
                deviceType === DeviceType.Mobile
                  ? '230px'
                  : props.isStoryView || props.isStoryEdit
                  ? 'calc(100vh - 173px)'
                  : 'calc(100vh - 125px)',
              width: '100%',
              position: 'absolute',
              left: 0,
              bottom: 0,
              top: 0,
              right: 0,
            }}
          >
            <TabBar
              selectedId={selectedId}
              tabItems={tabItems}
              tabClick={tabClick}
            />

            {chartData && (
              <CandlestickChart
                chartData={chartData}
                selectedCandlestick={selectedCandlestick}
                onChartClick={chartDoubleClick}
                onLoadBefore={() => {
                  if (oldestYear <= 1995 || useAssetDataQueryResult.loading) {
                    return Promise.resolve()
                  }
                  return useAssetDataQueryResult.fetchMore({
                    variables: {
                      assetType: AssetTypeEnum.stock,
                      code: codeUpper,
                      date: (oldestYear - 1).toString(),
                    },
                  })
                }}
                onLoadAfter={() => {
                  // Check if the year to load is the current year or later, or if a loading process is already ongoing
                  if (
                    latestYear >= currentYear ||
                    useAssetDataQueryResult.loading
                  ) {
                    return Promise.resolve()
                  }

                  // Attempt to fetch more data for the next year
                  return useAssetDataQueryResult
                    .fetchMore({
                      variables: {
                        assetType: AssetTypeEnum.stock,
                        code: codeUpper,
                        date: (latestYear + 1).toString(),
                      },
                    })
                    .then(response => {
                      // Check if the response contains data
                      if (
                        !response.data ||
                        response.data.assetData.length === 0
                      ) {
                        // Handle the case where no data is returned (e.g., display a message or perform another action)
                        console.log(
                          `No data available for the year ${latestYear + 1}`
                        )
                        return
                      }
                      return response // Return the response if data is available
                    })
                    .catch(error => {
                      return Promise.resolve() // ignore error it handles scenario when we've just entered a year but no data exists yet and therefore returns 500
                    })
                }}
                highlightedDay={dateToHighlight}
                markedDays={markedDays}
              />
            )}
          </div>
        </div>
        <Resizable
          enable={{ left: true }}
          minHeight={
            deviceType === DeviceType.Mobile ? 'calc(100% - 230px)' : '100%'
          }
          maxHeight={
            deviceType === DeviceType.Mobile ? 'calc(100% - 230px)' : '100%'
          }
          minWidth={deviceType === DeviceType.Mobile ? '100%' : 275}
          maxWidth="60%"
          onResize={() => {
            window.dispatchEvent(new Event('resize'))
          }}
          defaultSize={{
            width: 403,
            height:
              deviceType === DeviceType.Mobile ? 'calc(100% - 230px)' : '100%',
          }}
        >
          <div className={classes.posts}>
            <Routes>
              {deviceType === DeviceType.Desktop && (
                <Route
                  path={`/create`}
                  element={
                    <CreateTopicPanelIntegration nickname={props.nickname} />
                  }
                />
              )}
              {deviceType === DeviceType.Desktop && (
                <Route
                  path={`/edit/:editId`}
                  element={<PostEditIntegration />}
                />
              )}
              <Route
                path={`/report/:reportId`}
                element={<PostReportIntegration />}
              />
              <Route
                path={`/topic/:topicId`}
                element={
                  <ViewTopicIntegration
                    replyNotificationEmail={props.replyNotificationEmail}
                    userId={props.userId}
                    type={typeLower}
                    ticker={codeLower}
                    nickname={props.nickname}
                    setSignUpModalOpen={(isOpen: boolean) =>
                      props.setShowSignUpModal(isOpen)
                    }
                  />
                }
              />
              <Route
                path={`*`}
                element={
                  <TopicSidebarIntegration
                    userId={props.userId}
                    isStoryEdit={props.isStoryEdit}
                    isStoryView={props.isStoryView}
                    onTopicHover={topic => {
                      if (!topic) {
                        setDateToHighlight(undefined)
                        return
                      }
                      setDateToHighlight(topic?.date!)
                    }}
                    storyPost={storyPost}
                    selectedStoryPosts={props.selectedStoryPosts}
                    setSelectedStoryPosts={props.setSelectedStoryPosts}
                    filteredTopicIds={
                      props.isStoryView &&
                      (props.selectedStoryPosts?.length ?? 0) > 0
                        ? props.selectedStoryPosts?.map(
                            storyPost => storyPost.childPostId
                          )
                        : undefined
                    }
                    filteredPostTypes={props.filteredPostTypes}
                    setSignUpModalOpen={(isOpen: boolean) =>
                      props.setShowSignUpModal(isOpen)
                    }
                    onTopVisibleTopicDateChange={(date: ExDate) => {
                      setDateToHighlight(date)
                    }}
                    onFilteredDatesLoaded={
                      props.isStoryView
                        ? (dates: ExDate[]) => {
                            if (
                              (props.selectedStoryPosts?.length ?? 0) > 0 &&
                              dates.length > 0 &&
                              markedDays.length === 0
                            ) {
                              setMarkedDays(dates)
                            }
                          }
                        : () => {
                            if (markedDays.length > 0) {
                              setMarkedDays([])
                            }
                          }
                    }
                    submitStory={props.submitStory}
                  />
                }
              />
            </Routes>
          </div>
        </Resizable>
      </div>
      {showDatePicker && (
        <SimpleDatePicker
          open={showDatePicker}
          onClose={() => setShowDatePicker(false)}
          onChange={setDatePickerClick}
        />
      )}
    </>
  )
}

export default React.memo(Main)
