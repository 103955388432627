import { makeStyles } from '@mui/styles'
import theme from '../../../mui-theme'
import { colors, fonts } from '../../../constants'

export default makeStyles({
  wrapper: {
    display: 'flex',
    width: 392,
    height: 417,
    padding: '32px 28px',
    backgroundColor: colors.drawer_bg_right,
    boxSizing: 'border-box',
    marginLeft: 64,
    flexDirection: 'row',
    borderRadius: 8,

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      width: '100%',
      height: 170,
      padding: '24px 16px',
      marginLeft: 0,
      backgroundColor: colors.search_bg,
    },
  },

  wrapperMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },

  wrapperRightMobile: {
    alignSelf: 'start',
  },

  wrapperRightMobileInner: {
    display: 'flex',
    flexDirection: 'row',
  },

  avatarWrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 100,

    [theme.breakpoints.down('lg')]: {
      width: 60,
      height: 60,
    },
  },

  avatar: {
    width: 100,
    height: 100,

    [theme.breakpoints.down('lg')]: {
      width: 60,
      height: 60,
    },
  },

  ellipsisIcon: {
    background: colors.box_bg,
    borderRadius: '50%',
    padding: 4,
    alignSelf: 'flex-start',
    boxSizing: 'content-box',
    width: 17.5,
    height: 20,

    [theme.breakpoints.down('lg')]: {
      alignSelf: 'center',
    },
  },

  toolTipWrapper: {
    display: 'flex',
  },

  changeImageTooltip: {
    background: 'none',
    color: colors.white,
    fontSize: 12,
    padding: '8px 16px',
    borderRadius: 8,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  changeImageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },

  changeImageText: {
    fontFamily: fonts.dm_sans,
    fontSize: 10,
    fontWeight: 500,
    color: colors.white,
    lineHeight: '10px',
    borderRadius: 4,
    padding: '5px 9px',
    background: colors.greyDarker,
    letterSpacing: 0.4,

    [theme.breakpoints.down('lg')]: {
      padding: '5px 9px',
      width: 70,
    },
  },

  cameraBackground: {
    borderRadius: '50%',
    marginBottom: 4,

    [theme.breakpoints.down('lg')]: {
      height: 20,
      width: 20,
      marginBottom: 0,
    },
  },

  username: {
    fontFamily: fonts.dm_sans,
    fontSize: 18,
    fontWeight: 500,
    color: colors.white,
    lineHeight: '22px',
    marginTop: 16,
  },

  joinSince: {
    fontFamily: fonts.dm_sans,
    fontSize: 14,
    fontWeight: 400,
    color: colors.textGreyLight,
    lineHeight: '20px',
    marginTop: 8,

    [theme.breakpoints.down('lg')]: {
      marginTop: 4,
    },
  },

  followBtn: {
    width: 134,
    height: 38,
    padding: '13px 32px',
    marginTop: 32,
    borderRadius: 100,
    color: colors.white,
    background: colors.blue,

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      borderRadius: 6,
      width: 128,
      marginLeft: 16,
    },
  },

  followingBtn: {
    width: 134,
    height: 38,
    padding: '13px 32px',
    marginTop: 32,
    borderRadius: 100,
    color: colors.white_faded,
    background: colors.box_bg,

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      borderRadius: 6,
      width: 128,
      marginLeft: 24,
    },
  },

  messageBtn: {
    width: 134,
    height: 38,
    padding: '13px 32px',
    marginTop: 16,
    borderRadius: 100,
    color: colors.white,
    border: `1px solid ${colors.white}`,
  },

  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },

  socialIcon: {
    width: 24,
    height: 24,
    marginRight: 32,
    color: colors.textGreyLight,
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },
})
