import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(
  {
    dialog: {
      position: 'fixed',
      fontSize: 12,
      fontFamily: fonts.dm_sans,
      fontWeight: '500',
      color: colors.white,
      backgroundColor: colors.blue_light,
      textAlign: 'center',
      borderRadius: 4,
      padding: '10px 6px',
      whiteSpace: 'nowrap',
      zIndex: 999
    },

    overlayBackground: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  { index: 1 }
)
