import { NewsTabItem } from '../interfaces/newsTabItem'
import { SymbolTabItem } from '../interfaces/symbolTabItem'

export enum TabIdentifiers {
  TRENDING = 'trending',
  RESULTS = 'results',
  WATCHLIST = 'watchlist',
  MOST_WATCHED = 'mostWatched',
  FEED = 'feed',
  TRENDING_NEWS = 'trendingNews',
  LATEST_NEWS = 'latestNews',
}

export const NEWS_TAB_ITEMS: NewsTabItem[] = [
  {
    id: 'Feed',
    index: 0,
    label: 'Feed',
    feedItems: [],
  },
  {
    id: 'Trending News',
    index: 1,
    label: 'Trending News',
    newsPosts: [],
  },
  {
    id: 'Latest News',
    index: 2,
    label: 'Latest News',
    newsPosts: [],
  },
]

export const TICKER_TAB_ITEMS: SymbolTabItem[] = [
  {
    id: 'Trending',
    index: 0,
    label: 'Trending',
    options: [],
  },
  {
    id: 'Results',
    index: 1,
    label: 'Search Results',
    options: [],
  },
  {
    id: 'WatchList',
    index: 2,
    label: 'Watch List',
    options: [],
  },
  {
    id: 'MostWatched',
    index: 3,
    label: 'Most Watched',
    options: [],
  },
]
