import { colors } from '../../constants'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useDeviceType } from 'utils/screenSizeUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import ArrowRedCircleIcon from 'components/Icons/ArrowRedCircleIcon'
import ArrowBlueCircleIcon from 'components/Icons/ArrowBlueCircleIcon'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { useEffect, useState } from 'react'
import ClockIcon from 'components/Icons/ClockIcon'
import { Button } from '@mui/material'

interface Props {
  symbol: string
  symbolName: string
  symbolType: string
  startClose: number
  endOfVoting: Date
  isLoggedIn: boolean
  isVotingOpen: boolean
  submitVote: (higher: boolean) => void
  addToWatchList: () => void
  setShowSignUpModal: (show: boolean) => void
}

export default function PricePredictionBox(props: Props) {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const [timeRemaining, setTimeRemaining] = useState('')
  const [vote, setVote] = useState<boolean | null>(null)
  const [searchParams] = useSearchParams()
  const referrer = searchParams.get('referrer')

  const onClose = () => {
    if (referrer === 'signin') {
      navigate(ROUTES.HOME)
    } else {
      navigate(-1)
    }
  }

  const goToSymbol = () => {
    navigate(
      ROUTES.ASSET.replace(':symbol', props.symbol.toLowerCase())
        .replace(':type', props.symbolType.toLowerCase())
        .toLowerCase()
    )
  }

  const submitVote = async (higher: boolean) => {
    if (!props.isLoggedIn) {
      props.setShowSignUpModal(true)
      return
    }

    props.submitVote(higher)
    setVote(higher)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const end = new Date(props.endOfVoting)
      const diff = end.getTime() - now.getTime()

      if (diff <= 0 || !props.isVotingOpen) {
        clearInterval(timer)
        setTimeRemaining('Voting has ended.')
        return
      }

      const hours = Math.floor(diff / (1000 * 60 * 60))
      const minutes = Math.floor((diff / (1000 * 60)) % 60)
      setTimeRemaining(`${hours} hours ${minutes} minutes remaining`)
    }, 1000)

    return () => clearInterval(timer)
  }, [props.endOfVoting])

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.header}>
          <div style={{ width: 20 }}>&nbsp;</div>
          <div className={classes.headerText}>Price Prediction</div>
          <FontAwesomeIcon
            icon={faClose}
            className={classes.iconClose}
            color={colors.textGrey}
            onClick={onClose}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.symbol}>{props.symbol}</div>
          <div className={classes.symbolName}>{props.symbolName}</div>
          <div className={classes.today}>Today</div>
          <div className={classes.startClose}>{props.startClose} USD</div>
          {vote === null && (
            <img
              className={classes.thinkingEmoji}
              src={'./media/ThinkingEmoji.png'}
              alt="Thinking Emoji"
            />
          )}
          {vote !== null && (
            <img
              className={classes.thinkingEmoji}
              src={'./media/CelebratingHands.png'}
              alt="Thinking Emoji"
            />
          )}
          {vote === null && (
            <div className={classes.question}>
              Will the price go up or down tomorrow?
            </div>
          )}
          {vote === null && (
            <div className={classes.backToTopics} onClick={goToSymbol}>
              Research {props.symbolType}
            </div>
          )}
          {vote !== null && (
            <div className={classes.question}>
              You predicted price goes {vote ? 'up' : 'down'}!
            </div>
          )}
          {props.isVotingOpen && (
            <div
              className={classes.increaseDecreaseButtonsWrapper}
              style={{
                justifyContent: vote !== null ? 'center' : 'space-between',
              }}
            >
              {vote !== false && (
                <div
                  className={classes.voteButton}
                  onClick={() => submitVote(true)}
                >
                  <ArrowBlueCircleIcon />
                  <div className={classes.upDownText}>Up</div>
                </div>
              )}
              {vote !== true && (
                <div
                  className={classes.voteButton}
                  onClick={() => submitVote(false)}
                >
                  <ArrowRedCircleIcon />
                  <div className={classes.upDownText}>Down</div>
                </div>
              )}
            </div>
          )}
          {vote !== null && (
            <div
              className={classes.addToWatchList}
              onClick={props.addToWatchList}
            >
              Add to watchlist
            </div>
          )}
          {vote === null && (
            <div className={classes.timeRemaining}>
              <ClockIcon />{' '}
              <span className={classes.timeRemainingText}>{timeRemaining}</span>
            </div>
          )}
        </div>
        {vote !== null && (
          <Button
            className={classes.nextBtn}
            onClick={() => {
              goToSymbol()
            }}
            sx={{
              '.MuiTouchRipple-root': {
                display: 'none',
              },
            }}
          >
            Done
          </Button>
        )}
      </div>
    </div>
  )
}
