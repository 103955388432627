import CustomTooltip from 'components/common/CustomTooltip'
import * as React from 'react'

function WatchedStarSelectedImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <CustomTooltip title="Remove from watch list">
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.08203 1.24219L5.30469 4.87891L1.28516 5.45312C0.574219 5.5625 0.300781 6.4375 0.820312 6.95703L3.69141 9.77344L3.00781 13.7383C2.89844 14.4492 3.66406 14.9961 4.29297 14.668L7.875 12.7812L11.4297 14.668C12.0586 14.9961 12.8242 14.4492 12.7148 13.7383L12.0312 9.77344L14.9023 6.95703C15.4219 6.4375 15.1484 5.5625 14.4375 5.45312L10.4453 4.87891L8.64062 1.24219C8.33984 0.613281 7.41016 0.585938 7.08203 1.24219Z"
          fill="#838692"
        />
      </svg>
    </CustomTooltip>
  )
}

export default WatchedStarSelectedImage
