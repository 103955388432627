/* eslint-disable */
if (!process.env.REACT_APP_URL || !process.env.REACT_APP_DEPLOYMENT_ENV) {
  throw new Error('ERROR')
}

const DEV_ENVIRONMENT = process.env?.REACT_APP_DEPLOYMENT_ENV !== 'PRODUCTION'

/* eslint-disable */
export const DOMAIN = !DEV_ENVIRONMENT ? 'fnchart.com' : 'bidecho.com'

// const DOMAIN = 'localhost:3000'

export const AUTH_DOMAIN = `auth.${DOMAIN}`

const REDIRECT_SIGN_IN =
  process.env?.REACT_APP_DEPLOYMENT_ENV === 'LOCAL'
    ? 'http://localhost:3000/redirect-signin'
    : 'https://' + DOMAIN + '/redirect-signin'

const REDIRECT_SIGN_OUT =
  process.env?.REACT_APP_DEPLOYMENT_ENV === 'LOCAL'
    ? 'http://localhost:3000/redirect-signout'
    : 'https://' + DOMAIN + '/redirect-signout'

const awsConfig = {
  aws_cognito_region: process.env.AWS_DEFAULT_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
  oauth: {
    // TODO: use env var
    domain: AUTH_DOMAIN,
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],

    // TODO: use env var
    redirectSignIn: REDIRECT_SIGN_IN,
    // TODO: use env var
    redirectSignOut: REDIRECT_SIGN_OUT,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE', 'FACEBOOK', 'AMAZON'],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  /*
  minLength: 8,
  requireDigits: true,
  requireUppercase: true,
  requireLowercase: true,

  requireSymbols: false,
  */
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_NUMBERS',
      'REQUIRES_UPPERCASE',
      'REQUIRES_LOWERCASE',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  // Changing this to .localStorage causes user to stay logged-in on page reload
  // This github issue addresses the approach to implementing the "remember me" functionality:
  // https://github.com/aws-amplify/amplify-js/issues/2552
  // Also here:
  // https://javascript.plainenglish.io/front-end-developers-guide-to-localstorage-vs-sessionstorage-d3fcc8c597a0
  storage:
    sessionStorage.length > 4 // Check length > 4 because amplify will always be ~7 length if set
      ? sessionStorage
      : window.localStorage.length > 4
      ? window.localStorage
      : undefined,
}

export default awsConfig
