import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { colors } from '../../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faCopy,
  faDownload,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { CameraActionsMenuItemIds } from 'utils/cameraUtils'

interface CameraIconsMobileProps {
  comment: CommentData
  onClose: () => void
  onCameraActionsClick: (comment: CommentData, action: string | number) => void
}

export const CameraIconsMobile: React.FC<CameraIconsMobileProps> = props => {
  const [touchStartY, setTouchStartY] = useState(null)
  const [boxBottom, setBoxBottom] = useState(0)

  const onTouchStart = (e: any) => {
    e.stopPropagation()

    setTouchStartY(e.touches[0].clientY)
  }

  const onTouchMove = (e: any) => {
    e.stopPropagation()

    if (touchStartY != null) {
      const currentTouchY = e.touches[0].clientY
      const touchDelta = currentTouchY - touchStartY

      if (touchDelta !== 0) {
        let newBottom = boxBottom - touchDelta
        newBottom = Math.min(newBottom, window.innerHeight)

        setBoxBottom(newBottom)
        setTouchStartY(currentTouchY)
      }
    }
  }

  const onTouchEnd = (e: any) => {
    e.stopPropagation()

    const threshold = window.outerHeight / 10

    if (-boxBottom > threshold) {
      setBoxBottom(-window.innerHeight)
      props.onClose()
    } else {
      setBoxBottom(0)
    }

    setTouchStartY(null)
  }

  return (
    <Box
      onTouchStart={e => {
        onTouchStart(e)
        e.currentTarget.style.transition = 'none'
      }}
      onTouchMove={onTouchMove}
      onTouchEnd={e => {
        onTouchEnd(e)
        e.currentTarget.style.transition = 'bottom 0.3s ease'
      }}
      sx={{
        zIndex: 999999999,
        touchAction: 'none',
        position: 'fixed',
        bottom: `${boxBottom}px`,
        transition: 'bottom 0.3s ease',
        left: 0,
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: colors.drawer_bg_right,
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.20)',
        WebkitTransform: 'translate3d(0, 0, 0)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '6px',
          paddingTop: 1,
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '60px',
            height: '6px',
            backgroundColor: colors.search_bg,
            borderRadius: '100px',
            marginBottom: 2,
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '24px',
          color: colors.white_faded,
          padding: '16px 24px 0px 24px',
        }}
      >
        Create an image of this post
      </Box>
      <Box
        sx={{
          paddingBottom: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            color: colors.textGreyLight,
            padding: '16px 24px 0px 24px',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onCameraActionsClick(
                props.comment,
                CameraActionsMenuItemIds.DOWNLOAD_IMAGE
              )
            }
          >
            <FontAwesomeIcon style={{}} icon={faDownload} size={'xl'} />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Download image
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onCameraActionsClick(
                props.comment,
                CameraActionsMenuItemIds.COPY_IMAGE
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faCopy}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Copy image
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onCameraActionsClick(
                props.comment,
                CameraActionsMenuItemIds.COPY_LINK
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faLink}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Copy link
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() =>
              props.onCameraActionsClick(
                props.comment,
                CameraActionsMenuItemIds.OPEN_IN_NEW_TAB
              )
            }
          >
            <FontAwesomeIcon
              style={{
                marginBottom: 1,
              }}
              icon={faArrowUpRightFromSquare}
              size={'xl'}
            />
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: 1,
                lineHeight: '16px',
                color: colors.textGreyLight,
              }}
            >
              Open in new tab
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
