import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import {
  IBaseNotification,
  IPostReplyNotification,
  IPricePredictionResultNotification,
} from 'types/notification'
import PostReplyNotificationRow from '../PostReplyNotificationRow'
import PricePredictionResultNotificationRow from '../PricePredictionResultNotificationRow'

interface Props {
  notifications: IBaseNotification[]
  onClose?: () => void
}

const NotificationPopup: React.FC<Props> = props => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const isPostReplyNotification = (
    notification: IBaseNotification
  ): notification is IPostReplyNotification => {
    return 'text' in notification
  }

  const isPricePredictionResultNotification = (
    notification: IBaseNotification
  ): notification is IPricePredictionResultNotification => {
    return 'roundId' in notification
  }

  return (
    <div className={classes.notificationWrapper}>
      <div className={classes.header}>Notifications</div>
      <div>
        {props.notifications.map((notification, index) => {
          if (isPostReplyNotification(notification)) {
            return (
              <PostReplyNotificationRow
                key={index}
                onClose={props.onClose}
                limitTextCharacters={30}
                notification={notification}
              />
            )
          } else if (isPricePredictionResultNotification(notification)) {
            return (
              <PricePredictionResultNotificationRow
                key={index}
                onClose={props.onClose}
                limitTextCharacters={30}
                notification={notification}
              />
            )
          } else {
            return null
          }
        })}
      </div>
      <div
        className={classes.seeAll}
        onClick={() => navigate(ROUTES.NOTIFICATIONS)}
      >
        See all
      </div>
    </div>
  )
}

export default NotificationPopup
