import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { CommentData } from 'components/common/CommentWithParent/Comment.interfaces'
import { ExDate } from '../../constants'
import { CommentDataFragment } from 'generated/graphql'

export const mapSingleComment = (commentData: any): CommentData => {
  let userVoteDirection = null
  const userVote = commentData.userVotePositive

  if (userVote === true) {
    userVoteDirection = VoteDirection.up
  } else if (userVote === false) {
    userVoteDirection = VoteDirection.down
  }

  return {
    id: commentData.id,
    content: commentData.text ?? commentData.commentText,
    date: new ExDate(commentData.createdAt),
    userId: commentData.user?.id,
    username: commentData.user?.nickname,
    emailHash: commentData.user?.emailHash,
    numberOfReplies: commentData.numberOfReplies,
    userVoteDirection: userVoteDirection,
    voteNum: commentData.votes,
    createdAt: new Date(commentData.createdAt),
    deleted: commentData.deleted,
    parentPost: commentData.post
      ? {
          id: commentData.post.id,
          title: commentData.post.title,
          postType: commentData.post.postType,
          text: commentData.post.text ?? commentData.post.postText,
          url: commentData.post.url,
          date: new ExDate(commentData.post.date),
          userId: commentData.post.user?.id,
          username: commentData.post.user?.nickname,
          emailHash: commentData.post.user?.emailHash,
          symbol: commentData.post.postSymbol,
          symbolName: commentData.post.symbol?.name,
          assetType: commentData.post.postAssetType,
          numberOfReplies: commentData.post.numberOfReplies,
          image: commentData.post.image,
          voteNum: commentData.post.votes,
          createdAt: new Date(commentData.post.createdAt),
        }
      : undefined,
    parentComment: commentData.parentComment
      ? {
          id: commentData.parentComment.id,
          content:
            commentData.parentComment.text ??
            commentData.parentComment.commentText,
          date: new ExDate(commentData.parentComment.createdAt),
          userId: commentData.parentComment.user?.id,
          username: commentData.parentComment.user?.nickname,
          emailHash: commentData.parentComment.user?.emailHash,
          numberOfReplies: commentData.parentComment.numberOfReplies,
          createdAt: new Date(commentData.parentComment.createdAt),
          voteNum: commentData.parentComment.votes,
          deleted: commentData.parentComment.deleted,
          parentPost: commentData.post
            ? {
                id: commentData.post.id,
                title: commentData.post.title,
                postType: commentData.post.postType,
                url: commentData.post.url,
                date: new ExDate(commentData.post.date),
                userId: commentData.post.user?.id,
                username: commentData.post.user?.nickname,
                emailHash: commentData.post.user?.emailHash,
                symbol: commentData.post.postSymbol,
                assetType: commentData.post.postAssetType,
                numberOfReplies: commentData.post.numberOfReplies,
                voteNum: commentData.post.votes,
                createdAt: new Date(commentData.post.createdAt),
              }
            : undefined,
        }
      : undefined,
  }
}

export const mapComments = (comments: CommentDataFragment[]): CommentData[] => {
  return comments
    ? comments
        .map(mapSingleComment)
        .sort((a, b) => b.date?.getTime() - a.date?.getTime())
    : []
}
