import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { PostIcons } from 'components/PostIcons/PostIcons'
import React, { useEffect } from 'react'
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import { formatWithEllipsis } from 'utils/stringUtils'
import theme from '../../../mui-theme'
import { ensureHttpProtocol, extractHostname } from '../../../utils/urlUtils'
import VoteUpDown, { VoteDirection } from '../VoteUpDown'
import useStyles from './styles'
import ROUTES from 'constants/routes'
import { PostIconsMobile } from 'components/PostIconsMobile/PostIconsMobile'
import CustomTooltip from 'components/common/CustomTooltip'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { PostData } from 'components/common/CommentWithParent/Comment.interfaces'
import { Checkbox } from '@mui/material'
import { colors } from '../../../constants'
import { PostType } from 'generated/graphql'
import StoryComment from '../StoryComment'
import { IStoryPost } from 'types/story-posts'

export interface TopicControls {
  onTopicHeaderClick?(
    location: any,
    navigate: NavigateFunction,
    assetType: string,
    symbol: string,
    id: string | undefined,
    url: string | undefined
  ): void
  onTopicReplyClick?: (id: string) => void
  onTopicBookmarkClick?: (id: string) => void
  onShareActionsClick?: (id: string, action: string | number) => void
  onCameraActionsClick?: (topicInfo: PostData, action: string | number) => void
  onMoreActionsClick?: (id: string, action: string | number) => void
}

export interface TopicListControls extends TopicControls {
  onCommentClick?: (id: string) => void
}

interface Props extends TopicListControls {
  post: PostData
  isCreator: boolean
  isStoryEdit?: boolean
  isStoryView?: boolean
  selectedStoryPosts?: IStoryPost[]
  readonly?: boolean
  clearBackground?: boolean
  onVote: (direction: VoteDirection) => void
  isHovered?: (isHovered: boolean) => void
  checkTopic?: (e: any, storyPost: IStoryPost) => void
}

const Topic: React.FC<Props> = (props: Props) => {
  const classes = useStyles(theme)
  const deviceType = useDeviceType()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [highlightTopicId, setHighlightTopicId] = React.useState<string | null>(
    null
  )
  const [headlineWithFormatting, setHeadlineWithFormatting] =
    React.useState<string>('')

  const url = ensureHttpProtocol(props.post.url ?? '')

  useEffect(() => {
    setHighlightTopicId(searchParams.get('topicid'))
  }, [])

  // puts a span around the tickers so ticker text is blue
  useEffect(() => {
    setHeadlineWithFormatting(
      props.post.title.replace(/\$[A-Z]+[\S]*/g, function (match) {
        return (
          '<a target="_blank" ' +
          'onclick="event.stopPropagation();" ' +
          'href="/asset/' +
          match.replace('$', '').toLowerCase() +
          '" class="' +
          classes.ticker +
          '">' +
          match +
          '</a>'
        )
      })
    )
  }, [props.post.title])

  const getStoryComment = () => {
    if (props.selectedStoryPosts) {
      const storyPost = props.selectedStoryPosts.find(
        post => post.childPostId === props.post.id && !!post.noteText
      )
      return storyPost?.noteText ?? undefined
    }
    return undefined
  }

  return (
    <div
      className={classNames(
        !props.clearBackground && classes.topicWrapper,
        props.clearBackground && classes.topicWrapperClearBackground
      )}
      data-topic="true"
      data-topic-date={props.post.date}
      onMouseEnter={() => props.isHovered && props.isHovered(true)}
      onMouseLeave={() => props.isHovered && props.isHovered(false)}
    >
      {props.isStoryEdit && props.checkTopic && (
        <div className={classes.checkboxWrapper}>
          <Checkbox
            onChange={e =>
              props.checkTopic!(e, {
                childPostId: props.post.id,
                noteText: null,
              })
            }
            checked={
              props.selectedStoryPosts?.some(
                post => post.childPostId === props.post.id
              ) ?? false
            }
            sx={{
              color: colors.white_faded,
              padding: 0,
              width: 16,
              height: 16,
            }}
          />
        </div>
      )}
      {!props.isStoryEdit && <div></div>}
      <div
        className={classNames(
          !props.clearBackground && classes.topicLeft,
          props.clearBackground && classes.topicLeftClearBackground,
          highlightTopicId === props.post.id &&
            props.clearBackground &&
            classes.topicLeftHighlighted
        )}
      >
        {props.post.postType === PostType.Story && (
          <div className={classes.story}>STORY</div>
        )}
        <div className={classes.topicHeadline}>
          <span
            className={classes.topicHeadlineText}
            onClick={() => {
              const { url, id } = props.post

              if (props.onTopicHeaderClick === undefined) return

              if (props.post.postType === PostType.Story) {
                navigate(
                  ROUTES.STORY.replace(
                    ':type',
                    props.post.assetType.toLowerCase()
                  )
                    .replace(':symbol', props.post.symbol.toLowerCase())
                    .replace(':storyId', props.post.id.toLowerCase())
                )
              } else {
                props.onTopicHeaderClick(
                  location,
                  navigate,
                  props.post.assetType,
                  props.post.symbol,
                  id,
                  url ?? undefined
                )
              }
            }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: headlineWithFormatting }}
            ></span>{' '}
          </span>
          {props.post.postType === PostType.Story && (
            <span
              className={classes.topicHeadlineUrl}
              onClick={() =>
                navigate(
                  ROUTES.STORY.replace(
                    ':type',
                    props.post.assetType.toLowerCase()
                  )
                    .replace(':symbol', props.post.symbol.toLowerCase())
                    .replace(':storyId', props.post.id.toLowerCase())
                )
              }
              style={{ cursor: 'pointer' }}
            >
              <span className={classes.topicHeadlineSource}>
                (fnchart.com) &nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="1x" />
              </span>
            </span>
          )}
          {props.post.postType === PostType.Text && (
            <span className={classes.topicHeadlineSource}>(fnchart.com)</span>
          )}
          {props.post.postType === PostType.Url && !!props.post.url && (
            <CustomTooltip title={props.post.url!.toLowerCase()}>
              <a
                className={classes.topicHeadlineUrl}
                href={url}
                rel="noreferrer"
                target="_blank"
              >
                <span className={classes.topicHeadlineSource}>
                  ({extractHostname(props.post.url!)}) &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="1x" />
                </span>
              </a>
            </CustomTooltip>
          )}
        </div>
        {!!props.post.image && (
          <div className={classes.imageWrapper}>
            <a
              className={classes.image}
              href={url}
              rel="noreferrer"
              target="_blank"
            >
              <img
                style={{
                  width: '100%',
                  marginBottom: 8,
                }}
                src={props.post.image}
              />
            </a>
          </div>
        )}

        <div className={classes.topicPostDetails}>
          <div
            className={classes.topicUserName}
            onClick={() => {
              if (props.readonly) return

              navigate(
                ROUTES.USER.replace(
                  ':username',
                  props.post.username!
                ).toLowerCase()
              )
            }}
          >
            {formatWithEllipsis(props.post!.username ?? '', 10)}
          </div>
          {!props.readonly && (
            <div
              className={classes.topicPath}
              onClick={() => {
                navigate(
                  ROUTES.ASSET.replace(':type', props.post.assetType!)
                    .replace(':symbol', props.post.symbol!)
                    .toLowerCase()
                )
              }}
            >
              {'$' + props.post.symbol}
            </div>
          )}
          {props.readonly && (
            <div className={classes.topicPath}>{'$' + props.post.symbol}</div>
          )}
          <div className={classes.topicTime}>
            {getTimeElapsedRounded(props.post.date, props.post.createdAt)}
          </div>
        </div>
        {!props.isStoryEdit &&
          !props.isStoryView &&
          deviceType === DeviceType.Desktop &&
          props.onCommentClick && (
            <PostIcons
              post={props.post}
              numberOfReplies={props.post.numberOfReplies ?? 0}
              onCommentClick={(id: string) => {
                props.onCommentClick!(id)

                if (props.isHovered) {
                  props.isHovered(false)
                }
              }}
              onTopicBookmarkClick={props.onTopicBookmarkClick!}
              onShareActionsClick={props.onShareActionsClick!}
              onCameraActionsClick={props.onCameraActionsClick!}
              onMoreActionsClick={props.onMoreActionsClick!}
              isCreator={props.isCreator}
            />
          )}
        {!props.isStoryEdit &&
          !props.isStoryView &&
          deviceType === DeviceType.Mobile &&
          props.onCommentClick && (
            <PostIconsMobile
              post={props.post}
              numberOfReplies={props.post.numberOfReplies ?? 0}
              onCommentClick={(id: string) => {
                props.onCommentClick!(id)

                if (props.isHovered) {
                  props.isHovered(false)
                }
              }}
              onTopicBookmarkClick={props.onTopicBookmarkClick!}
              onShareActionsClick={props.onShareActionsClick!}
              onCameraActionsClick={props.onCameraActionsClick!}
              onMoreActionsClick={props.onMoreActionsClick!}
              isCreator={props.isCreator}
            />
          )}
        {props.isStoryEdit && props.post.postType !== PostType.Story && (
          <StoryComment
            post={props.post}
            isCreator={props.isCreator}
            isStoryEdit={false}
            onVote={props.onVote}
            isChecked={
              props.selectedStoryPosts?.some(
                post => post.childPostId === props.post.id
              ) ?? false
            }
            comment={
              props.selectedStoryPosts?.find(
                post => post.childPostId === props.post.id && !!post.noteText
              )?.noteText ?? undefined
            }
            onReply={(id: string, comment: string) => {
              props.checkTopic!(null, {
                childPostId: id,
                noteText: comment,
              })
            }}
          />
        )}
        {props.isStoryView && !!getStoryComment() && (
          <div className={classes.storyComment}>{getStoryComment()}</div>
        )}
      </div>
      <div
        className={classNames(
          !props.clearBackground && classes.topicRight,
          props.clearBackground && classes.topicRightClearBackground,
          highlightTopicId === props.post.id &&
            props.clearBackground &&
            classes.topicRightHighlighted
        )}
      >
        <VoteUpDown
          numVotes={props.post.voteNum || 0}
          onVoteDownClick={() => props.onVote(VoteDirection.down)}
          onVoteUpClick={() => props.onVote(VoteDirection.up)}
          voteDirection={props.post.userVoteDirection}
        />
      </div>
    </div>
  )
}

export default Topic
