import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'
import theme from '../../../mui-theme'

const wrapperHeight = 42
const wrapperHeightMobile = 47

const inputHeight = 26
const remainingSpace = wrapperHeight - inputHeight
const remainingSpaceMobile = wrapperHeightMobile - inputHeight

export default makeStyles<typeof theme>(
  theme => ({
    root: {
      width: '100%',
    },

    input: {
      width: '100%',
      height: inputHeight,
      color: colors.text_white,
      padding: 0,
      fontSize: 12,
      fontFamily: fonts.dm_sans,
    },

    inputFullHeight: {
      height: wrapperHeight,

      [theme.breakpoints.down('lg')]: {
        height: wrapperHeightMobile,
      },
    },

    underline: {
      '&:after': {
        borderBottom: `1px solid ${colors.blue_light}`,
        position: 'absolute',
        left: 0,
        bottom: '-1px',
      },

      '&:before': {
        borderBottom: 'none !important',
      },
    },

    noUnderline: {
      borderBottom: 'none',

      '&:after': {
        borderBottom: 'none !important',
      },

      '&:before': {
        borderBottom: 'none',
      },
    },

    inputBox: {
      width: '100%',
      height: wrapperHeight,
      marginTop: 0,
      backgroundColor: colors.input_bg,

      [theme.breakpoints.down('lg')]: {
        height: wrapperHeightMobile,
      },
    },

    inputRootNoMarginTop: {
      marginTop: '0 !important',
    },

    inputRootMarginTop: {
      marginTop: `${remainingSpace}px !important`,

      [theme.breakpoints.down('lg')]: {
        marginTop: `${remainingSpaceMobile}px !important`,
      },
    },

    inputRoot: {
      padding: '0px 10px',
      boxSizing: 'border-box',
      width: '100%',
    },

    inputRootUnderline: {
      borderBottomWidth: 1,
      borderBottomColor: colors.text_input_inactive,
      borderBottomStyle: 'solid',
    },

    formLabelRoot: {
      color: colors.textGrey,
      fontFamily: fonts.dm_sans,
      fontSize: 11,
      lineHeight: 1,
      marginBottom: 8,
      display: 'flex',

      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        marginBottom: 4,
      },
    },

    inputLabelRoot: {
      padding: '5px 10px',
      color: colors.text_input_inactive,
      transform: 'translateY(10px)',
      fontSize: 12,
      lineHeight: 1,
    },

    labelFocused: {
      color: `${colors.blue_light} !important`,
    },

    labelShrink: {
      transform: 'translateY(3px)',
      fontSize: 10,
    },

    formHelperRoot: {
      fontSize: 10,
      lineHeight: 1,
      marginTop: 5,
    },

    placeholderOverlay: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      left: 8,
      height: wrapperHeight,
      pointerEvents: 'none',
      color: colors.text_input_inactive,

      [theme.breakpoints.down('lg')]: {
        height: wrapperHeightMobile,
      },
    },
  }),
  { index: 1 }
)
