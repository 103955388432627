import React from 'react'
import { styled } from '@mui/material/styles'
import Box, { BoxProps as MuiBoxProps } from '@mui/material/Box'
import { colors } from '../../../constants'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

// Extend BoxProps to include isMobile
interface BoxProps extends MuiBoxProps {
  isMobile?: boolean
}

const StyledBox = styled(Box)<BoxProps>(({ theme, isMobile }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isMobile ? colors.drawer_bg_right : colors.main,
}))

const CenteredBoxScreenWrapper: React.FC<BoxProps> = props => {
  const deviceType = useDeviceType()

  return <StyledBox isMobile={deviceType === DeviceType.Mobile} {...props} />
}

export default CenteredBoxScreenWrapper
