import { forwardRef } from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomTooltip from '../CustomTooltip'
import { CommentData, PostData } from './Comment.interfaces'
import { extractHostname, handleTopicHeaderClick } from 'utils/urlUtils'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { PostIcons } from 'components/PostIcons/PostIcons'
import { getTimeElapsedRounded } from 'utils/dateTimeUtils'
import ChildComment from './ChildComment'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import UserAvatar from 'components/UserAvatar'
import { useLocation, useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import MarkdownViewer from 'components/inputs/MarkdownViewer'
import { CommentIcons } from 'components/CommentIcons/CommentIcons'
import { PostType } from 'generated/graphql'
import TickerImage from '../TickerImage'
import { DOMAIN } from 'auth/aws-exports'

interface Props {
  userId?: string
  comment: CommentData
  index: number
  onVote: (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  onPostClick: (id: string, symbol: string) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const location = useLocation()

  if (props.comment.parentPost!.username! === '[deleted]')
    props.comment!.parentPost!.url = 'https://' + DOMAIN

  return (
    <div className={classes.postCommentWrapper}>
      <div key={props.index} className={classes.postWrapper}>
        <div className={classes.postDetails}>
          {props.comment.parentPost!.postType === PostType.Story && (
            <div className={classes.story}>STORY</div>
          )}
          <div className={classes.symbolDetails}>
            <TickerImage symbol={props.comment.parentPost?.symbol!} />
            <div className={classes.symbolTitle}>
              {props.comment.parentPost?.symbolName!}
            </div>
          </div>
          <div className={classes.postTitle}>
            {props.comment.parentPost!.postType === PostType.Text && (
              <div
                onClick={() => {
                  handleTopicHeaderClick(
                    location,
                    navigate,
                    props.comment.parentPost?.assetType!,
                    props.comment.parentPost?.symbol!,
                    props.comment.parentPost!.id!
                  )
                }}
              >
                {props.comment.parentPost!.title}{' '}
                <span className={classes.url}>(fnchart.com)</span>
              </div>
            )}
            {props.comment.parentPost!.postType === PostType.Story && (
              <span
                onClick={() =>
                  navigate(
                    ROUTES.STORY.replace(
                      ':type',
                      props.comment.parentPost!.assetType.toLowerCase()
                    )
                      .replace(
                        ':symbol',
                        props.comment.parentPost!.symbol.toLowerCase()
                      )
                      .replace(
                        ':storyId',
                        props.comment.parentPost!.id.toLowerCase()
                      )
                  )
                }
                style={{ cursor: 'pointer' }}
              >
                {props.comment.parentPost!.title}{' '}
                <span className={classes.url}>
                  (fnchart.com) &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="1x" />
                </span>
              </span>
            )}
            {props.comment.parentPost!.postType === PostType.Url && (
              <div
                onClick={() => {
                  handleTopicHeaderClick(
                    location,
                    navigate,
                    props.comment.parentPost?.assetType!,
                    props.comment.parentPost?.symbol!,
                    undefined,
                    props.comment.parentPost!.url!
                  )
                }}
              >
                {props.comment.parentPost!.title}{' '}
                <CustomTooltip
                  title={
                    props.comment.parentPost!.userId ===
                    'de1e4ed0-0000-0000-0000-000000000000'
                      ? 'deleted post'
                      : !!props.comment?.parentPost?.url
                      ? props.comment.parentPost!.url!.toLowerCase()
                      : 'no url -- redirect to fnchart'
                  }
                >
                  <span className={classes.urlSource}>
                    <span className={classes.url}>
                      (
                      {!!props.comment?.parentPost?.url
                        ? extractHostname(props.comment.parentPost!.url!)
                        : 'https://' + DOMAIN}
                      ) &nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        size="1x"
                      />
                    </span>
                  </span>
                </CustomTooltip>
              </div>
            )}
          </div>
          {!!props.comment.parentPost!.image &&
            !!props.comment.parentPost!.url && (
              <div className={classes.imageWrapper}>
                <a
                  className={classes.image}
                  href={props.comment.parentPost!.url!}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    className={classes.image}
                    src={props.comment.parentPost!.image}
                  />
                </a>
              </div>
            )}
          <div className={classes.lineWrapper}>
            <div
              className={classes.usernameWrapper}
              onClick={() => {
                navigate(
                  ROUTES.USER.replace(
                    ':username',
                    props.comment.parentPost!.username!
                  ).toLowerCase()
                )
              }}
            >
              <div className={classes.avatarWrapper}>
                <UserAvatar emailHash={props.comment.parentPost!.emailHash} />
              </div>
              <div className={classes.username}>
                {props.comment.parentPost!.username}
              </div>
            </div>
            <div
              className={classes.symbol}
              onClick={() => {
                navigate(
                  ROUTES.ASSET.replace(
                    ':symbol',
                    props.comment.parentPost!.symbol!
                  )
                    .replace(':type', props.comment.parentPost!.assetType!)
                    .toLowerCase()
                )
              }}
            >
              ${props.comment.parentPost!.symbol}
            </div>
            <div className={classes.date}>
              {getTimeElapsedRounded(
                props.comment.parentPost!.date,
                props.comment.parentPost?.createdAt
              )}
            </div>
          </div>
          <div className={classes.postIconsWrapper}>
            <PostIcons
              post={props.comment.parentPost!}
              numberOfReplies={props.comment.parentPost!.numberOfReplies || 0}
              onTopicBookmarkClick={/* TODO: Add bookmarks feature */ () => {}}
              onMoreActionsClick={(id: string, action: string | number) =>
                props.onMoreActionsClick(
                  id,
                  props.comment.parentPost!.symbol,
                  props.comment.parentPost!.assetType,
                  action
                )
              }
              onShareActionsClick={(id: string, action: string | number) =>
                props.onShareActionsClick(
                  id,
                  props.comment.parentPost!.title,
                  props.comment.parentPost!.symbol,
                  action
                )
              }
              onCameraActionsClick={(
                topicInfo: PostData,
                action: string | number
              ) => props.onCameraActionsClick(topicInfo, action)}
              isCreator={props.userId === props.comment.parentPost!.userId}
              onCommentClick={(id: string) => {
                props.onPostClick(id, props.comment.parentPost!.symbol)
              }}
            />
          </div>
        </div>
      </div>
      <div>
        {props.comment.parentComment && (
          <div className={classes.commentWrapper}>
            {!props.comment.parentComment!.deleted && (
              <div>
                <div className={classes.lineWrapper}>
                  <div
                    className={classes.usernameWrapper}
                    onClick={() => {
                      navigate(
                        ROUTES.USER.replace(
                          ':username',
                          props.comment.parentComment!.username!
                        ).toLowerCase()
                      )
                    }}
                  >
                    <div className={classes.avatarWrapper}>
                      <UserAvatar
                        emailHash={props.comment.parentComment!.emailHash}
                      />
                    </div>
                    <div className={classes.username}>
                      {props.comment.parentComment!.username}
                    </div>
                  </div>
                  <div className={classes.date}>
                    {getTimeElapsedRounded(
                      props.comment.parentComment!.date,
                      props.comment.parentComment?.createdAt
                    )}
                  </div>
                </div>
                <div className={classes.commentText}>
                  <MarkdownViewer
                    markdownSource={props.comment.parentComment!.content}
                    isPreview={false}
                  ></MarkdownViewer>
                </div>
                <div className={classes.postIconsWrapper}>
                  <CommentIcons
                    comment={props.comment.parentComment!}
                    numberOfReplies={
                      props.comment.parentComment.numberOfReplies
                    }
                    onMoreActionsClick={(
                      id: string,
                      action: string | number
                    ) => {
                      props.onMoreCommentActionsClick(
                        props.comment.parentPost!.id,
                        id,
                        props.comment!.parentPost!.symbol,
                        props.comment!.parentPost!.assetType,
                        action
                      )
                    }}
                    onShareActionsClick={(
                      id: string,
                      action: string | number
                    ) =>
                      props.onShareActionsClick(
                        id,
                        props.comment.parentComment!.content,
                        props.comment!.parentPost!.symbol,
                        action,
                        props.comment.parentComment!.id
                      )
                    }
                    onCameraActionsClick={props.onCameraActionsCommentClick}
                    isCreator={
                      props.userId === props.comment.parentComment!.userId
                    }
                    onCommentClick={(id: string) => {
                      props.onCommentClick(
                        id,
                        props.comment.parentPost!.symbol,
                        props.comment.parentComment!.id
                      )
                    }}
                  />
                </div>
              </div>
            )}
            {(props.comment.parentComment!.deleted ||
              props.comment.parentComment!.content === '') && (
              <div className={classes.deletedComment}>Comment deleted</div>
            )}
            <ChildComment
              userId={props.userId}
              comment={props.comment}
              onVote={props.onVote}
              onShareActionsClick={props.onShareActionsClick}
              onCameraActionsCommentClick={props.onCameraActionsCommentClick}
              onMoreCommentActionsClick={props.onMoreCommentActionsClick}
              onCommentClick={props.onCommentClick}
            />
          </div>
        )}

        {!props.comment.parentComment && (
          <ChildComment
            userId={props.userId}
            comment={props.comment}
            onVote={props.onVote}
            onShareActionsClick={props.onShareActionsClick}
            onCameraActionsCommentClick={props.onCameraActionsCommentClick}
            onMoreCommentActionsClick={props.onMoreCommentActionsClick}
            onCommentClick={props.onCommentClick}
          />
        )}
      </div>
    </div>
  )
})
