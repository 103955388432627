import React, { forwardRef, HTMLAttributes } from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import useStyles from './styles'
import theme from '../../../mui-theme'
import { FormControl, TextField } from '@material-ui/core';
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { colors, fonts } from '../../../constants'
import { Popper, PopperProps } from '@mui/material';

export interface AutoCompleteOption {
  label: string
  value: string
}

interface Props {
  error?: string
  searchText: string
  name?: string
  label?: string
  value?: string
  options: AutoCompleteOption[]
  loading?: boolean
  loadingText?: string
  noOptionsText?: string
  iconRight?: IconDefinition
  label_outside_select?: boolean
  getOptionLabel?: (option: AutoCompleteOption) => string
  onInputChange?: (e: string) => void
  onChange?: (e: string) => void
  iconRightClick?: (search: string | undefined) => void
}

export default forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles(theme)
  const [value] = React.useState<string | AutoCompleteOption | undefined>(
    props.options?.filter(x => x.value === props.value)[0]
  )
  const [isFocused, setIsFocused] = React.useState(false)

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: AutoCompleteOption) => `${option.value}${option.label}`,
  })

  return (
    <>
      <div className={classes.autoCompleteWrapper}>
        <FormControl
          classes={{
            root: classes.root,
          }}
        >
          {props.label_outside_select && (
            <FormLabel
              htmlFor={`select-${props.name}`}
              error={!!props.error}
              classes={{
                root: classNames(
                  classes.formLabelRoot,
                  isFocused
                    ? classes.formLabelIsFocused
                    : classes.formLabelNotFocused
                ),
              }}
            >
              {props.label}
            </FormLabel>
          )}
          <div className={classes.selectBox}>
            <Autocomplete
              freeSolo
              sx={{
                paddingLeft: '10px',

                '& .MuiInput-root': {
                  height: 42
                },

                '& .MuiInputLabel-root': {
                  color: colors.textGrey
                },

                '& .MuiAutocomplete-inputRoot:not(.Mui-disabled)': {
                  '&:before': {
                    borderBottom: 'none'
                  },
                  '&:after': {
                    borderBottom: 'none'
                  }
                },

                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: 'none'                  
                },

                '& .MuiAutocomplete-input': {
                  height: 24,
                  fontSize: 12,
                  color: colors.text_white,
                  fontFamily: fonts.dm_sans,                  
                },

                '& .MuiAutocomplete-clearIndicator': {
                  color: colors.text_white
                }
              }}
              disableClearable
              id={`select-${props.name}`}
              options={props.options}
              getOptionLabel={
                props.getOptionLabel ||
                ((option: AutoCompleteOption) => option.label || '')
              }
              filterOptions={filterOptions}
              loading={props.loading}
              loadingText={props.loadingText}
              noOptionsText={props.noOptionsText}
              onFocus={_event => {
                setIsFocused(true)
              }}
              onBlur={_event => {
                setIsFocused(false)
              }}
              value={value}
              onChange={(_event, newValue) => {
                if (props.onChange) {
                  props.onChange((newValue as AutoCompleteOption).value)
                }
              }}
              inputValue={props.searchText || ''}
              onInputChange={(_event, newValue) => {
                if (props.onInputChange) {
                  props.onInputChange(newValue)
                }
              }}
              onKeyUp={event => {
                if (event.keyCode === 13) {
                  !!props.iconRightClick && props.iconRightClick(props.value)
                }
              }}
              renderInput={params => (
                <TextField
                  error={!!props.error}
                  {...params}
                  label={!props.label_outside_select && props.label}
                  name={props.name}
                  classes={{
                    root: classes.inputRoot,
                  }}
                />
              )}
              renderOption={(props: HTMLAttributes<HTMLLIElement>, option: AutoCompleteOption) => {
                return <React.Fragment>
                  <li key={option.value.toString()} {...props}>
                    <span className={classes.optionWrapper}>
                      <span>{option.label}</span>
                    </span>
                  </li>
                </React.Fragment>
              }}
              PopperComponent={(props: PopperProps) => {
                return <Popper {...props} className={classes.popper} placement="bottom-start" />;
              }}
            />
          </div>
        </FormControl>
        {!!props.iconRight && (
          <FontAwesomeIcon
            icon={props.iconRight}
            className={classes.iconRight}
            color={colors.textGrey}
            onClick={() => {
              !!props.iconRightClick && props.iconRightClick(props.value)
            }}
          />
        )}
      </div>
      {props.error && (
        <FormHelperText
          error={true}
          classes={{
            root: classes.formHelperRoot,
          }}
        >
          {props.error}
        </FormHelperText>
      )}
    </>
  )
})
