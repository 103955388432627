import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'

export default makeStyles({
  wrapper: {
    height: 73,
    width: '100%',
    backgroundColor: colors.drawer_bg_right,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  disabledIcon: {
    opacity: 0.3,
  },
})
