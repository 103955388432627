import type {} from '@mui/lab/themeAugmentation'
import { ThemeOptions, adaptV4Theme, createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import { colors, fonts } from './constants'

const options: ThemeOptions = {
  components: {
    MuiDatePicker: {
      styleOverrides: {
        '& .MuiPickersDay-root': {},
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colors.textGrey,
          backgroundColor: colors.menu_bg,
          transitionDelay: '50ms',
          '&.Mui-selected, &:hover': {
            color: colors.text_white,
            backgroundColor: colors.black,
            border: `1px solid ${colors.text_white}`,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '&:focus': {
            color: colors.text_white,
          },
          '.Mui-disabled': {
            color: colors.text_white,
            opacity: 0.5,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: colors.text_white,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.menu_bg,
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: colors.menu_bg,
          color: colors.text_white,
          '.MuiTypography-caption': {
            color: colors.text_white,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontFamily: fonts.dm_sans,
        },
        caption: {
          fontSize: 12,
          fontFamily: fonts.dm_sans,
        },
        subtitle1: {
          fontFamily: fonts.dm_sans,
        },
        body1: {
          fontSize: 14,
          fontFamily: fonts.dm_sans,
        },
        body2: {
          fontSize: 12,
          fontFamily: fonts.dm_sans,
        },
        // @ts-ignore
        colorPrimary: {
          color: colors.text_white,
        },
        h5: {
          fontFamily: fonts.dm_sans,
        },
      },
    },
  },
}

export default createTheme(
  deepmerge(
    options,
    adaptV4Theme({
      palette: {
        primary: {
          main: '#141420',
        },
        secondary: {
          main: colors.blue,
        },
        error: {
          main: colors.red_light,
        },
        text: {
          primary: colors.text_white,
        },
      },

      overrides: {
        MuiMenuItem: {
          root: {
            color: colors.textGrey,
            fontSize: 12,
            fontFamily: fonts.dm_sans,
          },
        },
        MuiTextField: {
          root: {
            width: '100%',
          },
        },
        MuiInput: {
          root: {
            width: '100%',
          },
          input: {
            fontSize: 12,
            fontFamily: fonts.dm_sans,
            color: colors.text_white,
          },
          underline: {
            '&:before': {
              borderBottom: 'none',
            },
            '&:after': {
              borderBottom: 'none',
            },

            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          },
        },
        MuiFormLabel: {
          root: {
            fontFamily: fonts.dm_sans,
            color: colors.text_white,

            '&.Mui-focused': {
              fontFamily: fonts.dm_sans,
              fontSize: 12,
              color: colors.blue_light,
            },
          },
        },
        MuiInputLabel: {
          root: {
            padding: '5px 10px',
            color: colors.text_input_inactive,
            transform: 'translateY(10px) !important',
            fontSize: 12,
            lineHeight: 1,
          },
          shrink: {
            transform: 'translate(0, 1.5px) scale(0.75) !important',
          },
        },
        MuiListItem: {
          button: {
            '&:hover': {
              backgroundColor: colors.drawer_bg_right,
            },
          },
        },
        MuiIconButton: {
          root: {
            color: colors.textGrey,
          },
        },
        MuiFormHelperText: {
          root: {
            fontSize: 10,
            fontFamily: fonts.dm_sans,
          },
        },
        MuiDialogContent: {
          root: {
            '&:first-child': {
              paddingTop: 0,
            },
          },
        },
      },
    })
  )
)
