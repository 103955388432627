import { Link } from '@mui/material'
import theme from '../../mui-theme'
import useStyles from './styles'

interface TermsofServiceProps {
  domainUrl: string
}

export const TermsofService = (props: TermsofServiceProps) => {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>Terms of Service</div>
        <div className={classes.lastRevised}>
          Effective September 12, 2023. Last Revised September 12, 2023
        </div>
        <div className={classes.bodyText}>
          <p>
            fnchart Inc (“Provider”) provides a community platform and
            application program interface (API) on a software as a service and
            platform as a service basis (“Service”, as further defined below).
            The Service is provided on a subscription basis as further described
            in this Agreement and on the Website under specific subscription
            plans offered by Provider from time to time. The Services are
            provided through the website https://fnchart.com (the “Website”).
            This Agreement applies to anyone (“You”/”Customer”) who subscribes
            for or otherwise uses the Services.
          </p>
          <p>
            THESE TERMS (WHICH TOGETHER WITH ANY ONLINE ORDER PROCESS OR ORDER
            FORM OFFERED BY PROVIDER THROUGH THE WEBSITE WHICH INCORPORATE THESE
            TERMS BY REFERENCE (“ORDER FORM”) ARE COLLECTIVELY REFERRED TO AS
            THE “AGREEMENT”) CONTAIN IMPORTANT LIMITATIONS ON REPRESENTATIONS,
            WARRANTIES, CONDITIONS, REMEDIES AND LIABILITIES THAT ARE APPLICABLE
            TO THE SERVICES. ACCORDINGLY, YOU SHOULD READ THESE TERMS CAREFULLY
            BEFORE USING THE SERVICES. EITHER BY CLICKING A BOX INDICATING YOUR
            ACCEPTANCE OR BY EXECUTING AN ORDER FORM THAT REFERENCES THESE
            TERMS, YOU AGREE TO THE TERMS HEREOF. IF YOU ARE AN AGENT OR
            EMPLOYEE OF AN ENTITY YOU REPRESENT AND WARRANT THAT (I) THE
            INDIVIDUAL ACCEPTING THIS AGREEMENT IS AUTHORIZED TO ACCEPT THIS
            AGREEMENT ON SUCH ENTITY’S BEHALF AND TO BIND SUCH ENTITY, AND (II)
            SUCH ENTITY HAS FULL POWER, CORPORATE OR OTHERWISE, TO ENTER INTO
            THIS AGREEMENT AND PERFORM ITS OBLIGATIONS HEREUNDER. IF YOU DO NOT
            ACCEPT THESE TERMS, THEN DO NOT USE THE WEBSITE OR ANY OF ITS
            CONTENT OR SERVICES.
          </p>
          <h3 className={classes.subHeader}>1. Provision of Service.</h3>
          <p>
            1. Provision Generally. On execution by each Party of the Order
            Form, during the Subscription Term (as defined in Section 5.1)
            Provider will provide Customer with access to Provider’s community
            platform and Provider’s application program interface (API)
            (collectively, the “Service”) allowing Customer to build scalable
            customer online community in accordance with the terms and
            conditions of this Agreement. In order to access and use the
            Service, Customer is responsible at its own expense for obtaining
            its own Internet access and any hardware and software required
            therefor. Customer’s right to use the Service during the
            Subscription Term shall be subject to and in accordance with any
            additional conditions, restrictions or parameters specified in the
            Order Form(s)
          </p>
          <h3 className={classes.subHeader}>The Information We Collect</h3>
          <p>
            We collect, store, and use certain information from or about you for
            the purposes described below:
          </p>
          <h3 className={classes.subHeader}>Information You Provide To Us</h3>
          <p>
            We collect a variety of information that you provide directly to us.
            For example, we collect information from you when you: Sign up for
            and use the Services Communicate with us (for example, when you
            communicate with our customer service teams or submit requests or
            questions to us via online forms, email, phone, chat, or otherwise)
            Interact with us when we reach out to you by phone or email in our
            sales and marketing efforts Attend our conferences or events or
            interact with us at other conferences or events The types of data we
            collect directly from you include: First and last name Business
            contact information (email address, phone number, postal address)
            Personal contact information (email address, phone number, postal
            address) Username and password associated with the Services Your
            position and role in your company or organization In accordance with
            applicable law, photos, videos, or audio recordings of sales calls
            by voice or video chat Any other information you choose to directly
            provide to us in connection with your use of the Site or Services
          </p>
          <p>
            2. Grant of Rights. Subject to the terms and conditions of this
            Agreement (including the applicable Order Form), Provider hereby
            grants to Customer a limited, royalty-free, non-exclusive,
            non-sublicensable, non-transferable (except for permitted
            assignments as hereinafter described) right to access and use the
            Service in accordance with the Documentation (as defined in Section
            7.1), subject to the purchased quota (as further described in the
            Order Form’s pricing tiers) and solely for Customer’s internal
            business purposes during the Subscription Term. Customer may use
            Provider’s community platform to host an online community for its
            own customers, but Provider’s community platform can’t be sold as a
            service by the Customer to other customers. All rights not expressly
            granted to Customer are reserved by Provider and its licensors.
            There are no implied rights.
          </p>
          <p>
            3. Eligibility Requirements. By entering into this Agreement,
            Customer represents and warrants that Customer meets the following
            minimum requirements (“Eligibility Requirements”): (a) Customer has
            the necessary rights and authority to enter into and perform the
            obligations required of Customer under this Agreement; (b) the
            Customer Data (as defined herein) is and will remain within
            Customer’s right to use, accurate, complete and current; (c)
            Customer’s use of the Service will comply with all applicable laws
            and regulations; and (d) Customer will not infringe the rights of
            any third party (including without limitation any intellectual
            property rights or privacy rights) in its use of the Service.
          </p>
          <p>
            4. Restrictions. Customer shall not (and shall not allow any third
            party to): (a) permit any third party to access or use the Service
            except as envisioned by the Service in its normal operation or
            specified herein; (b) alter, modify, debug, reverse engineer,
            decompile, disassemble, or otherwise attempt to derive or gain
            access to any software (including source code) associated with the
            Service; (c) use any unauthorized robot, spider, scraper or other
            automated means to access the Service, or engage in any scraping,
            data-mining, harvesting, data aggregating or indexing of the Service
            except to have copy of Customer Data and End User Data (d)frame or
            mirror any content forming part of the Service; or (e) access the
            Service in order to (f) build a competitive product or service, or
            (ii) copy any ideas, features, functions or graphics of the Service.
            Customer shall keep all passwords and API keys provided to it safe
            and secure, and shall be responsible for all use of the Service
            using passwords or API keys issued to Customer. Customer shall
            notify Provider immediately of any actual or suspected unauthorized
            use of its passwords or API keys for the Service. Without limiting
            any of its other rights or remedies, Provider reserves the right to
            suspend access to the Service if Provider reasonably believes that
            Customer has materially violated the restrictions and obligations in
            this Agreement after providing Customer written notice remained
            ineffective within 15 days.
          </p>
          <p>
            The Service may not be accessed or used by any national or resident
            of a country embargoed by the United Stated or Canada including
            countries sanctioned by the Office of Foreign Assets Control (OFAC)
            or the Financial Action Task Force (FATF). By accessing or using the
            Service, Customer is representing and warranting that Customer is
            not located in, under the control of, or are a national or resident
            of any country to which the use of the Service would be prohibited
            by the laws of Canada or the United States.
          </p>
          <p>
            5. Important note on Minors.  The Website and Services are not
            intended for use by children and Provider reserves the right to
            terminate access to and use of the Services without notice to any
            User we have reason to believe is a minor. {' '}
          </p>
          <p>
            6. Customer Cooperation. Customer shall: (a) reasonably cooperate
            with Provider in all matters relating to the Service; (b) respond
            promptly to any Provider request to provide information, approvals,
            authorizations or decisions that are reasonably necessary for
            Provider to provide the Service in accordance with this Agreement;
            and (c) provide such Customer materials or information as Provider
            may reasonably request to provide the Service and ensure that such
            materials or information are complete and accurate in all material
            respects.
          </p>
          <h3 className={classes.subHeader}>2. Provider Technology.</h3>
          <p>
            In connection with providing the Service, Provider and its licensors
            shall operate and support the hosted environment used by Provider to
            provide the Service, including the Provider Technology (as defined
            below), the server hardware, disk storage, firewall protection,
            server operating systems, management programs, web server programs,
            documentation and all other technology or information so used by
            Provider. As used herein, “Provider Technology” means all of
            Provider’s proprietary technology (including software, hardware,
            products, processes, algorithms, user interfaces, know-how,
            techniques, designs and other tangible or intangible technical
            material or information) made available to Customer by Provider in
            providing the Service, including any and all updates, modifications,
            improvements and derivatives thereto and thereof.
          </p>
          <h3 className={classes.subHeader}>
            Information We Collect From Others
          </h3>
          <p>
            From time to time, we may collect information about you from other
            sources, including our affiliates, marketing vendors, and
            third-party databases. We use this information to supplement the
            information that we collect directly from you and for any of the
            purposes described in the next section. When you "like" or "follow"
            us on Facebook, LinkedIn, Twitter or other social media sites, we
            may collect some information from you including your name, email
            address, and any comments or content you post relevant to us.
          </p>
          <h3 className={classes.subHeader}>3. Ownership. </h3>
          <p>
            Provider acknowledges and agrees that as between Customer and
            Provider, all right, title and interest in and to the Customer Data
            and End User Data are and shall remain owned by Customer or its
            licensors, and this Agreement in no way conveys any right, title or
            interest in the Customer Data or End User Data other than a limited
            right to use the Customer Data and End User Data in accordance with
            the terms and conditions herein. No right or license is granted
            hereunder to Customer under any trademarks, service marks, trade
            names or logos. Customer shall not remove any Provider trademark,
            service mark or logo, or any proprietary notices or labels
            (including any copyright or trademark notices) from the Service.
            Customer acknowledges and agrees that, as between Provider and
            Customer, all right, title and interest in and to the Service
            (including the data, information, text, images, designs, sound,
            music, marks, logos, compilations (meaning the collection,
            arrangement and assembly of information other than Customer Data and
            End User Data) and other content on or made available through the
            Service, other than Customer Data, the End User Data and the
            Customer’s copyrights elements including logo or graphic charter),
            the Provider Technology and all improvements and derivatives of the
            foregoing (including all intellectual property and proprietary
            rights embodied therein or associated therewith) are and shall
            remain owned by Provider or its licensors, and this Agreement in no
            way conveys any right, title or interest in the Service or the
            Provider Technology other than a limited right to use the Service in
            accordance with this Agreement.
          </p>
          <h3 className={classes.subHeader}>4. Fees; Payments; Taxes.</h3>
          <p>
            1. Fees. Customer shall pay Provider the monthly fees set forth in
            the Order Form and make such payment in accordance with the
            instructions and schedule set forth in the Order Form. If the Order
            Form does not specify payment terms, the payment terms shall be net
            45 days from receipt of invoice. Limited Services may be made
            available on a “free” basis, in which event no fees shall be
            payable.
          </p>
          <p>
            2. Increases. Provider reserves the right to increase its fees upon
            at least 90 days’ advance notice (e-mail or otherwise) to Customer;
            provided, however, that fee increases will not take effect until the
            start of the next Subscription Term.
          </p>
          <p>
            3. Taxes. All amounts due hereunder are exclusive of all sales, use,
            excise, service, value added, or other taxes, duties and charges of
            any kind (whether foreign, federal, state, local or other)
            associated with this Agreement, the Service, or Customer’s access to
            the Service. Customer shall be solely responsible for all such
            taxes, duties and charges (except for taxes imposed on Provider’s
            income), which may be invoiced by Provider from time-to-time.
          </p>
          <p>
            4. Late Payments. Customer shall pay interest on all late payments
            with 15 days written prior notice, at the lesser of (a) 1.5% per
            month or (b) the highest rate permissible under applicable law,
            calculated daily and compounded monthly. Customer shall reimburse
            Provider for all costs and expenses, including attorneys’ fees,
            incurred in collecting any unpaid amounts owed by Customer
            hereunder.
          </p>
          <h3 className={classes.subHeader}>5. Term & Termination.</h3>
          <p>
            Term, Termination and Automatic Renewal. Customer’s initial
            subscription term for the Service commences on the date of
            acceptance of the applicable Order Form by Provider and, unless
            sooner terminated as set forth herein, will continue for the period
            specified in the Order Form (the “Initial Subscription Term” or the
            “Subscription Term”). Notices to Provider shall be sent
            to legal@Provider.so.
          </p>
          <p>
            Termination for Breach. Either Party may terminate this Agreement by
            written notice thereof to the other Party, if the other Party
            materially breaches this Agreement and, where such breach is
            curable, has not cured such breach within 30 days’ receipt of
            written notice thereof.
          </p>
          <p>
            Termination for convenience. Where the Service is provided to
            Customer on a “free” basis, Provider reserves the right to terminate
            this Agreement at any time, with or without notice to Customer.
          </p>
          <p>
            Effects of Subscription Termination; Survival. Upon any termination
            of this Agreement: (a) all rights granted to Customer hereunder
            shall terminate and Provider shall no longer provide access to the
            Service to Customer, (b) Customer shall cease using the Service, and
            (c) Provider shall delete all Customer Data. Any obligations that
            have accrued prior to termination shall survive termination of this
            Agreement. In addition, the following Sections, as well as any other
            provisions herein which by their nature should survive, shall
            survive termination of this Agreement: Sections 4-10.
          </p>
          <h3 className={classes.subHeader}>
            6. Customer Data and End User Data.
          </h3>
          <p>
            Data Generally. All account and billing information, and all data
            and information which the Customer, or its End Users (being its
            employees and any other person who it permits to use its instance of
            the Service) inputs into the Service (collectively, “Customer Data”)
            will not be used by Provider except as permitted herein. Provider
            agrees to protect Customer Data and End User Data with no less than
            industry-standard information security tools and procedures.
            Customer hereby grants to Provider a limited, non- exclusive,
            non-transferable, royalty-free right to use, reproduce, manipulate,
            and display the Customer Data solely in connection with providing
            the Service to Customer. Provider may analyze Customer Data, and
            data of other customers, to create aggregated and anonymized
            statistics or data that do not identify Customer or any individual,
            household, user, browser, or device and Provider may during and
            after the Subscription Term use and disclose such statistics or data
            in its discretion. Except as specified otherwise in this Agreement,
            Customer shall be solely responsible for providing, updating,
            uploading and maintaining all Customer Data. Provider shall operate
            the Service in a manner that provides reasonable information
            security for Customer Data and End User Data, using commercially
            reasonable data backup, security, and recovery protections.
            [Personal data included in Customer Data and End User Data will only
            be processed in accordance with Provider’s Data Processing Agreement
            attached as Exhibit A (“Data Processing Agreement”).]
          </p>
          <p>
            Additional Customer Responsibilities. Customer is solely responsible
            for all Customer Data that he has himself uploaded on the Provider’s
            platform excluding the content uploaded by the end users of the
            Customer. Provider does not guarantee the accuracy, integrity or
            quality of Customer Data. Customer shall not upload or otherwise
            make available to Provider any Customer Data that: (a) constitutes
            an infringement, misappropriation, or violation of all intellectual
            property rights, proprietary rights, rights of publicity, rights of
            privacy, and any and all other legal rights protecting data,
            information, or intangible property throughout the world, including,
            without limitation, any and all copyrights, trademarks, service
            marks, trade secrets, patent rights, moral rights, sui generis
            rights in databases, and contract rights; (b) violates this
            Agreement, including, without limitation, the Privacy Policy or any
            applicable laws, rules, or regulations; (c) is unlawful, harassing,
            abusive, tortious, threatening, harmful, invasive of another’s
            privacy, vulgar, defamatory, false, intentionally misleading, trade
            libelous, pornographic, sexually explicit, obscene, patently
            offensive, promotes racism, bigotry, hatred, or physical harm of any
            kind against any group or individual, promotes illegal activities or
            contributes to the creation of weapons, illegal materials, or is
            otherwise objectionable or illegal in any way; (d) contains software
            viruses or any other computer code, files or programs designed to
            interrupt, destroy, limit the functionality of any computer software
            or hardware or telecommunications equipment; (e) interferes with or
            disrupts the Service or servers or networks connected to the
            Service; (f) constitutes protected health information subject to the
            Health Insurance Portability and Accountability Act (HIPAA) or any
            regulation, rule or standards issued thereunder, or constitutes
            similarly protected sensitive personal information under any
            applicable state, rule or regulation; (g) is harmful to minors in
            any way; (h) constitutes unsolicited or unauthorized advertising,
            promotional materials, junk mail, spam, chain letters, pyramid
            schemes, or any other form of duplicative or unsolicited messages,
            whether commercial or otherwise; or (i) causes Provider to violate
            any applicable law, rule or regulation, including those regarding
            the export of technical data. Provider reserves the right, but has
            no obligation, to review any Customer Data, investigate any claim
            related to Customer Data, or take appropriate action, in its sole
            discretion, against the person (Customer or end users of the
            Customer) at the origin of the content that creates any liability
            for Provider . Such actions may include removing, exercising any
            indemnity and termination rights contained herein, and reporting
            such Customer Data to law enforcement authorities.
          </p>
          <p>
            End User Data. All data collected through the Service from
            Customer’s End Users (“End User Data”) shall be used, stored and
            processed in accordance with the Provider privacy policy as set out
            at **.com/privacy-policy. For the avoidance of doubt, Customer Data
            includes End User Data.
          </p>
          <h3 className={classes.subHeader}>7. Warranty Disclaimer.</h3>
          <p>
            1. Disclaimer. THE SERVICES ARE PROVIDED ON AN “AS –IS” BASIS, AND,
            PROVIDER MAKES NO REPRESENTATION OR WARRANTY WHATSOEVER, AND HEREBY
            DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE
            SERVICE (IN EACH CASE WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF
            DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE),
            INCLUDING ANY WARRANTY (A) OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, OR NON-INFRINGEMENT (subject to the provisions
            of the article 9), (B) THAT THE SERVICE WILL MEET CUSTOMER’S
            REQUIREMENTS, WILL ALWAYS BE AVAILABLE , ACCESSIBLE, UNINTERRUPTED),
            TIMELY, OR OPERATE WITHOUT ERROR, (C) AS TO THE RESULTS THAT MAY BE
            OBTAINED FROM THE USE OF THE SERVICE, OR (D) AS TO THE ACCURACY OR
            RELIABILITY OF ANY INFORMATION OBTAINED FROM THE SERVICE.
          </p>
          <p>
            2. Additional Disclaimer. CUSTOMER ACKNOWLEDGES THAT THE SERVICE IS
            HOSTED BY A THIRD PARTY HOSTING PROVIDER (THE “HOSTING CONTRACTOR”)
            AND USES THIRD PARTY SERVER HARDWARE, DISK STORAGE, FIREWALL
            PROTECTION, SERVER OPERATING SYSTEMS, MANAGEMENT PROGRAMS, WEB
            SERVER PROGRAMS FOR DELIVERY OF THE SERVICE (THE “HOSTING CONTRACTOR
            SERVICES”). ADDITIONALLY, Provider USES THIRD PARTIES TO HELP
            RECEIVE PAYMENTS (“PAYMENT PROCESSOR”). PROVIDER MAY CHANGE ITS
            HOSTING CONTRACTOR AND PAYMENT PROCESSOR AT ANY TIME. CUSTOMER’S USE
            OF THE SERVICE IS SUBJECT TO ANY RESTRICTIONS IMPOSED BY THE HOSTING
            CONTRACTOR AND THE PAYMENT PROCESSOR, AS APPLICABLE. NOTWITHSTANDING
            ANY OTHER PROVISION OF THIS AGREEMENT, PROVIDER SHALL NOT BE LIABLE
            FOR ANY PROBLEMS, FAILURES, DEFECTS OR ERRORS WITH THE SERVICE TO
            THE EXTENT CAUSED BY THE HOSTING CONTRACTOR OR PAYMENT PROCESSOR.
            CUSTOMER ACKNOWLEDGES THAT THE FEES PAYABLE FOR THE SERVICE REFLECT
            THE FACT THAT PROVIDER IS NOT RESPONSIBLE FOR THE ACTS AND OMISSIONS
            OF THE HOSTING CONTRACTOR OR PAYMENT PROCESSOR, AND THAT PROVIDER
            COULD NOT AFFORD TO PROVIDE THE SERVICE AT THE PRICES OFFERED IF IT
            WERE RESPONSIBLE FOR THE ACTS OR OMISSIONS OF THE HOSTING CONTRACTOR
            OR PAYMENT PROCESSOR.
          </p>
          <h3 className={classes.subHeader}>8. Limitations of Liability.</h3>
          <p>
            1. Disclaimer of Indirect Damages. EXCEPT FOR (A) CUSTOMER’S
            OBLIGATION TO PAY ALL AMOUNTS DUE HEREUNDER; (B) ITS INDEMNIFICATION
            OBLIGATIONS; AND (C) ITS BREACH OF ANY INTELLECTUAL PROPERTY OR
            CONFIDENTIALITY OBLIGATIONS OR RESTRICTIONS HEREIN (INCLUDING ANY
            LIMITATIONS OR RESTRICTIONS ON USE OF THE SERVICE), IN NO EVENT
            SHALL EITHER PARTY BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
            INCIDENTAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING LOSS
            OF DATA, PROFITS OR REVENUE) ARISING OUT OF OR RELATED TO THE
            SERVICE OR THIS AGREEMENT, WHETHER SUCH DAMAGES ARISE IN CONTRACT,
            TORT (INCLUDING NEGLIGENCE) OR OTHERWISE.
          </p>
          <p>
            2. Damages Cap. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, PROVIDER’S
            TOTAL LIABILITY FOR ALL DAMAGES ARISING OUT OF OR RELATED TO THE
            SERVICE OR THIS AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING
            NEGLIGENCE) OR OTHERWISE, SHALL NOT EXCEED THE TOTAL AMOUNT OF FEES
            PAID BY CUSTOMER TO PROVIDER DURING THE THEN-CURRENT SUBSCRIPTION
            TERM WHATEVER THE MOMENT OF THE THEN-CURRENT PERIOD (EXCLUDING
            NON-INFRINGEMENT AND CLAIM RELATED TO DATA PROTECTION).
          </p>
          <p>
            3. Basis of the Bargain. THE PARTIES AGREE THAT THE LIMITATIONS OF
            LIABILITY SET FORTH IN THIS SECTION 8 ARE A FUNDAMENTAL BASIS OF THE
            BARGAIN, THAT PROVIDER HAS SET ITS FEES IN RELIANCE ON THE
            ENFORCEABILITY OF THESE PROVISIONS, AND THAT THEY SHALL APPLY
            NOTWITHSTANDING THAT ANY REMEDY SHALL FAIL ITS ESSENTIAL PURPOSE.
          </p>
          <h3 className={classes.subHeader}>9. Indemnification.</h3>
          <p>
            1. Provider Indemnification. Provider shall defend, indemnify and
            hold harmless Customer and its directors, officers, and employees
            (“Customer Indemnified Parties”) from and against any third-party
            claims, actions, proceedings, demands, lawsuits, damages,
            liabilities and expenses (including reasonable attorneys’ fees and
            court costs)(collectively, “Claims”) to the extent the Service
            infringes, misappropriates or otherwise violates (collectively,
            “Infringes”) any third party intellectual property or proprietary
            right.
          </p>
          <p>
            2. Customer Indemnification. Customer shall defend, indemnify and
            hold harmless Provider and its directors, officers, employees,
            agents and providers (“Provider Indemnified Parties”) from and
            against any Claims based on the Customer Data effectively uploaded
            by the Customer, excluding any such Claim to the extent resulting
            from Provider’s use of the Customer Data in violation of this
            Agreement.
          </p>
          <p>
            3. Indemnification Process. As conditions of the indemnification
            obligations in Sections 9.1-9.2 above: (a) the applicable Customer
            Indemnified Party or Provider Indemnified Party (the “Indemnitee”)
            will provide the indemnifying Party (the “Indemnitor”) with prompt
            written notice of any Claim for which indemnification is sought
            (provided that failure to so notify will not remove the Indemnitor’s
            indemnification obligations except to the extent it is prejudiced
            thereby), (b) the Indemnitee will permit the Indemnitor to control
            the defense and settlement of such Claim, and (c) the Indemnitee
            will reasonably cooperate with the Indemnitor in connection with the
            Indemnitor’s evaluation, defense and settlement of such Claim. In
            defending any Claim, the Indemnitor shall use counsel reasonably
            satisfactory to the other Party. The Indemnitor shall not settle or
            compromise any such Claim or consent to the entry of any judgment
            without the prior written consent of the other Party (not
            unreasonably withheld).
          </p>
          <p>
            4. Exclusions. Provider’s obligations in Section 9.1 above shall not
            apply to any Claim to the extent arising from or relating to (a)
            misuse of the Service not strictly in accordance with the
            documentation therefor, Provider’s instructions, and this Agreement;
            (b) any modification, alteration or conversion of the Service not
            created or approved in writing by Provider; (c) any combination of
            the Service with any computer, hardware, software or service not
            provided by Provider; (d) Provider’s compliance with specifications
            or other requirements of Customer; or (e) any third party data or
            Customer Data. If the Service is or may be subject to a Claim of
            Infringement described in Section 9.1 above, Provider may, at its
            cost and sole discretion: (i) obtain the right for Customer to
            continue using the Service as contemplated herein; (ii) replace or
            modify the Service so that it becomes non-Infringing without
            substantially compromising its principal functions; or (iii) to the
            extent the foregoing are not commercially reasonable, terminate this
            Agreement and return to Customer any pre-paid fees for the Service
            associated with the then-current Subscription Term. Provider’s
            obligations in this Section 9 shall be Provider’s sole obligations,
            and Customer’s sole remedies, in the event of any Infringement of
            intellectual property or proprietary rights by or related to the
            Service.
          </p>
          <h3 className={classes.subHeader}>10. Confidentiality.</h3>
          <p>
            1. Definitions. “Confidential Information” means information that is
            disclosed by either Party (the “Disclosing Party”) to the other
            Party (the “Receiving Party”) hereunder during the Subscription Term
            that is clearly labeled or identified as confidential or proprietary
            when disclosed, or that, under the circumstances, should reasonably
            be treated as confidential, including without limitation information
            (tangible or intangible) regarding a Party’s technology, designs,
            techniques, research, know-how, specifications, product plans,
            pricing, customer information, user data, current or future
            strategic information, current or future business plans, policies or
            practices, employee information, and other business and technical
            information. “Confidential Information” shall not include any
            information that (a) is or becomes generally known to the public
            through no fault of, or breach of this Agreement by, the Receiving
            Party; (b) is rightfully in the Receiving Party’s possession at the
            time of disclosure without an obligation of confidentiality; (c) is
            independently developed by the Receiving Party without use of the
            Disclosing Party’s Confidential Information; or (d) is rightfully
            obtained by the Receiving Party from a third party without
            restriction on use or disclosure. In addition, (i) the terms and
            conditions of this Agreement shall be deemed to be Confidential
            Information of both Parties; (ii) the Service and Provider
            Technology shall be deemed Confidential Information of Provider,
            regardless of whether or not they are labeled or identified, or
            would reasonably be considered confidential; and (iii) Customer Data
            shall be deemed Confidential Information of Customer.
          </p>
          <p>
            2. General Obligations. Each Party agrees that it will during the
            Subscription Term and thereafter a period of five (5) years (a) not
            disclose the other Party’s Confidential Information to any third
            party (other than as permitted in the last sentence of this
            paragraph); (b) use the other Party’s Confidential Information only
            to the extent reasonably necessary to perform its obligations or
            exercise its rights under this Agreement; (c) disclose the other
            Party’s Confidential Information only to those of its employees and
            independent contractors who reasonably need to know such information
            for purposes of this Agreement and who are bound by confidentiality
            obligations offering substantially similar protection to those in
            this Section 10; and (d) protect all Confidential Information of the
            other Party from unauthorized use, access, or disclosure in the same
            manner as it protects its own confidential information of a similar
            nature, and in no event with less than reasonable care.
            Notwithstanding the above, this paragraph shall not prohibit: (i) a
            Party from disclosing Confidential Information of the other Party to
            the extent required by applicable law, rule or regulation (including
            a court order or other government order); provided that such Party
            provides the other Party prior written notice of such disclosure, to
            the extent practicable, and reasonably cooperates with efforts of
            the other Party to seek confidential treatment thereof, to the
            extent such cooperation is requested by the other Party; or (ii) a
            Party from disclosing the terms and conditions of this Agreement to
            its attorneys and financial advisors, or current or potential
            lenders, other sources of financing, investors or acquirers;
            provided that such third parties are bound by confidentiality
            obligations offering substantially similar protection to those in
            this Section 10 (provided further that such third parties are only
            permitted to use such information for the purpose of advising,
            lending or providing financing to, or investing in or acquiring,
            such Party, as applicable).
          </p>
          <p>
            3. Return or Destruction. Except as otherwise expressly provided in
            this Agreement, the Receiving Party will return to the Disclosing
            Party, or destroy or erase, the Disclosing Party’s Confidential
            Information in tangible form, upon the termination of this
            Agreement; provided that (a) Receiving Party may retain a copy of
            Disclosing Party’s Confidential Information solely for the purposes
            of tracking Receiving Party’s rights and obligations hereunder with
            respect thereto, (b) Receiving Party may retain copies of Disclosing
            Party’s Confidential Information solely to the extent required by
            law or by applicable professional standards which require such Party
            to retain copies of its working papers, and (c) Receiving Party may
            retain Disclosing Party’s Confidential Information solely to the
            extent reasonably necessary for Receiving Party to exercise rights
            or perform obligations under this Agreement that survive such
            termination.
          </p>
          <p>
            4. Feedback. Notwithstanding the above or anything to the contrary
            herein, to the extent that Customer at any time provides Provider
            with any feedback or suggestions regarding the Service, including
            potential improvements or changes thereto (collectively,
            “Feedback”), the Feedback shall not be considered Confidential
            Information of Customer, and Provider may use, disclose and exploit
            the Feedback in any manner it chooses without any obligation to
            Customer. All Feedback provided by Customer is provided “AS IS” and
            without warranty or representation of any kind.
          </p>
          <h3 className={classes.subHeader}>11. Miscellaneous.</h3>
          <p>
            1. Assignment. This Agreement and any rights or obligations
            hereunder may not be assigned, sublicensed or otherwise transferred
            by either Party without the prior written consent of the other Party
            (not to be unreasonably withheld or delayed), except that either
            Party may assign or transfer this Agreement without the other
            Party’s consent (i) to any of its affiliates, or (b) upon a change
            of control of a party, a sale of all or substantially all assets of
            that party, or by operation of law, in each case, by providing the
            non-assigning party with prior written notice thereof provided that
            the assignee agrees in writing to be bound by all terms and
            conditions of this Agreement. Customer understands and agrees that,
            in the event its assignment of the Agreement results in a material
            change in Service usage, Provider may require a renegotiation of
            Service pricing.
          </p>
          <p>
            2. Entire Agreement; Precedence; Amendment. This Agreement
            (including the Order Form) contains the complete understanding and
            agreement of the Parties with respect to the subject matter hereof,
            and supersedes all prior or contemporaneous agreements or
            understandings, oral or written, with respect thereto. In the event
            of conflict between the terms of this Agreement and the Order Form,
            the Order Form will control and take precedence only with respect to
            pricing, payment and other such commercial matters that do not
            affect the Parties’ fundamental responsibilities and risk
            allocations herein. Each Party hereby rejects the inclusion of any
            pre-printed terms and conditions on any purchase orders or other
            such contract documents. All amendments must be in signed writing.
          </p>
          <p>
            3. Notices. Provider may give any notices issued in connection with
            this Agreement by email to Customer at the email address given by
            Customer when creating its account, and such notices shall be
            effective upon confirmation of transmission to Customer.
          </p>
          <p>
            4. Force Majeure. Neither Party will be liable to the other Party
            for any failure or delay in performance by circumstances beyond its
            control, including, fire, labor difficulties, telecommunication
            failures, Internet unavailability, governmental actions or
            terrorism, provided that the Party seeking to rely on such
            circumstances gives written notice of such circumstances to the
            other Party and uses reasonable efforts to overcome such
            circumstances.
          </p>
          <p>
            5. Choice of Law. Any question, claim or controversy arising out of
            or related to this Agreement (a “Dispute”) will be governed by and
            construed in accordance with the laws of the Province of British
            Columbia, without giving effect to any conflicts of laws provision
            thereof or of any other jurisdiction that would produce a contrary
            result.
          </p>
          <p>
            6. Disputes. In the event of any Dispute, the designated
            representatives of Customer and Provider shall promptly confer and
            exert their good faith efforts to reach a reasonable and equitable
            resolution of such Dispute. Ifsuch representatives are unable to
            resolve such Dispute within five business days, the Dispute shall be
            referred promptly to the responsible senior management of each Party
            for resolution. Neither Party shall seek any other means of
            resolving any Dispute until both Parties’ responsible senior
            management have had at least five business days to resolve the
            Dispute. If the Parties are unable to resolve the Dispute in
            accordance with the foregoing procedure, then either Party may, at
            any time, deliver notice to the other Party of its intent to submit
            the Dispute to the Court of the Province of British Columbia, Canada
            [, except for provision relating to personal data of the Agreement
            and the Data Processing Agreement which will be submitted to the
            Court of British Columbia, Canada].
          </p>
          <p>
            7. Claims of Infringement. Provider respects Customer’s copyrights
            and other intellectual property rights and those of other third
            parties. If Customer believes in good faith that Customer’s
            copyrighted work has been reproduced on the Service without
            Customer’s authorization in a way that constitutes copyright
            infringement, Customer may notify our designated copyright agent by
            mail to: fnchart, Inc., Attn: INFRINGEMENT
            / info@fnchart.com. Please provide the following information to
            Provider’s Copyright Agent: (a) the identity of the infringed work,
            and of the allegedly infringing work; (b) Customer’s name, address,
            daytime phone number, and email address, if available; (c) a
            statement that Customer has a good-faith belief that the use of the
            copyrighted work is not authorized by the owner, his or her agent,
            or the law; (d) a statement that the information in the notification
            is accurate and, under penalty of perjury, that Customer is
            authorized to act on behalf of the owner; and (e) Customer’s
            electronic or physical signature. Provider will take whatever
            action, in its sole discretion, it deems appropriate, including the
            removing the allegedly infringing work from the Service.
          </p>
          <p>
            8. Relationship of the Parties. The relationship between the Parties
            is that of independent contractors. Nothing contained in this
            Agreement shall be construed as creating any agency, partnership,
            joint venture or other form of joint enterprise or employment
            relationship between the Parties, and neither Party shall have
            authority to contract for or bind the other Party in any manner
            whatsoever.
          </p>
          <p>
            9. Waiver. No waiver by either Party of any of the provision of this
            Agreement is effective unless explicitly set forth in writing and
            signed by such Party. No failure to exercise, or delay in
            exercising, any right, remedy, power or privilege arising from this
            Agreement operates, or may be construed, as a waiver thereof. No
            single or partial exercise of any right, remedy, power or privilege
            hereunder precludes any other or further exercise thereof or the
            exercise of any other right, remedy, power or privilege.
          </p>
          <p>
            10. Severability. If any provision of this Agreement is held to be
            unenforceable or illegal by a court or tribunal of competent
            jurisdiction, such provision will be modified to the extent
            necessary to render it enforceable, or will be severed from this
            Agreement, and all other provisions of this Agreement will remain in
            full force and effect.
          </p>
          <p>
            11. Interpretation. Headings are provided for convenience only and
            will not be used to interpret the substance of this Agreement.
            Unless the intent is expressly otherwise in specific instances, use
            of the words “include,” “includes,” or “including” in this Agreement
            shall not be limiting and “or” shall not be exclusive.
          </p>
          <p>
            12. Additional Agreements and Disclosures. Additional agreements
            and/or disclosures may apply with respect to your access to and use
            of the Service generally or certain products and services that may
            be accessed via the Service. These agreements and/or disclosures
            include without limitation the agreements and disclosures located at
            &nbsp;
            <Link href={`/disclosures`} sx={{ color: '#4385F4' }}>
              {props.domainUrl}/disclosures
            </Link>
            . The terms of such agreements and/or disclosures are incorporated
            into these Terms by reference, to the extent such terms do not
            conflict with these Terms. In the event of a conflict, the terms of
            such separate user agreement and/or disclosure will prevail with
            respect to such specific products or services. Your access to the
            Service may also be subject to terms of use, privacy and other
            agreements required by third party providers in connection with use
            of your device, telephone, wireless and other services.
          </p>

          <h3 className={classes.subHeader}>12. Your Content.</h3>
          <p>
            The Services may contain information, text, links, graphics, photos,
            videos, audio, streams, or other materials (“Content”), including
            Content created with or submitted to the Services by you or through
            your Account (“Your Content”). We take no responsibility for and we
            do not expressly or implicitly endorse, support, or guarantee the
            completeness, truthfulness, accuracy, or reliability of any of Your
            Content.
          </p>
          <p>
            By submitting Your Content to the Services, you represent and
            warrant that you have all rights, power, and authority necessary to
            grant the rights to Your Content contained within these Terms.
            Because you alone are responsible for Your Content, you may expose
            yourself to liability if you post or share Content without all
            necessary rights.
          </p>
          <p>
            You retain any ownership rights you have in Your Content, but you
            grant Provider the following license to use that Content:
          </p>
          <p>
            When Your Content is created with or submitted to the Services, you
            grant us a worldwide, royalty-free, perpetual, irrevocable,
            non-exclusive, transferable, and sublicensable license to use, copy,
            modify, adapt, prepare derivative works of, distribute, store,
            perform, and display Your Content and any name, username, voice, or
            likeness provided in connection with Your Content in all media
            formats and channels now known or later developed anywhere in the
            world. This license includes the right for us to make Your Content
            available for syndication, broadcast, distribution, or publication
            by other companies, organizations, or individuals who partner with
            Reddit. You also agree that we may remove metadata associated with
            Your Content, and you irrevocably waive any claims and assertions of
            moral rights or attribution with respect to Your Content.
          </p>
          <p>
            Any ideas, suggestions, and feedback about the Provider or our
            Services that you provide to us are entirely voluntary, and you
            agree that the Providor may use such ideas, suggestions, and
            feedback without compensation or obligation to you.
          </p>
          <p>
            Although we have no obligation to screen, edit, or monitor Your
            Content, we may, in our sole discretion, delete or remove Your
            Content at any time and for any reason, including for violating
            these Terms, violating our Rules, or if you otherwise create or are
            likely to create liability for us.
          </p>
          <h3 className={classes.subHeader}>13. Changes to these Terms.</h3>
          <p>
            We may make changes to these Terms from time to time. If we make
            changes, we will post the revised Terms and update the Effective
            Date above. If the changes, in our sole discretion, are material, we
            may also notify you by sending an email to the address associated
            with your Account (if you have chosen to provide an email address)
            or by otherwise providing you with notice through our Services. By
            continuing to access or use the Services on or after the Effective
            Date of the revised Terms, you agree to be bound by the revised
            Terms. If you do not agree to the revised Terms, you must stop
            accessing and using our Services before the changes become
            effective.
          </p>
          <h3>14. Your Account and Account Security.</h3>
          <p>
            To use certain features of our Services, you may be required to
            create am fnchart account (an “Account”) and provide us with a
            username, password, and certain other information about yourself as
            set forth in the Privacy Policy.
          </p>
          <p>
            You are solely responsible for the information associated with your
            Account and anything that happens related to your Account. You must
            maintain the security of your Account and immediately notify
            Provider if you discover or suspect that someone has accessed your
            Account without your permission. We recommend that you use a strong
            password that is used only with your Account.
          </p>
          <p>
            You will not license, sell, or transfer your Account without our
            prior written approval.
          </p>

          <br />

          <p>Market data provided by FINANCIAL MODELING PREP SAS.</p>
          <p>
            Please read important legal disclosures that apply to your brokerage
            account.
          </p>
          <p>
            Securities products are:
            <ul>
              <li>Not FDIC insured;</li>
              <li>Not bank guaranteed; and,</li>
              <li>May lose value.</li>
            </ul>
          </p>
          <p>© 2019 fnchart Inc All rights reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default TermsofService
