import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    fontFamily: fonts.dm_sans,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.drawer_bg_right,
    minHeight: '100%',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.search_bg,
    },
  },
  content: {
    width: 700,
    marginBottom: 20,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  title: {
    color: colors.white_faded,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '30px',
    textAlign: 'center',
    width: 300,
    paddingTop: 38,

    [theme.breakpoints.down('lg')]: {
      paddingTop: 32,
    },
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.textGrey,
    textAlign: 'center',
    width: 300,
    paddingTop: 24,

    [theme.breakpoints.down('lg')]: {
      paddingTop: 16,
    },
  },
  sendButton: {
    borderRadius: 0,
    marginLeft: 8,
    width: '81px',
    padding: '10px 0',
    marginBottom: 16,
  },
  messageSent: {
    color: colors.textGrey,
    fontSize: 12,
    lineHeight: '18px',
    paddingTop: 24,
  },
}))
