import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'

export default makeStyles({
  wrapper: {
    width: 391,
    borderRadius: 20,
  },
  postWithCommentWrapper: {
    padding: 16,
    backgroundColor: colors.drawer_bg_right,
    display: 'flex',
    flexDirection: 'column',
  },
  postBodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  postTitleLeft: {
    width: 'calc(100% - 48px)',
  },
  postTitleRight: {
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  postDate: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.textGreyLight,
  },
  postTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    color: colors.white_faded,
    marginTop: 8,
  },
  topicHeadlineSource: {
    color: colors.textGreyLight,
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    marginLeft: 8,
  },
  message: {
    lineHeight: '16px',
    color: colors.textGreyLight,
  },
  numberOfReplies: {
    display: 'flex',
    color: colors.textGreyLight,
    alignItems: 'center',
    lineHeight: '16px',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 8,
  },
  notesWrapper: {
    width: '100%',
    padding: 16,
    boxSizing: 'border-box',
    backgroundColor: colors.drawer_bg,
    borderRadius: 8,
    marginTop: 16,
  },
  notes: {
    color: colors.white_faded,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  iconWrapper: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  arrowsWrapper: {
    paddingLeft: 16,
    width: 22,
    height: 72,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  arrow: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    width: 15,
    height: 15,
    lineHeight: '20px',
    cursor: 'pointer',
    alignSelf: 'center',
  },
  votes: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    alignSelf: 'center',
  },
  chartWrapper: {
    width: '100%',
    height: 232,
    backgroundColor: colors.search_bg,
    borderBottom: `1px solid ${colors.drawer_border}`,
    padding: '13px 0',
  },
})
