import { forwardRef } from 'react'
import theme from '../../../../mui-theme'
import useStyles from './styles'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { FollowerData } from 'components/UserOverlay/interfaces/userTabItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines } from '@fortawesome/free-solid-svg-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import UserAvatar from 'components/UserAvatar'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'

export interface FollowerProps {
  currentUserId?: string
  followers: FollowerData[]
  hasMoreFollowers: boolean
  isViewingOwnProfile: boolean
  loadMoreFollowers: () => void
  onFollowUser: (userId: string) => void
  onUnfollowUser: (userId: string) => void
  setShowSignUpModal: (show: boolean) => void
}

export default forwardRef<HTMLInputElement, FollowerProps>((props, ref) => {
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  const classes = useStyles(theme)

  return (
    <div id="tab-content" className={classes.tabContent}>
      <InfiniteScroll
        dataLength={props.followers.length}
        className={classes.wrapper}
        next={() => {
          console.log('load more followed users')
          props.loadMoreFollowers()
        }}
        hasMore={props.hasMoreFollowers}
        loader={<div>Loading more followers...</div>}
        scrollableTarget="tab-content"
      >
        {props.followers.length === 0 && (
          <div className={classes.noUsersWrapper}>
            <FontAwesomeIcon
              icon={faFileLines}
              size="lg"
              className={classes.noUsersIcon}
            />
            <div className={classes.noUsersText}>
              hmm... looks like there are no followers yet
            </div>
          </div>
        )}
        {props.followers.map((user, index) => (
          <div
            key={index}
            className={classes.userWrapper}
            onClick={() => {
              navigate(
                ROUTES.USER.replace(':username', user.username!).toLowerCase()
              )
            }}
          >
            <div className={classes.avatarWrapper}>
              {(user.emailHash || user.email) && (
                <UserAvatar
                  emailHash={user.emailHash || user.email}
                  size={deviceType === DeviceType.Mobile ? 60 : 211}
                ></UserAvatar>
              )}
            </div>
            <div className={classes.userDetailsWrapper}>
              <div className={classes.usernameWrapper}>
                <div className={classes.username}>{user.username}</div>
                {deviceType === DeviceType.Desktop &&
                  user.isFollowerOfCurrentUser &&
                  !props.isViewingOwnProfile && (
                    <div className={classes.isFollowingYou}>
                      is following you
                    </div>
                  )}
              </div>
              <div className={classes.lineWrapper}>
                <div className={classes.followers}>
                  {user.followers} followers
                </div>
                {deviceType === DeviceType.Desktop && (
                  <div className={classes.following}>
                    {user.following} following
                  </div>
                )}
              </div>
            </div>
            {props.currentUserId !== user.userId && (
              <Button
                onClick={e => {
                  e.stopPropagation()

                  if (!props.currentUserId) {
                    props.setShowSignUpModal(true)
                    return
                  }

                  user.isCurrentUserFollowing
                    ? props.onUnfollowUser(user.userId)
                    : props.onFollowUser(user.userId)
                }}
                className={
                  user.isCurrentUserFollowing
                    ? classes.followingBtn
                    : classes.followBtn
                }
              >
                {user.isCurrentUserFollowing ? 'Following' : 'Follow'}
              </Button>
            )}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
})
