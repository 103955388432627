import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    width: 450,
    backgroundColor: colors.drawer_bg_right,

    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      left: 0,
      top: 0,
      minWidth: '100%',
      width: '100%',
      height: '100%',
    },
  },

  mobileTitle: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      padding: '22px 16px 12px 16px',
      justifyContent: 'space-between',
    },
  },

  signOutMobile: {
    [theme.breakpoints.down('lg')]: {
      color: colors.textGreyLight,
      fontFamily: fonts.dm_sans,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
  },

  signOutNameMobile: {
    [theme.breakpoints.down('lg')]: {
      marginRight: 8,
    },
  },

  tabs: {
    padding: '23px 32px 14px 32px',
    minHeight: 40,
    height: 40,

    [theme.breakpoints.down('lg')]: {
      height: 38,
      padding: '23px 32px 0 32px',
    },
  },

  tabWrapper: {
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },

  tabIcon: {
    paddingRight: 8,
  },

  tab: {
    fontSize: 12,
    fontWeight: 18,
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    minWidth: 64,
    padding: '6px 0px',
    marginRight: 16,
    textTransform: 'capitalize',

    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },

  selectedTab: {
    color: `${colors.text_white} !important`,

    '& svg': {
      color: colors.text_white,
    },
  },

  selectedTabIndicator: {
    backgroundColor: colors.blue_light,
    color: colors.text_white,
    bottom: 0,
  },

  profileDetails: {
    padding: '23px 32px 14px 32px',
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('lg')]: {
      padding: '23px 32px 0 32px',
    },
  },

  profileDetailsInfo: {
    display: 'flex',
    width: 'calc(100% - 40px)',
    flexDirection: 'column',
    marginLeft: 16,
    justifyContent: 'center',
  },

  signOut: {
    color: colors.textGrey,
    minWidth: 68,
    alignSelf: 'flex-end',
    marginBottom: 7,
    cursor: 'pointer',
  },

  signOutName: {
    fontFamily: fonts.dm_sans,
    marginLeft: 4,
    fontSize: 12,
  },

  avatarWrapper: {
    width: 46,
  },

  name: {
    color: colors.text_white,
    fontFamily: fonts.dm_sans,
    fontSize: 14,
    height: 18,
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      color: colors.white_faded,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
    },
  },

  email: {
    color: colors.textGrey,
    fontFamily: fonts.dm_sans,
    fontSize: 12,
    letterSpacing: 0,
    height: 16,
    textAlign: 'left',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      color: colors.textGreyLight,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
  },

  tabContent: {
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 195px)',
      // minHeight: 'calc(100% - 195px)',
    },
  },
}))
