import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    fontFamily: fonts.dm_sans,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.drawer_bg_right,
    minHeight: '100%',
  },
  content: {
    width: 700,
    marginBottom: 20,
  },
  header: {
    color: colors.text_white,
    fontSize: 18,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: 20,

    '&:first-of-type': {
      marginTop: 80,
    },
  },
  subHeader: {
    color: colors.text_white,
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: 16,
    marginTop: 20,
  },
  lastRevised: {
    color: colors.textGrey,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    paddingTop: 10,
    paddingBottom: 30,
  },
  bodyText: {
    color: colors.text_white,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,

    '& > p': {
      marginTop: 0,
      marginBottom: 25,
    },

    '& > p > a:link': {
      color: '#4385F4',
    },

    '& > p > a:visited': {
      color: 'rgba(67, 133, 244, 0.65)',
    },
  },
}))
