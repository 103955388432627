import Box from '@mui/material/Box'
import { CommentReplyBox } from 'components/TopicSidebar/CommentReply/CommentReplyBox'
import MarkdownViewer from 'components/inputs/MarkdownViewer'
import React, { useEffect, useRef } from 'react'
import { ExDate, colors, fonts } from '../../constants'
import theme from '../../mui-theme'
import { getTimeElapsedRounded } from '../../utils/dateTimeUtils'
import VoteUpDown, { VoteDirection } from '../TopicSidebar/VoteUpDown'
import useStyles from '../TopicSidebar/VoteUpDown/styles'
import ROUTES from 'constants/routes'
import { formatWithEllipsis } from 'utils/stringUtils'
import { useNavigate } from 'react-router-dom'
import { CommentReplyMobileBox } from 'components/TopicSidebar/CommentReplyMobile/CommentReplyMobileBox'
import UserAvatar from 'components/UserAvatar'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { CommentData } from 'components/common/CommentWithParent/Comment.interfaces'
import { CommentIcons } from 'components/CommentIcons/CommentIcons'
import { CommentIconsMobile } from 'components/CommentIconsMobile/CommentIconsMobile'

export interface Props {
  onCommentReplyClick: (id: string) => void
  onReply: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    state: {
      get: boolean
      set: Function
    },
    commentId?: string | undefined
  ) => Promise<any>
  onUpdateComment: (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    commentId: string,
    state: {
      get: boolean
      set: Function
    }
  ) => Promise<any>
  onTopicBookmarkClick: (id: string, commentId: string) => void
  onShareActionsClick: (
    id: string,
    action: string | number,
    commentText: string,
    commentId: string
  ) => void
  onCameraActionsClick: (
    commentWithParent: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (
    id: string,
    action: string | number,
    commentId: string,
    state: {
      get: boolean
      set: Function
    }
  ) => void
  onVote: (
    direction: VoteDirection,
    commentId: string,
    userVotePositive?: boolean | null
  ) => void
  userId: string | undefined
  replyNotificationEmail: boolean
}

export interface CommentProps extends Props {
  renderChildComments: (parentCommentId: string) => JSX.Element
  isCreator: boolean
  commentWithParent: CommentData
}

const Comment: React.FC<CommentProps> = props => {
  const deviceType = useDeviceType()
  const { commentWithParent, renderChildComments } = props
  const navigate = useNavigate()
  const commentRef = useRef<HTMLDivElement>(null)

  const [isChildCommentsExpanded, setIsChildCommentsExpanded] = React.useState(
    commentWithParent.numberOfReplies > 0
  )
  const [isReplyOpen, setIsReplyOpen] = React.useState<boolean>(false)
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false)

  const [initialText, setInitialText] = React.useState<string>('')
  const classes = useStyles(theme)

  /***************************************************************************************************************************
   *  Handle Edit Comment Via State
   ***************************************************************************************************************************/
  useEffect(() => {
    if (isEditOpen) {
      setInitialText(props.commentWithParent.content || '')
    } else {
      setInitialText('')
    }
  }, [isEditOpen, props.commentWithParent.content])

  useEffect(() => {
    setIsChildCommentsExpanded(commentWithParent.numberOfReplies > 0)
  }, [commentWithParent.numberOfReplies])

  const onChildCommentsExpand = () => {
    setIsChildCommentsExpanded(prev => !prev)
  }

  const onMoreActionsClick = (
    id: string,
    action: string | number,
    commentId: string
  ) => {
    return props.onMoreActionsClick(id, action, commentId, {
      get: isEditOpen,
      set: setIsEditOpen,
    })
  }

  const onCommentReplySubmit = (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    commentData: CommentData,
    state: {
      get: boolean
      set: Function
    }
  ) => {
    // TODO: if state is EDIT then we want to Update, not call onReply

    const result = props
      .onReply(text, symbols, replyNotificationEmail, state, commentData.id)
      .then(() => {
        setIsReplyOpen(false)
      })

    return result
  }

  const onCommentUpdateSubmit = (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    commentId: string,
    state: {
      get: boolean
      set: Function
    }
  ) => {
    // TODO: if state is EDIT then we want to Update, not call onReply

    const result = props
      .onUpdateComment(text, symbols, replyNotificationEmail, commentId, state)
      .then(() => {
        setIsEditOpen(false)
      })

    return result
  }

  return (
    <Box
      ref={commentRef}
      sx={{
        borderTop: `1px solid ${colors.border}`,
        backgroundColor:
          deviceType === DeviceType.Mobile
            ? colors.drawer_bg_right
            : colors.box_bg,
      }}
    >
      {commentWithParent.deleted ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: `1px solid ${colors.border}`,
          }}
        >
          <Box
            sx={{
              color: colors.textGrey,
              fontSize: '12px',
              padding: 2,
            }}
          >
            Comment deleted by user
          </Box>
          <Box
            sx={{
              width: 61,
              backgroundColor:
                deviceType === DeviceType.Mobile
                  ? colors.drawer_bg_right
                  : colors.drawer_bg_light,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          ></Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            borderBottom: `1px solid ${colors.border}`,
          }}
        >
          <Box
            sx={{
              backgroundColor:
                deviceType === DeviceType.Mobile
                  ? colors.drawer_bg_right
                  : colors.drawer_bg,
              padding: 2,
              flex: 1,
              width: 200, // fix for long strings within flex
            }}
          >
            <Box
              sx={{
                display: 'flex',
                pb: 1,
              }}
            >
              {' '}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: fonts.dm_sans,
                  fontSize: 14,
                  color: colors.blue_light,
                  textDecoration: 'inherit',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(
                    ROUTES.USER.replace(
                      ':username',
                      commentWithParent!.username!
                    ).toLowerCase()
                  )
                }}
              >
                <Box sx={{ width: 32, height: 32, marginRight: 1 }}>
                  {commentWithParent!.emailHash && (
                    <UserAvatar emailHash={commentWithParent!.emailHash} />
                  )}
                </Box>
                {formatWithEllipsis(commentWithParent!.username ?? '', 20)}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: fonts.dm_sans,
                  fontSize: 11,
                  pl: 1,
                  color: colors.textGrey,
                }}
              >
                {getTimeElapsedRounded(
                  new ExDate(commentWithParent!.createdAt),
                  new Date(commentWithParent!.createdAt)
                )}
              </Box>
            </Box>
            <Box
              sx={{
                color: colors.text_white,
                fontFamily: fonts.dm_sans,
                fontSize: 13,
                lineHeight: 1.5,
                pb: 2,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
              }}
            >
              <MarkdownViewer
                markdownSource={commentWithParent.content}
                isPreview={false}
              ></MarkdownViewer>
            </Box>
            <Box>
              {deviceType === DeviceType.Desktop && (
                <CommentIcons
                  comment={props.commentWithParent}
                  numberOfReplies={commentWithParent.numberOfReplies}
                  onTopicReplyClick={(id: string) => {
                    props.onCommentReplyClick(id)

                    if (props.userId) {
                      setIsReplyOpen(!isReplyOpen)
                    }
                  }}
                  onShareActionsClick={(id, action) =>
                    props.onShareActionsClick(
                      id,
                      action,
                      commentWithParent!.content,
                      commentWithParent!.id
                    )
                  }
                  onCameraActionsClick={(
                    commentWithParent: CommentData,
                    action: string | number
                  ) => props.onCameraActionsClick(commentWithParent, action)}
                  onMoreActionsClick={(id: string, action: string | number) =>
                    onMoreActionsClick(id, action, commentWithParent.id)
                  }
                  isCreator={props.isCreator}
                  isExpanded={isChildCommentsExpanded}
                  onExpand={onChildCommentsExpand}
                />
              )}
              {deviceType === DeviceType.Mobile && (
                <CommentIconsMobile
                  comment={props.commentWithParent}
                  numberOfReplies={props.commentWithParent.numberOfReplies}
                  onTopicReplyClick={id => {
                    props.onCommentReplyClick(id)

                    if (props.userId) {
                      setIsReplyOpen(!isReplyOpen)
                    }
                  }}
                  onShareActionsClick={(id, action) => {
                    props.onShareActionsClick(
                      id,
                      action,
                      commentWithParent!.content,
                      commentWithParent!.id
                    )
                  }}
                  onMoreActionsClick={(id: string, action: string | number) =>
                    onMoreActionsClick(id, action, commentWithParent.id)
                  }
                  onCameraActionsClick={(
                    commentWithParent: CommentData,
                    action: string | number
                  ) => props.onCameraActionsClick(commentWithParent, action)}
                  isCreator={props.isCreator}
                  isExpanded={isChildCommentsExpanded}
                  onExpand={onChildCommentsExpand}
                />
              )}
              {isReplyOpen && deviceType === DeviceType.Desktop && (
                <CommentReplyBox
                  onCancel={() => setIsReplyOpen(false)}
                  onSubmit={(text, symbols, replyNotificationEmail) =>
                    onCommentReplySubmit(
                      text,
                      symbols,
                      replyNotificationEmail,
                      props.commentWithParent,
                      {
                        get: isReplyOpen,
                        set: setIsReplyOpen,
                      }
                    )
                  }
                  initialText=""
                  submitButtonText="REPLY"
                  replyNotificationEmail={props.replyNotificationEmail}
                />
              )}
              {isReplyOpen && deviceType === DeviceType.Mobile && (
                <CommentReplyMobileBox
                  onCancel={() => setIsReplyOpen(false)}
                  onSubmit={(text, symbols, replyNotificationEmail) =>
                    onCommentReplySubmit(
                      text,
                      symbols,
                      replyNotificationEmail,
                      props.commentWithParent,
                      {
                        get: isReplyOpen,
                        set: setIsReplyOpen,
                      }
                    )
                  }
                  onClose={() => setIsReplyOpen(false)}
                  initialText=""
                  submitButtonText="REPLY"
                  replyNotificationEmail={props.replyNotificationEmail}
                />
              )}

              {deviceType === DeviceType.Desktop &&
                isEditOpen &&
                initialText && (
                  <CommentReplyBox
                    onCancel={() => setIsEditOpen(false)}
                    onSubmit={(text, symbols, replyNotificationEmail) =>
                      onCommentUpdateSubmit(
                        text,
                        symbols,
                        replyNotificationEmail,
                        props.commentWithParent.id,
                        {
                          get: isEditOpen,
                          set: setIsEditOpen,
                        }
                      )
                    }
                    initialText={initialText}
                    submitButtonText="SAVE"
                    replyNotificationEmail={props.replyNotificationEmail}
                  />
                )}

              {deviceType === DeviceType.Mobile &&
                isEditOpen &&
                initialText && (
                  <CommentReplyMobileBox
                    onCancel={() => setIsEditOpen(false)}
                    onSubmit={(text, symbols, replyNotificationEmail) =>
                      onCommentUpdateSubmit(
                        text,
                        symbols,
                        replyNotificationEmail,
                        props.commentWithParent.id,
                        {
                          get: isEditOpen,
                          set: setIsEditOpen,
                        }
                      )
                    }
                    initialText={initialText}
                    submitButtonText="SAVE"
                    onClose={() => setIsEditOpen(false)}
                    replyNotificationEmail={props.replyNotificationEmail}
                  />
                )}
            </Box>
          </Box>
          <Box
            sx={{
              width: 61,
              backgroundColor:
                deviceType === DeviceType.Mobile
                  ? colors.drawer_bg_right
                  : colors.drawer_bg_light,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <VoteUpDown
              numVotes={commentWithParent!.voteNum || 0}
              onVoteDownClick={() =>
                props.onVote(VoteDirection.down, commentWithParent!.id)
              }
              onVoteUpClick={() =>
                props.onVote(VoteDirection.up, commentWithParent!.id)
              }
              voteDirection={(() => {
                return commentWithParent!.userVoteDirection
              })()}
            />
          </Box>
        </Box>
      )}
      {isChildCommentsExpanded && renderChildComments(commentWithParent!.id)}
    </Box>
  )
}

export default Comment
