import React from 'react'
import useStyles from './styles'
import theme from '../../mui-theme'
import classNames from 'classnames'
import YouTube from 'react-youtube'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faMessage,
  faRocket,
} from '@fortawesome/free-solid-svg-icons'
import ChartIcon from 'components/Images/ChartIcon'
import { useNavigate } from 'react-router-dom'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

interface Props {
  lastViewedSymbol?: string | null
  lastViewedSymbolType?: string | null
}

export const About: React.FC<Props> = props => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.aboutVideoWrapper}>
          <div className={classes.aboutTextWrapper}>
            <div className={classes.aboutText}>
              Want to know why your stock's price fell today?
            </div>
          </div>
          <div className={classes.videoWrapper}>
            <YouTube
              videoId={'h-5fGsfWu4w'}
              opts={{
                width: deviceType === DeviceType.Desktop ? 640 : '100%',
                height: deviceType === DeviceType.Desktop ? 360 : 200,
                playerVars: {
                  autoplay: 1,
                  controls: 1,
                  rel: 0,
                  modestbranding: 0,
                },
              }}
            />
          </div>
        </div>
        <div className={classes.boxWrapper}>
          <div className={classes.box}>
            <div className={classes.innerBox}>
              <ChartIcon />
              <div className={classes.boxTitle}>Explore what caused a move</div>
              {/* CHART */}
              <div className={classes.boxText}>
                <p>
                  Explore the pivotal news moments, both current and historical,
                  that have propelled a stock's price.
                </p>
                <p>
                  Just click on any price bar on the chart, and find news &
                  analysis explaining the price move that day.
                </p>
              </div>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.innerBox}>
              {/* ALT-ICONS: faFlag, faSearch, faLightbulb, faCompass, faRocket */}
              <FontAwesomeIcon
                icon={faRocket}
                size="2x"
                className={classNames(classes.icon, classes.pencilIcon)}
              />
              <div className={classes.boxTitle}>
                Discover the Future Big Movers
              </div>
              {/* DISCUSS */}
              <div className={classes.boxText}>
                <p>Discover the popular stocks among investors today.</p>
                <p>
                  Uncover the stocks that may be the growth-leaders of tomorrow,
                  with our innovative Sentiment Discovery Dashboard™
                </p>
              </div>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.innerBox}>
              <FontAwesomeIcon
                icon={faArrowUp}
                size="2x"
                className={classNames(classes.icon, classes.arrowUp)}
              />
              <FontAwesomeIcon
                icon={faArrowDown}
                size="2x"
                className={classNames(classes.icon, classes.arrowDown)}
              />
              <div className={classes.boxTitle}>Vote</div>
              {/* VOTE */}
              <div className={classes.boxText}>
                <p>
                  Choose the news that you believe affected a stock's price
                  today, by voting them up.
                </p>
                <p>
                  Discount the headlines that you believe are just noise, by
                  voting them down.
                </p>
              </div>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.innerBox}>
              <FontAwesomeIcon
                icon={faMessage}
                size="2x"
                className={classNames(classes.icon, classes.messageIcon)}
              />
              <div className={classes.boxTitle}>Discuss</div>
              {/* DISCUSS */}
              <div className={classes.boxText}>
                <p>
                  See the context behind the headlines by engaging in community
                  discussions with other investors.
                </p>
                <p>
                  Dive deep into investors' sentiments, and uncover potential
                  investment opportunities that might be lurking beyond
                  conventional news stories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
