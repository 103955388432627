import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import { ApolloLink, NextLink, Operation } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Auth } from '@aws-amplify/auth'
import { RestLink } from 'apollo-link-rest'
import { ExecutionResult } from 'graphql'
import { loader } from 'graphql.macro'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { v4 as uuid } from 'uuid'
import Observable from 'zen-observable'
import { globalErrors } from './reactive-vars'
import introspectionResult from '../generated/schema-introspection.json'
import { AssetTypeEnum } from 'enum/assetTypeEnum'
import { typePolicies } from './type-policies'
import { jwtDecode } from 'jwt-decode'

import { DEBUG_ENV } from 'index'
export const {
  link: networkStatusNotifierLink,
  useApolloNetworkStatus,
  useApolloNetworkStatusReducer,
} = createNetworkStatusNotifier()

const typeDefs = loader('./localSchema.graphql')

/**
 * Serves introspection operations. For example, the Apollo Client
 * Chrome Devtool issues an introspection operation when it opens
 * in order to display the schema.
 */
export class IntrospectionLink extends ApolloLink {
  request(operation: Operation, forward?: NextLink) {
    switch (operation.operationName.toLowerCase()) {
      case 'introspectionquery':
        return new Observable<ExecutionResult>(subscriber => {
          subscriber.next({
            data: introspectionResult,
          })
          subscriber.complete()
        })
    }

    if (forward) {
      return forward(operation)
    }

    throw new Error(`Unable to handle operation ${operation.operationName}`)
  }
}

// TODO: Create user friendly error messages
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = globalErrors()
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      globalErrors([
        ...errors,
        {
          id: uuid(),
          message,
        },
      ])
      if (DEBUG_ENV)
        if (DEBUG_ENV)
          console.debug(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
    })
  if (networkError) {
    if (DEBUG_ENV)
      if (DEBUG_ENV) console.debug(`[Network error]: ${networkError}`)
    globalErrors([
      ...errors,
      {
        id: uuid(),
        message: networkError.message || 'Server/connection error',
      },
    ])
  }
})
interface AssetDataIncoming {
  volume: string
  high: string
  low: string
  close: string
  open: string
}

interface AssetValue {
  volume: number
  high: number
  low: number
  close: number
  open: number
}

interface AssetItemRoot {
  date: string
  symbol: string
  type: AssetTypeEnum
}

interface AssetItemIncoming extends AssetItemRoot {
  data: AssetDataIncoming
}

interface AssetItem extends AssetItemRoot {
  id: string
  data: AssetValue
}

interface StockServiceResult {
  date: string
  symbol: string
  data: AssetItemIncoming[]
}

const restLink = new RestLink({
  uri: process.env.REACT_APP_STOCK_DATA_API_ENDPOINT_URL,
  responseTransformer: async (response, typeName): Promise<AssetItem[]> => {
    if (!response) {
      return []
    }
    const data: StockServiceResult[] = await response.json()
    const isProcessed = Array.isArray(data[0].data)

    if (!isProcessed) {
      return (data as unknown as AssetItemIncoming[]).map(item => ({
        ...item,
        id: item.symbol + item.type + item.date,
        data: {
          close: parseFloat(item.data.close),
          high: parseFloat(item.data.high),
          low: parseFloat(item.data.low),
          open: parseFloat(item.data.open),
          volume: parseFloat(item.data.volume),
        },
      }))
    }

    return data
      .reduce((assetList, currentAssetBatch) => {
        return assetList.concat(currentAssetBatch.data)
      }, [] as unknown as AssetItemIncoming[])
      .map(item => ({
        id: item.symbol + item.type + item.date,
        date: item.date,
        symbol: item.symbol,
        type: item.type,
        data: {
          close: parseFloat(item.data.close),
          high: parseFloat(item.data.high),
          low: parseFloat(item.data.low),
          open: parseFloat(item.data.open),
          volume: parseFloat(item.data.volume),
        },
      }))
  },
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT_URL,
})

const isTokenExpired = (token: string) => {
  const decodedToken = jwtDecode(token)

  if (!decodedToken || !decodedToken.exp) return true

  const currentTime = Date.now() / 1000
  return decodedToken.exp < currentTime
}

const requestTokenRefreshFromFlutter = () => {
  // Check if running inside a WebView that supports postMessage
  if (window.Flutter) {
    window.Flutter.postMessage(JSON.stringify({ action: 'refreshToken' }))
  } else {
    console.error(
      'Token refresh requested, but not running in a compatible WebView environment.'
    )
  }
}

const authLink = setContext(async (_, { headers }) => {
  let token = localStorage.getItem('idToken')

  const refreshTokenFromFlutter = async () => {
    requestTokenRefreshFromFlutter()
    const maxAttempts = 10
    let attempts = 0
    while (attempts < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, 1000)) // Wait for 1 second
      const newToken = localStorage.getItem('idToken')

      if (newToken && newToken !== token && !isTokenExpired(newToken)) {
        return newToken // New, valid token is found
      }
      attempts++
    }

    // If token cannot be refreshed, remove it and notify Flutter to logout
    localStorage.removeItem('idToken')
    if (window.Flutter) {
      // Send a logout message to Flutter
      window.Flutter.postMessage(JSON.stringify({ action: 'logout' }))
    } else {
      console.error(
        'Logout requested, but not running in a compatible WebView environment.'
      )
    }
    return null
  }

  if (token && isTokenExpired(token)) {
    // If the token is expired, attempt to refresh it from Flutter
    try {
      token = await refreshTokenFromFlutter()
    } catch (error) {
      token = null
    }
  } else if (!token) {
    try {
      const session = await Auth.currentSession()
      token = session.getIdToken().getJwtToken()
    } catch (amplifyError) {
      token = null
    }
  }

  // Continue with the Apollo request, using the existing or refreshed token
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: from([
    networkStatusNotifierLink,
    new IntrospectionLink(),
    authLink,
    restLink,
    httpLink,
    errorLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: typePolicies,
  }),
  queryDeduplication: true,
  connectToDevTools: true,
  typeDefs,
})

export default client
