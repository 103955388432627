import {
  faArrowLeft,
  faBook,
  faCommentAlt,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {
  CheckDuplicateUrlInput,
  PostType,
  useCheckDuplicateUrlLazyQuery,
} from 'generated/graphql'
import useTickerValidation from 'hooks/useTickerValidation'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ExDate, colors, regex } from '../../../constants'
import theme from '../../../mui-theme'
import { dateFromURL, titleFromURL } from '../../../utils/urlUtils'
import DatePicker from '../../inputs/DatePicker'
import MarkdownEditor from '../../inputs/MarkdownEditor'
import TextInput from '../../inputs/Text'
import useStyles from './styles'
import { DEBUG_ENV } from 'index'
import { BrandButton } from 'components/common/BrandButton'
import { Checkbox, FormControlLabel, checkboxClasses } from '@mui/material'

export interface CreateTopicInput {
  headline: string
  postSymbol: string
  date: string
  text: string
  postType: PostType
  url: string
  ticker: string
  notifyViaEmail: boolean
  additionalSymbols: string[] | undefined
}

interface Props {
  children?: React.ReactNode
  type?: string
  isEdit?: boolean
  onCancelNewTopicClick: () => void
  onPostClick: (data: CreateTopicInput) => void
  data?: Partial<CreateTopicInput>
  symbolDate: ExDate
}

const TITLE_MIN_LENGTH = 5
const TITLE_MAX_LENGTH = 1000

const TEXT_MIN_LENGTH = 5

const URL_DUPLICATE_ERROR = `There is already a post with this URL on this security...`

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default forwardRef<HTMLInputElement, Props>(props => {
  const classes = useStyles(theme)
  const { validateTicker, extractTickers, symbols } = useTickerValidation()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm<CreateTopicInput>({
    defaultValues: props.data,
    mode: 'onBlur', // or 'onChange', 'onSubmit', 'onTouched'
  })

  // If there is no text, then it is a link
  const [topicType, setTopicType] = useState(
    props?.data?.postType ?? PostType.Story
  )
  const [initialURL, setInitialURL] = useState(props.data?.url)
  const [getURL, setURL] = useState('')
  const [checkURL] = useCheckDuplicateUrlLazyQuery({
    fetchPolicy: 'network-only',
  })

  const isDuplicateURL = async () => {
    // Step 2: Compare the current URL with the initial URL
    if (getURL === initialURL) {
      // If they match, consider it not a duplicate without making a backend call
      return false
    }

    // If they don't match, proceed with the duplicate URL check
    const result = await checkURL({
      variables: {
        data: {
          url: getURL,
          postSymbol: props.type,
        } as CheckDuplicateUrlInput,
      },
    })

    if (!!result.data?.checkDuplicateUrl) {
      setError('url', {
        message: URL_DUPLICATE_ERROR,
      })
      return true // URL is a duplicate
    } else {
      return false // URL is not a duplicate
    }
  }

  const onBlur = () => {
    // This bit of code will check if there is a URL entered,
    // If there is, then it will try to extract a headline from it
    if (getValues('headline')?.length === 0) {
      const headline = titleFromURL(getURL) ?? ''

      setValue('headline', headline)
    }

    // Get date if it's in the URL
    if (getValues('date')?.length === 0) {
      console.debug(`--> CreateTopicPanel -- `)
      const newDate = dateFromURL(getURL) ?? ''

      if (!!newDate?.length) {
        setValue('date', new ExDate(newDate).toISOString())
      } else {
        setValue('date', props.symbolDate.toISOString())
      }
    }

    return isDuplicateURL()
  }

  const onPost = (data: CreateTopicInput) => {
    let payload = {
      ...data,
      type: props.data?.postSymbol,
      postType: topicType,
    } as CreateTopicInput

    extractTickers(payload.text)
    payload.additionalSymbols = symbols ?? undefined

    isDuplicateURL().then((result: boolean) => {
      if (!result) {
        props.onPostClick(payload)
      }
    })
  }

  // validation for markdown editor has to be done here due to it being an
  // editable div
  useEffect(() => {
    if (topicType === PostType.Text) {
      register(
        { name: 'text' },
        {
          required: 'The text field is required',
          validate: validateTicker,
          minLength: {
            message: `The text length needs to be at least ${TEXT_MIN_LENGTH} characters.`,
            value: TEXT_MIN_LENGTH,
          },
        }
      )
    }
  })

  useEffect(() => {
    // Update initialURL if props.data?.url changes
    setInitialURL(props.data?.url)
  }, [props.data?.url])

  let formDate: ExDate = props.symbolDate

  if (!!props?.data?.date) {
    formDate = new ExDate(props?.data?.date)

    if (DEBUG_ENV)
      console.debug(`props?.data?.date !== undefined: ${!!props?.data?.date}`)
  }

  return (
    <div className={classes.wrapper}>
      {/****************************************************************************************************
       * CREATE TOPIC FORM
       *****************************************************************************************************/}
      <div className={classes.buttonsWrapper}>
        {/****************************************************************************************************
         * NEW TOPIC TYPE - Story
         *****************************************************************************************************/}
        <div
          className={classNames(
            classes.topButton,
            topicType === PostType.Story && classes.buttonSelected
          )}
          onClick={() => !props.isEdit && setTopicType(PostType.Story)}
        >
          <FontAwesomeIcon icon={faBook} size="sm" />
          <span className={classes.buttonText}>Story</span>
        </div>
        {/****************************************************************************************************
         * NEW TOPIC TYPE - LINK
         *****************************************************************************************************/}
        <div
          className={classNames(
            classes.topButton,
            topicType === PostType.Url && classes.buttonSelected
          )}
          onClick={() => !props.isEdit && setTopicType(PostType.Url)}
        >
          <FontAwesomeIcon icon={faLink} size="sm" />
          <span className={classes.buttonText}>Link</span>
        </div>

        {/****************************************************************************************************
         * NEW TOPIC TYPE - LINK
         *****************************************************************************************************/}
        <div
          className={classNames(
            classes.topButton,
            topicType === PostType.Text && classes.buttonSelected
          )}
          onClick={() => !props.isEdit && setTopicType(PostType.Text)}
        >
          <FontAwesomeIcon icon={faCommentAlt} size="sm" />
          <span className={classes.buttonText}>Text</span>
        </div>
      </div>

      <div className={classes.contentWrapper}>
        <div className={classes.navigationWrapper}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.backArrow}
            size="sm"
            onClick={() => props.onCancelNewTopicClick()}
          />
        </div>

        <div className={classes.formWrapper}>
          <h2 className={classes.header}>
            {topicType !== PostType.Story &&
              (props.isEdit ? 'Edit Topic' : 'Create a Topic')}
            {topicType === PostType.Story &&
              (props.isEdit ? 'Edit Story' : 'Create a Story')}
          </h2>

          <form onSubmit={handleSubmit(onPost)} className={classes.form}>
            <div className={classes.inputWrapper}>
              <TextInput
                name="headline"
                label="Headline"
                ref={register({
                  required: 'The headline field is required',
                  minLength: {
                    message: `The text length needs to be at least ${TITLE_MIN_LENGTH} characters.`,
                    value: TITLE_MIN_LENGTH,
                  },
                  maxLength: {
                    message: ` The comment length cannot exceed ${TITLE_MAX_LENGTH} characters`,
                    value: TITLE_MAX_LENGTH,
                  },
                })}
                error={errors.headline ? errors.headline.message : undefined}
              />
            </div>
            <div className={classes.inputWrapper}>
              <Controller
                as={<DatePicker />}
                control={control}
                name="date"
                selectedDate={formDate.datetime.toDate()}
                defaultValue={formDate.datetime.toDate()}
                ref={{ required: 'The date field is required' }}
                autoOk
                error={errors.date ? errors.date.message : undefined}
              />
            </div>
            {topicType === PostType.Text && (
              <div
                className={classNames(
                  classes.inputWrapper,
                  classes.textInputWrapper
                )}
              >
                <Controller
                  as={<MarkdownEditor name="text" />}
                  control={control}
                  label="Text"
                  name="text"
                  onInput={(input: string) => {
                    setValue('text', input, { shouldValidate: true })
                    extractTickers(input)
                  }}
                  value={getValues().text}
                  error={errors.text ? errors.text.message : undefined}
                />
              </div>
            )}
            {topicType === PostType.Url && (
              <div className={classes.inputWrapper}>
                <TextInput
                  name="url"
                  label="URL"
                  onBlur={e => onBlur()}
                  onChange={async e => {
                    setURL(e)
                    await sleep(100)
                  }}
                  ref={register({
                    required: 'The URL field is required',
                    pattern: {
                      value: regex.url,
                      message: 'The value entered must be a valid URL',
                    },
                  })}
                  error={errors.url && errors.url.message}
                />
              </div>
            )}
            {topicType !== PostType.Story && !props?.data?.notifyViaEmail && (
              <Controller
                name="notifyViaEmail"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => onChange(e.target.checked)}
                        onBlur={onBlur}
                        checked={value}
                        name={name}
                        ref={ref}
                        sx={{
                          color: colors.white_faded,
                          padding: 0,
                          marginRight: 1,
                        }}
                      />
                    }
                    label="Notify me via email when someone replies"
                    sx={{
                      color: colors.textGreyLight,
                      margin: '8px 0 0 0',
                      [`& span.${checkboxClasses.checked}`]: {
                        color: 'white',
                      },
                    }}
                  />
                )}
              />
            )}
            <div className={classes.buttonWrapper}>
              <BrandButton
                variant="contained"
                className={classNames(classes.button, classes.saveButton)}
                type="submit"
              >
                {topicType === PostType.Story
                  ? 'SELECT TOPICS'
                  : props.isEdit
                  ? 'SAVE'
                  : 'POST'}
              </BrandButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
})
