import React from 'react'
import ReactDOM from 'react-dom'
import { PostHogProvider } from 'posthog-js/react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import client from './apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import theme from './mui-theme'
import { initiateAuth } from './auth/userAuth'

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

initiateAuth()

console.info('fnchart-webapp-1.0.5')
export const DEBUG_ENV =
  !!process.env.REACT_APP_DEPLOYMENT_ENV &&
  !!process.env.REACT_APP_LOG_DETAIL_LEVEL
    ? process.env.REACT_APP_DEPLOYMENT_ENV !== 'PRODUCTION' &&
      process.env.REACT_APP_LOG_DETAIL_LEVEL !== 'PRODUCTION'
    : false

if (DEBUG_ENV)
  console.log(
    `deployment_env: ${
      process.env?.REACT_APP_DEPLOYMENT_ENV
        ? process.env.REACT_APP_DEPLOYMENT_ENV
        : 'STAGING'
    }`
  )

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={posthogOptions}
  >
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </PostHogProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
