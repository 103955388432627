import { useCallback } from 'react'
import { debounce } from 'lodash'

type UseSearchAndSymbolManagementHook = (
  setSymbol: (symbol: string) => void,
  loadResultsTabData: (newSymbol?: string) => void
) => {
  handleInputChange: (e: any) => void
}

const useSearchAndSymbolManagement: UseSearchAndSymbolManagementHook = (
  setSymbol,
  loadResultsTabData
) => {
  const handleInputChange = useCallback(
    debounce((e: any) => {
      const newSymbol = e.target.value.toUpperCase()
      setSymbol(newSymbol)
      loadResultsTabData(newSymbol)
    }, 300),
    [setSymbol, loadResultsTabData]
  )

  return {
    handleInputChange,
  }
}

export default useSearchAndSymbolManagement
