import { useState } from 'react'
import { useGetSymbolsLazyQuery } from 'generated/graphql'

export const useSymbolSearch = () => {
  const [symbol, setSymbol] = useState('')
  const [
    getSymbols,
    { data: searchSymbols, loading: searchLoading, error: searchError },
  ] = useGetSymbolsLazyQuery({
    variables: {
      data: {
        symbol: symbol || 'DEFAULT_NON_EXISTENT_VALUE',
      },
    },
    fetchPolicy: 'cache-first',
  })

  return {
    symbol,
    setSymbol,
    getSymbols,
    searchSymbols,
    searchLoading,
    searchError,
  }
}
