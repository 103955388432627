import React from 'react'
import useStyles from './styles'
import { useForm } from 'react-hook-form'
import theme from '../../../mui-theme'
import classNames from 'classnames'
import MultiLineTextInput from '../../inputs/MultiLineText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { BrandButton } from 'components/common/BrandButton'

export interface ReportCommentInput {
  type: string
  message: string
}

interface Props {
  children?: React.ReactNode
  onCancelReportCommentClick: () => void
  onReportClick: (data: ReportCommentInput) => void
  data?: Partial<ReportCommentInput>
}

export default function ReportCommentPanel(props: Props) {
  const classes = useStyles(theme)
  const { register, handleSubmit, errors } = useForm<ReportCommentInput>({
    defaultValues: props.data,
  })

  const onPost = (data: ReportCommentInput) => {
    let payload = {
      ...data,
    } as ReportCommentInput

    props.onReportClick(payload)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.navigationWrapper}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.backArrow}
            size="sm"
            onClick={() => props.onCancelReportCommentClick()}
          />
        </div>

        <div className={classes.formWrapper}>
          <h2 className={classes.header}>Report Comment</h2>

          <form onSubmit={handleSubmit(onPost)} className={classes.form}>
            <div className={classes.inputWrapper}>
              <MultiLineTextInput
                name="message"
                label="Message"
                ref={register({
                  required: 'The text field is required',
                  validate: value =>
                    !!value.trim() ||
                    'The text field is required. You cannot submit an empty coment.',
                })}
                error={errors.message ? errors.message.message : undefined}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <BrandButton
                variant="contained"
                className={classNames(classes.button, classes.saveButton)}
                type="submit"
              >
                REPORT
              </BrandButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
