import { Button } from '@mui/material'
import ROUTES from 'constants/routes'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import YouTube from 'react-youtube'
import theme from '../../mui-theme'
import useStyles from './styles'

interface Props {
  showStartButton?: boolean
  lastViewedSymbol?: string | null
  lastViewedSymbolType?: string | null
}

export const BullsAndBears: React.FC<Props> = props => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.aboutVideoWrapper}>
          <div className={classes.aboutTextWrapper}>
            {props.showStartButton && (
              <Button
                onClick={() => {
                  if (props.lastViewedSymbol && props.lastViewedSymbolType) {
                    navigate(
                      ROUTES.ASSET.replace(':symbol', props.lastViewedSymbol)
                        .replace(':type', props.lastViewedSymbolType)
                        .toLowerCase()
                    )
                  } else {
                    navigate(ROUTES.SENTIMENT_DISCOVERY)
                  }
                }}
                className={classes.startBtn}
                sx={{
                  '.MuiTouchRipple-root': {
                    display: 'none',
                  },
                }}
              >
                Find out with fnchart
              </Button>
            )}
          </div>
          <div className={classes.headingText}>
            Bulls and Bears Documentary FULL John Moulton
          </div>
          <div className={classes.bullsAndBearsVideo}>
            <YouTube
              videoId={'ha0gv_nUrZI'}
              opts={{
                height: '600',
                width: '1100',
                playerVars: {
                  autoplay: 0,
                  controls: 1,
                  rel: 0,
                  modestbranding: 0,
                },
              }}
            />
          </div>
          <div className={classes.aboutText}>
            <div className={classes.stockTag}>
              Know why your stock's price fell today?
            </div>
            <Button
              onClick={() => {
                if (props.lastViewedSymbol && props.lastViewedSymbolType) {
                  navigate(
                    ROUTES.ASSET.replace(':symbol', props.lastViewedSymbol)
                      .replace(':type', props.lastViewedSymbolType)
                      .toLowerCase()
                  )
                } else {
                  navigate(ROUTES.SENTIMENT_DISCOVERY)
                }
              }}
              className={classes.startBtn}
              sx={{
                '.MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
            >
              Find out with fnchart
            </Button>
          </div>
        </div>
        <div className={classes.boxWrapper}></div>
      </div>
    </div>
  )
}
