import ROUTES from 'constants/routes'
import useStyles from './styles'
import theme from '../../mui-theme'
import { useGetCurrentUserQuery } from 'generated/graphql'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DEBUG_ENV } from '../../index'
import { LoadingProgress } from 'components/LoadingProgress'

const trace = `---> RedirectSignon -- `

const RedirectSignin = () => {
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const storedRedirectTo = localStorage.getItem('redirectTo')

  const {
    data: user,
    loading,
    error,
  } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
    // pollInterval: 1000,
  })

  if (DEBUG_ENV) console.debug(trace + `BEFORE USEEFFECT`)

  const checkUser = () => {
    if (DEBUG_ENV)
      console.debug(
        trace +
          `INSIDE USEEFFECT
      LOADING ${loading}
      ERROR: ${JSON.stringify(error)}
      data: ${JSON.stringify(user)}`
      )

    console.log('user', user?.getCurrentUser?.nickname)

    if (!user?.getCurrentUser) {
      return
    }

    if (storedRedirectTo) {
      localStorage.removeItem('redirectTo')
      navigate(storedRedirectTo)
      return
    }

    if (user?.getCurrentUser && user?.getCurrentUser?.nickname === null) {
      if (DEBUG_ENV)
        console.debug(
          trace + 'RedirectSignin: user.getCurrentUser.nickname === null'
        )
      navigate(ROUTES.CREATE_NICKNAME)
      return
    }

    if (
      !!user?.getCurrentUser &&
      !!user?.getCurrentUser?.nickname &&
      !!user?.getCurrentUser?.lastViewedSymbol
    ) {
      navigate(
        ROUTES.ASSET.replace(':symbol', user.getCurrentUser.lastViewedSymbol)
          .replace(':type', 'asset')
          .toLowerCase()
      )
      return
    } else {
      navigate(ROUTES.SENTIMENT_DISCOVERY)
      return
    }
  }

  useEffect(() => {
    checkUser()
  }, [user])

  if (DEBUG_ENV) console.debug(trace + `AFTER USEEFFECT`)

  return <div className={classes.wrapper}>{loading && <LoadingProgress />}</div>
}

export default RedirectSignin
