import useStyles from './styles'
import theme from '../../../mui-theme'
import { forwardRef, useEffect } from 'react'
import React from 'react'
import { Button, Tab, Tabs } from '@mui/material'
import classNames from 'classnames'
import NewsPost from '../NewsPost'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import { convertVoteDirection } from 'utils/voteUtils'
import { NewsTabItem } from '../interfaces/newsTabItem'
import NewsFeedItem from '../NewsFeedItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PostLoading } from 'components/SearchOverlay/PostLoading'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { IPricePredictionResultNotification } from 'types/notification'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { useMarkNotificationsMutation } from 'generated/graphql'

interface NewsProps {
  currentUserId: string | undefined
  tabItems: NewsTabItem[]
  selectedTabItemIndex: number
  hasMoreFeedItems: boolean
  hasMoreNewestPosts: boolean
  latestPricePredictionNotification: IPricePredictionResultNotification | null
  isPricePredictionRoundVotingOpen: boolean | undefined
  loadMoreFeedItems: () => void
  loadMoreLatestNews: () => void
  newsClick: (type: string, symbol: string, newsPostId: string) => void
  setSelectedTab: (newSelectedTabIndex: number) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  onPostClick: (id: string, symbol: string) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
  onVote: (
    direction: VoteDirection,
    id: string,
    userVotePositive?: boolean | null
  ) => void
  onCommentVote: (
    direction: VoteDirection,
    id: string,
    userVotePositive?: boolean | null
  ) => void
}

export default forwardRef<HTMLInputElement, NewsProps>((props, ref) => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newSelectedTabIndex: number
  ) => {
    props.setSelectedTab(newSelectedTabIndex)
  }

  const handlePredictionResultClick = () => {
    markNotifications({
      variables: {
        ids: [props.latestPricePredictionNotification?.notificationId!],
        read: true,
      },
    })

    navigate(
      ROUTES.PRICE_PREDICTION_RESULT.replace(
        ':roundId',
        props.latestPricePredictionNotification?.roundId!
      )
    )
  }

  const [markNotifications] = useMarkNotificationsMutation()

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={props.selectedTabItemIndex}
        className={classes.tabs}
        onChange={handleTabChange}
        aria-label="user settings tabs"
        sx={{
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        }}
        classes={{
          indicator: classes.selectedTabIndicator,
        }}
      >
        {props.tabItems.map((item, i) => {
          return (
            <Tab
              key={i}
              className={classNames(
                classes.tab,
                props.selectedTabItemIndex === i ? classes.selectedTab : ''
              )}
              label={<div>{item.label}</div>}
            />
          )
        })}
      </Tabs>
      {props.selectedTabItemIndex === 0 && (
        <div className={classes.newsPostWrapper} id="news-posts">
          <InfiniteScroll
            dataLength={
              props.tabItems[props.selectedTabItemIndex].feedItems?.length ?? 0
            }
            style={{ height: '100%' }}
            next={() => {
              props.loadMoreFeedItems()
            }}
            hasMore={props.hasMoreFeedItems}
            loader={
              <div style={{ paddingTop: 24 }}>
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
              </div>
            }
            scrollableTarget="news-posts"
            height="100%"
          >
            {props.isPricePredictionRoundVotingOpen && (
              <div className={classes.predictionResult}>
                <div className={classes.predictionResultHeader}>
                  <img
                    className={classes.fnchartIcon}
                    src={'./media/fnchartRoundWhiteBack.png'}
                    alt="fnchartRoundWhiteBack"
                  />
                  <div className={classes.fnchartText}>fnchart</div>
                  <Button
                    className={classes.seeResultButton}
                    sx={{
                      '.MuiTouchRipple-root': {
                        display: 'none',
                      },
                    }}
                    onClick={() => {
                      navigate(ROUTES.PRICE_PREDICTION)
                    }}
                  >
                    Play game
                  </Button>
                </div>
                <div className={classes.resultIsHereText}>
                  Daily Challenge 🔥: Read the latest posts and predict the
                  stock price at the close of the upcoming trading session.
                </div>
                <img
                  className={classes.predictResultHereFeedImage}
                  src={'./media/PredictPriceTomorrowFeed.png'}
                  alt="PredictResultHereFeed"
                  onClick={() => {
                    navigate(ROUTES.PRICE_PREDICTION)
                  }}
                />
              </div>
            )}
            {props.latestPricePredictionNotification && (
              <div className={classes.predictionResult}>
                <div className={classes.predictionResultHeader}>
                  <img
                    className={classes.fnchartIcon}
                    src={'./media/fnchartRoundWhiteBack.png'}
                    alt="fnchartRoundWhiteBack"
                  />
                  <div className={classes.fnchartText}>fnchart</div>
                  <Button
                    className={classes.seeResultButton}
                    sx={{
                      '.MuiTouchRipple-root': {
                        display: 'none',
                      },
                    }}
                    onClick={handlePredictionResultClick}
                  >
                    See result
                  </Button>
                </div>
                <div className={classes.resultIsHereText}>
                  Daily challenge 🔥 Result is here
                </div>
                <img
                  className={classes.predictResultHereFeedImage}
                  src={'./media/PredictResultHereFeed.png'}
                  alt="PredictResultHereFeed"
                  onClick={handlePredictionResultClick}
                />
              </div>
            )}
            {props.tabItems[props.selectedTabItemIndex].feedItems?.map(
              (feedItem, i) => (
                <NewsFeedItem
                  currentUserId={props.currentUserId}
                  key={i}
                  index={i}
                  feedItem={feedItem}
                  onVote={(direction: VoteDirection) => {
                    props.onVote(
                      direction,
                      feedItem?.id!,
                      feedItem!.userVoteDirection !== null
                        ? convertVoteDirection(feedItem!.userVoteDirection!)
                        : undefined
                    )
                  }}
                  onCommentVote={(direction: VoteDirection) => {
                    props.onCommentVote(
                      direction,
                      feedItem?.id!,
                      feedItem!.userVoteDirection !== null
                        ? convertVoteDirection(feedItem!.userVoteDirection!)
                        : undefined
                    )
                  }}
                  onShareActionsClick={props.onShareActionsClick}
                  onCameraActionsClick={props.onCameraActionsClick}
                  onCameraActionsCommentClick={
                    props.onCameraActionsCommentClick
                  }
                  onMoreActionsClick={props.onMoreActionsClick}
                  onMoreCommentActionsClick={props.onMoreCommentActionsClick}
                  onPostClick={props.onPostClick}
                  onCommentClick={props.onCommentClick}
                />
              )
            )}
          </InfiniteScroll>
        </div>
      )}
      {props.selectedTabItemIndex === 1 && (
        <div className={classes.newsPostWrapper} id="news-posts">
          <div className={classes.trendingLatestWrapper}>
            {props.tabItems[props.selectedTabItemIndex].newsPosts?.map(
              (post, i) => (
                <NewsPost
                  key={i}
                  newsPost={post}
                  newsPostClick={props.newsClick}
                  onVote={direction => {
                    props.onVote(
                      direction,
                      post.post.id,
                      post.post.userVoteDirection !== null
                        ? convertVoteDirection(post.post.userVoteDirection!)
                        : undefined
                    )
                  }}
                  onShareActionsClick={props.onShareActionsClick}
                  onCameraActionsClick={props.onCameraActionsClick}
                  onMoreActionsClick={props.onMoreActionsClick}
                  onPostClick={props.onPostClick}
                />
              )
            )}
          </div>
        </div>
      )}
      {props.selectedTabItemIndex === 2 && (
        <div className={classes.newsPostWrapper} id="lastest-news">
          <InfiniteScroll
            dataLength={
              props.tabItems[props.selectedTabItemIndex].newsPosts?.length ?? 0
            }
            style={{ height: '100%' }}
            next={() => {
              props.loadMoreLatestNews()
            }}
            hasMore={props.hasMoreNewestPosts}
            loader={
              <div style={{ paddingTop: 24 }}>
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
                <PostLoading />
              </div>
            }
            scrollableTarget="lastest-news"
            height="100%"
          >
            <div className={classes.trendingLatestWrapper}>
              {props.tabItems[props.selectedTabItemIndex].newsPosts?.map(
                (post, i) => (
                  <NewsPost
                    key={i}
                    newsPost={post}
                    newsPostClick={props.newsClick}
                    onVote={direction => {
                      props.onVote(
                        direction,
                        post.post.id,
                        post.post.userVoteDirection !== null
                          ? convertVoteDirection(post.post.userVoteDirection!)
                          : undefined
                      )
                    }}
                    onShareActionsClick={props.onShareActionsClick}
                    onCameraActionsClick={props.onCameraActionsClick}
                    onMoreActionsClick={props.onMoreActionsClick}
                    onPostClick={props.onPostClick}
                  />
                )
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  )
})
