import { useState, useEffect } from 'react'
import { DeviceType } from 'utils/screenSizeUtils' // Import DeviceType if not already imported

function useWindowHeight(deviceType: DeviceType) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      let newHeight = window.innerHeight

      if (deviceType === DeviceType.Mobile) {
        newHeight -= 73
      }

      setWindowHeight(newHeight)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [deviceType])

  return windowHeight
}

export default useWindowHeight
