import { Box } from '@mui/material'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import theme from '../../../mui-theme'
import useStyles from './styles'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import CommentWithParent from 'components/common/CommentWithParent'
import Post from 'components/common/Post'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'

export interface NewsFeedProps {
  currentUserId: string | undefined
  feedItem: PostData | CommentData | null
  index: number
  onVote: (
    direction: VoteDirection,
    postId: string,
    userVotePositive: boolean | null | undefined
  ) => void
  onCommentVote: (
    direction: VoteDirection,
    id: string,
    userVotePositive?: boolean | null
  ) => void
  onShareActionsClick: (
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) => void
  onCameraActionsClick: (topicInfo: PostData, action: string | number) => void
  onCameraActionsCommentClick: (
    commentInfo: CommentData,
    action: string | number
  ) => void
  onMoreActionsClick: (
    postId: string,
    symbol: string,
    assetTypeUpper: string,
    action: string | number
  ) => void
  onMoreCommentActionsClick: (
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) => void
  onPostClick: (id: string, symbol: string) => void
  onCommentClick: (id: string, symbol: string, commentId: string) => void
}

export const getTickerUrl = (ticker?: string): string => {
  return '/asset/' + ticker!.toLowerCase()
}

export default forwardRef<HTMLInputElement, NewsFeedProps>((props, ref) => {
  const classes = useStyles(theme)
  const navigate = useNavigate()

  function isCommentData(feedItem: any): feedItem is CommentData {
    return feedItem && typeof feedItem.parentPost !== 'undefined'
  }

  function isPostData(feedItem: any): feedItem is PostData {
    return feedItem && typeof feedItem.title !== 'undefined'
  }

  return (
    <div className={classes.wrapper}>
      <Box
        sx={{
          flex: 1,
          width: 'calc(100% - 93px)',
          alignSelf: 'center',
        }}
      >
        {isCommentData(props.feedItem) ? (
          <CommentWithParent
            userId={props.currentUserId}
            comment={props.feedItem as CommentData}
            index={props.index}
            onVote={props.onCommentVote}
            onShareActionsClick={props.onShareActionsClick}
            onCameraActionsClick={props.onCameraActionsClick}
            onCameraActionsCommentClick={props.onCameraActionsCommentClick}
            onMoreActionsClick={props.onMoreActionsClick}
            onMoreCommentActionsClick={props.onMoreCommentActionsClick}
            onPostClick={props.onPostClick}
            onCommentClick={props.onCommentClick}
          />
        ) : isPostData(props.feedItem) ? (
          <Post
            userId={props.currentUserId}
            post={props.feedItem as PostData}
            index={props.index}
            onVote={props.onVote}
            onShareActionsClick={props.onShareActionsClick}
            onCameraActionsClick={props.onCameraActionsClick}
            onMoreActionsClick={props.onMoreActionsClick}
            onPostClick={props.onPostClick}
          />
        ) : null}
      </Box>
    </div>
  )
})
