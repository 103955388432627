import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuItem, MenuList } from '@mui/material'
import classNames from 'classnames'
import theme from '../../mui-theme'
import useStyles from './styles'
import { NavMenuItem } from 'types/types'

interface Props {
  expanded: boolean
  navMenuItems: NavMenuItem[]
  navFooterMenuItems: {
    id: string
    text: string
  }[]
  onMenuItemClick: (id: string) => void
}

export default function NavigationSidebar(props: Props) {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      {props.expanded && (
        <>
          <MenuList className={classes.menuList}>
            {props.navMenuItems.map((item, i) => (
              <MenuItem
                key={i}
                className={classNames(
                  classes.menuOption,
                  classes.selectedMenuOption
                )}
                sx={{
                  '.MuiTouchRipple-root': {
                    display: 'none',
                  },
                }}
                onClick={() => props.onMenuItemClick(item.id)}
              >
                <div className={classes.menuOptionWrapper}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={item.icon ?? faHome}
                  />{' '}
                  {item.text}
                </div>
              </MenuItem>
            ))}
          </MenuList>
          <MenuList className={classes.footerMenuList}>
            {props.navFooterMenuItems.map((item, i) => (
              <MenuItem
                key={i}
                className={classes.footerMenuOption}
                sx={{
                  '.MuiTouchRipple-root': {
                    display: 'none',
                  },
                }}
                onClick={() => props.onMenuItemClick(item.id)}
              >
                <div className={classes.footerMenuOptionWrapper}>
                  {item.text}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
      {!props.expanded && (
        <MenuList className={classes.menuList}>
          {props.navMenuItems.map((item, i) => (
            <MenuItem
              key={i}
              className={classNames(
                classes.menuOption,
                classes.selectedMenuOption
              )}
              sx={{
                '.MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
              onClick={() => props.onMenuItemClick(item.id)}
            >
              <div className={classes.menuOptionWrapper}>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={item.icon ?? faHome}
                />
              </div>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </div>
  )
}
