import useStyles from './styles'
import theme from '../../../mui-theme'
import { forwardRef, useEffect } from 'react'
import React from 'react'
import { Tab, Tabs } from '@mui/material'
import classNames from 'classnames'
import WatchedStarUnselected from 'components/Images/WatchedStarUnselected'
import WatchedStarSelected from 'components/Images/WatchedStarSelected'
import { SymbolTabItem } from '../interfaces/symbolTabItem'
import { TabVisibility } from '..'
import { TabIdentifiers } from '../constants/tabConstants'
import TickerImage from 'components/common/TickerImage'

interface SearchResultsPopupProps {
  trendingTab: SymbolTabItem
  resultsTab: SymbolTabItem
  watchListTab: SymbolTabItem
  mostWatchedTab: SymbolTabItem
  selectedTab: TabIdentifiers | null
  tabVisibility: TabVisibility
  resultClick: (type: string, symbol: string) => void
  watchedClick: (symbol: string) => void
  setSelectedTab: (newSelectedTab: TabIdentifiers) => void
  setTabVisibility: (tabVisibility: TabVisibility) => void
}

export default forwardRef<HTMLInputElement, SearchResultsPopupProps>(
  (props, ref) => {
    const classes = useStyles(theme)

    const handleTabChange = (
      _event: React.ChangeEvent<{}>,
      newSelectedTab: TabIdentifiers
    ) => {
      props.setSelectedTab(newSelectedTab)
    }

    const getSelectedTab = () => {
      switch (props.selectedTab) {
        case TabIdentifiers.TRENDING:
          return props.trendingTab
        case TabIdentifiers.RESULTS:
          return props.resultsTab
        case TabIdentifiers.WATCHLIST:
          return props.watchListTab
        case TabIdentifiers.MOST_WATCHED:
          return props.mostWatchedTab
        default:
          return props.trendingTab
      }
    }

    // Update selected tab when visibility changes
    useEffect(() => {
      if (!!props.selectedTab && !props.tabVisibility[props.selectedTab]) {
        props.setSelectedTab(TabIdentifiers.TRENDING)
      }
    }, [props.tabVisibility, props.selectedTab])

    useEffect(() => {
      if (props.selectedTab === null) {
        props.setSelectedTab(TabIdentifiers.TRENDING)
      }
    }, [props.selectedTab])

    return (
      <div className={classes.wrapper}>
        {props.selectedTab && (
          <Tabs
            value={props.selectedTab}
            className={classes.tabs}
            onChange={handleTabChange}
            aria-label="user settings tabs"
            sx={{
              '.MuiTouchRipple-root': {
                display: 'none',
              },
            }}
            classes={{
              indicator: classes.selectedTabIndicator,
            }}
          >
            {props.tabVisibility[TabIdentifiers.TRENDING] && (
              <Tab
                key={'0'}
                value={TabIdentifiers.TRENDING}
                className={classNames(
                  classes.tab,
                  props.selectedTab === TabIdentifiers.TRENDING
                    ? classes.selectedTab
                    : ''
                )}
                label={<div>{props.trendingTab.label}</div>}
              />
            )}
            {props.tabVisibility[TabIdentifiers.RESULTS] && (
              <Tab
                key={'1'}
                value={TabIdentifiers.RESULTS}
                className={classNames(
                  classes.tab,
                  props.selectedTab === TabIdentifiers.RESULTS
                    ? classes.selectedTab
                    : ''
                )}
                label={<div>{props.resultsTab.label}</div>}
              />
            )}
            {props.tabVisibility[TabIdentifiers.WATCHLIST] && (
              <Tab
                key={'2'}
                value={TabIdentifiers.WATCHLIST}
                className={classNames(
                  classes.tab,
                  props.selectedTab === TabIdentifiers.WATCHLIST
                    ? classes.selectedTab
                    : ''
                )}
                label={<div>{props.watchListTab.label}</div>}
              />
            )}
            {props.tabVisibility[TabIdentifiers.MOST_WATCHED] && (
              <Tab
                key={'3'}
                value={TabIdentifiers.MOST_WATCHED}
                className={classNames(
                  classes.tab,
                  props.selectedTab === TabIdentifiers.MOST_WATCHED
                    ? classes.selectedTab
                    : ''
                )}
                label={<div>{props.mostWatchedTab.label}</div>}
              />
            )}
          </Tabs>
        )}
        <div className={classes.body}>
          {getSelectedTab().options.map((item, i) => (
            <div
              className={classes.resultRow}
              key={i}
              onClick={() => props.resultClick(item.type, item.symbol)}
            >
              <div className={classes.key}>
                <TickerImage symbol={item.symbol} />
                <span className={classes.keyText}>{item.symbol}</span>
                <span
                  className={classes.keyIcon}
                  onClick={_event => {
                    props.watchedClick(item.symbol)
                    _event.stopPropagation()
                  }}
                >
                  {!item.watched && <WatchedStarUnselected />}
                  {item.watched && <WatchedStarSelected />}
                </span>
              </div>
              <div className={classes.value}>{item.name}</div>
            </div>
          ))}
          {props.selectedTab === TabIdentifiers.RESULTS &&
            getSelectedTab().options.length === 0 && (
              <div className={classes.noResultsWrapper}>
                <div className={classes.noResultsTitle}>No Results</div>
                <div className={classes.noResultsBody}>
                  We couldn't find any results matching that stock symbol.
                  Please double-check the symbol and try again.
                </div>
              </div>
            )}
        </div>
      </div>
    )
  }
)
