import { NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES } from './navigationConstants'

export const navigationFooterMenuItems = [
  {
    text: 'About',
    id: NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.ABOUT,
  },
  {
    text: 'Privacy Policy',
    id: NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.PRIVACYPOLICY,
  },
  {
    text: 'Terms of Service',
    id: NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.TERMSOFSERVICE,
  },
  {
    text: 'Site Rules',
    id: NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.SITERULES,
  },
  {
    text: 'Contact',
    id: NAVIGATION_FOOTER_SIDEBAR_ACTION_NAMES.CONTACT,
  },
]
