import { forwardRef } from 'react'
import theme from '../../../mui-theme'
import useStyles from './styles'
import UserAvatar from 'components/UserAvatar'
import { Button, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import ComingSoonTooltip from 'components/common/ComingSoonTooltip'
import { UserProfile } from '../interfaces/user'
import Speech from 'components/Images/Speech'
import { ExDate, colors } from '../../../constants'
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import CameraIcon from 'components/Icons/CameraIcon'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import MenuWithOptions from 'components/common/MenuWithOptions'
import AccountBlockIcon from 'components/Icons/AccountBlock'

export interface UserInfoProps {
  currentUserId?: string
  userProfile: UserProfile
  onFollowUser: (userId: string) => void
  onUnfollowUser: (userId: string) => void
  setShowSignUpModal: (show: boolean) => void
  blockUser: (userId: string) => void
  unblockUser: (userId: string) => void
}

export default forwardRef<HTMLInputElement, UserInfoProps>((props, ref) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)

  const handleEditClick = () => {
    window.open('https://en.gravatar.com/emails/', '_blank')
  }

  const handleCreatedAt = () => {
    const date = new ExDate(props.userProfile.createdAt)
    return `Joined ${date.toLocaleString('default', {
      month: 'long',
    })} ${date.getDate()}th ${date.getFullYear()}`
  }

  const ChangeImageContent = () => (
    <div className={classes.changeImageWrapper} onClick={handleEditClick}>
      <div className={classes.cameraBackground}>
        {deviceType !== DeviceType.Mobile && <CameraIcon />}
      </div>
      {deviceType !== DeviceType.Mobile && (
        <div className={classes.changeImageText}>Change image</div>
      )}
    </div>
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperMain}>
        <div className={classes.avatarWrapper}>
          {props.currentUserId === props.userProfile.userId &&
            (deviceType === DeviceType.Mobile ? (
              <UserAvatar emailHash={props.userProfile.emailHash} size={211} />
            ) : (
              <Tooltip
                classes={{ tooltip: classes.changeImageTooltip }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [40, -60],
                      },
                    },
                  ],
                }}
                placement="bottom"
                title={<ChangeImageContent />}
              >
                <>
                  <div style={{ width: 28 }}>&nbsp;</div>
                  <div className={classes.toolTipWrapper}>
                    <div className={classes.avatar}>
                      <UserAvatar
                        emailHash={props.userProfile.emailHash}
                        size={211}
                      />
                    </div>
                  </div>
                  <div style={{ width: 28 }}>&nbsp;</div>
                </>
              </Tooltip>
            ))}
          {props.currentUserId !== props.userProfile.userId && (
            <>
              {deviceType === DeviceType.Desktop && (
                <div style={{ width: 28 }}>&nbsp;</div>
              )}
              <div className={classes.avatar}>
                <UserAvatar
                  emailHash={props.userProfile.emailHash}
                  size={deviceType === DeviceType.Mobile ? 60 : 211}
                />
              </div>
              {deviceType === DeviceType.Desktop && (
                <div className={classes.ellipsisIcon}>
                  <MenuWithOptions
                    menuIcon={faEllipsis}
                    size="lg"
                    actionsMenuItems={[
                      !props.userProfile.blocked
                        ? {
                            text: 'Block Account',
                            id: 0,
                            iconSvg: AccountBlockIcon,
                          }
                        : {
                            text: 'Unblock Account',
                            id: 1,
                            iconSvg: AccountBlockIcon,
                          },
                    ]}
                    backgroundColor={colors.box_bg}
                    onActionClick={action => {
                      if (action === 0) {
                        props.blockUser(props.userProfile.userId)
                      } else {
                        props.unblockUser(props.userProfile.userId)
                      }
                    }}
                  ></MenuWithOptions>
                </div>
              )}
            </>
          )}
        </div>
        {props.currentUserId !== props.userProfile.userId && (
          <>
            {deviceType === DeviceType.Mobile && (
              <div className={classes.wrapperRightMobile}>
                <div className={classes.wrapperRightMobileInner}>
                  <div className={classes.ellipsisIcon}>
                    <MenuWithOptions
                      menuIcon={faEllipsis}
                      size="lg"
                      actionsMenuItems={[
                        {
                          text: 'Block Account',
                          id: 0,
                          iconSvg: AccountBlockIcon,
                        },
                      ]}
                      backgroundColor={colors.box_bg}
                      onActionClick={action => {
                        if (action === 0) {
                          props.blockUser(props.userProfile.userId)
                        } else {
                          props.unblockUser(props.userProfile.userId)
                        }
                      }}
                    ></MenuWithOptions>
                  </div>

                  <ComingSoonTooltip
                    style={{ alignSelf: 'center', marginLeft: 2 }}
                    title="Coming soon (Message)"
                  >
                    <Speech />
                  </ComingSoonTooltip>
                  {!props.userProfile.isCurrentUserFollowing && (
                    <Button
                      className={classes.followBtn}
                      sx={{
                        '.MuiTouchRipple-root': {
                          display: 'none',
                        },
                      }}
                      onClick={() => {
                        props.onFollowUser(props.userProfile.userId)
                      }}
                    >
                      FOLLOW
                    </Button>
                  )}
                  {props.userProfile.isCurrentUserFollowing && (
                    <Button
                      className={classes.followingBtn}
                      sx={{
                        '.MuiTouchRipple-root': {
                          display: 'none',
                        },
                      }}
                      onClick={() => {
                        props.onUnfollowUser(props.userProfile.userId)
                      }}
                    >
                      FOLLOWING
                    </Button>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {deviceType === DeviceType.Desktop && (
          <>
            <div className={classes.username}>{props.userProfile.nickname}</div>
            <div className={classes.joinSince}>{handleCreatedAt()}</div>
          </>
        )}
        {props.currentUserId !== props.userProfile.userId && (
          <>
            {deviceType === DeviceType.Desktop && (
              <>
                {!props.userProfile.isCurrentUserFollowing && (
                  <Button
                    className={classes.followBtn}
                    sx={{
                      '.MuiTouchRipple-root': {
                        display: 'none',
                      },
                    }}
                    onClick={() => {
                      if (!props.currentUserId) {
                        props.setShowSignUpModal(true)
                        return
                      }

                      props.onFollowUser(props.userProfile.userId)
                    }}
                  >
                    FOLLOW
                  </Button>
                )}
                {props.userProfile.isCurrentUserFollowing && (
                  <Button
                    className={classes.followingBtn}
                    sx={{
                      '.MuiTouchRipple-root': {
                        display: 'none',
                      },
                    }}
                    onClick={() => {
                      props.onUnfollowUser(props.userProfile.userId)
                    }}
                  >
                    FOLLOWING
                  </Button>
                )}
              </>
            )}
            {deviceType === DeviceType.Desktop && (
              <ComingSoonTooltip title="Coming soon (Message)">
                <Button
                  className={classes.messageBtn}
                  sx={{
                    '.MuiTouchRipple-root': {
                      display: 'none',
                    },
                  }}
                >
                  MESSAGE
                </Button>
              </ComingSoonTooltip>
            )}
          </>
        )}
        {deviceType === DeviceType.Desktop && (
          <div className={classes.socialIcons}>
            {props.userProfile.twitter && (
              <FontAwesomeIcon
                icon={faTwitter}
                onClick={() => {
                  window.open(props.userProfile.twitter!, '_blank')
                }}
                className={classes.socialIcon}
              />
            )}
            {props.userProfile.reddit && (
              <FontAwesomeIcon
                icon={faReddit}
                onClick={() => {
                  window.open(props.userProfile.reddit!, '_blank')
                }}
                className={classes.socialIcon}
              />
            )}
            {props.userProfile.facebook && (
              <FontAwesomeIcon
                icon={faFacebookF}
                onClick={() => {
                  window.open(props.userProfile.facebook!, '_blank')
                }}
                className={classes.socialIcon}
              />
            )}
          </div>
        )}
      </div>
      {deviceType === DeviceType.Mobile && (
        <div>
          <div className={classes.username}>{props.userProfile.nickname}</div>
          <div className={classes.joinSince}>{handleCreatedAt()}</div>
        </div>
      )}
    </div>
  )
})
