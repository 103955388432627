import { makeStyles } from '@mui/styles'
import { colors } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'stretch',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  content: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: colors.menu_bg,
    // TODO: a hack to fix graph positioning, we need better solution
    '& canvas, svg': {
      left: 0,
    },

    [theme.breakpoints.down('lg')]: {
      flex: 'none',
      height: 230,
    },
  },
  posts: {
    backgroundColor: colors.drawer_bg,
    height: '100%',

    [theme.breakpoints.down('lg')]: {
      backgroundColor: colors.drawer_bg_right,
    },
  },
  progressWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
}))
