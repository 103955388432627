import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles({
  header: {
    fontSize: 15,
    fontFamily: fonts.dm_sans,
    color: colors.text_white,
    textAlign: 'left',
  },

  wrapper: {
    width: '100%',
    backgroundColor: colors.drawer_bg,
  },

  navigationWrapper: {
    height: 40,
    width: '100%',
    textAlign: 'left',
  },

  backArrow: {
    color: colors.textGrey,
    margin: '14px 16px 12px',
    cursor: 'pointer',
  },

  contentWrapper: {
    position: 'relative',
  },

  formWrapper: {
    position: 'relative',
    padding: '16px 32px 16px 32px',
  },

  form: {
    width: '100%',
  },

  inputWrapper: {
    marginTop: 16,
    width: '100%',
    flex: 1,
  },

  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },

  button: {
    width: '81px',
    marginBottom: 16,
    padding: '10px 0',
  },

  saveButton: {
    marginLeft: 8,
  },
})
