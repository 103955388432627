import React from 'react'

const ExclamationCircleIcon: React.FC = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_5793_60560"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="41"
      height="40"
    >
      <rect x="0.5" width="40" height="40" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_5793_60560)">
      <path
        d="M20.4988 27.8846C20.8464 27.8846 21.1342 27.7707 21.3623 27.543C21.5904 27.3152 21.7044 27.0276 21.7044 26.68C21.7044 26.3325 21.5905 26.0447 21.3628 25.8165C21.135 25.5885 20.8473 25.4744 20.4998 25.4744C20.1523 25.4744 19.8644 25.5883 19.6363 25.8161C19.4082 26.0438 19.2942 26.3315 19.2942 26.679C19.2942 27.0266 19.4081 27.3144 19.6358 27.5425C19.8636 27.7706 20.1513 27.8846 20.4988 27.8846ZM19.5143 21.936H21.6083V11.671H19.5143V21.936ZM20.5095 35.8333C18.3174 35.8333 16.2618 35.4178 14.3428 34.5867C12.4238 33.7556 10.747 32.623 9.31239 31.189C7.87775 29.755 6.7447 28.0782 5.91322 26.1587C5.08175 24.2392 4.66602 22.1826 4.66602 19.989C4.66602 17.8024 5.08157 15.7472 5.91268 13.8232C6.74379 11.8993 7.87635 10.2246 9.31035 8.79925C10.7443 7.37386 12.4211 6.24543 14.3406 5.41396C16.2602 4.58249 18.3168 4.16675 20.5104 4.16675C22.6969 4.16675 24.7522 4.58231 26.6761 5.41342C28.6001 6.24453 30.2747 7.37244 31.7001 8.79716C33.1255 10.2219 34.2539 11.8973 35.0854 13.8234C35.9169 15.7494 36.3326 17.8049 36.3326 19.9899C36.3326 22.182 35.917 24.2375 35.0859 26.1565C34.2548 28.0755 33.1269 29.7506 31.7022 31.1818C30.2774 32.6131 28.602 33.7461 26.676 34.581C24.7499 35.4159 22.6944 35.8333 20.5095 35.8333ZM20.5132 33.7394C24.3316 33.7394 27.5737 32.4035 30.2397 29.7319C32.9057 27.0602 34.2386 23.8117 34.2386 19.9862C34.2386 16.1678 32.9074 12.9256 30.2449 10.2597C27.5824 7.59369 24.3339 6.26071 20.4993 6.26071C16.6831 6.26071 13.4391 7.59196 10.7675 10.2545C8.09581 12.9169 6.75997 16.1655 6.75997 20C6.75997 23.8163 8.09581 27.0602 10.7675 29.7319C13.4391 32.4035 16.6877 33.7394 20.5132 33.7394Z"
        fill="#A0A4B2"
      />
    </g>
  </svg>
)

export default ExclamationCircleIcon
