import useStyles from './styles'
import theme from '../../mui-theme'
import React, { forwardRef, useEffect } from 'react'
import {
  faArrowLeft,
  faClose,
  faEllipsis,
  faSearch,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../constants'
import LogoWithoutText from 'components/Images/LogoWithoutText'
import { useLocation, useNavigate } from 'react-router-dom'
import { USER_TAB_ITEMS } from './constants/userTabConstants'
import UserInfo from './UserInfo'
import UserActivity from './UserActivity'
import {
  PostsDocument,
  PostsQuery,
  PostsQueryVariables,
  useBlockUserMutation,
  useChangeCommentVoteMutation,
  useChangePostVoteMutation,
  useDeleteCommentMutation,
  useDeleteCommentVoteMutation,
  useDeleteCurrentUserMutation,
  useDeletePostMutation,
  useDeletePostVoteMutation,
  useFollowUserMutation,
  useGetFollowersQuery,
  useGetFollowingQuery,
  useGetNewestCommentsForUserQuery,
  useGetNewestPostsForUserQuery,
  useUnblockUserMutation,
  useUnfollowUserMutation,
  useVoteCommentMutation,
  useVotePostMutation,
} from 'generated/graphql'
import { VoteDirection } from 'components/TopicSidebar/VoteUpDown'
import SignUpModal from 'components/auth/SignUpModal/SignUpModal'
import { onVote } from 'utils/onVote'
import { MoreActionsMenuItemIds } from 'components/PostIcons/PostIcons'
import { FollowerData, FollowingData } from './interfaces/userTabItem'
import { onCommentVote } from 'utils/onCommentVote'
import { UserProfile } from './interfaces/user'
import { FormControl, Input, Menu, MenuItem, Tab, Tabs } from '@mui/material'
import ROUTES from 'constants/routes'
import { ApolloCache, useApolloClient } from '@apollo/client'
import { SearchOverlayState } from 'components/SearchOverlay'
import {
  updateGetFollowerCacheAfterFollow,
  updateGetFollowerCacheAfterUnfollow,
  updateGetFollowingCacheAfterFollow,
  updateGetFollowingCacheAfterUnfollow,
} from './caching/userFollowCacheHandlers'
import {
  CommentData,
  PostData,
} from 'components/common/CommentWithParent/Comment.interfaces'
import { mapComments } from 'utils/mappers/commentsMapper'
import { mapPosts } from 'utils/mappers/postsMapper'
import { onShareActions } from 'utils/shareUtils'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import classNames from 'classnames'
import DeleteUserModal from 'components/auth/DeleteUserModal/DeleteUserModal'
import { useUserOperations } from 'hooks/useUserOperations'
import UserSettings from './UserSettings'
import { onCameraActions, onCameraActionsComment } from 'utils/cameraUtils'

export interface UserOverlayProps {
  hideClose?: boolean
  currentUserProfile?: UserProfile
  userProfile: UserProfile
}

export default forwardRef<HTMLInputElement, UserOverlayProps>((props, ref) => {
  const deviceType = useDeviceType()
  const classes = useStyles(theme)
  const navigate = useNavigate()
  const client = useApolloClient()
  const location = useLocation()
  const [showSignUpModal, setShowSignUpModal] = React.useState<boolean>(false)
  const [showDeleteUserModal, setShowDeleteUserModal] =
    React.useState<boolean>(false)
  const [hasMorePosts, setHasMorePosts] = React.useState(true)
  const [posts, setPosts] = React.useState<PostData[]>([])
  const [comments, setComments] = React.useState<CommentData[]>([])
  const [followings, setFollowings] = React.useState<FollowingData[]>([])
  const [followers, setFollowers] = React.useState<FollowerData[]>([])
  const [hasMoreComments, setHasMoreComments] = React.useState(true)
  const [tabValue, setTabValue] = React.useState(0)
  const [followUser] = useFollowUserMutation()
  const [unfollowUser] = useUnfollowUserMutation()
  const [deleteUser] = useDeleteCurrentUserMutation()
  const [followUnfollowMutationCompleted, setFollowUnfollowMutationCompleted] =
    React.useState(false)
  const [actionsEl, setActionsEl] = React.useState<Element | null>(null)

  const { signOut } = useUserOperations()

  const { data: currentUserFollowing } = useGetFollowingQuery({
    variables: { userId: props.currentUserProfile?.userId || '' },
    skip: !props.currentUserProfile?.userId,
    fetchPolicy: 'cache-first',
  })

  const { data: newestPostsForUser, fetchMore: fetchMoreNewestPostsForUser } =
    useGetNewestPostsForUserQuery({
      fetchPolicy: 'cache-first',
      variables: {
        userId: props.userProfile!.userId!,
        lastCreatedAt: null,
      },
    })

  const {
    data: newestCommentsForUser,
    fetchMore: fetchMoreNewestCommentsForUser,
  } = useGetNewestCommentsForUserQuery({
    fetchPolicy: 'cache-first',
    variables: {
      userId: props.userProfile!.userId!,
      lastCreatedAt: null,
    },
  })

  const { data: followingUsers, fetchMore: fetchMoreFollowingUsers } =
    useGetFollowingQuery({
      fetchPolicy: 'cache-first',
      variables: {
        userId: props.userProfile!.userId!,
      },
    })

  const { data: followerUsers, fetchMore: fetchMoreFollowerUsers } =
    useGetFollowersQuery({
      fetchPolicy: 'cache-first',
      variables: {
        userId: props.userProfile!.userId!,
      },
    })

  const onFollowUser = (userId: string): void => {
    setFollowUnfollowMutationCompleted(false)

    followUser({
      variables: { userId },
      update: cache => {
        if (props.currentUserProfile === undefined) return

        updateGetFollowingCacheAfterFollow(
          cache,
          userId,
          props,
          followings,
          followers
        )
        updateGetFollowerCacheAfterFollow(cache, userId, props)
      },
    }).then(() => {
      setFollowUnfollowMutationCompleted(true)
    })
  }

  const onUnfollowUser = (userId: string): void => {
    setFollowUnfollowMutationCompleted(false)

    unfollowUser({
      variables: { userId },
      update: (cache, { data }) => {
        if (data?.unfollowUser) {
          updateGetFollowingCacheAfterUnfollow(cache, userId, props)
          updateGetFollowerCacheAfterUnfollow(cache, userId, props)
        }
      },
    }).then(() => {
      setFollowUnfollowMutationCompleted(true)
    })
  }

  const handleActionsClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setActionsEl(event.currentTarget)
  }

  const handleActionsClose = () => {
    setActionsEl(null)
  }

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newSelectedTabValue: number
  ) => {
    setTabValue(newSelectedTabValue)
  }

  const loadMorePosts = () => {
    const lastPost = posts.slice(-1)[0]
    const lastCreatedAtTimestamp = lastPost.createdAt.getTime()

    fetchMoreNewestPostsForUser({
      variables: { lastCreatedAt: lastCreatedAtTimestamp },
    }).then(newData => {
      if (newData.data.getNewestPostsForUser.length === 0) {
        setHasMorePosts(false)
      } else {
        const newPosts = mapPosts(newData.data.getNewestPostsForUser)
        setPosts(prevPosts => [...prevPosts, ...newPosts])
      }
    })
  }

  const loadMoreComments = () => {
    const lastComment =
      comments.length > 0 ? comments[comments.length - 1] : null
    const lastCreatedAtTimestamp = lastComment ? lastComment.createdAt : null

    fetchMoreNewestCommentsForUser({
      variables: {
        userId: props.userProfile!.userId!,
        lastCreatedAt: lastCreatedAtTimestamp,
      },
    })
      .then(newData => {
        if (newData.data.getNewestCommentsForUser.length === 0) {
          setHasMoreComments(false)
        } else {
          const newComments = mapComments(newData.data.getNewestCommentsForUser)
          setComments(prevComments => [...prevComments, ...newComments])
        }
      })
      .catch(error => {
        console.error('Error fetching more comments:', error)
      })
  }

  const loadMoreFollowing = () => {}

  const [blockUser] = useBlockUserMutation()

  const blockUserHandler = (userId: string) => {
    blockUser({
      variables: { blockedUserId: userId },
    })
      .then(() => {
        // Clear the cache entirely as blocking a user will affect a number of responses
        // and we don't want to have to update all of them. Also blocking is a rare operation
        // so the performance hit of clearing the cache is acceptable.
        client.resetStore().then(() => {
          navigate('/', { replace: true })
        })
      })
      .catch(error => {
        console.error('Error during block operation:', error)
      })
  }

  const [unblockUser] = useUnblockUserMutation()

  const unblockUserHandler = (userId: string) => {
    unblockUser({
      variables: { blockedUserId: userId },
    })
      .then(() => {
        client.resetStore().then(() => {
          navigate('/', { replace: true })
        })
      })
      .catch(error => {
        console.error('Error during unblock operation:', error)
      })
  }

  const [deletePost] = useDeletePostMutation()
  const [deleteComment] = useDeleteCommentMutation()

  const votePostMutationHookResult = useVotePostMutation({
    errorPolicy: 'ignore',
  })
  const changePostVoteMutationHookResult = useChangePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const voteCommentMutationHookResult = useVoteCommentMutation({
    errorPolicy: 'ignore',
  })
  const changeCommentVoteMutationHookResult = useChangeCommentVoteMutation({
    errorPolicy: 'ignore',
  })
  const deletePostMutationHookResult = useDeletePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const deleteCommentMutationHookResult = useDeleteCommentVoteMutation({
    errorPolicy: 'ignore',
  })

  const handleSignUpModalClose = () => {
    setShowSignUpModal(false)
  }

  const handleDeleteUserModalClose = () => {
    setShowDeleteUserModal(false)
  }

  function onShareActionsClick(
    id: string,
    shareText: string,
    symbol: string,
    action: string | number,
    commentId?: string
  ) {
    onShareActions(id, action, symbol, shareText, commentId)
  }

  function onMoreActionsClick(
    postId: string,
    symbol: string,
    typeUpper: string,
    action: string | number
  ) {
    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        const reportUrl = `/asset/${symbol}/report/${postId}`
        navigate(reportUrl)
        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        const editUrl = `/asset/${symbol}/edit/${postId}`
        navigate(editUrl)
        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        console.log(
          `screens->Main->TopicSidebarIntegration->onMoreActionsClick->CASE: EDIT`
        )

        deletePost({
          variables: { postId },
          update(cache, { data }) {
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: postId,
              __typename: 'Post',
            })
            cache.evict({ id: normalizedId })
            cache.gc()

            const readQueryResult = cache.readQuery<
              PostsQuery,
              PostsQueryVariables
            >({
              query: PostsDocument,
              variables: {
                postSymbol: symbol?.toUpperCase() as string,
                postAssetType: typeUpper!,
              },
            })

            const posts = readQueryResult?.posts || []
            const updatedPosts = posts.filter(post => post.id !== postId)

            cache.writeQuery({
              query: PostsDocument,
              data: {
                posts: updatedPosts,
              },
              variables: {
                postSymbol: symbol?.toUpperCase() as string,
                postAssetType: typeUpper!,
              },
            })
          },
        })
          .then(() => {})
          .catch(e => console.log(e))

        break
      }
    }
  }

  function onMoreCommentActionsClick(
    postId: string,
    commentId: string,
    symbol: string,
    type: string,
    action: string | number
  ) {
    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        const reportUrl = `/asset/${symbol}/report/${postId}?commentid=${commentId}`
        navigate(reportUrl)
        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        const editUrl = `/asset/${symbol}/topic/${postId}?commentid=${commentId}`
        navigate(editUrl)
        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        deleteComment({
          variables: { id: commentId },
          update(cache: ApolloCache<any>, { data }: any) {
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: commentId,
              __typename: 'Comment',
            })
            cache.modify({
              id: normalizedId,
              fields: {
                deleted() {
                  return true
                },
              },
            })
            cache.gc()
          },
        })
          .then(() => {
            navigate(`${location.pathname}`, { replace: true })
          })
          .catch((e: any) => console.log(e))

        break
      }
    }
  }

  function onPostClick(id: string, symbol: string) {
    let url = `/asset/${symbol}/topic/${id}`
    navigate(url)
  }

  function onCommentClick(id: string, symbol: string, commentId: string) {
    let url = `/asset/${symbol}/topic/${id}?commentid=${commentId}`
    navigate(url)
  }

  const onClose = () => {
    navigate(-1)
  }

  const redirectToPage = () => {
    const hideState: SearchOverlayState = {
      hideClose: false,
      navigateDelta: -2,
    }

    navigate(ROUTES.SENTIMENT_DISCOVERY, {
      state: hideState,
    })
  }

  useEffect(() => {
    if (newestPostsForUser?.getNewestPostsForUser) {
      const transformedPosts = mapPosts(
        newestPostsForUser.getNewestPostsForUser
      )
      setPosts(transformedPosts)

      // Update hasMorePosts based on whether any posts were returned
      setHasMorePosts(transformedPosts.length > 0)
    }
  }, [newestPostsForUser])

  useEffect(() => {
    if (newestCommentsForUser?.getNewestCommentsForUser) {
      const transformedComments = mapComments(
        newestCommentsForUser?.getNewestCommentsForUser
      )
      setComments(transformedComments)
    }
  }, [newestCommentsForUser])

  useEffect(() => {
    if (followingUsers?.getFollowing) {
      const transformedFollowing = followingUsers?.getFollowing
        .map(newestFollowingUser => {
          const followingUser: FollowingData = {
            userId: newestFollowingUser.id,
            username: newestFollowingUser.nickname,
            emailHash: newestFollowingUser.emailHash ?? '',
            followers: newestFollowingUser.followersCount ?? 0,
            following: newestFollowingUser.followingCount ?? 0,
            isCurrentUserFollowing: !!props.currentUserProfile?.userId
              ? currentUserFollowing?.getFollowing
                  ?.map(following => following.id)
                  .includes(newestFollowingUser.id) ?? false
              : false,
            isFollowerOfCurrentUser:
              props.currentUserProfile?.followerIds?.includes(
                newestFollowingUser.id
              ) ?? false,
          }

          return followingUser
        })
        .sort((a, b) => b.followers - a.followers)

      setFollowings(transformedFollowing)
    }
  }, [followingUsers, followUnfollowMutationCompleted])

  useEffect(() => {
    if (followerUsers?.getFollowers) {
      const transformedFollower = followerUsers?.getFollowers
        .map(newestFollower => {
          const followerUser: FollowerData = {
            userId: newestFollower.id,
            username: newestFollower.nickname,
            emailHash: newestFollower.emailHash ?? '',
            followers: newestFollower.followersCount ?? 0,
            following: newestFollower.followingCount ?? 0,
            isCurrentUserFollowing: !!props.currentUserProfile?.userId
              ? currentUserFollowing?.getFollowing
                  ?.map(following => following.id)
                  .includes(newestFollower.id) ?? false
              : false,
            isFollowerOfCurrentUser:
              props.currentUserProfile?.followerIds?.includes(
                newestFollower.id
              ) ?? false,
          }

          return followerUser
        })
        .sort((a, b) => b.followers - a.followers)

      setFollowers(transformedFollower)
    }
  }, [followerUsers, followUnfollowMutationCompleted])

  return (
    <div className={classes.wrapper}>
      {deviceType === DeviceType.Desktop && (
        <div className={classes.topNavWrapper}>
          <div className={classes.logoWrapper}>
            <LogoWithoutText />
          </div>
          <div className={classes.inputWithIcon} onClick={redirectToPage}>
            <FormControl
              classes={{
                root: classes.formControlRoot,
              }}
            >
              <Input
                placeholder={'Search stocks e.g. Apple AAPL'}
                inputProps={{}}
                id={`input-search`}
                inputRef={ref}
                readOnly={true}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {}}
                name={'search'}
                classes={{
                  input: classes.input,
                  root: classes.inputRoot,
                }}
              />
            </FormControl>
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.iconSearch}
              color={colors.textGrey}
            />
          </div>
          <div className={classes.closeWithIcon} onClick={onClose}>
            <span>Close</span>
            <FontAwesomeIcon
              icon={faClose}
              className={classes.iconClose}
              color={colors.textGrey}
            />
          </div>
        </div>
      )}

      {deviceType === DeviceType.Mobile && (
        <div className={classes.topNavWrapper}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={classes.iconLeftArrow}
            color={colors.textGrey}
            onClick={onClose}
          />
          <span className={classes.username}>{props.userProfile.nickname}</span>
        </div>
      )}

      <div className={classes.bodyWrapper}>
        <div className={classes.userTabsWrapper}>
          {deviceType === DeviceType.Desktop &&
            props.currentUserProfile?.userId === props.userProfile.userId && (
              <div className={classes.tabsWrapper}>
                {props.currentUserProfile?.userId ===
                  props.userProfile.userId && (
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="user settings tabs"
                    classes={{
                      indicator: classes.selectedTabIndicator,
                      scroller: classes.selectedTabScroller,
                      root: classes.tabs,
                    }}
                  >
                    <Tab
                      disableRipple={true}
                      key={0}
                      className={classNames(
                        classes.tab,
                        tabValue === 0 ? classes.selectedTab : ''
                      )}
                      label={'Overview'}
                    />
                    <Tab
                      disableRipple={true}
                      key={1}
                      className={classNames(
                        classes.tab,
                        tabValue === 1 ? classes.selectedTab : ''
                      )}
                      label={'User settings'}
                    />
                  </Tabs>
                )}
                {tabValue === 1 && (
                  <>
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      color={colors.textGrey}
                      className={classes.moreIcon}
                      size="sm"
                      onClick={handleActionsClick}
                    />
                    <Menu
                      anchorEl={actionsEl}
                      keepMounted
                      elevation={1}
                      open={Boolean(actionsEl)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={handleActionsClose}
                      classes={{
                        root: classes.menuRoot,
                        list: classes.menuList,
                      }}
                    >
                      <MenuItem
                        sx={{
                          backgroundColor: colors.drawer_bg_right,
                          height: '48px',
                          width: '242px',
                        }}
                        onClick={() => setShowDeleteUserModal(true)}
                      >
                        <span className={classes.deleteAccountText}>
                          Delete account
                        </span>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className={classes.trashIcon}
                          size="sm"
                          onClick={handleActionsClick}
                        />
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            )}
          {tabValue === 0 && (
            <UserActivity
              currentUserId={props.currentUserProfile?.userId}
              tabs={USER_TAB_ITEMS}
              posts={posts}
              hasMorePosts={hasMorePosts}
              comments={comments}
              followings={followings}
              followers={followers}
              hasMoreComments={hasMoreComments}
              isViewingOwnProfile={
                props.currentUserProfile?.userId === props.userProfile.userId
              }
              onShareActionsClick={onShareActionsClick}
              onCameraActionsClick={onCameraActions}
              onCameraActionsCommentClick={onCameraActionsComment}
              onMoreActionsClick={onMoreActionsClick}
              onMoreCommentActionsClick={onMoreCommentActionsClick}
              onPostClick={onPostClick}
              onCommentClick={onCommentClick}
              loadMorePosts={loadMorePosts}
              loadMoreComments={loadMoreComments}
              loadMoreFollowing={loadMoreFollowing}
              onFollowUser={onFollowUser}
              onUnfollowUser={onUnfollowUser}
              setShowSignUpModal={setShowSignUpModal}
              onVote={(
                direction: VoteDirection,
                postId: string,
                userVotePositive: boolean | null | undefined
              ) => {
                if (!props.currentUserProfile?.userId) {
                  setShowSignUpModal(true)
                  return
                }

                onVote({
                  direction,
                  postId,
                  changePostVoteMutationHookResult,
                  votePostMutationHookResult,
                  deletePostMutationHookResult,
                  userId: props.currentUserProfile?.userId,
                  userVotePositive,
                  callback: () => {
                    console.log('onVote callback')
                  },
                })
              }}
              onCommentVote={(
                direction: VoteDirection,
                commentId: string,
                userVotePositive: boolean | null | undefined
              ) => {
                if (!props.currentUserProfile?.userId) {
                  setShowSignUpModal(true)
                  return
                }

                onCommentVote({
                  direction,
                  commentId,
                  userId: props.currentUserProfile?.userId,
                  changeCommentVoteMutationHookResult,
                  voteCommentMutationHookResult,
                  deleteCommentMutationHookResult,
                  userVotePositive,
                })
              }}
            ></UserActivity>
          )}
          {tabValue === 1 && props.currentUserProfile && (
            <UserSettings currentUser={props.currentUserProfile} />
          )}
        </div>
        <UserInfo
          currentUserId={props.currentUserProfile?.userId}
          userProfile={props.userProfile}
          onFollowUser={onFollowUser}
          onUnfollowUser={onUnfollowUser}
          setShowSignUpModal={setShowSignUpModal}
          blockUser={blockUserHandler}
          unblockUser={unblockUserHandler}
        ></UserInfo>
      </div>
      <SignUpModal open={showSignUpModal} onClose={handleSignUpModalClose} />
      {props.currentUserProfile?.userId === props.userProfile.userId && (
        <DeleteUserModal
          email={props.currentUserProfile?.email!}
          username={props.currentUserProfile?.nickname!}
          open={showDeleteUserModal}
          onClose={handleDeleteUserModalClose}
          deleteAccount={() => {
            deleteUser()
              .then(({ data }) => {
                signOut()
              })
              .catch(error => {
                console.error('Error deleting account', error)
              })
          }}
        />
      )}
    </div>
  )
})
