import ROUTES from 'constants/routes'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SuccessBox from '../components/auth/SuccessBox'
import CenteredBoxScreenWrapper from '../components/auth/common/CenteredBoxScreenWrapper'

const ResetPasswordSuccess: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(ROUTES.SENTIMENT_DISCOVERY)
    }, 2000)

    return () => clearTimeout(timeout)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CenteredBoxScreenWrapper>
      <SuccessBox text="Your password has been updated." />
    </CenteredBoxScreenWrapper>
  )
}

export default ResetPasswordSuccess
