import { makeStyles } from '@mui/styles'
import { colors } from '../../../constants'
import theme from '../../../mui-theme'

export default makeStyles({
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.down('lg')]: {
      padding: '12px 16px 12px 16px',
      marginBottom: 0,
    },
  },

  avatarWrapper: {
    width: 32,
    height: 32,
  },

  unreadNotification: {
    backgroundColor: colors.box_bg,
  },

  notificationContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    width: 'calc(100% - 32px)',
    boxSizing: 'border-box',
  },

  notificationTopContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  notificationBottomContentWrapper: {
    color: colors.white_faded,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 4,
  },

  nickname: {
    color: colors.blue_lighter,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  symbol: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '22px',
    color: colors.cyan_blue_light_faded,
    marginLeft: 8,
    textDecoration: 'none',
  },

  date: {
    marginLeft: 8,
    color: colors.textGreyLight,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  text: {
    color: colors.white_faded,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
})
