const MessageIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9C6 8.44772 6.44772 8 7 8H26C26.5523 8 27 8.44772 27 9V23C27 23.5523 26.5523 24 26 24H7C6.44772 24 6 23.5523 6 23V9Z"
      stroke="#A0A4B2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g transform="translate(6, 10) scale(0.92, 1)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 0.631948L11.4785 6.38189L0 0.631828V2.86874L10.5828 8.17007C11.1456 8.45202 11.8084 8.45252 12.3716 8.17141L23 2.86718V0.631948Z"
        fill="#A0A4B2"
      />
    </g>
  </svg>
)

export default MessageIcon
