import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

const inputHeight = 26

export default makeStyles(
  {
    root: {
      width: '100%',
    },

    input: {
      width: '100%',
      height: inputHeight,
      color: colors.text_white,
      padding: 0,
      fontSize: 12,
      fontFamily: fonts.dm_sans,
    },

    underline: {
      '&:after': {
        borderBottom: `1px solid ${colors.blue_light}`,
        position: 'absolute',
        left: 0,
        bottom: '-1px',
      },
    },

    noUnderline: {
      borderBottom: 'none',
    },

    inputBox: {
      width: '100%',
      marginTop: 0,

      '.MuiFormControl-root': {
        width: '100%',
      },
    },

    inputRoot: {
      padding: '0px 10px',
      boxSizing: 'border-box',
      backgroundColor: colors.input_bg,
      width: '100%',
      height: 42,

      '&:before': {
        content: `''`,
        borderBottom: 'none',
      },

      '&:hover': {
        backgroundColor: colors.input_bg,
      },

      '&.Mui-focused': {
        backgroundColor: colors.input_bg,
      },
    },

    inputRootUnderline: {
      borderBottomWidth: 1,
      borderBottomColor: colors.text_input_inactive,
      borderBottomStyle: 'solid',
    },

    formHelperRoot: {
      fontSize: 10,
      lineHeight: 1,
      marginTop: 5,
    },

    hideDialogActions: {
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  },
  { index: 1 }
)
