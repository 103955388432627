import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../constants'
import theme from '../../mui-theme'

export default makeStyles<typeof theme>(theme => ({
  wrapper: {
    fontFamily: fonts.dm_sans,
    height: '100%',
    width: '100%',
    alignItems: 'stretch',
    position: 'relative',
    backgroundColor: colors.black,
    overflowY: 'auto',
  },
  content: {
    marginTop: 32,
    marginBottom: 88,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.black,
  },
  startBtn: {
    boxShadow: 'none',
    color: colors.white,
    fontFamily: fonts.dm_sans,
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
    height: 50,
    width: 293,
    border: `1px solid ${colors.blue}`,
    borderRadius: 20,
    backgroundColor: colors.blue,
    padding: '12px 32px',
    textTransform: 'none',
    '&:hover': {
      color: colors.white,
    },
  },
  logoWrapper: {
    paddingTop: 161,
  },

  stockTag: {
    width: 558,
    fontSize: 40,
    fontWeight: 500,
    lineHeight: '46px',
    letterSpacing: 1,
  },

  headingText: {
    width: 1112,
    color: colors.text_white,
    fontSize: 48,
    fontWeight: 500,
    lineHeight: '52px',
    letterSpacing: -0.4,
  },

  bullsAndBearsVideo: {
    marginTop: 48,
  },

  aboutText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 1112,
    color: colors.text_white,
    marginTop: 31,
    fontSize: 40,
    fontWeight: 500,
    letterSpacing: -0.4,
  },
  aboutTextWrapper: {
    width: 407,
  },
  aboutVideoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 1162,
  },
  boxWrapper: {
    paddingTop: 47,
    display: 'flex',
    justifyContent: 'space-between',
    width: 1162,
  },
  box: {
    backgroundColor: colors.box_bg,
    width: 257,
    height: 'auto',
  },
  innerBox: {
    margin: '32px 24px',
  },
  icon: {
    color: colors.white,
  },
  pencilIcon: {
    color: colors.blue,
  },
  messageIcon: {
    color: colors.very_soft_blue,
  },
  arrowUp: {
    color: colors.blue,
  },
  arrowDown: {
    color: colors.red_light,
    paddingLeft: 9,
  },
  boxTitle: {
    fontSize: 18,
    lineHeight: '24px',
    color: colors.text_white,
    marginTop: 24,
  },
  boxText: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.textGrey,
    marginTop: 8,
  },
}))
