import { Box } from '@mui/material'
import React from 'react'
import AuthBoxContainer from './common/AuthBoxContainer'
import AuthSubmitButton from './common/AuthSubmitButton'

interface SuccessBoxProps {
  text: string
}

const SuccessBox: React.FC<SuccessBoxProps> = props => (
  <AuthBoxContainer headingText={props.text} descriptionText="">
    <form>
      <Box
        sx={{
          mt: 3,
          mb: 3,
        }}
      ></Box>
      <AuthSubmitButton>GO TO SEARCH STOCKS</AuthSubmitButton>
    </form>
  </AuthBoxContainer>
)

export default SuccessBox
