import useStyles from './styles'
import theme from '../../../mui-theme'
import Button from '@mui/material/Button'

interface Props {
  onClick: () => void
  value?: string
}

export default function SignUpButton(props: Props) {
  const classes = useStyles(theme)

  return (
    <Button
      onClick={() => props.onClick()}
      className={classes.signUpBtn}
      sx={{
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      }}
    >
      {!!props.value ? props.value : 'SignUp'}
    </Button>
  )
}
