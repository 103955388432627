import * as React from 'react'

function LogoWithoutTextImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 82 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.1295 82.4107C60.3784 82.4107 54.667 76.2913 54.667 68.5402C54.667 61.197 60.7863 55.0776 68.1295 55.0776C75.8807 55.0776 82 61.197 82 68.5402C82 76.2913 75.8807 82.4107 68.1295 82.4107Z"
        fill="#9AA5EF"
      />
      <path
        d="M0.40795 0.819458H81.9991V28.1525H54.6661V55.0776H27.333V82.4106H0V0.819458H0.40795Z"
        fill="#4C61EE"
      />
    </svg>
  )
}

export default LogoWithoutTextImage
