import Box from '@mui/material/Box'
import React from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  CommentDataFragment,
  CommentDataFragmentDoc,
  CommentsDataFragment,
  CommentsDataFragmentDoc,
  ListChildCommentsDocument,
  ListChildCommentsQuery,
  ListChildCommentsQueryVariables,
  PostDataFragment,
  PostDataFragmentDoc,
  UpdateCommentInput,
  useChangeCommentVoteMutation,
  useChangePostVoteMutation,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useDeleteCommentVoteMutation,
  useDeletePostMutation,
  useDeletePostVoteMutation,
  useGetCommentQuery,
  useGetPostQuery,
  useGetRelatedPostsQuery,
  useUpdateCommentMutation,
  useVoteCommentMutation,
  useVotePostMutation,
} from '../../generated/graphql'

import { MoreActionsMenuItemIds } from 'components/PostIcons/PostIcons'
import { trimURL } from 'utils/urlUtils'
import { DEBUG_ENV } from '../../index'
import ViewTopic from 'components/TopicSidebar/ViewTopic'
import ViewTopicMobile from 'components/TopicSidebar/ViewTopicMobile'
import { onShareActions } from 'utils/shareUtils'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { useEmailNotification } from 'hooks/useEmailNotification'
import { mapPost } from 'utils/mappers/postsMapper'
import { onCameraActions, onCameraActionsComment } from 'utils/cameraUtils'
import { mapSingleComment } from 'utils/mappers/commentsMapper'

interface ViewTopicIntegrationProps {
  userId?: string
  nickname?: string | null
  type?: string
  ticker?: string
  replyNotificationEmail: boolean
  setSignUpModalOpen: (isOpen: boolean) => void
}

const ViewTopicIntegration: React.FC<ViewTopicIntegrationProps> = props => {
  const deviceType = useDeviceType()
  const votePostMutationHookResult = useVotePostMutation({
    errorPolicy: 'ignore',
  })
  const changePostVoteMutationHookResult = useChangePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const deletePostMutationHookResult = useDeletePostVoteMutation({
    errorPolicy: 'ignore',
  })
  const voteCommentMutationHookResult = useVoteCommentMutation({
    errorPolicy: 'ignore',
  })
  const changeCommentVoteMutationHookResult = useChangeCommentVoteMutation({
    errorPolicy: 'ignore',
  })
  const deleteCommentMutationHookResult = useDeleteCommentVoteMutation({
    errorPolicy: 'ignore',
  })
  const [deletePost] = useDeletePostMutation()

  const {
    topicId: postId,
    type,
    symbol,
  } = useParams<'type' | 'symbol' | 'topicId'>()
  const [searchParams, setSearchParams] = useSearchParams()

  const commentid = searchParams.get('commentid')
  const date: string | null = searchParams.get('date')
  const navigate = useNavigate()
  const location = useLocation()

  const { data: getPost } = useGetPostQuery({
    variables: {
      id: postId!,
    },
  })

  const post = getPost?.getPost ? mapPost(getPost.getPost) : undefined

  const { data: getComment } = useGetCommentQuery({
    variables: {
      id: commentid!,
    },
    skip: !commentid,
  })

  const { data: getRelatedPosts } = useGetRelatedPostsQuery({
    variables: {
      postId: postId!,
    },
  })

  const commentWithParent = getComment?.getComment
    ? mapSingleComment(getComment.getComment)
    : undefined

  const { emailNotification, handleEmailNotificationChange } =
    useEmailNotification()

  const [createComment, createCommentState] = useCreateCommentMutation()
  const [updateComment, updateCommentState] = useUpdateCommentMutation()
  const [deleteComment] = useDeleteCommentMutation()

  if (DEBUG_ENV) console.debug(`screens->Main->ViewTopicIntegration`)

  /*****************************************************************************************************************************
   * TOPIC - MORE ACTIONS
   *****************************************************************************************************************************/
  function onMoreActionsTopic(postId: string, action: string | number) {
    if (DEBUG_ENV)
      console.debug(
        'screen->Main->TopicSideBarIntegration->onMoreActionsTopic FIRED'
      )
    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        navigate(
          `/${type!.toLowerCase()}/${symbol!.toLowerCase()}/report/${postId}`,
          { replace: true }
        )

        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->TopicSidebarIntegration->onMoreActionsTopic->CASE: EDIT`
          )

        navigate(
          `/${type!.toLowerCase()}/${symbol!.toLowerCase()}/edit/${postId}`
        )

        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->TopicSidebarIntegration->onMoreActionsTopic->CASE: DELETE`
          )

        deletePost({
          variables: { postId },
          update(cache, { data }) {
            // TODO: move to separate function
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: postId,
              __typename: 'Post',
            })
            cache.evict({ id: normalizedId })
            cache.gc()
          },
        })
          .then(() => {
            if (DEBUG_ENV)
              console.debug(`location.pathname (${location.pathname})`)
            if (DEBUG_ENV)
              console.debug(
                `Navigate to: fixed_path(${trimURL(location.pathname)}))`
              )

            navigate(`${trimURL(location.pathname)}`, { replace: true })
          })
          .catch(e => {
            if (DEBUG_ENV) console.error(e)
          })

        break
      }
    }
  }

  /*****************************************************************************************************************************
   * COMMENT - MORE ACTIONS
   *****************************************************************************************************************************/
  const onMoreActionsComment = (
    id: string,
    action: string | number,
    commentId: string,
    state: {
      get: boolean
      set: Function
    }
  ) => {
    if (DEBUG_ENV)
      console.debug(
        `screens->Main->ViewTopicIntegration->onMoreActionsComment FIRED`
      )
    if (DEBUG_ENV) console.debug(`id: ${commentId}, action: ${action}`)

    switch (action) {
      case MoreActionsMenuItemIds.REPORT: {
        navigate(`/${type}/${symbol}/report/${id}?commentid=${commentId}`, {
          replace: true,
        })
        break
      }

      case MoreActionsMenuItemIds.EDIT: {
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->ViewTopicIntegration->onMoreActionsComment->CASE: EDIT`
          )
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->ViewTopicIntegration->onMoreActionsComment -- isRespondCommentExpanded?: ${state.get}`
          )

        state.set(!state.get)

        break
      }

      case MoreActionsMenuItemIds.DELETE: {
        if (DEBUG_ENV)
          console.debug(
            `screens->Main->ViewTopicIntegration->onMoreActionsComment->CASE: DELETE`
          )
        deleteComment({
          variables: { id: commentId },
          update(cache, { data }) {
            // TODO: move to separate function
            if (!data) {
              return
            }

            const normalizedId = cache.identify({
              id: commentId,
              __typename: 'Comment',
            })
            cache.modify({
              id: normalizedId,
              fields: {
                deleted() {
                  return true
                },
              },
            })
            cache.gc()
          },
        })
          .then(() => {
            if (DEBUG_ENV)
              console.debug(`DELETE DONE!  Redirect to :${location.pathname}`)
            navigate(`${location.pathname}`, { replace: true })
          })
          .catch(e => {
            if (DEBUG_ENV) console.error(e)
          })
        break
      }
    }
  }

  const onCommentReply = (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    state: {
      get: boolean
      set: Function
    },
    commentId?: string | undefined
  ) => {
    if (DEBUG_ENV) console.debug(`inside: ${commentId} ${text}`)

    // if user doesn't exist, open modal
    if (!props.userId) {
      props.setSignUpModalOpen(true)
      return Promise.resolve()
    }

    handleEmailNotificationChange(replyNotificationEmail)

    return createComment({
      refetchQueries: [],
      update(cache, { data }) {
        if (!data) {
          return
        }

        // add nickname as it doesn't get returned
        const createdComment = {
          ...data.createComment,
          user: data.createComment.user
            ? {
                ...data.createComment.user,
                nickname: props.nickname,
              }
            : null,
        }

        // Comment that is a child of a comment was added.
        if (commentId) {
          cache.updateQuery<
            ListChildCommentsQuery,
            ListChildCommentsQueryVariables
          >(
            { query: ListChildCommentsDocument, variables: { commentId } },
            createChildCommentsData => {
              const comments = createChildCommentsData?.listChildComments
              // Only add item when there are items in the cache already.
              // Otherwise leave empty so that they are fetched when user expands comments.
              if (comments && comments.length) {
                return {
                  listChildComments: [createdComment, ...comments].sort(
                    (a, b) => {
                      return b.createdAt - a.createdAt
                    }
                  ),
                }
              }
            }
          )
          cache.updateFragment<CommentDataFragment>(
            { id: `Comment:${commentId}`, fragment: CommentDataFragmentDoc },
            commentData => {
              return commentData
                ? {
                    ...commentData,
                    numberOfReplies: commentData?.numberOfReplies
                      ? commentData.numberOfReplies + 1
                      : 1,
                  }
                : null
            }
          )

          cache.updateFragment<PostDataFragment>(
            {
              id: `Post:${postId}`,
              fragment: PostDataFragmentDoc,
              fragmentName: 'PostData',
            },
            postData => {
              return postData
                ? {
                    ...postData,
                    numberOfReplies: postData?.numberOfReplies
                      ? postData.numberOfReplies + 1
                      : 1,
                  }
                : null
            }
          )
          // Comment that is a direct child of a post was added.
        } else {
          cache.updateFragment<PostDataFragment>(
            {
              id: `Post:${postId}`,
              fragment: PostDataFragmentDoc,
              fragmentName: 'PostData',
            },
            postData => {
              return postData
                ? {
                    ...postData,
                    numberOfReplies: postData?.numberOfReplies
                      ? postData.numberOfReplies + 1
                      : 1,
                  }
                : null
            }
          )

          cache.updateFragment<CommentsDataFragment>(
            {
              id: `Post:${postId}`,
              fragment: CommentsDataFragmentDoc,
              fragmentName: 'CommentsData',
            },
            commentsData => {
              return commentsData
                ? {
                    ...commentsData,
                    comments: [...commentsData.comments!, createdComment].sort(
                      (a, b) => {
                        return b.createdAt - a.createdAt
                      }
                    ),
                  }
                : null
            }
          )
        }

        if (state.get) state.set(!state.get)
      },
      variables: {
        data: {
          postId: postId!,
          text,
          parentCommentId: commentId,
          additionalSymbols: symbols,
        },
      },
    })
  }

  const onUpdateComment = (
    text: string,
    symbols: string[],
    replyNotificationEmail: boolean,
    commentId: string,
    state: { get: boolean; set: Function }
  ) => {
    if (DEBUG_ENV) console.debug(`inside: ${commentId} ${text}`)

    handleEmailNotificationChange(replyNotificationEmail)

    const payload: UpdateCommentInput = {
      commentId,
      text,
      additionalSymbols: symbols,
    }

    const sendData = async () => {
      return await updateComment({
        variables: {
          data: payload,
        },
        async update(cache, { data }) {
          if (!data) {
            return
          }

          cache.updateFragment<CommentDataFragment>(
            { id: `Comment:${commentId}`, fragment: CommentDataFragmentDoc },
            commentData => {
              return commentData
                ? {
                    ...commentData,
                    text,
                  }
                : null
            }
          )
        },
      })
    }

    const result = sendData()
      .then(() => {
        if (state.get) state.set(!state.get)
      })
      .then(() => {
        navigate(location.pathname, { replace: true })
      })

    return result
  }

  function onShareActionsClick(
    id: string,
    action: string | number,
    commentText: string,
    commentId?: string
  ) {
    onShareActions(id, action, symbol!, commentText, commentId)
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {deviceType === DeviceType.Desktop && (
        <ViewTopic
          userId={props.userId}
          post={post}
          commentWithParent={commentWithParent}
          commentid={commentid}
          getPost={getPost}
          getComment={getComment}
          getRelatedPosts={getRelatedPosts}
          replyNotificationEmail={props.replyNotificationEmail}
          onMoreActionsComment={onMoreActionsComment}
          onCameraActionsClick={onCameraActions}
          onCameraActionsCommentClick={onCameraActionsComment}
          onShareActionsClick={onShareActionsClick}
          onCommentReply={onCommentReply}
          onUpdateComment={onUpdateComment}
          onMoreActionsTopic={onMoreActionsTopic}
          setSignUpModalOpen={props.setSignUpModalOpen}
          changeCommentVoteMutationHookResult={
            changeCommentVoteMutationHookResult
          }
          voteCommentMutationHookResult={voteCommentMutationHookResult}
          deleteCommentMutationHookResult={deleteCommentMutationHookResult}
          changePostVoteMutationHookResult={changePostVoteMutationHookResult}
          votePostMutationHookResult={votePostMutationHookResult}
          deletePostMutationHookResult={deletePostMutationHookResult}
        />
      )}
      {deviceType === DeviceType.Mobile && (
        <ViewTopicMobile
          userId={props.userId}
          post={post}
          commentWithParent={commentWithParent}
          commentid={commentid}
          replyNotificationEmail={props.replyNotificationEmail}
          getPost={getPost}
          getComment={getComment}
          getRelatedPosts={getRelatedPosts}
          onMoreActionsComment={onMoreActionsComment}
          onShareActionsClick={onShareActionsClick}
          onCameraActionsClick={onCameraActions}
          onCameraActionsCommentClick={onCameraActionsComment}
          onCommentReply={onCommentReply}
          onUpdateComment={onUpdateComment}
          onMoreActionsTopic={onMoreActionsTopic}
          setSignUpModalOpen={props.setSignUpModalOpen}
          changeCommentVoteMutationHookResult={
            changeCommentVoteMutationHookResult
          }
          voteCommentMutationHookResult={voteCommentMutationHookResult}
          deleteCommentMutationHookResult={deleteCommentMutationHookResult}
          changePostVoteMutationHookResult={changePostVoteMutationHookResult}
          votePostMutationHookResult={votePostMutationHookResult}
          deletePostMutationHookResult={deletePostMutationHookResult}
        />
      )}
    </Box>
  )
}

export default ViewTopicIntegration
