import React, { useEffect, useRef } from 'react'
import UserAvatar from 'components/UserAvatar'
import LoginButton from 'components/NavigationFrame/LoginButton'
import SignUpButton from 'components/NavigationFrame/SignUpButton'
import useStyles from './styles'
import theme from '../../../mui-theme'
import MenuIcon from '@material-ui/icons/Menu'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import NotificationBell from 'components/Notification/NotificationBell'
import {
  GetNotificationsDocument,
  GetUnreadNotificationsCountDocument,
  useGetNotificationsQuery,
  useGetUnreadNotificationsCountQuery,
} from 'generated/graphql'
import NotificationPopup from 'components/Notification/NotificationPopup'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import {
  IBaseNotification,
  IPostReplyNotification,
  IPricePredictionResultNotification,
} from 'types/notification'

// Define the types for your props
interface AuthToolbarProps {
  isLoggedIn?: boolean
  email?: string
  logIn: () => void
  signUp: () => void
  goToUserSettingsMobile: () => void
  setOpenRight?: (open: boolean) => void
}

const AuthToolbar: React.FC<AuthToolbarProps> = ({
  isLoggedIn,
  email,
  logIn,
  signUp,
  goToUserSettingsMobile,
  setOpenRight,
}) => {
  const classes = useStyles(theme)
  const client = useApolloClient()
  const navigate = useNavigate()
  const deviceType = useDeviceType()
  const popupRef = useRef<HTMLDivElement>(null)
  const bellRef = useRef<HTMLDivElement>(null)
  const [showNotificationPopup, setShowNotificationPopup] =
    React.useState<boolean>(false)

  const { data: getUnreadNotifications } = useGetUnreadNotificationsCountQuery({
    fetchPolicy: 'cache-first',
  })

  const { data: getNotifications } = useGetNotificationsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      limit: 3,
    },
    skip: !showNotificationPopup,
  })

  const mapNotification = (
    item: any
  ): IPostReplyNotification | IPricePredictionResultNotification | null => {
    if (
      item.body.__typename === 'PostReply' ||
      item.body.__typename === 'CommentReply'
    ) {
      return {
        commentId: item.body.comment.id,
        postId: item.body.comment.post?.id,
        assetType: item.body.comment.post?.postAssetType,
        text: item.body.comment.text,
        createdAt: item.body.comment.createdAt,
        nickname: item.body.comment.user?.nickname,
        emailHash: item.body.comment.user?.emailHash,
        symbol: item.body.comment.post?.postSymbol,
        read: item.read,
      }
    } else if (item.body.__typename === 'PricePredictionResult') {
      return {
        read: item.read,
        roundId: item.body.vote.round.id,
        symbol: item.body.vote.round.symbolObj.symbol,
        endOfRoundTimestamp: item.body.vote.round.endOfRoundTimestamp,
      }
    }
    return null
  }

  const notifications: IBaseNotification[] =
    (getNotifications?.getNotifications.notifications
      .map(mapNotification)
      .filter(n => n !== null) as IBaseNotification[]) || []

  const updateCacheAfterPopupClosed = () => {
    // Update the notifications read status
    const notificationsData = client.readQuery({
      query: GetNotificationsDocument,
      variables: { limit: 3 },
    })

    if (notificationsData) {
      const updatedNotifications =
        notificationsData.getNotifications.notifications.map(
          (notification: any) => ({
            ...notification,
            read: true,
          })
        )

      client.writeQuery({
        query: GetNotificationsDocument,
        variables: { limit: 3 },
        data: {
          getNotifications: {
            ...notificationsData.getNotifications,
            notifications: updatedNotifications,
          },
        },
      })
    }

    // Update the unread notifications count
    const unreadNotificationsData = client.readQuery({
      query: GetUnreadNotificationsCountDocument,
    })

    if (unreadNotificationsData) {
      client.writeQuery({
        query: GetUnreadNotificationsCountDocument,
        data: {
          getUnreadNotificationsCount: 0,
        },
      })
    }
  }

  useEffect(() => {
    // When the popup is closed
    if (!showNotificationPopup) {
      updateCacheAfterPopupClosed()
    }
  }, [showNotificationPopup])

  useEffect(() => {
    // Function to check if clicked outside of popup
    const handleClickOutside = (event: any) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        bellRef.current &&
        !bellRef.current.contains(event.target)
      ) {
        setShowNotificationPopup(false)
      }
    }

    // Add click event listener
    document.addEventListener('mousedown', handleClickOutside)

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setShowNotificationPopup])

  return (
    <div className={classes.authToolbar}>
      {deviceType === DeviceType.Mobile && (
        <div className={classes.userAvatar}>
          {isLoggedIn && (
            <UserAvatar
              email={email}
              onClick={() => goToUserSettingsMobile && goToUserSettingsMobile()}
            />
          )}
          {!isLoggedIn && (
            <IconButton
              onClick={() => goToUserSettingsMobile && goToUserSettingsMobile()}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>
      )}
      {deviceType === DeviceType.Desktop && (
        <>
          {isLoggedIn && email && (
            <>
              <div className={classes.notificationBellWrapper}>
                <div ref={bellRef}>
                  <NotificationBell
                    unreadNotifications={
                      getUnreadNotifications?.getUnreadNotificationsCount || 0
                    }
                    onClick={() =>
                      setShowNotificationPopup(!showNotificationPopup)
                    }
                  />
                </div>
                {showNotificationPopup && (
                  <div ref={popupRef}>
                    <NotificationPopup
                      notifications={notifications}
                      onClose={() => setShowNotificationPopup(false)}
                    />
                  </div>
                )}
              </div>
              <div className={classes.userAvatar}>
                <UserAvatar
                  email={email}
                  onClick={() => setOpenRight && setOpenRight(true)}
                />
              </div>
            </>
          )}
          {!isLoggedIn && (
            <div className={classes.buttonsWrapper}>
              <LoginButton onClick={logIn} />
              <SignUpButton onClick={signUp} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default AuthToolbar
