import { makeStyles } from '@mui/styles'
import { colors, fonts } from '../../../constants'

export default makeStyles(
  {
    signUpBtn: {
      boxShadow: 'none',
      color: colors.white,
      fontFamily: fonts.dm_sans,
      letterSpacing: 2.75,
      fontSize: 12,
      lineHeight: '12px',
      fontWeight: 'bold',
      height: 32,
      width: 112,
      border: `1px solid ${colors.white}`,
      borderRadius: 20,

      '&:hover': {
        color: colors.menu_bg,
        backgroundColor: colors.white,
        boxShadow: 'none',
      },
    }
  },
  { index: 1 }
)
