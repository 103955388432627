import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { useForm } from 'react-hook-form'
import { colors, fonts } from '../../constants'

import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Link from '@mui/material/Link'
import * as R from 'ramda'
import { UserLoginer } from '../../auth/userAuth'
import { regex } from '../../constants'
import TextInput from '../inputs/Text'
import AuthBoxContainer from './common/AuthBoxContainer'
import AuthSubmitButton from './common/AuthSubmitButton'
import RedirectFooter from './common/RedirectFooter'
import SocialButton from './common/SocialButton'
import { SocialProvider } from './common/SocialSignupOption'
import SpaceBetween from './common/SpaceBetween'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'
import { LoadingProgress } from 'components/LoadingProgress'

interface Props {
  // TO DO: add proper type for variables
  onSubmit: (variables: UserLoginer) => void
  onSocial: (provider: SocialProvider) => void
  onForgotPassword: () => void
  errors?: {
    password?: string
    email?: string
  }
  loading?: boolean
  redirectTo?: string | null
}

const LoginBox: React.FC<Props> = props => {
  const deviceType = useDeviceType()
  const { register, handleSubmit, errors } = useForm<UserLoginer>()

  const onSubmit = (data: UserLoginer) => {
    props.onSubmit(R.dissoc('password_confirm', data) as UserLoginer)
  }

  const handleSocialLogin = (provider: CognitoHostedUIIdentityProvider) => {
    if (props.redirectTo) {
      localStorage.setItem('redirectTo', props.redirectTo)
    }

    Auth.federatedSignIn({ provider })
  }

  return (
    <AuthBoxContainer headingText="Log In">
      <SocialButton
        text={'Continue with Google'}
        iconUrl={'./media/GoogleIcon.png'}
        onHandleClick={() =>
          handleSocialLogin(CognitoHostedUIIdentityProvider.Google)
        }
        socialProvider={'google'}
      />
      <SocialButton
        text={'Continue with Facebook'}
        iconUrl={'./media/FacebookIcon.png'}
        socialProvider={'facebook'}
        onHandleClick={() =>
          handleSocialLogin(CognitoHostedUIIdentityProvider.Facebook)
        }
      />
      <SocialButton
        text={'Continue with Amazon'}
        iconUrl={'./media/AmazonIcon.png'}
        socialProvider={'amazon'}
        onHandleClick={() =>
          handleSocialLogin(CognitoHostedUIIdentityProvider.Amazon)
        }
      />
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          borderTopWidth: 1,
          borderTopColor: colors.textGrey,
          borderTopStyle: 'solid',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: deviceType === DeviceType.Mobile ? '34px' : '40px',
          marginBottom: deviceType === DeviceType.Mobile ? '34px' : '40px',
        }}
      >
        <Box
          component="span"
          sx={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            fontFamily: fonts.dm_sans,
            fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
            lineHeight: deviceType === DeviceType.Mobile ? '20px' : '18px',
            fontWeight: deviceType === DeviceType.Mobile ? 400 : 'lighter',
            backgroundColor:
              deviceType === DeviceType.Mobile
                ? colors.drawer_bg_right
                : colors.box_bg,
            padding: '0 7px',
          }}
        >
          OR
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SpaceBetween spaceSize={4} sx={{ mb: 4, mt: 3 }}>
          <TextInput
            name="email"
            label="Email"
            type="email"
            ref={register({
              required:
                'Please enter your email address. This is a required field.',
              pattern: {
                value: regex.email,
                message: 'You have entered an invalid email address.',
              },
            })}
            error={
              (errors.email && errors.email.message) ||
              (props.errors && props.errors.email)
            }
          />

          <TextInput
            name="password"
            label="Password"
            type="password"
            ref={register({
              required: 'Please enter your password. This is a required field.',
            })}
            error={errors.password?.message || props.errors?.password}
          />
        </SpaceBetween>
        <AuthSubmitButton>Log in</AuthSubmitButton>
      </form>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <FormControlLabel
          control={
            <Box
              sx={{
                backgroundColor: colors.input_bg,
                width: 16,
                height: 16,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderColor: colors.border_light_grey,

                '& input': {
                  width: 0,
                  height: 0,
                  opacity: 0,
                  position: 'absolute',
                  '& + svg': {
                    display: 'none',
                  },
                  '&:checked + svg': {
                    display: 'block',
                  },
                },
              }}
            >
              <input type="checkbox" name="remember_user" ref={register} />
              <FontAwesomeIcon
                icon={faCheck}
                size="xs"
                color={colors.textGrey}
              />
            </Box>
          }
          label="Remember me"
          sx={{
            '.MuiFormControlLabel-label': {
              fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
              fontFamily: fonts.dm_sans,
              fontWeight: deviceType === DeviceType.Mobile ? 400 : 400,
              ml: 1,
              color: colors.text_white,
            },
            ml: 0,
          }}
        />
        <Link
          sx={{
            fontSize: deviceType === DeviceType.Mobile ? 14 : 12,
            fontFamily: fonts.dm_sans,
            color: colors.blue_lighter,
            textDecoration: 'none',
          }}
          component="button"
          variant="body1"
          onClick={() => props.onForgotPassword()}
        >
          Forgot Password
        </Link>
      </Box>
      <RedirectFooter
        text="Don't have an account?"
        urlText="Sign up"
        url="signup"
      ></RedirectFooter>
      {props.loading && <LoadingProgress />}
    </AuthBoxContainer>
  )
}

export default LoginBox
