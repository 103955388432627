import {
  VoteCommentMutationHookResult,
  ChangeCommentVoteMutationHookResult,
  CommentDataFragment,
  CommentDataFragmentDoc,
  DeleteCommentVoteMutationHookResult,
} from '../generated/graphql'
import { VoteDirection } from '../components/TopicSidebar/VoteUpDown'
import gAnalytics, { gaVoteEvent } from 'utils/analytics/ga'

interface OnVoteProps {
  direction: VoteDirection
  commentId: string
  userId?: string
  voteCommentMutationHookResult: VoteCommentMutationHookResult
  changeCommentVoteMutationHookResult: ChangeCommentVoteMutationHookResult
  deleteCommentMutationHookResult: DeleteCommentVoteMutationHookResult
  userVotePositive?: boolean | null
}

export function onCommentVote(props: OnVoteProps) {
  const {
    direction,
    commentId,
    voteCommentMutationHookResult,
    changeCommentVoteMutationHookResult,
    deleteCommentMutationHookResult,
    userVotePositive,
  } = props

  const [voteComment] = voteCommentMutationHookResult
  const [changeCommentVote] = changeCommentVoteMutationHookResult
  const [deleteCommentVote] = deleteCommentMutationHookResult

  const userVote = userVotePositive

  gaVoteEvent(
    'vote',
    `vote___comment`,
    'submit',
    props.userId ?? 'unknown_user'
  ).catch(err => {
    console.error(`Google analytics failed to record the vote comment event.`)
  })

  if (typeof userVote === 'boolean') {
    if (
      (userVote === true && direction === VoteDirection.up) ||
      (userVote === false && direction === VoteDirection.down)
    ) {
      deleteCommentVote({
        variables: {
          commentId,
        },
        update(cache, { data: updateVoteData }) {
          if (!updateVoteData) {
            return
          }
          cache.updateFragment<CommentDataFragment>(
            {
              id: `Comment:${commentId}`,
              fragment: CommentDataFragmentDoc,
              fragmentName: 'CommentData',
            },
            commentData => {
              return commentData
                ? {
                    ...commentData,
                    votes: commentData.votes + (userVote ? -1 : 1),
                    userVotePositive: null,
                  }
                : null
            }
          )
        },
      })
    } else {
      const positive = direction === VoteDirection.up ? true : false
      const voteNumChange = positive ? 2 : -2

      changeCommentVote({
        variables: {
          positive,
          commentId,
        },
        update(cache, { data: changeVoteData }) {
          if (!changeVoteData) {
            return
          }
          cache.updateFragment<CommentDataFragment>(
            {
              id: `Comment:${commentId}`,
              fragment: CommentDataFragmentDoc,
              fragmentName: 'CommentData',
            },
            commentData => {
              return commentData
                ? {
                    ...commentData,
                    votes: commentData.votes + voteNumChange,
                    userVotePositive: positive,
                  }
                : null
            }
          )
        },
      })
    }
  } else {
    const positive = direction === VoteDirection.up ? true : false
    const voteNumChange = positive ? 1 : -1

    voteComment({
      variables: {
        positive,
        commentId,
      },
      update(cache, { data: voteCommentData }) {
        if (!voteCommentData) {
          return
        }
        cache.updateFragment<CommentDataFragment>(
          {
            id: `Comment:${commentId}`,
            fragment: CommentDataFragmentDoc,
            fragmentName: 'CommentData',
          },
          commentData => {
            return commentData
              ? {
                  ...commentData,
                  votes: commentData.votes + voteNumChange,
                  userVotePositive: positive,
                }
              : null
          }
        )
      },
    })
  }
}
