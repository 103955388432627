import React from 'react'
import Box from '@mui/material/Box'
import { colors, fonts } from '../../constants'
import Skeleton from '@mui/material/Skeleton'
import { DeviceType, useDeviceType } from 'utils/screenSizeUtils'

export const TopicLoading: React.FC = props => {
  const deviceType = useDeviceType()

  return (
    <Box
      sx={{
        borderTop: `1px solid ${colors.border}`,
        backgroundColor:
          deviceType === DeviceType.Mobile ? colors.search_bg : colors.box_bg,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflow: 'hidden',
          borderBottom: `1px solid ${colors.border}`,
        }}
      >
        <Box
          sx={{
            backgroundColor:
              deviceType === DeviceType.Mobile
                ? colors.search_bg
                : colors.drawer_bg,
            padding: 2,
            flex: 1,
            width: 200, // fix for long strings within flex
          }}
        >
          <Box
            sx={{
              display: 'flex',
              pb: 1,
            }}
          >
            {' '}
            <Skeleton
              width={100}
              sx={{
                bgcolor: colors.textGrey,
              }}
              height={18}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: fonts.dm_sans,
                fontSize: 11,
                pl: 1,
                color: colors.textGrey,
              }}
            >
              <Skeleton
                width={70}
                sx={{ bgcolor: colors.textGrey }}
                height={14}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: 2,
            }}
          >
            <Skeleton
              width={140}
              height={18}
              sx={{
                bgcolor: colors.textGrey,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              sx={{ bgcolor: colors.textGrey }}
              width={200}
              height={21}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: 55,
            backgroundColor:
              deviceType === DeviceType.Mobile
                ? colors.search_bg
                : colors.drawer_bg_light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Skeleton
            height={deviceType === DeviceType.Mobile ? 108 : 117}
            width={18}
            sx={{ bgcolor: colors.textGrey }}
          />
        </Box>
      </Box>
    </Box>
  )
}
