import React from 'react'
import useStyles from './styles'
import theme from '../../../mui-theme'
import WatchedStarUnselectedImage from './WatchedStarUnselected'

const WatchedStarUnselected: React.FC = () => {
  const classes = useStyles(theme)

  return (
    <div className={classes.wrapper}>
      <WatchedStarUnselectedImage className={classes.img} />
    </div>
  )
}

export default WatchedStarUnselected
