import Box from '@mui/material/Box'
import React from 'react'
import { useForm } from 'react-hook-form'
import { regex } from '../../constants'
import TextInput from '../inputs/Text'
import AuthBoxContainer from './common/AuthBoxContainer'
import AuthSubmitButton from './common/AuthSubmitButton'
import { Typography } from '@mui/material'

interface ResetPasswordBoxProps {
  onSubmit: (code: string) => void
  errors?: {
    email?: string
  }
}

interface ResetPasswordForm {
  email: string
}

const ResetPasswordBox: React.FC<ResetPasswordBoxProps> = ({
  onSubmit,
  errors,
}) => {
  const { register, handleSubmit, formState } = useForm<ResetPasswordForm>()

  const onSubmitForm = (data: ResetPasswordForm) => {
    onSubmit(data.email)
  }

  return (
    <AuthBoxContainer
      headingText="Reset password"
      descriptionText={
        <Typography sx={{ fontSize: 14, letterSpacing: 0.4 }}>
          We will email you to reset your password. Please check your email.
        </Typography>
      }
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box sx={{ mt: 3, mb: 4 }}>
          <TextInput
            name="email"
            label="Enter your email"
            ref={register({
              required:
                'Please enter your email address. This is a required field.',
              pattern: {
                value: regex.email,
                message: 'You have entered an invalid email address.',
              },
            })}
            error={formState.errors.email?.message || errors?.email}
          />
        </Box>
        <AuthSubmitButton>RESET PASSWORD</AuthSubmitButton>
      </form>
    </AuthBoxContainer>
  )
}

export default ResetPasswordBox
